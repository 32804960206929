import { Select } from "antd";
import React from "react";
import { Asterisk } from "../../../../utils/helperFunctions";
import SelectWithTooltip from "../../../common/component/SelectWithTooltip"

const { Option } = Select;

const MppDetails = ({ chapterList = [], filterData = {}, setFilterData, showAssigned, facultyList = [] }) => {

    return (
        <>
            <div className="">
                <div className="row">
                    <div className="col-md-2 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Program <Asterisk /></p>

                        <div className="antd_dropdown" >
                            <SelectWithTooltip
                                value={filterData.chapterIDs}
                                setValue={item => setFilterData({ ...filterData, chapterIDs: item })}
                                data={chapterList}
                                placeholder={"All"}
                                keyValue={"name"}
                            />
                        </div>
                    </div>

                    <div className="col-md-3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Mpp Sequence in chapter <Asterisk /></p>

                        <div className="antd_dropdown" >
                            <SelectWithTooltip
                                value={filterData.chapterIDs}
                                setValue={item => setFilterData({ ...filterData, chapterIDs: item })}
                                data={chapterList}
                                placeholder={"All"}
                                keyValue={"name"}
                            />
                        </div>
                    </div>

                    <div className="col-md-3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Faculty Name <Asterisk /></p>

                        <input
                            className={`change_pw_input`}
                            placeholder="Add Faculty Name"
                            value={filterData.search}
                            onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                    setFilterData({ ...filterData, search: e.target.value.replace(/[^0-9]/g, "") })
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MppDetails;
