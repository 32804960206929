import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { currencyFormatter } from "../../../../utils/helperFunctions";

const CashPositionDetails = (props) => {
    const { cash_closing, cash_confirmed, cash_opening, deposit_confirmed, centre_name, centre_id } = props
    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.arrow}`]: {
                color: theme.palette.common.black,
            },
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.black,
                fontSize: "13px",
            },
        })
    );

    return (
        <div key={centre_id} className="cash_position_details-container">
            <div className="details_container_row1 align-items-center">
                <div className="cash-position_divider1 my-1">
                    <p className="cheque-details__label mb-0">Centre</p>
                    <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                        {centre_name.length > 25 ? (
                            <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={centre_name}>
                                <span style={{ cursor: "pointer" }}>{centre_name.substring(0, 25) + "..."}</span>
                            </BootstrapTooltip>
                        ) : (
                            centre_name
                        )}
                    </p>
                </div>
                <div className="cash-position_divider3 my-1">
                    <p className="cheque-details__label mb-0">Cash opening</p>
                    <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                        ₹ {currencyFormatter(cash_opening, false)}
                    </p>
                </div>
                <div className="cash-position_divider3 my-1">
                    <p className="cheque-details__label mb-0">Confirmed cash</p>
                    <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                        ₹ {currencyFormatter(cash_confirmed, false)}
                    </p>
                </div>
                <div className="cash-position_divider3 my-1">
                    <p className="cheque-details__label mb-0">Cash deposit</p>
                    <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                        ₹ {currencyFormatter(deposit_confirmed, false)}
                    </p>
                </div>
                <div className="cash-position_divider3 my-1">
                    <p className="cheque-details__label mb-0">Cash closing</p>
                    <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                        ₹ {currencyFormatter(cash_closing, false)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CashPositionDetails;
