import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ErrorText from "../common/errorText";

const ChangePassword = ({
    open,
    close,
    loading,
    changeNewPassword
}) => {

    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState("");

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [notMatch, setNotMatch] = useState(false);

    const checkChangePassword = () => {
        let flag = 1;

        if (!(oldPassword.trim())) {
            flag = 0;
            setOldPasswordError("Please type Old Password")
        }
        if (!(newPassword.trim())) {
            flag = 0;
            setNewPasswordError("Please type New Password")
        }
        if (!(confirmPassword.trim())) {
            flag = 0;
            setConfirmPasswordError("Please Confirm Password")
        }

        if(newPassword.trim() !== confirmPassword.trim()){
            flag = 0;
            setNotMatch(true);
            setNewPasswordError("New password doesn't match");
        }

        if (flag) {
            var formdata = new FormData();
            formdata.append("old_password", oldPassword);
            formdata.append("new_password", newPassword);
            formdata.append("confirm_password", confirmPassword);

            changeNewPassword(formdata);
        }

    }

    return (
        <div className="add_course_container">
            <Dialog
                open={open}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "380px", // Set your width here
                        },
                    },
                }}
                className="add_course_modal_index"
            >
                <DialogTitle style={{ paddingBottom: "10px" }}>
                    <div
                        style={{
                            display: "flex",
                            padding: "6px",
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="mark-att-modal-head">Change Password</div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 5 }}>
                        <div>
                            <p className="add_course_drop_header">
                                Old Password
                            </p>

                            <input
                                className={`change_pw_input ${oldPasswordError ? "change_pw_input_error" : ""}`}
                                placeholder="Enter Old Password"
                                value={oldPassword}
                                onChange={e => {
                                    setOldPassword(e.target.value);
                                    setOldPasswordError("");
                                }}
                            />
                            {
                                oldPasswordError &&
                                <ErrorText
                                    text={oldPasswordError}
                                />
                            }

                        </div>

                        <div className="mt-3">
                            <p className="add_course_drop_header">
                                New Password
                            </p>

                            <input
                                className={`change_pw_input ${newPasswordError ? "change_pw_input_error" : ""}`}
                                placeholder="Enter New Password"
                                value={newPassword}
                                onChange={e => {
                                    setNewPassword(e.target.value);
                                    setNewPasswordError("");
                                    if(notMatch){
                                        setConfirmPasswordError("");
                                    }
                                }}
                            />
                            {
                                newPasswordError &&
                                <ErrorText
                                    text={newPasswordError}
                                />
                            }

                        </div>

                        <div className="mt-3">
                            <p className="add_course_drop_header">
                                Confirm Password
                            </p>

                            <input
                                className={`change_pw_input ${confirmPasswordError ? "change_pw_input_error" : ""}`}
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={e => {
                                    setConfirmPassword(e.target.value);
                                    setConfirmPasswordError("");
                                    if(notMatch){
                                        setNewPasswordError("");
                                    }
                                }}
                            />
                            {
                                confirmPasswordError &&
                                <ErrorText
                                    text={confirmPasswordError}
                                />
                            }

                        </div>

                        <div className="mt-5">
                            <button
                                className="btn btn-md student_detail_no"
                                onClick={close}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-md student_detail_password_confirm ml-2"
                                onClick={() => {
                                    if (!loading) {
                                        checkChangePassword();
                                    }
                                }}
                            >
                                Save
                                {loading && <span className="ml-2 spinner spinner-white mr-3"></span>}
                            </button>
                        </div>

                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ChangePassword;