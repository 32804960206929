import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../assets/img/backArrowBlack.svg";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import ViewQuestion from '../../mpp/mppScreens/components/ViewQuestion'
import Loader from '../../common/Loader';
import { errorHandler } from '../../../utils';
import Empty from "../../../assets/img/empty_student.svg";
import { useParams } from "react-router-dom";


const limit = 1;

const QuestionBankPreview = () => {
    const { qbID } = useParams();
    const [mppName, setMppName] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(0);

    const getQBDetails = async ({ SKIP = skip, LIMIT = limit }) => {
        setLoading(true)
        const response = await Api.doFetch("GET", {}, ApiUrl.MPP + `/preview?mpp_id=${ qbID }&skip=${ SKIP }&limit=${ LIMIT }`)
        if (response?.status) {
            const questionList = response?.data?.questions
            setMppName(response?.data?.mpp_name)
            setTotal(response?.data?.total)
            if (questionList.length > 0) {
                setCurrentQuestion(questionList[0])
            } else {
                setCurrentQuestion({})
            }
        } else {
            errorHandler(response)
        }
        window.scrollTo(0, 0);
        setLoading(false)
    }

    useEffect(() => {
        getQBDetails({ SKIP: skip, LIMIT: limit })
    }, [skip])

    const navigate = useNavigate();

    const handlePrevious = () => {
        if (skip == 0) {
            navigate(`/mpp/add`)
        } else {
            setSkip(skip => skip - 1)
        }
    }

    const handleNext = () => {
        if (skip + 1 == total) {
            // navigate(`/mpp/assign`, { state: { mppIds: [mppID] } })
            navigate(`/qb/create/${ qbID }`)
        } else {
            setSkip(skip => skip + 1)
        }
    }

    return (<>
        <div className="mpp_view_header">
            <div className="course_list_header mb-0">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/qb/add");
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">Exit Preview</span>
                </div>
            </div>

            {loading ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                    <Loader />
                </div>
                :
                currentQuestion?.question ?
                    <ViewQuestion question={currentQuestion} qB={true} total={total} />
                    :
                    <div className="student_search_empty_container mt-5">
                        <div className="student_search_empty_img_container">
                            <img
                                src={Empty}
                                alt="not found"
                                className="student_search_empty_img"
                            />
                        </div>

                        <p className="student_search_empty_header">
                            QB does not have any questions
                        </p>
                    </div>}

        </div>
        {(!loading && currentQuestion?.question) && <div className="button_row_add_mpp mt-2 mb-3 ml-3 mr-3" >
            {skip != 0 && <button
                className="btn btn-md mpp_previous_button"
                type="button"
                style={{ marginBottom: 20 }}

                onClick={handlePrevious}
            >
                Previous
            </button>}
            <div />

            <button
                onClick={handleNext}
                className={"btn btn-md course_add_next"}
                type="button"
                style={{ marginBottom: 20 }}
            >
                {skip + 1 == total ? "Create exercise" : "Next"}
                {/* {saveLoading && <span className="ml-2 spinner spinner-white mr-2"></span>} */}
            </button>
        </div>}
    </>

    )
}

export default QuestionBankPreview