import React from "react";
import moment from "moment";
import Api from "../../common/api";
import { segmentEvents } from "../../../utils/constants";

const SignedForm = ({
    admission_form_list
}) => {
    return (
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                    <h4 className="modal-title">Admission forms</h4>
                </div>
                <div className="modal-body">
                    <div className="installmentList">
                        {
                            admission_form_list.map((item, index) => {
                                return (
                                    <div className="signed_form_container" key={index}>
                                        <div className="signed_form_container-info">
                                            <p className="admission_form_text_header">
                                                {decodeURIComponent(item.registration_form_url.substring(36))}
                                            </p>
                                            <p className="admission_form_text_sub_header">
                                                {moment(item.created_on).format("DD MMM, YYYY || hh:mm A")}
                                            </p>
                                        </div>
                                        <div className="signed_form_container-view">
                                            <a rel="noopener noreferrer"
                                                href={item.registration_form_url}
                                                target="_blank"
                                                className="admission_form_view"
                                                onClick={() => {
                                                    Api.trackEvent(segmentEvents.STUDENT.CLICK_VIEW_SIGNED_FORM);
                                                }}
                                            >
                                                View
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignedForm;