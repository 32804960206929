import React from "react";
import ReactLoading from "react-loading";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import StudentCard from "../../student/detail/studentCard";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Loader from "../../common/Loader";
import Swal from "sweetalert2";
import { currencyFormatter } from "../../../utils/helperFunctions";
import NotFound from "../../common/notFound";
import { useMediaQuery } from "@mui/material";
import { segmentEvents } from "../../../utils/constants";

const InstallmentCreate = (props) => {
	// let data = localStorage.getItem("userdetail");
	// data = JSON.parse(data);

	let navigate = useNavigate();
	// const location = useLocation()
	const renderResponsiveCards = useMediaQuery("(max-width: 900px)");

	const { centreID, batchID, student_id } = useParams();

	const [isLoading, setIsLoading] = useState(false);
	const [studentDetails, set_studentDetails] = useState(null);
	const [feeDetails, set_feeDetails] = useState(null);
	// const installment_plans = [];
	const [installments_count, setinstallments_count] = useState(1);
	const [discount, set_discount] = useState(null);
	const [payableAmount, setPayableAmount] = useState();

	const [centre, setCentre] = useState("");
	const [course, setCourse] = useState("");
	const [batch, setBatch] = useState("");

	const [loading, setLoading] = useState(false);

	const [notFound, setNotFound] = useState(false);

	const getStudentDetails = async () => {
		setLoading(true);

		const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_DETAILS}?student_id=${student_id}`);

		setLoading(false);
		if (data && !data.message) {
			if (data.student_data?.is_free) {
				setNotFound(true);
				return;
			}
			set_studentDetails(data);
		} else if (data.message && data.message === "Student not found") {
			setNotFound(true);
		}
	};

	const getFeeDetails = async () => {
		if (studentDetails?.student_data?.id) {
			setIsLoading(true);

			const data = await Api.doFetch("GET", {}, `${ApiUrl.COURSE_DETAIL}?school_id=${centreID}&section_id=${batchID}`);

			setIsLoading(false);

			if (data && data.status) {
				if (data.payload.length > 0) {
					setPayableAmount(data.payload[0].fee_amount);
					set_feeDetails(data.payload[0]);

					setBatch(data.payload[0].section_assoc && data.payload[0].section_assoc.section_name);
					setCourse(data.payload[0].section_assoc && data.payload[0].section_assoc.class_name);
					setCentre(data.payload[0].school_name);
				}
			} else if (data && ((data.payload && data.payload.Error) || data.message)) {
				if (data.message === "school not found" || data.message === "section not found") {
					setNotFound(true);
				} else if (data.payload && data.payload.Error) {
					Swal.fire("Error", data.payload.Error, "error");
				} else if (data.message) {
					Swal.fire("Error", data.message, "error");
				}
			}
		}
	};

	const [installmentLoading, setInstallmentLoading] = useState(false);

	const createInstallment = async () => {
		let formdata = new FormData();
		formdata.append("section_id", batchID);
		formdata.append("school_id", centreID);
		formdata.append("no_of_installment", installments_count);
		formdata.append("discounted_amount", discount ? discount : 0);

		setInstallmentLoading(true);

		const data = await Api.doUpload("POST", formdata, ApiUrl.CALC_INSTALLMENT_DETAILS);

		setInstallmentLoading(false);

		if (data && data.status) {
			Api.trackEvent(segmentEvents.STUDENT.CREATED_INSTALLMENTS);
			navigate(`/payment/edit-installment/${centreID}/${batchID}/${studentDetails.student_data.id}`, {
				state: {
					installment_details: data.payload,
					installments_count: installments_count,
					discount: discount,
				},
			});
		} else if (data && ((data.payload && data.payload.Error) || data.message)) {
			if (data.payload && data.payload.Error) {
				Swal.fire("Error", data.payload.Error, "error");
			} else if (data.message) {
				Swal.fire("Error", data.message, "error");
			}
		}
	};

	let incNum = () => {
		if (installments_count < 10) {
			setinstallments_count(Number(installments_count) + 1);
		}
	};
	let decNum = () => {
		if (installments_count > 1) {
			setinstallments_count(installments_count - 1);
		}
	};

	let handleChange = (e) => {
		setinstallments_count(e.target.value);
	};

	const updateInputValue = (event) => {
		if (feeDetails.fee_amount >= event.target.value) {
			set_discount(event.target.value);
			setPayableAmount(feeDetails.fee_amount - event.target.value);
		}
	};

	useEffect(() => {
		getStudentDetails();

		window.scrollTo(0, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getFeeDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [studentDetails]);

	// useEffect(() => {
	//   console.log("feeDetails", feeDetails);
	// }, [feeDetails]);

	// useEffect(() => {
	//     console.log("installment_plans", installment_plans);
	// }, [installment_plans]);

	if (notFound) {
		return <NotFound />;
	}

	return (
		<div className="containerMain">
			{loading ? (
				<div className="loader_container">
					<Loader />
				</div>
			) : (
				<>
					<StudentCard
						style={{
							marginTop: "0rem",
						}}
						studentData={studentDetails}
						goback={() => {
							navigate(`/student/detail/${student_id}`);
						}}
						optionsNeeded={false}
					/>

					<div className="payment_installment_container">
						<div className={`student_course_detail-payment mb-4${renderResponsiveCards ? " responsive" : ""}`}>
							<div className="student_course_detail_centre_flex center">
								<span className="student_course_detail_header">Centre:</span>
								<p className="student_course_detail_value">{centre}</p>
							</div>
							<div className="student_course_detail_others_flex course">
								<span className="student_course_detail_header">Course:</span>
								<p className="student_course_detail_value">{course}</p>
							</div>
							<div className="student_course_detail_others_flex batch">
								<span className="student_course_detail_header">Batch:</span>
								<p className="student_course_detail_value">{batch}</p>
							</div>
						</div>

						<div className="payFieldsOuter tabWrap mt-5">
							<div className="container-fluid">
								<div className="row">
									<div className="col-sm-6 col-md-4 col-lg-3 payFields">
										<div className="mb-4">
											<p className="total_fee_installment_text">Total Fee:</p>
											<p className="total_fee_installment_amount">&#8377; {currencyFormatter(feeDetails?.fee_amount, false)}</p>
											{isLoading ? <ReactLoading type="cylon" color="#09AEE5" /> : ""}
										</div>
										<div>
											<div className="mb-4">
												<p className="total_fee_installment_discount_text">Discount</p>
												<div className="input-group discountWrpField">
													<span className="input-group-addon">&#8377;</span>
													<input
														type="text"
														placeholder="Enter Discount"
														// onChange={feeDetails}
														className="form-control discountWrp"
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}}
														autoComplete="off"
														value={discount}
														onChange={(event) => updateInputValue(event)}
													/>
												</div>
											</div>
										</div>
										<div className="mb-4">
											<p className="total_fee_installment_text">Total Payable Fee:</p>
											<p className="total_fee_installment_amount">&#8377; {currencyFormatter(payableAmount, false)}</p>
										</div>
										<div className="mb-4">
											<p className="total_fee_installment_text">Number Of Installments</p>
											<div className="input-group addNumber">
												<div className="input-group-prepend ">
													<button className="btn btn-outline-primary minusBtn" type="button" onClick={decNum}>
														<span>-</span>
													</button>
												</div>
												<input
													type="text"
													className="form-control installment_counter_text"
													value={installments_count}
													onChange={handleChange}
												/>
												<div className="input-group-prepend">
													<button className="btn btn-outline-primary plusBtn" type="button" onClick={incNum}>
														+
													</button>
												</div>
											</div>
										</div>
										<div>
											<button
												className="btn btn-md create_installment_button"
												onClick={() => {
													if (!installmentLoading) {
														createInstallment();
													}
												}}
											>
												Create Installment
												{installmentLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
											</button>
										</div>
									</div>
									<div className="col-md-9"></div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default InstallmentCreate;
