import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import CreateStudentStep1 from "./step1";
import CreateStudentStep2 from "./step2";
import CreateStudentStep3 from "./step3";
import CreateStudentStep4 from "./step4";
import OtpVerificationModal from "./otpVerificationModal";
import Swal from "sweetalert2";
import { useMediaQuery } from "@mui/material";
import "../../../styles/student-form.scss";
import { segmentEvents } from "../../../utils/constants";
import Stepper from "../../common/component/Stepper";
import { useQuery } from "../../../utils/hooks/use-querry";
import Loader from "../../common/Loader";
import { State } from "country-state-city";
import { City } from "country-state-city";
import ToastMessage from "../../common/toastMessage";
import { toast } from "react-toastify";

const initialState = {
	studentName: "",
	studentMobile: "",
	state: "",
	city: "",
	centre: "",
	course: "",
	batch: "",
	standard: "",
	leadID: "",
	parentName: "",
	parentMobile: "",
	relation: "",
	otp: "",
	is_mobile: false,
};

const AddStudent = () => {
	const navigate = useNavigate();
	const renderResponsiveView = useMediaQuery("(max-width: 800px)");
	const [openModal, toggleModal] = useState(false);
	const [step, setStep] = useState(1);
	const [studentDetails, setStudentDetails] = useState(initialState);
	const [studentError, setStudentError] = useState({});
	const [cityList, setCityList] = useState([]);
	const [courseList, setCourseList] = useState([]);
	const [batchList, setBatchList] = useState([]);
	const [feeDetails, setFeeDetails] = useState(null);
	const [installmentsCount, setInstallmentsCount] = useState(1);
	const [discount, setDiscount] = useState(null);
	const [payableAmount, setPayableAmount] = useState();
	const [loading, setLoading] = useState(false);
	const leadId = useQuery().get("lead-id");
	const [leadDetailsLoading, setLeadDetailsLoading] = useState(!!leadId);
	// const [notFound, setNotFound] = useState(false);
	const [isLoadingFee, setLoadingFee] = useState(false);
	const { batch, course, centre } = studentDetails;
	const [verifying, setVerifying] = useState(false);
	const [sendingOtp, setSendingOtp] = useState(false);
	const verifiedNumber = React.useRef(null);
	const isLead = React.useRef(false);

	useEffect(() => {
		if (centre) {
			getCourses();
		}
		handleChange(
			{
				course: "",
				batch: "",
				standard: "",
			},
			null
		);

		setCourseList([]);
		setBatchList([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [centre]);

	useEffect(() => {
		if (course) {
			getBatches();
		}
		handleChange(
			{
				batch: "",
			},
			null
		);

		setBatchList([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [course]);

	useEffect(() => {
		if (centre && batch && course) getFeeDetails();
	}, [centre, batch, course]);

	useEffect(() => {
		if (leadId) {
			getLeadDetails(leadId, true);
		}
	}, [leadId]);

	const closeModal = () => {
		toggleModal(false);
	};

	const sendOtp = async (retry) => {
		setSendingOtp(true);
		const status = await Api.doFetch(
			"POST",
			{
				mobile_number: studentDetails.studentMobile,
				name: studentDetails.studentName,
			},
			ApiUrl.STUDENT_VERIFY_MOBILE
		).then((res) => {
			handleChange({ otp: "" });
			setSendingOtp(false);
			if (res.status) {
				toggleModal(true);
				toast(<ToastMessage type="success" text={retry ? "OTP resent successfully" : "OTP sent successfully"} />);
				return true;
			} else {
				toast(<ToastMessage type="error" text="Mobile number verification failed" />);
				return false;
			}
		});
		return status;
	};

	const verifyOtp = async () => {
		setVerifying(true);
		await Api.doFetch(
			"POST",
			{
				mobile_number: studentDetails.studentMobile,
				otp: studentDetails.otp,
			},
			ApiUrl.STUDENT_VERIFY_OTP
		).then((res) => {
			setVerifying(false);
			if (res.status) {
				toast(<ToastMessage type="success" text="OTP verified successfully" />);
				setStep(2);
				closeModal();
				verifiedNumber.current = studentDetails.studentMobile;
				handleChange(
					{
						is_mobile: true,
					},
					null
				);
			} else {
				toast(<ToastMessage type="error" text="Mobile number verification failed" />);
				setStudentError({
					...studentError,
					otpError: "Invalid OTP, please try again",
				});
			}
		});
	};

	const validateStep1 = (errorMsg = true) => {
		const { studentName, studentMobile, state, city } = studentDetails;

		let tempError = {};

		if (!studentName.trim()) {
			tempError.studentNameError = "Please enter student name";
		}
		if (studentMobile.trim().length !== 10) {
			tempError.studentMobileError = "Please enter a valid mobile";
		}
		if (!state || !state.trim()) {
			tempError.stateError = "Please select state";
		}
		if (!city || !city.trim()) {
			tempError.cityError = "Please select city";
		}

		if (Object.keys(tempError).length === 0) {
			if (errorMsg) {
				if (
					studentDetails.studentMobile.length === 10 &&
					(!studentDetails.is_mobile || (studentDetails.is_mobile && studentDetails.studentMobile !== verifiedNumber.current))
				) {
					sendOtp();
				} else setStep(2);
			} else {
				return true;
			}
		} else {
			if (errorMsg) {
				setStudentError({
					...studentError,
					...tempError,
				});
			} else {
				return false;
			}
		}
	};

	const validateStep2 = (errorMsg = true) => {
		const { centre, course, batch } = studentDetails;

		let tempError = {};

		if (!centre) {
			tempError.centreError = "Please select centre";
		}
		if (!studentDetails.standard) {
			tempError.standardError = "Please select class standard";
		}
		if (!course) {
			tempError.courseError = "Please select course";
		}
		if (!batch) {
			tempError.batchError = "Please select batch";
		}

		if (Object.keys(tempError).length === 0) {
			if (errorMsg) {
				setStep(3);
			} else {
				return true;
			}
		} else {
			if (errorMsg) {
				setStudentError({
					...studentError,
					...tempError,
				});
			} else {
				return false;
			}
		}
	};

	const validateStep3 = (errorMsg = true) => {
		const { parentMobile, parentName, relation } = studentDetails;

		let tempError = {};

		if (!parentMobile.trim()) {
			tempError.parentMobileError = "Please enter parent's mobile";
		} else if (parentMobile.trim().length !== 10) {
			tempError.parentMobileError = "Please enter a valid mobile";
		}
		if (!relation) {
			tempError.relationError = "Please select a relationship";
		}
		if (!parentName.trim()) {
			tempError.parentNameError = "Please enter parent's name";
		}

		if (Object.keys(tempError).length === 0) {
			if (errorMsg) {
				setStep(4);
			} else {
				return true;
			}
		} else {
			if (errorMsg) {
				setStudentError({
					...studentError,
					...tempError,
				});
			} else {
				return false;
			}
		}
	};

	const getFeeDetails = async () => {
		setLoadingFee(true);
		const data = await Api.doFetch("GET", {}, `${ApiUrl.COURSE_DETAIL}?school_id=${centre}&section_id=${batch}`);
		setLoadingFee(false);
		if (data && data.status) {
			if (data.payload.length > 0) {
				setPayableAmount(data.payload[0].fee_amount);
				setFeeDetails(data.payload[0]);
			}
		} else if (data && ((data.payload && data.payload.Error) || data.message)) {
			if (data.message === "school not found" || data.message === "section not found") {
			} else if (data.payload && data.payload.Error) {
				Swal.fire("Error", data.payload.Error, "error");
			} else if (data.message) {
				Swal.fire("Error", data.message, "error");
			}
		}
	};

	const getCourses = async () => {
		const data = await Api.doFetch("GET", {}, `${ApiUrl.CLASSES}?school=${centre}`);
		setCourseList(data);
	};

	const getBatches = async () => {
		const data = await Api.doFetch("GET", {}, `${ApiUrl.SECTION}?class=${course}&school=${centre}`);
		setBatchList(data);
	};

	const getLeadDetails = async (leadId, useLoader) => {
		if (useLoader) setLeadDetailsLoading(true);
		const payload = await Api.doFetch("GET", {}, `${ApiUrl.LEAD}/${leadId}`);
		if (payload.status && payload.data) {
			isLead.current = true;
			const { data } = payload;
			const stateIndex = State.getStatesOfCountry("IN").findIndex((stateData) => stateData.name === data.state);
			setCityList(City.getCitiesOfState("IN", State.getStatesOfCountry("IN")[stateIndex].isoCode));
			if (data.is_mobile) verifiedNumber.current = data.mobile_number;
			const leadData = {
				studentName: data.name,
				studentMobile: data.mobile_number,
				state: data.state,
				city: data.city_assoc,
				centre: data.centre_id,
				course: data.course_id,
				standard: data.class_standard,
				is_mobile: data.is_mobile,
			};

			setStudentDetails({
				...studentDetails,
				...leadData,
			});
			setLeadDetailsLoading(false);
			return true;
		}
		return false;
	};

	const handleChange = (payload, errorPayload) => {
		if (payload) {
			setStudentDetails({
				...studentDetails,
				...payload,
			});
		}
		if (errorPayload) {
			setStudentError({
				...studentError,
				...errorPayload,
			});
		}
	};

	const createStudent = async () => {
		setLoading(true);
		const dataObj = {
			name: studentDetails.studentName,
			mobile_number: studentDetails.studentMobile,
			state: studentDetails.state,
			city: studentDetails.city,
			center: centre,
			batch: batch,
			class_standard: studentDetails.standard,
			lead_square_id: studentDetails.leadID,
			parent_name: studentDetails.parentName,
			parent_mobile_number: studentDetails.parentMobile,
			parent_relationship: studentDetails.relation,
			installments: installmentsCount,
			discount: discount ? discount : 0,
			is_mobile: studentDetails.is_mobile,
		};
		const payload = await Api.doFetch("POST", dataObj, ApiUrl.CREATE_STUDENT);
		setLoading(false);
		if (!payload.status) {
			Swal.fire("Error!", `${payload.message}`, "error");
		} else {
			Api.trackEvent(segmentEvents.STUDENT.ADDED_STUDENT);
			Api.trackEvent(segmentEvents.STUDENT.CREATED_INSTALLMENTS);
			navigate(`/payment/edit-installment/${centre}/${batch}/${leadId ? leadId : payload.data.id}`, {
				state: {
					new_flow: true,
				},
			});
		}
	};

	const resetData = (payload) => {
		isLead.current = false;
		setStudentDetails({
			...initialState,
			...payload,
		});
		setStudentError({});
	};

	const steps = [
		{ label: "Student Details" },
		{
			label: "Course Details",
			validate: () => {
				return studentDetails.is_mobile && studentDetails.studentMobile === verifiedNumber.current && validateStep1(false);
			},
		},
		{
			label: "Parent Details",
			validate: () => validateStep1(false) && validateStep2(false),
		},
		{
			label: "Fee Details",
			validate: () => validateStep1(false) && validateStep2(false) && validateStep3,
		},
	];

	return (
		<div className="student_add_container">
			<Stepper steps={steps} activeStep={step} setStep={setStep} />

			<div className={`student_details_wrapper mt-3${renderResponsiveView ? " is-mobile" : ""}`}>
				<div className="student_details_container">
					<div className={step === 4 ? "fee_details_form" : "student_details_form"}>
						{leadDetailsLoading ? (
							<div>
								<Loader />
							</div>
						) : step === 1 ? (
							<CreateStudentStep1
								data={studentDetails}
								handleData={setStudentDetails}
								error={studentError}
								handleError={setStudentError}
								cityList={cityList}
								setCityList={setCityList}
								disableMobile={!!leadId}
								getLeadDetails={getLeadDetails}
								isPrefilled={isLead.current}
								resetData={resetData}
							/>
						) : step === 2 ? (
							<CreateStudentStep2
								data={studentDetails}
								handleData={setStudentDetails}
								error={studentError}
								handleError={setStudentError}
								courseList={courseList}
								setCourseList={setCourseList}
								batchList={batchList}
								setBatchList={setBatchList}
							/>
						) : step === 3 ? (
							<CreateStudentStep3 data={studentDetails} handleData={setStudentDetails} error={studentError} handleError={setStudentError} />
						) : (
							<CreateStudentStep4
								centre={centre}
								course={course}
								batch={batch}
								feeDetails={feeDetails}
								payableAmount={payableAmount}
								discount={discount}
								courseList={courseList}
								batchList={batchList}
								isLoading={isLoadingFee}
								installmentsCount={installmentsCount}
								setDiscount={setDiscount}
								setPayableAmount={setPayableAmount}
								setInstallmentsCount={setInstallmentsCount}
							/>
						)}
					</div>
				</div>

				<div className="mt-3 d-flex justify-content-between">
					<button
						className="btn btn-md add_student_back"
						onClick={() => {
							if (step === 1) {
								navigate("/student");
							} else {
								setStep(step === 2 ? 1 : 2);
							}
						}}
					>
						{step === 1 ? "Cancel" : "Back"}
					</button>
					<button
						className="btn btn-md add_student_next ml-2"
						style={{ minWidth: 118 }}
						onClick={() => {
							if (loading) return;

							if (step === 1) {
								if (sendingOtp) return;
								validateStep1();
							} else if (step === 2) {
								validateStep2();
							} else if (step === 3) {
								validateStep3();
							} else if (step === 4) {
								createStudent();
							}
						}}
					>
						{/* {step === 4 ? "Create" : "Next"} */}
						Next
						{(loading || sendingOtp) && <span className="ml-2 spinner spinner-white mr-2"></span>}
					</button>
				</div>
				{openModal && (
					<OtpVerificationModal
						openModal={openModal}
						closeModal={closeModal}
						mobile={studentDetails.studentMobile}
						otp={studentDetails.otp}
						handelData={handleChange}
						verifyOtp={verifyOtp}
						verifying={verifying}
						sendOtp={sendOtp}
						otpError={studentError.otpError}
						nextStep={() => setStep(2)}
					/>
				)}
			</div>
		</div>
	);
};

export default AddStudent;
