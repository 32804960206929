import SearchStudent from "../../assets/img/search_student.svg";
import AddStudent from "../../assets/img/add_student.svg";
import { useNavigate } from "react-router-dom";

const RoleDashboard = () => {
    const navigate = useNavigate();
    return (

        <div className="containerMain">

            <div className="payment_reversal_container">

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate('/role/list')
                    }}
                >

                    <img
                        src={SearchStudent}
                        alt="pending"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Search an
                    </span>
                    <span className="payment_reversal_container_text">
                        Existing Role
                    </span>

                </div>

                <div
                    className="payment_reversal_container_history"
                    onClick={() => {
                        navigate('/role/add')
                    }}
                >

                    <img
                        src={AddStudent}
                        alt="history"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Add a New
                    </span>
                    <span className="payment_reversal_container_text">
                        Role
                    </span>

                </div>

            </div>


        </div>

    )
}

export default RoleDashboard;