import { useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import TopNavBar from "../navbar/TopNavBar";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const UserList = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    userList: [],
    error: "",
    activeUserCount: "-",
    inActiveUserCount: "-",
    totalUserCount: "-",
    allUserList: [],
  });
  let data = localStorage.getItem("userdetail");
  data = JSON.parse(data);
  const card = (count, title) => {
    return (
      <>
        <CardContent>
          <Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
            {count}
          </Typography>
          <Typography variant="h5" component="div"></Typography>
          <Typography sx={{ fontSize: 22 }} color="text.secondary">
            {title}
          </Typography>
        </CardContent>
      </>
    );
  };

  const tableCellStyles = {
    fontSize: "1rem",
  };

  const initFunction = async () => {
    let t = {
      userList: [],
      error: "",
      activeUserCount: "-",
      inActiveUserCount: "-",
      totalUserCount: "-",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}users/list-of-user/`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${data.token}`,
          },
        }
      );
      const D = await response.json();
      if (response.status === 401) {
        Swal.fire("Unauthorised?", "User logged into another device", "error");
        localStorage.clear();
        navigate("/error");
      }
      if (response.status === 200) {
        t = {
          ...t,
          userList: D?.payload?.users,
          allUserList: D?.payload?.users,
        };

        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}users/get-user-count/`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Token ${data.token}`,
              },
            }
          );
          const res = await response.json();
          if (response.status === 401) {
            Swal.fire(
              "Unauthorised?",
              "User logged into another device",
              "error"
            );
            localStorage.clear();
            navigate("/error");
          }
          if (response.status === 200) {
            t = {
              ...t,
              activeUserCount: res?.payload?.active_user_count,
              inActiveUserCount: res?.payload?.in_active_user_count,
              totalUserCount: res?.payload?.total_user_count,
            };
          }

          setUserDetails(t);
        } catch (err) {
          setUserDetails({
            ...userDetails,
            error: err.message,
          });
        }
      }
    } catch (err) {
      setUserDetails({
        ...userDetails,
        error: err.message,
      });
    }
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = userDetails?.allUserList.filter((row) => {
      return (
        row.first_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        ("" + row.user_id).includes(searchedVal) ||
        row.mobile_number.includes(searchedVal)
      );
    });
    setUserDetails({
      ...userDetails,
      userList: filteredRows,
    });
  };

  useEffect(() => {
    initFunction();
  }, []);

  const keyValueExtract = (arr, keyName) => {
    let res = "";
    arr.map((item) => {
      res += item[keyName] + ", ";
    });
    return res;
  };

  return (
    <div style={{ display: "flex" }}>

      <div style={{ width: "100%", padding: "0" }}>
       

        <div style={{ margin: "1rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Paper>
                <Card variant="outlined">
                  {card(userDetails?.totalUserCount, "Total Users")}
                </Card>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Card variant="outlined">
                  {card(userDetails?.activeUserCount, "Total Active")}
                </Card>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Card variant="outlined">
                  {card(userDetails?.inActiveUserCount, "Total Inactive")}
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <div style={{ margin: "1rem" }}>
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => requestSearch(e.target.value)}
          />
        </div>

        <div style={{ margin: "1rem" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: 12 }}>ID</TableCell>
                  <TableCell sx={{ fontSize: 12 }} align="right">
                    Name
                  </TableCell>
                  <TableCell sx={{ fontSize: 12 }} align="right">
                    Contact
                  </TableCell>
                  <TableCell sx={{ fontSize: 12 }} align="right">
                    Role
                  </TableCell>
                  <TableCell sx={{ fontSize: 12 }} align="right">
                    Center
                  </TableCell>
                  <TableCell sx={{ fontSize: 12 }} align="right">
                    Status
                  </TableCell>
                  <TableCell sx={{ fontSize: 12 }} align="right">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userDetails?.userList?.map((row) => (
                  <TableRow
                    key={row.user_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: 12 }} align="right">
                      {row.user_id}
                    </TableCell>
                    <TableCell sx={{ fontSize: 12 }} align="right">
                    <div style={{whiteSpace:"nowrap", overflow:"hidden", "textOverflow":"ellipsis"}}>
                      {row.first_name.substring(0,20)}
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontSize: 12 }} align="right">
                      {row.mobile_number}
                    </TableCell>
                    <TableCell sx={{ fontSize: 12 }} align="right">
                       
                      {keyValueExtract(row.roles, "role_name").substring(0,200)} ...
                     
                    </TableCell>
                    <TableCell sx={{ fontSize: 12 }} align="right">
                      {keyValueExtract(row.schools, "school_name").substring(0,200)} ...
                    </TableCell>
                    <TableCell sx={{ fontSize: 12 }} align="right">
                      {row.status ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell  align="right">
                      <Button variant="contained" onClick={(e) => {
                        navigate('/user/add',{ state: {
                            first_name: row.first_name,
                            user_id: row.user_id,
                            email_id: row.email_id,
                            mobile_number: row.mobile_number,
                            schools: keyValueExtract(row.schools, "school_id").split(', '),
                            roles: keyValueExtract(row.roles, "role_id").split(', ')
                        }})
                      }} >Edit</Button>
                      {/* <Button variant="contained">Change Status</Button> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default UserList;
