import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../../assets/img/left_arrow.svg";
import Gif from "../../../../assets/img/processing.gif";
import StatusBar from './statusBar';
import { Checkbox } from 'antd';
import CounselorCard from './counselorCard';
import Api from '../../../common/api';
import ApiUrl from '../../../common/apiUrl';
import Swal from 'sweetalert2';
import Loader from '../../../common/Loader';
import Confirmation from './confirmation';
import Empty from "../../../../assets/img/empty_student.svg";
import ToastMessage from '../../../common/toastMessage';
import { toast } from "react-toastify";

const ConfirmCash = () => {

    const navigate = useNavigate();

    const myTimeout = useRef(null);

    const [createModal, setCreateModal] = useState(false);
    const [confirm, setConfirm] = useState(false)

    const closeCreateModal = () => {
        setCreateModal(false);
    }

    const [paymentGroups, setPaymentGroups] = useState([]);
    const [allPaymentId, setAllPaymentId] = useState([]);

    const [checkedPayments, setCheckedPayments] = useState({});
    const [totalCash, setTotalCash] = useState(0);

    const handleCheck = (payment_id) => {

        // Toggle
        payment_id = String(payment_id)

        if (!payment_id) return;

        setCheckedPayments(p => {
            const paymentItem = p[payment_id]; // true or false

            return {
                ...p,
                [payment_id]: !paymentItem,
            }
        });
    }

    const handleCheckGroup = (group_payment_ids = []) => {
        setCheckedPayments(p => {

            // check if cousellor group checkbox is true
            if (!isCheckedGroup(group_payment_ids)) {
                let x = {}
                group_payment_ids.forEach(i => x = { ...x, [i]: true })
                return {
                    ...p, ...x
                }

            } else {
                let x = {}
                group_payment_ids.forEach(i => x = { ...x, [i]: false })
                return {
                    ...p, ...x
                }
            }            
        })
    }

    const isCheckedPayment = (payment_id) => {

        if (payment_id in checkedPayments) {
            return !!checkedPayments[payment_id]
        }

        return false // true or false
    }

    const isCheckedGroup = (paymentIds = []) => {

        if (paymentIds?.length === 0) {
            return false;
        }

        for (let paymentId of paymentIds) {
            if (!(paymentId in checkedPayments) || !checkedPayments[paymentId]) {
                return false
            }
        }
        return true;
    }

    const canConfirmCash = () => Object.entries(checkedPayments).filter(x => x[1]).map(x => Number(x[0]))

    const [confirmLoading, setConfirmLoading] = useState(false);

    const confirmCash = async () => {

        setConfirmLoading(true);
        
        const data = await Api.doFetch("POST", { payment_ids: canConfirmCash() }, `${ApiUrl.CONFIRM_CASH}`);

        setConfirmLoading(false);
        
        if (data?.status) {
            toast(<ToastMessage text="Cash confirmed successfully." />);
            setConfirm(true);
        } else {
            Swal.fire("Error", data?.message || "Internal server error", "error");
        }
    }

    useEffect(() => {
        if (confirm) {
            myTimeout.current = setTimeout(() => {
                navigate("/cash-deposit/add");
            }, 8000);
        }
        return () => clearTimeout(myTimeout.current);
    }, [confirm])

    const [loading, setLoading] = useState(false);

    const getConfirmedCashList = async () => {

        setLoading(true);

        const data = await Api.doFetch("GET", {}, `${ApiUrl.CONFIRM_CASH}/view`);

        setLoading(false);

        if (data?.status) {

            let allPaymentIds = [], totalCash = 0;

            const computedPayload = data?.data.map(paymentGroup => {

                let paymentIds = paymentGroup.student_fees.filter(x => !x.reversed).map(studentFee => String(studentFee.payment_id) );

                totalCash += +paymentGroup.total_amount_paid;

                allPaymentIds = [ ...allPaymentIds, ...paymentIds]

                return {
                    ...paymentGroup,
                    paymentIds: paymentIds,
                }
            })
            
            setTotalCash(totalCash);
            setAllPaymentId(allPaymentIds)
            setPaymentGroups(computedPayload)
        } else {
            Swal.fire("Error", data?.message || "Internal server error", "error");
        }

    }

    useEffect(() => {
        getConfirmedCashList()
    }, [])

    const calculateSelectedCash = (selectedPayments, cashData) => {
        
        if (cashData.length === 0) {
            return 0;
        }

        let tempCashData = [];

        cashData.map(x => {
            tempCashData = [...tempCashData, ...x.student_fees]
        })

        const tempSelectedPayments = Object.entries(selectedPayments).filter(x => x[1]).map(x => x[0])

        let total = 0, i;

        for (i = 0;i < tempCashData.length; i++) {
            if (tempSelectedPayments.indexOf(String(tempCashData[i].payment_id)) >= 0) {
                total += +tempCashData[i].amount_paid
            }
        }

        return total;
    }

    const selectedCash = useMemo(() => calculateSelectedCash(checkedPayments, paymentGroups), [checkedPayments]);

    if (confirm) {
        return (
            <div className='redirection_page_container' >
                <img src={Gif} className='processing_gif' />
                <span className='modal_text_18' >
                    Redirecting to cash deposit
                </span>
                <p className='modal_text_18_g' style={{ textAlign: 'center' }} >
                    click cancel to move to dashboard
                </p>
                <button 
                    className='btn btn-md add_tab_back' 
                    style={{ marginBottom: "20px" }} 
                    onClick={() => {
                        navigate("/cash-deposit");
                    }} 
                >
                    Cancel
                </button>
            </div>
        )
    }

    return (
        <div className="course_list_container">
            
            <Confirmation 
                open = {createModal}
                close = {closeCreateModal}
                selectedCash = {selectedCash}
                confirmCash = {confirmCash}
            />

            <div className="course_list_header">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/cash-deposit");
                        }}
                    />
                    <span className="reversal_detail_header_text ml-2">Confirm cash</span>
                </div>
            </div>

            {
                loading ?
                <div className="loader_scroll_container">
                    <Loader />
                </div>
                :
                paymentGroups?.length === 0 ?
                <> 
                    <StatusBar 
                        totalCash = {totalCash}
                        selectedCash = {selectedCash}
                    />

                    <div
                        className="student_search_empty_container"
                        style = {{
                            marginTop: "8rem"
                        }}
                    >
                        <div className="student_search_empty_img_container">
                            <img
                                src={Empty}
                                alt="not found"
                                className="student_search_empty_img"
                            />
                        </div>

                        <p className="student_search_empty_header">
                            No cash pending
                        </p>
                        <span className="student_search_empty_label">
                            There is no cash pending to be 
                        </span>
                        <span className="student_search_empty_label">
                            confirmed at the moment
                        </span>
                    </div>
                </>
                :
                <>
                    <StatusBar 
                        totalCash = {totalCash}
                        selectedCash = {selectedCash}
                    />
                    <div className='confirm_cash_scroll_container' >
                        {
                            !!paymentGroups &&
                            <div className='cash_not_confirmed_container' >
                                <Checkbox 
                                    className='checkbox_text' 
                                    onChange={() => handleCheckGroup(allPaymentId) }
                                    checked = {isCheckedGroup(allPaymentId)}
                                >
                                    Select all
                                </Checkbox>

                                {
                                    paymentGroups.map((paymentGroup, index) => {
                                        return (
                                            <CounselorCard
                                                isCheckedGroup={isCheckedGroup}
                                                handleCheckGroup={handleCheckGroup}
                                                isCheckedPayment={isCheckedPayment}
                                                handleCheck={handleCheck}
                                                key={`${paymentGroup.centre_id}_${paymentGroup.counsellor_id}_${index}`}
                                                index={index}
                                                item={paymentGroup} 
                                            />
                                        )
                                    })
                                }
                            </div>
                        }

                        <div className="d-flex justify-content-between mt-5 mb-3" >
                            <button
                                className="btn btn-md add_tab_back"
                                onClick={() => navigate("/cash-deposit")}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={canConfirmCash()?.length > 0 ? false : true}
                                className="btn btn-md add_tab_next ml-2"
                                onClick={() => {
                                    if (confirmLoading) {
                                        return;
                                    }
                                    setCreateModal(true)
                                }}
                            >
                                Confirm
                                {confirmLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                            </button>
                        </div>
                    </div>
                </>
            }

        </div>
    )
}

export default ConfirmCash