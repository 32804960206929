import React from 'react'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import QBDownloadModal from './QBDownloadModal'
import moment from 'moment';


let options = [
    'Download with solution',
    'Download as raw data'
];

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${ className } tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${ tooltipClasses.arrow }`]: {
            color: theme.palette.common.black,
        },
        [`& .${ tooltipClasses.tooltip }`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const QBCard = ({ name, id, noQuestions, subject, uploadDate, download }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openModal, setOpenModal] = React.useState(false)

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, value) => {
        if (e.nativeEvent.target.outerText === "Download with solution") {
            download(id, name, 'solution')
        }
        else if (e.nativeEvent.target.outerText === 'Download as raw data') {
            download(id, name, 'raw')
        }
        setAnchorEl(null);
    };

    const handleDownload = (type) => {
        let payload = {}
        console.log(type)
        if (type == 'Question wise') {
            payload.sorting_format = 'Question'
        }
        if (type == 'Tag wise') {
            payload.sorting_format = 'Tag'
        }
        download(id, name, 'sorting', payload)
    }

    return (
        <div className="course_list_card ">

            <QBDownloadModal openModal={openModal} closeModal={() => { setOpenModal(false) }} handleConfirmSubmit={handleDownload} />

            <div className="mpp_divider3">
                <p className="course_list_card_header">Name</p>

                {name && (name.length < 45 ? (
                    <p className="course_list_card_value">{name ? name : '-'}</p>
                ) : (
                    <BootstrapTooltip
                        disableFocusListener
                        enterTouchDelay={0}
                        title={name}
                    >
                        <p style={{ cursor: "pointer" }} className="course_list_card_value">
                            {name.substring(0, 35) + "..."}
                        </p>
                    </BootstrapTooltip>
                ))}

                <p className="payment_details_list_card_header subvalue">ID: {id ? id : '-'}</p>
            </div>

            <div className="mpp_divider3">
                <p className="course_list_card_header">No. of questions</p>

                <p className="course_list_card_value">{noQuestions ? noQuestions : '-'}</p>
            </div>

            <div className="mpp_divider3">
                <p className="course_list_card_header">Subject</p>
                <p className="course_list_card_value">{subject ? subject : '-'}</p>

            </div>

            <div className="mpp_divider3">
                <p className="course_list_card_header">Upload date</p>
                <p className="mpp_qp_tag">{uploadDate ? moment(uploadDate).format("MMM DD, YYYY") : '-'}</p>
            </div>
            <div
                className="button_edit cursor-pointer "
                onClick={() => {
                    setOpenModal(true)
                }}
            >
                <span style={{ fontWeight: '500' }}>Download</span>
            </div>
            <div>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon
                        style={{
                            fontSize: "2.5rem",
                            color: "#000000"
                        }}
                    />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{}}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    style={{
                        marginLeft: "1rem",
                        marginTop: "-0.8rem"
                    }}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option}
                            value={option}
                            onClick={handleClose}
                            style={{
                                padding: "1rem 2rem"
                            }}
                            className="mui_dropdown_options"
                        >
                            <span
                                className={"student_detail_option_reset_pw"}
                            >
                                {option}
                            </span>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
            {/* 
          <StyledTooltip
              placement="bottomRight"
              true
              trigger="hover"
              content={<div className='payment_history_options_text' style={{ alignItems: "center", justifyContent: "center", fontSize: 16,cursor: "pointer" }} onClick={() => { alert('Download') }}>Download as word</div>} >
              <MoreVertIcon fontSize="large"/>

          </StyledTooltip> */}
        </div>
    )
}

export default QBCard