import React from "react";
import { useState, useEffect } from 'react';
import {
    useParams,
    useNavigate,
} from "react-router-dom";
import '../installmentCreate.css';
import '../installmentCreateHostel.css';
import Swal from "sweetalert2";
import StudentCard from "../../student/detail/studentCard";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Loader from "../../common/Loader";
import { currencyFormatter } from "../../../utils/helperFunctions";
import NotFound from "../../common/notFound";
import { segmentEvents } from "../../../utils/constants";

const CreateInstallmentHostel = (props) => {
    // let data = localStorage.getItem("userdetail");
    // data = JSON.parse(data);

    let navigate = useNavigate()
    // let location = useLocation();

    const { centreID, batchID, student_id } = useParams();
    // const [isLoading, setIsLoading] = useState(false);
    const [studentDetails, set_studentDetails] = useState(null);
    // const [feeDetails, set_feeDetails] = useState(null);
    // const [installment_plans, set_installment_plans] = useState([]);
    const [installments_count, setinstallments_count] = useState(1);
    const [discount, set_discount] = useState("");
    const [payableAmount, setPayableAmount] = useState(0);
    const [hostal_fee, set_hostal_fee] = useState("");
    const [startdate, set_startdate] = useState();
    const [enddate, set_enddate] = useState();
    const [min_end_date, set_min_end_date] = useState(new Date().toISOString().split("T")[0]);

    const [centre, setCentre] = useState("");
    const [course, setCourse] = useState("");
    const [batch, setBatch] = useState("");

    const [loading, setLoading] = useState(false);

    const [notFound, setNotFound] = useState(false);

    const getStudentDetails = async () => {

        setLoading(true);

        const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_DETAILS}?student_id=${student_id}`);

        setLoading(false);
        if (data && !data.message) {
            set_studentDetails(data);
        } else if(data.message && data.message === "Student not found") {
            setNotFound(true);
        }
    }

    const getFeeDetails = async () => {

        if (studentDetails?.student_data?.id) {

            // setIsLoading(true);

            const data = await Api.doFetch("GET", {}, `${ApiUrl.COURSE_DETAIL}?school_id=${centreID}&section_id=${batchID}`);

            // setIsLoading(false);

            if (data && data.status) {

                if (data.payload.length > 0) {
                    // setPayableAmount(data.payload[0].fee_amount); // as course fee as nothing to do with Hostel fees
                    // set_feeDetails(data.payload[0]);

                    setBatch(data.payload[0].section_assoc && data.payload[0].section_assoc.section_name);
                    setCourse(data.payload[0].section_assoc && data.payload[0].section_assoc.class_name);
                    setCentre(data.payload[0].school_name);
                }

            } else if (data && ((data.payload && data.payload.Error) || data.message)) {
                if(data.message === "school not found" || data.message === "section not found"){
                    setNotFound(true);
                } else if (data.payload && data.payload.Error) {
                    Swal.fire("Error", data.payload.Error, "error");
                } else if (data.message) {
                    Swal.fire("Error", data.message, "error");
                }
            }
        }
    }

    const [installmentLoading, setInstallmentLoading] = useState(false);

    const createInstallment = async () => {
        if (startdate === undefined || startdate === "") {
            Swal.fire("Please select Start Date");
            return false;
        }
        if (enddate === undefined || enddate === "") {
            Swal.fire("Please select End Date");
            return false;
        }
        if (hostal_fee === undefined || hostal_fee === "") {
            Swal.fire("Please enter Hostel Fee");
            return false;
        }
        if(+payableAmount < 0) {
            Swal.fire("Total Hostel Payable fee cannot be in Negative");
            return false;
        }

        let formdata = new FormData();
        formdata.append("start_date", startdate);
        formdata.append("end_date", enddate);
        formdata.append("total_amount", hostal_fee);
        formdata.append("no_of_installment", installments_count);
        formdata.append("discounted_amount", discount ? discount : 0);

        setInstallmentLoading(true);

        const data = await Api.doUpload("POST", formdata, ApiUrl.CALC_HOSTEL_INSTALLMENT_DETAILS);

        setInstallmentLoading(false);

        if (data && data.status) {

            Api.trackEvent(segmentEvents.STUDENT.CREATED_HOSTEL_INSTALLMENTS);
            navigate(`/payment/edit-hostel-installment/${centreID}/${batchID}/${studentDetails.student_data.id}`, {
                state: {
                    installment_details: data.payload,
                    installments_count: installments_count,
                    discount: discount
                }
            })

        } else if (data && ((data.payload && data.payload.Error) || data.message)) {
            if (data.payload && data.payload.Error) {
                Swal.fire("Error", data.payload.Error, "error");
            } else if (data.message) {
                Swal.fire("Error", data.message, "error");
            }
        }
    };

    let incNum = () => {
        if (installments_count < 10) {
            setinstallments_count(Number(installments_count) + 1);
        }
    };
    let decNum = () => {
        if (installments_count > 1) {
            setinstallments_count(installments_count - 1);
        }
    }

    let handleChange = (e) => {
        setinstallments_count(e.target.value);
    }

    const updateHostalFeeInputValue = (event) => {
        //if (hostal_fee >= event.target.value) {
        set_hostal_fee(event.target.value);
        setPayableAmount(+event.target.value - (+discount ? +discount : 0));
        // }
    }

    const updateDiscountInputValue = (event) => {
        if (+hostal_fee >= +event.target.value) {
            set_discount(event.target.value);
            setPayableAmount(hostal_fee - event.target.value);
        }
    }

    useEffect(() => {
        getStudentDetails();

        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getFeeDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentDetails]);

    if(notFound){
        return <NotFound />
    }

    return (
        <div className="containerMain">
            {
                loading ?
                <div className="loader_container">
                    <Loader />
                </div>
                :
                <>
                    <StudentCard
                        style={{
                            marginTop: "0rem"
                        }}
                        studentData={studentDetails}
                        goback={() => {
                            navigate(`/payment/fee-summary/${centreID}/${batchID}/${student_id}`);
                        }}
                        optionsNeeded={false}
                    />

                    <div className="payment_installment_container">
                        <div className='row mb-4'>
                          <div className="col-md-10 payment_installment_container-col d-flex">
                            <div className="student_course_detail_centre_flex">
                              <span className="student_course_detail_header">Centre:</span>
                              <p className="student_course_detail_value">{centre}</p>
                            </div>
                            <div className="student_course_detail_others_flex">
                              <span className="student_course_detail_header">Course:</span>
                              <p className="student_course_detail_value">{course}</p>
                            </div>
                            <div className="student_course_detail_others_flex">
                              <span className="student_course_detail_header">Batch:</span>
                              <p className="student_course_detail_value">{batch}</p>
                            </div>
                          </div>
                        </div>

                        <div className="payFieldsOuter tabWrap  mt-5">
                            <div className="container-fluid">
                                <div className="row payFields DateWrap">
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="mb-4">
                                            <p className="total_fee_installment_discount_text">
                                                Hostel Start Date
                                            </p>
                                            <p> <input type="date" onKeyDown={(e) => e.preventDefault()} onChange={(event) => {
                                                set_startdate(event.target.value);
                                                if (startdate !== "") {
                                                    var date = new Date(event.target.value)
                                                    let date2 = date.setDate(date.getDate() + 1);
                                                    set_min_end_date(new Date(date2).toISOString().split("T")[0])
                                                }
                                            }} /></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="mb-4">
                                            <p className="total_fee_installment_discount_text">
                                                Hostel End Date
                                            </p>
                                            <p><input type="date" onKeyDown={(e) => e.preventDefault()} min={min_end_date} onChange={(event) => { set_enddate(event.target.value); }} /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-md-4 col-lg-3 payFields">
                                        <div className="mb-4">
                                            <p className="total_fee_installment_discount_text">
                                                Total Hostel Fee
                                            </p>
                                            <div className="input-group discountWrpField">
                                                <span className="input-group-addon">&#8377;</span>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Hostel Fee"
                                                    // onChange={feeDetails}
                                                    className="form-control discountWrp"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    autoComplete="off" value={hostal_fee} onChange={(event) => updateHostalFeeInputValue(event)}
                                                />
                                            </div>
                                            <span style={{ fontSize: '12px' }}>Enter the hostel fee for entire duration</span>
                                        </div>
                                        <div>
                                            <div className="mb-4">
                                                <p className="total_fee_installment_discount_text">
                                                    Discount
                                                </p>
                                                <div className="input-group discountWrpField">
                                                    <span className="input-group-addon">&#8377;</span>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Discount"
                                                        // onChange={feeDetails}
                                                        className="form-control discountWrp"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        autoComplete="off" value={discount} onChange={(event) => updateDiscountInputValue(event)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <p className="total_fee_installment_text">
                                                Total Hostel Fee Payable:
                                            </p>
                                            <p className="total_fee_installment_amount">
                                                &#8377; {currencyFormatter(payableAmount, false)}
                                            </p>
                                        </div>
                                        <div className="mb-4">
                                            <p className="total_fee_installment_text">
                                                Number Of Installments
                                            </p>
                                            <div className="input-group addNumber">
                                                <div className="input-group-prepend ">
                                                    <button className="btn btn-outline-primary minusBtn" type="button" onClick={decNum}><span>-</span></button>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control installment_counter_text"
                                                    value={installments_count}
                                                    onChange={handleChange}
                                                />
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-outline-primary plusBtn" type="button" onClick={incNum}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                className="btn btn-md create_installment_button"
                                                onClick={() => {
                                                    if(!installmentLoading){
                                                        createInstallment()
                                                    }
                                                }}
                                            >
                                                Create Installment
                                                {installmentLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-9"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            }

        </div>
    )

}
export default CreateInstallmentHostel;