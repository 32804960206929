import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import AddCircle from '../../../assets/img/addCircle.svg'
import RupeeCircle from '../../../assets/img/rupee_circle.png'
import ListImage from '../../../assets/img/list-att-icon.svg'




function CashDeposit() {

    const navigate = useNavigate();

  

  return (
    <div className="containerMain">

            <div className="payment_reversal_container2">

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/cash-deposit/confirm");
                    }}
                >

                    <img
                        src={RupeeCircle}
                        alt="add course"
                        height="40px"
                        width="40px"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Confirm cash
                    </span>

                </div>

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        // dispatch({ type: SCRL_RESET_LIST })
                        navigate("/cash-deposit/add");
                    }}
                >

                    <img
                        src={AddCircle}
                        alt="subject list"
                        height="33.33px"
                        width="33.33px"

                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Add cash deposit
                    </span>

                </div>

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        // dispatch({ type: SCRL_RESET_LIST })
                        navigate("/cash-deposit/list");
                    }}
                >

                    <img
                        src={ListImage}
                        alt="cash deposit list"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        List of deposits
                    </span>

                </div>

            </div>

        </div>
  )
}

export default CashDeposit