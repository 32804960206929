import React from "react";
import { useNavigate } from "react-router-dom";

const HubTile = ({ hubName, hubImage, hubID, onEdit }) => {
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate(`/hub/${hubID}`);

        onEdit && onEdit();
    };

    return (
        <div className="hub_list_card">
            <div className="hub_list_divider1">
                <img className="hub_list_img" src={hubImage} alt="hub-list-img" />
            </div>

            <div className="hub_list_divider2">
                <span style={{ fontSize: 22, fontColor: "#0B0B0C", fontWeight: "500", width: "40%" }}>{hubName}</span>
            </div>

            <div className="hub_list_divider3">
                <div onClick={handleEdit} className="course_action_wrapper">
                    <span>Edit</span>
                </div>
            </div>
        </div>
    );
};

export default HubTile;
