import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LeftArrow from "../../../assets/img/backArrowBlack.svg";
import api from "../../common/api";
import apiUrl from "../../common/apiUrl";
import { Asterisk, debounce } from "../../../utils/helperFunctions";
import Loader from "../../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { SCRL_UPDATE_LIST } from "../../../actions/types";
import ErrorText, { InfoText } from "../../common/errorText";
import Confirmation from "./confirmMpp";
import { IconButton } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MppDetails from "./components/MppDetails";
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { errorHandler, getSubstringedText, handleFileResponse } from "../../../utils";
import SelectWithTooltip from "../../common/component/SelectWithTooltip";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import DeleteModal from "./DeleteModal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const styles = {
    dialog: {
        boxShadow: 24,
        maxWidth: 600,
        borderRadius: 4,
        background: "#fff",
    },
    paper: {
        background: "transparent",
    },
    toast: {
        display: "flex",
        alignItems: "center",
    },
};

const ToastMessage = ({ variant = "success", message = "" }) => {
    const color =
        {
            success: "#35CB00",
            error: "#FF3300",
        }[variant] || "#35CB00";

    return (
        <div className="reversal_toast" style={{ ...styles.toast, "--toast-status": color }}>
            {{
                success: <CheckCircleIcon sx={{ fill: "#fff", mr: 1 }} />,
                error: <CancelIcon sx={{ fill: "#fff", mr: 1 }} />,
            }[variant] || null}
            {message}
        </div>
    );
};

const optionsValue = {
    ViewMPPInstructions: "View MPP Instructions",
}

const TextContainer = ({ header = '', content = '' }) => {
    return <div className="mpp_gray_container">
        <span className="mpp_gray_container_text1">{header}</span>
        <span className="mpp_gray_container_text2">{content}</span>
    </div>
}

const chapters = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(item => ({ id: item, name: item }))
const initialFileResponse = {
    chapter: "",
    filename: "",
    mpp_temp_id: null,
    num_questions: null,
    subject: "",
    faculties: [],
    target_exams: [],
    chapters: chapters
}

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

function AddMpp({ showEdit = false }) {
    const navigate = useNavigate();
    const { mppID } = useParams();
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(showEdit);
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState("");

    const toggleModal = () => {
        setOpenModal(!openModal);
    };

    const handleConfirmSubmit = async () => {
        navigator.clipboard.writeText(message.substring(message.lastIndexOf("ID-") + 3, message.length - 1))
        toast(<ToastMessage message={"MPP ID copied successfully."} />);
        navigate("/dashboard");
    };

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const toggleDeleteModal = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    const handleDeleteSubmit = async () => {
        toast(<ToastMessage message={"Question file deleted succesfully"} />);
        setFileResponse(initialFileResponse);
        toggleDeleteModal();
    };

    const [fileError, setFileError] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [handleDropDown, setHandleDropDown] = useState({
        program: '',
        chapter: '',
        faculty: ''
    })

    const [fileResponse, setFileResponse] = useState(initialFileResponse)
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, value) => {
        if (e.nativeEvent.target.outerText === optionsValue.ViewMPPInstructions) {
            navigate(`/mpp/instructions`)
        }
        setAnchorEl(null);
    };

    const goBack = () => {
        navigate("/dashboard");
    };

    const handleFileDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length === 1 && rejectedFiles.length === 0) {
            // Accepted file
            setFileError("");
            uploadDocFile(acceptedFiles[0]);
        } else {
            if (rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors && rejectedFiles[0].errors.length > 0 && rejectedFiles[0].errors[0].code === "file-too-large") {
                setFileError(<span>The File size is larger than <strong>2 MB</strong>. Please reduce the File size.</span>);
            } else {
                setFileError(<span>Please upload a <strong>.doc</strong> or <strong>.docx</strong> File</span>);
            }
        }
    }, []);

    const uploadDocFile = async (file) => {
        setLoading(true)
        const formData = new FormData();
        formData.append('file', file)
        console.log('formData', formData);
        const response = await api.doUpload("POST", formData, `${apiUrl.MPP_UPLOAD}`);
        if (response?.status) {
            setFileResponse(response?.data)
        } else {
            setFileError(response?.message)
            errorHandler(response)
        }
        setLoading(false)
    }

    const canSave = () => {
        if (mppID) {
            if (handleDropDown.chapter && handleDropDown.faculty && handleDropDown.program) {
                return true
            }
        } else {
            if (fileResponse.filename) {
                return true
            }
        }
        return false;
    };

    const { getInputProps, getRootProps } = useDropzone({
        multiple: false,
        maxSize: 2.01 * 1024 * 1024, // 2 MB
        accept: {
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
                ".doc", ".docx",
            ],
        },
        onDrop: handleFileDrop,
    });

    const getMppDetails = async (mppID) => {
        setLoading(true)
        const response = await api.doFetch("GET", {}, apiUrl.MPP_GET_DETAILS + `?mpp_id=${mppID}`)
        if (response?.status) {
            let faculties = response?.data?.faculties.sort((a, b) => a.faculty_name.localeCompare(b.faculty_name)).map(item => ({ id: item.faculty_id, name: item.faculty_name }))
            let target_exams = response?.data?.target_exams.sort((a, b) => a.target_exam.localeCompare(b.target_exam)).map(item => ({ id: item.target_exam_id, name: item.target_exam }))
            // console.log('api call', faculties, target_exams)
            setFileResponse({
                ...response?.data,
                faculties,
                target_exams,
                chapters
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        if (mppID) {
            getMppDetails(mppID)
        }
    }, [])

    const createMpp = async () => {
        setSaveLoading(true)
        const params = {
            "mpp_id": mppID,
            "target_exam_id": handleDropDown.program,
            "sequence_number": handleDropDown.chapter,
            "faculty_id": handleDropDown.faculty
        }
        // console.log('params', params)
        const response = await api.doFetch("POST", params, apiUrl.MPP_CREATE)
        if (response?.status) {
            setMessage(response?.message)
            toggleModal()
        }
        setSaveLoading(false)
    }

    const onPreviewMppClick = () => {
        if (mppID) {
            createMpp()
        } else {
            navigate(`/mpp/review/${fileResponse.mpp_temp_id}`);
        }
    }

    const downloadTemplate = async () => {
        // const response = await api.doDownload("GET", {}, apiUrl.MPP_DOWNLOAD_TEMPLATE, true)
        await handleFileResponse(apiUrl.MPP_DOWNLOAD_TEMPLATE, 'Sample Mpp Template.docx')
    }

    return (
        <>
            <Confirmation
                openModal={openModal}
                closeModal={toggleModal}
                handleConfirmSubmit={handleConfirmSubmit}
                message={message}
            />

            <DeleteModal
                openModal={openDeleteModal}
                closeModal={toggleDeleteModal}
                handleConfirmSubmit={handleDeleteSubmit}
            />
            <div className="mpp_list_container">
                <div className="course_list_header">
                    <div className="d-flex align-items-center">
                        <img src={LeftArrow} alt="back" className="cursor-pointer" onClick={goBack} />
                        <span className="reversal_detail_header_text ml-2">{showEdit ? "Edit Mpp" : "Create MPP"}</span>
                    </div>

                    <div className="options">
                        {!fileResponse.filename ?
                            <div>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon
                                        style={{
                                            fontSize: "2.5rem",
                                            color: "#000000"
                                        }}
                                    />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{}}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    style={{
                                        marginLeft: "1rem",
                                        marginTop: "-0.8rem"
                                    }}
                                >
                                    {Object.values(optionsValue).map((option) => (
                                        <MenuItem
                                            key={option}
                                            value={option}
                                            selected={option === 'Pyxis'}
                                            onClick={handleClose}
                                            style={{
                                                padding: "1rem 2rem"
                                            }}
                                            className="mui_dropdown_options"
                                        >
                                            <span
                                                className={`mpp_option`}
                                            >
                                                {option}
                                            </span>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>

                {/* <div className="course_add_container"> */}
                {/* <div className="addMppContainer" style={{ display: "flex", justifyContent: 'space-between' }}>
                    <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                        <img src={LeftArrow} alt="back" className="cursor-pointer" onClick={goBack} />
                        <span className="reversal_detail_header_text ml-2">{showEdit ? "Edit Mpp" : "Create MPP"}</span>
                    </div>

                    <div className="options">
                        {!fileResponse.filename ?
                            <div>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon
                                        style={{
                                            fontSize: "2.5rem",
                                            color: "#000000"
                                        }}
                                    />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{}}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    style={{
                                        marginLeft: "1rem",
                                        marginTop: "-0.8rem"
                                    }}
                                >
                                    {Object.values(optionsValue).map((option) => (
                                        <MenuItem
                                            key={option}
                                            value={option}
                                            selected={option === 'Pyxis'}
                                            onClick={handleClose}
                                            style={{
                                                padding: "1rem 2rem"
                                            }}
                                            className="mui_dropdown_options"
                                        >
                                            <span
                                                className={`mpp_option`}
                                            >
                                                {option}
                                            </span>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                            :
                            null
                        }
                    </div>
                </div> */}
                {loading ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                        <Loader />
                    </div>
                    :
                    <>
                        {fileResponse?.filename ?
                            <>
                                <div className="addMppContainer">
                                    <span className="mpp_text_header">
                                        File Uploaded
                                    </span>

                                    <div className="mpp_file_upload_container1">
                                        <div className="mpp_file_upload_container2">
                                            <div className="mpp_tag mt-2 mr-2">
                                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={fileResponse.filename}>
                                                    <span className="mpp_tag_text">{getSubstringedText(fileResponse.filename, 25)}</span>
                                                </BootstrapTooltip>
                                            </div>
                                            <TextContainer header="Number of questions" content={fileResponse.num_questions} />
                                            <TextContainer header="Subject" content={fileResponse.subject} />
                                            <TextContainer header="Chapter" content={fileResponse.chapter} />
                                        </div>
                                        {!mppID && <DeleteForeverTwoToneIcon style={{ color: '#C00000', marginTop: 5, cursor: 'pointer' }} fontSize="large" onClick={toggleDeleteModal} />}
                                    </div>

                                    {fileError.length > 0 && <div className="mt-2" >
                                        <ErrorText
                                            text={fileError}
                                        />
                                        <span className="mpp_error_description_text"> - Error description</span>
                                    </div>}
                                </div>

                                {mppID && <div className="addMppContainer">
                                    <span className="mpp_text_header">
                                        Select MPP details
                                    </span>
                                    <div className="row display-name-overall-container">
                                        <div className={"col-md-11"}>
                                            <div className="col-md-4 course_data_wrapper" style={{ paddingLeft: 0 }}>
                                                <p className="student_filter_drop_header">Program {<Asterisk />}</p>
                                                <div className="antd_dropdown">
                                                    <Select style={{ width: "100%" }}
                                                        placeholder="All"
                                                        value={handleDropDown.program || undefined}
                                                        onChange={event => {
                                                            // alert(JSON.stringify(handleDropDown))
                                                            setHandleDropDown({ ...handleDropDown, program: event })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        showArrow
                                                        allowClear>
                                                        {fileResponse.target_exams && fileResponse.target_exams.map((exam, index) => {
                                                            return (
                                                                <Option key={index} value={exam.id}>
                                                                    {exam.name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>

                                            <div className="col-md-4 course_data_wrapper">
                                                <p className="student_filter_drop_header">MPP sequence in chapter {<Asterisk />}</p>
                                                <div className="antd_dropdown">
                                                    <Select style={{ width: "100%" }}
                                                        placeholder="All"
                                                        value={handleDropDown.chapter || undefined}
                                                        onChange={event => {
                                                            // alert(JSON.stringify(handleDropDown))
                                                            setHandleDropDown({ ...handleDropDown, chapter: event })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        showArrow
                                                        allowClear>
                                                        {fileResponse.chapters && fileResponse.chapters.map((chapter, index) => {
                                                            return (
                                                                <Option key={index} value={chapter.id}>
                                                                    {chapter.name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                {/* {
                                            facultyError && facultyError.subjects &&
                                            <ErrorText
                                                text={facultyError.subjects}
                                            />
                                        } */}
                                            </div>

                                            <div className="col-md-4 course_data_wrapper">
                                                <p className="student_filter_drop_header">Author (Faculty) name {<Asterisk />}</p>
                                                <div className="antd_dropdown">
                                                    <Select style={{ width: "100%" }}
                                                        placeholder="All"
                                                        value={handleDropDown.faculty || undefined}
                                                        onChange={event => {
                                                            // alert(JSON.stringify(handleDropDown))
                                                            setHandleDropDown({ ...handleDropDown, faculty: event })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        showArrow
                                                        allowClear>
                                                        {fileResponse.faculties && fileResponse.faculties.map((faculty, index) => {
                                                            return (
                                                                <Option key={index} value={faculty.id}>
                                                                    {faculty.name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                {/* {
                                            facultyError && facultyError.reviewer_type &&
                                            <ErrorText
                                                text={facultyError.reviewer_type}
                                            />
                                        } */}
                                            </div>
                                        </div>
                                    </div>

                                </div>}

                                {/* <div className="addMppContainer">
                                    <span className="mpp_text_header">
                                        Select MPP Details
                                    </span>
                                    <MppDetails />
                                </div> */}

                                {(handleDropDown.chapter) && <div>
                                    <div className="mt-3">
                                        <span className="mpp_text_header">
                                            File Name visible to Student
                                        </span>
                                    </div>

                                    <div className="mpp_tag mt-2">
                                        <span className="mpp_tag_text">{fileResponse.chapter} - {handleDropDown.chapter}</span>
                                    </div>
                                </div>}

                                <div className="button_row_add_mpp mt-5">
                                    <div />

                                    <button
                                        // disabled={!canSave() || saveLoading}
                                        disabled={!canSave() || saveLoading}
                                        onClick={onPreviewMppClick}
                                        className={"btn btn-md course_add_next"}
                                        type="button"
                                    >
                                        {mppID ? "Save MPP" : "Preview MPP"}
                                        {saveLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <div className="addMppContainer">
                                    <p className="student_filter_drop_header">
                                        <strong>{showEdit ? "Update question template" : "Upload question template"}</strong> <Asterisk />
                                    </p>

                                    <div className="filesBox add_hub" {...getRootProps()}>
                                        <div className="inputFile"></div>
                                        <input className="fileup" {...getInputProps()} />
                                        <div className="fileUploadHelperText hub">Drag {"&"} drop or click to choose a file</div>
                                        <div style={{ marginTop: 14 }}>
                                            Max File size is <strong>2 MB</strong>
                                        </div>
                                    </div>

                                    {
                                        fileError &&
                                        <div>
                                            <ErrorText
                                                text={fileError}
                                            />
                                        </div>
                                    }
                                </div>

                                <div className="addMppContainer">
                                    <p className="student_filter_drop_header">
                                        <strong>Download Template</strong>
                                    </p>
                                    <button
                                        className="download_template_button mt-2"
                                        style={{ fontSize: 16 }}
                                        onClick={downloadTemplate}
                                    >
                                        Template
                                        <svg style={{ marginLeft: 6 }} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                                            <path d="M0 17.5H18V19.5H0V17.5ZM10 11.672L16.071 5.6L17.485 7.014L9 15.5L0.515 7.015L1.929 5.6L8 11.67V0.5H10V11.672Z" fill="black" />
                                        </svg>
                                    </button>
                                </div>

                                <div className="addMppContainer">
                                    <div className="button_row_add_mpp">
                                        <div />

                                        <button
                                            disabled={!canSave() || saveLoading}
                                            onClick={onPreviewMppClick}
                                            className={"btn btn-md course_add_next"}
                                            type="button"
                                        >
                                            {mppID ? "Save MPP" : "Preview MPP"}
                                            {saveLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                                        </button>
                                    </div>
                                </div>
                            </>}
                    </>}
                {/* </div> */}
            </div>
        </>
    );
}

export default AddMpp;

