import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import LeftArrow from "../../../assets/img/backArrowBlack.svg";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import ReviewMpp from '../mppScreens/ReviewMpp'
import ViewQuestion from '../mppScreens/components/ViewQuestion'
import { useParams } from "react-router-dom";
import Loader from '../../common/Loader';
import { errorHandler, getSubstringedText, handleFileResponse } from '../../../utils';
import Empty from "../../../assets/img/empty_student.svg";

let options = [
    "Download word file",
    "Schedule History",
];

const limit = 1;

const MppView = () => {
    const { mppID } = useParams();
    // const  mppID  = 8339
    const [mppName, setMppName] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(0);

    // const getMpp = async () => {
    //     setLoading(true)
    //     let url = `${ApiUrl.MPP}?mpp_id=${mppID}`;
    //     const data = await Api.doFetch("GET", {}, url);
    //     if (data.status) {
    //         let payload = data.data;
    //         setMppData(payload)
    //     }
    //     window.scrollTo(0, 0);
    //     setLoading(false)
    // }

    const getMppDetails = async ({ SKIP = skip, LIMIT = limit }) => {
        setLoading(true)
        const response = await Api.doFetch("GET", {}, ApiUrl.MPP + `?mpp_id=${mppID}&skip=${SKIP}&limit=${LIMIT}`)
        if (response?.status) {
            const questionList = response?.data?.questions
            setMppName(response?.data?.mpp_name)
            setTotal(response?.data?.total)
            if (questionList.length > 0) {
                setCurrentQuestion(questionList[0])
            } else {
                setCurrentQuestion({})
            }
        } else {
            errorHandler(response)
        }
        window.scrollTo(0, 0);
        setLoading(false)
    }

    useEffect(() => {
        getMppDetails({ SKIP: skip, LIMIT: limit })
    }, [skip])

    // useEffect(() => {
    //     if (mppID) {
    //         getMpp()
    //     }
    // }, [mppID])

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, value) => {
        if (e.nativeEvent.target.outerText === "Download word file") {
            DownloadWordFile(mppID, mppID)
        } else if (e.nativeEvent.target.outerText === "Schedule History") {
            navigate(`/mpp/schedule-history/${mppID}`)
        }
        setAnchorEl(null);
    };

    const DownloadWordFile = async (mppID, name) => {
        // const response = await Api.doDownload("GET", {}, `${ApiUrl.MPP}/download/original/${mppID}`, true)
        await handleFileResponse(`${ApiUrl.MPP}/download/original/${mppID}`, name + '.docx')
    }

    const handlePrevious = () => {
        if (skip == 0) {
            navigate(`/mpp/add`)
        } else {
            setSkip(skip => skip - 1)
        }
    }

    const handleNext = () => {
        if (skip + 1 == total) {
            // navigate(`/mpp/assign/${mppID}`)
            navigate(`/mpp/assign`, { state: { mppIds: [mppID] } })
        } else {
            setSkip(skip => skip + 1)
        }
    }



    return (<>
        <div className="mpp_view_header">
            <div className="course_list_header mb-0">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/mpp/list");
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">{getSubstringedText(mppName, 130) || ''}</span>
                </div>
                {currentQuestion?.question && <div>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon
                            style={{
                                fontSize: "2.5rem",
                                color: "#000000"
                            }}
                        />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{}}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        style={{
                            marginLeft: "1rem",
                            marginTop: "-0.8rem"
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem
                                key={option}
                                value={option}
                                selected={option === 'Pyxis'}
                                onClick={handleClose}
                                style={{
                                    padding: "1rem 2rem"
                                }}
                                className="mui_dropdown_options"
                            >
                                <span
                                    className={"student_detail_option_reset_pw"}
                                >
                                    {option}
                                </span>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>}
            </div>

            {loading ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                    <Loader />
                </div>
                :
                currentQuestion?.question ?
                    <ViewQuestion question={currentQuestion} />
                    :
                    <div className="student_search_empty_container mt-5">
                        <div className="student_search_empty_img_container">
                            <img
                                src={Empty}
                                alt="not found"
                                className="student_search_empty_img"
                            />
                        </div>

                        <p className="student_search_empty_header">
                            MPP does not have any questions
                        </p>
                    </div>}

        </div>
        {(!loading && currentQuestion?.question) && <div className="button_row_add_mpp mt-3 mb-3 ml-3 mr-3">
            {skip != 0 && <button className="btn btn-md mpp_previous_button" type="button"
                onClick={handlePrevious}
            >
                Previous
            </button>}
            <div />

            <button
                onClick={handleNext}
                className={"btn btn-md course_add_next"}
                type="button"
            >
                {skip + 1 == total ? "Schedule" : "Next"}
                {/* {saveLoading && <span className="ml-2 spinner spinner-white mr-2"></span>} */}
            </button>
        </div>}
    </>

    )
}

export default MppView