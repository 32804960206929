import React from "react";
import ErrorText from "../../common/errorText";
import IndianFlag from "../../../assets/img/indian_flag.svg";
import DropdownImg from "../../../assets/img/drop_down.svg";
import { Select } from "antd";
import { Asterisk } from "../../../utils/helperFunctions";

const { Option } = Select;

const Step3 = ({
	data = {},
	handleData,
	error,
	handleError,
	// editView = false,
}) => {
	const { parentName, parentMobile, relation } = data;

	const { parentNameError, parentMobileError, relationError } = error;

	const handleChange = (payload, errorPayload) => {
		if (payload) {
			handleData({
				...data,
				...payload,
			});
		}
		if (errorPayload) {
			handleError({
				...error,
				...errorPayload,
			});
		}
	};

	return (
		<>
			<div>
				<p className="student_filter_drop_header">
					Parent Name <Asterisk />
				</p>

				<input
					className={`change_pw_input ${parentNameError ? "change_pw_input_error" : ""}`}
					placeholder="Enter parent name"
					value={parentName}
					onChange={(e) => {
						handleChange(
							{
								parentName: e.target.value,
							},
							{
								parentNameError: "",
							}
						);
					}}
				/>
				{parentNameError && <ErrorText text={parentNameError} />}
			</div>

			<div className="mt-3 antd_dropdown">
				<p className="student_filter_drop_header">
					Relation <Asterisk />
				</p>

				<Select
					className="full-width"
					value={relation || undefined}
					placeholder="Select relation"
					onChange={(value) => {
						handleChange(
							{
								relation: value || "",
							},
							{
								relationError: "",
							}
						);
					}}
					showArrow
					allowClear
					showSearch
				>
					<Option value={"Father"}>Father</Option>
					<Option value={"Mother"}>Mother</Option>
					<Option value={"Others"}>Others</Option>
				</Select>
				{relationError && <ErrorText text={relationError} />}
			</div>

			<div className="mt-3">
				<p className="student_filter_drop_header student_text_bottom">
					Parents Mobile Number <Asterisk />
				</p>

				<img src={IndianFlag} alt="indian" className="student_mobile_indian_img" />
				<img src={DropdownImg} alt="dropdown" className="student_mobile_drop_img" />
				<div className="d-flex">
					<input className={`change_pw_input student_add_mobile_indian`} value={"+91"} disabled />

					<input
						className={`change_pw_input student_add_mobile_input ${parentMobileError ? "change_pw_input_error" : ""}`}
						placeholder="Enter number"
						value={parentMobile}
						onChange={(e) => {
							if (e.target.value.length <= 10) {
								handleChange(
									{
										parentMobile: e.target.value.replace(/[^0-9]/g, ""),
									},
									{
										parentMobileError: "",
									}
								);
							}
							handleChange(null, {
								parentMobileError: "",
							});
						}}
					/>
				</div>
				{parentMobileError && <ErrorText text={parentMobileError} />}
			</div>
		</>
	);
};

export default Step3;
