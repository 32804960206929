import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";

const Confirmation = ({
    open,
    close,
    batchName = "",
    course = "",
    centre = "",
    startDate = "",
    createBatchClickHandler,
    submitLoading
}) => {
    const style = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        maxWidth: 500,
        width: "90%",
        padding: "3rem",
        borderRadius: 4,
    };


    return (
        <Modal open={open} onClose={close} className="payment_reversal_cofirmation_modal">
            <Box sx={style}>
                <p className="reversal_accept_text">Are you sure you want to create this batch? </p>

                <div className="mb-3 modal_save_confirmation_wrapper">
                    <div className="modal_save_confirmation_container">
                        <p className="modal_save_confimation_header">Batch name</p>
                        <span className="modal_save_confimation_value">{batchName}</span>
                    </div>
                    <div className="modal_save_confirmation_container">
                        <p className="modal_save_confimation_header">Course name</p>
                        <span className="modal_save_confimation_value">{course}</span>
                    </div>
                    <div className="modal_save_confirmation_container">
                        <p className="modal_save_confimation_header">Associated centre</p>
                        <span className="modal_save_confimation_value">{centre}</span>
                    </div>
                    <div className="modal_save_confirmation_container">
                        <p className="modal_save_confimation_header">Start date</p>
                        <span className="modal_save_confimation_value">{startDate ? moment(startDate).format("DD-MM-YYYY") : ""}</span>
                    </div>
                </div>

                <div className="mt-3" style={{ float: "right" }}>
                    <button className="btn btn-md student_detail_no" onClick={close}>
                        No
                    </button>
                    <button
                        className="btn btn-md student_detail_password_confirm ml-2"
                        onClick={() => {
                            if (!submitLoading) {
                                close();
                                createBatchClickHandler();
                            } else {
                                return;
                            }
                        }}
                    >
                        Create
                        {submitLoading && <span className="ml-2 spinner spinner-white mr-3"></span>}
                    </button>
                </div>
            </Box>
        </Modal>
    );
}

export default Confirmation