import React, { useRef, useState } from "react";
import { Asterisk } from "../../../utils/helperFunctions";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Swal from "sweetalert2";
import { dropdownSizeWeb, dropdownSizeMobile } from "../../../utils/helperFunctions";
// import Select from "@mui/material/Select";
import { createTheme, MenuItem, ThemeProvider, useMediaQuery } from "@mui/material";
import { Select } from "antd";
const { Option } = Select;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: { xl: 600, lg: 600, md: 450, sm: "85%", xs: "85%" },
    padding: "3rem 2rem",
};

const style2 = {
    fontFamily: "Montserrat",
    width: { xl: "55%", lg: "55%", md: "65%", sm: "63%", xs: "60%" },
    height: "100%",
};

const Reversal = ({ reversalID, refresh, mobile = false }) => {
    const filterThemeWeb = createTheme({
        typography: {
            htmlFontSize: dropdownSizeWeb,
        },
    });

    const filterThemeMobile = createTheme({
        typography: {
            htmlFontSize: dropdownSizeMobile,
        },
    });

    const isMobile = useMediaQuery("(max-width:600px)");

    const [loading, setLoading] = useState(false);

    const [reversalReasonDropdown, setreversalReasonDropdown] = useState("");
    // const [reversalReasonErrorDropdown, setReversalReasonErrorDropdown] = useState("");

    const [reversalReason, setReversalReason] = useState("");
    const [reversalReasonError, setReversalReasonError] = useState("");

    const [reversalModal, setReversalModal] = useState(false);

    const errorTimeoutRef = useRef(null);

    const openReversalModal = () => {
        if (reversalReason.trim()) {
            setReversalModal(true);
        } else {
            reversalReasonDropdown === "Others"
                ? setReversalReasonError("Please type your reason for reversal")
                : setReversalReasonError("Please select your reason for reversal");
        }
    };

    const closeReversalModal = () => {
        setReversalModal(false);
        refresh();
    };

    const initiateReversal = async () => {
        setLoading(true);
        setReversalModal(false);

        const response = await Api.doFetch(
            "POST",
            {
                receipt_ack_id: reversalID,
                counsellor_remarks: reversalReason,
            },
            ApiUrl.INITIATE_REVERSAL
        );

        setLoading(false);

        if (response && response.status) {
            Swal.fire("Success", response.message, "success");
            refresh(true);
        } else if (response && response.payload && response.payload.Error) {
            Swal.fire("Error", response.payload.Error, "error");
        } else if (response && response.message) {
            Swal.fire("Error", response.message, "error");
        }
    };

    return (
        <div className="reversal_reason_container">
            <Modal open={reversalModal} onClose={closeReversalModal} className="reversal_confirmation_container">
                <Box sx={style}>
                    <p className="reversal_accept_text">Are you sure you want to initiate reversal?</p>

                    <div className="mt-5">
                        <button className="btn btn-danger btn-md reversal_decline_button" onClick={closeReversalModal}>
                            Cancel
                        </button>
                        <button className="btn btn-md reversal_accept_button ml-2" onClick={initiateReversal}>
                            Confirm
                        </button>
                    </div>
                </Box>
            </Modal>
            <p className="reversal_reversal_header_1">Select Reason</p>

            <div className="reversal_container_options">
                <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placeholder="Select your reason here"
                    showArrow
                    className="reversal_reason_dropdown"
                    value={reversalReasonDropdown || undefined}
                    onChange={(value) => {
                        setreversalReasonDropdown(value);
                        value === "Others" ? setReversalReason("") : setReversalReason(value);
                        setReversalReasonError("");
                    }}
                >
                    <Option value="Double entry by mistake">Double entry by mistake</Option>
                    <Option value="Wrong amount entry">Wrong amount entry</Option>
                    <Option value="Others">Others</Option>
                </Select>

                {reversalReasonDropdown === "Others" ? null : (
                    <button
                        className="btn btn-md reversal_initiate_button ml-2 initiate_reversal_dropdown"
                        onClick={() => {
                            if (!loading) {
                                openReversalModal();
                            }
                        }}
                        disabled={!reversalReasonDropdown}
                    >
                        {mobile ? "Initiate" : "Initiate Reversal"}
                        {loading && <span className="ml-2 spinner spinner-white mr-3"></span>}
                    </button>
                )}
            </div>

            {reversalReasonDropdown === "Others" && (
                <>
                    <p className="reversal_reversal_header">
                        Reason for reversal
                        <Asterisk />
                    </p>
                    <div className="d-flex">
                        <textarea
                            className="reversal_reason_textarea"
                            placeholder="Type your reason here"
                            value={reversalReason}
                            onChange={(e) => {
                                if (e.target.value.length <= 200) {
                                    setReversalReason(e.target.value);
                                    setReversalReasonError("");
                                } else {
                                    setReversalReasonError("Max limit of 200 characters reached");
                                    if (errorTimeoutRef.current !== null && errorTimeoutRef.current <= 200) {
                                        clearTimeout(errorTimeoutRef.current);
                                    }
                                    errorTimeoutRef.current = setTimeout(() => {
                                        setReversalReasonError("");
                                    }, 1200)
                                }
                            }}
                        />
                        <button
                            className="btn btn-md reversal_initiate_button ml-2"
                            onClick={() => {
                                if (!loading) {
                                    openReversalModal();
                                }
                            }}
                        >
                            {mobile ? (loading ? "" : "Initiate") : "Initiate Reversal"}
                            {loading && <span className="ml-2 spinner spinner-white mr-3"></span>}
                        </button>
                    </div>
                </>
            )}
            {reversalReasonError && <p className="reject_reason_error">{reversalReasonError}</p>}
        </div>
    );
};

export default Reversal;
