import React from "react";
import { Asterisk } from "../../../utils/helperFunctions";
import { useSelector } from "react-redux";
import ErrorText from "../../common/errorText";
import { Select } from "antd";

const { Option } = Select;
const classOptions = ["V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XII Pass"];

const Step2 = ({ data = {}, handleData, error, handleError, courseList, batchList }) => {
	const { centerList } = useSelector((state) => state.auth);

	const { centre, course, batch, leadID, standard } = data;

	const { centreError, courseError, batchError, leadIDError, standardError } = error;

	const handleChange = (payload, errorPayload) => {
		if (payload) {
			handleData({
				...data,
				...payload,
			});
		}
		if (errorPayload) {
			handleError({
				...error,
				...errorPayload,
			});
		}
	};

	const avaialableStandards = courseList?.map((data) => data.course_starting_standard) ?? [];

	return (
		<>
			<div className="antd_dropdown">
				<p className="add_course_drop_header">
					Select Center <Asterisk />
				</p>

				<Select
					className="full-width"
					value={centre || undefined}
					placeholder="Select Centre"
					onChange={(value) => {
						handleChange(
							{
								centre: value || "",
							},
							{
								centreError: "",
							}
						);
					}}
					showArrow
					allowClear
					showSearch
					optionFilterProp="children"
				>
					{centerList &&
						centerList.map((centreData, index) => {
							return (
								<Option value={centreData.school_id} key={index}>
									{centreData.school_name}
								</Option>
							);
						})}
				</Select>
				{centreError && <ErrorText text={centreError} />}
			</div>

			<div className="mt-3 antd_dropdown">
				<p className="student_filter_drop_header">
					Class standard (course starting) <Asterisk />
				</p>

				<Select
					className="full-width"
					value={standard || undefined}
					placeholder="Select standard"
					onChange={(value) => handleChange({ standard: value || null, course: null, batch: null }, { standardError: "" })}
					showArrow
					allowClear
					showSearch
					aria-autocomplete="none"
				>
					{centre &&
						classOptions
							.filter((opt) => avaialableStandards.find((stand) => stand === opt))
							.map((options, index) => {
								return (
									<Option key={index} value={options}>
										{options}
									</Option>
								);
							})}
				</Select>
				{standardError && <ErrorText text={standardError} />}
			</div>

			<div className="mt-3 antd_dropdown">
				<p className="add_course_drop_header">
					Course Name <Asterisk />
				</p>

				<Select
					className="full-width"
					value={course || undefined}
					placeholder="Select Course Name"
					onChange={(value) => {
						handleChange(
							{
								course: value || "",
							},
							{
								courseError: "",
							}
						);
					}}
					showArrow
					allowClear
					showSearch
					optionFilterProp="children"
				>
					{courseList &&
						standard &&
						courseList
							.filter((course) => course.course_starting_standard === standard)
							.map((course, index) => {
								return (
									<Option value={course.id} key={index}>
										{course.class_name}
									</Option>
								);
							})}
				</Select>
				{courseError && <ErrorText text={courseError} />}
			</div>

			<div className="mt-3 antd_dropdown">
				<p className="add_course_drop_header">
					Batch Name <Asterisk />
				</p>

				<Select
					className="full-width"
					value={batch || undefined}
					placeholder="Select Batch Name"
					onChange={(value) => {
						handleChange(
							{
								batch: value || "",
							},
							{
								batchError: "",
							}
						);
					}}
					showArrow
					allowClear
					showSearch
					optionFilterProp="children"
				>
					{batchList &&
						batchList.map((batchData, index) => {
							return (
								<Option value={batchData.id} key={index}>
									{batchData.section_name}
								</Option>
							);
						})}
				</Select>
				{batchError && <ErrorText text={batchError} />}
			</div>

			<div className="mt-3">
				<p className="student_filter_drop_header">Lead ID</p>

				<input
					className={`change_pw_input ${leadIDError ? "change_pw_input_error" : ""}`}
					placeholder="Add Lead ID here"
					value={leadID}
					onChange={(e) => {
						handleChange(
							{
								leadID: e.target.value,
							},
							{
								leadIDError: "",
							}
						);
					}}
				/>
				{leadIDError && <ErrorText text={leadIDError} />}
			</div>
		</>
	);
};

export default Step2;
