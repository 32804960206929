import React, {  useState } from "react";
import ListAttImage from "../../../assets/img/list-att-icon.svg";
import LiveClassModal from "./selectLivClassDialoge";

export default function Attendance() {
    const [openDialogue, setOpenDialogue] = useState(false);
    return (
        <div className="containerMain">
            <div className="payment_reversal_container">
                <div
                    className="payment_reversal_container_history"
                    data-toggle="modal"
                    data-target="#markAttModal"
                    onClick={() => {
                        setOpenDialogue(true);
                    }}
                >
                    <img src={ListAttImage} alt="history" className="mb-3" />

                    <span className="payment_reversal_container_text">
                        Mark Attendance
                    </span>
                </div>

                <div className="payment_reversal_container_history">
                    <img src={ListAttImage} alt="history" className="mb-3" />

                    <span className="payment_reversal_container_text">
                        List of Attendance
                    </span>
                </div>
            </div>
            {openDialogue && (
                <div>
                    <LiveClassModal
                        isOpen={openDialogue}
                        close={() => {
                            setOpenDialogue(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
}
