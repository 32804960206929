import React from "react";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { RESET_STUDENT_SEARCH } from "../../../actions/types";
import { useMediaQuery } from "@mui/material";
import Api from "../../common/api";
import { segmentEvents } from "../../../utils/constants";

const FilterInfo = ({
    header, value
}) => {

    if(!value){
        return null;
    }

    return (
        <div className="ml-3">
            <p className="filter_applied_header">
                {header}
            </p>
            <div className="filter_applied_value_container">
                <p className="filter_applied_value">
                    {value}
                </p>
            </div>
        </div>
    );
};

const MoreFilter = ({ resetSearchRef }) => {

    const dispatch = useDispatch();

    const { centerList } = useSelector(state => state.auth);
	const isMobile = useMediaQuery("(max-width:600px)");

    const {
        searchText,

        selectedCentre,
        selectedCourse,
        selectedBatch,
        selectedState,
        selectedCity,

        courseList,
        batchList,
    } = useSelector(state => state.studentSearch);

    return (
        <div className={`student_search_filter_applied_list${isMobile ? " is-mobile" : ""}`}>
            {
                selectedCentre &&
                <FilterInfo 
                    header = {"Centre"}
                    value = {centerList && centerList.findIndex(x => x.school_id === selectedCentre) >= 0 ? centerList[centerList.findIndex(x => x.school_id === selectedCentre)].school_name : ""}
                />
            }
            {
                selectedCourse &&
                <FilterInfo 
                    header = {"Course"}
                    value = {courseList && courseList.findIndex(x => x.id === selectedCourse) >= 0 ? courseList[courseList.findIndex(x => x.id === selectedCourse)].class_name : ""}
                />
            }
            {
                selectedBatch &&
                <FilterInfo 
                    header = {"Batch"}
                    value = {batchList && batchList.findIndex(x => x.id === selectedBatch) >= 0 ? batchList[batchList.findIndex(x => x.id === selectedBatch)].section_name : ""}
                />
            }
            {
                selectedState &&
                <FilterInfo 
                    header = {"State"}
                    value = {selectedState}
                />
            }
            {
                selectedCity &&
                <FilterInfo 
                    header = {"City"}
                    value = {selectedCity}
                />
            }
            <div className="ml-3 cursor-pointer filter_applied_clearall_container_wrapper">
                <div className="filter_applied_clearall_container">
                    <p 
                        className="filter_applied_value"
                        onClick={() => {
                            if (!searchText.trim()) {
                                resetSearchRef && resetSearchRef();
                            }
                            Api.trackEvent(segmentEvents.STUDENT.CLICKED_STUDENT_SEARCH_CLEAR);
                            dispatch({ type: RESET_STUDENT_SEARCH, payload: { searchText, searchFilterApplied: !!searchText.trim() } });
                        }}
                    >
                        Clear All
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MoreFilter;