import React from "react";
import { currencyFormatter } from "../../../utils/helperFunctions";
import { useMediaQuery } from "@mui/material";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";

function Step4({
	centre,
	batch,
	feeDetails,
	payableAmount,
	discount,
	batchList,
	isLoading,
	setDiscount,
	setPayableAmount,
	setInstallmentsCount,
	installmentsCount,
}) {
	const renderResponsiveCards = useMediaQuery("(max-width: 900px)");
	const { centerList } = useSelector((state) => state.auth);
	const batchDetails = batchList.find((b) => b.id === batch);
	const centerDetails = centerList.find((c) => c.school_id === centre);

	const updateInputValue = (event) => {
		if (feeDetails.fee_amount >= event.target.value) {
			setDiscount(event.target.value);
			setPayableAmount(feeDetails.fee_amount - event.target.value);
		}
	};

	let incNum = () => {
		if (installmentsCount < 10) {
			setInstallmentsCount(Number(installmentsCount) + 1);
		}
	};
	let decNum = () => {
		if (installmentsCount > 1) {
			setInstallmentsCount(installmentsCount - 1);
		}
	};

	let handleChange = (e) => {
		setInstallmentsCount(e.target.value);
	};

	return (
		<>
			<div className={`student_course_detail-payment mb-4${renderResponsiveCards ? " responsive" : ""}`}>
				<div className="student_course_detail_centre_flex center">
					<span className="student_course_detail_header">Centre:</span>
					<p className="student_course_detail_value">{centerDetails.school_name}</p>
				</div>
				<div className="student_course_detail_others_flex course">
					<span className="student_course_detail_header">Course:</span>
					<p className="student_course_detail_value">{batchDetails?.class_name}</p>
				</div>
				<div className="student_course_detail_others_flex batch">
					<span className="student_course_detail_header">Batch:</span>
					<p className="student_course_detail_value">{batchDetails?.section_name}</p>
				</div>
			</div>

			<div className="payFieldsOuter tabWrap mt-5">
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-6 col-md-4 col-lg-3 payFields">
							<div className="mb-4">
								<p className="total_fee_installment_text">Total Fee:</p>
								<p className="total_fee_installment_amount">&#8377; {currencyFormatter(feeDetails?.fee_amount, false)}</p>
								{isLoading ? <ReactLoading type="cylon" color="#09AEE5" /> : ""}
							</div>
							<div>
								<div className="mb-4">
									<p className="total_fee_installment_discount_text">Discount</p>
									<div className="input-group discountWrpField">
										<span className="input-group-addon">&#8377;</span>
										<input
											type="text"
											placeholder="Enter Discount"
											// onChange={feeDetails}
											className="form-control discountWrp"
											onKeyPress={(event) => {
												if (!/[0-9]/.test(event.key)) {
													event.preventDefault();
												}
											}}
											autoComplete="off"
											value={discount ? discount : ""}
											onChange={(event) => updateInputValue(event)}
										/>
									</div>
								</div>
							</div>
							<div className="mb-4">
								<p className="total_fee_installment_text">Total Payable Fee:</p>
								<p className="total_fee_installment_amount">&#8377; {currencyFormatter(payableAmount, false)}</p>
							</div>
							<div className="mb-4">
								<p className="total_fee_installment_text">Number Of Installments</p>
								<div className="input-group addNumber">
									<div className="input-group-prepend ">
										<button className="btn btn-outline-primary minusBtn" type="button" onClick={decNum}>
											<span>-</span>
										</button>
									</div>
									<input
										type="text"
										className="form-control installment_counter_text"
										value={installmentsCount}
										onChange={handleChange}
									/>
									<div className="input-group-prepend">
										<button className="btn btn-outline-primary plusBtn" type="button" onClick={incNum}>
											+
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-9"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Step4;
