import React from "react";
import { Asterisk } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import { Select } from 'antd';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const { Option } = Select;

const AcademicDetails = ({
    view,
    setBatchData, 
    batchData, 
    centreListMetaData,
    classroomListMetaData ,
    batchError,
    setBatchError,
    centreNameHandler,
    selectedCentreName,
    defaultCenterName,
    forCenter = false,
}) => {
    const selectedCentreChangeHandler = (value) => {
        setBatchError({
            ...batchError,
            associated_centre_id: ""
        })
        centreNameHandler(value)
        setBatchData({ ...batchData, associated_centre_id: value });
    };

    const selectedClassroomChangeHandler = (value) => {
        setBatchData({ ...batchData, default_classroom_id: value });
    };
//     course_add_header_container
// course_add_data_container
    return (
        <>

            {!forCenter && <div className="course_add_header_container">
                Academic delivery details
            </div>}

            <div className={forCenter ? "academic_details_data_container" : "course_add_data_container"}>

            {forCenter && 
                <div className="academic_details_header_container">
                    Academic delivery details
                </div>
            }

                <div className="row" >

                    <div className={view ? "col-md-10" : "col-md-11"}>

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">
                                Associated centre { !view ? <Asterisk /> : "" }
                            </p>

                            {
                                view ?
                                <p className="course_view_text_data">
                                    {selectedCentreName ? selectedCentreName : "-"}
                                </p>
                                :
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select centre"
                                    optionFilterProp="children"
                                    showArrow
                                    allowClear
                                    showSearch
                                    value={batchData.associated_centre_id}
                                    onChange={selectedCentreChangeHandler}
                                >
                                    {
                                        centreListMetaData.map((options, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    value={options.id}
                                                >{options.name}</Option>
                                            )
                                        })
                                    }

                                </Select>
                                
                            }
                            {
                                batchError.associated_centre_id &&
                                <ErrorText
                                    text={batchError.associated_centre_id}
                                />
                            }

                        </div>

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">
                                Default classroom name 
                            </p>

                            {
                                view ?
                                <p className="course_view_text_data">
                                    {defaultCenterName? defaultCenterName : "-"}
                                </p>
                                :
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select default classroom name"
                                    value={batchData.default_classroom_id}
                                    onChange={selectedClassroomChangeHandler}
                                    showArrow
                                    allowClear
                                >
                                    {
                                        classroomListMetaData.map((options, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    value={options.id}
                                                >{options.name}</Option>
                                            )
                                        })
                                    }

                                </Select>
                            }
                        </div>

                        {
                            view &&
                            <div className="col-md-4 course_data_wrapper">
                                {
                                    view ?
                                    <>
                                        <p className="student_filter_drop_header">
                                            Platform usage
                                        </p>
                                        <p className="course_view_text_data">
                                            {batchData.uses_mycrm ? "Yes" : "No"}
                                        </p>
                                    </>
                                    :

                                    <div className="add_course_resource_container">
                                        <span className="add_course_resource_text ">
                                            Will the batch use our platform?
                                        </span>

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={batchData.uses_mycrm} 
                                                    color={batchData.uses_mycrm ? "success" : "error"}
                                                    style={{color : batchData.uses_mycrm ? "" : "#BC0000"}}
                                                />
                                            }
                                            onClick={() => {
                                                setBatchData({
                                                    ...batchData,
                                                    uses_mycrm: !batchData.uses_mycrm,
                                                });
                                            }}
                                            className="student_course_toggle_text"
                                            style={{
                                                marginTop: "0.5rem"
                                            }}
                                        />

                                        <span className="add_course_resource_text">
                                            {batchData.uses_mycrm ? "Yes" : "No"}
                                        </span>
                                    </div>
                                }

                            </div>
                        }

                    </div>

                </div>

                <div className="row add_course_division" >

                    <div className={view ? "col-md-10" : "col-md-11"}>

                        <div className="col-md-4 course_data_wrapper">
                            {
                                view ?
                                <>
                                    <p className="student_filter_drop_header">
                                        Is it school integrated program?
                                    </p>
                                    <p className="course_view_text_data">
                                        {batchData.is_school_integrated ? "Yes" : "No"}
                                    </p>
                                </>
                                :

                                <div className="add_course_resource_container">
                                    <span className="add_course_resource_text ">
                                        Will the batch use our platform?
                                    </span>

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={batchData.uses_mycrm} 
                                                color={batchData.uses_mycrm ? "success" : "error"}
                                                style={{color : batchData.uses_mycrm ? "" : "#BC0000"}}
                                            />
                                        }
                                        onClick={() => {
                                            setBatchData({
                                                ...batchData,
                                                uses_mycrm: !batchData.uses_mycrm,
                                            });
                                        }}
                                        className="student_course_toggle_text"
                                        style={{
                                            marginTop: "0.5rem"
                                        }}
                                    />

                                    <span className="add_course_resource_text">
                                        {batchData.uses_mycrm ? "Yes" : "No"}
                                    </span>
                                </div>
                            }

                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};

export default AcademicDetails;