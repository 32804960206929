import React from "react";
import AddBatch from "../addBatch";

const EditBatch = (props) => {

    return (
        <AddBatch 
            {...props}
            defaultView = {true}
        />
    );
};

export default EditBatch;