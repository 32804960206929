import Api from "../components/common/api";
import ApiUrl from "../components/common/apiUrl";
import store from "../store";
import { HANDLE_AUTH_DETAILS, HANDLE_COMMON } from "../actions/types";
import Swal from "sweetalert2";
import moment from "moment";

export const currencyFormatter = (input, needSymbol = true) => {
	let options = needSymbol
		? {
				style: "currency",
				currency: "INR",
				minimumFractionDigits: 0,
		  }
		: {
				minimumFractionDigits: 0,
		  };

	if (input) {
		return input.toLocaleString("en-IN", options);
	} else {
		return (0).toLocaleString("en-IN", options);
	}
};

export const Asterisk = ({ color = "red" }) => {
    return <span style={{ color }}>*</span>
};

export const getAuthDetails = () => {
	getUserPages();
	getUserSchoolRoleList();
	getUserType();
};

export const getUserPages = async () => {
    const response = await Api.doFetch("GET", {}, `${ApiUrl.USER_PAGES}?user_id=${Api.getUserID()}`);

    if (response) {
        const pages = response && response.payload && response.payload.pages && response.payload.pages.length > 0 ? response.payload.pages : [];
        pages.push({ page: 'mpp' })
        store.dispatch({ type: HANDLE_AUTH_DETAILS, payload: { pages, permissionApiCalled: true } });
    }
}

export const getUserSchoolRoleList = async () => {

    const response = await Api.doFetch("GET", {}, `${ApiUrl.USER_ROLE_CENTER}?user_id=${Api.getUserID()}`);

    if (response) {
        const centerList = response && response.payload && response.payload.schools && response.payload.schools.length > 0 ? response.payload.schools : [];
        const roleList = response && response.payload && response.payload.roles && response.payload.roles.length > 0 ? response.payload.roles : [];

        store.dispatch({
            type: HANDLE_AUTH_DETAILS,
            payload: {
                centerList: centerList.filter(x => x && x.school_name && x.school_name.trim()).sort(function (a, b) {
                    var nameA = a.school_name.toLowerCase(),
                        nameB = b.school_name.toLowerCase();
                    if (nameA < nameB)
                        //sort string ascending
                        return -1;
                    if (nameA > nameB) return 1;
                    return 0; //default return value (no sorting)
                }),
                roleList: roleList.map(x => {
                    if (x?.role_name === "dtp") {
                        return {
                            ...x,
                            role_name: "DTP"
                        }
                    }

                    return { ...x }
                }),
                roleApiCalled: true,
            },
        });
    }
}

export const getMyclassroomCentre = async () => {
	const response = await Api.doFetch("GET", {}, ApiUrl.MYCLASSROOM_CENTRE);

	if (response && response.status) {
		let myclassroomCentre = response && response.data && response.data.length > 0 ? response.data : [];
		store.dispatch({ type: HANDLE_COMMON, payload: { myclassroomCentre } });
	}
};

export const getUserType = async () => {

    const response = await Api.doFetch("GET", {}, `${ApiUrl.USER_TYPE}?username=${Api.getUserName()}`);

    if (response) {
        console.log(response, "user type");
        if (response.user_type.user_type === "Student") {
            Swal.fire(
                'Unauthorised?',
                'You are not authorized to access this portal',
                'error'
            )
            localStorage.clear();
            setTimeout(() => {
                window.location.href = "/"
            }, 500)
        }
    }
}

export const generateProfileInitials = (username) => {
	try {
		if (typeof username === "string" && username.length > 0) {
			const usernameSplit = username.trim().split(" ");

			const firstInitial = usernameSplit[0];

			if (usernameSplit.length > 1) {
				const lastInitial = usernameSplit[usernameSplit.length - 1];

				return (firstInitial[0] + lastInitial[0]).toUpperCase();
			}

			return firstInitial[0].toUpperCase();
		}

		return "";
	} catch (e) {
		return "";
	}
};

const paymentBucket = {
	Cash: "Cash",
	cash: "Cash",
	"Cash Receive": "Cash",
	Cheque: "Cheque",
	cheque: "Cheque",
	"By Cheque": "Cheque",
	CommerceConnect: "Online",
	link: "Online",
	Razorpay: "Online",
	Link: "Online",
	razorpay: "Online",
	"Online Payment": "Online",
	netbanking: "Online",
	Paypal: "Online",
	wallet: "Online",
	upi: "Bank transfer",
	"Bank Transfer": "Bank transfer",
	Paytm: "Bank transfer",
	UPI: "Bank transfer",
	"NEFT/IMPS": "Bank transfer",
	NEFT: "Bank transfer",
	"neft/imps": "Bank transfer",
	ezetap: "POS",
	Swipe: "POS",
	card: "POS",
	Hdfc: "POS",
	swipe: "POS",
	"Swipe Machine": "POS",
	"Virtual Account": "Virtual account",
};

export const PaymentMode = {
	CASH: "Cash",
	CHEQUE: "Cheque",
	ONLINE: "Online",
	BANK_TRANSFER: "Bank Transfer",
	POS: "POS",
	VIRTUAL_ACCOUNT: "Virtual Account",
	NO_COST_EMI: "No cost EMI",
};

export const getPaymentMode = (paymentType) => {
	let paymentMode = "";
	switch (paymentType) {
		case "online/swipe":
			paymentMode = "Online/Swipe";
			break;
		case "hdfc/ccavenue":
			paymentMode = "hdfc/ccavenue";
			break;
		case "cash":
		case "Cash":
		case "Cash Receive":
			paymentMode = PaymentMode.CASH;
			break;
		case "cheque":
		case "Cheque":
		case "Post Dated Cheque":
			paymentMode = PaymentMode.CHEQUE;
			break;
		case "CommerceConnect":
		case "commerceconnect":
		case "razorpay":
		case "Razorpay":
		case "Bank Transfer":
		case "paytm":
		case "Paytm":
			paymentMode = PaymentMode.ONLINE;
			break;
		case "ezetap":
		case "swipe":
		case "Swipe":
			paymentMode = PaymentMode.POS;
			break;
		case "link":
			// paymentMode = PaymentMode.LINK;
			paymentMode = "Link";
			break;
		default:
			paymentMode = paymentType;
	}
	return paymentMode;
};

export const checkRouteActive = (route) => {

    switch (route) {
        case "Dashboard":
            return window.location.href.includes("/dashboard") || window.location.href.includes("/mpp");
        case "Students":
            return window.location.href.includes("/student") && !window.location.href.includes("/student/attendance") && !window.location.href.includes("/student/mark-attendance");
        case "Attendance":
            return (window.location.href.includes("/student/attendance") || window.location.href.includes("/student/mark-attendance"));
        case "Reversal Request":
            return window.location.href.includes("/payment-reversal");
        case "Hub Management":
            return window.location.href.includes("/hub");
        case "Centre Management":
            return window.location.href.includes("/centre");
        case "Subject Management":
            return window.location.href.includes("/subject") && !window.location.href.includes("/assign-reviewer");
        case "Chapter Management":
            return window.location.href.includes("/chapter");
        case "Course Management":
            return window.location.href.includes("/course");
        case "Batch Management":
            return window.location.href.includes("/batch");
        case "Pending cheque":
            return window.location.href.includes("/pending-cheque");
        case "Pending deposits":
            return window.location.href.includes("/pending-deposit");
        case "Pending no cost EMI":
            return window.location.href.includes("/pending-emi");
        case "Payment details":
            return window.location.href.includes("/payment");
        case "Faculty onboarding":
            return window.location.href.includes("/faculty");
        case "Assign reviewer":
            return window.location.href.includes("/assign-reviewer");
        case "Deposit":
            return window.location.href.includes("/cash-deposit");
        case "Pending Cash":
            return window.location.href.includes("/cash") && !window.location.href.includes("/cash-deposit");
		case "Lead Management":
			return window.location.href.includes("/lead");
		case "Question Bank":
			return window.location.href.includes("/qb");
        default:
            return false;
    }
}

export const permissionConstants = {
    STUDENT: "student list",
    ATTENDANCE: "attendance",
    PAYMENT_REVERSAL: "payment-reversal",
    CHANGE_PASSWORD: "change password",
    HUB: "hub",
    CENTRE: "centre",
    SUBJECT: "subject",
    CHAPTER: "chapter",
    COURSE: "course",
    BATCH: "batch",
    PAYMENT: "payment",
    CHEQUE: "cheque",
    BANK_DEPOSIT: "bank deposit",
    NO_COST_EMI: "no cost emi",
    FACULTY: "faculty",
    REVIEWER: "reviewer",
    ACCOUNTANT_DEPOSIT: "cash deposit",
    FM_DEPOSIT: "deposit",
    MPP: "mpp",
	LEAD: "lead",
	QUESTION_BANK: "question bank",
	USER:"user"
}

export const roleConstant = {
    STUDENT_MANAGER: "student manager",
    COUNSELOR: "counselor",
    ACCOUNTANT: "accountant",
    FINANCE_MANAGER: "finance manager",
    DTP: "DTP",
	CENTER_HEAD: "centre head",
}

export const checkWindowLocation = () => {
	const windowLocation = window.location.href;

	if (
		!windowLocation.includes("paymentlink") &&
		!windowLocation.includes("payment/paymentSuccess") &&
		!windowLocation.includes("payment/paymentFailed") &&
		!windowLocation.includes("payment/payment-failed") &&
		!windowLocation.includes("payment/payment-success-hostel")
	) {
		return true;
	}

	return false;
};

export const dropdownSizeWeb = 11;

export const dropdownSizeMobile = 11;

export const Capitalize = (text) => {
	try {
		return text ? text[0].toUpperCase() + text.slice(1) : "";
	} catch (e) {
		return "";
	}
};

export const debounce = (fn, delay) => {
	let timeoutID;

	return function (...args) {
		if (timeoutID) {
			clearTimeout(timeoutID);
		}

		timeoutID = setTimeout(() => {
			fn(...args);
		}, delay);
	};
};

export const throttle = (callback, limit) => {
	var waiting = false; // Initially, we're not waiting
	return function () {
		// We return a throttled function
		if (!waiting) {
			// If we're not waiting
			callback.apply(this, arguments); // Execute users function
			waiting = true; // Prevent future invocations
			setTimeout(function () {
				// After a period of time
				waiting = false; // And allow future invocations
			}, limit);
		}
	};
};

export const PaymentStatus = {
	PAID: "paid",
	FAILED: "failed",
	REVERSED: "reversed",
	PENDING: "pending",
};

export const getPaymentStatus = (status) => {
	try {
		if (!status) {
			return "";
		}
		let tempStatus = status && status.toLocaleLowerCase();
		let paymentStatus = "";
		switch (tempStatus) {
			case "paid":
				paymentStatus = PaymentStatus.PAID;
				break;
			case "failure":
			case "failed":
				paymentStatus = PaymentStatus.FAILED;
				break;
			case "reversed":
				paymentStatus = PaymentStatus.REVERSED;
				break;
			case "pending":
				paymentStatus = PaymentStatus.PENDING;
				break;
			default:
				paymentStatus = "";
		}

		return paymentStatus;
	} catch (e) {
		return null;
	}
};

export const disableFutureData = (current) => {
	return current && current > moment().endOf("day");
};

export const monthList = {
	JANUARY: "January",
	FEBRUARY: "February",
	MARCH: "March",
	APRIL: "April",
	MAY: "May",
	JUNE: "June",
	JULY: "July",
	AUGUST: "August",
	SEPTEMBER: "September",
	OCTOBER: "October",
	NOVEMBER: "November",
	DECEMBER: "December",
};

export const examTypes = {
	"JEE MAINS": "JEE Mains",
	"JEE ADVANCED": "JEE Advanced",
	NEET: "NEET",
};

export const programList = {
	CUBE: "CUBE",
	FOUNDATION: "Foundation",
	JEE: "JEE",
	NEET: "NEET",
};

export const courseTypeList = {
	"CLASSROOM (HYBRID)": "Classroom (hybrid)",
	ONLINE: "Online",
};

export const getPaymentBucket = (paymentType) => {
	let paymentMode = "";
	switch (paymentType) {
		case "cash":
		case "Cash":
		case "Cash Receive":
			paymentMode = PaymentMode.CASH;
			break;
		case "cheque":
		case "Cheque":
		case "Post Dated Cheque":
		case "By Cheque":
			paymentMode = PaymentMode.CHEQUE;
			break;
		case "CommerceConnect":
		case "commerceconnect":
		case "razorpay":
		case "Razorpay":
		case "link":
		case "Link":
		case "Online Payment":
		case "netbanking":
		case "Paypal":
		case "wallet":
			paymentMode = PaymentMode.ONLINE;
			break;
		case "upi":
		case "Bank Transfer":
		case "Paytm":
		case "UPI":
		case "NEFT/IMPS":
		case "NEFT":
		case "neft/imps":
		case "Bank Deposit":
		case "Bank deposit":
			paymentMode = PaymentMode.BANK_TRANSFER;
			break;
		case "ezetap":
		case "swipe":
		case "Swipe":
		case "Swipe Machine":
		case "card":
		case "Hdfc":
			paymentMode = PaymentMode.POS;
			break;
		case "Virtual Account":
			paymentMode = PaymentMode.VIRTUAL_ACCOUNT;
			break;
		default:
			paymentMode = paymentType;
	}
	return paymentMode;
};

export const CourseTypeMap = {
	ONLINE: "Online",
	"CLASSROOM (HYBRID)": "Classroom (hybrid)",
};

export const zinedus3 = "https://zinedumain.s3.us-east-2.amazonaws.com/";

export const isFloat = (n) => {
	n = Number(n);

	if (isNaN(n)) return false;

	return n === +n && n !== (n | 0);
};

export const isLatLong = (s) => {
    const r = new RegExp(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/);

    return r.test(s)
}

export const NumSuffix = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export const bankStatusMap = {
	CONFIRMED: "confirmed",
	PENDING: "pending",
	REJECTED: "rejected",
};
