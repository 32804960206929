import React from "react";
import { Asterisk } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import { State, City } from "country-state-city";
import StudentIcon from "../../../assets/img/student_icon.svg";
import IndianFlag from "../../../assets/img/indian_flag.svg";
import DropdownImg from "../../../assets/img/drop_down.svg";
import { Select } from "antd";

const { Option } = Select;

const EditStudent = ({ data = {}, handleData, error, handleError, cityList, setCityList, disableMobile = false }) => {
	const { studentName, studentMobile, studentEmail, state, city } = data;

	const { studentNameError, studentMobileError, studentEmailError, stateError, cityError } = error;

	const handleChange = (payload, errorPayload) => {
		if (payload) {
			handleData({
				...data,
				...payload,
			});
		}
		if (errorPayload) {
			handleError({
				...error,
				...errorPayload,
			});
		}
	};

	return (
		<>
			<div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ marginInline: 15 }}>
				<p className="student_filter_drop_header student_text_bottom">
					Student Name <Asterisk />
				</p>

				<img src={StudentIcon} alt="student" className="add_student_student_icon" />

				<input
					className={`change_pw_input add_student_student_icon_input ${studentNameError ? "change_pw_input_error" : ""}`}
					placeholder="Enter name"
					value={studentName}
					onChange={(e) => {
						handleChange(
							{
								studentName: e.target.value,
							},
							{
								studentNameError: "",
							}
						);
					}}
				/>
				{studentNameError && <ErrorText text={studentNameError} />}
			</div>

			<div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ marginInline: 15 }}>
				<p className="student_filter_drop_header student_text_bottom">
					Student Mobile Number <Asterisk />
				</p>

				<img src={IndianFlag} alt="indian" className="student_mobile_indian_img" />
				<img src={DropdownImg} alt="dropdown" className="student_mobile_drop_img" />
				<div className="d-flex">
					<input className={`change_pw_input student_add_mobile_indian`} value={"+91"} disabled />

					<input
						className={`change_pw_input student_add_mobile_input ${studentMobileError ? "change_pw_input_error" : ""}`}
						placeholder="Enter number"
						value={studentMobile}
						onChange={(e) => {
							if (e.target.value.length <= 10) {
								handleChange(
									{
										studentMobile: e.target.value.replace(/[^0-9]/g, ""),
									},
									{
										studentMobileError: "",
									}
								);
							}
							handleChange(null, {
								studentMobileError: "",
							});
						}}
						disabled={disableMobile}
					/>
				</div>
				{studentMobileError && <ErrorText text={studentMobileError} />}
			</div>

			<div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ marginInline: 15 }}>
				<p className="student_filter_drop_header">Student Email ID</p>

				<input
					className={`change_pw_input ${studentEmailError ? "change_pw_input_error" : ""}`}
					placeholder="Enter email"
					value={studentEmail}
					onChange={(e) => {
						handleChange(
							{
								studentEmail: e.target.value,
							},
							{
								studentEmailError: "",
							}
						);
					}}
				/>
				{studentEmailError && <ErrorText text={studentEmailError} />}
			</div>

			<div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ marginInline: 15 }}>
				<p className="student_filter_drop_header">
					State <Asterisk />
				</p>

				<Select
					className="full-width"
					value={state || undefined}
					placeholder="Select State"
					onChange={(value) => {
						handleChange(
							{
								state: value || "",
								city: "",
							},
							{
								stateError: "",
							}
						);

						if (value) {
							let index = State.getStatesOfCountry("IN").findIndex((x) => x.name === value);

							if (index >= 0) {
								setCityList(City.getCitiesOfState("IN", State.getStatesOfCountry("IN")[index].isoCode));
							} else {
								setCityList([]);
							}
						} else {
							setCityList([]);
						}
					}}
					showArrow
					allowClear
					showSearch
				>
					{State.getStatesOfCountry("IN").map((stateData, index) => {
						return (
							<Option value={stateData.name} key={index}>
								{stateData.name}
							</Option>
						);
					})}
				</Select>
				{stateError && <ErrorText text={stateError} />}
			</div>

			<div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ marginInline: 15 }}>
				<p className="student_filter_drop_header">
					City <Asterisk />
				</p>

				<Select
					className="full-width"
					value={city || undefined}
					placeholder="Select City"
					onChange={(value) => {
						handleChange(
							{
								city: value || "",
							},
							{
								cityError: "",
							}
						);
					}}
					showArrow
					allowClear
					showSearch
				>
					{cityList &&
						cityList.map((cityData, index) => {
							return (
								<Option value={cityData.name} key={index}>
									{cityData.name}
								</Option>
							);
						})}
				</Select>
				{cityError && <ErrorText text={cityError} />}
			</div>
		</>
	);
};

export default EditStudent;
