import React from "react";
import SearchImg from "../../../assets/img/search.svg";
import { useSelector } from "react-redux";
import { currencyFormatter } from "../../../utils/helperFunctions";
import { CustomFinanceTooltip } from "../common/handleTooltip";

const searchByItems = [
    "Acknowledgement no.",
    "Cheque ID",
    "Phone no.",
    "Student name",
    "Student ID",
];

const ChequeHeader = ({
    totalAmount,
    searchText,
    handleSearch
}) => {

    const { total } = useSelector((state) => state.scrollingList);

    return (
        <div className="course_list_header">
            <div className="d-flex">
                <div className="pending-cheques__heading_text mb-0">Pending Cheques</div>
                <div className="ml-3">
                    <p className="pending-cheques__amount mb-0">₹ {currencyFormatter(totalAmount, false) || 0}</p>
                    <p className="pending-cheques__count mb-0">Count: {total}</p>
                </div>
            </div>

            <div className="d-flex align-items-center course_search_wrapper">
                <img src={SearchImg} alt="search" className="course_search_image" />
                <CustomFinanceTooltip
                    disableFocusListener
                    enterTouchDelay={0}
                    placement="top-start"
                    title={
                        searchText && searchText.length > 0 ? "" : 
                        <div style={{ width: 259 }}>
                            <div style={{ fontWeight: 600, fontSize: 14 }}>Search by</div>
                            {searchByItems.map((searchBy, searchByIndex) => (
                                <div key={searchByIndex} style={{ fontSize: 12, fontWeight: 400 }}>
                                    {searchBy}
                                </div>
                            ))}
                        </div>
                    }
                >
                    <input 
                        className="course_search_input" 
                        placeholder="Search a pending cheque"
                        defaultValue={searchText}
                        onChange={handleSearch}
                    />
                </CustomFinanceTooltip>
            </div>
        </div>
    );
};

export default ChequeHeader;
