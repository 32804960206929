import { HANDLE_HUB_DETAILS, RESET_HUB_DETAILS, SET_HUB_SCROLL} from '../actions/types';

const initialState = {
    hubList: [],
    scrollPositionY: 0,
    selectedHubIndex: -1,
    searchText: "",
    skip: 0,
    limit: 10,
    continueScroll: false
};
//eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_HUB_DETAILS:
            return {
                ...state,
                ...action.payload
            };
        case RESET_HUB_DETAILS:
            return initialState;
        case SET_HUB_SCROLL:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}