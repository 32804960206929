import { HANDLE_SUBJECT_DETAILS, RESET_SUBJECT_DETAILS,SET_SUBJECT_SCROLL } from '../actions/types';

const initialState = {
    subjectList: [],
    scrollPositionY: 0,
    searchText: "",
    skip: 0,
    limit: 20,
    continueScroll: false
};
//eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_SUBJECT_DETAILS:
            return {
                ...state,
                ...action.payload
            };
        case RESET_SUBJECT_DETAILS:
            return initialState;
        case SET_SUBJECT_SCROLL:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
