import React from "react";
import RupeeSymbol from "../../../assets/img/rupee-symbol.svg"
import ListImage from "../../../assets/img/list-att-icon.svg";
import { useNavigate } from "react-router-dom";

const CashReconciliation = () => {

    const navigate = useNavigate();

    return (
        <div className="containerMain">

            <div className="payment_reversal_container2">

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/cash/pending");
                    }}
                >

                    <img
                        src={RupeeSymbol}
                        alt="add course"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Pending cash deposit
                    </span>

                </div>

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/cash/list");
                    }}
                >

                    <img
                        src={ListImage}
                        alt="add course"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        List of deposits
                    </span>

                </div>

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/cash/not-confirmed");
                    }}
                >

                    <img
                        src={RupeeSymbol}
                        alt="add course"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        List of cash not confirmed
                    </span>

                </div>

            </div>

        </div>
    );
};

export default CashReconciliation;
