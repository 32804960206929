import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Modal } from "@mui/material";

const ViewSlipModal = ({ 
    handleViewSlip, 
    name, 
    mobileNumber, 
    date,
    ChequePhoto,
    open,
    dataKey,
    dataValue,
}) => {
    return (
        <Modal open = {open} >
            <div className="view_cheque_modal">
                <div className="justify-content-between d-flex mb-3">
                    <p className="payment_details_list_card_header value big" style={{fontSize: '18px'}}>Deposit Details</p>
                    <CloseIcon onClick={handleViewSlip} style={{ width: "22px", height: "22px", cursor: "pointer" }} />
                </div>

                <div className="paymanet_details_card_divider3 cheque mb-2">
                    <div className="payment_details_list_card_header">Name</div>
                    <p className="payment_details_list_card_header value">{name}</p>
                </div>

                <div className="d-flex payment_details_card_row align-items-start mb-2">
                    <div className="paymanet_details_card_divider5">
                        <div className="payment_details_list_card_header">Phone number</div>
                        <p className="payment_details_list_card_header value">+91 {mobileNumber}</p>
                    </div>

                    {!dataKey ? 
                        <div>
                            <div className="payment_details_list_card_header">Entry date</div>
                            <p className="payment_details_list_card_header value">{moment(date).format("MMM DD, YYYY")}</p>
                        </div>
                    :
                        <div>
                            <div className="payment_details_list_card_header">
                                {{ 'utr': 'UTR Code', 'loan': 'Loan number'}[dataKey] || ""}
                            </div>
                            <p className="payment_details_list_card_header value">{dataValue}</p>
                        </div>
                    }
                </div>

                <img className="full-width add_course_division" src={ChequePhoto} style={{ width: "100%" }} alt="" />
            </div>
        </Modal>
    );
};

export default ViewSlipModal;
