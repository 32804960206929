import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import LeftArrow from "../../../assets/img/left_arrow.svg";
import ArrowDown from "../../../assets/img/arrow_down.svg";
import ArrowUp from "../../../assets/img/arrow_up.svg";
import CloseButton from "../../../assets/img/close_button.svg";
import TickWhite from "../../../assets/img/tick_white.svg";
import CloseWhite from "../../../assets/img/close_white.svg";
import { useNavigate } from "react-router-dom";
import Accordian from "../../../utils/accordian";
import { currencyFormatter, Asterisk } from "../../../utils/helperFunctions";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from '../../common/api';
import ApiUrl from '../../common/apiUrl';
import Loader from '../../common/Loader';
import { getPaymentMode } from "../../../utils/helperFunctions";
import moment from "moment";
import { useSelector } from 'react-redux';
import Empty from "../../../assets/img/empty_student.svg";
import { useMediaQuery } from '@mui/material';

const ToastMessage = ({ type }) => (
    <div
        className="reversal_toast"
        style={{
            "--toast-status": type === "success" ? "#35CB00" : "#C80000"
        }}
    >
        <img
            src={type === "success" ? TickWhite : CloseWhite}
            alt={type === "success" ? "success" : "declined"}
            className="mr-2"
            style={{ width: "1.2rem" }}
        />
        {
            type === "success" ?
                "The reversal request has been accepted successfully."
                :
                "The reversal request has been declined."
        }
    </div>
)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxWidth: 600,
    width: "90%",
    padding: "3rem 2rem",
    borderRadius: 4,
};

function PaymentReversalPending() {

    const navigate = useNavigate();
    const renderResponsiveView = useMediaQuery('(max-width: 800px)');

    const { centerList } = useSelector(state => state.auth);

    const [pending, setPending] = useState([])

    const [acceptModal, setAcceptModal] = useState(-1);

    const acceptReversal = (index) => {
        setUpdateLoading(false);
        setAcceptModal(index);
    }

    const [updateLoading, setUpdateLoading] = useState(false);

    const updateReversalRequest = async (status) => {

        let payload = {
            status
        };

        if (+status === 3) {
            payload.updated_remarks = declineReason;
        }

        let id = +status === 2 ? pending[acceptModal].id : pending[rejectModal].id;

        setUpdateLoading(true);

        const data = await Api.doFetch("PUT", payload, `${ApiUrl.PAYMENT_REVERSAL_UPDATE}${id}/`);

        setUpdateLoading(true);

        console.log(data);
        if (data && data.status) {

            toast(<ToastMessage type={+status === 2 ? "success" : "declined"} />);

            closeAcceptModal();
            closeRejectModal();

            getPendingReversalRequest();
        } else if (data && ((data.payload && data.payload.Error) || data.message)) {
            if (data.payload && data.payload.Error) {
                Swal.fire("Error", data.payload.Error, "error");
            } else if (data.message) {
                Swal.fire("Error", data.message, "error");
            }
        }
    }

    const closeAcceptModal = () => {
        setAcceptModal(-1);
    }

    const [rejectModal, setRejectModal] = useState(-1);

    const [declineReason, setDeclineReason] = useState("");
    const [declineReasonError, setDeclineReasonError] = useState("");

    const rejectReversal = (index) => {
        setUpdateLoading(false);
        setDeclineReason("");
        setDeclineReasonError("");
        setRejectModal(index);
    }

    const closeRejectModal = () => {
        setRejectModal(-1);
    }

    const [loading, setLoading] = useState(false);

    const getCentres = () => {
        return centerList.map(x => x.school_id).toString();
    }

    const getPendingReversalRequest = async () => {

        setLoading(true);

        const data = await Api.doFetch("GET", {}, `${ApiUrl.PAYMENT_REVERSAL}?status=1&school_id=${getCentres()}`);

        setLoading(false);

        if (data && data.status) {
            setPending(data.payload);
        } else if (data && ((data.payload && data.payload.Error) || data.message)) {
            if (data.payload && data.payload.Error) {
                Swal.fire("Error", data.payload.Error, "error");
            } else if (data.message) {
                Swal.fire("Error", data.message, "error");
            }
        }
    }

    useEffect(() => {
        if(getCentres()){
            getPendingReversalRequest();
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [centerList])

    const getTotaldue = (x) => {
        const totalPayable = x.installment_details && x.installment_details.total_installment_amount ? x.installment_details.total_installment_amount : 0;
        const totalPaid = x.installment_details && x.installment_details.paid_amount ? x.installment_details.paid_amount : 0;
        const reversalAmount = x.payment_assoc && x.payment_assoc && x.payment_assoc.amount_paid ? x.payment_assoc.amount_paid : 0;

        return ((totalPayable - totalPaid) + reversalAmount);
    }

    const toggleDetails = (index, x) => () => {
        setPending(pending.map((pendingData, i) => {
            if (i === index) {
                return {
                    ...pendingData,
                    toggle: x.toggle ? false : true
                };
            }
            return { ...pendingData };
        }));
    };

    return (

        <div style={{ display: 'flex' }} className="pending_reversal" >

            <Modal
                open={acceptModal >= 0 ? true : false}
                onClose={closeAcceptModal}
                className="payment_reversal_cofirmation_modal"
            >
                <Box sx={style}>
                    <p className="reversal_accept_text">
                        Are you sure you want to accept reversal request?
                    </p>

                    <div className="mt-5">
                        <button
                            className="btn btn-danger btn-md reversal_decline_button"
                            onClick={closeAcceptModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-md reversal_accept_button ml-2"
                            onClick={() => {
                                if (!updateLoading) {
                                    updateReversalRequest(2)
                                }
                            }}
                        >
                            Confirm
                            {updateLoading && <span className="ml-2 spinner spinner-white mr-3"></span>}
                        </button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={rejectModal >= 0 ? true : false}
                onClose={closeRejectModal}
                className="payment_reversal_cofirmation_modal"
            >
                <Box sx={style}>

                    <div className="d-flex justify-content-between">
                        <p className="reversal_accept_text">
                            Are you sure you want to decline?
                        </p>
                        <img
                            src={CloseButton}
                            alt="close"
                            className="cursor-pointer"
                            style={{ width: "2.5rem" }}
                            onClick={closeRejectModal}
                        />
                    </div>

                    <p className="reversal_decline_header">
                        Reason to decline<Asterisk />
                    </p>

                    <textarea
                        className="reject_reason_textarea"
                        placeholder="Type your reason here"
                        value={declineReason}
                        onChange={e => {
                            if (e.target.value.length <= 200) {
                                setDeclineReason(e.target.value);
                                setDeclineReasonError("");
                            } else {
                                setDeclineReasonError("Max limit of 200 characters reached");
                                setTimeout(() => {
                                    setDeclineReasonError("");
                                }, 1200)
                            }
                        }}
                    />

                    {
                        declineReasonError &&
                        <p className="reject_reason_error">
                            {declineReasonError}
                        </p>
                    }

                    <button
                        className="btn btn-md reversal_accept_button mt-4"
                        onClick={() => {
                            if (declineReason.trim()) {
                                if (!updateLoading) {
                                    updateReversalRequest(3);
                                }
                            } else {
                                setDeclineReasonError("Please type reason to decline");
                            }
                        }}
                    >
                        Decline
                        {updateLoading && <span className="ml-2 spinner spinner-white mr-3"></span>}
                    </button>
                </Box>
            </Modal>

            <div style={{ width: '100%', padding: '0' }}>
                {/* <div className='topHeader'>
                    <TopNavBar
                        logo={true}
                    />
                </div> */}

                <div className="containerMain">

                    <div className="reversal_detail_header">

                        <img
                            src={LeftArrow}
                            alt="go back"
                            className="cursor-pointer"
                            onClick={() => {
                                navigate("/payment-reversal")
                            }}
                        />

                        <span className="reversal_detail_header_text ml-2">
                            Pending Reversal Request
                        </span>

                    </div>

                    {
                        loading ?
                            <div className="loader_container">
                                <Loader />
                            </div>
                            :
                            pending.length === 0 ?
                            <div
                                className="student_search_empty_container"
                                style = {{
                                    marginTop: "8rem"
                                }}
                            >
                                <div className="student_search_empty_img_container">
                                    <img
                                        src={Empty}
                                        alt="not found"
                                        className="student_search_empty_img"
                                    />
                                </div>

                                <p className="student_search_empty_header">
                                    No pending reversal
                                </p>
                                <span className="student_search_empty_label">
                                    Your team is fantastic, their are no new
                                </span>
                                <span className="student_search_empty_label">
                                    request, we will get back to you soon
                                </span>
                            </div>
                            :
                            pending.map((x, index) => {
                                return (
                                    <div className={`reversal_detail_card${renderResponsiveView ? " is-mobile" : ""}`} key={index}>

                                        <div className="d-flex justify-content-between align-items-center">

                                            <div className="reversal_request_user-info">
                                                <div className="student_reversal_fee_tag">
                                                    Course fee
                                                </div>
                                                <div className="reversal_request_user">
                                                    Raised by {x.created_by_assoc && x.created_by_assoc.first_name}
                                                </div>
                                                <div className="reversal_request_date">
                                                    Initiated date: {moment(x.created_on, "YYYY-MM-DD").format("DD MMM, YYYY")}
                                                </div>
                                            </div>

                                            {!renderResponsiveView && <div>
                                                <button
                                                    className="btn btn-md reversal_accept_button"
                                                    onClick={() => acceptReversal(index)}
                                                >
                                                    Accept
                                                </button>

                                                <button
                                                    className="btn btn-danger btn-md ml-2 reversal_decline_button"
                                                    onClick={() => rejectReversal(index)}
                                                >
                                                    Decline
                                                </button>
                                            </div>}

                                        </div>

                                        {renderResponsiveView && (
                                            <div className="payment-reversal-actions">
                                                <button
                                                    className="btn btn-md reversal_accept_button"
                                                    onClick={() => acceptReversal(index)}
                                                >
                                                    Accept
                                                </button>

                                                <button
                                                    className="btn btn-danger btn-md ml-2 reversal_decline_button"
                                                    onClick={() => rejectReversal(index)}
                                                >
                                                    Decline
                                                </button>
                                            </div>
                                        )}

                                        <div className="payment_reversal_table-container mt-3">
                                        {!renderResponsiveView ? (
                                            <table className="payment_reversal_table">
                                                <tbody>
                                                    <tr className="payment_reversal_table_header">
                                                        <td>Student Name</td>
                                                        <td>Reversed Amount</td>
                                                        <td>Paid On</td>
                                                        <td>Ack. No.</td>
                                                        <td>Mode</td>
                                                    </tr>
                                                    <tr className="payment_reversal_table_value">
                                                        <td>{x.payment_assoc && x.payment_assoc.student_assoc && x.payment_assoc.student_assoc.name}</td>
                                                        <td>{x.payment_assoc && x.payment_assoc && currencyFormatter(x.payment_assoc.amount_paid)}</td>
                                                        <td>{x.payment_assoc && x.payment_assoc && moment(x.payment_assoc.created_on, "YYYY-MM-DD").format("DD MMM, YYYY")}</td>
                                                        <td>{x.payment_assoc && x.payment_assoc && x.payment_assoc.id}</td>
                                                        <td>{x.payment_assoc && x.payment_assoc && getPaymentMode(x.payment_assoc.payment_type)}</td>
                                                    </tr>
                                                    <tr className="reversal_student_id">
                                                        <td className="reversal_student_id_padding">
                                                            ID: {x?.payment_assoc?.student_assoc?.id || ""}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="payment-reversal-card">
                                                <div className="payment-rc-col">
                                                    <div className="payment-rc-header">Student Name</div>
                                                    <div className="payment-rc-text">
                                                        {x.payment_assoc.student_assoc && x.payment_assoc.student_assoc.name}
                                                    </div>
                                                    <div className="reversal_student_id">
                                                        ID: {x?.payment_assoc?.student_assoc?.id || ""}
                                                    </div>
                                                </div>
                                                <div className="payment-rc-col">
                                                    <div className="payment-rc-header">Amount</div>
                                                    <div className="payment-rc-text">
                                                        {x.payment_assoc && currencyFormatter(x.payment_assoc.amount_paid)}
                                                    </div>
                                                </div>
                                                <div className="payment-rc-col">
                                                    <div className="payment-rc-header">Paid On</div>
                                                    <div className="payment-rc-text">
                                                        {x.payment_assoc && moment(x.payment_assoc.created_on, "YYYY-MM-DD").format("DD MMM, YYYY")}
                                                    </div>
                                                </div>
                                                <div className="payment-rc-col">
                                                    <div className="payment-rc-header">Ack. No.</div>
                                                    <div className="payment-rc-text">
                                                        {x.payment_assoc && x.payment_assoc.id}
                                                    </div>
                                                </div>
                                                <div className="payment-rc-col">
                                                    <div className="payment-rc-header">Mode</div>
                                                    <div className="payment-rc-text">
                                                        {x.payment_assoc && getPaymentMode(x.payment_assoc.payment_type)}
                                                    </div>
                                                </div>
                                                {/* <div className="payment-rc-col">
                                                    <button
                                                        type="button"
                                                        className="payment_reversal_collapse_button"
                                                        onClick={toggleDetails(index, x)}
                                                    >
                                                        <img
                                                            src={x.toggle ? ArrowUp : ArrowDown}
                                                            alt="arrow"
                                                            style={{ width: "1.2rem" }}
                                                        />
                                                    </button>
                                                </div> */}
                                            </div>
                                        )}
                                        {!renderResponsiveView && (
                                            <div className="payment_reversal_collapse_container">
                                                <button
                                                    type="button"
                                                    className="payment_reversal_collapse_button"
                                                    onClick={toggleDetails(index, x)}
                                                >
                                                    <img
                                                        src={x.toggle ? ArrowUp : ArrowDown}
                                                        alt="arrow"
                                                        style={{ width: "1.2rem" }}
                                                    />
                                                </button>
                                            </div>
                                        )}
                                        </div>


                                        {renderResponsiveView ? (
                                            <div className='row mx-0 mt-4'>
                                                <div className="col-xs-10 px-0">
                                                    <div className="payment-reversal-header">Reason For Reversal</div>
                                                    <p className="reversal_reason_text">
                                                        {x.counsellor_remarks}
                                                    </p>
                                                </div>
                                                <div className="col-xs-2 px-0">
                                                    <div className="text-right">
                                                        <button
                                                            type="button"
                                                            className="payment_reversal_collapse_button"
                                                            onClick={toggleDetails(index, x)}
                                                        >
                                                            <img
                                                                src={x.toggle ? ArrowUp : ArrowDown}
                                                                alt="arrow"
                                                                style={{ width: "1.2rem" }}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>


                                            </div>
                                        ) : (
                                            <div className="mt-4">
                                                <div className="reversal_request_date">Reason For Reversal</div>

                                                <p className="reversal_reason_text">
                                                    {x.counsellor_remarks}
                                                </p>
                                            </div>
                                        )}


                                        <Accordian expand={!!x.toggle}
                                        >
                                            <hr />


                                            <div className="">
                                                {renderResponsiveView ? <div className="payment-reversal-header">Installment Information</div> : <div className="reversal_request_date">Installment Information</div>}

                                                <div className="d-flex reversal_detail">
                                                    {!renderResponsiveView ? (
                                                        <table className="payment_reversal_table">
                                                            <tbody>
                                                                <tr className="payment_reversal_table_header">
                                                                    <td>No. Of Installments</td>
                                                                    <td>Total Payable</td>
                                                                    <td>Total Paid</td>
                                                                    <td>Amount due after reversal</td>
                                                                </tr>
                                                                <tr className="payment_reversal_table_value">
                                                                    <td>{x.installment_details && x.installment_details.installment_no ? x.installment_details.installment_no : 0}</td>
                                                                    <td>{currencyFormatter(x.installment_details && x.installment_details.total_installment_amount ? x.installment_details.total_installment_amount : 0)}</td>
                                                                    <td>{currencyFormatter(x.installment_details && x.installment_details.paid_amount ? x.installment_details.paid_amount : 0)}</td>
                                                                    <td>{currencyFormatter(getTotaldue(x))}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <div className="payment-reversal-card spaced">
                                                            <div className="payment-rc-col">
                                                                <div className="payment-rc-header">No. of Installments</div>
                                                                <div className="payment-rc-text">
                                                                    {x.installment_details && x.installment_details.installment_no ? x.installment_details.installment_no : 0}
                                                                </div>
                                                            </div>
                                                            <div className="payment-rc-col">
                                                                <div className="payment-rc-header">Total Payable</div>
                                                                <div className="payment-rc-text">
                                                                    {currencyFormatter(x.installment_details && x.installment_details.total_installment_amount ? x.installment_details.total_installment_amount : 0)}
                                                                </div>
                                                            </div>
                                                            <div className="payment-rc-col">
                                                                <div className="payment-rc-header">Total Paid</div>
                                                                <div className="payment-rc-text">
                                                                    {currencyFormatter(x.installment_details && x.installment_details.paid_amount ? x.installment_details.paid_amount : 0)}
                                                                </div>
                                                            </div>
                                                            <div className="payment-rc-col">
                                                                <div className="payment-rc-header">Amount due after reversal</div>
                                                                <div className="payment-rc-text">
                                                                    {currencyFormatter(getTotaldue(x))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>

                                            <div className="mt-4 reversal_detail">

                                                {renderResponsiveView ? <div className="payment-reversal-header">Student Information</div> : <div className="reversal_request_date">Student Information</div>}

                                                <div className="d-flex">
                                                    {renderResponsiveView ? (
                                                        <div className="payment-reversal-card even">
                                                            <div className="payment-rc-col">
                                                                <div className="payment-rc-header">Student Name</div>
                                                                <div className="payment-rc-text">
                                                                    {x.payment_assoc.student_assoc && x.payment_assoc.student_assoc.name}
                                                                </div>
                                                            </div>
                                                            <div className="payment-rc-col">
                                                                <div className="payment-rc-header">Mobile Number</div>
                                                                <div className="payment-rc-text">
                                                                    {x.payment_assoc.student_assoc && `*****${x.payment_assoc.student_assoc.mobile_number.substring("5")}`}
                                                                </div>
                                                            </div>
                                                            <div className="payment-rc-col">
                                                                <div className="payment-rc-header">Centre</div>
                                                                <div className="payment-rc-text">
                                                                    {x.payment_assoc.section_assoc && x.payment_assoc.section_assoc.school_assoc && x.payment_assoc.section_assoc.school_assoc.name}
                                                                </div>
                                                            </div>
                                                            <div className="payment-rc-col">
                                                                <div className="payment-rc-header">Course</div>
                                                                <div className="payment-rc-text">
                                                                    {x.payment_assoc.section_assoc && x.payment_assoc.section_assoc.section_assoc && x.payment_assoc.section_assoc.section_assoc.class_assoc && x.payment_assoc.section_assoc.section_assoc.class_assoc.class_name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <table className="payment_reversal_table">
                                                        <tbody>
                                                            <tr className="payment_reversal_table_header">
                                                                <td>Student Name</td>
                                                                <td>Mobile Number</td>
                                                                <td>Centre</td>
                                                                <td>Course</td>
                                                            </tr>
                                                            <tr className="payment_reversal_table_value">
                                                                <td>{x.payment_assoc && x.payment_assoc.student_assoc && x.payment_assoc.student_assoc.name}</td>
                                                                <td>{x.payment_assoc && x.payment_assoc.student_assoc && `*****${x.payment_assoc.student_assoc.mobile_number.substring("5")}`}</td>
                                                                <td>{x.payment_assoc && x.payment_assoc.section_assoc && x.payment_assoc.section_assoc.school_assoc && x.payment_assoc.section_assoc.school_assoc.name}</td>
                                                                <td>{x.payment_assoc && x.payment_assoc.section_assoc && x.payment_assoc.section_assoc.section_assoc && x.payment_assoc.section_assoc.section_assoc.class_assoc && x.payment_assoc.section_assoc.section_assoc.class_assoc.class_name}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    )}
                                                </div>

                                            </div>
                                        </Accordian>

                                    </div>
                                )
                            })
                    }


                </div>
            </div>

        </div>

    )
}

export default PaymentReversalPending;