import React from "react";
import { BackTop } from "antd";
import TopImg from "../../../assets/img/top.svg";

const GoToTop = ({
    target
}) => {
    return (
        <BackTop 
            target={() => document.getElementsByClassName(target)[0]}
        >
            <div className="backtop_container">
                <img 
                    src={TopImg} 
                    alt="top"
                    className="backtop_img"
                />
            </div>
        </BackTop>
    );
};

export default GoToTop;