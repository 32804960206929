import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "../payment/installmentCreate.css";
import paymentSuccessImg from "../payment/css/payment-success.gif";
import paymentFailedImg from "../payment/css/payment-failed.gif";
import Api from "../common/api";
import ApiUrl from "../common/apiUrl";
import Loader from "../common/Loader";
import Swal from "sweetalert2";
import { currencyFormatter } from "../../utils/helperFunctions";

const PaymentHDFC = () => {

	const navigate = useNavigate();

	const { paymentId } = useParams();

	const [loading, setLoading] = useState(false);
	const [paymentStatus, setPaymentStatus] = useState("");
	const [paymentAmount, setPaymentAmount] = useState("");

	const getPaymentStatus = async () => {
		
		setLoading(true);

		const data = await Api.doFetch("GET", {}, `${ApiUrl.HDFC_CALLBACK_URL}/${paymentId}`)

		if (data?.status) {
			setPaymentAmount(data?.payload?.amount);
			setPaymentStatus(data?.payload?.success ? "success" : "failure");
		} else {
			Swal.fire("Error!", data?.message || "Internal server Error", "error");
			navigate("/");
		}

		setLoading(false);

	}

	useEffect(() => {
		getPaymentStatus();
	}, []);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12">
					<div className="support_main">
						<div className="installCreateWrap">

							<div className="container">

								{
									loading ?
									<div className="loader_scroll_container">
										<Loader />
									</div>
									:
									paymentStatus === "success" || paymentStatus === "failure" ?
									<div className="tabWrap paymentSuccess" style={{ textAlign: "center" }}>
										<div>
											<img 
												src={paymentStatus === "failure" ? paymentFailedImg : paymentSuccessImg} 
												alt="status" 
												style = {
													paymentStatus === "failure" ?
													{
														width: "23rem",
    													marginBottom: "12rem",
														marginTop: "10rem"
													}
													:
													{}
												}
											/>
											<div className="successWrp">
												<h3>
													Payment {paymentStatus === "failure" ? "Failed" : "Successfull" }
												</h3>
												<h4 className="mt-3">
													Order Id: {paymentId}
												</h4>
												<h4 className="mt-3">
													Amount: {currencyFormatter(paymentAmount)}
												</h4>
												{
													Api.isAuthenticated() && (
													<div className="mt-5">
														{
															paymentStatus === "success" &&
															<>
																<NavLink to={`/payment/paymentReceiptSummary/${paymentId}`} target="_blank">
																	View Payment Receipt
																</NavLink>
																<br />
															</>
														}
														<NavLink to="/" style={{ color: "#847C7C", cursor: "pointer", textDecoration: "none" }}>
															Go to dashboard
														</NavLink>
													</div>
												)}
											</div>
										</div>
									</div>
									:
									null
								}	
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default PaymentHDFC;