import React from "react";
import AddSubject from "../addSubject";

const EditSubject = (props) => {

    return (
        <AddSubject 
            {...props}
            defaultView = {true}
        />
    );
};

export default EditSubject;