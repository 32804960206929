import React, { useRef } from "react";
import { Asterisk, debounce, throttle } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";

const ChapterBasicDetails = ({ 
    view = false,
    chapterData, 
    setChapterData, 
    chapterError = {}, 
    setChapterError,
    combinationError,
    setCombinationError,
    serialError,
    setSerialError
}) => {

    console.log(chapterData, "chapterData");

    let incNumber = () => {
        setSerialError("");
        handleChapterError({ chapter_serial_number: "" });

        setChapterData({
            ...chapterData,
            chapter_serial_number: Number(chapterData.chapter_serial_number) + 1,
        });
    };

    let decNumber = () => {
        setSerialError("");
        if(chapterData.chapter_serial_number === 0) {
            return;
        }

        handleChapterError({ chapter_serial_number: "" });
        setChapterData({
            ...chapterData,
            chapter_serial_number: Number(chapterData.chapter_serial_number) - 1,
        });
    };
    

    const handleChapterNameChange = (e) => {
        setCombinationError("");
        handleChapterError({ chapter_name: "" });
        const value = e.target.value;
        setChapterData(cd => {
            return {...cd,
            chapter_name: value,}
        });
    };

    const handleSerialNumberChange = (e) => {
        setSerialError("");
        handleChapterError({ chapter_serial_number: "" });
        setChapterData({
            ...chapterData,
            chapter_serial_number: e.target.value.replace(/[^0-9]/g, ""),
        });
    };

    const handleChapterError = (payload) => {
        setChapterError({
            ...chapterError,
            ...payload,
        });
    };

    return (
        <>
            <div className="course_add_header_container">Basic details</div>

            <div className="course_add_data_container">
                <div className="row">
                    <div className="col-md-10">
                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">Chapter {!view ? <Asterisk /> : ""}</p>

                            {
                                view ?
                                <p className="course_view_text_data">{chapterData.chapter_name}</p>
                                :
                                <input
                                    className={`change_pw_input ${chapterError.chapter_name || combinationError ? "change_pw_input_error" : ""}`}
                                    placeholder="Enter chapter name"
                                    value={chapterData.chapter_name}
                                    onChange={e => {
                                        handleChapterNameChange(e);
                                    }}
                                />
                            }
                            {chapterError.chapter_name && <ErrorText text={chapterError.chapter_name} />}
                            {combinationError && <ErrorText text={combinationError} />}
                        </div>

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">Chapter serial number {!view ? <Asterisk /> : ""}</p>

                            {
                                view ?
                                <p className="course_view_text_data">{chapterData.chapter_serial_number}</p>
                                :
                                <div className="input-group addNumber">
                                    <div className="input-group-prepend ">
                                        <button className="btn btn-outline-primary minusBtn" type="button" onClick={decNumber}>
                                            -
                                        </button>
                                    </div>
                                    <input
                                        className="form-control installment_counter_text hidden_arrows_input"
                                        value={chapterData.chapter_serial_number}
                                        onChange={handleSerialNumberChange}
                                    />
                                    <div className="input-group-prepend">
                                        <button className="btn btn-outline-primary plusBtn" type="button" onClick={incNumber}>
                                            +
                                        </button>
                                    </div>
                                </div>
                            }
                            {chapterError.chapter_serial_number && <ErrorText text={chapterError.chapter_serial_number} />}
                            {serialError && <ErrorText text={serialError} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChapterBasicDetails;
