import React, { useState, useEffect, useCallback } from "react";
import LeftArrow from "../../assets/img/backArrowBlack.svg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/img/search.svg";
import HubTile from "./HubTile";
import AddCircle from "../../assets/img/add_circle.svg";
import Api from "../common/api";
import ApiUrl from "../common/apiUrl";
import Loader from "../common/Loader";
import Empty from "../../assets/img/empty_student.svg";
import { useScrollingList } from "../../utils/hooks/use-scrolling-list";

const ListOfHubs = () => {
    const [noData, setNoData] = useState(false);

    const navigate = useNavigate();

    const getHubs = useCallback(async ({ skip = 0, search = "", limit = 10 }) => {
        let url = `${ApiUrl.HUB}?skip=${skip}&limit=${limit}`;

        if (search && search.trim().length > 0) {
            url += `&search=${search}`;
        }

        const data = await Api.doFetch("GET", {}, url);

        if (data?.status === true) {
            const payload = data.data;

            if (payload) {
                const { data: items, total, skip, limit } = payload;

                return {
                    items,
                    total,
                    skip,
                    limit,
                };
            }
        }

        setNoData(true);

        return null;
    }, []);

    const [scrollContainer, { loading, scrollLoading, list: hubList, handleScroll, searchText, handleSearch, saveScrollPosition, scrollPositionY }] = useScrollingList({
        listKey: "hubs",
        getData: getHubs,
    });

    const handleSearchText = (e) => {
        const value = e.target.value;

        handleSearch(value);
    };

    const handleOnEdit = () => {
        if (scrollContainer.current) {
            const scrollTopPos = scrollContainer.current.scrollTop;
            saveScrollPosition(scrollTopPos);
        }
    };

    useEffect(() => {
        if (scrollContainer.current && scrollPositionY !== 0) {
            scrollContainer.current.scrollTo(0, scrollPositionY);
        }
    }, [scrollContainer, scrollPositionY]);

    return (
        <div className="hub-container">
            <div className="course_list_header">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/hub");
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">List of hubs</span>
                </div>

                <div className="d-flex align-items-center course_search_wrapper">
                    <img src={SearchIcon} alt="search" className="course_search_image" />
                    <input 
                        className="course_search_input" 
                        onChange={handleSearchText} 
                        placeholder="Search a hub"
                        defaultValue={searchText}
                    />
                </div>
            </div>

            <div ref={scrollContainer} className={"subject_scroll_container"} onScroll={handleScroll}>
                {
                    loading ?
                    <div className="loader_container">
                        <Loader />
                    </div>
                    : 
                    hubList?.length === 0 ?
                    <div className="student_search_empty_container mt-5">
                        <div className="student_search_empty_img_container">
                            <img src={Empty} alt="not found" className="student_search_empty_img" />
                        </div>

                        {
                            noData ?
                            <p className="student_search_empty_header">No hubs found</p>
                            :
                            <>
                                <p className="student_search_empty_header">No results found</p>
                                <span className="student_search_empty_label">Try adjusting your search to find</span>
                                <span className="student_search_empty_label">the best results</span>
                            </>
                        }
                    </div>
                    :
                    hubList.map((hub, index) => {
                        return <HubTile key={index} hubName={hub.hub_name} hubImage={hub.hub_logo} hubID={hub.id} onEdit={handleOnEdit} />;
                    })
                }

                {
                    !loading && scrollLoading &&
                    <div className="loader_scroll_container">
                        <Loader />
                    </div>
                }

                {
                    !loading && !scrollLoading &&
                    <div
                        className="add_course_container"
                        onClick={() => {
                            navigate("/hub/add");
                        }}
                    >
                        <div className="add_course_text_container">
                            <img src={AddCircle} alt="add" className="add_student_img" />

                            <span className="add_student_container_text">Add hub</span>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ListOfHubs;
