import React from "react";
import styled from "styled-components";
import ErrorImg from "../../assets/img/error_txt.svg";

const Error = ({ text }) => {
	return (
		<>
			<img src={ErrorImg} alt="error" className="error_img" />
			<span className="error_text">{text}</span>
		</>
	);
};

const colorMap = {
	success: "#1CBD02",
	error: "#BC0000",
	normal: "#818181",
};

export const InfoText = ({ status, text, fontSize }) => {
	const InfoTextSpan = styled.span`
		color: ${(props) => colorMap[props.textColor] || "#000"};
		font-size: ${fontSize ? fontSize : "1.5rem"};
	`;
	return (
		<>
			<svg className="error_img" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle r="4.85" transform="matrix(1 0 0 -1 6 6)" stroke={colorMap[status]} strokeWidth="0.8" />
				<circle cx="6" cy="7.75008" r="0.675" fill={colorMap[status]} stroke={colorMap[status]} strokeWidth="0.4" />
				<path
					d="M5.50005 4.07508C5.50005 3.79894 5.72391 3.57508 6.00005 3.57508C6.27619 3.57508 6.50005 3.79894 6.50005 4.07508V5.30008C6.50005 5.57622 6.27619 5.80008 6.00005 5.80008C5.72391 5.80008 5.50005 5.57622 5.50005 5.30008V4.07508Z"
					fill={colorMap[status]}
					stroke={colorMap[status]}
					strokeWidth="0.4"
				/>
			</svg>

			<InfoTextSpan className="error_text" textColor={status}>
				{text}
			</InfoTextSpan>
		</>
	);
};

export default Error;
