import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Modal } from "@mui/material";

const ViewChequeModal = ({ 
    handleViewCheque, 
    name, 
    mobileNumber, 
    date,
    ChequePhoto,
    open
 }) => {
    return (
        <Modal open = {open} >
            <div className="view_cheque_modal">
                <div className="justify-content-between d-flex mb-3">
                    <p className="payment_details_list_card_header value big" style={{ fontSize: "18px", marginTop: "0.2rem" }}>
                        Cheque Details
                    </p>
                    <CloseIcon onClick={handleViewCheque} style={{ width: "22px", height: "22px", cursor: "pointer" }} />
                </div>

                <div 
                    className="d-flex payment_details_card_row align-items-start add_course_division justify-content-between"
                    style={{ gap: 0 }}
                >
                    <div className="paymanet_details_card_divider3 cheque ">
                        <div className="payment_details_list_card_header">Student Name</div>
                        <p className="payment_details_list_card_header value">{name}</p>
                    </div>

                    <div className="paymanet_details_card_divider5">
                        <div className="payment_details_list_card_header">Phone number</div>
                        <p className="payment_details_list_card_header value">+91 {mobileNumber}</p>
                    </div>

                    <div>
                        <div className="payment_details_list_card_header">Entry date</div>
                        <p className="payment_details_list_card_header value">{moment(date).format("MMM DD, YYYY")}</p>
                    </div>
                </div>

                <img className="full-width add_course_division" src={ChequePhoto} style={{ width: "100%" }} alt="" />
            </div>
        </Modal>
    );
};

export default ViewChequeModal;
