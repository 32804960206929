import { HANDLE_CHAPTER_DETAILS, RESET_CHAPTER_DETAILS, SET_CHAPTER_SCROLL } from '../actions/types';

const initialState = {
    chapterList: [],
    scrollPositionY: 0,
    searchText: "",
    skip: 0,
    limit: 20,
    continueScroll: false
};
//eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_CHAPTER_DETAILS:
            return {
                ...state,
                ...action.payload
            };
        case RESET_CHAPTER_DETAILS:
            return initialState;

        case SET_CHAPTER_SCROLL:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
