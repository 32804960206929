import React, { useState } from "react";
import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MobileMenu from "../../assets/img/mobile_menu.svg";
import Container from '@mui/material/Container';

import myclassroom_logo from "../../assets/img/mycrm-logo.svg";
import { Link } from "react-router-dom";

import Drawer from '@mui/material/Drawer';
import Navbar from "./Navbar";

const ResponsiveAppBar = ({
    changePassword
}) => {

    const [openSidebar, setOpenSidebar] = useState(false);

    return (
        <>
            <Drawer
                anchor={"left"}
                open={openSidebar}
                onClose={() => setOpenSidebar(false)}
            >
                <Navbar 
                    mobile={true} 
                    changePassword = {() => {
                        setOpenSidebar(false);
                        changePassword();
                    }}
                    closePanel = {() => {
                        setOpenSidebar(false);
                    }}
                />
            </Drawer>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters className="mobile_topbar">

                        {/* <div className="d-flex align-items-center">
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => setOpenSidebar(true)}
                                color="inherit"
                            >
                                <img src={MobileMenu} alt="menu"  />
                            </IconButton>

                            <span className="mobile_header_text">
                                Dashboard
                            </span>
                        </div> */}

                        <div className='nav_heading'>
                            <Link to="/dashboard">
                                <img src={myclassroom_logo} alt='LOGO' />
                            </Link>
                        </div>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => setOpenSidebar(true)}
                            color="inherit"
                        >
                            <img src={MobileMenu} alt="menu"  />
                        </IconButton>

                    </Toolbar>
                </Container>
            </AppBar>

        </>
    );
};
export default ResponsiveAppBar;