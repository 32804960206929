import React from "react";
import { currencyFormatter } from "../../../utils/helperFunctions";

const InstallmentConfirmation = ({
    instalments,
    numberToCardinal,
    installments_amount,
    isDisabled,
    confirmInstallment,
    installmentLoading,
    convertDate
}) => {
    return (
        <div className="modal-content">
            <div className="modal-body">
                <div className="content">
                    <h2>Are you sure you want to confirm the installment plan?</h2>
                    <h3>Installment Summary</h3>
                    <div className="tableWrap">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Installment No.</th>
                                    <th>Due Date</th>
                                    <th>Installment Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    instalments.map((installment, i) => {
                                        return (
                                            <tr key={`inst${i}`}>
                                                <td>{`${numberToCardinal(installment.installment_no)} Installment`}</td>
                                                <td>{convertDate(installment?.start_date)}</td>
                                                <td> {currencyFormatter(Number(installment.amount_per_installment))}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2}>Sum Of Installments:</td>
                                    <td>{currencyFormatter(installments_amount)}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="d-flex btnsWrap">
                            <button
                                type="button"
                                className="btn modal-button cancelBtn"
                                data-dismiss="modal"
                                style={{
                                    backgroundColor: "#ffffff",
                                    border: "1px solid #012C63",
                                }}
                            >
                                Cancel
                            </button>

                            <button
                                type="button"
                                className="btn btn-primary modal-button confirmBtn2"
                                data-dismiss="modal"
                                disabled={isDisabled}
                                onClick={() => confirmInstallment()}
                            >
                                Confirm Installment
                                {installmentLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstallmentConfirmation;