import React from "react";
import { Select,Tooltip } from "antd";
import { Capitalize } from "../../../utils/helperFunctions";

const { Option } = Select;

const SelectWithTooltip = ({
    value,
    setValue,
    data,
    keyValue = false,
    placeholder,
    capitalize = false,
    useDefaultValue = false,
    selectAll=false,
    keyID= false
}) => {
    
     const handleToolTipValue= (valuelist,) => {
        let outputList=[]
        // console.log("value list:",valuelist)
        // eslint-disable-next-line array-callback-return
        valuelist.map((item)=>{outputList.push(item.label)})
        return outputList.join(', ')
    }

    const selectProps = useDefaultValue ? { defaultValue: value } : {value};

    return (
            <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={placeholder}
                showArrow
                showSearch
                allowClear
                optionFilterProp="children"
                maxTagCount="responsive"
                autoClearSearchValue={false}
                maxTagPlaceholder={(omittedValues)=>{return <Tooltip title={handleToolTipValue(omittedValues)}>+ {omittedValues.length} ...</Tooltip> }}
                {...selectProps}
                onChange={selectedData => {
                    setValue(selectedData);
                }}
            >
                {
                    selectAll &&
                    <Option value={"Select All"}>
                        Select All
                    </Option>
                }
                {
                    data && data.map((options, index) => {
                    return (
                        <Option key={index} value={keyValue ? keyID ? options[keyID] : options.id : options}>
                            {
                                keyValue ? 
                                capitalize ? Capitalize(options[keyValue]) : options[keyValue] 
                                : 
                                capitalize ? Capitalize(options) : options
                            }
                        </Option>
                    );
                })}
            </Select>

          
    
    );
};

export default SelectWithTooltip;