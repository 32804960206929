import React, { useEffect, useState, useCallback, useRef } from "react";
import { useClickOutside } from '../../../utils/hooks/use-click-outside';
import { Dropdown } from 'antd';

const Tab = ({
    selectedCenterId,
    setSelectedCenterId,
    centerIds,
    setCenterIds,
    centerList,
    centerIDRef = null
}) => {

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [filteredCenterList, setFilteredCenterList] = useState([]);
    const [searchText, setSearchText] = useState("");

    const dropdownRef = useRef(null);
    const selectedCenterRef = useRef(selectedCenterId);

    const clickTab = (centerId) => {
        if (centerIDRef) {
            centerIDRef.current = centerId;
        }
        setSelectedCenterId(centerId);
        selectedCenterRef.current = centerId;
    };

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
        let filteredData = centerList.filter(i => i.school_name.toLowerCase().includes(e.target.value.toLowerCase()))
        setFilteredCenterList(filteredData)
    }

    const selectCenterHandler = (schoolId) => {
        // const centerIndex = centerList.findIndex(i => i.school_id === schoolId)
        // if (centerIndex + 1 === centerList.length) {
        //     setCenterIds(curState => {
        //         return { tab1: centerList[centerIndex - 1].school_id, tab2: schoolId }
        //     })
        // } else {
        //     setCenterIds(curState => {
        //         return { tab1: schoolId, tab2: centerList[centerIndex + 1].school_id }
        //     })
        // } 
        setCenterIds({
            tab1: centerIds.tab1 === selectedCenterRef.current ? schoolId : centerIds.tab1,
            tab2: centerIds.tab2 === selectedCenterRef.current ? schoolId : centerIds.tab2,
        })
    }

    const closeDropdown = useCallback(() => {
        setDropdownVisible(false)
        setSearchText("")
    }, [])

    useClickOutside(dropdownRef, closeDropdown)

    useEffect(() => {
        if (centerList && centerList.length >= 2) {
            if (centerIDRef) {
                centerIDRef.current = centerList[0].school_id;
            }
            setSelectedCenterId(centerList[0].school_id)
            selectedCenterRef.current = centerList[0].school_id;
            setCenterIds({
                tab1: centerList[0].school_id,
                tab2: centerList[1].school_id,
            })
        } else if (centerList && centerList.length >= 1) {
            if (centerIDRef) {
                centerIDRef.current = centerList[0].school_id;
            }
            setSelectedCenterId(centerList[0].school_id);
            selectedCenterRef.current = centerList[0].school_id;
            setCenterIds({
                tab1: centerList[0].school_id,
                tab2: null
            })
        }

    }, [centerList])

    return (
        <ul className="nav nav-tabs" style={{ marginTop: 20 }} >

            {
                centerIds?.tab1 && centerList.filter(x => x.school_id === centerIds?.tab1).map(center => {
                    return (
                        <li
                            key = {center.school_id}
                            className = {selectedCenterId === center.school_id ? "active" : ""}
                            onClick = {() => clickTab(center.school_id)}
                        >
                            <a
                                className={`fee_summary_tab mr-3 ${selectedCenterId === center.school_id ? "fee_summary_tab_selected active_tab border-bottom-none" : ""}`}
                            >
                                {center.school_name}
                            </a>
                        </li>
                    )
                })
            }

            {
                centerIds?.tab2 && centerList.filter(x => x.school_id === centerIds?.tab2).map(center => {
                    return (
                        <li
                            key = {center.school_id}
                            className = {selectedCenterId === center.school_id ? "active" : ""}
                            onClick = {() => clickTab(center.school_id)}
                        >
                            <a
                                className={`fee_summary_tab mr-3 ${selectedCenterId === center.school_id ? "fee_summary_tab_selected active_tab border-bottom-none" : ""}`}
                            >
                                {center.school_name}
                            </a>
                        </li>
                    )
                })
            }

            {/* {
                centerList.map((center, index) => {
                    if (center.school_id === centerIds.tab1 || center.school_id === centerIds.tab2) {
                        return (
                            <li
                                key = {center.school_id}
                                className = {selectedCenterId === center.school_id ? "active" : ""}
                                onClick = {() => clickTab(center.school_id)}
                            >
                                <a
                                    className={`fee_summary_tab mr-3 ${selectedCenterId === center.school_id ? "fee_summary_tab_selected" : ""}`}
                                >
                                    {center.school_name}
                                </a>
                            </li>
                        )
                    }
                })
            } */}

            {
                centerList && centerList.length > 2 &&
                <Dropdown 
                    visible={dropdownVisible} 
                    autoFocus 
                    overlay={
                        <div className='modal_div toggle_centre_modal' ref={dropdownRef} >
                            <div className="d-flex align-items-center " style={{ width: "100%", }} >
                                <input 
                                    className="course_search_input2"
                                    placeholder="Search"
                                    style={{ width: '100%' }}
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                />

                            </div>
                            {!!searchText ?
                                filteredCenterList.filter((center, index) => center.school_id !== centerIds.tab1 && center.school_id !== centerIds.tab2).map((center, ind) => {
                                    return (
                                        <button 
                                            className='modal_item_list_button'
                                            onClick={() => {
                                                setDropdownVisible(false)
                                                selectCenterHandler(center.school_id)
                                                clickTab(center.school_id)
                                                setSearchText("")
                                            }}
                                            key={center.school_id} 
                                        >
                                            {center.school_name}
                                        </button>
                                    )
                                })
                                :
                                centerList.filter((center, index) => center.school_id !== centerIds.tab1 && center.school_id !== centerIds.tab2).map((center, ind) => {
                                    return (
                                        <button 
                                            className='modal_item_list_button' 
                                            onClick={() => {
                                                setDropdownVisible(false)
                                                selectCenterHandler(center.school_id)
                                                clickTab(center.school_id)
                                            }}
                                            key={center.school_id} 
                                        >
                                            {center.school_name}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    } 
                    trigger={['click']}
                >
                    <li>
                        <a onClick={(e) => {
                            e.preventDefault()
                            setDropdownVisible(curState => !curState)
                        }}
                            className={`dots`}
                        >
                            ...
                        </a>

                    </li>
                </Dropdown>
            }
        </ul>
    )
};

export default Tab;