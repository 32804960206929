import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Api from './api';
import { useSelector } from 'react-redux';

let lastVisitedPage = "";

const PrivateRoute = ({ component: Component, ...props }) => {

    useEffect(() => {
        if (lastVisitedPage !== window.location.pathname) {
            Api.trackPage()
            lastVisitedPage = window.location.pathname;
        }        
    }, [window.location.pathname]);

    const { pages, permissionApiCalled } = useSelector(state => state.auth);

    let hasPermission = true;

    if(props.resource && permissionApiCalled){
        let index = pages.findIndex(x => x.page === props.resource);

        hasPermission = index === -1 ? false : true;
    }

    if (Api.isAuthenticated() && hasPermission) {
        return <Component {...props} />
    } 

    return <Navigate to="/" />
};

export default PrivateRoute;