import React from "react";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		arrow
		classes={{ popper: `${className} tooltip_header` }}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
	  color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
	  backgroundColor: theme.palette.common.black,
	  fontSize: "13px"
	},
}));

const TooltipComponent = (props) => {
    return (
        <BootstrapTooltip
            disableFocusListener
            enterTouchDelay={0}
            title={props?.text || ""}
        >
            {props.children}
        </BootstrapTooltip>
    );
};

export default TooltipComponent;