import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxWidth: 500,
    width: "90%",
    padding: "3rem",
    borderRadius: 4,
};

const Confirmation = ({
    updateLoading,

    createModal,
    closeCreateModal,

    updateModal,
    closeUpdateModal,

    subject_name,
    updateSubject,
    checkUserHasUpdated
}) => {
    return (
        <>
            <Modal
                open={createModal}
                onClose={closeCreateModal}
                className="payment_reversal_cofirmation_modal"
            >
                <Box sx={style}>
                    <p className="reversal_accept_text">
                        Are you sure you want to create ‘{subject_name}’ as a subject?
                    </p>

                    <div
                        className="mt-3"
                        style={{ float: "right" }}
                    >
                        <button
                            className="btn btn-md student_detail_no"
                            onClick={closeCreateModal}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-md student_detail_password_confirm ml-2"
                            onClick={() => {
                                closeCreateModal();
                                updateSubject();
                            }}
                        >
                            Save
                            {/* {updateLoading && <span className="ml-2 spinner spinner-white mr-3"></span>} */}
                        </button>
                    </div>

                </Box>
            </Modal>

            <Modal
                open={updateModal}
                onClose={closeUpdateModal}
                className="payment_reversal_cofirmation_modal"
            >
                <Box sx={style}>
                    <p className="reversal_accept_text">
                        Are you sure you want to save changes in subject management?
                    </p>

                    <div className="mb-3 modal_save_confirmation_wrapper">
                        {
                            checkUserHasUpdated().map((data, index) => {
                                return (
                                    <div className="modal_save_confirmation_container" key={index}>
                                        <p className="modal_save_confimation_header">
                                            {data.header}
                                        </p>
                                        <span className="modal_save_confimation_value">
                                            {data.value}
                                        </span>
                                    </div>
                                )
                            })
                        }

                    </div>

                    <div
                        className="mt-3"
                        style={{ float: "right" }}
                    >
                        <button
                            className="btn btn-md student_detail_no"
                            onClick={closeUpdateModal}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-md student_detail_password_confirm ml-2"
                            onClick={() => {
                                closeUpdateModal();
                                updateSubject();
                            }}
                        >
                            Save
                            {/* {updateLoading && <span className="ml-2 spinner spinner-white mr-3"></span>} */}
                        </button>
                    </div>

                </Box>
            </Modal>
        </>
    );
};

export default Confirmation;