import React, { useState, useEffect } from 'react'
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Select, DatePicker } from 'antd';
import { useMediaQuery } from "@mui/material";
import { Asterisk } from "../../../utils/helperFunctions";
import AddCircle from "../../../assets/img/add_circle.svg";
import moment from "moment";
import ErrorText from "../../common/errorText";
import swal from "sweetalert2";
import CommonModal from './CommonModal';

const brandOptions = ["Samsung", "Lenovo"];
const { Option } = Select;

const EditResourcesTab = ({ editLoading,tabData, postTabData ,tempTabList,setTempTabList, showTabModal,setShowTabModal, includesTab, setIncludesTab, tabDataInitialState, goBack }) => {

    const isMobile = useMediaQuery("(max-width: 850px)");
    const [errorState, setErrorState] = useState({})
    const [TabModalFromResources, setTabModalFromResources] = useState(false)

    const toggleSwitch = (toggle) => {
        if (toggle) {
            //set list to inital state
            setErrorState({})
            setTempTabList(tabData.length === 0 ? Array(tabDataInitialState) : [])
        }
        tabData.length === 0 && setIncludesTab(toggle => !toggle)
    }
    
    function deleteAndShift(object, key) {
        delete object[key];
        while (++key in object) {
            object[key - 1] = object[key];
            delete object[key];
        }
    }

    const rowDel = (index) => {
        let temp = errorState;
        deleteAndShift(errorState,index)
        console.log(temp)
        setErrorState({...temp})

        let newData = tempTabList.filter((x, ind) => index !== ind)
        setTempTabList(newData)
    }

    const checkErrors = (tabData) => {
        let noErrors = true;
        let errors = { ...errorState }
        // eslint-disable-next-line array-callback-return
        tabData.map((data, index) => {
            let d = errors[index]
            if (data.brand_name.length === 0) {
                if (d) {
                    d = { ...d, brand_name: 'Please select tab brand' }
                }
                else { d = { brand_name: 'Please select tab brand' } }
                errors = { ...errors, [index]: { ...d } }
                noErrors = false;
            }
            if (data.serial_number === "") {
                if (d) {
                    d = { ...d, serial_number: 'Please enter serial number' }
                }
                else { d = { serial_number: 'Please enter serial number'} }
                errors = { ...errors, [index]: { ...d } }
                noErrors = false;
            }
            if (data.issue_date === null) {
                if (d) {
                    d = { ...d, issue_date: 'Please select issue date' }
                }
                else { d = { issue_date: 'Please select issue date'} }
                errors = { ...errors, [index]: { ...d } }
                noErrors = false;
            }
        })
        setErrorState(errors)
        return noErrors;

    }

    const setData = (payload, index) => {
        let key = Object.keys(payload)
        // reseting error message on  edit
        let temp = errorState;
        if (errorState[index]) {
            temp[index][key] = ""
            setErrorState(temp)
        }
        //setting data
        setTempTabList(tempTabList.map((data, dataIndex) => {
            if (index === dataIndex) {
                return { ...data, ...payload }
            }
            return { ...data }
        }))
    }

    return (
        <div className={`courseDetailWrap${ isMobile ? " is-mobile" : "" }`}>

            <div className="tab_card_div" >

                <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }} >
                    <div className="add_course_resource_container">
                        <span className="course_tab_text">
                            Course includes tab?
                        </span>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={
                                        includesTab
                                    }
                                    color={includesTab ? "success" : "error"}
                                />
                            }
                            // disabled={tabData.length > 0}
                            onClick={(value) => { tabData.length === 0 ? toggleSwitch(value) : swal.fire("", `You cannot disable this feature as you have already added tab details. <br> Please reach out to <b>care@myclassroom.digital</b> for assistance.`, "warning") }}
                            className="student_course_toggle_text"
                            style={{
                                marginTop: "0.5rem"
                            }}
                        />

                        {includesTab ? <span className="add_course_resource_text active true">
                            Yes
                        </span> : <span className="add_course_resource_text inactive">
                            No
                        </span>}
                    </div>

                    {/* non editable Tab Data */}
                    {tabData.length > 0 && tabData.map((item, index) =>
                        <div key={index} className="add_tab_wrapper" >
                            <div className="tab_text" >
                                <span className={includesTab ? "tab_number_text_active" : "tab_number_text_inactive"} >
                                    Tab{index + 1}
                                </span>

                                <div className="add_tab_wrapper" style={{ flexDirection: 'row' }} >
                                    <div className="add_tab_container"  >
                                        <div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ width: "100%" }} >


                                            <p className={includesTab ? "cheque_filter_drop_header mb-1" : "cheque_filter_drop_header_inactive"}>
                                                Brand <Asterisk color={includesTab ? "red" : "#B7B7B7"} />
                                            </p>

                                            <Select
                                                style={{ width: "100%" }}
                                                placeholder="Select brand"
                                                showArrow
                                                allowClear
                                                disabled={true}
                                                value={item.brand_name}
                                            >
                                                {brandOptions.map((item, index) => {
                                                    return (
                                                        <Option key={index} value={item}>
                                                            {item}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>

                                        <div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ width: "100%" }} >
                                            <p className={includesTab ? "cheque_filter_drop_header mb-1" : "cheque_filter_drop_header_inactive"}>
                                                Serial number
                                                <Asterisk color={includesTab ? "red" : "#B7B7B7"} />
                                            </p>
                                            <input
                                                type="text"
                                                step="0.01"
                                                min="0.01"
                                                maxLength='30'
                                                className={`change_pw_input hidden_arrows_input_full_width`}
                                                value={item.serial_number}
                                                placeholder="Enter device S/N"
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ width: "100%" }} >
                                            <p className={includesTab ? "cheque_filter_drop_header mb-1" : "cheque_filter_drop_header_inactive"}>
                                                Issue date <Asterisk color={includesTab ? "red" : "#B7B7B7"} />
                                            </p>
                                            <DatePicker
                                                allowClear={false}
                                                disabled={true}
                                                className="attendance_date_picker mb-0"
                                                getPopupContainer={(triggerNode) => {
                                                    return triggerNode.parentNode;
                                                }}
                                                format={"DD/MM/YYYY"}
                                                value={moment(item.issue_date)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>)}
                    {/* non editable Tab Data */}

                    {tempTabList.map((item, index) =>
                        <div key={index} className="add_tab_wrapper" >
                            <div className="tab_text" >
                                <span className={includesTab ? "tab_number_text_active" : "tab_number_text_inactive"} >
                                    Tab{tabData.length + index + 1}
                                </span>
                                <div className="add_tab_wrapper" style={{ flexDirection: 'row' }} >
                                    <div className="add_tab_container"  >
                                        <div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ width: "100%" }} >

                                            <p className={includesTab ? "cheque_filter_drop_header mb-1" : "cheque_filter_drop_header_inactive"}>
                                                Brand <Asterisk color={includesTab ? "red" : "#B7B7B7"} />
                                            </p>

                                            <Select
                                                style={{ width: "100%" }}
                                                placeholder="Select brand"
                                                showArrow
                                                allowClear
                                                disabled={!includesTab}
                                                value={item.brand_name}
                                                onChange={value => { setData({ brand_name: value }, index) }}
                                            >
                                                {brandOptions.map((item, index) => {
                                                    return (
                                                        <Option key={index} value={item}>
                                                            {item}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>

                                            {errorState && errorState[index] && errorState[index].brand_name && <ErrorText text={errorState[index].brand_name} />}

                                        </div>

                                        <div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ width: "100%" }} >
                                            <p className={includesTab ? "cheque_filter_drop_header mb-1" : "cheque_filter_drop_header_inactive"}>
                                                Serial number
                                                <Asterisk color={includesTab ? "red" : "#B7B7B7"} />
                                            </p>
                                            <input
                                                type="text"
                                                maxLength='30'
                                                className={`change_pw_input hidden_arrows_input_full_width`}
                                                value={item.serial_number}
                                                placeholder="Enter device S/N"
                                                disabled={!includesTab}
                                                onChange={e => setData({ serial_number: e.target.value.replace(/\s/g, "")}, index)}
                                            />

                                            {errorState && errorState[index] && errorState[index].serial_number && <ErrorText text={errorState[index].serial_number} />}


                                        </div>
                                        <div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown" style={{ width: "100%" }} >
                                            <p className={includesTab ? "cheque_filter_drop_header mb-1" : "cheque_filter_drop_header_inactive"}>
                                                Issue date <Asterisk color={includesTab ? "red" : "#B7B7B7"} />
                                            </p>
                                            <DatePicker
                                                allowClear={false}
                                                disabled={!includesTab}
                                                className="attendance_date_picker mb-0"
                                                getPopupContainer={(triggerNode) => {
                                                    return triggerNode.parentNode;
                                                }}
                                                format={"DD/MM/YYYY"}
                                                value={item.issue_date}
                                                onChange={date => {console.log(date, typeof data)
                                                setData({ issue_date: date }, index) 
                                                    }
                                                }

                                            />
                                            {errorState && errorState[index] && errorState[index].issue_date && <ErrorText text={errorState[index].issue_date} />}
                                        </div>
                                    </div>
                                    {(tabData.length > 0 ? true : index > 0) && <div style={{ display: 'flex', width: '20%', }} >
                                        {includesTab && <button className="addbtn3" data-toggle="modal" style={{ right: 0 }}
                                            data-target="#exampleModal"
                                            onClick={() => rowDel(index)}
                                        >
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </button>}
                                    </div>}
                                </div>
                            </div>

                        </div>)}

                    {includesTab &&
                        <div className="add_tab_wrapper" style={{ width: '80%' }} >

                            <div
                                className="add_student_container"
                                style={{ height: "126px" }}
                                onClick={() => {
                                    //add more tab
                                    tempTabList.length + tabData.length < 5 ? setTempTabList([...tempTabList, tabDataInitialState]) :
                                        swal.fire("Maximum Limit Reached", "You've already added max. limit of 5 tabs for the student. <br>If you want to add more,please reach out to <b>care@myclassroom.digital</b> for assistance", 'warning');
                                }}
                            >
                                <div className="add_student_text_container">
                                    <img
                                        src={AddCircle}
                                        alt="add"
                                        className="add_student_img"
                                    />

                                    <span className="add_student_container_text">
                                        Add another tab
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between" style={{ width: '100%', paddingTop: 20 }} >
                                <button
                                    className="btn btn-md add_tab_back"
                                    onClick={() =>  goBack()}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-md add_tab_next ml-2"
                                    disabled={tempTabList.length===0}
                                    onClick={() => {
                                        if ((tempTabList.length > 0 && checkErrors(tempTabList))) {
                                            let postData = [];
                                            // eslint-disable-next-line array-callback-return
                                            tempTabList.map((tab) => {
                                                let obj = {};
                                                obj.brand_name = tab.brand_name;
                                                obj.serial_number = tab.serial_number;
                                                obj.issue_date = moment(tab.issue_date).format('YYYY-MM-DD hh:mm:ss');
                                                postData.push(obj)
                                            })
                                            setShowTabModal(true)
                                            setTabModalFromResources(true)
                                            
                                        }
                                    }}
                                >
                                   Save {editLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                                    
                                </button>
                            </div>
                        </div>
                    }
                </div>

            </div>

         <CommonModal
                message={!TabModalFromResources ? "Click yes to save changes. If you click no, then all unsaved changes will be lost" : "Please verify tab details properly before you confirm. Once confirmed, you won't be able to edit the details."}
                isOpen={showTabModal}
                setIsOpen={setShowTabModal}
                onCancel={() => {
                    setTabModalFromResources(false)
                    toggleSwitch(includesTab)
                    setShowTabModal(false)
                }}
                onClose={()=>{
                    setShowTabModal(false)
                    setTabModalFromResources(false)
                }}
                onSave={() => {
                    if ((tempTabList.length > 0 && checkErrors(tempTabList))) {
                        let postData = [];
                        // eslint-disable-next-line array-callback-return
                        tempTabList.map((tab) => {
                            let obj = {};
                            obj.brand_name = tab.brand_name;
                            obj.serial_number = tab.serial_number;
                            obj.issue_date = moment(tab.issue_date).format('YYYY-MM-DD hh:mm:ss');
                            postData.push(obj)
                        })
                        postTabData(postData)
                    }
                    setShowTabModal(false)
                    setTabModalFromResources(false)
                }
            }
            /> 

        </div>
    )
}

export default EditResourcesTab