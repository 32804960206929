import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2'
import {  useNavigate, useParams } from 'react-router-dom';
import { httpGet } from "../../utils/https";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import moment from 'moment';

import { useSelector } from "react-redux";

function AddCourse() {
  let data = localStorage.getItem("userdetail");
  data = JSON.parse(data);

  const navigate = useNavigate();
  const { student_id } = useParams();

  const { centerList } = useSelector((state) => state.auth);

  const [studentCourses, setStudentCourses] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [classList, setClassList] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [showCourseForm, setShowCourseForm] = useState(false);
  const [studentDetails, set_studentDetails] = useState(null);
//   const [switchColor, setSwitchColor] = useState('');
  const [admission_form_list, set_admission_form_list] = useState([]);

  const getStudentDetails = async () => {
    const studentDetailsRes = await httpGet(`${process.env.REACT_APP_BASE_URL}users/get-student-details/?student_id=${student_id}`);
    if (studentDetailsRes.status === 200) {
      set_studentDetails(studentDetailsRes.data);
    } else {
      console.log(studentDetailsRes.data.payload.Error);
    }
  };

  const getSectionList = async () => {
    if (selectedClass !== "" && selectedSchool !== "") {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/get-section-class/?class=${selectedClass}&school=${selectedSchool}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${data.token}`,
          },
        });
        const D = await response.json();
        if (response.status === 401) {
          Swal.fire("Unauthorised?", "User logged into another device", "error");
          localStorage.clear();
          navigate("/error");
        }
        setSectionList(D);
        console.log(D, "section List");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getClasses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}support/get-class/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${data.token}`,
        },
      });
      const D = await response.json();
      setClassList(D);
      console.log(D, "data");
    } catch (err) {
      console.log(err);
    }
  };

  const getCoursesListForStudent = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/list-of-course-for-a-student/?student_id=${student_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${data.token}`,
        },
      });
      const D = await response.json();
      if (response.status === 401) {
        Swal.fire("Unauthorised?", "User logged into another device", "error");
        localStorage.clear();
        navigate("/error");
      }
      console.log(D.payload.courses, "student course list");
      setStudentCourses(D.payload.courses);
    } catch (err) {
      console.log(err);
    }
  };

  const addCourseForStudent = async () => {
    if (selectedSection === "") {
      Swal.fire("Select a Course", "Course Empty", "warning");
    } else {
      try {
        var formdata = new FormData();
        formdata.append("student_id", student_id);
        formdata.append("school_id", selectedSchool);
        formdata.append("section_id", selectedSection);

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/add-course-by-student/`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Token ${data.token}`,
          },
          body: formdata,
        });
        const D = await response.json();
        if (response.status === 401) {
          Swal.fire("Unauthorised?", "User logged into another device", "error");
          localStorage.clear();
          navigate("/error");
        }
        // setClassList(D)

        Swal.fire(`${D.message}`);
        getCoursesListForStudent();
        setShowCourseForm(false);
        console.log(D, "Course Added");
      } catch (err) {
        console.log(err);
        Swal.fire(`${err.message}`);
      }
    }
  };

  const updateCourse = async (student_course_id, status) => {
    try {
      if (status === true) {
        // setSwitchColor('success');
      } else {
        // setSwitchColor('error');
      }
      var formdata = new FormData();
      formdata.append("student_course_id", student_course_id);
      formdata.append("status", !status);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/activate-deactivate-student-course/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
          Authorization: `Token ${data.token}`,
        },
        body: formdata,
      });
      const D = await response.json();
      if (response.status === 401) {
        Swal.fire("Unauthorised?", "User logged into another device", "error");
        localStorage.clear();
        navigate("/");
      }
      // setClassList(D)

      Swal.fire(`${D.message}`);
      getCoursesListForStudent();
      console.log(D, "Course updated");
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.payload?.Error) {
        Swal.fire(`${err.response.data.payload.Error}`);
      } else {
        Swal.fire(`${err.message}`);
      }
    }
  };

  const handleChangeSchool = (event) => {
    setSelectedSchool(event.target.value);
  };

  const handleChangeClass = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleChangeSection = (event) => {
    setSelectedSection(event.target.value);
  };

  const viewAllPdf = (item) => {
    set_admission_form_list(item.admission_forms_data);
  };

  useEffect(() => {
    getStudentDetails()
    getClasses()
    getCoursesListForStudent()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getSectionList()
    setSelectedSection('')
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass, selectedSchool])

//   const columns = [
//     {
//       name: "S. No.",
//       selector: (row, index) => index + 1,
//       width: "5%",
//     },
//     // {
//     //   name: "Name",
//     //   selector: (row) => row.name,
//     //   width: "30%",
//     // },
//     {
//       name: "Student Course Id",
//       //   selector: (row) => row.name,
//       width: "25%",
//       cell: (rowData) => (
//         <div>
//           {rowData.student_course_id}
//         </div>
//       ),
//     },
//     {
//       name: "Section Name",
//       //   selector: (row) => row.email,
//       width: "25%",
//       cell: (rowData) => (
//         <div>
//           {rowData.section_name}
//         </div>
//       ),
//     },
//     {
//       name: "School Name",
//       selector: (row) => row.email,
//       width: "25%",
//       cell: (rowData) => (
//         <div>
//           {rowData.school_name}
//         </div>
//       ),
//     },
//   ];

  return (
    <>
      {/* Popup  Start */}
      <div className="modal fade paymentPopup" id="myModal" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">Admission forms</h4>
            </div>
            <div className="modal-body">
              <div className="installmentList">
                <table rules="rows" className="table">
                  <thead>
                    <tr>
                      <th>Uploaded On</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {admission_form_list.map((item, index) => {
                      return (
                        <tr>
                          <td>{moment(item.created_on).format("DD-MM-YYYY HH:mm ")}</td>
                          <td>{item.registration_form_url.substring(36)}</td>
                          <td>
                            <a href={item.registration_form_url} target="_blank" rel="noreferrer" style={{ color: "#012C63", fontSize: "12px", fontWeight: "600", textDecoration: "none", cursor: "pointer" }}>
                              View
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Popup  End */}

      {/* <div className='topHeader'>
        <TopNavBar />
      </div> */}
      <div className="containerMain courseDetail">
        <div className="studentDetailBar">
          <div className="bgFull">
            <div className="container1">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <p>
                    <label>
                      <b>Student Name </b>
                    </label>{" "}
                    : {studentDetails?.student_data?.name}
                  </p>
                </div>
                <div className="col-md-4 col-sm-12">
                  <p>
                    <label>
                      <b>Mobile Number </b>
                    </label>{" "}
                    : {studentDetails?.student_data?.mobile_number}
                  </p>
                </div>
                <div className="col-md-4 col-sm-12">
                  <p>
                    <label>
                      <b>myClassroom Centre</b>
                    </label>{" "}
                    : {studentDetails?.student_data?.school_assoc?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="row">
              <div className="col-md-4 col-sm-12">Current Course</div>
            </div>
          </div>

          <div className="course-container">
            <div className="row">
              <div className="col-sm-7">
                {studentCourses?.length > 0 &&
                  studentCourses.map((item, index) => {
                    let switchClass = "clickActivate";
                    if (item.status) {
                      switchClass = "clickDeactivate";
                    }
                    return (
                      <div key={index} className={item.status ? "courseBox" : "courseBox deactivecourseBox"}>
                        <div className="row">
                          <div className="col-md-7">
                            <div className="form-group">
                              <label>Centre:</label>
                              <p> {item.school_name}</p>
                            </div>
                            <div className="form-group">
                              <label>Course : </label>
                              <p>{item?.course_name}</p>
                            </div>
                            <div className="form-group">
                              <label>Batch: </label>
                              <p>{item.section_name} </p>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <FormControlLabel
                              control={<Switch checked={item.status} color={item.status ? "success" : "error"} className={switchClass} />}
                              label={item.status ? `Click to deactivate` : `Click to activate`}
                              onClick={() => updateCourse(item.student_course_id, item.status)}
                              className="switchLabel"
                            />
                            {!item.status && <p style={{ color: "#C50404", fontSize: "12px", marginRight: "10px", float: "right" }}>Course deactivated</p>}
                            {item.status && <p style={{ color: "#038200", fontSize: "12px", marginRight: "10px", float: "right" }}>Course is Active</p>}
                          </div>
                        </div>
                        <div className="btnWrpp">
                          <Button
                            sx={{ m: 2, mb: 0, backgroundColor: "#EFF6FF", color: "#000000" }}
                            variant="contained"
                            size="large"
                            onClick={
                              () =>
                                navigate(`/student/upload-admission-form/${student_id}`, {
                                  state: {
                                    student_course_id: item.student_course_id,
                                    section_id: item.section_id,
                                    school_id: item.school_id,
                                    school_name: item.school_name,
                                    section_name: item.section_name,
                                  },
                                })
                              // Swal.fire(`Coming Soon`)
                            }
                          >
                            Upload Signed form
                          </Button>
                          <Button
                            sx={{ m: 2, mb: 0, backgroundColor: "#012C63" }}
                            variant="contained"
                            size="large"
                            onClick={() =>
                              item.is_installment_created
                                ? navigate(`/payment/fee-summary/${student_id}`, {
                                    state: {
                                      section_id: item.section_id,
                                      school_id: item.school_id,
                                      school_name: item.school_name,
                                      section_name: item.section_name,
                                      course_name: item.course_name,
                                    },
                                  })
                                : item.status
                                ? navigate(`/payment/create-installment/${item.school_id}/${item.section_id}/${student_id}`)
                                : Swal.fire(`Course is not active`)
                            }
                          >
                            Fee Summary
                          </Button>

                          {/* <Button sx={{ m: 2, mb: 0, backgroundColor: '#EFF6FF', color: '#000000' }}
                          variant="contained"
                          size="large"
                          onClick={() => updateCourse(item.student_course_id, item.status)}
                        >
                          {item.status ? `Deactivate Course` : `Activate Course`}
                        </Button> */}
                        </div>
                        {item?.admission_forms_data?.length > 0 && (
                          <div className="seeAllbtn">
                            <p>
                              {item.admission_forms_data[0]?.name} {moment(item.admission_forms_data[0]?.created_on).format("DD MMM,YYYY")} || {moment(item.admission_forms_data[0]?.created_on).format("HH:mm ")}{" "}
                              <a href={item?.admission_forms_data[0]?.registration_form_url} target="_blank" rel="noreferrer">
                                View
                              </a>
                            </p>
                            <a href="/#" data-toggle="modal" data-target="#myModal" onClick={() => viewAllPdf(item)}>
                              See all uploads
                            </a>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="col-md-5"></div>
            </div>
          </div>
          {showCourseForm && (
            <div className="course-container">
              <div className="row">
                <div className="col-sm-7">
                  <div className="courseBox addCourseBox">
                    <Box sx={{ "& .MuiTextField-root": { m: 2, width: "25ch" } }}>
                      <FormControl sx={{ m: 2, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-label">Centre</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedSchool} label="Centre" onChange={handleChangeSchool}>
                          {centerList.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.school_id}>
                                {item.school_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl sx={{ m: 2, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-label">Course</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedClass} label="Course" onChange={handleChangeClass}>
                          {classList.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.id}>
                                {item.class_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl sx={{ m: 2, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-label">Batch</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedSection} label="Batch" onChange={handleChangeSection} disabled={selectedSchool === "" || selectedClass === ""}>
                          {sectionList.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.id}>
                                {item.section_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Button sx={{ m: 2, backgroundColor: "#012C63" }} variant="contained" onClick={addCourseForStudent}>
                      Add Course
                    </Button>
                  </div>
                </div>
                <div className="col-md-5"></div>
              </div>
            </div>
          )}
          {!showCourseForm && (
            <div className="row">
              <div className="col-md-7">
                <div
                  className="addCourseBtn"
                  onClick={() => {
                    setShowCourseForm(true);
                  }}
                >
                  <p className="AddCourseWrp">
                    <i className="fa fa-plus-circle fa-2x"></i>
                    <span className="add-ins-text">Add Course</span>
                  </p>
                </div>
              </div>
              <div className="col-md-5"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AddCourse;
