import React, { useState, useCallback, useEffect, useRef } from 'react'
import LeftArrow from "../../../assets/img/backArrowBlack.svg";
import SearchIcon from "../../../assets/img/search.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SCRL_HANDLE_SEARCH_INIT, SCRL_RESET_LIST } from '../../../actions/types';
import { useScrollingList } from "../../../utils/hooks/use-scrolling-list";
import Loader from "../../common/Loader";
import Empty from "../../../assets/img/empty_student.svg";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import { Select } from "antd";
import QbCard from './QBCard'
import { handleFileResponse, handleFileResponsePost } from '../../../utils';


const { Option } = Select;

const initialState = {
    selectedCourse: null,
    selectedExam: null,
    selectedSubject: null,
    selectedChapter: null,
    courseList: [],
    targetExamList: [],
    subjectList: [],
    chapterList: []
}

const QuestionBankList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [noData, setNoData] = useState(false)
    const [state, setState] = useState(initialState)
    const [filterLoading, setFilterLoading] = useState(false);
    const [downloading, setDownloading] = useState(false)

    let SelectedCourseRef = useRef(state.selectedCourse)
    let SelectedExamRef = useRef(state.selectedExam)
    let SelectedSubjectRef = useRef(state.selectedSubject)
    let SelectedChapterRef = useRef(state.selectedChapter)

    const { limit } = useSelector((state) => state.scrollingList);

    const getFilterDataList = async () => {
        let url = `${ ApiUrl.QB }/list`;
        const data = await Api.doFetch("GET", {}, url);
        if (data.status) {
            const payload = data.data
            if (payload) {
                console.log(payload)
                handleState({ courseList: payload?.courses.sort((a, b) => a.course_name.localeCompare(b.course_name)) })
                handleState({ targetExamList: payload?.target_exams.sort((a, b) => a.target_exam.localeCompare(b.target_exam)) })
                handleState({ subjectList: payload?.subjects.sort((a, b) => a.subject_name.localeCompare(b.subject_name)) })
            }
        }
    }

    const getChapter = async (subjectID) => {
        let url = `${ ApiUrl.MPP }/list/chapters/${ subjectID }`;
        const data = await Api.doFetch("GET", {}, url);

        if (data.status) {
            const payload = data.data
            if (payload) {
                handleState({ chapterList: payload.sort((a, b) => a.chapter_name.trim().localeCompare(b.chapter_name.trim())) })
            }
        }
    }

    const getQBs = useCallback(async ({ skip = 0, search = "", limit = 10 }) => {
        let url = `${ ApiUrl.QB }/list`;
        let postData = {
            "skip": skip,
            "limit": limit,
        }
        if (SelectedCourseRef.current && SelectedCourseRef.current !== null) {
            postData.course_id = SelectedCourseRef.current
        }

        if (SelectedExamRef.current && SelectedExamRef.current !== null) {
            postData.target_exam_id = SelectedExamRef.current
        }
        if (SelectedSubjectRef.current && SelectedSubjectRef.current !== null) {
            postData.subject_id = SelectedSubjectRef.current
        }
        if (SelectedChapterRef.current && SelectedChapterRef.current !== null) {
            postData.chapter_id = SelectedChapterRef.current
        }

        if (search && search.trim().length > 0) {
            postData.search = search;
        }

        const data = await Api.doFetch("POST", postData, url);

        if (data?.status === true) {
            const payload = data.data;
            console.log(payload)
            if (payload) {
                const { question_banks: items, total, skip, limit } = payload;
                return {
                    items,
                    total,
                    skip,
                    limit,
                };
            }
            return data.data.mpps;
        }
        setNoData(true);
        return null;
    }, []);

    const getFilteredQBs = async () => {
        setFilterLoading(true);
        let payload = {
            skip: 0,
            limit: limit,
            search: searchText
        }
        const data = await getQBs({ ...payload });

        dispatch({
            type: SCRL_HANDLE_SEARCH_INIT,
            payload: {
                list: (data && data.items) || [],
                skip: 0,
                total: (data && data.total) || 0,
                scrollLoading: false,
            },
        });
        setFilterLoading(false);
    }

    const [scrollContainer, { loading, scrollLoading, list: qbList, handleScroll, searchText, handleSearch, saveScrollPosition, scrollPositionY }] = useScrollingList({
        listKey: "qbs",
        getData: getQBs,
    });

    const handleSearchText = (e) => {
        const value = e.target.value;
        handleSearch(value);
    };
    const handleState = (payload) => {
        setState((state) => { return { ...state, ...payload } })
    }
    const ClearFilter = () => {

        handleState({ selectedCourse: null })
        handleState({ selectedExam: null })
        handleState({ selectedSubject: null })
        handleState({ selectedChapter: null })


        SelectedCourseRef.current = null
        SelectedSubjectRef.current = null
        SelectedExamRef.current = null
        SelectedChapterRef.current = null
    }
    const downloadQB = async (qbID, name, type, payload = {}) => {
        setDownloading(true)
        !downloading && type === 'solution' && await handleFileResponse(`${ ApiUrl.QB }/download/solution/${ qbID }`, name + '.docx')
        !downloading && type === 'raw' && await handleFileResponse(`${ ApiUrl.QB }/download/original/${ qbID }`, name + '.docx')
        !downloading && type === 'sorting' && await handleFileResponsePost(`${ ApiUrl.QB }/download/${ qbID }`, name + '.docx', payload)
        setDownloading(false)
    }
    useEffect(() => {
        getFilterDataList()
    }, []);
    useEffect(() => {
        if (state.selectedSubject) {
            getChapter(state.selectedSubject)
        }
    }, [state.selectedSubject])

    return (
        <div className="mpp_list_container">
            <div className="course_list_header">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/qb");
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">List of QB exercises</span>
                </div>

                <div className="d-flex align-items-center course_search_wrapper">
                    <img src={SearchIcon} alt="search" className="course_search_image" />
                    <input
                        className="course_search_input"
                        defaultValue={searchText}
                        onChange={handleSearchText}
                        placeholder="Search"
                    />
                </div>
            </div>

            <div className=" d-column course_list_header justify-content-flex-start align-items-start" >
                <div className="d-flex" style={{ width: '100%' }}>

                    <div className="mpp_divider3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Course</p>
                        <div className="antd_dropdown" >
                            <Select style={{ width: "100%" }}
                                placeholder="All"
                                value={state.selectedCourse}

                                onChange={value => {
                                    handleState({ selectedCourse: value })
                                }}
                                showSearch
                                optionFilterProp="children"
                                showArrow
                                allowClear>
                                {state?.courseList?.map((course, index) => {
                                    return (
                                        <Option key={index} value={course.course_id}>
                                            {course.course_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>

                    <div className="mpp_divider3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Target exam</p>

                        <div className="antd_dropdown" >
                            <Select style={{ width: "100%" }}
                                placeholder="All"
                                value={state.selectedExam}
                                onChange={value => {
                                    handleState({ selectedExam: value })
                                }}
                                showSearch
                                optionFilterProp="children"
                                showArrow
                                allowClear>
                                {state?.targetExamList?.map((exam, index) => {
                                    return (
                                        <Option key={index} value={exam.id}>
                                            {exam.target_exam}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>

                    <div className="mpp_divider3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Subject</p>
                        <div className="antd_dropdown" >
                            <Select
                                style={{ width: '100%' }}
                                placeholder={"Select subject"}
                                onChange={value => {
                                    handleState({ selectedSubject: value })
                                }}
                                value={state.selectedSubject}
                                optionFilterProp="children"
                                showArrow
                                allowClear
                                showSearch
                            >
                                {
                                    state.subjectList && state.subjectList.map((options, index) => {
                                        return (
                                            <Option
                                                key={index}
                                                value={options.id}
                                            >{options.subject_name}</Option>
                                        )
                                    })
                                }
                            </Select>

                        </div>
                    </div>

                    <div className="mpp_divider3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Chapter</p>

                        <div className="antd_dropdown" >
                            <Select
                                style={{ width: '100%' }}
                                placeholder={"Select subject"}
                                onChange={value => {
                                    handleState({ selectedChapter: value })
                                }}
                                value={state.selectedChapter}
                                optionFilterProp="children"
                                showArrow
                                allowClear
                                showSearch
                            >
                                {
                                    state.chapterList?.map((options, index) => {
                                        return (
                                            <Option
                                                key={index}
                                                value={options.id}
                                            >{options.chapter_name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="filter-heading-col-assign-ques  padding_top">
                    <button
                        className="btn cheques_apply-filter-button mr-2"
                        onClick={() => {
                            console.log(state.selectedCourse);
                            if (state.selectedCourse !== null || state.selectedExam !== null || state.selectedSubject !== null || state.selectedChapter !== null) {
                                SelectedCourseRef.current = state.selectedCourse
                                SelectedSubjectRef.current = state.selectedSubject
                                SelectedExamRef.current = state.selectedExam
                                SelectedChapterRef.current = state.selectedChapter
                                getFilteredQBs()
                            }

                        }}
                    >
                        Apply
                    </button>

                    <span onClick={() => {
                        if (state.selectedCourse !== null || state.selectedExam !== null || state.selectedSubject !== null || state.selectedChapter !== null) {
                            ClearFilter()
                            getFilteredQBs()
                        }
                    }}
                        className="clear-filter-cheques ml-2" >Clear</span>
                </div>
            </div>

            {loading || filterLoading ? (
                <div className="loader_container">
                    <Loader />
                </div>
            ) : qbList.length === 0 ? (
                <div className="student_search_empty_container mt-5">
                    <div className="student_search_empty_img_container">
                        <img src={Empty} alt="not found" className="student_search_empty_img" />
                    </div>
                    {
                        noData ?
                            <p className="student_search_empty_header">
                                No QBs found
                            </p>
                            :
                            <>
                                <p className="student_search_empty_header">
                                    No results found
                                </p>
                                <span className="student_search_empty_label">
                                    Try adjusting your search to find
                                </span>
                                <span className="student_search_empty_label">
                                    the best results
                                </span>
                            </>
                    }
                </div>
            ) :
                <div className={`mpp_scroll_container ${ true && 'big' }`}
                    ref={scrollContainer}
                    onScroll={handleScroll}
                >
                    {qbList && qbList.map((qb, index) => {
                        return <QbCard key={index} name={qb.name} id={qb.question_bank_id} noQuestions={qb.num_questions} subject={qb.subject_name} uploadDate={qb.upload_date} download={downloadQB} />
                    })}
                    {
                        !loading && scrollLoading &&
                        <div className="loader_scroll_container">
                            <Loader />
                        </div>
                    }
                </div>
            }



        </div>
    )
}

export default QuestionBankList