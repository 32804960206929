import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxWidth: 500,
    width: "90%",
    padding: "3rem",
    borderRadius: 4,
};

const Confirmation = ({
    openModal,
    closeModal,
    handleConfirmSubmit,
    message
}) => {
    return (
        <Modal
            open={openModal}
            onClose={closeModal}
            className="payment_reversal_cofirmation_modal"
        >
            <Box sx={style}>
                <p className="reversal_accept_text">
                {message}
                </p>

                <div
                    className="mt-3"
                    style={{ float: "right" }}
                >
                    {/* <button
                        className="btn btn-md student_detail_no"
                        onClick={closeModal}
                    >
                        No
                    </button> */}
                    <button
                        className="btn btn-md student_detail_password_confirm ml-2"
                        onClick={handleConfirmSubmit}
                    >
                        Copy and Close
                    </button>
                </div>

            </Box>
        </Modal>
    );
};

export default Confirmation;