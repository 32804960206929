import { useState, useEffect } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import Swal from "sweetalert2";
import {  useNavigate } from "react-router-dom";

const Report = () => {

    // const data = [
    //     { name: 'Agra', students: 400, totalFee: 40000, collectedFee: 37000, overdueFee: 3000 },
    //     { name: 'Nano', students: 700, totalFee: 60000, collectedFee: 47000, overdueFee: 13000 },
    //     { name: 'Kothapet', students: 200, totalFee: 65000, collectedFee: 35000, overdueFee: 30000 },
    //     { name: 'Neet Academy', students: 500, totalFee: 40000, collectedFee: 20000, overdueFee: 20000 }
    // ];
    const navigate = useNavigate();

    const [data, setData] = useState({
        "center_wise_count":[],
        "class_wise_count":[],
        "section_wise_count":[],
        "school_wise_total_fees":[],
        "school_wise_pending_fees":[],
        "school_wise_paid_fees":[]
    })

    const initFunction = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}users/get-reports-dashboard/`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          const D = await response.json();
          let center_wise_count = [];
          let class_wise_count = [];
          let section_wise_count = [];
          let school_wise_fees = [];
          for(let name in D["center_wise_count"]){
            center_wise_count.push({"name":name, "students": D["center_wise_count"][name]})
          }
          for(let name in D["class_wise_count"]){
            class_wise_count.push({"name":name, "students": D["class_wise_count"][name]})
          }
          for(let name in D["section_wise_count"]){
            section_wise_count.push({"name":name, "students": D["section_wise_count"][name]})
          }
          for(let name in D["school_wise_total_fees"]){
            school_wise_fees.push({"name":name, "total_fees": D["school_wise_total_fees"][name], "pending_fees":D["school_wise_pending_fees"][name], "paid_fees":D["school_wise_paid_fees"][name]})
          }
          if (response.status === 401) {
            Swal.fire("Unauthorised?", "User logged into another device", "error");
            // localStorage.clear();
            navigate("/error");
          }
          setData({
            ...data,
            center_wise_count: center_wise_count,
            class_wise_count: class_wise_count,
            section_wise_count: section_wise_count,
            school_wise_total_fees: school_wise_fees
          });
          // console.log(D,'data')
        } catch (err) {
          console.log(err);
        //   setError(err.message);
        }
      };

      useEffect(() => {
        initFunction();
      },[])

    return (
        <>
        <h1 style={{margin: "100px"}}>Reports</h1>
        <div style={{display: "flex", flexWrap:"wrap", margin:"100px"}}>
            <BarChart width={400} height={300} data={data?.center_wise_count} title="Center Wise Student Count">
                
                <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
                <XAxis dataKey="name" angle={90} textAnchor="start" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="students" fill="blue" barSize={24} />
                
            </BarChart>

            <BarChart width={400} height={300} data={data?.class_wise_count} title="Class Wise Student Count">
                
                <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
                <XAxis dataKey="name" angle={90} textAnchor="start" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="students" fill="red" barSize={24} />
                
            </BarChart>

            <BarChart width={400} height={300} data={data?.section_wise_count} title="Section Wise Student Count">
                
                <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
                <XAxis dataKey="name" angle={90} textAnchor="start" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="students" fill="orange" barSize={24} />
                
            </BarChart>

            <BarChart width={1200} height={600} data={data?.school_wise_total_fees} title="School Wise Fees">
                
                <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
                <XAxis dataKey="name" angle={90} textAnchor="start" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total_fees" fill="orange" barSize={24} />
                <Bar dataKey="paid_fees" fill="blue" barSize={24} />
                <Bar dataKey="pending_fees" fill="red" barSize={24} />
                
            </BarChart>

           

            {/* <BarChart width={600} height={600} data={data}>
               
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Legend />
                <Tooltip />
                
                <Bar dataKey="students" fill="red" />
            </BarChart> */}

            {/* <BarChart width={600} height={600} data={data}>
                
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Legend />
                <Tooltip />
               
                <Bar dataKey="students" fill="green" />
            </BarChart> */}
            {/* <BarChart width={600} height={600} data={data}>
               
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Legend />
                <Tooltip />
               
                <Bar dataKey="totalFee" fill="green" />
                <Bar dataKey="collectedFee" fill="red" />
                <Bar dataKey="overdueFee" fill="orange" />
            </BarChart> */}
            </div>
        </>
    )
}

export default Report;