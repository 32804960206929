import React from 'react';
import { CourseTypeMap } from '../../../../utils/helperFunctions';

const PosDrop = ({
	courseType = "",
	studentState = ""
}) => {
	return (
		<div className="payment_details_card_row add_course_division">

			<div className="payment_details_card_group1 paymanet_details_card_divider00 paymanet_details_card_divider0">
				<div className="paymanet_details_card_divider00 d-flex" >
				</div>
				<div className="paymanet_details_card_divider0 d-flex" >
				</div>
			</div>

			<div className="paymanet_details_card_divider1 pos">
				<div className="paymanet_details_card_divider2">
					<div className="payment_details_list_card_header">Course type</div>
					<p className="payment_details_list_card_header value">{courseType ? CourseTypeMap[courseType] : "-"}</p>
				</div>

			</div>

			<div className="paymanet_details_card_divider3 d-flex ">
				<div className="payment_details_student_circular_img block"></div>
				<div>
					<div className="payment_details_list_card_header">Student state</div>
					<p className="payment_details_list_card_header value">{studentState || "-"}</p>
				</div>
			</div>

			<div className="paymanet_details_card_divider5 d-flex justify-content-flex-end">

			</div>



		</div>
	)
}

export default PosDrop