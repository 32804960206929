import React, { useState } from 'react'
import { Modal, } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from 'moment';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import DummyImage from "../../../../assets/img/tempImage.png"
import { bankStatusMap, currencyFormatter, zinedus3 } from '../../../../utils/helperFunctions';


function CashDepositCard(props) {
    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.arrow}`]: {
                color: theme.palette.common.black,
            },
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.black,
                fontSize: "13px",
            },
        })
    );


    const {
        amount,
        bank_name,
        bank_status,
        by,
        centre,
        deposit_date,
        deposit_slip,
        finance_manager,
        entry_date,
        id
    } = props.data

    const handleViewSlip = () => {
        window.open(`${zinedus3}${deposit_slip}`, '_blank');
    };

    return (
        <div className='cash_deposit_card_container' >

            <div className='cash_deposit_card_column' >
                <span className='cash_deposit_card_column_header' >Entry date</span>
                {moment(entry_date).format("MMM DD,YYYY") || "-"}
                <span className='cash_deposit_card_column_header2' >By: {by || "-"}</span>
            </div>
            <div className='cash_deposit_card_column' >
                <span className='cash_deposit_card_column_header' >Deposit date(as per slip)</span>
                {moment(deposit_date).format("MMM DD,YYYY") || "-"}
            </div>
            <div className='cash_deposit_card_column' >
                <span className='cash_deposit_card_column_header' >Amount (as per slip)</span>
                <span className='cash_deposit_card_column_header3' >₹{currencyFormatter(+(amount), false) || "-"}{deposit_slip && <button className='link_button' onClick={handleViewSlip} >View slip</button>}</span>

            </div>
            <div className='cash_deposit_card_column' >
                <span className='cash_deposit_card_column_header' >Bank name</span>
                {/* {bank_name||"-"} */}
                {bank_name && bank_name.length > 15 ? (
                    <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={bank_name}>
                        <span style={{ cursor: "pointer" }}>{bank_name.substring(0, 15) + "..."}</span>
                    </BootstrapTooltip>
                ) : (
                    <>
                        {bank_name || "-"}
                    </>
                )}
            </div>
            <div className='cash_deposit_card_column' >
                <span className='cash_deposit_card_column_header' >Centre</span>
                {centre && centre.length > 15 ? (
                    <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={bank_name}>
                        <span style={{ cursor: "pointer" }}>{bank_name.substring(0, 15) + "..."}</span>
                    </BootstrapTooltip>
                ) : (
                    <>
                        {centre || "-"}
                    </>
                )}
            </div>
            <div className='cash_deposit_card_column' >
                <span className='cash_deposit_card_column_header' >Bank status</span>
                <span 
                    className={
                        bank_status === bankStatusMap.PENDING ? 
                        'status_button_pending' : 
                        bank_status === bankStatusMap.CONFIRMED ? 
                        'status_button_confirmed' : 
                        bank_status === bankStatusMap.REJECTED ? 
                        'status_button_rejected' : 
                        ''
                    } 
                >
                    {bank_status || "-"}
                </span>
                {bank_status === bankStatusMap.REJECTED && <span className='cash_deposit_card_column_header2' >By: {finance_manager || "-"}</span>}
            </div>
        </div>
    )
}

export default CashDepositCard;