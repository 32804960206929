export const segmentEvents = {
    GENERAL: {
        LOGIN: "MYCRM_SuccessfullLogin",
        CHANGED_PASSWORD: "MYCRM_ChangedPassword"
    },
    STUDENT: {
        CLICKED_ADD_NEW: "MYCRM_AddNewStudent",
        CLICKED_SEARCH_EXISTING: "MYCRM_SearchExistingStudent",
        ADDED_STUDENT: "MYCRM_AddedStudent",
        EDITED_STUDENT: "MYCRM_EditedStudent",
        EDITED_PARENT: "MYCRM_EditedParent",
        ADDED_TABLET: "MYCRM_AddedTablet",
        RESET_PASSWORD: "MYCRM_ResetStudentPassword",
        ADDED_COURSE: "MYCRM_AddedStudentCourse",
        CREATED_INSTALLMENTS: "MYCRM_CreatedInstallments",
        CONFIRMED_INSTALLMENTS: "MYCRM_ConfirmedInstallments",
        CLICKED_ADD_HOSTEL: "MYCRM_AddHostel",
        CREATED_HOSTEL_INSTALLMENTS: "MYCRM_CreatedHostelInstallments",
        CONFIRMED_HOSTEL_INSTALLMENTS: "MYCRM_ConfirmedHostelInstallments",
        CLICKED_SEARCH_STUDENT: "MYCRM_SearchStudent",
        CLICKED_SEARCH_STUDENT_APPLY: "MYCRM_SearchStudentApply",
        CLICKED_STUDENT_SEARCH_MORE_FILTER: "MYCRM_SearchStudentMoreFilter",
        CLICKED_STUDENT_SEARCH_CLEAR: "MYCRM_SearchStudentClear",
        UPLOADED_SIGNED_FORM: "MYCRM_UploadedSignedForm",
        CLICK_VIEW_SIGNED_FORM: "MYCRM_ViewSignedForm",
        CLICK_SEE_ALL_SIGNED_FORM: "MYCRM_SeeAllSignedForm",
        COURSE_ACTIVATED: "MYCRM_StudentCourseActivated",
        COURSE_DEACTIVATED: "MYCRM_StudentCourseDeactivated",
        ACCESS_LOCKED: "MYCRM_StudentAccessLocked",
        ACCESS_UNLOCKED: "MYCRM_StudentAccessUnlocked"
    }
}