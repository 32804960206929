import React, { useState } from 'react'
import Accordian from '../../../utils/accordian'
import ArrowDown from "../../../assets/img/arrow_down.svg";
import ArrowUp from "../../../assets/img/arrow_up.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import moment from 'moment';
import { currencyFormatter } from '../../../utils/helperFunctions';
import QuestionMarkIcon from "../../../assets/img/Centre-question-mark.svg"

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const CashInfo = ({
    data
}) => {

    const [expanded, setExpanded] = useState(false);

    const {
        date,
        cash_opening,
        cash_confirmed,
        deposit_confirmed,
        deposit_pending,
        deposit_rejected,
        cash_closing,
        confirmed_cash_details
    } = data;

    return (
        <div className='cash_info_card_container'>
            <div className='cash_info_row_container' >
                <div className='cash_deposit_card_column' >
                    <span className='cash_deposit_card_column_header' >Date</span>
                    {moment(date).format("MMM DD, YYYY")}
                </div>
                <div className='cash_deposit_card_column' >
                    <span className='cash_deposit_card_column_header' >Cash opening</span>
                    ₹ {currencyFormatter(cash_opening, false)}
                </div>
                <div className='cash_deposit_card_column' >
                    <span className='cash_deposit_card_column_header' >Confirmed cash</span>
                    ₹ {currencyFormatter(cash_confirmed, false)}
                </div>
                <div className='cash_deposit_card_column' >
                    <span className='cash_deposit_card_column_header' >Cash deposit (confirmed)</span>
                    ₹ {currencyFormatter(deposit_confirmed, false)}
                </div>
                <div className='cash_deposit_card_column' >
                    <span className='cash_deposit_card_column_header' >
                        Cash deposit (Pending) {" "}
                        {
                            +deposit_pending > 0 &&
                            <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={`Cash opening and closing will vary until Cash deposit (pending) is 0.`}>
                                <img src={QuestionMarkIcon} alt="info" className="cursor-pointer" />
                            </BootstrapTooltip>
                        }
                    </span>
                    
                    {
                        +deposit_pending > 0 ?
                        <span className="cash_deposit_rejected_text">
                            ₹ {currencyFormatter(deposit_pending, false)}                            
                        </span>
                        :
                        <span>
                            ₹ {currencyFormatter(deposit_pending, false)}                            
                        </span>
                    }
                </div>
                <div className='cash_deposit_card_column' >
                    <span className='cash_deposit_card_column_header' >Cash deposit (Rejected)</span>
                    ₹ {currencyFormatter(deposit_rejected, false)}
                </div>
                <div className='cash_deposit_card_column' >
                    <span className='cash_deposit_card_column_header' >Cash Closing</span>
                    ₹ {currencyFormatter(cash_closing, false)}
                </div>

                <button
                    type="button"
                    className="cash_deposit_card_column2"
                    style={{ border: "none" }}
                    onClick={() => setExpanded(cur => !cur)}
                >
                    Confirmed cash details
                    <img
                        src={expanded ? ArrowUp : ArrowDown}
                        alt="arrow"

                    />
                </button>

            </div>

            <div style={{ width: "100%" }}>
                <Accordian
                    expand={expanded}
                    style={{ width: "100%" }}
                >
                    {
                        confirmed_cash_details && confirmed_cash_details.map( (item, i) => {
                            return(
                                <div className='accordion_container' key={i}>

                                    <div className='accordion_row' >
                                        <div className='cash_deposit_card_column' >
                                            <span className='cash_deposit_card_column_header' >Counsellor Name</span>
                                            <span className='cash_deposit_card_column_header3' >
                                                {
                                                    item.counsellor_name.length > 25 ? (
                                                    <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={item.counsellor_name}>
                                                        <span style={{ cursor: "pointer" }}>{item.counsellor_name.substring(0, 25) + "..."}</span>
                                                    </BootstrapTooltip>
                                                    ) 
                                                : 
                                                    (
                                                        item.counsellor_name
                                                    )
                                                }
                                                {/* {item.counsellor_name} */}
                                            </span>
                                        </div>
                                        <div className='cash_deposit_card_column' >
                                            <span className='cash_deposit_card_column_header' >Transaction count</span>
                                            <span className='cash_deposit_card_column_header3' >
                                                {item.student_fees.length}
                                            </span>
                                        </div>
                                        <div className='cash_deposit_card_column' >
                                            <span className='cash_deposit_card_column_header' >Cash Collection</span>
                                            <span className='cash_deposit_card_column_header3' >
                                                ₹ {currencyFormatter(item.total_amount_paid, false)}
                                            </span>
                                        </div>
                                        <div className='cash_deposit_card_column' >
                                            <span className='cash_deposit_card_column_header' >Centre name</span>
                                            <span className='cash_deposit_card_column_header3' >
                                                {
                                                    item.centre_name.length > 25 ? (
                                                    <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={item.centre_name}>
                                                        <span style={{ cursor: "pointer" }}>{item.centre_name.substring(0, 25) + "..."}</span>
                                                    </BootstrapTooltip>
                                                    ) 
                                                : 
                                                    (
                                                        item.centre_name
                                                    )
                                                }
                                                {/* {item.centre_name} */}
                                            </span>
                                        </div>
                                    </div>

                                    {
                                        item.student_fees && item.student_fees.map(studentData => {
                                            return (
                                                <div className='accordion_row' key={studentData.payment_id}>
                                                    <div className='cash_deposit_card_column' style={{ paddingLeft: "20px" }} >
                                                        <span className='cash_deposit_card_column_header' >Student name</span>
                                                        <span className='cash_deposit_card_column_header3' >
                                                            {
                                                                studentData.student_name.length > 25 ? (
                                                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={studentData.student_name}>
                                                                    <span style={{ cursor: "pointer" }}>{studentData.student_name.substring(0, 25) + "..."}</span>
                                                                </BootstrapTooltip>
                                                                ) 
                                                            : 
                                                                (
                                                                    studentData.student_name
                                                                )
                                                            }
                                                            {/* {studentData.student_name} */}
                                                        </span>
                                                        <span className='cash_deposit_card_column_header2' >ID : {studentData.student_id}</span>
                                                    </div>
                                                    <div className='cash_deposit_card_column' >
                                                        <span className='cash_deposit_card_column_header' >Date of payment</span>
                                                        <span className='cash_deposit_card_column_header3' >
                                                            {moment(studentData.paid_on_date).format("MMM DD, YYYY")}
                                                        </span>
                                                    </div>
                                                    <div className='cash_deposit_card_column' >
                                                        <span className='cash_deposit_card_column_header' >Cash Collection</span>
                                                        <span className='cash_deposit_card_column_header3' >
                                                            ₹ {currencyFormatter(studentData.amount_paid, false)}
                                                        </span>
                                                    </div>
                                                    <div className='cash_deposit_card_column' >
                                                        <span className='cash_deposit_card_column_header' >Confirmed by</span>
                                                        <span className='cash_deposit_card_column_header3' >
                                                            {
                                                                studentData.accountant_name.length > 25 ? (
                                                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={studentData.accountant_name}>
                                                                    <span style={{ cursor: "pointer" }}>{studentData.accountant_name.substring(0, 25) + "..."}</span>
                                                                </BootstrapTooltip>
                                                                ) 
                                                            : 
                                                                (
                                                                    studentData.accountant_name
                                                                )
                                                            }
                                                        </span>
                                                        <span className='cash_deposit_card_column_header2' >ID : {studentData.accountant_id}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }                        

                                </div>
                            )
                        })
                    }
                </Accordian>
            </div>
        </div>
    )
}

export default CashInfo;