import React, { useState, useCallback } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Loader from "../../common/Loader";
import { errorHandler, getSubstringedText, handleFileResponse } from "../../../utils";
import DownloadIcon from "../../../assets/img/downloadIcon.svg";
import ErrorImg from "../../../assets/img/error_txt.svg";
import api from "../../common/api";
import ErrorText from "../../common/errorText";
import apiUrl from "../../common/apiUrl";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import DeleteModal from '../../mpp/addMpp/DeleteModal';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import LeftArrow from "../../../assets/img/backArrowBlack.svg";
import ToastMessage from '../../common/toastMessage';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${ className } tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${ tooltipClasses.arrow }`]: {
            color: theme.palette.common.black
        },
        [`& .${ tooltipClasses.tooltip }`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "14px"
        },
    })
);

const chapters = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(item => ({ id: item, name: item }))
const initialFileResponse = {
    chapter: "",
    filename: "",
    mpp_temp_id: null,
    num_questions: null,
    subject: "",
    faculties: [],
    target_exams: [],
    chapters: chapters
}

const TextContainer = ({ header = '', content = '' }) => {

    return <div className="mpp_gray_container">
        <span className="mpp_gray_container_text1">{header}</span>
        {
            header === "Chapter" ?
            <span 
                className="mpp_gray_container_text2" 
                style={{ fontSize: 17, display: "flex", columnGap: "7px" }}
            >
                {content[0]}
                {
                    content.length > 1 &&
                    <div className="d-flex align-items-center cursor-pointer">
                        <div className="test_chapter_seperator" />
                        <BootstrapTooltip title={content.slice(1).map(e => e).join(", ")} placement="bottom">
                            <span className="test_chapters_more">+{content.length-1}</span>
                        </BootstrapTooltip>
                    </div>
                }
            </span>
            :
            <span 
                className="mpp_gray_container_text2" 
                style={{ fontSize: 17 }}
            >
                {content}
            </span>
        }
    </div>
}

const QuestionBankAdd = () => {
    const navigate = useNavigate();
    const [fileError, setFileError] = useState("");
    const [deleteModal, setDeleteModal] = useState(false)
    const [loading, setLoading] = useState();
    const [fileResponse, setFileResponse] = useState(initialFileResponse)
    const [uploadErrors, setUploadErrors] = useState(null)

    const downloadTemplate = async () => {
        await handleFileResponse(apiUrl.MPP_DOWNLOAD_TEMPLATE, 'Sample Mpp Template.docx')
    }

    const uploadDocFile = async (file) => {
        setLoading(true)
        setUploadErrors(null)
        const formData = new FormData();
        formData.append('file', file)
        console.log('formData', formData);
        const response = await api.doUpload("POST", formData, `${ apiUrl.QB }/upload`);
        setLoading(false)
        if (response && response?.status === true) {
            setFileResponse(response?.data)
        } else {
            Swal.fire("Error!", response.message ? response.message : "Internal Server Error", "error");
            setUploadErrors(response?.data?.errors)
        }
    }

    const handleFileDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length === 1 && rejectedFiles.length === 0) {
            // Accepted file
            setFileError("");
            uploadDocFile(acceptedFiles[0]);
        } else {
            if (rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors && rejectedFiles[0].errors.length > 0 && rejectedFiles[0].errors[0].code === "file-too-large") {
                setFileError(<span>The File size is larger than <strong>5 MB</strong>. Please reduce the File size.</span>);
            } else {
                setFileError(<span>Please upload a <strong>.doc</strong> or <strong>.docx</strong> File</span>);
            }
        }
    }, []);

    const { getInputProps, getRootProps } = useDropzone({
		multiple: false,
		maxSize: 5.01 * 1024 * 1024, // 5 MB
		accept: {
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".doc", ".docx"],
			"application/msword": [".doc", ".docx"],
		},
		onDrop: handleFileDrop,
	});

    const handleDelete = () => {
        setDeleteModal(false)
        setFileResponse(initialFileResponse)
        toast(<ToastMessage text="Question file deleted succesfully" type='error' />)
    }

    const handleDownloadExcelTemplate = (data) => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Question Bank Errors');

        sheet.columns = [
            { header: "Table Number", key: "Table_Number" },
            { header: "Field Name", key: "Field_Name" },
            { header: "Error", key: "Error" },
            { header: "Response entered", key: "Response_entered"}
        ];
        data?.map((error) => {
            let obj = {
                Table_Number: error[0],
                Field_Name: error[1],
                Error: `there is error in "${error[0]}" - ${error[2]}`,
                Response_entered: error[3],
            }
            sheet.addRow(obj);

        })
        workbook.xlsx.writeBuffer().then(function (buffer) {
            const blob = new Blob([buffer], { type: "application/xlsx" });

            saveAs(blob, "Question Bank Error.xlsx");
        });
        setFileResponse(initialFileResponse)
        setUploadErrors(null)
    };

    return (
        <div className="mpp_list_container">
            <DeleteModal
                openModal={deleteModal}
                closeModal={() => setDeleteModal(!deleteModal)}
                handleConfirmSubmit={handleDelete}
            />

            <div className="qb_add_header">
                <div className="d-flex align-items-center">

                    <img src={LeftArrow} alt="back" className="cursor-pointer" onClick={() => navigate("/qb")} />
                    <span className="reversal_detail_header_text ml-2">Create QB</span>
                </div>
            </div>

            {!fileResponse.filename && <div className="addQBContainer">
                <p className="student_filter_drop_header">
                    <strong>Download Template</strong>
                </p>
                <button
                    className="download_template_button mt-2"
                    style={{ fontSize: 16 }}
                    onClick={downloadTemplate}
                >
                    Template
                    <svg style={{ marginLeft: 12 }} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                        <path d="M0 17.5H18V19.5H0V17.5ZM10 11.672L16.071 5.6L17.485 7.014L9 15.5L0.515 7.015L1.929 5.6L8 11.67V0.5H10V11.672Z" fill="black" />
                    </svg>
                </button>
            </div>}

            {loading ?
                <div style={{ justifyContent: "center", alignItems: "center", marginTop: 50 }}>
                    <div className="text-center" role="alert">
                        <img alt="loader" src="https://c.tenor.com/5o2p0tH5LFQAAAAi/hug.gif" width="60px" height="60px"></img>
                    </div>
                    <div className="text-center mt-2" role="alert">
                        <span className="student_search_empty_header">Uploading...</span>
                    </div>
                </div>
                : <>
                    {!fileResponse.filename && <div className="addQBContainer">
                        <p className="student_filter_drop_header big">
                            <strong>Upload exercise</strong>
                        </p>

                        <div className="filesBox add_hub" {...getRootProps()}>
                            <div className="inputFile"></div>
                            <input className="fileup" {...getInputProps()} />
                            <div className="fileUploadHelperText hub">Drag {"&"} drop or click to choose a file</div>
                            <div style={{ marginTop: 14 }}>
                                Max File size is <strong>5 MB</strong>
                            </div>
                        </div>

                        {
                            fileError &&
                            <div>
                                <ErrorText
                                    text={fileError}
                                />
                            </div>
                        }
                    </div>}

                    {uploadErrors && <div className="d-flex mt-2">
                        <img src={ErrorImg} alt="err" style={{ width: 25, height: 25, marginRight: 10 }} />
                        <span style={{
                            fontFamily: 'Montserrat',
                            fontSize: 18,
                            color: '#C20707',
                            fontWeight: 500
                        }}>{uploadErrors && uploadErrors.length - 1} {uploadErrors.length -1 >1 ? 'Errors' : 'Error'} found, try reuploading</span>

                        <div className='ml-2 cursor-pointer' onClick={() => {
                            handleDownloadExcelTemplate(uploadErrors.slice(1))
                            toast(<ToastMessage text="Error file downloaded successfully kindly reupload the exercise" />)
                        }}> <span style={{
                            fontFamily: 'Montserrat',
                            fontSize: 18,
                            color: '#1F3061',
                            fontWeight: 500,

                        }}> Download error report</span>
                            <img src={DownloadIcon} alt="err" style={{ marginLeft: 10, color: '#1F3061', marginTop: -5 }} />

                        </div>

                    </div>}

                    {
                        fileResponse?.filename && <>
                            <div className="addQBContainer">

                                <div className="mpp_file_upload_container1">
                                    <div className="mpp_file_upload_container2" style={{ gap: 10, width: '90%' }}>
                                        <TextContainer header="Subject" content={fileResponse?.subject_name} />
                                        <TextContainer header="Chapter" content={fileResponse?.chapter_name} />
                                        <TextContainer header="File uploaded" content={fileResponse?.filename} />
                                        <TextContainer header="Number of questions" content={fileResponse?.num_questions} />

                                    </div>
                                    {true && <DeleteForeverTwoToneIcon style={{ color: '#C00000', marginTop: 5, cursor: 'pointer' }} fontSize="large" onClick={() => { setDeleteModal(true) }} />}
                                </div>
                            </div>

                            <div className="button_row_add_mpp mt-5">
                                <div />
                                <button
                                    // disabled={!canSave() || saveLoading}
                                    onClick={() => navigate(`/qb/preview/${ fileResponse?.qb_id }`)}
                                    className={"btn btn-md course_add_next"}
                                    type="button"
                                >
                                    Check Preview
                                    {/* {saveLoading && <span className="ml-2 spinner spinner-white mr-2"></span>} */}
                                </button>
                            </div>
                        </>
                    }</>}



        </div >
    )
}

export default QuestionBankAdd