import React, { Suspense, lazy } from "react";
import "./App.css";
import "antd/dist/antd.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Student from "./components/student/Student";
import Login from "./components/landing/Login";
import User from "./components/users/users";
import UserDashboard from "./components/users";
// import InstallmentCreate from "./components/payment/InstallmentCreate";
import InstallmentCreate from "./components/payment/feeInstallment/create";
// import InstallmentEdit from "./components/payment/InstallmentEdit";
import InstallmentEdit from "./components/payment/feeInstallment/edit";
// import FeeSummary from "./components/payment/FeeSummary";
import FeeSummary from "./components/payment/feeSummary/index";
import ErrorPage from "./components/common/ErrorPage";
import AddCourseStudent from "./components/student/AddCourse";
import Dashboard from "./components/dashboard";
import PaymentDetails from "./components/payment/PaymentDetails";
import PaymentFailed from "./components/payment/PaymentFailed";
import PaymentSuccess from "./components/payment/PaymentSuccess";
import ForgotPassword from "./components/landing/ForgotPassword";
import EnterOtp from "./components/landing/EnterOtp";
import UploadAdmissionForm from "./components/student/detail/admissionForm";

import PaymentRecipt_feesummmary from "./components/payment/PaymentRecipt_feesummmary";
// import CreateInstallmentHostel from "./components/payment/CreateInstallmentHostel";
import CreateInstallmentHostel from "./components/payment/feeHostelInstallment/create";
// import InstallmentEditHostel from "./components/payment/InstallmentEditHostel";
import InstallmentEditHostel from "./components/payment/feeHostelInstallment/edit";
import PaymentDetailsHostel from "./components/payment/PaymentDetailsHostel";
import PaymentSuccessHostel from "./components/payment/PaymentSuccessHostel";
import PaymentReciptHostelSummary from "./components/payment/PaymentReciptHostelSummary";

import PaymentLink from "./components/payment/PaymentLink";
import HostelPaymentLink from "./components/payment/HostelPaymentLink";

import Attendance from "./components/student/attendance/index";
import MarkAttendance from "./components/student/attendance/MarkAttendance";
// import RequireAuth from './components/landing/RequireAuth';

import PaymentReversal from "./components/paymentReversal";
import PaymentReversalPending from "./components/paymentReversal/pending";
import PaymentReversalHistory from "./components/paymentReversal/history";

import PrivateRoute from "./components/common/privateRoute";

import { Provider } from "react-redux";
import store from "./store";
import { useEffect } from "react";
import Api from "./components/common/api";
import { getAuthDetails } from "./utils/helperFunctions";

import Layout from "./components/layout";
import LayoutTopbar from "./components/layoutTopbar";

import StudentLanding from "./components/student";
import StudentSearch from "./components/student/search";
import StudentDetail from "./components/student/detail";
import AddNewStudent from "./components/student/addStudent/index";
import EditStudent from "./components/student/editStudent";

import { permissionConstants, checkWindowLocation } from "./utils/helperFunctions";

import Hub from "./components/hub/index";
import Addhub from "./components/hub/AddHub";
import HubList from "./components/hub/ListOfHubs";
import EditHub from "./components/hub/Edit";

import Batch from "./components/batch";
import AddBatch from "./components/batch/addBatch";
import EditBatch from "./components/batch/editBatch";
import BatchList from "./components/batch/batchList";

import Subject from "./components/subject";
import AddSubject from "./components/subject/addSubject";
import EditSubject from "./components/subject/editSubject";
import SubjectList from "./components/subject/subjectList";

import Chapter from "./components/chapter";
import AddChapter from "./components/chapter/addChapter";
import ChapterList from "./components/chapter/chapterList";
import EditChapter from "./components/chapter/editChapter";

import Report from "./components/reports";


// import StudioList from "./components/studio/studioList";
// import Studio from "./components/studio";
// import AddStudio from "./components/studio/addStudio";
// import EditStudio from "./components/studio/editStudio";

// import Group from "./components/group";
// import GroupList from "./components/group/groupList";
// import AddGroup from "./components/group/addGroup";
// import EditGroup from "./components/group/editGroup";

import FinancePendingCheque from "./components/finance/pendingCheque";
import FinancePayment from "./components/finance/payment";
import FinancePendingDeposits from "./components/finance/pendingDeposits";
import FinancePendingEMI from "./components/finance/pendingEMI";
import FinanceBulkBankDeposit from "./components/finance/bulkBankDeposits";

import FinanceCashReconciliation from "./components/finance/cashReconciliation";
import FinancePendingCashDeposits from "./components/finance/cashReconciliation/pendingCashDeposits";
import FinanceCashList from "./components/finance/cashReconciliation/cashList";
import FinanceCashNotConfirmed from "./components/finance/cashReconciliation/cashNotConfirmed";
import FinanceCashPosition from "./components/finance/cashReconciliation/cashPosition";

import CashDeposit from "./components/accountant/cashDeposit";
import CashDepositList from "./components/accountant/cashDeposit/cashDepositList";
import ConfirmCash from "./components/accountant/cashDeposit/confirmCash";
import AccountantDashboard from "./components/accountant/dashboard";
import AddCashDeposit from "./components/accountant/cashDeposit/addCashDeposit";

import LeadManagement from "./components/lead/index";

import LeadSearch from "./components/lead/search";
import LeadAdd from "./components/lead/addLead";
import LeadList from "./components/lead/leadList";
import EditLead from "./components/lead/editLead";
// import EnrollCourseLead from "./components/lead/enrollCourse/index"
import PublicRoute from "./components/common/publicRoute";
import AddMpp from "./components/mpp/addMpp";
import MppInstructions from "./components/mpp/mppScreens/MppInstructions";
import ReviewMpp from "./components/mpp/mppScreens/ReviewMpp";
import MppList from "./components/mpp/mppList";
import MppAssign from './components/mpp/mppScreens/MppAssign'
import MppView from './components/mpp/mppView'
import MppScheduleHistory from "./components/mpp/mppScreens/ScheduleHistory";

import QuestionBank from "./components/qb";
import QuestionBankAdd from "./components/qb/add"
import QuestionBankList from "./components/qb/list"
import QuestionBankPreview from './components/qb/add/PreviewQB'
import QuestionBankCreate from './components/qb/add/CreateQB'
import PaymentHDFC from "./components/paymentHdfc";
import UserList from "./components/users/userList";
import UserAdd from "./components/users/addUser";
import RoleDashboard from "./components/roles";
import RoleList from "./components/roles/roleList";
import AddRole from "./components/roles/addRole";
import Role from "./components/roles/roles";
import OMR from "./components/omr";

const Course = lazy(() => import("./components/course"));
const AddCourse = lazy(() => import("./components/course/addCourse"));
const EditCourse = lazy(() => import("./components/course/editCourse"));
const CourseList = lazy(() => import("./components/course/courseList"));

const Centre = lazy(() => import("./components/centre"));
const AddCentre = lazy(() => import("./components/centre/addCentre"));
const EditCentre = lazy(() => import("./components/centre/editCentre"));
const CentreList = lazy(() => import("./components/centre/centreList"));

const FacultyOnboarding = lazy(() => import("./components/faculty/onboarding"));
const AddFaculty = lazy(() => import("./components/faculty/onboarding/addFaculty"));
const EditFaculty = lazy(() => import("./components/faculty/onboarding/editFaculty"));
const FacultyList = lazy(() => import("./components/faculty/onboarding/facultyList"));

const AssignQuestion = lazy(() => import("./components/faculty/assignQuestion"));
const AssignReviewer = lazy(() => import("./components/faculty/assignQuestion/AssignReviewer"));
const ReviewerSubject = lazy(() => import("./components/faculty/assignQuestion/reviewerSubject"));

function App() {
    // let data = localStorage.getItem("userdetail");
    // data = JSON.parse(data);

    useEffect(() => {
        if (Api.isAuthenticated() && checkWindowLocation()) {
            getAuthDetails();
            Api.identifyUser();
        }

        window.onstorage = (event) => {
            if (event && event.key === "userdetail" && checkWindowLocation()) {
                window.location.href = "/";
            }
        };

        return () => {
            window.onstorage = null;
        };
    }, []);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                      
                        <Route
                            exact
                            path="/"
                            element={
                                <PublicRoute
                                    component={Login}
                                />
                            }
                        />
                        <Route
                            exact
                            path="/forgotPassword"
                            element={
                                <PublicRoute
                                    component={ForgotPassword}
                                />
                            }
                        />
                        <Route
                            exact
                            path="/enterOtp"
                            element={
                                <PublicRoute
                                    component={EnterOtp}
                                />
                            }
                        />

                        <Route
                            path="/payment/paymentReceiptSummary/:payment_id"
                            element={
                                <PrivateRoute
                                    component={PaymentRecipt_feesummmary}
                                />
                            }
                        />
                        <Route
                            path="/payment/hostel-receipt/:payment_id"
                            element={
                                <PrivateRoute
                                    component={PaymentReciptHostelSummary}
                                />
                            }
                        />

                        <Route path="/" element={<LayoutTopbar />}>
                       
                            <Route
                                path="/student"
                                element={
                                    <PrivateRoute component={Student} />}
                            >
                                <Route
                                    exact
                                    path="addCourse/:student_id"
                                    element={
                                        <PrivateRoute
                                            component={AddCourseStudent}
                                            resource={permissionConstants.STUDENT}
                                        />}
                                />
								<Route
                                    exact
                                    path="omr/"
                                    element={
                                        <PrivateRoute
                                            component={OMR}
                                            resource={permissionConstants.CENTRE}
                                        />}
                                />
                                <Route
                                    exact
                                    path="add"
                                    element={
                                        <PrivateRoute
                                            component={AddNewStudent}
                                            resource={permissionConstants.STUDENT}
                                        />
                                    }
                                />
                                <Route
                                    path="mark-attendance"
                                    element={
                                        <PrivateRoute
                                            component={MarkAttendance}
                                            resource={permissionConstants.ATTENDANCE}
                                        />
                                    }
                                />
                            </Route>

                           
                            <Route
                            path="report"
                            element={
                                <PrivateRoute
                                    component={Report}
                                    resource={permissionConstants.STUDENT}
                                />
                            }
                        />

                            {/*
                                MyCRM1.1
                            */}

                            <Route
                                path="/hub/add"
                                element={
                                    <PrivateRoute
                                        component={Addhub}
                                        resource={permissionConstants.HUB}
                                    />
                                }
                            />

                            <Route
                                path="/hub/:hubID"
                                element={
                                    <PrivateRoute
                                        component={EditHub}
                                        resource={permissionConstants.HUB}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/centre/add"
                                element={
                                    <PrivateRoute
                                        component={AddCentre}
                                        resource={permissionConstants.CENTRE}
                                    />
                                }
                            />
                            <Route
                                exact
                                path="/centre/:centreID"
                                element={
                                    <PrivateRoute
                                        component={EditCentre}
                                        resource={permissionConstants.CENTRE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/course/add"
                                element={
                                    <PrivateRoute
                                        component={AddCourse}
                                        resource={permissionConstants.COURSE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/course/:courseID"
                                element={
                                    <PrivateRoute
                                        component={EditCourse}
                                        resource={permissionConstants.COURSE}
                                    />
                                }
                            />
                            <Route
                                exact
                                // path="/mpp/assign/:mppIDs"
                                path="/mpp/assign"
                                element={
                                    <PrivateRoute
                                        component={MppAssign}
                                        resource={permissionConstants.MPP}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/batch/add"
                                element={
                                    <PrivateRoute
                                        component={AddBatch}
                                        resource={permissionConstants.BATCH}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/batch/:batchID"
                                element={
                                    <PrivateRoute
                                        component={EditBatch}
                                        resource={permissionConstants.BATCH}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/subject/add"
                                element={
                                    <PrivateRoute
                                        component={AddSubject}
                                        resource={permissionConstants.SUBJECT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/subject/:subjectID"
                                element={
                                    <PrivateRoute
                                        component={EditSubject}
                                        resource={permissionConstants.SUBJECT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/chapter/add"
                                element={
                                    <PrivateRoute
                                        component={AddChapter}
                                        resource={permissionConstants.CHAPTER}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/chapter/:chapterID"
                                element={
                                    <PrivateRoute
                                        component={EditChapter}
                                        resource={permissionConstants.CHAPTER}
                                    />
                                }
                            />

                            {/* <Route
                                exact
                                path="/studio/add"
                                element={
                                    <PrivateRoute
                                        component={AddStudio}
                                    // resource={permissionConstants.COURSE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/studio/:studioID"
                                element={
                                    <PrivateRoute
                                        component={EditStudio}
                                    // resource={permissionConstants.COURSE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/group/add"
                                element={
                                    <PrivateRoute
                                        component={AddGroup}
                                    // resource={permissionConstants.COURSE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/group/:studioID"
                                element={
                                    <PrivateRoute
                                        component={EditGroup}
                                    // resource={permissionConstants.COURSE}
                                    />
                                }
                            /> */}

                            {/* <Route
                                exact
                                path="/group/fullBatchList"
                                element={
                                    <PrivateRoute
                                        component={FullBatchList}
                                    // resource={permissionConstants.COURSE}
                                    />
                                }
                            />    */}

                            <Route
                                exact
                                path="/faculty/add"
                                element={
                                    <PrivateRoute
                                        component={AddFaculty}
                                        resource={permissionConstants.FACULTY}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/faculty/:facultyID"
                                element={
                                    <PrivateRoute
                                        component={EditFaculty}
                                        resource={permissionConstants.FACULTY}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/faculty/list"
                                element={
                                    <PrivateRoute
                                        component={FacultyList}
                                        resource={permissionConstants.FACULTY}
                                    />
                                }
                            />

                            <Route
                                path="/assign-reviewer/subjects/:subjectID"
                                element={
                                    <PrivateRoute
                                        component={ReviewerSubject}
                                        resource={permissionConstants.REVIEWER}
                                    />
                                }
                            />

                            <Route
                                path="/mpp/review/:mppID"
                                element={
                                    <PrivateRoute
                                        component={ReviewMpp}
                                        resource={permissionConstants.MPP}
                                    />
                                }
                            />
                            <Route
                                path="/qb/preview/:qbID"
                                element={
                                    <PrivateRoute
                                        component={QuestionBankPreview}
                                        resource={permissionConstants.QUESTION_BANK}
                                    />
                                }
                            />

                        </Route>

                        <Route path="/" element={<Layout />}>
                            {/* <Route element={<TopBar/>}> */}

                            <Route 
                              path="user"
                              element={
                                <PrivateRoute 
                                  component={User}
                                  resource={permissionConstants.USER}
                                />
                          }
                        >

                          <Route 
                          exact
                            path=""
                            element={
                              <PrivateRoute 
                                component={UserDashboard}
                                resource={permissionConstants.USER}
                              />
                            }
                          />

                          <Route 
                          exact
                            path="list"
                            element={
                              <PrivateRoute 
                                component={UserList}
                                resource={permissionConstants.USER}
                              />
                            }
                          />

                          <Route 
                          exact
                            path="add"
                            element={
                              <PrivateRoute 
                                component={UserAdd}
                                resource={permissionConstants.USER}
                              />
                            }
                          />

                        </Route>

                        <Route 
                              path="role"
                              element={
                                <PrivateRoute 
                                  component={RoleDashboard}
                                  resource={permissionConstants.USER}
                                />
                          }
                        >
                           {/* <Route 
                          exact
                            path=""
                            element={
                              <PrivateRoute 
                                component={Role}
                                // resource={permissionConstants.USER}
                              />
                            }
                          /> */}
                       

                         

                        </Route>

                        <Route 
                          exact
                            path="/role/list"
                            element={
                              <PrivateRoute 
                                component={RoleList}
                                // resource={permissionConstants.USER}
                              />
                            }
                          />

                          <Route 
                          exact
                            path="/role/add"
                            element={
                              <PrivateRoute 
                                component={AddRole}
                                // resource={permissionConstants.USER}
                              />
                            }
                          />

                            <Route
                                exact
                                path="/dashboard"
                                element={<PrivateRoute component={Dashboard} />}
                            />

                            <Route
                                path="/student"
                                element={<PrivateRoute component={Student} />}
                            >
                                <Route
                                    path=""
                                    element={
                                        <PrivateRoute
                                            component={StudentLanding}
                                            resource={permissionConstants.STUDENT}
                                        />
                                    }
                                />


                                <Route
                                    path="search"
                                    element={
                                        <PrivateRoute
                                            component={StudentSearch}
                                            resource={permissionConstants.STUDENT}
                                        />
                                    }
                                />

                                <Route
                                    path="detail/:student_id"
                                    element={
                                        <PrivateRoute
                                            component={StudentDetail}
                                            resource={permissionConstants.STUDENT}
                                        />
                                    }
                                />

                                <Route
                                    path="upload-admission-form/:student_id"
                                    element={
                                        <PrivateRoute
                                            component={UploadAdmissionForm}
                                            resource={permissionConstants.STUDENT}
                                        />
                                    }
                                />

                               

                                <Route
                                    path="attendance"
                                    element={
                                        <PrivateRoute
                                            component={Attendance}
                                            resource={permissionConstants.ATTENDANCE}
                                        />
                                    }
                                />

                                <Route
                                    exact
                                    path="edit/:student_id"
                                    element={
                                        <PrivateRoute
                                            component={EditStudent}
                                            resource={permissionConstants.STUDENT}
                                        />
                                    }
                                />
                            </Route>

                            <Route
                                path="/payment/create-installment/:centreID/:batchID/:student_id"
                                element={
                                    <PrivateRoute
                                        component={InstallmentCreate}
                                        resource={permissionConstants.STUDENT}
                                    />
                                }
                            />
                            <Route
                                path="/payment/edit-installment/:centreID/:batchID/:student_id"
                                element={
                                    <PrivateRoute
                                        component={InstallmentEdit}
                                        resource={permissionConstants.STUDENT}
                                    />
                                }
                            />
                            <Route
                                path="/payment/fee-summary/:centreID/:batchID/:student_id"
                                element={
                                    <PrivateRoute
                                        component={FeeSummary}
                                        resource={permissionConstants.STUDENT}
                                    />
                                }
                            />
                            <Route
                                path="/payment/paymentDetails/:student_id"
                                element={
                                    <PrivateRoute
                                        component={PaymentDetails}
                                        resource={permissionConstants.STUDENT}
                                    />
                                }
                            />

                            <Route
                                path="/payment/create-hostel-installment/:centreID/:batchID/:student_id"
                                element={
                                    <PrivateRoute
                                        component={CreateInstallmentHostel}
                                        resource={permissionConstants.STUDENT}
                                    />
                                }
                            />
                            <Route
                                path="/payment/edit-hostel-installment/:centreID/:batchID/:student_id"
                                element={
                                    <PrivateRoute
                                        component={InstallmentEditHostel}
                                        resource={permissionConstants.STUDENT}
                                    />
                                }
                            />
                            <Route
                                path="/payment/payment-details-hostel/:student_id"
                                element={
                                    <PrivateRoute
                                        component={PaymentDetailsHostel}
                                        resource={permissionConstants.STUDENT}
                                    />
                                }
                            />

                            <Route
                                path="/payment-reversal"
                                element={
                                    <PrivateRoute
                                        component={PaymentReversal}
                                        resource={
                                            permissionConstants.PAYMENT_REVERSAL
                                        }
                                    />
                                }
                            />

                            <Route
                                path="/payment-reversal/pending"
                                element={
                                    <PrivateRoute
                                        component={PaymentReversalPending}
                                        resource={
                                            permissionConstants.PAYMENT_REVERSAL
                                        }
                                    />
                                }
                            />

                            <Route
                                path="/payment-reversal/history"
                                element={
                                    <PrivateRoute
                                        component={PaymentReversalHistory}
                                        resource={
                                            permissionConstants.PAYMENT_REVERSAL
                                        }
                                    />
                                }
                            />


                            {/*
                                MyCRM1.1
                            */}

                            <Route
                                path="/hub"
                                element={
                                    <PrivateRoute
                                        component={Hub}
                                        resource={permissionConstants.HUB}
                                    />
                                }
                            />

                            <Route
                                path="/hub/list"
                                element={
                                    <PrivateRoute
                                        component={HubList}
                                        resource={permissionConstants.HUB}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/centre"
                                element={
                                    <PrivateRoute
                                        component={Centre}
                                        resource={permissionConstants.CENTRE}
                                    />
                                }
                            />
                            <Route
                                exact
                                path="/centre/list"
                                element={
                                    <PrivateRoute
                                        component={CentreList}
                                        resource={permissionConstants.CENTRE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/course"
                                element={
                                    <PrivateRoute
                                        component={Course}
                                        resource={permissionConstants.COURSE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/course/list"
                                element={
                                    <PrivateRoute
                                        component={CourseList}
                                        resource={permissionConstants.COURSE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/batch"
                                element={
                                    <PrivateRoute
                                        component={Batch}
                                        resource={permissionConstants.BATCH}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/batch/list"
                                element={
                                    <PrivateRoute
                                        component={BatchList}
                                        resource={permissionConstants.BATCH}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/subject"
                                element={
                                    <PrivateRoute
                                        component={Subject}
                                        resource={permissionConstants.SUBJECT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/subject/list"
                                element={
                                    <PrivateRoute
                                        component={SubjectList}
                                        resource={permissionConstants.SUBJECT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/chapter"
                                element={
                                    <PrivateRoute
                                        component={Chapter}
                                        resource={permissionConstants.CHAPTER}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/chapter/list"
                                element={
                                    <PrivateRoute
                                        component={ChapterList}
                                        resource={permissionConstants.CHAPTER}
                                    />
                                }
                            />

                            {/* <Route
                                exact
                                path="/studio"
                                element={
                                    <PrivateRoute
                                        component={Studio}
                                    // resource={permissionConstants.COURSE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/studio/list"
                                element={
                                    <PrivateRoute
                                        component={StudioList}
                                    // resource={permissionConstants.COURSE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/group"
                                element={
                                    <PrivateRoute
                                        component={Group}
                                    // resource={permissionConstants.CHAPTER}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/group/list"
                                element={
                                    <PrivateRoute
                                        component={GroupList}
                                    // resource={permissionConstants.COURSE}
                                    />
                                }
                            /> */}

                            <Route
                                exact
                                path="/payment"
                                element={
                                    <PrivateRoute
                                        component={FinancePayment}
                                        resource={permissionConstants.PAYMENT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/pending-cheque"
                                element={
                                    <PrivateRoute
                                        component={FinancePendingCheque}
                                        resource={permissionConstants.CHEQUE}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/pending-deposit"
                                element={
                                    <PrivateRoute
                                        component={FinancePendingDeposits}
                                        resource={permissionConstants.BANK_DEPOSIT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/pending-deposit/bulk-deposit"
                                element={
                                    <PrivateRoute
                                        component={FinanceBulkBankDeposit}
                                        resource={permissionConstants.BANK_DEPOSIT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/pending-emi"
                                element={
                                    <PrivateRoute
                                        component={FinancePendingEMI}
                                        resource={permissionConstants.NO_COST_EMI}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/faculty"
                                element={
                                    <PrivateRoute
                                        component={FacultyOnboarding}
                                        resource={permissionConstants.FACULTY}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/assign-reviewer"
                                element={
                                    <PrivateRoute
                                        component={AssignQuestion}
                                        resource={permissionConstants.REVIEWER}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/assign-reviewer/subjects"
                                element={
                                    <PrivateRoute
                                        component={AssignReviewer}
                                        resource={permissionConstants.REVIEWER}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/cash"
                                element={
                                    <PrivateRoute
                                        component={FinanceCashReconciliation}
                                        resource={permissionConstants.FM_DEPOSIT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/cash-position"
                                element={
                                    <PrivateRoute
                                        component={FinanceCashPosition}
                                        resource={permissionConstants.FM_DEPOSIT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/cash/pending"
                                element={
                                    <PrivateRoute
                                        component={FinancePendingCashDeposits}
                                        resource={permissionConstants.FM_DEPOSIT}
                                    />
                                }
                            />
                            <Route
                                exact
                                path="/cash/list"
                                element={
                                    <PrivateRoute
                                        component={FinanceCashList}
                                        resource={permissionConstants.FM_DEPOSIT}
                                    />
                                }
                            />
                            <Route
                                exact
                                path="/cash/not-confirmed"
                                element={
                                    <PrivateRoute
                                        component={FinanceCashNotConfirmed}
                                        resource={permissionConstants.FM_DEPOSIT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/cash-deposit"
                                element={
                                    <PrivateRoute
                                        component={CashDeposit}
                                        resource={permissionConstants.ACCOUNTANT_DEPOSIT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/cash-deposit/list"
                                element={
                                    <PrivateRoute
                                        component={CashDepositList}
                                        resource={permissionConstants.ACCOUNTANT_DEPOSIT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/cash-deposit/confirm"
                                element={
                                    <PrivateRoute
                                        component={ConfirmCash}
                                        resource={permissionConstants.ACCOUNTANT_DEPOSIT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/cash-deposit/add"
                                element={
                                    <PrivateRoute
                                        component={AddCashDeposit}
                                        resource={permissionConstants.ACCOUNTANT_DEPOSIT}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/accountant/dashboard"
                                element={
                                    <PrivateRoute
                                        component={AccountantDashboard}
                                        resource={permissionConstants.ACCOUNTANT_DEPOSIT}
                                    />
                                }
                            />

							<Route 
								exact 
								path="/lead" 
								element={
									<PrivateRoute 
										component={LeadManagement}
                                        resource={permissionConstants.LEAD}
									/>
								} 
							/>
							<Route 
								exact 
								path="/lead/search" 
								element={
									<PrivateRoute 
										component={LeadSearch}
                                        resource={permissionConstants.LEAD}
									/>
								} 
							/>
							<Route 
								exact 
								path="/lead/add" 
								element={
									<PrivateRoute 
										component={LeadAdd}
                                        resource={permissionConstants.LEAD}
									/>
								} 
							/>
							<Route 
								exact 
								path="/lead/list" 
								element={
									<PrivateRoute 
										component={LeadList}
                                        resource={permissionConstants.LEAD}
									/>
								} 
							/>
							<Route 
								exact 
								path="/lead/:leadId" 
								element={
									<PrivateRoute 
										component={EditLead}
                                        resource={permissionConstants.LEAD}
									/>
								} 
							/>

                            <Route
                                path="*"
                                element={
                                    <PublicRoute
                                        component={ErrorPage}
                                    />
                                }
                            />

                            <Route
                                path="/mpp/add"
                                element={
                                    <PrivateRoute
                                        component={AddMpp}
                                        resource={permissionConstants.MPP}
                                    />
                                }
                            />

                            <Route
                                path="/mpp/add/:mppID"
                                element={
                                    <PrivateRoute
                                        component={AddMpp}
                                        resource={permissionConstants.MPP}
                                    />
                                }
                            />

                            <Route
                                path="/mpp/instructions"
                                element={
                                    <PrivateRoute
                                        component={MppInstructions}
                                        resource={permissionConstants.MPP}
                                    />
                                }
                            />

                            <Route
                                path="/mpp/list"
                                element={
                                    <PrivateRoute
                                        component={MppList}
                                        resource={permissionConstants.MPP}
                                    />
                                }
                            />
                            <Route
                                path="/mpp/schedule-history/:mppID"
                                element={
                                    <PrivateRoute
                                        component={MppScheduleHistory}
                                        resource={permissionConstants.MPP}
                                    />
                                }
                            />
                            <Route
                                path="/mpp/:mppID"
                                element={
                                    <PrivateRoute
                                        component={MppView}
                                        resource={permissionConstants.MPP}
                                    />
                                }
                            />
                            <Route
                                exact
                                path="/qb"
                                element={
                                    <PrivateRoute 
                                        component={QuestionBank}
                                        resource={permissionConstants.QUESTION_BANK}
                                    />
                                }
                            />
                            <Route
                                exact
                                path="/qb/add"
                                element={
                                    <PrivateRoute 
                                        component={QuestionBankAdd} 
                                        resource={permissionConstants.QUESTION_BANK}
                                    />
                                }
                            />
                            <Route
                                exact
                                path="/qb/list"
                                element={
                                    <PrivateRoute 
                                        component={QuestionBankList} 
                                    />
                                }
                            />
                            <Route
                                exact
                                path="/qb/create/:qbID"
                                element={
                                    <PrivateRoute 
                                        component={QuestionBankCreate} 
                                        resource={permissionConstants.QUESTION_BANK}
                                    />
                                }
                            />

                        </Route>

                        <Route
                            path="/paymentlink/:orderid"
                            element={
                                <PublicRoute
                                    component={PaymentLink}
                                />
                            }
                        />
                        <Route
                            path="/hostelpaymentlink/:orderid"
                            element={
                                <PublicRoute
                                    component={HostelPaymentLink}
                                />
                            }
                        />

                        <Route
                            path="/payment/paymentFailed"
                            element={
                                <PublicRoute
                                    component={PaymentFailed}
                                />
                            }
                        />
                        <Route
                            path="/payment/paymentSuccess"
                            element={
                                <PublicRoute
                                    component={PaymentSuccess}
                                />
                            }
                        />

                        <Route
                            path="/payment/payment-failed"
                            element={
                                <PublicRoute
                                    component={PaymentFailed}
                                />
                            }
                        />
                        <Route
                            path="/payment/payment-success-hostel"
                            element={
                                <PublicRoute
                                    component={PaymentSuccessHostel}
                                />
                            }
                        />

                        <Route
                            path="/payment/hdfc/:paymentId"
                            element={
                                <PublicRoute
                                    component={PaymentHDFC}
                                />
                            }
                        />

                        

                        <Route
                            path="*"
                            element={
                                <PublicRoute
                                    component={ErrorPage}
                                />
                            }
                        />
                        {/* <Route path="*"  element={<Navigate to={data?.authenticated?"/error":"/login"}/>} /> */}
                    </Routes>
                </BrowserRouter>
            </Provider>
        </Suspense >
    );
}

export default App;
