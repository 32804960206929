import React from 'react'
import { Checkbox } from 'antd';
import moment from "moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { currencyFormatter } from '../../../../utils/helperFunctions';
import flagImg from "../../../../assets/img/flagImg.svg";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const CounselorCard = ({
    isCheckedPayment,
    handleCheck,
    handleCheckGroup,
    isCheckedGroup,
    item
}) => {

    const {
        counsellor_name,
        counsellor_id,
        centre_name,
        centre_id,
        student_fees,
        total_amount_paid,
        paymentIds
    } = item

    return (
        <div className="confirm_cash_counselor_container">
            <div className="confirm_cash_counselor_row">
                <div className="confirm_cash_counselor_column1">
                    <div className="confirm_cash_checkbox_div">
                        <Checkbox className="checkbox_1" onChange={() => handleCheckGroup(paymentIds)} checked={isCheckedGroup(paymentIds)} />
                        <div className="confirm_cash_checkbox_div2">
                            <span className="cash_deposit_card_column_header">Counsellor</span>
                            {counsellor_name.length > 20 ? (
                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={counsellor_name}>
                                    <span style={{ cursor: "pointer" }}>{counsellor_name.substring(0, 20) + "..."}</span>
                                </BootstrapTooltip>
                            ) : (
                                counsellor_name
                            )}
                        </div>
                    </div>
                </div>

                <div className="confirm_cash_counselor_column2">
                    <span className="cash_deposit_card_column_header">Transaction count</span>
                    {student_fees.length}
                </div>

                <div className="confirm_cash_counselor_column2">
                    <span className="cash_deposit_card_column_header">Cash collection</span>₹ {currencyFormatter(total_amount_paid, false)}
                </div>

                <div className="confirm_cash_counselor_column3">
                    <span className="cash_deposit_card_column_header">Centre</span>
                    {centre_name}
                </div>
            </div>
            {student_fees.map((student) => {
                return (
                    <>
                        <div key={student.payment_id} className="confirm_cash_counselor_row">
                            <div className="confirm_cash_counselor_column1">
                                <div className="confirm_cash_checkbox_div3">
                                    <Checkbox
                                        className="checkbox_1"
                                        onChange={() => handleCheck(student.payment_id)}
                                        // checked={getCheckedStateForStudent(centre_id,counsellor_id,student.student_id)}
                                        checked={isCheckedPayment(student.payment_id)}
                                        disabled={student.reversed}
                                    />
                                    <div className="confirm_cash_checkbox_div2">
                                        <span className="cash_deposit_card_column_header">Student name</span>
                                        <span className="confirm_cash_header2">{student.student_name}</span>
                                        <span className="cash_deposit_card_column_header2">ID : {student.student_id}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="confirm_cash_counselor_column2">
                                <span className="cash_deposit_card_column_header">Date of payment</span>
                                <span className="confirm_cash_header2">{moment(student.paid_on_date).format("MMM DD, YYYY")}</span>
                            </div>

                            <div className="confirm_cash_counselor_column2">
                                <span className="cash_deposit_card_column_header">
                                    Cash collection 
                                    {
                                        student.reversed && 
                                        <img 
                                            src={flagImg} 
                                            alt="reversal flag" 
                                            className="reversal-flag-cash-recon"
                                        />
                                    }
                                </span>
                                <span className="confirm_cash_header2">₹ {currencyFormatter(student.amount_paid, false)}</span>
                            </div>
                            <div className="confirm_cash_counselor_column3"></div>
                        </div>                        
                        {
                            student.reversed &&
                            <div className="reversal-flag-div">
                                <img src={flagImg} alt="reversal flag" style={{ width: "13px", height: "13px" }} />
                                <span className="reversal-flag-text">Reversal raised by counsellor for the cash entry</span>
                            </div>
                        }
                    </>
                );
            })}
        </div>
    );
}

export default React.memo(CounselorCard)