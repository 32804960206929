import React from "react";
import { useSelector } from "react-redux";
import { roleConstant } from "../../utils/helperFunctions";
import FinanceManagerDashboard from "../finance/cashReconciliation/cashPosition";
import AccountantDashboard from "../accountant/dashboard";
import Loader from "../common/Loader";
import Mpp from "../mpp";

const Dashboard = () => {
	let data = localStorage.getItem("userdetail");
	data = JSON.parse(data);

	const { roleList, roleApiCalled } = useSelector(state => state.auth);

	const checkIsDTP = () => {
		return roleList?.filter(x => x.role_name === roleConstant.DTP).length > 0 ? true : false
	}

	const checkIsAccountant = () => {
		return roleList?.filter(x => x.role_name === roleConstant.ACCOUNTANT).length > 0 ? true : false
	}

	const checkIsFinanceManager = () => {
		return roleList?.filter(x => x.role_name === roleConstant.FINANCE_MANAGER).length > 0 ? true : false
	}

	if (!roleApiCalled) {
		return <div className="loader_scroll_container"><Loader /></div>
	}

	if(checkIsDTP()) {
		return <Mpp />
	}

	if (checkIsAccountant()) {
		return <AccountantDashboard />
	}

	if (checkIsFinanceManager()) {
		return <FinanceManagerDashboard />
	}

	return (
		<div className="homePage">
			<h1 style={{ fontWeight: "600", fontSize: "30px" }}>
				Welcome back {data.user_display_name} !
			</h1>

			<p>
				Dashboard is under review. Meanwhile you can navigate through other menu options on your left.
			</p>
			{/* <div style={{ padding: "20px" }}>
				<h1 style={{ padding: "40" }}>Dashboard Coming soon</h1>
				<h3>
					Meanwhile, you can add/view a student from the left navigation
					panel.
				</h3>
			</div>
			<div className="row">

			</div> */}
		</div>
	);
};

export default Dashboard;