import React
    from "react";
const DifficultyLevel = ({ selectedLevel = '3' }) => {
    return <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, alignItems: 'center' }}>
        {[1, 2, 3, 4, 5].map(item => {
            return <div className={item == selectedLevel ? "mpp_difficulty_level_selected" : "mpp_difficulty_level_unselected"}>
                <span className={item == selectedLevel ? "mpp_difficulty_level_text_selected" : "mpp_difficulty_level_text_unselected"}>{item}</span>
            </div>
        })}
    </div>
}

export default DifficultyLevel;