import React, { useEffect } from 'react';
import Api from './api';

let lastVisitedPage = "";

const PublicRoute = ({ component: Component, ...props }) => {

    useEffect(() => {
        if (lastVisitedPage !== window.location.pathname) {
            Api.trackPage()
            lastVisitedPage = window.location.pathname;
        }        
    }, [window.location.pathname]);

    return <Component {...props} />
};

export default PublicRoute;