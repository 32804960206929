import React from "react";
import { Asterisk } from "../../../utils/helperFunctions";
import ErrorText, { InfoText } from "../../common/errorText";
import { State, City } from "country-state-city";
import StudentIcon from "../../../assets/img/student_icon.svg";
import IndianFlag from "../../../assets/img/indian_flag.svg";
import DropdownImg from "../../../assets/img/drop_down.svg";
import { Select } from "antd";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";

const { Option } = Select;

const Step1 = ({ data = {}, handleData, error, handleError, cityList, setCityList, disableMobile = false, getLeadDetails, isPrefilled, resetData }) => {
	const { studentName, studentMobile, state, city } = data;
	const { studentNameError, studentMobileError, stateError, cityError } = error;

	const handleChange = async (payload, errorPayload) => {
		if (payload) {
			if (isPrefilled && Object.keys(payload)[0] === "studentMobile") {
				resetData(payload);
				return;
			}
			handleData({
				...data,
				...payload,
			});
		}
		if (errorPayload) {
			handleError({
				...error,
				...errorPayload,
			});
		}
		let objKey = Object.keys(payload)[0];
		if (objKey === "studentMobile" && payload[objKey].length === 10) {
			const { data, status } = await Api.doFetch("POST", { mobile_number: payload[objKey] }, `${ApiUrl.CHECK_MOBILE}`);
			if (status && data.mobile_exists) {
				let prefillAvailable = false;
				if (data.is_lead) {
					prefillAvailable = await getLeadDetails(data.lead_id);
				}

				handleError((error) => {
					return {
						...error,
						studentMobileError: prefillAvailable
							? "Mobile no. already exists, details prefilled below for enrollment"
							: data.is_student
							? "Mobile no. already exists, please search from existing student list"
							: "Mobile no. already exists",
					};
				});
			}
		}
	};

	return (
		<>
			<div>
				<p className="student_filter_drop_header student_text_bottom">
					Student Mobile Number <Asterisk />
				</p>

				<img src={IndianFlag} alt="indian" className="student_mobile_indian_img" />
				<img src={DropdownImg} alt="dropdown" className="student_mobile_drop_img" />
				<div className="d-flex">
					<input className={`change_pw_input student_add_mobile_indian`} value={"+91"} disabled />

					<input
						className={`change_pw_input student_add_mobile_input ${studentMobileError && !isPrefilled ? "change_pw_input_error" : ""}`}
						placeholder="Enter number"
						value={studentMobile}
						onChange={(e) => {
							if (e.target.value.length <= 10) {
								handleChange(
									{
										studentMobile: e.target.value.replace(/[^0-9]/g, ""),
									},
									{
										studentMobileError: "",
									}
								);
							}
						}}
						disabled={disableMobile}
					/>
				</div>
				{studentMobileError && <InfoText status={isPrefilled ? "success" : "error"} fontSize="1.2rem" text={studentMobileError} />}
			</div>

			<div className="mt-3">
				<p className="student_filter_drop_header student_text_bottom">
					Student Name <Asterisk />
				</p>

				<img src={StudentIcon} alt="student" className="add_student_student_icon" />

				<input
					className={`change_pw_input add_student_student_icon_input ${studentNameError ? "change_pw_input_error" : ""}`}
					placeholder="Enter name"
					value={studentName}
					onChange={(e) => {
						handleChange(
							{
								studentName: e.target.value,
							},
							{
								studentNameError: "",
							}
						);
					}}
				/>
				{studentNameError && <ErrorText text={studentNameError} />}
			</div>

			<div className="mt-3 antd_dropdown">
				<p className="student_filter_drop_header">
					State <Asterisk />
				</p>

				<Select
					className="full-width"
					value={state || undefined}
					placeholder="Select State"
					onChange={(value) => {
						handleChange(
							{
								state: value || "",
								city: "",
							},
							{
								stateError: "",
							}
						);

						if (value) {
							let index = State.getStatesOfCountry("IN").findIndex((x) => x.name === value);

							if (index >= 0) {
								setCityList(City.getCitiesOfState("IN", State.getStatesOfCountry("IN")[index].isoCode));
							} else {
								setCityList([]);
							}
						} else {
							setCityList([]);
						}
					}}
					showArrow
					allowClear
					showSearch
				>
					{State.getStatesOfCountry("IN").map((stateData, index) => {
						return (
							<Option value={stateData.name} key={index}>
								{stateData.name}
							</Option>
						);
					})}
				</Select>
				{stateError && <ErrorText text={stateError} />}
			</div>

			<div className="mt-3 antd_dropdown">
				<p className="student_filter_drop_header">
					City <Asterisk />
				</p>

				<Select
					className="full-width"
					value={city || undefined}
					placeholder="Select City"
					onChange={(value) => {
						handleChange(
							{
								city: value || "",
							},
							{
								cityError: "",
							}
						);
					}}
					showArrow
					allowClear
					showSearch
				>
					{cityList &&
						cityList.map((cityData, index) => {
							return (
								<Option value={cityData.name} key={index}>
									{cityData.name}
								</Option>
							);
						})}
				</Select>
				{cityError && <ErrorText text={cityError} />}
			</div>
		</>
	);
};

export default Step1;
