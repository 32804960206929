import React from "react";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const HandleCentreTooltip = ({ 
    selectedCentre, 
    centreList
}) => {
    return (
        <>
            {selectedCentre.length > 2 ? (
                <>
                    {selectedCentre.slice(0, 2).map((item, index) => {
                        return (
                            <p key={index} className="mb-0">
                                {centreList
                                    .filter((x) => x.id === item)
                                    .map((item2) => {
                                        return <p className="cheque-selected-filter" style={{ margin: "auto 4px" }}>{item2.centre}</p>;
                                    })}
                            </p>
                        );
                    })}
                    <BootstrapTooltip
                        disableFocusListener
                        enterTouchDelay={0}
                        title={selectedCentre.slice(2, selectedCentre.length).map((item) => {
                            return centreList
                                .filter((x) => x.id === item)
                                .map((item2) => {
                                    return item2.centre + ", ";
                                });
                        })}
                    >
                        <div className="cheque-selected-filter" style={{ cursor: "pointer", margin: "auto 4px" }}>
                            {`+ ${selectedCentre.length - 2}`}
                        </div>
                    </BootstrapTooltip>
                </>
            ) : (
                <>
                    <p className="mb-0">
                        {centreList
                            .filter((x) => x.id === selectedCentre[0])
                            .map((item2) => {
                                return <p className="cheque-selected-filter" style={{ margin: "auto 4px" }}>{item2.centre}</p>;
                            })}
                    </p>

                    <p className="mb-0">
                        {centreList
                            .filter((x) => x.id === selectedCentre[1])
                            .map((item2) => {
                                return <p className="cheque-selected-filter" style={{ margin: "auto 4px" }} >{item2.centre}</p>;
                            })}
                    </p>
                </>
            )}
        </>
    );
};

export default HandleCentreTooltip;
