import { HANDLE_COMMON } from '../actions/types';

const initialState = {
    myclassroomCentre: []
};
//eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_COMMON:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}