import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Accordian from "../../../utils/accordian";
import { DatePicker } from "antd";
import { Select } from "antd";
import HandleCentreTooltip from "./HandleCentreTooltip";
import HandleTooltip from "./handleTooltip";
import moment from "moment";
import ErrorText from "../../common/errorText";
import { Capitalize, PaymentMode, PaymentStatus } from "../../../utils/helperFunctions";
import { useSelector } from "react-redux";
import SelectComponent from "../../common/component/select";

const { Option } = Select;

const Filter = ({
    startDate,
    endDate,
    centre,
    paymentMode,
    paymentStatus,
    setStartDate,
    startDateRef,
    setEndDate,
    endDateRef,
    setCentre,
    setPaymentMode,
    setPaymentStatus,
    centreRef,
    paymentModeRef,
    paymentStatusRef,
    dateError,
    setDateError,
    getDetails,
    filterLoading,
    statusFilter = false,
}) => {

    const { myclassroomCentre } = useSelector(state => state.common);    

    const [isOpen, setIsOpen] = useState(true);

    const filterArrowToggle = () => {
        setIsOpen(!isOpen);

        setEndDate(endDateRef.current);
        setStartDate(startDateRef.current);
        setCentre(centreRef.current);

        if (statusFilter) {
            setPaymentMode(paymentModeRef.current);
            setPaymentStatus(paymentStatusRef.current);
        }

        setStartDateError("");
        setEndDateError("");
        setDateError("");
    };

    const handleClearFilter = () => {        
        setEndDate("");
        setStartDate("");
        setCentre([]);

        if (statusFilter) {
            setPaymentMode([]);
            setPaymentStatus([]);

            paymentModeRef.current = [];
            paymentStatusRef.current = [];
        }

        setIsOpen(true);

        startDateRef.current = "";
        endDateRef.current = "";
        centreRef.current = [];

        setStartDateError("");
        setEndDateError("");
        setDateError("");

        getDetails();
    }

    const filterApplied = () => {

        if (
            startDateRef.current ||
            endDateRef.current ||
            (centreRef.current && centreRef.current.length > 0)
        ) {
            return true;
        }
        if (statusFilter) {
            if (
                (paymentModeRef.current && paymentModeRef.current.length > 0) ||
                (paymentStatusRef.current && paymentStatusRef.current.length > 0)
            ) {
                return true;
            }
        }
        return false;
    };

    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");

    const canApplyFilter = (skipStateUpdate = false) => {

        if (startDate && !endDate) {
            if(!skipStateUpdate) setEndDateError("Please select To date");
            return false;
        }

        if (endDate && !startDate) {
            if(!skipStateUpdate) setStartDateError("Please select From date");
            return false;
        }

        if (startDate && endDate && moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
            if(!skipStateUpdate) setDateError("From date cannot be greater than To date");
            return false;
        }

        if (
            startDate ||
            endDate ||
            (centre && centre.length > 0)
        ) {
            return true;
        }

        if (statusFilter) {
            if (
                (paymentMode && paymentMode.length > 0) ||
                (paymentStatus && paymentStatus.length > 0)
            ) {
                return true;
            }
        }

        return false;
    };

    const applyFilter = () => {

        if (
            startDate ||
            endDate ||
            (centre && centre.length > 0)
        ) {
            return true;
        }

        if (statusFilter) {
            if (
                (paymentMode && paymentMode.length > 0) ||
                (paymentStatus && paymentStatus.length > 0)
            ) {
                return true;
            }
        }

        return false;
    };

    const getCentreList = () => {
        return myclassroomCentre.filter(x => x && x.centre && x.centre.trim())
    }

    return (
        <div className="pending-cheques__filter_container">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
                <p className="filter-heading mb-0">Filter by</p>
                <span 
                    onClick={() => {
                        if (filterApplied()) {
                            filterArrowToggle();
                        }
                    }} 
                    className={`${!filterApplied() ? "disabled-content" : ""} filter-arrow-wrapper`}
                >
                    {
                        !filterApplied() ?
                        <KeyboardArrowDownIcon style={{ width: "22px", height: "22px" }} />
                        :
                        isOpen ?
                        <KeyboardArrowUpIcon style={{ width: "22px", height: "22px" }} />
                        :
                        <KeyboardArrowDownIcon style={{ width: "22px", height: "22px" }} />
                    }
                </span>
            </div>

            {
                filterApplied() && !isOpen &&
                <div>
                    <div className="selected-filters-cheque-row">
                        <div className="flex-wrapper-cheque date-row-cheque">
                            
                            <div className="flex-wrapper-cheque">
                                <p className="mb-0">
                                    From{" "}
                                </p>
                                <p className="cheque-selected-filter">
                                    {
                                        startDate ?
                                        moment(startDate).format("MMM DD, YYYY")
                                        :
                                        "-"
                                    }
                                </p>
                            </div>

                            <div className="flex-wrapper-cheque">
                                <p className="mb-0">
                                    To{" "}
                                </p>
                                <p className="cheque-selected-filter"> 
                                    {
                                        endDate ?
                                        moment(endDate).format("MMM DD, YYYY")
                                        :
                                        "-"
                                    }
                                </p>
                            </div>
                        </div>

                        {
                            centre.length !== 0 ?
                            <div className="selected-filters-cheque-col-2">
                                <div className="flex-wrapper-cheque">
                                    <p className="mb-0">Centre name </p>
                                    <HandleCentreTooltip centreList={getCentreList()} selectedCentre={centre} />
                                </div>
                            </div>
                            :
                            <div className="selected-filters-cheque-col-2">
                                <div className="flex-wrapper-cheque">
                                    <p className="mb-0">Centre name </p>
                                    <p className="cheque-selected-filter">All</p>
                                </div>
                            </div>
                        }
                    </div>

                    {
                        statusFilter &&
                        <div className="selected-filters-cheque-row">
                            {
                                paymentMode.length !== 0 ?
                                <div className="flex-wrapper-cheque date-row-cheque">
                                    <div className="flex-wrapper-cheque">
                                        <p className="mb-0">Payment mode </p>
                                        <HandleTooltip selectedValue={paymentMode} dataList={Object.values(PaymentMode)} />
                                    </div>
                                </div>
                                :
                                <div className="selected-filters-cheque-col-2">
                                    <div className="flex-wrapper-cheque">
                                        <p className="mb-0">Payment mode </p>
                                        <p className="cheque-selected-filter">All</p>
                                    </div>
                                </div>
                            }

                            {
                                paymentStatus.length !== 0 ?
                                <div className="selected-filters-cheque-col-2">
                                    <div className="flex-wrapper-cheque">
                                        <p className="mb-0">Payment status </p>
                                        <HandleTooltip dataList={Object.values(PaymentStatus)} selectedValue={paymentStatus} />
                                    </div>
                                </div>
                                :
                                <div className="selected-filters-cheque-col-2">
                                    <div className="flex-wrapper-cheque">
                                        <p className="mb-0">Payment status </p>
                                        <p className="cheque-selected-filter">All</p>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                </div>
            }

            <Accordian expand={isOpen} noStyles={true}>
                <div className="filter_expanded_div">
                    <div className="cheque-form-container">
                        <div className="form-container__col-first filter">
                            <div className="cheque-status-select-container filter my-2 antd_dropdown">
                                <p className="cheque_filter_drop_header mb-1">
                                    <span className="filter-heading-light">Entry date: </span>From
                                </p>
                                <DatePicker
                                    allowClear={false}
                                    className="attendance_date_picker mb-0"
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    // style={{ width: "100%" }}
                                    format={"DD/MM/YYYY"}
                                    value={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setDateError("");
                                        setStartDateError("");
                                    }}
                                    placeholder = "DD/MM/YYYY"
                                />
                                {
                                    dateError &&
                                    <ErrorText text={dateError} />
                                }
                                {
                                    startDateError &&
                                    <ErrorText text={startDateError} />
                                }
                            </div>
                            <div className="cheque-status-select-container filter  my-2">
                                <p className="cheque_filter_drop_header mb-1">
                                    <span className="filter-heading-light">Entry date: </span>To
                                </p>
                                <DatePicker
                                    allowClear={false}
                                    className="attendance_date_picker mb-0"
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    // style={{ width: "100%" }}
                                    format={"DD/MM/YYYY"}
                                    value={endDate}
                                    onChange={(date) => {
                                        setDateError("");
                                        setEndDate(date);
                                        setEndDateError("");
                                    }}
                                    placeholder = "DD/MM/YYYY"
                                />
                                {
                                    endDateError &&
                                    <ErrorText text={endDateError} />
                                }
                            </div>

                            <div className="cheque-status-select-container filter my-2">
                                <p className="cheque_filter_drop_header mb-1">Centre name</p>

                                <div className="antd_dropdown">
                                    <SelectComponent 
                                        value = {centre}
                                        setValue = {value => {
                                            let index = value.indexOf("Select All");

                                            if (index >= 0) {
                                                if (centre.length === getCentreList().length) {
                                                    setCentre([]);
                                                } else {
                                                    setCentre(getCentreList().map(x => x.id));
                                                }
                                            } else {
                                                setCentre(value);
                                            }
                                            
                                        }}
                                        data = {getCentreList()}
                                        keyValue = {"centre"}
                                        placeholder = {"Select centre"}
                                        selectAll = {true}
                                    />
                                    {/* <Select
                                        mode="multiple"
                                        getPopupContainer={(triggerNode) => {
                                            return triggerNode.parentNode;
                                        }}
                                        style={{ width: "100%" }}
                                        placeholder="Select course name"
                                        showArrow
                                        showSearch
                                        optionFilterProp="children"
                                        value={centre}
                                        onChange={(centreData) => {
                                            setCentre(centreData);
                                        }}
                                        // allowClear
                                        tagRender={ data => {
                                            if (centre?.length > 0 && +data.value === +centre[0]) {
                                                return <span className="antd-placeholder-tag" >Select course name</span>;
                                            }
                                            return null;
                                        }}
                                    >
                                        {
                                            myclassroomCentre.filter(x => x).map((options, index) => {
                                            return (
                                                <Option key={index} value={options.id}>
                                                    {options.centre}
                                                </Option>
                                            );
                                        })}
                                    </Select> */}
                                </div>
                            </div>
                        </div>

                        <div className="my-2 padding_top">
                            <button
                                className="btn cheques_apply-filter-button mr-2"
                                onClick={() => {
                                    if (!canApplyFilter()) {
                                        return;
                                    }
                                    if (!filterLoading) {
                                        startDateRef.current = startDate;
                                        endDateRef.current = endDate;
                                        centreRef.current = centre;

                                        if (statusFilter) {
                                            paymentModeRef.current = paymentMode;
                                            paymentStatusRef.current = paymentStatus;
                                        }

                                        getDetails();
                                        setIsOpen(false);
                                    }
                                }}
                                disabled = {(filterLoading || !applyFilter()) ? true : false}
                            >
                                Apply
                            </button>

                            <span className="clear-filter-cheques ml-2" onClick={handleClearFilter}>Clear Filter</span>
                        </div>
                    </div>

                    {
                        statusFilter &&
                        <div className="cheque-form-container justify-content-flex-start ">
                            <div className="form-container__col-first filter">
                                <div className="cheque-status-select-container filter  my-2">
                                    <p className="cheque_filter_drop_header mb-1">Payment mode</p>

                                    <div className="antd_dropdown">
                                        <SelectComponent 
                                            value = {paymentMode}
                                            setValue = {setPaymentMode}
                                            data = {Object.values(PaymentMode)}
                                            placeholder = {"Select type"}
                                        />
                                        {/* <Select
                                            mode="multiple"
                                            getPopupContainer={(triggerNode) => {
                                                return triggerNode.parentNode;
                                            }}
                                            style={{ width: "100%" }}
                                            placeholder="Select type"
                                            showArrow
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            value={paymentMode}
                                            onChange={(data) => {
                                                setPaymentMode(data);
                                            }}
                                        >
                                            {
                                                Object.values(PaymentMode).map((data, index) => {
                                                    return (
                                                        <Option key={index} value={data}>
                                                            {Capitalize(data)}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select> */}
                                    </div>
                                </div>
                                <div className="cheque-status-select-container filter my-2">
                                    <p className="cheque_filter_drop_header mb-1">Payment status</p>

                                    <div className="antd_dropdown">
                                        <SelectComponent 
                                            value = {paymentStatus}
                                            setValue = {setPaymentStatus}
                                            data = {Object.values(PaymentStatus)}
                                            placeholder = {"Select status"}
                                            capitalize = {true}
                                        />
                                        {/* <Select
                                            mode="multiple"
                                            getPopupContainer={(triggerNode) => {
                                                return triggerNode.parentNode;
                                            }}
                                            style={{ width: "100%" }}
                                            placeholder="Select status"
                                            showArrow
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            value={paymentStatus}
                                            onChange={(data) => {
                                                setPaymentStatus(data);
                                            }}
                                        >
                                            {
                                                Object.values(PaymentStatus).map((data, index) => {
                                                    return (
                                                        <Option key={index} value={data}>
                                                            {Capitalize(data)}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    
                </div>
            </Accordian>

        </div>
    );
};

export default Filter;
