import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const CommonModal =({isOpen,onCancel, onClose, onSave, message="Click yes to save changes. If you click no, then all unsaved changes will be lost"})=> {
  return (
    <Modal
                open={isOpen}
                onClose={() => onClose()}
                className="payment_reversal_cofirmation_modal"
            >
                <Box className="view_cheque_modal" >
                    <p className="reversal_accept_text">
                        Do you want to save changes?
                    </p>
                    <p className="payment_details_list_card_header value" style={{ marginTop: '22px', marginBottom: '16px' }}>
                        {message}
                    </p>

                    <div
                        className="mt-3"
                        style={{ float: "right" }}
                    >
                        <button
                            className="btn btn-md student_detail_no"
                            onClick={() =>  onCancel()}
                        >
                            No
                        </button>
                        <button
                            className="btn btn-md student_detail_password_confirm ml-2"
                            onClick={() => onSave()}
                        >
                            Save
                            {/* {resetPasswordLoading && <span className="ml-2 spinner spinner-white mr-3"></span>} */}
                        </button>
                    </div>

                </Box>
            </Modal>
  )
}

export default CommonModal