import React, { useEffect } from 'react'
import { Modal } from "@mui/material";
import CloseImg from "../../../../assets/img/close.svg";
import ToastMessage from '../../../common/toastMessage';
import { toast } from "react-toastify";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";


const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const MppScheduleModal = ({
    open, setShowModal, data, setData, scheduleMpp,
    assignState,
    handleState,
    postMppData,
    loading,
}) => {
    return (
        <Modal open={open} >
            <div className="mpp_modal_container">
                <div className="view_mpp_modal">
                    <p className="group_name_header_container">
                        Review batches before scheduling
                    </p>
                    <div>
                        <p className="group_name_header_container mb-2" >
                            Mpp details
                        </p>
                        {data?.mpp_details.map((mpp) => {
                            return <MppDetailsRow key={mpp.mpp_id} name={mpp.mpp_name} subject={mpp.subject_name} qCount={mpp.question_counts} />
                        })}
                    </div>

                    <div>
                        <p className="group_name_header_container mb-2 mt-1" >
                            Selected batches
                        </p>
                        {data && data.selected_batches.map((selectedbatch, index) => {
                            return <SelectedBatchCard key={index} batchData={selectedbatch} data={data} setData={setData} batchDataIndex={index} assignState={assignState} handleState={handleState} postMppData={postMppData} loading={loading} />
                        })}
                        <div className='d-flex justify-content-flex-end mt-2' style={{ gap: 10 }}>
                            <button className='btn mpp_assign_back'
                                onClick={() => {
                                    setShowModal(false)
                                }}

                            >
                                No
                            </button>
                            <button
                                disabled={data?.selected_batches?.length == 0}
                                onClick={() => {
                                    if (!loading) {
                                        scheduleMpp()
                                    }
                                }}
                                className="btn mpp_assign_button ml-1"
                            >
                                Schedule
                                {loading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                            </button>
                        </div>

                    </div>

                </div>
            </div>

        </Modal>
    );
}
const MppDetailsRow = ({ name, subject, qCount }) => {
    return <div className='d-flex-wrap'>
        <div className="col-md-3 mpp_details_card d-column" >
            <span className="mpp_details_card_header"> No of Questions</span>
            <span className='mpp_details_card_text'>{qCount}</span>
        </div>
        <div className="col-md-3 mpp_details_card d-column" style={{ backgroundColor: '#F5FFF6' }} >
            <span className="mpp_details_card_header"> Subject</span>
            <span className='mpp_details_card_text'>{subject}</span>
        </div>
        <div className="col-md-3 mpp_details_card d-column" style={{ backgroundColor: '#FFFAFA' }} >
            <span className="mpp_details_card_header"> Name</span>
            {name.length < 30 ? (
                <span className='mpp_details_card_text'>{name ? name : '-'}</span>
            ) : (
                <BootstrapTooltip
                    disableFocusListener
                    enterTouchDelay={0}
                    title={name}
                >
                    <p style={{ cursor: "pointer" }} className="course_list_card_value">
                        {name.substring(0, 30) + "..."}
                    </p>
                </BootstrapTooltip>
            )}
        </div>

    </div>
}

const SelectedBatchCard = ({ batchData, data, setData, batchDataIndex, assignState, handleState, postMppData, loading }) => {
    // console.log('SelectedBatchCard', assignState, data)
    return <div className="list_of_subjects_faculty_modal mb-2">
        <p className='total_fee_installment_text'>{batchData?.course_name}</p>
        {batchData && batchData.schools.map((school, schoolIndex) => {
            return school?.batches?.length == 0 ? null : <div key={school.school_id} className="mb-2">
                <span className='group_modal_center_text'>{school.school_name}</span>

                <div className="d-flex-wrap">
                    {school?.batches && school?.batches.map((batch, batchIndex) => {
                        return <div key={batch.batch_id} className="mpp_batch_tag mb-1">
                            <span className="modal_save_confimation_value">
                                {batch.batch_name}
                            </span>

                            <img
                                src={CloseImg}
                                className="cursor-pointer ml-1"
                                alt="close"
                                onClick={async () => {
                                    if (loading) return;
                                    let temp = data
                                    // delete temp.selected_batches[batchDataIndex].schools[schoolIndex].batches[batchIndex]
                                    let batchList = temp.selected_batches[batchDataIndex].schools[schoolIndex].batches
                                    batchList = batchList.filter((x, index) => index !== batchIndex)
                                    // console.log(batchList);
                                    temp.selected_batches[batchDataIndex].schools[schoolIndex].batches = batchList
                                    // console.log(temp);
                                    setData({ ...temp })
                                    // console.log('onClick', batch, assignState.selectedBatches)

                                    // let tempSelectedBatches = [...assignState.selectedBatches]
                                    // tempSelectedBatches = assignState.selectedBatches.filter(item => item?.batch_id != batch?.batch_id)
                                    // // console.log('tempAssignState', tempAssignState)
                                    // handleState({ selectedBatches: tempSelectedBatches })
                                    // await postMppData({ ...assignState, selectedBatches: tempSelectedBatches })
                                }}
                            />
                        </div>
                    })}
                </div>

            </div>
        })}


    </div>
}
export default MppScheduleModal