
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { Capitalize } from "../../../utils/helperFunctions";

const { Option } = Select;


const Filter = ({
    centre,
    setCentre,
    centreRef,
    getDetails,
    filterLoading
}) => {

    // const { myclassroomCentre } = useSelector(state => state.common);
    const { centerList } = useSelector(state => state.auth);

    // const [isOpen, setIsOpen] = useState(true);

    const filterArrowToggle = () => {
        // setIsOpen(!isOpen);

        setCentre(centreRef.current);
    };

    const handleClearFilter = () => {
        setCentre([]);

        // setIsOpen(true);

        centreRef.current = [];

        getDetails();
    }

    const filterApplied = () => {
        if (centreRef.current && centreRef.current.length > 0) {
            return true;
        }
        return false;
    };

    const canApplyFilter = (skipStateUpdate = false) => {

        if (centre && centre.length > 0) {
            return true;
        }

        return false;
    };

    const applyFilter = () => {

        if (centre && centre.length > 0) {
            return true;
        }

        return false;
    };

    const getCentreList = () => {
        // return myclassroomCentre.filter(x => x && x.centre && x.centre.trim())
        return centerList
            .filter((x) => x && x.school_name && x.school_name.trim())
            .map((x) => {
                return {
                    id: x.school_id,
                    centre: x.school_name,
                };
            })
            .sort(function (a, b) {
                var nameA = a.centre.toLowerCase(),
                    nameB = b.centre.toLowerCase();
                if (nameA < nameB)
                    //sort string ascending
                    return -1;
                if (nameA > nameB) return 1;
                return 0; //default return value (no sorting)
            });
    };

    return (
        <div className="pending-cheques__filter_container">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
                <span
                    onClick={() => {
                        if (filterApplied()) {
                            filterArrowToggle();
                        }
                    }}
                    className={`${!filterApplied() ? "disabled-content" : ""} filter-arrow-wrapper`}
                >
                </span>
            </div>

                <div className="filter_expanded_div">
                    <div className="cheque-form-container">
                        <div className="form-container__col-first filter">
                            <div className="cheque-status-select-container filter my-2">
                            <p className="accounts_filter_drop_header mb-1">
                                {centreRef.current&&centreRef.current.length>0&&<span className="marker" ></span>}
                                    Centre</p>

                                <div className="antd_dropdown">
                                <Select
                                    mode="multiple"
                                    autoClearSearchValue={false}
                                    style={{ width: '100%' }}
                                    placeholder="All"
                                    value={centre}
                                    onChange={value => {
                                        let index = value.indexOf("Select All");

                                        if (index >= 0) {
                                            if (centre.length === getCentreList().length) {
                                                setCentre([]);
                                            } else {
                                                setCentre(getCentreList().map(x => x.id));
                                            }
                                        } else {
                                            setCentre(value);
                                        }

                                    }}
                                    showArrow
                                    allowClear
                                    optionFilterProp="children"
                                    maxTagCount='responsive'
                                >
                                    {
                                        getCentreList() && getCentreList().map((center, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    value={center.id}
                                                >{Capitalize(center.centre)}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                </div>
                            </div>

                            <div className="my-2 padding_top1 d-flex align-items-center">
                                <button
                                    className="btn finance_manager-filter-button-cash-recon mr-2"
                                    onClick={() => {
                                        if (!canApplyFilter()) {
                                            return;
                                        }
                                        if (!filterLoading) {
                                            centreRef.current = centre;

                                            getDetails();
                                        }
                                    }}
                                    disabled={(filterLoading || !applyFilter()) ? true : false}
                                >
                                    Apply
                                </button>

                                <span className="clear-filter-cheques ml-2" onClick={handleClearFilter} style={{textDecoration: "none"}}>Clear</span>
                            </div>

                        </div>                        
                    </div>

                </div>

        </div>
    );
};

export default Filter;

