import React, { useState } from "react";
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from "react-redux";
import { HANDLE_STUDENT_SEARCH, RESET_STUDENT_SEARCH } from "../../../actions/types";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import { State, City } from 'country-state-city';
import { Select } from "antd";
import { segmentEvents } from "../../../utils/constants";

const { Option } = Select;

const Filter = ({
    close,
    tempSearch,
    allCity
}) => {

    const dispatch = useDispatch();

    const { centerList } = useSelector(state => state.auth);

    const studentSearch = useSelector(state => state.studentSearch);

    const [filterData, setFilterData] = useState({
        selectedCentre: studentSearch.selectedCentre,
        selectedCourse: studentSearch.selectedCourse,
        selectedBatch: studentSearch.selectedBatch,
        selectedState: studentSearch.selectedState,
        selectedCity: studentSearch.selectedCity,
    })

    const handleChange = payload => {
        setFilterData({ ...filterData, ...payload });
    }

    const handleChangeCentre = value => {
        handleChange({
            selectedCentre: value || "",
            selectedCourse: "",
            selectedBatch: ""
        })

        setCourseList([]);
        setBatchList([]);

        if (value) {
            getCourses(value);
        }
    }

    const handleChangeCourse = value => {
        handleChange({
            selectedCourse: value || "",
            selectedBatch: ""
        })

        setBatchList([]);

        if (value) {
            getBatches(selectedCentre, value);
        }
    }

    const handleChangeBatch = value => {
        handleChange({
            selectedBatch: value || ""
        })
    }

    const handleChangeState = value => {
        handleChange({
            selectedState: value || "",
            selectedCity: ""
        })

        if (value) {
            let index = State.getStatesOfCountry('IN').findIndex(x => x.name === value);

            if (index >= 0) {
                setCityList(City.getCitiesOfState("IN", State.getStatesOfCountry('IN')[index].isoCode));
            } else {
                setCityList([]);
            }
        } else {
            setCityList([]);
        }
    }

    const handleChangeCity = value => {
        handleChange({
            selectedCity: value || ""
        })
    }

    const applyFilter = () => {
        dispatch({
            type: HANDLE_STUDENT_SEARCH,
            payload: {
                ...filterData,
                courseList,
                batchList,
                cityList,
                skip: 0,
                continueScroll: false,
                searchText: tempSearch,
            }
        })

        close();
    }

    const clearAll = () => {
        handleChange({
            selectedCentre: "",
            selectedCourse: "",
            selectedBatch: "",
            selectedState: "",
            selectedCity: "",

            courseList: [],
            batchList: [],
            cityList: []
        })

        // dispatch({
        //     type: HANDLE_STUDENT_SEARCH,
        //     payload: {
        //         selectedCentre: "",
        //         selectedCourse: "",
        //         selectedBatch: "",
        //         selectedState: "",
        //         selectedCity: "",

        //         courseList: [],
        //         batchList: [],
        //         cityList: []
        //     }
        // })
        dispatch({ type: RESET_STUDENT_SEARCH });

        close();
    }

    const {
        selectedCentre,
        selectedCourse,
        selectedBatch,
        selectedState,
        selectedCity
    } = filterData;


    const [courseList, setCourseList] = useState(studentSearch.courseList && studentSearch.courseList.length > 0 ? studentSearch.courseList : []);
    const [batchList, setBatchList] = useState(studentSearch.batchList && studentSearch.batchList.length > 0 ? studentSearch.batchList : []);
    const [cityList, setCityList] = useState(studentSearch.cityList && studentSearch.cityList.length > 0 ? studentSearch.cityList : []);

    const getCourses = async (tempCentre = selectedCentre) => {
        const data = await Api.doFetch("GET", {}, `${ApiUrl.CLASSES}?school=${tempCentre}`);
        setCourseList(data);
    }

    const getBatches = async (tempCentre = selectedCentre, tempCourse = selectedCourse) => {
        const data = await Api.doFetch("GET", {}, `${ApiUrl.SECTION}?school=${tempCentre}&class=${tempCourse}`)
        setBatchList(data);
    }


    return (
        <Box
            sx={{ width: 340 }}
            role="presentation"
        >
            <div className="student_search_filter_wrapper antd_dropdown">
                <p className="student_search_filter_heading mb-4">
                    Filter by
                </p>

                <div style={{ marginBottom: "18px" }}>
                    <p className="student_filter_drop_header">
                        Centre
                    </p>

                    <Select
                        className="full-width"
                        value={selectedCentre || undefined}
                        placeholder="Select Centre"
                        onChange={handleChangeCentre}
                        showArrow
                        allowClear
                        showSearch
                        optionFilterProp="children"
                    >
                        {
                            centerList && centerList.map((centreData, index) => {
                                return (
                                    <Option
                                        value={centreData.school_id}
                                        key={index}
                                    >
                                        {centreData.school_name}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </div>

                <div style={{ marginBottom: "18px" }}>
                    <p className="student_filter_drop_header">
                        Course
                    </p>

                    <Select
                        className="full-width"
                        value={selectedCourse || undefined}
                        placeholder="Select Course"
                        onChange={handleChangeCourse}
                        showArrow
                        allowClear
                        showSearch
                        optionFilterProp="children"
                    >
                        {
                            courseList && courseList.map((courseData, index) => {
                                return (
                                    <Option
                                        value={courseData.id}
                                        key={index}
                                    >
                                        {courseData.class_name}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </div>

                <div style={{ marginBottom: "18px" }}>
                    <p className="student_filter_drop_header">
                        Batch
                    </p>

                    <Select
                        className="full-width"
                        value={selectedBatch || undefined}
                        placeholder="Select Batch"
                        onChange={handleChangeBatch}
                        showArrow
                        allowClear
                        showSearch
                        optionFilterProp="children"
                    >
                        {
                            batchList && batchList.map((batchData, index) => {
                                return (
                                    <Option
                                        value={batchData.id}
                                        key={index}
                                    >
                                        {batchData.section_name}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </div>

                <div style={{ marginBottom: "18px" }}>
                    <p className="student_filter_drop_header">
                        State
                    </p>

                    <Select
                        className="full-width"
                        value={selectedState || undefined}
                        placeholder="Select State"
                        onChange={handleChangeState}
                        showArrow
                        allowClear
                        showSearch
                    >
                        {
                            State.getStatesOfCountry('IN').map((stateData, index) => {
                                return (
                                    <Option
                                        value={stateData.name}
                                        key={index}
                                    >
                                        {stateData.name}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </div>

                <div style={{ marginBottom: "18px" }}>
                    <p className="student_filter_drop_header">
                        City
                    </p>

                    <Select
                        className="full-width"
                        value={selectedCity || undefined}
                        placeholder="Select City"
                        onChange={handleChangeCity}
                        showArrow
                        allowClear
                        showSearch
                    >
                        {
                            cityList && cityList.map((cityData, index) => {

                                if (allCity && allCity.length > 0) {
                                    let index = allCity.findIndex(x => x && x.trim() === cityData.name.trim());

                                    if (index >= 0) {
                                        return (
                                            <Option
                                                value={cityData.name}
                                                key={index}
                                            >
                                                {cityData.name}
                                            </Option>
                                        )
                                    } else {
                                        return null;
                                    }
                                }

                                return (
                                    <Option
                                        value={cityData.name}
                                        key={index}
                                    >
                                        {cityData.name}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-2">
                    <button
                        className="btn btn-md student_search_filter_button"
                        onClick={() => {
                            Api.trackEvent(segmentEvents.STUDENT.CLICKED_SEARCH_STUDENT_APPLY);
                            applyFilter();
                        }}
                    >
                        Apply
                    </button>

                    <span
                        className="student_search_filter_drawer_clear"
                        onClick={() => {
                            Api.trackEvent(segmentEvents.STUDENT.CLICKED_STUDENT_SEARCH_CLEAR);
                            clearAll();
                        }}
                    >
                        Clear all
                    </span>
                </div>

            </div>
        </Box>
    );
};

export default Filter;