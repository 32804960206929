import React from "react";
import { useState, useEffect } from 'react';
import {
    useParams,
    useNavigate,
    useLocation,
} from "react-router-dom";
import dateFormat from "dateformat";
import '../installmentCreate.css';
import '../installmentCreateHostel.css';
import Swal from "sweetalert2";
import moment from "moment";
import StudentCard from "../../student/detail/studentCard";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Loader from "../../common/Loader";
import AddCircle from "../../../assets/img/add_circle.svg";
import { currencyFormatter } from "../../../utils/helperFunctions";
import DeleteConfirmation from "../feeInstallment/installmentDelete";
import InstallmentConfirmation from "../feeInstallment/installmentConfirmation";
import NotFound from "../../common/notFound";
import { useMediaQuery } from "@mui/material";

import '../installmentCreate.css';
import '../installmentCreateHostel.css';
import '../feeInstallment/styles/installment-cards.css';
import { segmentEvents } from "../../../utils/constants";

const Installment_edit = (props) => {
    // let data = localStorage.getItem("userdetail");
    // data = JSON.parse(data);

    const location = useLocation();
    let navigate = useNavigate();
    const renderResponsiveCards = useMediaQuery('(max-width: 900px)');

    const { centreID, batchID, student_id } = useParams();
    // var [token, setToken] = useState(data.token);
    const [showErrmsg, set_showErrmsg] = useState("hidden");
    const [err_msg, set_err_msg] = useState("");
    const [err_msg_color, set_err_msg_color] = useState('#35CB00');
    const [isDisabled, set_isDisabled] = useState(true);
    const [total_amount, set_total_amount] = useState(0);
    const [installments_amount, set_installments_amount] = useState(240000);
    const [current_row_index, set_current_row_index] = useState(null);
    const [current_row_data, set_current_row_data] = useState(null);
    const [studentDetails, set_studentDetails] = useState(null);
    const [instalments, set_installments] = useState(location && location.state && location.state.installment_details && location.state.installment_details.length > 0 ? location.state.installment_details : []);
    // const [installment_no, set_installment_no] = useState(location && location.state && location.state.installments_count);
    // const [discount, set_discount] = useState(location && location.state && location.state.discount);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const [centre, setCentre] = useState("");
    const [course, setCourse] = useState("");
    const [batch, setBatch] = useState("");

    const [loading, setLoading] = useState(false);

    const [notFound, setNotFound] = useState(false);

    const getStudentDetails = async () => {
        setLoading(true);

        const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_DETAILS}?student_id=${student_id}`);

        setLoading(false);
        if (data && !data.message) {
            set_studentDetails(data);
        } else if (data.message && data.message === "Student not found") {
            setNotFound(true);
        }
    }

    const getFeeDetails = async () => {

        const data = await Api.doFetch("GET", {}, `${ApiUrl.COURSE_DETAIL}?school_id=${centreID}&section_id=${batchID}`);

        if (data && data.status) {

            if (data.payload.length > 0) {

                setBatch(data.payload[0].section_assoc && data.payload[0].section_assoc.section_name);
                setCourse(data.payload[0].section_assoc && data.payload[0].section_assoc.class_name);
                setCentre(data.payload[0].school_name);
            }

        } else if (data && ((data.payload && data.payload.Error) || data.message)) {
            if (data.message === "school not found" || data.message === "section not found") {
                setNotFound(true);
            } else if (data.payload && data.payload.Error) {
                Swal.fire("Error", data.payload.Error, "error");
            } else if (data.message) {
                Swal.fire("Error", data.message, "error");
            }
        }

    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const [installmentLoading, setInstallmentLoading] = useState(false);

    const confirmInstallment = async () => {
        for (let i = 0; i < instalments.length; i++) {
            if (instalments[i].amount_per_installment === 0 || instalments[i].amount_per_installment === "") {
                Swal.fire("Installment amount can't be 0. Please enter valid amount");
                return false;
            }
            else if (instalments[i].start_date === "") {
                Swal.fire("Please enter valid date");
                return false;
            }
            else if (i > 0 && moment(instalments[i].start_date).isBefore(instalments[i - 1].start_date)) {
                Swal.fire("Installment date should be in chronological order");
                return false;
            }
            else if (i > 0 && moment(instalments[i].start_date).isSame(instalments[i - 1].start_date)) {
                Swal.fire("Two installments can't be of same date");
                return false;
            }
        }

        let installmentUpdate = [];
        for (let i = 0; i < instalments.length; i++) {
            let instalmentOnj = {
                amount_per_installment: Number(instalments[i].amount_per_installment),
                start_date: formatDate(instalments[i].start_date),
                end_date: formatDate(instalments[i].end_date),
                installment_no: instalments[i].installment_no
            }
            installmentUpdate.push(instalmentOnj);
        }

        let postData = { 
            installment_details: installmentUpdate,
            school_id: centreID
        }

        setInstallmentLoading(true);

        const data = await Api.doFetch("PUT", postData, `${ApiUrl.EDIT_HOSTEL_INSTALLMENT_PLAN}${studentDetails.student_data.id}/`);

        setInstallmentLoading(false);

        if (data && data.status) {

            Api.trackEvent(segmentEvents.STUDENT.CONFIRMED_HOSTEL_INSTALLMENTS);
            navigate(`/payment/fee-summary/${centreID}/${batchID}/${studentDetails.student_data.id}`)

        } else if (data && ((data.payload && data.payload.Error) || data.message)) {
            if (data.payload && data.payload.Error) {
                Swal.fire("Error", data.payload.Error, "error");
            } else if (data.message) {
                Swal.fire("Error", data.message, "error");
            }
        }

    }

    const getInstallments = () => {
        let installmentAmount = instalments.reduce((acc, array) => Number(acc) + Number(array.amount_per_installment), 0);
        set_installments_amount(installmentAmount);
        set_total_amount(installmentAmount);
    };

    const matchBalance = (installmentAmount) => {
        if (parseInt(total_amount) > parseInt(installmentAmount)) {
            set_err_msg(`Sum of installments is less than total payable amount ${currencyFormatter(total_amount)}`);
            set_showErrmsg("inline");
            set_err_msg_color('#FF0000');
            //set_isDisabled(true);
        }
        else if (parseInt(total_amount) < parseInt(installmentAmount)) {
            set_err_msg(`Sum of installments is more than total payable amount ${currencyFormatter(total_amount)}`);
            set_showErrmsg("inline");
            set_err_msg_color('#FF0000');
            //set_isDisabled(true);
        } else {
            set_err_msg_color('#35CB00');
            set_showErrmsg("hidden");
            //set_isDisabled(false);
        }
    }
    const setInputDate = (event, i) => {
        const tempRows = [...instalments];
        tempRows[i].start_date = event.target.value;
        set_installments(tempRows)
    }

    const updateInputValue = (event, i) => {

        event.target.value = event.target.value.replace(/[^0-9]/g, "");

        const tempRows = [...instalments];
        tempRows[i].amount_per_installment = event.target.value;
        let installmentAmount = tempRows.reduce((acc, array) => Number(acc) + Number(array.amount_per_installment), 0);

        setIsConfirmed(false);
        set_isDisabled(true);
        matchBalance(installmentAmount);
        set_installments_amount(installmentAmount);
        set_installments(tempRows)
    }

    const addRow = () => {
        let maxObj = instalments.reduce((max, obj) => (max.installment_no > obj.installment_no) ? max : obj);
        let newMaxObj = JSON.parse(JSON.stringify(maxObj));

        let newDate = new Date(newMaxObj.start_date);
        let newEndDate = new Date(newMaxObj.end_date);
        let installmentData = {
            installment_no: newMaxObj.installment_no + 1,
            start_date: new Date(newDate.setMonth(newDate.getMonth() + 1)),
            end_date: new Date(newDate.setMonth(newEndDate.getMonth() + 1)),
            amount_per_installment: 0
        };
        let new_installments = [...instalments, installmentData];
        let installmentAmount = new_installments.reduce((acc, array) => Number(acc) + Number(array.amount_per_installment), 0);
        matchBalance(installmentAmount);
        set_installments_amount(installmentAmount);
        set_installments(new_installments);
        //matchBalance();
    }

    const addRowDel = (item, i) => {
        set_current_row_index(i);
        set_current_row_data(item);
    }

    const confirmDeleteRow = () => {
        const tempRows = [...instalments];
        tempRows.splice(current_row_index, 1);
        let installmentAmount = tempRows.reduce((acc, array) => Number(acc) + Number(array.amount_per_installment), 0);
        let tempRowsNew = [];
        for (let i = 0; i < tempRows.length; i++) {
            tempRowsNew.push({
                amount_per_installment: tempRows[i].amount_per_installment,
                end_date: tempRows[i].end_date,
                installment_no: i + 1,
                start_date: tempRows[i].start_date,
            });

        }
        matchBalance(installmentAmount);
        set_installments_amount(installmentAmount);
        //set_installments(tempRows);
        set_installments(tempRowsNew);
        setIsConfirmed(false);
    }


    const handleConfirm = (event) => {
        setIsConfirmed(event.target.checked);
        if (event.target.checked === true) {
            if (parseInt(total_amount) > parseInt(installments_amount)) {
                set_isDisabled(true);
            }
            else if (parseInt(total_amount) < parseInt(installments_amount)) {
                set_isDisabled(true);
            } else {
                set_isDisabled(false);
            }
        } else {
            set_isDisabled(true);
        }
    };

    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-')
    }

    function numberToCardinal(num) {
        var ones = num % 10;
        var tens = num % 100;
        if (tens < 11 || tens > 13) {
            switch (ones) {
                case 1:
                    return num + "st";
                case 2:
                    return num + "nd";
                case 3:
                    return num + "rd";
                default:
                    break;
            }
        }
        return num + "th";
    }
    
    useEffect(() => {

        if (location && location.state && location.state.installments_count) {
            getStudentDetails();
            getFeeDetails();
        } else {
            navigate(`/student/detail/${student_id}`);
        }

        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getInstallments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentDetails]);

    if (notFound) {
        return <NotFound />
    }

    return (
        <div>

            {/* Modal for delete installment */}
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <DeleteConfirmation
                        numberToCardinal = {numberToCardinal}
                        current_row_data = {current_row_data}
                        confirmDeleteRow = {confirmDeleteRow}
                        convertDate = {convertDate}
                    />
                </div>
            </div>


            {/* Modal for confirm installment */}
            <div
                className="modal fade installmentPlanWrap"
                id="exampleModal1"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <InstallmentConfirmation
                        instalments = {instalments}
                        numberToCardinal = {numberToCardinal}
                        installments_amount = {installments_amount}
                        isDisabled = {isDisabled}
                        confirmInstallment = {confirmInstallment}
                        installmentLoading = {installmentLoading}
                        convertDate = {convertDate}
                    />
                </div>
            </div>

            <div className="containerMain">
                {
                    loading ?
                    <div className="loader_container">
                        <Loader />
                    </div>
                    :
                    <>
                        <StudentCard
                            style={{
                                marginTop: "0rem"
                            }}
                            studentData={studentDetails}
                            goback={() => {
                                navigate(`/payment/create-hostel-installment/${centreID}/${batchID}/${student_id}`)
                            }}
                            optionsNeeded={false}
                        />

                        <div className="payment_installment_container">
                            <div className='row mb-4'>
                              <div className="col-md-10 payment_installment_container-col d-flex">
                                <div className="student_course_detail_centre_flex">
                                  <span className="student_course_detail_header">Centre:</span>
                                  <p className="student_course_detail_value">{centre}</p>
                                </div>
                                <div className="student_course_detail_others_flex">
                                  <span className="student_course_detail_header">Course:</span>
                                  <p className="student_course_detail_value">{course}</p>
                                </div>
                                <div className="student_course_detail_others_flex">
                                  <span className="student_course_detail_header">Batch:</span>
                                  <p className="student_course_detail_value">{batch}</p>
                                </div>
                              </div>
                            </div>

                            <div>
                              <div className={`installment_detail_meta_container edit-installments${renderResponsiveCards ? " responsive" : ""}`}>
                                    <div className="installment_detail-count">
                                        <p className="installment_meta_header">
                                            No. of Installments:
                                        </p>
                                        <p className="installment_meta_sub_header">
                                            {instalments?.length}
                                        </p>
                                    </div>
                                    <div className="installment_detail-payable">
                                        <p className="installment_meta_header">
                                            Total Payable Fee:
                                        </p>
                                        <p className="installment_meta_sub_header">
                                            &#8377; {currencyFormatter(total_amount, false)}
                                        </p>
                                    </div>
                                    <div className="installment_detail-sum">
                                        <p className="installment_meta_header">
                                            Sum of Installments:
                                        </p>
                                        <p
                                            className="installment_meta_sub_header_amount"
                                            style={{
                                                "--installment_total_amount": err_msg_color
                                            }}
                                        >
                                            &#8377; {currencyFormatter(installments_amount, false)}
                                        </p>
                                    </div>
                                </div>
                                <div className="installment_amount_alert">
                                    <i className={`fa fa-exclamation-circle ${showErrmsg}`} style={{ color: "#ff0000" }}></i>
                                    <p className={`installment_amount_alert_text ${showErrmsg}`}>
                                        {err_msg}
                                    </p>
                                </div>
                                {
                                  renderResponsiveCards ? <div className="installment-list-cards">
                                  {instalments &&
                                      instalments.map((installment, installmentIdx) => {
                                          return (
                                              <div
                                                  className="installment-card"
                                                  key={"installment-" + installmentIdx}
                                              >
                                                  <div className="installment-name">
                                                      {`${numberToCardinal(installment.installment_no)} Installment`}
                                                  </div>
                                                  <div className="installment-delete">
                                                      <button data-toggle="modal"
                                                          data-target="#exampleModal"
                                                          onClick={() => addRowDel(installment, installmentIdx)}
                                                      >
                                                          <i className="fa fa-times" aria-hidden="true" />
                                                      </button>
                                                  </div>
                                                  <div className="installment-options">
                                                      <div className="installment-date">
                                                          <label htmlFor={`installment-date-${installmentIdx}`}>Date</label>
                                                          <input
                                                              id={`installment-date-${installmentIdx}`}
                                                              type="date"
                                                              onKeyDown={(e) => e.preventDefault()}
                                                              value={dateFormat(installment.start_date, "yyyy-mm-dd")}
                                                              onChange={(event) => setInputDate(event, installmentIdx)}
                                                          />
                                                      </div>
                                                      <div className="installment-amount">
                                                          <label htmlFor={`installment-amount-${installmentIdx}`}>Installment Amount</label>
                                                          <p className="currencyWrap">
                                                              <span>&#8377;</span>
                                                              <input
                                                                  id={`installment-amount-${installmentIdx}`}
                                                                  className="amt"
                                                                  type="currency"
                                                                  value={installment.amount_per_installment}
                                                                  onChange={(event) => updateInputValue(event, installmentIdx)}
                                                              />
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          );
                                      })}
                              </div> :
                                <div className="installmentList">
                                    <table rules="rows" className="table">
                                        <thead>
                                            <tr>
                                                <th className="installment_details_table_header">Installment No.</th>
                                                <th className="installment_details_table_header">Due Date</th>
                                                <th className="installment_details_table_header">Installment Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                instalments && instalments.map((installment, i) => {
                                                    return (
                                                        <tr key={`install${i}`}>
                                                            <td
                                                                className="installment_details_table_data_text"
                                                            >
                                                                {`${numberToCardinal(installment.installment_no)} Installment`}
                                                            </td>
                                                            <td className="installment_details_table_data_text">
                                                                <input
                                                                    type="date"
                                                                    onKeyDown={(e) => e.preventDefault()}
                                                                    value={dateFormat(installment.start_date, "yyyy-mm-dd")}
                                                                    onChange={(event) => setInputDate(event, i)}
                                                                ></input>
                                                            </td>
                                                            <td className="installment_details_table_data_text_amount">
                                                                <p className="currencyWrap">
                                                                    <span>&#8377;</span>
                                                                    <input
                                                                        className="amt"
                                                                        type="currency"
                                                                        value={installment.amount_per_installment}
                                                                        onChange={(event) => updateInputValue(event, i)}
                                                                    ></input>
                                                                </p>
                                                                <button className="addbtn2" data-toggle="modal"
                                                                    data-target="#exampleModal"
                                                                    onClick={() => addRowDel(installment, i)}
                                                                >
                                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                              }
                                    <div
                                        className="add_student_container add_installment_container"
                                        onClick={addRow}
                                    >
                                        <div className="add_student_text_container">
                                            <img
                                                src={AddCircle}
                                                alt="add"
                                                className="add_student_img"
                                            />

                                            <span className="add_student_container_text">
                                                Add Installment
                                            </span>
                                        </div>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" checked={isConfirmed} id="defaultCheck1" onChange={(event) => handleConfirm(event)} />
                                        <label className="form-check-label" htmlFor="defaultCheck1" style={{ fontWeight: '500', marginLeft: '5px', position: "relative", top: "-0.15rem" }}>
                                            Once confirmed you can’t edit number of installments later
                                        </label>
                                    </div>
                                    <div className="sapceBtn">
                                        <button
                                            className="btn btn-primary btn-lg"
                                            data-toggle="modal"
                                            data-target="#exampleModal1"
                                            style={{
                                                backgroundColor: "#012C63",
                                                marginTop: "2%",
                                                fontWeight: "600",
                                                fontStyle: "normal",
                                            }}
                                            disabled={isDisabled}
                                        >
                                            Confirm Installment
                                        </button>
                                    </div>
                            </div>
                        </div>
                    </>
                }
            </div>

        </div>
    )
}

export default Installment_edit;