import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import ErrorText from "../../common/errorText";
import api from "../../common/api";
import apiUrl from "../../common/apiUrl";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import excelLogo from "../../../assets/img/excel.svg";
import downloadIcon from "../../../assets/img/downloadIcon.svg";

const ToastMessage = ({ variant = "success", message = "" }) => {
	const color =
		{
			success: "#35CB00",
			error: "#FF3300",
		}[variant] || "#35CB00";

	return (
		<div
			className="reversal_toast"
			style={{ display: "flex", alignItems: "center", "--toast-status": color }}
		>
			{{
				success: <CheckCircleIcon sx={{ fill: "#fff", mr: 1 }} />,
				error: <CancelIcon sx={{ fill: "#fff", mr: 1 }} />,
			}[variant] || null}
			{message}
		</div>
	);
};

export default function FinanceBulkBankDeposit() {
	const navigate = useNavigate();

	const [depositAmount, setDepositAmount] = useState("");
	const [depositFile, setDepositFile] = useState(null);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [fileError, setFileError] = useState("");

	const [loading, setLoading] = useState(false);

	const handleFileDrop = useCallback((acceptedFiles, rejectedFiles) => {
		if (acceptedFiles.length === 1 && rejectedFiles.length === 0) {
			// Accepted file
			setFileError("");
			setDepositFile(acceptedFiles[0]);
			setUploadedFile(true);
		} else {
			if (
				rejectedFiles &&
				rejectedFiles.length > 0 &&
				rejectedFiles[0].errors &&
				rejectedFiles[0].errors.length > 0 &&
				rejectedFiles[0].errors[0].code === "file-too-large"
			) {
				setFileError(
					<span>
						The image size is larger than <strong>5 MB</strong>. Please reduce
						the image size.
					</span>
				);
			} else {
				setFileError(
					<span>
						Please upload a {/* <strong>.xls</strong> or{" "} */}
						<strong>.xlsx</strong> file
					</span>
				);
			}
			setTimeout(() => {
				setFileError(null);
			}, 2500);
			setDepositFile(null);
			setUploadedFile(null);
		}
	}, []);

	const { getInputProps, getRootProps } = useDropzone({
		multiple: false,
		accept: {
			// "application/vnd.ms-excel": [".xls"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
				".xlsx",
			],
		},
		onDrop: handleFileDrop,
	});

	const canSave = () => {
		if (depositFile && +depositAmount > 0) {
			return true;
		}

		return false;
	};

	const handleChangeDepositAmount = (e) => {
		const value = e.target.value;

		if (!value) {
			setDepositAmount("");
		} else if (/^[0-9]+$/.test(value)) {
			setDepositAmount(value);
		}
	};

	const handleDownloadExcelTemplate = () => {
		const workbook = new ExcelJS.Workbook();

		const sheet = workbook.addWorksheet("Payment details");

		sheet.columns = [
			{ header: "Student ID" },
			{ header: "Batch ID" },
			{ header: "Centre ID" },
			{ header: "Amount" },
			{ header: "Partner name" },
			{ header: "UTR Code" },
			{ header: "Transfer Date (DD/MM/YYYY)" },
		];

		workbook.xlsx.writeBuffer().then(function (buffer) {
			const blob = new Blob([buffer], { type: "application/xlsx" });

			saveAs(blob, "Bulk Bank Deposit.xlsx");
		});
	};

	const clearForm = () => {
		setDepositAmount("");
		setDepositFile(null);
		setUploadedFile(null);
	};

	const handleCancel = () => {
		if (depositAmount !== "" || depositFile) {
			clearForm();
		} else {
			navigate("/pending-deposit");
		}
	};

	const handleSubmit = async () => {
		try {
			if (loading) return;

			if (!depositAmount || +depositAmount <= 0 || !depositFile) {
				// TODO: Error handling
				return;
			}

			setLoading(true);

			const formData = new FormData();

			formData.append("deposit_amount", depositAmount);
			formData.append("file", depositFile);

			const bulkBankDepositRes = await api.doUpload(
				"POST",
				formData,
				apiUrl.BULK_BANK_DEPOSIT
			);

			if (bulkBankDepositRes?.status === true) {
				toast(
					<ToastMessage
						variant="success"
						message={bulkBankDepositRes.message}
					/>
				);

				clearForm();
			} else {
				if (bulkBankDepositRes?.data) {
					const { row, message } = bulkBankDepositRes.data;
					if (row) {
						Swal.fire(`Error at Row #${row}`, message, "error");
					} else {
						Swal.fire("Error", message, "error");
					}
				} else {
					if (!bulkBankDepositRes) {
						setFileError(null);
						setDepositFile(null);
						setUploadedFile(null);

						Swal.fire("Error", "Please re-upload the XLSX file and try again", "error");
					} else {
						Swal.fire(
							"Error",
							bulkBankDepositRes.message || "Some error occurred",
							"error"
						);
					}
				}
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="bulk_deposit_container">
			<div className="bulk_deposit_header justify-content-between">
				<div className="d-flex align-items-center">
					<img
						src="/static/media/left_arrow.35f3374f21e36b005407bc18a0c6e235.svg"
						alt="go back"
						className="cursor-pointer"
						onClick={() => navigate("/pending-deposit")}
					/>
					<span className="bulk_deposit_header_text ml-2">
						Bulk bank deposits
					</span>
				</div>
			</div>
			<div className="bulk_deposit_card">
				<p className="bulk_deposit_label">Download Template</p>
				<button
					className="download_template_button"
					onClick={handleDownloadExcelTemplate}
				>
					Template
					<svg style={{ marginLeft: 6 }} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
						<path d="M0 17.5H18V19.5H0V17.5ZM10 11.672L16.071 5.6L17.485 7.014L9 15.5L0.515 7.015L1.929 5.6L8 11.67V0.5H10V11.672Z" fill="black" />
					</svg>
				</button>
			</div>
			<div className="bulk_deposit_card">
				<p className="bulk_deposit_label">
					Deposit Amount <span>*</span>
				</p>
				<div className="input-group  discountWrpField" style={{ maxWidth: 384 }}>
					<div className="input-group-addon" style={{background: "transparent", border: "none", fontWeight: 500 }}>₹</div>
						<input
						className="form-control discountWrp px-0"
						placeholder="Enter amount"
						value={depositAmount}
						onChange={handleChangeDepositAmount}
					/>
				</div>
				<p className="bulk_deposit_label" style={{ marginTop: 14 }}>
					Upload file <span>*</span>
				</p>
				{uploadedFile ? (
					<div className="update_hub_logo">
						{depositFile ? (
							<div className="bulk_deposit_file_div">
								<img
									className="bulk_deposit_file"
									src={excelLogo}
									alt="uploaded_excel"
								/>
								<span className="bulk_deposit_file_name">
									{depositFile.name}
								</span>
							</div>
						) : null}
						<div className="bulk_deposit_edit_text" {...getRootProps()}>
							Edit
						</div>
						<input className="fileup" {...getInputProps()} />
					</div>
				) : (
					<div className="filesBox add_hub" {...getRootProps()}>
						<div className="inputFile"></div>
						<input className="fileup" {...getInputProps()} />

						{depositFile ? (
							depositFile.name
						) : (
							<>
								<div className="fileUploadHelperText hub">
									Drag {"&"} drop or click to choose a file
								</div>
								<div style={{ marginTop: 14 }}>
									Please upload a {/* <strong>.xls</strong> or{" "} */}
									<strong>.xlsx</strong> file
								</div>
							</>
						)}
					</div>
				)}

				{fileError && (
					<div>
						<ErrorText text={fileError} />
					</div>
				)}
			</div>

			<div className="bulk_deposit_buttons">
				<button
					disabled={loading}
					onClick={handleCancel}
					className="btn btn-md course_add_back"
					type="button"
				>
					Cancel
				</button>

				<button
					disabled={!canSave() || loading}
					className={"btn btn-md course_add_next"}
					type="button"
					onClick={handleSubmit}
				>
					Submit
					{loading && <span className="ml-2 spinner spinner-white mr-2"></span>}
				</button>
			</div>
		</div>
	);
}
