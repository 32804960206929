import { createTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { currencyFormatter } from "../../../utils/helperFunctions";
import dateFormat from "dateformat";
import { styled, ThemeProvider } from "@mui/system";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const tooltipTheme = createTheme({
    typography: {
        htmlFontSize: 10,
    }
});

const BootstrapTooltip = styled(({ className, hasError, ...props }) => (
    <Tooltip
        {...props}
        arrow
        classes={{ popper: `${ className } tooltip_header` }}
    />
))(({ theme, hasError }) => {
    return {
        [`& .${ tooltipClasses.arrow }`]: {
            color: hasError ? theme.palette.error.main : theme.palette.success.light,
        },
        [`& .${ tooltipClasses.tooltip }`]: {
            backgroundColor: hasError ? theme.palette.error.main : theme.palette.success.light,
            fontSize: "13px"
        },
    }
});

const SummaryTable = ({
    summaryPlans
}) => {
    const renderResponsiveCards = useMediaQuery('(max-width: 900px)');

    function numberToCardinal(num) {
        var ones = num % 10;
        var tens = num % 100;
        if (tens < 11 || tens > 13) {
            switch (ones) {
                case 1:
                    return num + "st";
                case 2:
                    return num + "nd";
                case 3:
                    return num + "rd";
                default:
                    break;
            }
        }
        return num + "th";
    }

    function convertDate(inputFormat) {
        function pad(s) {
            return s < 10 ? "0" + s : s;
        }
        var d = new Date(inputFormat);
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
    }

    return (
        <ThemeProvider theme={tooltipTheme}>
            {renderResponsiveCards ? (<div className="installment-list-cards mb-4">
                {summaryPlans &&
                    summaryPlans.map((installment, installmentIdx) => {
                        const overDue = new Date() >= new Date(installment.installment_date);

                        return (
                            <div className="installment-card" key={"installment-" + installmentIdx}>
                                <div className="installment-name with-due">
                                    {(installment.status === 'Paid' || ((installment.status === "Unpaid" || installment.status === "Partial") && (overDue))) && (
                                        <div className="installment-status">
                                            {installment.status === "Paid" && (
                                                <BootstrapTooltip title="Completed" arrow placement="top" disableFocusListener enterTouchDelay={0}>
                                                    <i className="fa fa-check-double" style={{ color: "#2CAA00" }} />
                                                </BootstrapTooltip>
                                            )}

                                            {(installment.status === "Unpaid" || installment.status === "Partial") && (overDue) && (
                                                <BootstrapTooltip title="Missed Due Date" arrow hasError placement="top" disableFocusListener enterTouchDelay={0}>
                                                    <i className="fal fa-exclamation-circle" style={{ color: "#E00000", fontWeight: "500" }} />
                                                </BootstrapTooltip>
                                            )}
                                        </div>
                                    )}
                                    <div className="installment-name-text">{`${numberToCardinal(installmentIdx + 1)} Installment`}</div>
                                    <div className="installment-date">
                                        <div className="installment-label">Due date</div>
                                        <div className="installment-text">{dateFormat(installment.installment_date, "yyyy-mm-dd")}</div>
                                    </div>
                                </div>
                                <div className="installment-options with-due">
                                    <div className="installment-amount">
                                        <div className="installment-label">Inst. Amount</div>
                                        <div className="installment-text">&#8377; {installment.amount_payable}</div>
                                    </div>
                                    <div className="installment-due text-right">
                                        <div className="installment-label text-right">Amount due</div>
                                        <div className="installment-text">&#8377; {installment.reaming_amount}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>) : (<div className="table-responsive">
            <table rules="rows" className="table">
                <thead>
                    <tr>
                        <th className="installment_details_table_header">Installment No.</th>
                        <th className="installment_details_table_header">Due Date</th>
                        <th className="installment_details_table_header">Installment Amount</th>
                        <th className="installment_details_table_header">Amount Due</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        summaryPlans.map((installment, i) => {

                            var g1 = new Date();
                            var g2 = new Date(installment.installment_date);

                            return (
                                <tr key={`rowinsta${i}`}>
                                    <td className="fee_summary_table_data_text">
                                        {installment.status === "Paid" && (
                                            <BootstrapTooltip title="Completed" arrow>
                                                <i className="fa fa-check-double mr-2" style={{ color: "#2CAA00" }} />
                                            </BootstrapTooltip>
                                        )}

                                        {(installment.status === "Unpaid" || installment.status === "Partial") && (g2 < g1) && (
                                            <BootstrapTooltip title="Missed Due Date" arrow hasError>
                                                <i className="fal fa-exclamation-circle mr-2" style={{ color: "#E00000", fontWeight: "500" }} />
                                            </BootstrapTooltip>
                                        )}

                                        {`${numberToCardinal(i + 1)} Installment`}
                                    </td>
                                    <td className="fee_summary_table_data_text">
                                        {convertDate(
                                            installment.installment_date
                                        )}
                                    </td>
                                    <td className="fee_summary_table_data_text">
                                        {
                                            currencyFormatter(installment.amount_payable)
                                        }
                                    </td>
                                    <td className="fee_summary_table_data_text">
                                        {
                                            currencyFormatter(installment.reaming_amount)
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>)}
        </ThemeProvider>
    )
};

export default SummaryTable;