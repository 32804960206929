import React from "react";
import TickWhite from "../../assets/img/tick_white.svg";
import CloseWhite from "../../assets/img/close_white.svg";

const ToastMessage = ({ text, type = "success"  }) => {
    return (
        <div
            className="reversal_toast"
            style={{
                "--toast-status": type === "success" ? "#35CB00" : "#C80000"
            }}
        >
            <img
                src={type === "success" ? TickWhite : CloseWhite}
                alt={type === "success" ? "success" : "declined"}
                className="mr-2"
                style={{ width: "1.2rem" }}
            />
            {text}
        </div>
    );
};

export default ToastMessage;