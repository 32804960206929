import React from "react";
import { Asterisk } from "../../../utils/helperFunctions";
import StudentIcon from "../../../assets/img/student_icon.svg";
import DropdownImg from "../../../assets/img/drop_down.svg";
import ErrorText, { InfoText } from "../../common/errorText";
import { State, City } from "country-state-city";
import IndianFlag from "../../../assets/img/indian_flag.svg";
import { Select } from "antd";

const states = State.getStatesOfCountry("IN");
const classOptions = ["V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XII Pass"];

const { Option } = Select;

const LeadForm = ({ data, handleLeadData, leadError, setCityList, cityList, centreList, courseList, edit }) => {
	const handleChangeState = (value) => {
		if (value) {
			let index = states.findIndex((x) => x.name === value);
			if (index >= 0) {
				setCityList(City.getCitiesOfState("IN", states[index].isoCode));
			} else {
				setCityList([]);
			}
		} else {
			setCityList([]);
		}
	};

	const avaialableStandards = courseList?.map((data) => data.course_starting_standard) ?? [];

	return (
		<div className="add_lead_header align-items-center">
			<div className="student_details_form">
				<div>
					<p className="student_filter_drop_header student_text_bottom">
						Student Mobile Number <Asterisk />
					</p>

					<img src={IndianFlag} alt="indian" className="student_mobile_indian_img" />
					<img src={DropdownImg} alt="dropdown" className="student_mobile_drop_img" />
					<div className="d-flex">
						<input className={`change_pw_input student_add_mobile_indian`} value={"+91"} disabled />

						<input
							className={`change_pw_input student_add_mobile_input ${false ? "change_pw_input_error" : ""}`}
							placeholder="Enter number"
							value={data.mobile}
							onChange={(e) => {
								if (e.target.value.length <= 10) {
									handleLeadData({ mobile: e.target.value.replace(/[^0-9]/g, "") });
								}
							}}
							disabled={edit}
						/>
					</div>
					{leadError && leadError.mobile && <ErrorText text={leadError.mobile} />}
				</div>

				<div className="mt-3">
					<p className="student_filter_drop_header student_text_bottom">
						Student Name <Asterisk />
					</p>

					<img src={StudentIcon} alt="student" className="add_student_student_icon" />

					<input
						className={`change_pw_input add_student_student_icon_input ${false ? "change_pw_input_error" : ""}`}
						placeholder="Enter name"
						value={data.name}
						onChange={(e) => {
							handleLeadData({ name: e.target.value });
						}}
					/>
					{leadError && leadError.name && <ErrorText text={leadError.name} />}
				</div>

				<div className="mt-3 antd_dropdown">
					<p className="student_filter_drop_header">
						State <Asterisk />
					</p>

					<Select
						className="full-width"
						value={data.state || undefined}
						placeholder="Select state"
						onChange={(event) => {
							handleChangeState(event);
							handleLeadData({ state: event, city: null });
						}}
						showArrow
						allowClear
						showSearch
					>
						{State.getStatesOfCountry("IN").map((stateData, index) => {
							return (
								<Option value={stateData.name} key={index}>
									{stateData.name}
								</Option>
							);
						})}
					</Select>
					{leadError && leadError.state && <ErrorText text={leadError.state} />}
				</div>

				<div className="mt-3 antd_dropdown">
					<p className="student_filter_drop_header">
						City <Asterisk />
					</p>

					<Select
						className="full-width"
						value={data.city || undefined}
						placeholder="Select city"
						onChange={(event) => {
							handleLeadData({ city: event });
						}}
						showArrow
						allowClear
						showSearch
					>
						{cityList &&
							cityList.map((cityData, index) => {
								return (
									<Option value={cityData.name} key={index}>
										{cityData.name}
									</Option>
								);
							})}
					</Select>
					{leadError && leadError.city && <ErrorText text={leadError.city} />}
				</div>

				<div className="mt-3 antd_dropdown">
					<p className="student_filter_drop_header">
						Select centre <Asterisk />
					</p>

					<Select
						className="full-width"
						value={data.centre}
						placeholder="Select centre"
						onChange={(event) => handleLeadData({ centre: event, course_starting: null, course: null })}
						showArrow
						allowClear
						showSearch
						optionFilterProp="children"
					>
						{centreList &&
							centreList.map((centre, index) => {
								return (
									<Option value={centre.school_id} key={index}>
										{centre.school_name}
									</Option>
								);
							})}
					</Select>
					{leadError && leadError.centre && <ErrorText text={leadError.centre} />}
				</div>

				<div className="mt-3 antd_dropdown">
					<p className="student_filter_drop_header">
						Class standard (course starting) <Asterisk />
					</p>

					<Select
						className="full-width"
						value={data.course_starting}
						placeholder="Select standard"
						onChange={(value) => handleLeadData({ course_starting: value || null, course: null })}
						showArrow
						allowClear
						showSearch
						aria-autocomplete="none"
					>
						{data.centre &&
							classOptions
								.filter((opt) => avaialableStandards.find((stand) => stand === opt))
								.map((options, index) => {
									return (
										<Option key={index} value={options}>
											{options}
										</Option>
									);
								})}
					</Select>
					{leadError && leadError.course_starting && <ErrorText text={leadError.course_starting} />}
				</div>

				<div className="mt-3 antd_dropdown">
					<p className="student_filter_drop_header">
						Course name <Asterisk />
					</p>

					<Select
						className="full-width"
						value={data.course}
						placeholder="Select course"
						onChange={(event) => handleLeadData({ course: event })}
						showArrow
						allowClear
						showSearch
						optionFilterProp="children"
					>
						{courseList &&
							data.course_starting &&
							courseList
								.filter((course) => course.course_starting_standard === data.course_starting)
								.map((course, index) => {
									return (
										<Option value={course.id} key={index}>
											{course.class_name}
										</Option>
									);
								})}
					</Select>
					{leadError && leadError.course && <ErrorText text={leadError.course} />}
					{!leadError.course && (
						<InfoText
							status={`${data.course ? "success" : "normal"}`}
							text={"Details can be changed during enrollment except mobile no."}
							fontSize="10.5px"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default LeadForm;
