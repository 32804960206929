import React from 'react'
import AddBatchImg from "../../assets/img/add_batch.svg";
import ListImage from "../../assets/img/list-att-icon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { SCRL_RESET_LIST } from "../../actions/types";

const Batch = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    return (

        <div className="containerMain">

            <div className="payment_reversal_container">

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/batch/add");
                    }}
                >

                    <img
                        src={AddBatchImg}
                        alt="add course"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Add batch
                    </span>

                </div>

                <div
                    className="payment_reversal_container_history"
                    onClick={() => {
                        dispatch({ type: SCRL_RESET_LIST })
                        navigate("/batch/list");
                    }}
                >

                    <img
                        src={ListImage}
                        alt="subject list"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        List of batches
                    </span>

                </div>

            </div>

        </div>

    )
}

export default Batch;