/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import AddCircle from "../../../assets/img/add_circle.svg";
import SearchImg from "../../../assets/img/search.svg";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import swal from "sweetalert2";
import Loader from "../../common/Loader";
import Empty from "../../../assets/img/empty_student.svg";
import { useScrollingList } from "../../../utils/hooks/use-scrolling-list";

const SubjectList = () => {
    const getData = useCallback(async ({ skip = 0, search = "", limit = 10 }) => {
        try {
            let url = `${ApiUrl.SUBJECT}?skip=${skip}&limit=${limit}`;

            if (search) {
                url += `&search=${search}`;
            }

            const data = await Api.doFetch("GET", {}, url);

            if (data?.status === true) {
                const payload = data.data;

                if (payload) {
                    const { data: items, total, skip, limit } = payload;

                    return {
                        items,
                        total,
                        skip,
                        limit,
                    };
                }
            } else {
                if (data && data.message) {
                    swal.fire("Error!", data.message, "error");
                } else {
                    swal.fire("Error!", "Internal Server Error", "error");
                }
            }

            setNoData(true);

            return null;
        } catch (error) {
            console.log(error);
        }
    }, []);

    const [scrollContainer, { handleScroll, handleSearch, searchText, scrollLoading, loading, list: subjectList, scrollPositionY, saveScrollPosition }] =
        useScrollingList({
            getData,
            listKey: "subjects",
        });

    const navigate = useNavigate();

    const [noData, setNoData] = useState(false);

    const handleSearchText = (event) => {
        const value = event.target.value;

        handleSearch(value);
    }

    const handleOnEdit = (subjectID) => () => {
        if (scrollContainer.current) {
            const scrollTopPos = scrollContainer.current.scrollTop;
            saveScrollPosition(scrollTopPos);
        }

        navigate(`/subject/${subjectID}`)
    }

    useEffect(() => {
        if (scrollContainer.current && scrollPositionY !== 0) {
            scrollContainer.current.scrollTo(0, scrollPositionY);
        }
    }, [scrollContainer, scrollPositionY]);

    return (
        <div className="course_list_container">

            <div className="course_list_header">

                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/subject")
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">
                        List of subjects
                    </span>
                </div>

                <div className='d-flex align-items-center course_search_wrapper'>
                    <img
                        src={SearchImg}
                        alt="search"
                        className="course_search_image"
                    />
                    <input
                        className="course_search_input"
                        defaultValue={searchText}
                        onChange={handleSearchText}
                        placeholder="Search a subject"
                    />

                </div>

            </div>

            <div
                id="subject_scroll_container_id"
                className={"subject_scroll_container"}
                ref={scrollContainer}
                onScroll={handleScroll}
            >
                {
                    loading ?
                    <div className="loader_container">
                        <Loader />
                    </div>
                    :
                    subjectList.length === 0 ?
                    <div className="student_search_empty_container mt-5">
                        <div className="student_search_empty_img_container">
                            <img
                                src={Empty}
                                alt="not found"
                                className="student_search_empty_img"
                            />
                        </div>

                        {
                            noData ?
                            <p className="student_search_empty_header">
                                No subjects found
                            </p>
                            :
                            <>
                                <p className="student_search_empty_header">
                                    No results found
                                </p>
                                <span className="student_search_empty_label">
                                    Try adjusting your search to find
                                </span>
                                <span className="student_search_empty_label">
                                    the best results
                                </span>
                            </>
                        }
                    </div>
                    :
                    subjectList.map((data, index) => {
                        return (
                            <div className="course_list_card" key={index}>
                                <div className="subject_divider1">
                                    <p className="course_list_card_header">
                                        Subject name
                                    </p>
                                    <p className="course_list_card_value">
                                        {data.subject_name}
                                    </p>
                                </div>

                                <div className="subject_divider2">
                                    <p className="course_list_card_header">
                                        Main subject
                                    </p>
                                    <p className="course_list_card_value">
                                        {data.is_main_subject ? "Yes" : "No"}
                                    </p>
                                </div>

                                <div className="subject_divider3">
                                    <div 
                                        className="course_action_wrapper"
                                        onClick={handleOnEdit(data.id)}
                                    >
                                        <span>
                                            Edit
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {
                    !loading && scrollLoading &&
                    <div className="loader_scroll_container">
                        <Loader />
                    </div>
                }

                {
                    !loading && !scrollLoading &&
                    <div
                        className="add_course_container"
                        onClick = {() => {
                            navigate("/subject/add");
                        }}
                    >
                        <div className="add_course_text_container">
                            <img
                                src={AddCircle}
                                alt="add"
                                className="add_student_img"
                            />

                            <span className="add_student_container_text">
                                Add Subject
                            </span>
                        </div>
                    </div>
                }

            </div>

        </div>
    );
};

export default SubjectList;