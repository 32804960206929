import React from "react";
import * as XLSX from "xlsx";
const XlsxFileInput = ({ onFileLoad }) => {
	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (event) => {
				const data = new Uint8Array(event.target.result);

				const workbook = XLSX.read(data, { type: "array" });
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];

				const jsonData = XLSX.utils.sheet_to_json(sheet);
				console.log(jsonData);
				onFileLoad(jsonData);
			};
			reader.readAsArrayBuffer(file);
		}
	};
	return (
		<div>
			<input type="file" onChange={handleFileChange} />
		</div>
	);
};

export default XlsxFileInput;