import { combineReducers } from 'redux';
import authReducer from './authReducer';
import studentSearch from './studentSearch';
import subject from './subject';
import chapter from './chapter';
import hub from './hub';
import centre from './centre';
import course from './course';
import batch from './batch';
import scrollingList from './scrollingList';
import common from './common';
import scrollingMultiple from './scrollingListMultiple';

// Combine all reducers
const appReducer = combineReducers({
    auth: authReducer,
    studentSearch,
    subject,
    chapter,
    hub,
    centre,
    course,
    batch,
    scrollingList,
    common,
    scrollingMultiple
});

const rootReducer = (state, action) => {
    // Clear all data in store when user logs out
    if (action.type === 'RESET') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;