import React from 'react'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover } from 'antd';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';

const CustomTooltip = ({ className, children, ...restProps }) => (
    <Popover overlayClassName={className} {...restProps}>
        {children}
    </Popover>
);

const StyledTooltip = styled(CustomTooltip)`
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    
    background-color: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    color: black;
    height: 42px;  
     font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    align-items:center;
    justify-content: center;
    paddingleft:10px;
  }
`;
let options = [
    "Download as word",
];

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const MppCard = ({ data, handleOnView, selectedMpps, handleState, downloadPDF }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const open = Boolean(anchorEl);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, value) => {
        if (e.nativeEvent.target.outerText === "Download as word") {
            downloadPDF(data.mpp_id, data.mpp_name)
        }
        setAnchorEl(null);
    };
   
    return (
        <div className="course_list_card ">
            <div className="d-flex mpp_divider1">
                <div className="" style={{ alignSelf: "center" }}>
                    <input
                        className="form-check-input assign-question-checkbox"
                        type="checkbox"
                        checked={selectedMpps.includes(data.mpp_id)}
                        style={{ cursor: "pointer" }}
                        onChange={() => {
                            if (
                                selectedMpps.indexOf(
                                    data.mpp_id
                                ) === -1
                            ) {
                                let temp = selectedMpps
                                temp.push(data.mpp_id)
                                handleState({ selectedMpps: temp })
                            } else {
                                let temp = selectedMpps.filter((x) => { return x !== data.mpp_id })
                                handleState({ selectedMpps: temp })
                            }

                        }}
                    />
                </div>

            </div>
            <div className="mpp_divider2">
                <p className="course_list_card_header">Program</p>

                <p className="course_list_card_value">{data.target_exam ? data.target_exam : '-'}</p>
            </div>

            <div className="mpp_divider2">
                <p className="course_list_card_header">Subject</p>

                {data.subject_name && (data.subject_name.length < 35 ? (
                    <p className="course_list_card_value">{data.subject_name ? data.subject_name : '-'}</p>
                ) : (
                    <BootstrapTooltip
                        disableFocusListener
                        enterTouchDelay={0}
                        title={data.subject_name}
                    >
                        <p style={{ cursor: "pointer" }} className="course_list_card_value">
                            {data.subject_name.substring(0, 35) + "..."}
                        </p>
                    </BootstrapTooltip>
                ))}
            </div>

            <div className="mpp_divider3">
                <p className="course_list_card_header">Name</p>

                <p className="course_list_card_value">{data.mpp_name ? data.mpp_name : '-'}</p>
                <p className="payment_details_list_card_header subvalue">ID: {data.mpp_id}</p>
            </div>

            <div className="mpp_divider4">
                <div className="course_list_card_header">Tags</div>

                <div className="course_list_card_value d-flex chapter_selected_tags_container">
                    {Array.isArray(data?.tag_name) &&
                        (data.tag_name.length > 1 ? (
                            <>
                                {data.tag_name[0].length > 13 ? (
                                    <BootstrapTooltip
                                        disableFocusListener
                                        enterTouchDelay={0}
                                        title={data.tag_name[0]}
                                    >
                                        <p
                                            style={{ cursor: "pointer" }}
                                            className="selected_tags_name"
                                        >
                                            {data.tag_name[0].substr(0, 13) +
                                                "..."}
                                        </p>
                                    </BootstrapTooltip>
                                ) : (
                                    <p className="selected_tags_name">
                                        {data.tag_name[0]}
                                    </p>
                                )}
                                {data.tag_name[1].length > 13 ? (
                                    <BootstrapTooltip
                                        disableFocusListener
                                        enterTouchDelay={0}
                                        title={data.tag_name[1]}
                                    >
                                        <p
                                            style={{ cursor: "pointer" }}
                                            className="selected_tags_name"
                                        >
                                            {data.tag_name[1].substr(0, 13) +
                                                "..."}
                                        </p>
                                    </BootstrapTooltip>
                                ) : (
                                    <p className="selected_tags_name">
                                        {data.tag_name[1]}
                                    </p>
                                )}
                            </>
                        ) : data.tag_name.length === 0 ? (
                            "-"
                        ) : (
                            <p className="selected_tags_name">
                                {data.tag_name[0] ? data.tag_name[0] : "-"}
                            </p>
                        ))}
                    {Array.isArray(data.tag_name) && data.tag_name?.length > 2 && (
                        <BootstrapTooltip
                            disableFocusListener
                            enterTouchDelay={0}
                            title={
                                data.tag_name?.length > 1 &&
                                data.tag_name?.slice(2, data.tag_name?.length)
                                    .map((tag) => {
                                        return tag;
                                    })
                                    .join(", ")
                            }
                        >
                            <div className="selected_tags_name" style={{ cursor: "pointer" }}>{`+${data.tag_name?.length - 2}`}</div>
                        </BootstrapTooltip>
                    )}
                </div>
            </div>
            <div className='mpp_divider2'>
                <div
                    className="course_action_wrapper mt-md-3"
                    onClick={() => {
                        handleOnView()
                        navigate(`/mpp/${ data.mpp_id }`)
                    }}
                >
                    <span>View</span>
                </div>
            </div>
            <div>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon
                        style={{
                            fontSize: "2.5rem",
                            color: "#000000"
                        }}
                    />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{}}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    style={{
                        marginLeft: "1rem",
                        marginTop: "-0.8rem"
                    }}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option}
                            value={option}
                            onClick={handleClose}
                            style={{
                                padding: "1rem 2rem"
                            }}
                            className="mui_dropdown_options"
                        >
                            <span
                                className={"student_detail_option_reset_pw"}
                            >
                                {option}
                            </span>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
            {/* 
          <StyledTooltip
              placement="bottomRight"
              true
              trigger="hover"
              content={<div className='payment_history_options_text' style={{ alignItems: "center", justifyContent: "center", fontSize: 16,cursor: "pointer" }} onClick={() => { alert('Download') }}>Download as word</div>} >
              <MoreVertIcon fontSize="large"/>

          </StyledTooltip> */}
        </div>
    )
}

export default MppCard