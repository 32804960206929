import { useMediaQuery } from "@mui/material";
import React from "react";
import { currencyFormatter } from "../../../utils/helperFunctions";

const Overview = ({
    noOfInstallment,
    totalPayable,
    paidAmount,
    unrealisedAmount,
    remainingAmount
}) => {
    const willWrap = useMediaQuery("(max-width: 850px)");

    return (
        <div className={`installment_detail_meta_container${willWrap ? " will-wrap" : ""}`}>
            <div className="installment-count">
                <p className="installment_meta_header">
                    No of Installments:
                </p>
                <p className="installment_meta_sub_header">
                    {noOfInstallment}
                </p>
            </div>
            <div className="installment-payable">
                <p className="installment_meta_header">
                    Total Payable:
                </p>
                <p className="installment_meta_sub_header">
                    &#8377; {currencyFormatter(totalPayable, false)}
                </p>
            </div>
            <div className="installment-paid">
                <p className="installment_meta_header">
                    Paid:
                </p>
                <p className="installment_meta_sub_header">
                    &#8377; {currencyFormatter(paidAmount, false)}
                </p>
            </div>
            <div className="installment-unrealised">
                <p className="installment_meta_header">
                    Unrealised:
                </p>
                <p className="installment_meta_sub_header">
                    &#8377; {currencyFormatter(unrealisedAmount, false)}
                </p>
            </div>
            <div className="installment-remaining">
                <p className="installment_meta_header">
                    Remaining:
                </p>
                <p className="installment_meta_sub_header">
                    &#8377; {currencyFormatter(remainingAmount, false)}
                </p>
            </div>
        </div>
    );
};

export default Overview;