import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../utils/helperFunctions";

import {
    SCRL_HANDLE_DETAILS_MULTIPLE,
    SCRL_HANDLE_LOADING_MULTIPLE,
    SCRL_HANDLE_SCROLL_LOADING_MULTIPLE,
    SCRL_HANDLE_SCROLL_APPEND_MULTIPLE, 
    SCRL_HANDLE_INIT_MULTIPLE,
    SCRL_HANDLE_SCROLL_POSITION_MULTIPLE,
} from "../../actions/types";

export function useScrollingList({ searchWait = 500, getData, key, scrollState }) {
    const scrollContainer = useRef(null);
    const initialLoaded = useRef(false);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.scrollingMultiple);
    // const [state, dispatch] = useReducer(scrollingListReducer, scrollingListInititalState);

    const { loading, scrollLoading, skip, limit, total, searchText, continueScroll, scrollPositionY } = scrollState;

    const list = state[key]?.data || [];
    
    // const list = useMemo(() => {
    //     if (key && state[key]) {
    //         return state[key]?.data || [];
    //     }

    //     return [];
    // }, [state, key]);

    const handleScroll = async (event) => {
        const target = event.target;

        const threshold = 150;
        const overThreshold = target.scrollHeight - target.scrollTop <= target.clientHeight + threshold;

        if (overThreshold && !scrollLoading && list.length < total && continueScroll) {
            // Increment skip, limit for next iteration

            dispatch({
                type: SCRL_HANDLE_SCROLL_LOADING_MULTIPLE,
                payload: {
                    key,
                    scrollLoading: true
                },
            });

            const newSkip = skip + limit;

            const data = await getData({
                skip: newSkip,
                limit,
                search: searchText,
            });

            if (data && data.items) {
                dispatch({
                    type: SCRL_HANDLE_SCROLL_APPEND_MULTIPLE,
                    payload: {
                        key,
                        listItems: data.items || [],
                        skip: newSkip,
                        scrollLoading: false,
                        total: data.total || 0,
                    },
                });
            } else {
                dispatch({
                    type: SCRL_HANDLE_SCROLL_LOADING_MULTIPLE,
                    payload: {
                        key,
                        scrollLoading: false
                    },
                });
            }
        }
    }

    const saveScrollPosition = useCallback((scrollPosY) => {
        dispatch({
            type: SCRL_HANDLE_SCROLL_POSITION_MULTIPLE,
            payload: {
                key,
                scrollPositionY: scrollPosY || 0,
            }
        })
    }, [dispatch]);

    useEffect(() => {
        initialLoaded.current = false;
    }, [key]);

    useEffect(() => {
        if (!key) {
            return;
            // throw new Error("Please provide a list key for scrolling-list hook!");
        }

        dispatch({
            type: SCRL_HANDLE_INIT_MULTIPLE,
            payload: {
                key
            },
        });
    }, [dispatch, key]);

    useEffect(() => {

        if (state[key]) {
            return;
        }

        if (initialLoaded.current && total === 0) return;

        if (!key || list.length > 0 || (searchText && searchText.trim().length > 0)) return;
        
        // Will trigger the initial call
        const getInitialListData = async () => {
            dispatch({
                type: SCRL_HANDLE_LOADING_MULTIPLE,
                payload: {
                    key,
                    loading: true
                },
            });

            const data = await getData({ skip: 0, limit: 10 });

            initialLoaded.current = true;

            if (data) {
                dispatch({
                    type: SCRL_HANDLE_DETAILS_MULTIPLE,
                    payload: {
                        key,
                        data: data.items || [],
                        skip: 0,
                        limit: 10,
                        total: data.total || 0,
                        loading: false,
                        continueScroll: true,
                    },
                });
            }
        };

        getInitialListData();
    }, [dispatch, key, list, getData, total]);

    return [
        scrollContainer,
        {
            handleScroll,
            list,
            loading,
            scrollLoading,
            scrollPositionY,
            saveScrollPosition,
        },
    ];
}
