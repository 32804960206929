import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import "./styles.css";

const Stepper = ({ steps = [], activeStep, setStep }) => {
	// Steps type => {label: string, validate?: () => boolean }[]
	const showSingleStep = useMediaQuery("(max-width: 800px)");
	const [completedSteps, setCompletedSteps] = useState([]);
	const [pendingSteps, setPendingSteps] = useState([]);

	React.useEffect(() => {
		setCompletedSteps(steps.slice(0, activeStep - 1));
		setPendingSteps(steps.slice(activeStep));
	}, [steps, activeStep]);

	const checkCursor = (step) => {
		return !step.validate || step.validate(false);
	};

	return (
		<div>
			<div className="d-flex align-items-center">
				<div className="progress_line1" />
				<div className="progress_line2" />
			</div>
			<div className="d-flex align-items-center justify-content-between steps-container ">
				<div
					className="d-flex align-items-center"
					style={{ width: "49%" }}
				>
					{!showSingleStep &&
						completedSteps.map((step, i) => (
							<div
								onClick={() => setStep(i + 1)}
								style={{
									marginRight: "4rem",
									cursor: "pointer",
								}}
								key={`step-${i + 1}`}
							>
								<div className="step-number">{i + 1}</div>
								<div className="step-label">{step.label}</div>
							</div>
						))}
				</div>
				<div
					className="d-column align-items-center"
					style={{ width: 200 }}
				>
					<div className="active-step-ring">
						<div className="step-number">{activeStep}</div>
					</div>
					<div style={{ marginTop: 48 }} />
					<div className="step-label">
						{steps[activeStep - 1].label}
					</div>
				</div>
				<div
					className="d-flex align-items-center justify-content-flex-end"
					style={{ width: "49%" }}
				>
					{!showSingleStep &&
						pendingSteps.map((step, i) => (
							<div
								onClick={() => {
									if (step.validate && !step.validate(false))
										return;
									setStep(activeStep + i + 1);
								}}
								style={{
									marginLeft: "4rem",
									cursor: checkCursor(step)
										? "pointer"
										: "default",
								}}
								key={`step-${activeStep + i + 1}`}
							>
								<div className="step-number">
									{activeStep + i + 1}
								</div>
								<div className="step-label">{step.label}</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default Stepper;
