import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Loader from '../../common/Loader';
import Swal from "sweetalert2";
import PaymentCard from "./paymentCard";

const Reversed = ({
    open,
    close,
    reversalID
}) => {

    const [loading, setLoading] = useState(false);
    const [original, setOriginal] = useState({});
    const [reversed, setReversed] = useState({});

    const getReversalData = async () => {

        setLoading(true);

        const data = await Api.doFetch("GET", {}, `${ApiUrl.FINANCE_MANAGE_PAYMENT_REVERSED}/${reversalID}`);

        setLoading(false);

        if (data?.status && data?.data?.child && data?.data?.parent && data?.data?.child.length > 0 && data?.data?.parent.length > 0) {
            setOriginal(data?.data?.parent[0]);
            setReversed(data?.data?.child[0]);
        } else {
            Swal.fire("Error!", "Reversal details not found!", "warning");
            close();
        }
    }

    useEffect(() => {
        if (reversalID) {
            getReversalData();
        }
    }, [reversalID])

    return (
        <div>
            <Dialog
                open={open}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            minWidth: "90%", // Set your width here
                        },
                    },
                }}
                className="add_course_modal_index"
            >
                <DialogContent>

                    {
                        loading ?
                        <div className="loader_container">
                            <Loader />
                        </div>
                        :
                        <div>
                            <div style={{ padding: 5 }} className="antd_dropdown">
                                <p className="payment_reversed_modal_header">
                                    Reversed entry
                                </p>
                            </div>

                            <div className="payment_details_reversal_wrapper">
                                <PaymentCard 
                                    ackNo = {reversed.ack_no}
                                    date = {reversed.date}
                                    paymentType = {reversed.payment_type}
                                    paymentMode = {reversed.payment_mode}
                                    paymentStatus = {reversed.payment_status}
                                    paymentID = {reversed.id}
                                    studentID = {reversed.student_id}
                                    centre = {reversed.centre_name}
                                    studentName = {reversed.student_name}
                                    mobileNumber = {reversed.student_mobile_number}
                                    studentStatus = {reversed.student_status}
                                    amount = {reversed.amount}
                                    recievedBy = {reversed.received_by}
                                    courseType = {reversed.course_type}
                                    studentState = {reversed.student_state}
                                    linkedAckNo = {reversed.reversal_receipt_id}
                                    reversedOn = {reversed.reversal_action_date}
                                    reversedBy = {reversed.reversed_by}
                                    paymentReversed = {reversed.linked_ack_no}
                                    UtrCode = {reversed.id}
                                    paymentPartner = {reversed.partner_name}
                                    VirtualAccountNo = {reversed.id}
                                    defaultOpen = {true}
                                    bankName = {reversed.bank_name}
                                    ChequePhoto = {reversed.cheque_file}
                                    LoanNumber = {reversed.loan_number}
                                    chequeID = {reversed.cheque_id}
                                    financeCharges = {reversed.financeCharges}
                                />
                            </div>

                            <div style={{ padding: 5 }} className="antd_dropdown mt-5">
                                <p className="payment_reversed_modal_header">
                                    Original entry
                                </p>
                            </div>

                            <div className="payment_details_reversal_wrapper">
                                <PaymentCard 
                                    ackNo = {original.ack_no}
                                    date = {original.date}
                                    paymentType = {original.payment_type}
                                    paymentMode = {original.payment_mode}
                                    paymentStatus = {original.payment_status}
                                    paymentID = {original.id}
                                    studentID = {original.student_id}
                                    centre = {original.centre_name}
                                    studentName = {original.student_name}
                                    mobileNumber = {original.student_mobile_number}
                                    studentStatus = {original.student_status}
                                    amount = {original.amount}
                                    recievedBy = {original.received_by}
                                    courseType = {original.course_type}
                                    studentState = {original.student_state}
                                    linkedAckNo = {original.reversal_receipt_id}
                                    reversedOn = {original.reversal_action_date}
                                    reversedBy = {original.reversed_by}
                                    paymentReversed = {true}
                                    UtrCode = {original.id}
                                    paymentPartner = {original.partner_name}
                                    VirtualAccountNo = {original.id}
                                    defaultOpen = {true}
                                    bankName = {original.bank_name}
                                    ChequePhoto = {original.cheque_file}
                                    LoanNumber = {original.loan_number}
                                    chequeID = {original.cheque_id}
                                    financeCharges = {original.financeCharges}
                                />
                            </div>

                            <button 
                                className={"btn btn-md payment_details_reversal_close_button mt-3"}
                                onClick = {close}
                            >
                                Close
                            </button>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Reversed;