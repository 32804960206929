import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const Confirmation = ({ 
    createModal,
    closeCreateModal,
    chapterData,
    updateModal,
    closeUpdateModal,
    updateChapter,
    checkUserHasUpdated,
}) => {
    const style = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        maxWidth: 500,
        width: "90%",
        padding: "3rem",
        borderRadius: 4,
    };

    return (
        <>
            <Modal open={createModal} onClose={closeCreateModal} className="payment_reversal_cofirmation_modal">
                <Box sx={style}>
                    <p className="reversal_accept_text">Are you sure you want to create ‘{chapterData.chapter_name}’ as a chapter?</p>

                    <div className="mt-3" style={{ float: "right" }}>
                        <button className="btn btn-md student_detail_no" onClick={closeCreateModal}>
                            No
                        </button>
                        <button
                            className="btn btn-md student_detail_password_confirm ml-2"
                            onClick={() => {
                                closeCreateModal();
                                updateChapter();
                            }}
                        >
                            Confirm
                            {/* {resetPasswordLoading && <span className="ml-2 spinner spinner-white mr-3"></span>} */}
                        </button>
                    </div>
                </Box>
            </Modal>

            <Modal open={updateModal} onClose={closeUpdateModal} className="payment_reversal_cofirmation_modal">
                <Box sx={style}>
                    <p className="reversal_accept_text">Are you sure you want to save changes in chapter management?</p>

                    <div className="mb-3 modal_save_confirmation_wrapper">
                        {checkUserHasUpdated() && checkUserHasUpdated().map((data, index) => {
                            return (
                                <div className="modal_save_confirmation_container" key={index}>
                                    <div className="modal_save_confimation_header">{data.header}</div>
                                    {data.header === "Course Name" ? (
                                        <>
                                            <div className="chapter_selected_tags_container">
                                                {data.value.map((data2, index2) => {
                                                    return (
                                                        <p className="modal_save_confirmation_selected_tag mb-0" key={index2}>
                                                            {data2}
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    ) : data.header === "Tags" ? (
                                        <>
                                            <div className="chapter_selected_tags_container">
                                                {data.value.length > 0 ? (
                                                    data.value.map((data1, index1) => {
                                                        return (
                                                            <p className="modal_save_confirmation_selected_tag mb-0" key={index1}>
                                                                {data1}
                                                            </p>
                                                        );
                                                    })
                                                ) : (
                                                    <p className="mb-0">No tags added</p>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <span className="modal_save_confimation_value">{data.value}</span>
                                    )}
                                </div>
                            );
                        })}
                    </div>

                    <div className="mt-3" style={{ float: "right" }}>
                        <button className="btn btn-md student_detail_no" onClick={closeUpdateModal}>
                            No
                        </button>
                        <button
                            className="btn btn-md student_detail_password_confirm ml-2"
                            onClick={() => {
                                closeUpdateModal();
                                updateChapter();
                            }}
                        >
                            Save
                            {/* {resetPasswordLoading && <span className="ml-2 spinner spinner-white mr-3"></span>} */}
                        </button>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default Confirmation;
