import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxWidth: 500,
    width: "90%",
    padding: "3rem",
    borderRadius: 4,
};

const Confirmation = ({
    createModal,
    closeCreateModal,
    hubName,
    showEdit,
    handleConfirmUpdate,
    handleConfirmSubmit
}) => {
    return (
        <Modal
            open={createModal}
            onClose={closeCreateModal}
            className="payment_reversal_cofirmation_modal"
        >
            <Box sx={style}>
                <p className="reversal_accept_text">
                {
                    showEdit ? 
                    "Are you sure you want to save changes?" : 
                    `Are you sure you want to create?`
                }
                </p>

                <div
                    className="mt-3"
                    style={{ float: "right" }}
                >
                    <button
                        className="btn btn-md student_detail_no"
                        onClick={closeCreateModal}
                    >
                        No
                    </button>
                    <button
                        className="btn btn-md student_detail_password_confirm ml-2"
                        onClick={() => {
                            closeCreateModal();
                            if (showEdit) {
                                // handleConfirmUpdate();
                            } else {
                                handleConfirmSubmit();
                            }
                        }}
                    >
                        Save
                    </button>
                </div>

            </Box>
        </Modal>
    );
};

export default Confirmation;