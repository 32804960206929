import { HANDLE_AUTH_DETAILS } from '../actions/types';

const initialState = {
    roleList: [],
    centerList: [],
    pages: [],
    permissionApiCalled: false,
    roleApiCalled: false
};
//eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_AUTH_DETAILS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
