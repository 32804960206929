import React, { useEffect, useState } from "react";
import Error from "../../common/errorText";
import { useSelector } from "react-redux";
import { roleConstant } from "../../../utils/helperFunctions";

const FeePayment = ({ goToPayment, paymentMethod, handlePaymentMethod, paymentAmount, updateInputValue, toggle, isSociety = false, enableHDFC }) => {
	const { roleList } = useSelector((state) => state.auth);

	const [error, setError] = useState("");

	useEffect(() => {
		setError("");
	}, [toggle]);

	const [isStudentManager, setIsStudentManager] = useState(false);

	useEffect(() => {
		if (roleList && roleList.length > 0 && roleList.findIndex((x) => x.role_name === roleConstant.STUDENT_MANAGER) >= 0) {
			setIsStudentManager(true);
		}
	}, [roleList]);

	const getClassName = (type, societyCheck = true) => {
		let className = paymentMethod === type ? "contain active" : "contain";

		// if (societyCheck && isSociety) {
		// 	className += " d-none";
		// }

		return className;
	};

	return (
		<div className="modal-content">
			<div className="modal-header">
				<button type="button" className="close payment_pay_close_button" data-dismiss="modal">
					&times;
				</button>
				<h4 className="modal-title">Payment Collection</h4>
			</div>
			<div className="modal-body">
				<div className="">
					<div className="row">
						<div className="col-sm-12">
							<div className="form-group">
								<label htmlFor="text">Enter Amount</label>
								<div className="input-group discountWrpField col-sm-6 col-md-4 col-lg-3" style={{ width: "40%" }}>
									<span className="input-group-addon">&#8377;</span>
									<input
										type="text"
										placeholder="Enter Amount"
										// onChange={feeDetails}
										className="form-control discountWrp"
										onKeyPress={(event) => {
											if (!/[0-9]/.test(event.key)) {
												event.preventDefault();
											}
										}}
										autoComplete="off"
										value={paymentAmount}
										onChange={(event) => {
											setError("");
											updateInputValue(event);
										}}
										style={{ width: "100%" }}
									/>
								</div>
								{error && <Error text={error} />}
								{/* <input type="text" className="form-control enterAmt" autoComplete="off" value={paymentAmount} onChange={(event) => updateInputValue(event)} /> */}
							</div>
							<div className="form-group">
								<label htmlFor="pwd">Mode of payment:</label>
								<div className="form-group">
									{/* <label
                                        className={getClassName("icici")}
                                    >
                                        Online Payment (ICICI)
                                        <input
                                            type="radio"
                                            name="radio"
                                            checked={paymentMethod === "icici"}
                                            value="icici"
                                            onChange={(event) => handlePaymentMethod(event)}
                                        />
                                        <span className="checkmark"></span>
                                    </label> */}
									<label className={getClassName("cash", false)}>
										Cash
										<input
											type="radio"
											name="radio"
											checked={paymentMethod === "cash"}
											value="cash"
											onChange={(event) => handlePaymentMethod(event)}
										/>
										<span className="checkmark"></span>
									</label>
									<label className={getClassName("cheque", false)}>
										Cheque
										<input
											type="radio"
											name="radio"
											checked={paymentMethod === "cheque"}
											value="cheque"
											onChange={(event) => handlePaymentMethod(event)}
										/>
										<span className="checkmark"></span>
									</label>

									{/* <label
                                        className={getClassName("swipe")}
                                    >
                                        POS (Swipe Machine)
                                        <input
                                            type="radio"
                                            name="radio"
                                            checked={paymentMethod === "swipe"}
                                            value="swipe"
                                            onChange={(event) => handlePaymentMethod(event)}
                                        />
                                        <span className="checkmark"></span>
                                    </label> */}
								</div>
							</div>
							<div className="form-group">
								<label className={getClassName("ezetap")}>
									new POS (ICICI)
									<input
										type="radio"
										name="radio"
										checked={paymentMethod === "ezetap"}
										value="ezetap"
										onChange={(event) => handlePaymentMethod(event)}
									/>
									<span className="checkmark"></span>
								</label>

								<label className={getClassName("link")}>
									Share Payment Link
									<input
										type="radio"
										name="radio"
										checked={paymentMethod === "link"}
										value="link"
										onChange={(event) => handlePaymentMethod(event)}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
							<div className="form-group"></div>
							<div className="form-group"></div>
							<div className="form-group">
								<label className={getClassName("razorpay")}>
									Online Payment (Razor Pay)
									<input
										type="radio"
										name="radio"
										checked={paymentMethod === "razorpay"}
										value="razorpay"
										onChange={(event) => handlePaymentMethod(event)}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
							<div className="form-group">
								{isStudentManager && (
									<label className={getClassName("bankdeposit", false)}>
										Bank Deposit
										<input
											type="radio"
											name="radio"
											checked={paymentMethod === "bankdeposit"}
											value="bankdeposit"
											onChange={(event) => handlePaymentMethod(event)}
										/>
										<span className="checkmark"></span>
									</label>
								)}

								<label className={getClassName("nocostemi")}>
									No cost EMI
									<input
										type="radio"
										name="radio"
										checked={paymentMethod === "nocostemi"}
										value="nocostemi"
										onChange={(event) => handlePaymentMethod(event)}
									/>
									<span className="checkmark"></span>
								</label>

								<label className={getClassName("online/swipe")}>
									Online/Swipe
									<input
										type="radio"
										name="radio"
										checked={paymentMethod === "online/swipe"}
										value="online/swipe"
										onChange={(event) => handlePaymentMethod(event)}
									/>
									<span className="checkmark"></span>
								</label>
							</div>

							{enableHDFC && (
								<div className="form-group">
									<label className={getClassName("hdfc/ccavenue")}>
										hdfc/ccavenue
										<input
											type="radio"
											name="radio"
											checked={paymentMethod === "hdfc/ccavenue"}
											value="hdfc/ccavenue"
											onChange={(event) => handlePaymentMethod(event)}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="modal-footer floatleft">
				<button type="button" data-dismiss="modal" style={{ display: "none" }} id="close_modal" />
				<button
					type="button"
					className="btn btn-default"
					onClick={(e) => {
						if (+paymentAmount > 0) {
							goToPayment();
							document.getElementById("close_modal").click();
						} else {
							setError("Please enter valid Amount");
						}
					}}
				>
					Next
				</button>
			</div>
		</div>
	);
};

export default FeePayment;
