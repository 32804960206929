import React, { useEffect, useState } from "react";
import "./Fees.css";
import dateFormat from "dateformat";
import Logo from "../../assets/img/MCR.png";

import { ToWords } from "to-words";
import ReactLoading from "react-loading";
import {
    useParams,
} from "react-router-dom";
import { httpGet } from "../../utils/https";

const PaymentReciptHostelSummary = (props) => {
    let data = localStorage.getItem("userdetail");
    data = JSON.parse(data);
    // console.log(props, "props");
    const { payment_id } = useParams()
    // const [paymentDetails, setPaymentDetails] = useState()
    const [paymentdata, setPayment] = useState("");
    const [name, setName] = useState("");
    //   const [amtword, setAmtWord] = useState("");
    const [studentId, setStudentId] = useState("");
    // const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [receipt, setReceipt] = useState(null)

    const [mobile, setMobile] = useState("");
    // const [stud_class, setStud_Class] = useState("");
    const [parentName, setParentName] = useState("");
    const [amt, setAmt] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const token = data.token;
    const [schoolname, setSchoolname] = useState("");
    //   const pdfExportComponent = useRef(null);

    const [totalfees, setTotalFees] = useState("");
    const [feepaid, setFeePaid] = useState("");
    const [remfee, setRemFee] = useState("");
    const [unrelamt, setUnrealAmt] = useState("");
    // const [paidfor, setPaidFor] = useState("");



    const getPaymentMode = (paymentType) => {
        let paymentMode = '';
        switch (paymentType) {
            case "online/swipe":
                paymentMode = "Online/Swipe";
                break;
            case "hdfc/ccavenue":
                paymentMode = "hdfc/ccavenue";
                break;
            case "cash":
            case "Cash":
                paymentMode = 'Cash';
                break;
            case "cheque":
            case "Cheque":
            case "Post Dated Cheque":
                paymentMode = 'Cheque';
                break;
            case "CommerceConnect":
            case "commerceconnect":
            case "razorpay":
            case "Razorpay":
            case "Bank Transfer":
            case "paytm":
            case "Paytm":
                paymentMode = 'Online';
                break;
            case "ezetap":
            case "swipe":
            case "Swipe":
                paymentMode = 'POS';
                break;
            case "link":
                paymentMode = 'Link';
                break;
            default:
                paymentMode = paymentType;
        }
        return paymentMode;
    }


    const downloadReciept = async (student_id, payment_id, section_id, school_id) => {
        try {
            // console.log('studentid:',studentId,'payment_id',paymentDetails.id,'section_id',paymentDetails.student_assoc.section_assoc.id,'school_id',paymentDetails.school_assoc.id)
            let formdata = new FormData()
            formdata.append('student_id', student_id)
            formdata.append('payment_id', payment_id)
            formdata.append('section_id', section_id)
            formdata.append('school_id', school_id)

            const response = await fetch(`${ process.env.REACT_APP_BASE_URL }support/generate-hostel-payment-receipt/`, {
                method: 'POST',
                headers: {
                    // 'Accept': "application/json",
                    // 'Content-Type': "application/json",
                    'Authorization': "Token " + token,
                },
                body: formdata
            })
            const D = await response.json()
            console.log(D.payload.invoice_url, 'open')
            setReceipt(D.payload.invoice_url)
        } catch (err) {
            console.log(err)
        }
    }

    var toWords = new ToWords({
        localeCode: "en-IN",
        converterOptions: {
            currency: false,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        },
    });

    const getHostelPaymentReceipt = async () => {
        const PaymentReceiptRs = await httpGet(`${ process.env.REACT_APP_BASE_URL }support/get-hostel-payment/${ payment_id }`);

        if (PaymentReceiptRs.status === 200) {
            // setPaymentDetails(PaymentReceiptRs.data.payload)
            setSchoolname(PaymentReceiptRs.data.payload.school_assoc.name);
            setName(PaymentReceiptRs.data.payload.student_assoc.name);
            setStudentId(PaymentReceiptRs.data.payload.student_assoc.id);
            setLogoUrl(PaymentReceiptRs.data.payload.school_assoc.logo);
            // setEmail(PaymentReceiptRs.data.payload.student_assoc.email);
            setMobile(PaymentReceiptRs.data.payload.student_assoc.mobile_number);
            setPayment(PaymentReceiptRs.data.payload);
            setAmt(PaymentReceiptRs.data.payload.amount_paid);

            // setTotalFees(PaymentReceiptRs.data.payload.student_assoc.total_fees)
            // setFeePaid(PaymentReceiptRs.data.payload.student_assoc.fee_paid)
            // setRemFee(PaymentReceiptRs.data.payload.student_assoc.fee_pending)
            // setUnrealAmt(PaymentReceiptRs.data.payload.unrealised_amount);
            // setPaidFor(PaymentReceiptRs.data.payload.course_name);

            setTotalFees(PaymentReceiptRs.data.payload.hostel_assoc.fee_amount)
            setFeePaid(PaymentReceiptRs.data.payload.fee_paid_till_date)
            setRemFee(PaymentReceiptRs.data.payload.hostel_assoc.fee_amount - PaymentReceiptRs.data.payload.fee_paid_till_date)
            setUnrealAmt(PaymentReceiptRs.data.payload.unrealised_amount);
            // setPaidFor(PaymentReceiptRs.data.payload.course_name);

            downloadReciept(PaymentReceiptRs.data.payload.student_assoc.id, PaymentReceiptRs.data.payload.id, PaymentReceiptRs.data.payload.student_assoc.section_assoc.id, PaymentReceiptRs.data.payload.school_assoc.id)

            const parentDetails = await httpGet(`${ process.env.REACT_APP_BASE_URL }users/get-parent-details/?ID=${ PaymentReceiptRs.data.payload.student_assoc.id }`)

            if (parentDetails.status === 200) {
                setParentName(parentDetails && parentDetails.data.length > 0 && parentDetails.data[0].name ? parentDetails.data[0].name : "");
            }

            // const studentDetails = await httpGet(`${ process.env.REACT_APP_BASE_URL }users/get-student-details/?student_id=${ PaymentReceiptRs.data.payload.student_assoc.id }`)

            // if (studentDetails.status === 200) {
            //     setStud_Class(studentDetails.data.class_assoc);
            // }

            setLoading(false);
        }
    }
    useEffect(() => {
        setLoading(true);
        getHostelPaymentReceipt();
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="container">
                <div className="text-right" style={{ marginTop: '20px' }}>
                    <button className={receipt != null ? `btn btn-primary` : `btn non_click`}
                        onClick={() => {
                            if (receipt != null) {
                                window.open(receipt, '_blank')
                            }
                        }}>
                        Download Invoice
                    </button>
                </div>

                <br />
                <br />
                {loading ? (
                    <div>
                        <div>
                            <br />
                            <br />
                            <center>
                                <ReactLoading
                                    type="spinningBubbles"
                                    color="blue"
                                    height="3px"
                                />
                                <br />
                                <br />
                                <br />
                                <br />
                                <p>Please Wait While Your Receipt Is loading</p>
                            </center>
                        </div>
                    </div>
                ) : (

                    <div style={{ marginTop: "30px", border: "0.5px solid #909090" }}>
                        <div className="blue-box row">
                            <div className="col-md-4">
                                <img src="https://zinedumain.s3.us-east-2.amazonaws.com/Rectangle_logo_container.svg" alt="" className="rect-img-cont" />

                                {logoUrl === null ? (
                                    <img alt={"Partner Logo"} src={Logo} className="partner-logo-img" />
                                ) : (
                                    <img alt={"Partner Logo"} src={logoUrl} className="partner-logo-img" />
                                )}
                            </div>
                            <div className="col-md-4">
                                <center>
                                    <span className="ack-text"> Fee Acknowledgement </span>
                                </center>
                            </div>
                            <div className="col-md-4"></div>
                        </div>

                        <div className="ack-below-box">
                            {/* <div className="col-md-3 blank"></div> */}
                            <div className="company-deets">
                                {/* <p>
                                    <strong>
                                        <span className="company-name">
                                            {paymentdata?.centre_details?.entity}
                                        </span>
                                    </strong>
                                    <br />
                                    <span className="small-line">
                                        {paymentdata?.centre_details?.sub_entity}
                                    </span>
                                </p> */}

                                <p className="address">
                                    {paymentdata?.centre_details?.address}
                                </p>

                                {
                                    paymentdata?.centre_details?.CIN &&
                                    <p className="cin">{paymentdata?.centre_details?.CIN}</p>
                                }
                            </div>
                            <div className="myclass-center">
                                <p className="center-heading">
                                    <strong>Centre name</strong> <br />
                                    {schoolname}
                                </p>
                            </div>
                        </div>

                        <div className="maincontent">
                            <div className="acknowledgement-num">
                                <div>
                                    <span style={{ fontWeight: "600" }}>
                                        Acknowledgement
                                        Number&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <span style={{ fontWeight: "500" }}>
                                        {paymentdata.id}
                                    </span>
                                </div>
                                <div>
                                    <span style={{ fontWeight: "600" }}>
                                        Acknowledgement
                                        Date&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>{" "}
                                    <span style={{ fontWeight: "500" }}>{dateFormat(paymentdata.created_on, "dd mmmm yyyy")}</span>
                                </div>
                            </div>

                            <center>
                                <div className="stud-details-box mx-auto">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="stud-deet-td">
                                                    {" "}
                                                    <strong>Student Name</strong>
                                                </td>
                                                <td className="stud-deet-td">:</td>
                                                <td className="stud-deet-td">{name}</td>
                                                <td className="stud-deet-td">
                                                    <strong>Student Mobile No</strong>
                                                </td>
                                                <td className="stud-deet-td">:</td>
                                                <td className="stud-deet-td">{mobile}</td>
                                            </tr>

                                            <tr>
                                                <td className="stud-deet-td">
                                                    <strong>Student ID</strong>
                                                </td>
                                                <td className="stud-deet-td">:</td>
                                                <td className="stud-deet-td">{studentId}</td>
                                                <td className="stud-deet-td">
                                                    <strong>Parent Name</strong>
                                                </td>
                                                <td className="stud-deet-td">:</td>

                                                <td className="stud-deet-td">{parentName}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </center>

                            <div className="pay-deets-1">
                                <center>
                                    <table className="pay-deets-1-tab">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Paid For</strong>
                                                </td>
                                                <td>Hostel</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Mode of Payment</strong>
                                                </td>
                                                <td>
                                                    <strong>{getPaymentMode(paymentdata.payment_type)}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Cheque/DD/Ref No</strong>
                                                </td>
                                                <td>
                                                    {paymentdata.payment_id === "Not Applicable !" ? (
                                                        <p>Not Applicable</p>
                                                    ) : (
                                                        <p>{paymentdata.payment_id}</p>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Date of Receipt</strong>
                                                </td>
                                                <td>{dateFormat(paymentdata.created_on, "dd mmmm yyyy")}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Total Amount (inclusive of GST)</strong>
                                                </td>
                                                <td>₹ {paymentdata.amount_paid}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Total Amount in Words</strong>
                                                </td>
                                                <td>
                                                    {amt ? (
                                                        <div> Rupees {toWords.convert(amt)} Only</div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className="pay-deets-2-tab">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Total Fees</strong>
                                                </td>
                                                <td>₹ {totalfees}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Fee Paid Till Date</strong>
                                                </td>
                                                <td>₹ {feepaid}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Outstanding Fees</strong>
                                                </td>
                                                <td>₹ {remfee}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Unrealised Amount</strong>
                                                </td>
                                                <td>₹ {unrelamt}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </center>
                            </div>

                            {
                                paymentdata?.centre_details?.CIN &&
                                <p className="to-view">
                                    To view detailed installment breakup, check your student
                                    dashboard on{" "}
                                    <a href="https://myclassroom.digital">
                                        {" "}
                                        https://myclassroom.digital
                                    </a>
                                </p>
                            }

                            <p className="information">
                                <strong>Information</strong> <br />
                                <br />
                                {
                                    paymentdata?.centre_details?.CIN ?
                                    <>
                                        This is not a GST invoice. <br /> <br />
                                    </>
                                    :
                                    null
                                }
                                {/* GST invoice will be issued within 30 days. <br /> */}
                                {/* <br /> */}
                                Payment is subject to realisation.
                            </p>

                            <p className="comp-gen text-center">
                                <strong>
                                    This is a computer generated receipt and does not require
                                    manual signature.
                                </strong>
                            </p>
                        </div>

                        <img
                            src="https://zinedumain.s3.us-east-2.amazonaws.com/below-img.svg"
                            alt=""
                            className="below-img"
                        ></img>
                    </div>
                )}

                <br />
                <br />

                <br />
                <br />
            </div>
        </div>
    );
};

export default PaymentReciptHostelSummary;


/*
LINE 302
*/
