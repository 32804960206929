import React from "react";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Capitalize } from "../../../utils/helperFunctions";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

export const CustomFinanceTooltip = styled(({ className, hideTooltip = false, title, ...props }) => {
    title = hideTooltip ? "" : title;

    return <Tooltip {...props} title={title} arrow classes={{ popper: `${className} tooltip_header` }} />;
})(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#4D4D4D",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#4D4D4D",
        fontSize: "13px",
    },
}));

const HandleTooltip = ({ 
    selectedValue, 
    dataList
}) => {
    return (
        <>
            {selectedValue.length > 2 ? (
                <>
                    {selectedValue.slice(0, 2).map((item, index) => {
                        return (
                            <p key={index} className="mb-0">
                                {
                                    dataList
                                    .filter((x) => x === item)
                                    .map((item2) => {
                                        return <p className="cheque-selected-filter" style={{ margin: "auto 4px" }}>{Capitalize(item2)}</p>;
                                    })
                                }
                            </p>
                        );
                    })}
                    <BootstrapTooltip
                        disableFocusListener
                        enterTouchDelay={0}
                        title={
                            selectedValue.slice(2, selectedValue.length).map((item) => {
                                return dataList
                                    .filter((x) => x === item)
                                    .map((item2) => {
                                        return item2 + ", ";
                                    });
                            })
                        }
                    >
                        <div className="cheque-selected-filter" style={{ cursor: "pointer", margin: "auto 4px" }}>
                            {`+ ${selectedValue.length - 2}`}
                        </div>
                    </BootstrapTooltip>
                </>
            ) : (
                <>
                    <p className="mb-0">
                        {
                            dataList
                            .filter((x) => x === selectedValue[0])
                            .map((item2) => {
                                return <p className="cheque-selected-filter" style={{ margin: "auto 4px" }}>{Capitalize(item2)}</p>;
                            })
                        }
                    </p>

                    <p className="mb-0">
                        {dataList
                            .filter((x) => x === selectedValue[1])
                            .map((item2) => {
                                return <p className="cheque-selected-filter" style={{ margin: "auto 4px" }} >{Capitalize(item2)}</p>;
                            })}
                    </p>
                </>
            )}
        </>
    );
};

export default HandleTooltip;
