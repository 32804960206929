import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";

import Dashboard from "../../assets/img/dashboard.svg";
import DashboardSel from "../../assets/img/dashboard_s.svg";
import { navList } from "../../utils/navbar";

import { checkRouteActive, generateProfileInitials, permissionConstants, roleConstant } from "../../utils/helperFunctions";
import SideNavArrow from "../../assets/img/sidenav_option.svg";
import Api from "../common/api";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LogoutImg from "../../assets/img/logout.svg";

import { RESET, RESET_STUDENT_SEARCH } from "../../actions/types";
import useHover from "../../utils/hooks/use-hover";

import StudentImg from "../../assets/img/student.svg";
import StudentSelImg from "../../assets/img/student_s.svg";

const drawerWidth = 240;

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
	({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.black,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.black,
			fontSize: "13px",
		},
	})
);

const Navbar = ({ mobile = false, changePassword, closePanel, setNavExpanded }) => {
	const [toggleRef, isHovering] = useHover();

	const userDisplayName = Api.getUserDisplayName();

	const [navOpen, setNavOpen] = useState(Api.getNavState());

	const isMobile = useMediaQuery("(max-width:600px)");

	const { pages, centerList, roleList } = useSelector((state) => state.auth);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const logout = () => {
		Api.logout();
		dispatch({ type: RESET });
		navigate("/");
	};

	React.useEffect(() => {
		if (setNavExpanded) setNavExpanded(navOpen);
	}, [navOpen, setNavExpanded]);

	const [isStudentManager, setIsStudentManager] = useState(false);

	useEffect(() => {
		if (roleList && roleList.length > 0 && roleList.findIndex((x) => x.role_name === roleConstant.STUDENT_MANAGER) >= 0) {
			if (roleList.findIndex((x) => x.role_name === roleConstant.COUNSELOR) === -1) {
				setIsStudentManager(true);
			}
		}
	}, [roleList]);

	if (isMobile && !mobile) {
		return null;
	}

	let index = pages.findIndex((x) => x.page === permissionConstants.CHANGE_PASSWORD);

	let hasPasswordPermission = index === -1 ? false : true;

	return (
		<div
			className={`${
				mobile
					? "navbar_container_wrapper_open"
					: navOpen
					? "navbar_container_wrapper_open"
					: `navbar_container_wrapper${isHovering && navOpen !== null ? " nav-collapse" : ""}`
			}`}
		>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
					},
				}}
				variant="permanent"
				anchor="left"
				className="side_nav"
			>
				{mobile && (
					<div className="mobile_nav_profile">
						<div className="mav_mobile_name_container">{generateProfileInitials(userDisplayName)}</div>

						<span className="mobile_profile_username">{userDisplayName}</span>
						{roleList.length > 0 && (
							<div className="d-flex justify-content-center">
								<span className={`mobile_profile_role ${roleList.length > 1 ? "mr-2" : ""}`}>
									{roleList[0].role_name.charAt(0).toUpperCase() + roleList[0].role_name.slice(1)}
								</span>
								{roleList.length > 1 && (
									<BootstrapTooltip
										disableFocusListener
										enterTouchDelay={0}
										title={
											roleList.length > 1 &&
											roleList
												.slice(1, roleList.length)
												.map((item) => {
													return `${item.role_name.charAt(0).toUpperCase() + item.role_name.slice(1)}`;
												})
												.toString()
										}
										// className="mobile_nav_tooltip"
									>
										<div style={{ cursor: "pointer" }} className="mobile_profile_role">
											{`+${roleList.length - 1}`}
										</div>
									</BootstrapTooltip>
								)}
							</div>
						)}

						<div className="center-badge-mobile-wrapper">
							{
								<>
									{centerList.slice(0, 1).map((item, i) => {
										return (
											<div className="center-badge-mobile" key={item.school_name}>
												{item.school_name}
											</div>
										);
									})}
									{centerList.length > 1 && (
										<BootstrapTooltip
											disableFocusListener
											enterTouchDelay={0}
											title={
												centerList.length > 1 &&
												centerList
													.slice(1, centerList.length)
													.map((item) => {
														return ` ${item.school_name}`;
													})
													.toString()
											}
											// className="mobile_nav_tooltip"
										>
											<div className="center-badge-mobile" style={{ cursor: "pointer" }}>{`+${centerList.length - 1}`}</div>
										</BootstrapTooltip>
									)}
								</>
							}
						</div>

						{hasPasswordPermission && (
							<p className="mobile_password_change" onClick={changePassword}>
								Change Password
							</p>
						)}
					</div>
				)}
				<List className={`${mobile ? "nav-list-mobile" : "navbar_container"}`}>
					<NavLink
						style={({ isActive }) => {
							return {
								textDecoration: "none",
								fontWeight: "600",
								display: "block",
								borderRadius: "0 50px 50px 0",
								backgroundColor: checkRouteActive("Dashboard") ? "#012C63" : "",
								color: checkRouteActive("Dashboard") ? "white" : "#535353",
								marginRight: "15%"
							};
						}}
            
						to={`/dashboard`}
						className="customSideNavBtn"
						onClick={() => {
							if (isMobile) {
								closePanel();
							}
						}}
					>
						<div className="nav_link_container">
							<img src={checkRouteActive("Dashboard") ? DashboardSel : Dashboard} alt="dashboard" />
							<span className={`sidebar_text ${mobile ? "sidebar_text_open" : navOpen ? "sidebar_text_open" : ""}`}>Dashboard</span>
						</div>
					</NavLink>
					{Object.keys(navList).map((navListItem, navIndex) => {
						let index = pages.findIndex((x) => x.page === navListItem);

						if (index >= 0) {
							let { route, name, isActive, notActive } = navList[navListItem];

							if (route === "/student" && isStudentManager) {
								dispatch({ type: RESET_STUDENT_SEARCH });
								route = "/student/search";
							}

							return (
								<NavLink
									key={navIndex}
									style={({ isActive }) => {
										return {
											textDecoration: "none",
											fontWeight: "600",
											display: "block",
											borderRadius: "0 50px 50px 0",
											backgroundColor: checkRouteActive(name) ? "#012C63" : "",
											color: checkRouteActive(name) ? "white" : "#535353",
											marginRight: "15%",
										};
									}}
									to={`${route}`}
									className="customSideNavBtn"
									onClick={() => {
										if (isMobile) {
											closePanel();
										}
									}}
								>
									<div className="nav_link_container">
										<img
											src={checkRouteActive(name) ? isActive : notActive}
											alt={name}
											className={name === "Reversal Request" ? "reversal_icon_img" : ""}
										/>
										<span className={`sidebar_text ${mobile ? "sidebar_text_open" : navOpen ? "sidebar_text_open" : ""}`}>{name}</span>
									</div>
								</NavLink>
							);
						}

						return null;
					})}

           <NavLink
						style={({ isActive }) => {
							return {
								textDecoration: "none",
								fontWeight: "600",
								display: "block",
								borderRadius: "0 50px 50px 0",
								backgroundColor: checkRouteActive("Role") ? "#012C63" : "",
								color: checkRouteActive("User") ? "white" : "#535353",
								marginRight: "15%"
							};
						}}
						to={`/role`}
						className="customSideNavBtn"
						onClick={() => {
							if (isMobile) {
								closePanel();
							}
						}}
					>
						<div className="nav_link_container">
							<img src={checkRouteActive("Role") ? StudentSelImg : StudentImg} alt="user" />
							<span className={`sidebar_text ${mobile ? "sidebar_text_open" : navOpen ? "sidebar_text_open" : ""}`}>Role</span>
						</div>
					</NavLink>

					<NavLink
						style={({ isActive }) => {
							return {
								textDecoration: "none",
								fontWeight: "600",
								display: "block",
								borderRadius: "0 50px 50px 0",
								backgroundColor: checkRouteActive("Role") ? "#012C63" : "",
								color: checkRouteActive("User") ? "white" : "#535353",
								marginRight: "15%"
							};
						}}
						to={`/report`}
						className="customSideNavBtn"
						onClick={() => {
							if (isMobile) {
								closePanel();
							}
						}}
					>
						<div className="nav_link_container">
							<img src={checkRouteActive("Report") ? StudentSelImg : StudentImg} alt="Report" />
							<span className={`sidebar_text ${mobile ? "sidebar_text_open" : navOpen ? "sidebar_text_open" : ""}`}>Report</span>
						</div>
					</NavLink>

          <NavLink
						style={({ isActive }) => {
							return {
								textDecoration: "none",
								fontWeight: "600",
								display: "block",
								borderRadius: "0 50px 50px 0",
								backgroundColor: checkRouteActive("Report") ? "#012C63" : "",
								color: checkRouteActive("Report") ? "white" : "#535353",
								marginRight: "15%"
							};
						}}
            target="_blank"
						to={`http://admin.zinedu.com/WEBSupport/PageAccess?UserId=${JSON.parse(localStorage.getItem('userdetail')).user_id}&AuthKey=${JSON.parse(localStorage.getItem('userdetail')).token}`}
						className="customSideNavBtn"
						onClick={() => {
							if (isMobile) {
								closePanel();
							}
						}}
					>
						<div className="nav_link_container">
							<img src={checkRouteActive("Report") ? DashboardSel : Dashboard} alt="report" />
							<span className={`sidebar_text ${mobile ? "sidebar_text_open" : navOpen ? "sidebar_text_open" : ""}`}>Report</span>
						</div>
					</NavLink>

         

				</List>

				{mobile ? (
					<div className="sidebar_nav_option_logout">
						<div className="sidebar_nav_option_logout_wrapper" onClick={logout}>
							<img src={LogoutImg} alt="logout" />

							<p className="nav_mobile_logout">Logout</p>
						</div>
					</div>
				) : (
					<div
						ref={toggleRef}
						className="sidebar_nav_option"
						onClick={() => {
							Api.setNavState(!navOpen);
							setNavOpen(!navOpen);
						}}
					>
						<img src={SideNavArrow} alt="arrow" className={`${navOpen ? "side_bar_open_arrow" : ""}`} />
					</div>
				)}
			</Drawer>
		</div>
	);
};

export default Navbar;
