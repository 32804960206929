import React, { useRef, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Select, DatePicker } from "antd";
import { Asterisk, currencyFormatter, zinedus3 } from "../../../utils/helperFunctions";
import ViewSlipModal from "../common/ViewSlipModal";
import { toast } from "react-toastify";
import ToastMessage from "../../common/toastMessage";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import Swal from "sweetalert2";
import moment from "moment";

const { Option } = Select;

const DepositsDetails = (props) => {
    const {
        ackNo = "",
        utrCode = "",
        entryDate = "",
        transferDate = "",
        paymentPartner = "",
        studentID = "",
        studentName = "",
        studentMobile = "",
        centreName = "",
        amount = "",
        slipPhoto = "",
        id = "",
        updateDeposits,
    } = props;

    const [loading, setLoading] = useState(false);

    const [paymentReceiveDate, setPaymentReceiveDate] = useState("");

    const [paymentDeclineReason, setPaymentDeclineReason] = useState("");

    const [selectedReason, setSelectedReason] = useState();

    const depositStatus = ["Paid", "Failed"];

    const [viewSlipModal, setViewSlipModal] = useState(false);

    const [errorText, setErrorText] = useState("");

    const errorTimeoutRef = useRef(null);

    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.arrow}`]: {
                color: theme.palette.common.black,
            },
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.black,
                fontSize: "13px",
            },
        })
    );

    const handleViewSlip = () => {
        setViewSlipModal((state) => !state);
    };

    const handleSubmitClick = async () => {
        if (loading) {
            return;
        }

        let payload = {
            status: selectedReason,
        };

        if (selectedReason === "Paid") {
            payload.amount_receiving_date = moment(paymentReceiveDate).format("YYYY-MM-DD");
        } else if (selectedReason === "Failed") {
            payload.remarks = paymentDeclineReason;
        }

        setLoading(true);

        const data = await Api.doFetch("POST", payload, `${ApiUrl.UPDATE_PENDING_DEPOSITS}${id}`);

        setLoading(false);

        if (data?.status) {
            setPaymentReceiveDate("");
            setPaymentDeclineReason("");
            setSelectedReason("");
            updateDeposits(id);
            toast(<ToastMessage text={"Payment updated successfully!"} />);
        } else {
            Swal.fire("Error!", data?.message || "Internal Server Error", "error");
        }
    };

    return (
        <>
            <div className="pending-cheques__details_container">
                <div className="ack_number">Ack no. : {ackNo}</div>
                <div className="details_container_row1">
                    <div className="cheque-details__col-1">
                        <div className="cheque-details-col">
                            <div className="margin-right-det-col-cheque-first my-2">
                                <p className="cheque-details__label mb-0">UTR Code</p>
                                <p className="cheque-details__label-1 mb-0">
                                    {utrCode}{" "}
                                    <span className="view-cheque-link" onClick={handleViewSlip}>
                                        View slip
                                    </span>
                                </p>
                                <p className="cheque-details__label-2 mb-0">Entry date: {entryDate ? moment(entryDate).format("MMM DD, YYYY") : "-"}</p>
                            </div>
                            <div className="margin-left-det-col-cheque-first my-2">
                                <p className="cheque-details__label mb-0">Payment Partner</p>
                                <p className="cheque-details__label-1 mb-0">{paymentPartner || "-"}</p>
                                <p className="cheque-details__label-2 mb-0">
                                    Transfer date: {transferDate ? moment(transferDate).format("MMM DD, YYYY") : "-"}
                                </p>
                            </div>
                        </div>

                        <div className="student-details-col">
                            <div className="margin-right-det-col-cheque-first my-2">
                                <p className="cheque-details__label mb-0">Student ID</p>
                                <p className="cheque-details__label-1 mb-0">{studentID || "-"}</p>
                                <p className="cheque-details__label-2 mb-0">
                                    Centre:{" "}
                                    {centreName && centreName.length > 11 ? (
                                        <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={centreName}>
                                            <span style={{ cursor: "pointer" }}>{centreName.substring(0, 11) + "..."}</span>
                                        </BootstrapTooltip>
                                    ) : (
                                        centreName || "-"
                                    )}
                                </p>
                            </div>
                            <div className="margin-left-det-col-cheque-first my-2">
                                <p className="cheque-details__label mb-0">Student name</p>
                                <p className="cheque-details__label-1 mb-0">{studentName || "-"}</p>
                                <p className="cheque-details__label-2 mb-0">Phone number : {`${studentMobile ? `+91 ${studentMobile}` : "-"}`}</p>
                            </div>
                        </div>
                    </div>

                    <div className="cheque-details__col-2">₹ {currencyFormatter(amount, false)}</div>
                </div>

                <div className="details_container_row2">
                    <p className="update-cheque__heading mb-0">Update deposit</p>

                    <div className="cheque-form-container">
                        <div className="form-container__col-first">
                            <div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown">
                                <p className="cheque_filter_drop_header mb-1">
                                    Status <Asterisk />
                                </p>

                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select status"
                                    showArrow
                                    allowClear
                                    optionFilterProp="children"
                                    value={selectedReason || undefined}
                                    onChange={(value) => {
                                        setSelectedReason(value);
                                    }}
                                >
                                    {depositStatus.map((item, index) => {
                                        return (
                                            <Option key={index} value={item}>
                                                {item}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {/* {chapterError.subject_assoc && <ErrorText text={chapterError.subject_assoc} />} */}
                            </div>

                            <div className="cheque-status-select-container margin-left-det-col my-2">
                                {selectedReason === "Failed" ? (
                                    <>
                                        <p className="cheque_filter_drop_header mb-1">
                                            Reason for failure <Asterisk />
                                        </p>
                                        <input
                                            className={`change_pw_input`}
                                            placeholder="Enter reason"
                                            value={paymentDeclineReason}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 300) {
                                                    setPaymentDeclineReason(e.target.value);
                                                    setErrorText("");
                                                } else {
                                                    setErrorText("Max limit of 300 characters reached");
                                                    if (errorTimeoutRef.current !== null && errorTimeoutRef.current <= 300) {
                                                        clearTimeout(errorTimeoutRef.current);
                                                    }
                                                    errorTimeoutRef.current = setTimeout(() => {
                                                        setErrorText("");
                                                    }, 1200);
                                                }
                                            }}
                                        />
                                        {errorText && <p className="reject_reason_error">{errorText}</p>}
                                    </>
                                ) : (
                                    <></>
                                )}

                                {selectedReason === "Paid" ? (
                                    <>
                                        <p className="cheque_filter_drop_header mb-1">
                                            Amount receiving date <Asterisk />
                                        </p>
                                        <DatePicker
                                            allowClear={false}
                                            className="attendance_date_picker mb-0"
                                            getPopupContainer={(triggerNode) => {
                                                return triggerNode.parentNode;
                                            }}
                                            placeholder="DD/MM/YYYY"
                                            // style={{ width: "100%" }}
                                            format={"DD/MM/YYYY"}
                                            onChange={(date) => setPaymentReceiveDate(date)}
                                            value={paymentReceiveDate}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <div className="cheque-button-container" style={{ marginTop: "2.8rem" }}>
                            <button
                                className="btn submit-btn-pending-cheque"
                                disabled={
                                    (selectedReason === "Paid" && paymentReceiveDate !== "") || (selectedReason === "Failed" && paymentDeclineReason !== "")
                                        ? false
                                        : true
                                }
                                onClick={handleSubmitClick}
                            >
                                Submit
                                {loading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ViewSlipModal
                handleViewSlip={handleViewSlip}
                name={studentName}
                date={entryDate}
                mobileNumber={studentMobile}
                ChequePhoto={slipPhoto ? `${zinedus3}${slipPhoto}` : ""}
                open={viewSlipModal}
                dataKey="utr"
                dataValue={utrCode}
            />
            
        </>
    );
};

export default DepositsDetails;
