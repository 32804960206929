import React, { useState, useCallback } from 'react'
import { Asterisk } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { Select } from 'antd';
import { Modal, Box } from '@mui/material';
import Api from "../../common/api";
import ApiUrl from '../../common/apiUrl';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function currencyFormatter(input) {
    if (input) {
        return (Number(input)).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0
        })
    } else {
        return 0;
    }
}

const Hdfc = ({ 
    paymentAmount, 
    studentName = "",
    sectionSchoolID,
    hostel_id,
    student_id,
    location,
    type,
    studentDetails
}) => {

    const navigate = useNavigate();
    
    const [appr_code, set_appr_code] = useState("");

    const [formError, setFormError] = useState({});
    const [openSumbitModal, setOpenSumbitModal] = useState(false)

    const checkFormHasErrors = () => {
        let flag = false;
        let temp = {};
        // if (appr_code === null || appr_code === '') {
        //     temp = { ...temp, appr_code: "please enter reference number" }
        //     flag = true;
        // }
        setFormError(temp);
        return flag;

    }

    const [loading, setLoading] = useState(false);

    const makeChequePayment = async () => {

        let formdata = new FormData();

        formdata.append("payment_type", "hdfc/ccavenue");
        formdata.append("amount", paymentAmount);

        if (type === "C") {
            formdata.append("section_subject_id", sectionSchoolID);
        }

        if (type === "H") {
            formdata.append("hostel_id", hostel_id);
        }

        setLoading(true);

        const data = await Api.doUpload("POST", formdata, type === "C" ? `${ApiUrl.RECEIVE_PAYMENT}${student_id}/` : `${ApiUrl.RECEIVE_HOSTEL_PAYMENT}${student_id}/`);

        setLoading(false);

        if (data && data.status) {
            // if (type === "C") {
            //     navigate(`/payment/paymentSuccess`, {
            //         state: {
            //             payment_id: data.payload.id,
            //             section_id: location.state.section_id,
            //             school_id: location.state.school_id,
            //             student_id: student_id
            //         }
            //     })
            // } else if (type === "H") {
            //     navigate(`/payment/payment-success-hostel`, {
            //         state: {
            //             payment_id: data.payload.id,
            //             section_id: studentDetails.student_data.section_assoc.id,
            //             school_id: studentDetails.student_data.school_assoc.id,
            //             student_id: student_id
            //         }
            //     });
            // }
            console.log(data?.payload);
            window.location.href = data?.payload;
            
        }  else {
            if (data && data.error_code === 2001) {
                Swal.fire("Error!", data.message, "error");
            } else {
                if (type === "C") {
                    navigate(`/payment/paymentFailed`, {
                        state: {
                            student_id: student_id,
                            section_id: location.state.section_id,
                            school_id: location.state.school_id,
                            school_name: location.state.school_name,
                            section_name: location.state.section_name,
                        },
                        replace: true
                    }) 
                } else if (type === "H") {
                    navigate(`/payment/payment-failed`, {
                        state: { student_id: student_id }
                    })
                }  
            }
        }

    }

    return (
        <>
            <div className="margin_Bottom">
                <p className="student_filter_drop_header">Payment Amount:</p>

                <h3>{currencyFormatter(paymentAmount)}</h3>
            </div>

            <div className="margin_Bottom mt-5">
                <button 
                    className={'confirmBtn'} 
                    onClick={() => {
                        if (!checkFormHasErrors() && !loading) {
                            setOpenSumbitModal(!openSumbitModal);
                        }
                    }}
                >
                    Confirm
                    {loading && <span className="ml-2 spinner spinner-white mr-3"></span>}
                </button>
            </div>


            <Modal open={openSumbitModal}>
                <Box>
                    <div className="view_cheque_modal d-column ">
                        <span className="bank_deposit_modal_heading" >Are you sure?</span>
                        <span className="bank_deposit_modal_heading small ">Receiving ₹{paymentAmount} as hdfc/ccavenue from {studentName}</span>
                        <div
                            className="mt-3 d-flex justify-content-flex-end"
                            style={{ float: "left" }}
                        >
                            <button
                                className="btn btn-md student_detail_no"
                                onClick={() => { 
                                    setOpenSumbitModal(!openSumbitModal);
                                }}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-md student_detail_password_confirm ml-2"
                                onClick={() => {
                                    setOpenSumbitModal(!openSumbitModal);
                                    makeChequePayment();
                                }}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}

export default Hdfc;