// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD1fl6jUhneqAEYndCerRserrnoPiooypA",
    authDomain: "myclassroom-83bfb.firebaseapp.com",
    projectId: "myclassroom-83bfb",
    storageBucket: "myclassroom-83bfb.appspot.com",
    messagingSenderId: "310671691158",
    appId: "1:310671691158:web:aea9939de9381e9d80e1b7",
    measurementId: "G-B1S3H8R9JD"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const messaging = getMessaging(app);
export { db, analytics, messaging };
