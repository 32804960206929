import React, { useState, useEffect, useCallback, useRef } from 'react'
import LeftArrow from "../../../assets/img/left_arrow.svg";
import SelectWithTooltip from "../../common/component/SelectWithTooltip"
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CloseImg from "../../../assets/img/close.svg";
import MppModal from './components/mppScheduleModal'
import MppAccordianComponent from './components/AccordianComponent';
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import { useScrollingList } from "../../../utils/hooks/use-scrolling-list";
import { SCRL_HANDLE_SEARCH_INIT, SCRL_RESET_LIST } from '../../../actions/types';
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../common/Loader";
import Empty from "../../../assets/img/empty_student.svg";
import ToastMessage from '../../common/toastMessage';
import { toast } from "react-toastify";

const MppAssign = () => {
    const initialState = {
        selectedCentre: [],
        selectedCourse: [],
        centreList: [],
        courseList: [],
        selectedBatches: [],
    }
    const { limit } = useSelector((state) => state.scrollingList);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const mppIDs = location?.state?.mppIds || []
    const fromScheduleHistory = location?.state?.fromScheduleHistory
    const fromList = location?.state?.fromList

    const [showModal, setShowModal] = useState(false)
    const [assignState, setAssignState] = useState({ ...initialState })
    const SelectedCentreRef = useRef(assignState.selectedCentre)
    const SelectedCourseRef = useRef(assignState.selectedCourse)
    const [noData, setNoData] = useState(false)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [filterLoading, setFilterLoading] = useState(false);
    const [mppSummary, setMppSummary] = useState(null);
    const [scheduleModalLoading, setScheduleModalLoading] = useState(false);


    const handleState = (payload) => {
        setAssignState((state) => { 
            // console.log('state', state, payload)
            return { ...state, ...payload } 
        })
    }
    const ClearFilter = () => {
        handleState({ selectedCentre: [], selectedCourse: [], selectedBatches: [], courseList: [] })
        SelectedCentreRef.current = []
        SelectedCourseRef.current = []
        // handleState({ courseList: [] })
        setIsFilterApplied(false)
    }
    const checkAllSelected = () => {
        let allChecked = false
        batchList?.map((course) => {
            allChecked = course.batches.every((batch) => { return assignState.selectedBatches.some((selectedBatches) => batch.batch_id === selectedBatches.batch_id) })
        })
        if (allChecked === false) {
            return;
        }
        return allChecked;
    }

    const getCentreList = async () => {
        let url = `${ApiUrl.MPP}/schedule/centres`;
        const params = {
            selected_mpp_ids: mppIDs
        }
        const data = await Api.doFetch("POST", params, url);
        if (data.status) {
            const payload = data.data
            if (payload) {
                handleState({ centreList: payload.sort((a, b) => a.centre_name.localeCompare(b.centre_name)) })
            }
        }
    };

    const getCourseList = async () => {
        let url = `${ApiUrl.MPP}/schedule/courses`;
        const params = {
            filtered_centre_ids: assignState.selectedCentre,
            selected_mpp_ids: mppIDs
        }
        const data = await Api.doFetch("POST", params, url);
        if (data.status) {
            const payload = data.data
            if (payload) {
                handleState({ courseList: payload.sort((a, b) => a.course_name.localeCompare(b.course_name)) })
            }
        }
    };

    const getBatches = useCallback(async ({ skip = 0, limit = 10 }) => {
        let url = `${ApiUrl.MPP}/schedule`;
        let postData = {
            "is_filter": true,
            "skip": skip,
            "limit": limit,
            "selected_mpp_ids": mppIDs
        }
        if (SelectedCentreRef && SelectedCentreRef.current.length > 0) {
            postData.centre_ids = SelectedCentreRef.current
        }
        if (SelectedCourseRef && SelectedCourseRef.current.length > 0) {
            postData.course_ids = SelectedCourseRef.current
        }

        const data = await Api.doFetch("POST", postData, url);

        if (data?.status === true) {
            const payload = data.data;
            if (payload) {
                const { batches: items, total, skip, limit } = payload;
                return {
                    items,
                    total,
                    skip,
                    limit,
                };
            }
            return data.data.batches;
        }
        setNoData(true);
        return null;
    }, []);

    const [scrollContainer, { loading, scrollLoading, list: batchList, handleScroll, }] = useScrollingList({
        listKey: "mpp_batches",
        getData: getBatches,
    });

    const getFilteredBatches = async () => {
        setFilterLoading(true);
        let payload = {
            skip: 0,
            limit: limit,
        }
        const data = await getBatches({ ...payload });

        dispatch({
            type: SCRL_HANDLE_SEARCH_INIT,
            payload: {
                list: (data && data.items) || [],
                skip: 0,
                total: (data && data.total) || 0,
                scrollLoading: false,
            },
        });

        setFilterLoading(false);
    }

    const postMppData = async (tempAssignState = { ...assignState }) => {
        // console.log('postMppData', tempAssignState)
        setScheduleModalLoading(true)
        let url = `${ApiUrl.MPP}/schedule`;
        let postData = {
            "is_preview": true,
            "selected_batches": tempAssignState.selectedBatches,
            "exam_ids": mppIDs,
        }
        if (assignState.selectedCentre.length) {
            postData = {
                ...postData,
                centre_ids: assignState.selectedCentre
            }
        }
        const data = await Api.doFetch("POST", postData, url);

        if (data?.status === true) {
            let payload = data.data
            setMppSummary(payload)
        } else {
            setMppSummary({
                ...mppSummary,
                selected_batches: []
            })
        }
        setScheduleModalLoading(false)
    }

    const scheduleMppData = async () => {
        setScheduleModalLoading(true)
        setFilterLoading(true)
        let url = `${ApiUrl.MPP}/schedule`;
        let postData = {
            "is_schedule": true,
            "selected_batches": mppSummary.selected_batches,
            "exam_ids": mppIDs,
        }
        // console.log('params', postData)
        const data = await Api.doFetch("POST", postData, url);

        if (data?.status === true) {
            setShowModal(false)
            setAssignState(state => { return { ...initialState, centreList: state.centreList } })
            toast(<ToastMessage text="MPPs scheduled successfully." />)
            setTimeout(() => {
                navigate("/mpp/list")
                setFilterLoading(false)
            }, 1000)
        } else {
            setShowModal(false)
            setFilterLoading(false)
            toast(<ToastMessage type="error" text={data.message} />)
        }
        setScheduleModalLoading(false)
    }

    useEffect(() => {
        if (mppIDs.length > 0) {
            getCentreList()
        } else {
            navigate("/dashboard")
        }
    }, [])


    useEffect(() => {
        if (assignState.selectedCentre.length > 0) {
            getCourseList()
        }
    }, [assignState.selectedCentre])

    useEffect(() => {
        // console.log('mppSummary', mppSummary, assignState.selectedBatches)
        const { selected_batches } = mppSummary || {}
        const tempSelectedBatches = assignState.selectedBatches
        // console.log('before', tempSelectedBatches)
        const tempIds = []
        if (selected_batches?.length > 0) {
            selected_batches?.map(x => {
                x.schools?.map(y => {
                    y.batches?.map(z => {
                        tempIds.indexOf(z.batch_id) == -1 && tempIds.push(z.batch_id)
                    })
                })
            })

            tempSelectedBatches.map(item => item.batch_id).map((item, index) => {
                let i = tempIds.indexOf(item)
                if (i == -1) {
                    tempSelectedBatches.splice(index, 1)
                }
            })
            // console.log('tempSelectedBatches', tempSelectedBatches)
            handleState({ selectedBatches: tempSelectedBatches })
        }
    }, [mppSummary])

    return (
        <>
            <div className="course_list_container antd_dropdown">
                {mppSummary && <MppModal open={showModal} setShowModal={setShowModal} data={mppSummary} setData={setMppSummary} scheduleMpp={scheduleMppData} assignState={assignState} handleState={handleState} postMppData={postMppData} loading={scheduleModalLoading} setLoading={setScheduleModalLoading} />}
                <div className="reversal_detail_header  py-3 mb-3 d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            if (!fromList) {
                                if (fromScheduleHistory) {
                                    navigate(`/mpp/schedule-history/${mppIDs[0]}`)
                                } else {
                                    navigate(`/mpp/${mppIDs[0]}`)
                                }
                            } else {
                                navigate(`/mpp/list`)
                            }
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">
                        Assign batches
                    </span>

                </div>

                <div className=" d-flex course_list_header justify-content-flex-start">
                    <div className="mpp_divider3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Centre</p>

                        <div className="antd_dropdown" >
                            <SelectWithTooltip
                                value={assignState.selectedCentre}
                                setValue={item => {
                                    let index = item.indexOf("Select All");
                                    if (index >= 0) {
                                        handleState({ selectedCentre: assignState.centreList.map((x) => x.centre_id) })
                                    } else {
                                        handleState({ selectedCentre: item })
                                    }
                                }
                                }
                                data={assignState.centreList}
                                placeholder={"Select centre"}
                                keyValue={"centre_name"}
                                keyID={'centre_id'}
                                selectAll={assignState.centreList?.length > 0 && true}
                            />
                        </div>
                    </div>

                    <div className="mpp_divider3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Course</p>

                        <div className="antd_dropdown" >
                            <SelectWithTooltip
                                value={assignState.selectedCourse}
                                setValue={item => {
                                    let index = item.indexOf("Select All");
                                    if (index >= 0) {
                                        handleState({ selectedCourse: assignState.courseList.map((x) => x.course_id) })
                                    } else {
                                        handleState({ selectedCourse: item })
                                    }
                                }
                                }
                                data={assignState.courseList}
                                placeholder={"Select course"}
                                keyValue={"course_name"}
                                keyID={'course_id'}
                                selectAll={assignState.courseList?.length > 0 && true}
                            />


                        </div>
                    </div>


                    <div className="my-2 padding_top">
                        <button
                            className="btn cheques_apply-filter-button mr-2"
                            onClick={() => {
                                if (assignState.selectedCentre.length !== 0 || assignState.selectedCourse.length !== 0) {
                                    SelectedCentreRef.current = assignState.selectedCentre
                                    SelectedCourseRef.current = assignState.selectedCourse
                                    getFilteredBatches()
                                    handleState({ selectedBatches: [] })
                                    setIsFilterApplied(true)
                                }
                            }}
                        //   disabled={(filterLoading || !applyFilter()) ? true : false}
                        >
                            Apply
                        </button>

                        <span onClick={() => {
                            ClearFilter()
                            getFilteredBatches()
                            setIsFilterApplied(false)
                        }

                        } className="clear-filter-cheques ml-2" >Clear Filter</span>
                    </div>
                </div>


                {loading || filterLoading ? (
                    <div className="loader_container">
                        <Loader />
                    </div>
                ) : batchList && batchList.length === 0 ? (
                    <div className="student_search_empty_container mt-5">
                        <div className="student_search_empty_img_container">
                            <img src={Empty} alt="not found" className="student_search_empty_img" />
                        </div>
                        {
                            noData ?
                                <p className="student_search_empty_header">
                                    No mpps found
                                </p>
                                :
                                <>
                                    <p className="student_search_empty_header">
                                        No results found
                                    </p>
                                    <span className="student_search_empty_label">
                                        Try adjusting your search to find
                                    </span>
                                    <span className="student_search_empty_label">
                                        the best results
                                    </span>
                                </>
                        }
                    </div>
                ) :
                    <div className='mpp_assign_container'>
                        {isFilterApplied && <div className=" mpp_assign_card mb-1 d-flex align-items-center" >
                            <input
                                className="form-check-input assign-question-checkbox"
                                type="checkbox"
                                checked={checkAllSelected()}
                                style={{ cursor: "pointer", marginRight: 15 }}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        let temp = batchList.map((course) => {
                                            return course.batches.map((batch) => {
                                                let temp = {}
                                                temp.course_id = course.course_id
                                                temp.course_name = course.course_name
                                                temp.batch_id = batch.batch_id
                                                temp.batch_name = batch.batch_name
                                                return temp;
                                            })
                                        })
                                        let allSelectedBatches = [].concat.apply([], temp);

                                        handleState({ selectedBatches: allSelectedBatches })
                                    } else {
                                        handleState({ selectedBatches: [] })
                                    }



                                }}
                            />
                            <span className="group_modal_text"> Select All</span>
                        </div>}
                        <div
                            className={`mpp_assign_scroll_container ${!isFilterApplied && 'big'} d-column pb-1 mb-1`}
                            ref={scrollContainer}
                            onScroll={handleScroll}
                        >
                            {batchList && batchList.map((batch, index) => {
                                return <MppAccordianComponent key={index} data={batch} selectedBatches={assignState.selectedBatches} handleState={handleState} />
                            })}
                            {
                                !loading && scrollLoading &&
                                <div className="loader_scroll_container">
                                    <Loader />
                                </div>
                            }
                        </div>
                    </div>}

            </div>

            {!loading && !filterLoading && <div className="footer footer-attendance" style={{ width: '100%' }} >
                <div className="d-flex align-items-center">
                    {assignState.selectedBatches.length > 0 && <div>
                        <span className='course_list_card_header' style={{ paddingLeft: 25 }}>Assigned batches</span>
                        <div className='d-flex-wrap ml-2 pb-1'>
                            {assignState.selectedBatches.slice(0, 2).map((batch, index) => {
                                return <div key={index + 100000} className="mpp_batch">
                                    <span className="modal_save_confimation_value">
                                        {batch.batch_name}
                                    </span>
                                    <img
                                        src={CloseImg}
                                        className="cursor-pointer ml-1"
                                        alt="close"
                                        onClick={() => {
                                            let temp = assignState.selectedBatches;
                                            temp = temp.filter((selectedBatch) => batch.batch_id !== selectedBatch.batch_id)
                                            handleState({ selectedBatches: temp })
                                        }}
                                    />
                                </div>
                            })}
                            {assignState.selectedBatches.length > 2 && <div className="mpp_batch"> + {assignState.selectedBatches.length - 2}</div>}
                        </div>

                    </div>}

                </div>

                <div className="course_list_card justify-content-between">
                    <span onClick={() => {
                        handleState({ selectedBatches: [] })

                    }}
                        className="clear-filter-cheques" style={{ fontSize: 18 }} >Clear selections</span>
                    <button
                        disabled={assignState.selectedBatches.length === 0}
                        onClick={() => {
                            postMppData()
                            setShowModal(true);
                        }}
                        className="btn mpp_assign_button"
                    >
                        Show summary
                    </button>
                </div>


            </div>}

        </>
    )
}

export default MppAssign