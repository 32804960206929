import React, { useState, useCallback } from 'react'
import { Asterisk } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { Select, DatePicker } from 'antd';
import { Modal, Box } from '@mui/material';
import Api from "../../common/api";
import ApiUrl from '../../common/apiUrl';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const { Option } = Select;
const programOptions = ["Financepeer"];

function currencyFormatter(input) {
    if (input) {
        return (Number(input)).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0
        })
    } else {
        return 0;
    }
}

const NoCostEMI = ({ 
    paymentAmount, 
    studentName = "",
    sectionSchoolID,
    hostel_id,
    student_id,
    location,
    type,
    studentDetails
}) => {

    const navigate = useNavigate(); 

    const [financeName, setFinanceName] = useState(null)
    const [loanNumber, setLoanNumber] = useState(null)
    // const [selectedDate, setSelectedDate] = useState(moment());
    const [approvalLetterImage, setApprovalLetterImage] = useState(null);
    const [formError, setFormError] = useState({});
    const [openSumbitModal, setOpenSumbitModal] = useState(false)

    const handleFileDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length === 1 && rejectedFiles.length === 0) {
            console.log(acceptedFiles[0])
            // Accepted file
            setFormError({ ...formError, approvalLetterImage: null });
            setApprovalLetterImage(acceptedFiles[0]);
        } else {
            if (rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors && rejectedFiles[0].errors.length > 0 && rejectedFiles[0].errors[0].code === "file-too-large") {
                setFormError({ ...formError, approvalLetterImage: <span>The image size is larger than <strong>10 MB</strong>. Please reduce the image size.</span> });

            } else {
                setFormError({ ...formError, approvalLetterImage: <span>Please upload a <strong>.png</strong> or <strong>.jpg</strong> image</span> });

            }
            // setFormError({ ...formError, approvalLetterImage: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getInputProps, getRootProps } = useDropzone({
        multiple: false,
        maxSize: 10.01 * 1024 * 1024, // 10 MB
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
        onDrop: handleFileDrop,
    });

    const checkFormHasErrors = () => {
        let flag = false;
        let temp = {};
        if (financeName === null || financeName === '') {
            temp = { ...temp, financeName: "Enter valid finance name" }
            flag = true;
        }
        if (loanNumber === null || loanNumber === '' || !loanNumber.trim()) {
            temp = { ...temp, loanNumber: "Enter valid loan number" }
            flag = true;
        }
        if (approvalLetterImage === null) {
            temp = { ...temp, approvalLetterImage: "Please upload approval letter image" }
            flag = true;
        }
        setFormError(temp);
        return flag;

    }

    const [loading, setLoading] = useState(false);

    const makeNoCostEMIPayment = async () => {

        let formdata = new FormData();
        formdata.append("finance_company", financeName);
        formdata.append("loan_number", loanNumber);
        // formdata.append("transfer_date", moment(selectedDate).format("YYYY-MM-DD"));
        formdata.append("loan_amount", paymentAmount);
        formdata.append("approval_letter", approvalLetterImage);
        formdata.append("payment_for", type);

        if (type === "C") {
            formdata.append("section_school_id", sectionSchoolID);
        }

        if (type === "H") {
            formdata.append("hostel_id", hostel_id);
        }

        setLoading(true);

        const data = await Api.doUpload("POST", formdata, `${ApiUrl.NO_COST_EMI}${student_id}`);

        setLoading(false);

        if (data && data.status) {
            if (type === "C") {
                navigate(`/payment/paymentSuccess`, {
                    state: {
                        payment_id: data.data.ack_no,
                        section_id: location.state.section_id,
                        school_id: location.state.school_id,
                        student_id: student_id
                    }
                })
            } else if (type === "H") {
                navigate(`/payment/payment-success-hostel`, {
                    state: {
                        payment_id: data.data.ack_no,
                        section_id: studentDetails.student_data.section_assoc.id,
                        school_id: studentDetails.student_data.school_assoc.id,
                        student_id: student_id
                    }
                });
            }
            
        }  else {
            if (data && data.error_code === 2001) {
                Swal.fire("Error!", data.message, "error");
            } else {
                if (type === "C") {
                    navigate(`/payment/paymentFailed`, {
                        state: {
                            student_id: student_id,
                            section_id: location.state.section_id,
                            school_id: location.state.school_id,
                            school_name: location.state.school_name,
                            section_name: location.state.section_name,
                        },
                        replace: true
                    }) 
                } else if (type === "H") {
                    navigate(`/payment/payment-failed`, {
                        state: { student_id: student_id }
                    })
                }  
            }
        }

    }

    return (
        <>
            <div className="margin_Bottom antd_dropdown student_filter_drop_header">
                <p className="student_filter_drop_header">Finance company name <Asterisk /></p>
                <Select
                    style={{ width: '100%' }}
                    placeholder="Select finance company name"
                    onChange={value => {
                        setFormError({ ...formError, financeName: null })
                        setFinanceName(value || "")
                    }}
                    value={financeName || undefined}
                    showArrow
                    allowClear
                    showSearch
                >
                    {
                        programOptions.map((options, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={options}
                                >{options}</Option>
                            )
                        })
                    }

                </Select>
                {
                    formError.financeName &&
                    <ErrorText
                        text={formError.financeName}
                    />
                }
            </div>

            <div className="margin_Bottom">
                <p className="student_filter_drop_header">Loan number <Asterisk /></p>

                <input
                    maxLength="50"
                    className={`change_pw_input ${formError.loanNumber ? "change_pw_input_error" : ""}`}
                    placeholder="Enter loan number"
                    value={loanNumber || ""}
                    onChange={e => {
                        setFormError({ ...formError, loanNumber: null })
                        setLoanNumber(e.target.value)
                    }}
                />
                {
                    formError.loanNumber &&
                    <ErrorText
                        text={formError.loanNumber}
                    />
                }
            </div>

            {/* <div className="margin_Bottom">
                <p className="student_filter_drop_header">Transfer Date <Asterisk /></p>

                <DatePicker
                    allowClear={false}
                    getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                    }}
                    className="attendance_date_picker"
                    format={"DD/MM/YYYY"}
                    value={selectedDate}
                    onChange={date => {
                        setSelectedDate(date);
                    }}
                />
            </div> */}

            <div className="margin_Bottom">
                <p className="student_filter_drop_header">Loan Amount:</p>

                <h3>{currencyFormatter(paymentAmount)}</h3>
            </div>

            <div className="margin_Bottom upCheque" {...getRootProps()}>
                <p className="student_filter_drop_header">Upload Screenshot of loan approval letter  <Asterisk /></p>
                <div className="d-flex btnWrap">
                    {/* <button className="camertaBtn"><img src={cameraIcon} /></button> */}
                    <div className="upbtn"><input className="fileup" {...getInputProps()} type="file" /> </div>
                </div>
                {(!approvalLetterImage && !formError.approvalLetterImage) && <span>Please upload .Jpg, .Png files upto 10Mb<br /></span>}

                {
                    formError.approvalLetterImage &&
                    <ErrorText
                        text={formError.approvalLetterImage}
                    />
                }

            </div>
            {
                approvalLetterImage &&
                <div className="form-group cheque-m">
                    <h5>
                        <i className="fa fa-check-double" style={{ color: "#2CAA00" }}></i>
                        {
                            approvalLetterImage.name.length > 25 ?
                            `${approvalLetterImage.name.substring(0, 25)}...`
                            :
                            approvalLetterImage.name
                        }
                    </h5>
                </div>
            }


            <div className="margin_Bottom">
                <button 
                    className={'confirmBtn'} 
                    onClick={() => {
                        if (!checkFormHasErrors() && !loading) {
                            setOpenSumbitModal(!openSumbitModal);
                        }
                    }}
                >
                    Confirm
                    {loading && <span className="ml-2 spinner spinner-white mr-3"></span>}
                </button>
            </div>


            <Modal open={openSumbitModal}>
                <Box>
                    <div className="view_cheque_modal d-column ">
                        <span className="bank_deposit_modal_heading" >Are you sure?</span>
                        <span className="bank_deposit_modal_heading small ">Receiving ₹{paymentAmount} as no cost emi from {studentName}</span>
                        <div
                            className="mt-3 d-flex justify-content-flex-end"
                            style={{ float: "left" }}
                        >
                            <button
                                className="btn btn-md student_detail_no"
                                onClick={() => { 
                                    setOpenSumbitModal(!openSumbitModal);
                                }}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-md student_detail_password_confirm ml-2"
                                onClick={() => {
                                    setOpenSumbitModal(!openSumbitModal);
                                    makeNoCostEMIPayment();
                                }}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}

export default NoCostEMI;