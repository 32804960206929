import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import BasicDetails from "./basicDetails";
import ToastMessage from "../../common/toastMessage";
import { toast } from "react-toastify";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import swal from "sweetalert2";
import NotFound from "../../common/notFound";
import Loader from "../../common/Loader";
import Confirmation from "./confirmation";
import { useDispatch, useSelector } from "react-redux";
import { SCRL_UPDATE_LIST } from "../../../actions/types";


const AddSubject = ({
    defaultView = false
}) => {
    const dispatch = useDispatch();
    const subjectState = useSelector(state => state.scrollingList);
    const { subjectID } = useParams();

    const navigate = useNavigate();

    const [view, setView] = useState(false);

    const [createModal, setCreateModal] = useState(false);

    const [subjectData, setSubjectData] = useState({
        subject_name: "",
        is_main_subject: false
    })

    const {
        subject_name,
        is_main_subject
    } = subjectData;

    const [subjectError, setSubjectError] = useState({})

    const closeCreateModal = () => {
        setCreateModal(false);
    }

    const [updateModal, setUpdateModal] = useState(false);

    const closeUpdateModal = () => {
        setUpdateModal(false);
    }

    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    const [dataDump, setDataDump] = useState({});

    const getSubjectDetails = async () => {

        setLoading(true);

        const data = await Api.doFetch("GET", {}, `${ApiUrl.SUBJECT}/${subjectID}`);

        setLoading(false);

        if (data && data.status) {
            setSubjectData({
                subject_name: data.data.subject_name,
                is_main_subject: data.data.is_main_subject
            })
            setDataDump(data.data);

            return data.data;
        } else {
            if (data && data.error_code && data.error_code === 1001) {
                setNotFound(true);
            } else if (data && data.message) {
                swal.fire("Error!", data.message, "error");
            } else {
                swal.fire("Error!", "Internal Server Error", "error");
            }
        }
    }

    const [updateLoading, setUpdateLoading] = useState(false);

    const checkSubmit = () => {
        let flag = true;

        if (!(subject_name && subject_name.trim())) {
            setSubjectError({
                subject_name: "Please enter subject name"
            })
            flag = false;
        }

        return flag;
    }

    const updateSubject = async () => {

        setUpdateLoading(true);

        const data = await Api.doFetch(subjectID ? "PATCH" : "POST", {
            subject_name: subject_name.trim(),
            is_main_subject
        }, subjectID ? `${ApiUrl.SUBJECT}/update/${subjectID}` : `${ApiUrl.SUBJECT}/add`);

        setUpdateLoading(false);

        if (data && data.status) {
            toast(<ToastMessage text={data.message} />);

            if (subjectID) {
                const subjectRefreshedData = await getSubjectDetails();

                if (subjectRefreshedData) {
                    const subjectList = subjectState.listKey ? subjectState[subjectState.listKey] || [] : [];

                    const result = Array.from(subjectList);
                    const selectedSubjectIndex = result.findIndex(sub => sub.id === subjectRefreshedData.id);

                    if (selectedSubjectIndex > -1) {
                        result[selectedSubjectIndex].subject_name = subjectRefreshedData.subject_name;
                        result[selectedSubjectIndex].is_main_subject = subjectRefreshedData.is_main_subject;

                        dispatch({
                            type: SCRL_UPDATE_LIST,
                            payload: result,
                        });
                    }
                }
            } else {
                setSubjectData({
                    subject_name: "",
                    is_main_subject: true
                });
            }

            // setTimeout(() => {
            //     if (subjectID) {
            //         navigate("/subject/list");
            //     } else {
            //         navigate("/subject");
            //     }
            // }, 1500)
        } else {
            if (data && data.error_code && data.error_code === 1001) {
                setNotFound(true);
            } else if (data && data.message) {
                swal.fire("Error!", data.message, "error");
            } else {
                swal.fire("Error!", "Internal Server Error", "error");
            }
        }

    }

    useEffect(() => {
        if (subjectID) {
            getSubjectDetails();
        }
    }, [subjectID])

    const checkUserHasUpdated = () => {
        let hasChanged = [];

        if (dataDump && dataDump.subject_name && subject_name && dataDump.subject_name.trim() !== subject_name.trim()) {
            hasChanged.push({
                header: "Subject name",
                value: subject_name.trim()
            })
        }
        if (dataDump && dataDump.is_main_subject !== is_main_subject) {
            hasChanged.push({
                header: "Main subject",
                value: is_main_subject ? "Yes" : "No"
            })
        }

        return hasChanged;
    }

    if (notFound) {
        return <NotFound />
    }

    return (
        <div className="course_add_container antd_dropdown">

            <Confirmation
                updateLoading={updateLoading}
                createModal={createModal}
                closeCreateModal={closeCreateModal}
                updateModal={updateModal}
                closeUpdateModal={closeUpdateModal}
                subject_name={subject_name}
                updateSubject={updateSubject}
                checkUserHasUpdated={checkUserHasUpdated}
            />

            <div className="reversal_detail_header justify-content-between">

                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            if (subjectID) {
                                navigate("/subject/list")
                            } else {
                                navigate("/subject")
                            }
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">
                        {
                            defaultView ?
                                "Edit subject"
                                :
                                "Add subject"
                        }
                    </span>
                </div>

                {
                    view &&
                    <button
                        className="btn btn-md course_add_next"
                        onClick={() => setView(false)}
                    >
                        Edit
                    </button>
                }

            </div>

            {
                loading ?
                    <div className="loader_container">
                        <Loader />
                    </div>
                    :
                    <>
                        <BasicDetails
                            view={view}
                            subjectData={subjectData}
                            setSubjectData={setSubjectData}
                            subjectError={subjectError}
                            setSubjectError={setSubjectError}
                            subjectID={subjectID}
                        />

                        {
                            !view &&
                            <div className="mt-3 d-flex justify-content-between">
                                <button
                                    className="btn btn-md course_add_back"
                                    onClick={() => {
                                        if (defaultView) {
                                            navigate("/subject/list");
                                        } else {
                                            navigate("/subject");
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-md course_add_next"
                                    onClick={() => {
                                        if(Object.values(subjectError).filter(x => x).length > 0){
                                            return;
                                        }
                                        if (updateLoading) {
                                            return;
                                        }
                                        if (defaultView) {
                                            setUpdateModal(true);
                                        } else {
                                            if (checkSubmit()) {
                                                setCreateModal(true)
                                            }
                                        }
                                    }}
                                    disabled={defaultView && checkUserHasUpdated().length === 0 ? true : false}
                                >
                                    {defaultView ? "Save" : "Create"}
                                    {updateLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                                </button>
                            </div>
                        }
                    </>
            }

        </div>
    );
};

export default AddSubject;