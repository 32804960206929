import React from "react";
import AddChapterImg from "../../assets/img/add_chapter.svg";
import ListImage from "../../assets/img/list-att-icon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { SCRL_RESET_LIST } from "../../actions/types";

const Chapter = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    return (
        <div className="containerMain">
            <div className="payment_reversal_container">
                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/chapter/add");
                    }}
                >
                    <img src={AddChapterImg} alt="add course" className="mb-3" />

                    <span className="payment_reversal_container_text">Add chapter</span>
                </div>

                <div
                    className="payment_reversal_container_history"
                    onClick={() => {
                        dispatch({ type: SCRL_RESET_LIST })
                        navigate("/chapter/list");
                    }}
                >
                    <img src={ListImage} alt="subject list" className="mb-3" />

                    <span className="payment_reversal_container_text">List of chapters</span>
                </div>
            </div>
        </div>
    );
};

export default Chapter;
