import StudentImg from "../assets/img/student.svg";
import StudentSelImg from "../assets/img/student_s.svg";
import AttendanceImg from "../assets/img/attendance.svg";
import AttendanceSelImg from "../assets/img/attendance_s.svg";
import ReversalImg from "../assets/img/reversal_icon.svg";
import ReversalSelImg from "../assets/img/reversal_icon_s.svg";
import CourseImg from "../assets/img/course.svg";
import CourseSelImg from "../assets/img/course_s.svg";
import BatchImg from "../assets/img/batch.svg";
import BatchSelImg from "../assets/img/batch_s.svg";
import HubImg from "../assets/img/hub.svg";
import HubSelImg from "../assets/img/hub_s.svg";
import CentreImg from "../assets/img/centre.svg";
import CentreSelImg from "../assets/img/centre_s.svg";
import SubjectImg from "../assets/img/subject.svg";
import SubjectSelImg from "../assets/img/subject_s.svg";
import ChapterImg from "../assets/img/chapter.svg";
import ChapterSelImg from "../assets/img/chapter_s.svg";
import PaymentSelImg from "../assets/img/payment_s.svg";
import PaymentImg from "../assets/img/payment.svg";
import ChequeSelImg from "../assets/img/cheque_s.svg";
import ChequeImg from "../assets/img/cheque.svg";
import BankDepositSelImg from "../assets/img/bank_deposit_s.svg";
import BankDepositImg from "../assets/img/bank_deposit.svg";
import NoCostEMISelImg from "../assets/img/no_cost_emi_s.svg";
import NoCostEMIImg from "../assets/img/no_cost_emi.svg";
import FacultySelImg from "../assets/img/faculty_s.svg";
import FacultyImg from "../assets/img/faculty.svg";
import ReviewerSelImg from "../assets/img/reviewer_s.svg";
import ReviewerImg from "../assets/img/reviewer.svg";
import AccountantDepostSelImg from "../assets/img/accountant_deposit_s.svg";
import AccountantDepostImg from "../assets/img/accountant_deposit.svg";
import LeadSelImg from "../assets/img/lead_s.svg";
import LeadImg from "../assets/img/lead.svg";

export const navList = {
	"student list": {
		name: "Students",
		route: "/student",
		isActive: StudentSelImg,
		notActive: StudentImg,
	},
	"user list":{
		name: "Users",
		route: "/user",
		isActive: StudentSelImg,
		notActive: StudentImg,
	},
	attendance: {
		name: "Attendance",
		route: "/student/attendance",
		isActive: AttendanceSelImg,
		notActive: AttendanceImg,
	},
	"payment-reversal": {
		name: "Reversal Request",
		route: "/payment-reversal",
		isActive: ReversalSelImg,
		notActive: ReversalImg,
	},
	course: {
		name: "Course Management",
		route: "/course",
		isActive: CourseSelImg,
		notActive: CourseImg,
	},
	batch: {
		name: "Batch Management",
		route: "/batch",
		isActive: BatchSelImg,
		notActive: BatchImg,
	},
	hub: {
		name: "Hub Management",
		route: "/hub",
		isActive: HubSelImg,
		notActive: HubImg,
	},
	centre: {
		name: "Centre Management",
		route: "/centre",
		isActive: CentreSelImg,
		notActive: CentreImg,
	},
	subject: {
		name: "Subject Management",
		route: "/subject",
		isActive: SubjectSelImg,
		notActive: SubjectImg,
	},
	chapter: {
		name: "Chapter Management",
		route: "/chapter",
		isActive: ChapterSelImg,
		notActive: ChapterImg,
	},
	cheque: {
		name: "Pending cheque",
		route: "/pending-cheque",
		isActive: ChequeSelImg,
		notActive: ChequeImg,
	},
	"bank deposit": {
		name: "Pending deposits",
		route: "/pending-deposit",
		isActive: BankDepositSelImg,
		notActive: BankDepositImg,
	},
	"no cost emi": {
		name: "Pending no cost EMI",
		route: "/pending-emi",
		isActive: NoCostEMISelImg,
		notActive: NoCostEMIImg,
	},
	payment: {
		name: "Payment details",
		route: "/payment",
		isActive: PaymentSelImg,
		notActive: PaymentImg,
	},
	reviewer: {
		name: "Assign reviewer",
		route: "/assign-reviewer",
		isActive: ReviewerSelImg,
		notActive: ReviewerImg,
	},
	faculty: {
		name: "Faculty onboarding",
		route: "/faculty",
		isActive: FacultySelImg,
		notActive: FacultyImg,
	},
	"cash deposit": {
		name: "Deposit",
		route: "/cash-deposit",
		isActive: AccountantDepostSelImg,
		notActive: AccountantDepostImg,
	},
	deposit: {
		name: "Pending Cash",
		route: "/cash",
		isActive: BankDepositSelImg,
		notActive: BankDepositImg,
	},
	lead: {
		name: "Lead Management",
		route: "/lead",
		isActive: LeadSelImg,
		notActive: LeadImg,
	},
	"question bank": {
		name: "Question Bank",
		route: "/qb",
		isActive: LeadSelImg,
		notActive: LeadImg,
	},
	"user": {
		name: "User",
		route: "/user",
		isActive: StudentSelImg,
		notActive: StudentImg,
	},
	
};
