import React, { useEffect, useState, useCallback, useRef } from "react";
import CashPositionDetails from "./cashPositionDetails";
import CashPositionFilter from "./cashPositionFilter";
import CashPositionHeader from "./cashPositionHeader";
import Api from "../../../common/api";
import ApiUrl from "../../../common/apiUrl";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import FinanceLoader from "../../common/FinanceLoader";
import { currencyFormatter, getMyclassroomCentre } from "../../../../utils/helperFunctions";
import Empty from "../../../../assets/img/empty_student.svg";
import Loader from "../../../common/Loader";
import BackTop from "../../../finance/common/backTop";

const initialAllData = {
    cashOpening: 0,
    confirmedCash: 0,
    cashDeposit: 0,
    cashClosing: 0
}
const CashPosition = () => {

    const dispatch = useDispatch();

    // const { myclassroomCentre } = useSelector((state) => state.common);

    const [filterLoading, setFilterLoading] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [allData, setAllData] = useState(initialAllData);
    const [centre, setCentre] = useState([]);

    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const centreRef = useRef(centre);
    const [showDateValue, setShowDateValue] = useState({
        startDate: '',
        endDate: ''
    });
    const [loading, setLoading] = useState(true);
    const [dateError, setDateError] = useState("");

    const [cashDepositList, setCashDepositList] = useState([]);

    const getCashPosition = async () => {

        let payload = {}
        if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
            payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
            payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
            setShowDateValue({
                startDate: startDateRef.current,
                endDate: endDateRef.current
            })
        } else {
            setShowDateValue({
                startDate: '',
                endDate: ''
            })
        }

        if (centreRef && centreRef.current && centreRef.current.length > 0) {
            payload.centre_ids = centreRef.current;
        }
        const data = await Api.doFetch("POST", payload, ApiUrl.FM_CASH_POSITION);

        if (data.status) {
            const payload = data.data;
            if (payload.length) {
                setAllData({
                    cashClosing: payload.reduce((prev, curr) => prev + curr.cash_closing, 0),
                    cashDeposit: payload.reduce((prev, curr) => prev + curr.deposit_confirmed, 0),
                    cashOpening: payload.reduce((prev, curr) => prev + curr.cash_opening, 0),
                    confirmedCash: payload.reduce((prev, curr) => prev + curr.cash_confirmed, 0),
                })
                setCashDepositList(payload)
            } else {
                setAllData(initialAllData)
                setCashDepositList([])
            }
        } else {
            setAllData(initialAllData)
            setCashDepositList([])
        }

        setFilterLoading(false);
        setLoading(false);
        return null;
    };

    useEffect(() => {
        getCashPosition()
        // if (myclassroomCentre && myclassroomCentre.length === 0) {
        //     getMyclassroomCentre();
        // }
    }, [])

    const formatDate = (date) => {
        return moment(date).format("MMM DD, YYYY");
    }

    if (loading) {
        return (
            <>
                <FinanceLoader text="Loading cash position..." />
            </>
        );
    }

    return (
        <>
            <div className="course_list_container">
                <div className="pending-cheques-scroll-container">
                    <CashPositionHeader />

                    <CashPositionFilter
                        startDate={startDate}
                        endDate={endDate}
                        centre={centre}
                        setStartDate={setStartDate}
                        startDateRef={startDateRef}
                        setEndDate={setEndDate}
                        endDateRef={endDateRef}
                        setCentre={setCentre}
                        centreRef={centreRef}
                        dateError={dateError}
                        setDateError={setDateError}
                        getDetails={getCashPosition}
                        filterLoading={filterLoading}
                        setFilterLoading={setFilterLoading}
                        cashDepositList={cashDepositList}
                        allData={allData}
                    />

                    
                    {
                        filterLoading ?
                        <div className="loader_scroll_container">
                            <Loader />
                        </div>
                        :
                        cashDepositList.length == 0 ?
                        <div className="student_search_empty_container mt-5">
                            <div className="student_search_empty_img_container mt-5">
                                <img src={Empty} alt="not found" className="student_search_empty_img" />
                            </div>

                            <p className="student_search_empty_header">No Cash positions found</p>

                        </div>
                        :
                        <>
                            <div className="cash-not-confirmed-container">
                                <div className="cash-not-confirmed-inside-container" >
                                    <div className="details_container_row1 align-items-center">
                                        <div className="cash-position_divider1 my-1">
                                            {!(showDateValue.startDate && showDateValue.endDate) && <span className="selected-filter-tag-look">{formatDate(new Date(Date.now() - 86400000))}</span>}
                                        </div>
                                        {(showDateValue.startDate && showDateValue.endDate) && <>
                                            <div className="cash-position_divider3 disp-mob-none my-1">
                                                <span className="selected-filter-tag-look">{formatDate(showDateValue.startDate)}</span>
                                            </div>
                                            <div className="cash-position_divider3_big_one my-1 ml-2 mr-5">
                                                <div className="selected-filter-tag-look d-flex justify-content-around">
                                                    <div>{formatDate(showDateValue.startDate)} </div>
                                                    <div>TO </div>
                                                    <div>{formatDate(showDateValue.endDate)}</div>
                                                </div>
                                            </div>
                                            <div className="cash-position_divider3 disp-mob-none my-1">
                                                <span className="selected-filter-tag-look">{formatDate(showDateValue.endDate)}</span>
                                            </div>
                                        </>}
                                    </div>


                                    <div className="cash_position_details-container" style={{background: "white"}}>
                                        <div className="details_container_row1 align-items-center">
                                            <div className="cash-position_divider1 my-1">
                                                <p className="cheque-details__label mb-0">Centre</p>
                                                <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                                                    All
                                                </p>
                                            </div>
                                            <div className="cash-position_divider3 my-1">
                                                <p className="cheque-details__label mb-0">Cash opening</p>
                                                <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                                                    ₹ {currencyFormatter(allData.cashOpening, false)}
                                                </p>
                                            </div>
                                            <div className="cash-position_divider3 my-1">
                                                <p className="cheque-details__label mb-0">Confirmed cash</p>
                                                <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                                                    ₹ {currencyFormatter(allData.confirmedCash, false)}
                                                </p>
                                            </div>
                                            <div className="cash-position_divider3 my-1">
                                                <p className="cheque-details__label mb-0">Cash deposit</p>
                                                <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                                                    ₹ {currencyFormatter(allData.cashDeposit, false)}
                                                </p>
                                            </div>
                                            <div className="cash-position_divider3 my-1">
                                                <p className="cheque-details__label mb-0">Cash closing</p>
                                                <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "600" }}>
                                                    ₹ {currencyFormatter(allData.cashClosing, false)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {cashDepositList.map((item) => {
                                        return <CashPositionDetails key={item.centre_id} {...item} />;
                                    })}
                                </div>
                            </div>
                        </>
                    }

                    <BackTop 
                        target = "pending-cheques-scroll-container"
                    />
                </div>                
            </div>
        </>
    );
};

export default CashPosition;
