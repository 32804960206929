import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import AddCircle from "../../../assets/img/add_circle.svg";
import SearchImg from "../../../assets/img/search.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Loader from "../../common/Loader";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import swal from "sweetalert2";
import Empty from "../../../assets/img/empty_student.svg";
import { useScrollingList } from "../../../utils/hooks/use-scrolling-list";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const ChapterList = () => {
    const getData = useCallback(async ({ skip = 0, search = "", limit = 20 }) => {
        try {
            let url = `${ApiUrl.CHAPTER}?skip=${skip}&limit=${limit}`;
            
            if(search && search.trim().length > 0){
                url += `&search=${search}`
            }

            const data = await Api.doFetch("GET", {}, url);

            if (data?.status === true) {
                const payload = data.data;
    
                if (payload) {
                    const { data: items, total, skip, limit } = payload;
    
                    return {
                        items,
                        total,
                        skip,
                        limit,
                    };
                }
            } else {
                if (data && data.message) {
                    swal.fire("Error!", data.message, "error");
                } else {
                    swal.fire("Error!", "Internal Server Error", "error");
                }
            }
    
            setNoData(true);

            return null;
        } catch (error) {
            console.log(error);

            return null;
        }
    }, []);

    const [scrollContainer, {
        loading, scrollLoading, list: chapterList, handleScroll, searchText, handleSearch, saveScrollPosition, scrollPositionY
    }] = useScrollingList({ getData, listKey: 'chapters', });
    const navigate = useNavigate();
    
    const [noData, setNoData] = useState(false);

    const handleSearchText = (event) => {
        const value = event.target.value;

        handleSearch(value);
    }

    const handleViewClick = (chapterID) => () => {
        if (scrollContainer.current) {
            const scrollTopPos = scrollContainer.current.scrollTop;
            saveScrollPosition(scrollTopPos);
        }

        navigate(`/chapter/${chapterID}`);
    }; 

    useEffect(() => {
        if (scrollContainer.current && scrollPositionY !== 0) {
            scrollContainer.current.scrollTo(0, scrollPositionY);
        }
    }, [scrollContainer, scrollPositionY]);
    
    return (
        <div className="course_list_container">

            <div className="course_list_header">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/chapter");
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">List of chapters</span>
                </div>

                <div className="d-flex align-items-center course_search_wrapper">
                    <img src={SearchImg} alt="search" className="course_search_image" />
                    <input
                        className="course_search_input"
                        defaultValue={searchText}
                        onChange={handleSearchText}
                        placeholder="Search a chapter"
                    />
                </div>
            </div>

            <div
                id="chapter_scroll_container_id"
                className={"subject_scroll_container"}
                ref={scrollContainer}
                onScroll={handleScroll}
            >
                {loading ?
                    <div className="loader_container">
                        <Loader />
                    </div> :
                    chapterList.length === 0 ?
                        <div className="student_search_empty_container mt-5">
                            <div className="student_search_empty_img_container">
                                <img
                                    src={Empty}
                                    alt="not found"
                                    className="student_search_empty_img"
                                />
                            </div>

                            {
                                noData ?
                                    <p className="student_search_empty_header">
                                        No chapters found
                                    </p>
                                    :
                                    <>
                                        <p className="student_search_empty_header">
                                            No results found
                                        </p>
                                        <span className="student_search_empty_label">
                                            Try adjusting your search to find
                                        </span>
                                        <span className="student_search_empty_label">
                                            the best results
                                        </span>
                                    </>
                            }
                        </div>
                        :
                        chapterList.map((chapter, index) => {
                            return (
                                <div className="course_list_card" key={index}>
                                    <div className="course_divider2">
                                        <p className="course_list_card_header">Chapter name</p>
                                        <p className="course_list_card_value">{chapter.chapter_name}</p>
                                    </div>

                                    <div className="course_divider1">
                                        <p className="course_list_card_header">Subject name</p>
                                        <p className="course_list_card_value">{chapter.subject_assoc.subject_name}</p>
                                    </div>

                                    {/* <div className="course_divider1">
                                        <div className="course_list_card_header">Course name</div>
                                        <div className="course_list_card_value d-flex chapter_selected_tags_container">
                                            {
                                                chapter.courses && chapter.courses.length > 0 ?
                                                chapter.courses[0].class_name[0].length > 20 ?
                                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={chapter.courses[0].class_name}>
                                                    <p style={{ cursor: "pointer" }} className="selected_tags_name">
                                                        {chapter.courses[0].class_name[0].substr(0, 20) + "..."}
                                                    </p>
                                                </BootstrapTooltip>
                                                :
                                                <p className="selected_tags_name">
                                                    {chapter.courses[0].class_name}
                                                </p>
                                                :  
                                                "-"
                                            }

                                            {
                                                chapter.courses && chapter.courses.length > 1 &&
                                                <BootstrapTooltip
                                                    disableFocusListener
                                                    enterTouchDelay={0}
                                                    title={
                                                        chapter.courses.length > 1 &&
                                                        chapter.courses.slice(1, chapter.courses.length)
                                                            .map((course) => {
                                                                return `${ course.class_name } `;
                                                            })
                                                            .toString()
                                                    }
                                                >
                                                    <p className="selected_tags_name" style={{ cursor: "pointer" }}>{`+${ chapter.courses.length - 1 }`}</p>
                                                </BootstrapTooltip>
                                            }
                                        </div>
                                    </div> */}

                                    <div className="course_divider1">
                                        <div className="course_list_card_header">Tag</div>

                                        <div className="course_list_card_value d-flex chapter_selected_tags_container">
                                           { chapter.tags?.length >0 ? chapter.tags[0]?.length > 13 ? <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={chapter.tags[0]}>
                                                <p style={{ cursor: "pointer" }} className="selected_tags_name">
                                                    { chapter.tags[0].substr(0, 13) + "..."}
                                                </p>
                                            </BootstrapTooltip>:
                                                <p className="selected_tags_name">
                                                    {chapter.tags[0].substr(0, 13)}
                                                </p> : '-'}
                                            {chapter.tags?.length > 1 && (
                                                <BootstrapTooltip
                                                    disableFocusListener
                                                    enterTouchDelay={0}
                                                    title={
                                                        chapter.tags?.length > 1 &&
                                                        chapter.tags.slice(1, chapter.tags?.length)
                                                            .map((tag) => {
                                                                return `${ tag } `;
                                                            })
                                                            .toString()
                                                    }
                                                >
                                                    <div className="selected_tags_name" style={{ cursor: "pointer" }}>{`+${ chapter.tags?.length - 1 }`}</div>
                                                </BootstrapTooltip>
                                            )}
                                        </div>
                                    </div>

                                    <div className="course_divider4">
                                        {/* <div className="course_action_wrapper" onClick={() => navigate("/chapter/:chapterID")}> */}
                                        <div className="course_action_wrapper" onClick={handleViewClick(chapter.id, index)}>
                                            <span>View</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                }

                {
                    !loading && scrollLoading &&
                    <div className="loader_scroll_container">
                        <Loader />
                    </div>
                }

                {
                    !loading && !scrollLoading &&
                    <div
                        className="add_course_container"
                        onClick={() => {
                            navigate("/chapter/add");
                        }}
                    >
                        <div className="add_course_text_container">
                            <img src={AddCircle} alt="add" className="add_student_img" />

                            <span className="add_student_container_text">Add Chapter</span>
                        </div>
                    </div>
                }
            </div>
            
        </div>
    );
};

export default ChapterList;
