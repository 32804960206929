import { MoreVert } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchImg from "../../../assets/img/search.svg";
import { currencyFormatter } from "../../../utils/helperFunctions";
import { CustomFinanceTooltip } from "../common/handleTooltip";

const searchByItems = [
	"Acknowledgement no.",
	"UTR code",
	"Phone no.",
	"Student name",
	"Student ID",
];

const DepositsHeader = ({ totalAmount, searchText, handleSearch }) => {
	const { total } = useSelector((state) => state.scrollingList);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const navigate = useNavigate();

	const onMenuClick = (e) => {
		if (anchorEl) {
			setAnchorEl(null);
			return;
		}
		setAnchorEl(e.currentTarget);
	};

	return (
		<div className="course_list_header">
			<div className="d-flex">
				<div className="pending-cheques__heading_text mb-0">
					Pending Deposits
				</div>
				<div className="ml-3">
					<p className="pending-cheques__amount mb-0">
						₹ {currencyFormatter(totalAmount, false) || 0}
					</p>
					<p className="pending-cheques__count mb-0">Count: {total}</p>
				</div>
			</div>
			<div className="d-flex align-items-center flex-grow-1 justify-end">
				<div className="d-flex align-items-center course_search_wrapper">
					<img src={SearchImg} alt="search" className="course_search_image" />
					<CustomFinanceTooltip
						disableFocusListener
						enterTouchDelay={0}
						placement="top-start"
						title={
							searchText && searchText.length > 0 ? (
								""
							) : (
								<div style={{ width: 259 }}>
									<div style={{ fontWeight: 600, fontSize: 14 }}>Search by</div>
									{searchByItems.map((searchBy, searchByIndex) => (
										<div
											key={searchByIndex}
											style={{ fontSize: 12, fontWeight: 400 }}
										>
											{searchBy}
										</div>
									))}
								</div>
							)
						}
					>
						<input
							className="course_search_input"
							placeholder="Search a pending deposit"
							defaultValue={searchText}
							onChange={handleSearch}
						/>
					</CustomFinanceTooltip>
				</div>

				<div
					className="d-flex align-items-center ml-3 cursor-pointer"
					onClick={onMenuClick}
				>
					<MoreVert fontSize="large" />
					<Menu
						anchorEl={anchorEl}
						keepMounted
						open={!!anchorEl}
						onClose={onMenuClick}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						PaperProps={{ className: "menu_container" }}
						classes={{ list: "p-0" }}
					>
						<MenuItem
							className="menu_item"
							autoFocus={false}
							onClick={() => navigate("/pending-deposit/bulk-deposit")}
						>
							Bulk bank deposits
						</MenuItem>
					</Menu>
				</div>
			</div>
		</div>
	);
};

export default DepositsHeader;
