import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Select } from "antd";

const { Option } = Select;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxWidth: 400,
    width: "90%",
    padding: "3rem",
    borderRadius: 2,
};

const downloadOptions = ['Question wise', 'Tag wise', 'Topic wise']
const QBDownload = ({
    openModal,
    closeModal,
    handleConfirmSubmit,
}) => {
    const [selectedValue, setSelectedValue] = React.useState(null)
    return (
        <Modal
            open={openModal}
            onClose={closeModal}
            className="payment_reversal_cofirmation_modal"
        >
            <Box sx={style}>
                <p className="reversal_accept_text">
                    Please select sorting format
                </p>

                <div className=" p-0 filter-heading-col-assign-ques my-2">
                    <p className="select_component_text mb-1">Sorting format</p>

                    <div className="antd_dropdown" >
                        <Select
                            className="full-width"
                            value={selectedValue}
                            onChange={(value) => {
                                setSelectedValue(value)
                            }}
                            keyValue={'target_exam'}
                            // data={state.targetExamList}
                            placeholder={"Select sorting format"}>
                            {
                                downloadOptions.map((option, index) => {
                                    return (
                                        <Option
                                            disabled={option === 'Topic wise'}
                                            key={option}
                                            value={option}
                                        >{option}</Option>
                                    )
                                })
                            }
                        </Select>

                    </div>
                </div>

                <div
                    className="mt-3"
                >
                    <button
                        disabled={!selectedValue}
                        className="btn btn-md student_detail_password_confirm ml-2"
                        onClick={() => {
                            handleConfirmSubmit(selectedValue)
                            closeModal()
                        }}
                    >
                        Download
                    </button>
                </div>

            </Box>
        </Modal>
    );
};

export default QBDownload;