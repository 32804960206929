import React, { useEffect } from 'react'
import {
	useNavigate,
} from "react-router-dom";

function ErrorPage() {

	let data = localStorage.getItem("userdetail");
	data = JSON.parse(data);

	let navigate = useNavigate()

	useEffect(() => {
		if (data === null) {
			navigate('/')
		}
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="not_found_container">
			<h1 className="not_found_container_heading">
				404
			</h1>

			<span className="not_found_container_text">
				OOPS! Something went wrong here
			</span>

			<div>
				{/* Go to
				<Button sx={{ m: 2 }}
					variant="contained"
					onClick={() => navigate("/dashboard")}
				>Home Page</Button> */}
				<button
					className="btn btn-md not_found_page_button"
					onClick={() => navigate("/dashboard")}
				>
					Go to Home Page
				</button>
			</div>

		</div>
	)
}

export default ErrorPage