import React,{  useEffect } from 'react';
import {
  useLocation,
  Link
} from "react-router-dom";
import './installmentCreate.css';
import paymentFailedImg from "./css/payment-failed.gif";

const PaymentFailed = (props) => {

  const location = useLocation()

  const student_id= location.state.student_id;

  useEffect(() => {
    window.addEventListener("popstate", e => {
      // Nope, go back to your page
      props.history.go(1);
    });
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ( 
    <div className="container-fluid">
      <div className="row">
        
        <div className="col-md-12">
          <div className="support_main">
            <div className="installCreateWrap">
              <div className="container">
                <div className="row">
                  <div className="headingWrap">
                    <div className="col-sm-12">
                      <h2>Installment Details</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">                  
                <div className="ErrorWrap tabWrap paymentSuccess" style={{ textAlign: 'center'}}>
                  <div>   
                  <img src={paymentFailedImg} alt="failed" /> 
                  <div className="successWrp">                      
                    <p>Payment Failed! Please try again</p>  
                    <Link
                    style={{fontSize:20}}
                      to={`/payment/fee-summary/${student_id}`}
                      state={{
                          section_id: location.state.section_id,
                          school_id: location.state.school_id,
                          school_name: location.state.school_name,
                          section_name: location.state.section_name,
                      }}>
                      Go to Fee Summary
                    </Link>               
                    {/* <p><a href={`FeeSummary/${student_id}`} style={{color:"#847C7C", cursor: "pointer", textDecoration: "none"}}>Go to Fee Summary</a></p> */}
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PaymentFailed

// navigate(`/payment/fee-summary/${student_id}`, {
//   state: {
//     section_id: item.section_id,
//     school_id: item.school_id,
//     school_name: item.school_name,
//     section_name: item.section_name,
//   }
// }) 