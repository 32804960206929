import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/Login.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import Logo from "../../assets/img/mycrm-logo.svg";

import Api from "../common/api";

function ForgotPassword() {
    let navigate = useNavigate();
    // const location = useLocation()

    const [mobileNumber, setMobileNumber] = useState('')



    const getOtp = (e) => {
        e.preventDefault();
        var requestOptions = {
            redirect: "follow",
            // headers: {
            //     'Authorization': 'Token ' + this.state.token
            // },
        };
        if (isNaN(mobileNumber) === true) {
            // console.log('hello')
            toast.error("Input Value Must be a Number", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } else {
            let formdata = new FormData();
            formdata.append("username", mobileNumber);
            axios
                .post(
                    process.env.REACT_APP_BASE_URL + `forgot-password-request-akmc/`,
                    formdata,
                    requestOptions
                )
                .then((data) => {
                    // console.log(data)
                    if (data.data.Success) {
                        toast.success("Otp sent Successfully", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        setTimeout(() => {
                            navigate("/EnterOtp", { state: { mobileNumber: mobileNumber } })
                        }, 1500);
                    } else {
                        toast.error("User Does Not Exist", {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                })
                .catch((err) => {
                    toast.error("Server Error", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                });
        }
    };

    useEffect(() => {
        if (Api.isAuthenticated()) {
            navigate("/dashboard");
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <div className="row main-cont">
                <div className="col-md-8 img-side"></div>
                <ToastContainer />

                <div className="col-md-4 form-side">
                    <img src={Logo} className="logo-img" alt="" />

                    <div className="forgot-password-first-div">
                        <h3 className="login-text blue-primary mt-4 mb-1">
                            Forgot Password
                        </h3>
                        <p className="blue-primary">
                            Enter your Phone number to receive an OTP to reset password
                        </p>

                        <form className="formm mt-5" >
                            <div className="my-5">
                                <label className="form-label">Mobile Number</label>
                                <input
                                    className="form-control login-form-input-f"
                                    placeholder="Mobile Number"
                                    required
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                            </div>

                            <button onClick={getOtp} className="btn login-btn p-3">Get OTP</button>
                        </form>
                    </div>
                </div>
            </div>









            {/* <div className="container">
        <div className="row main-cont">
          <div className="col-md-8 img-side sticky-top" id="img">
          </div>
          <div className="col-md-4 form-side my-5 pt-5 px-5" id="form">
            <div className="col">
              <img src="" alt="mycrm logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="login-div">
        <h4 className="login-text blue-primary mt-4 mb-1">Log In</h4>
        <p className="blue-primary">
          Enter your Phone number and Password to login.
        </p>

        <form onSubmit={onSubmithandler} className="mt-5">
          <div className="mb-3">
            <label className="form-label">Mobile Number</label>
            <input
              className="form-control login-form-input-f"
              placeholder="Mobile Number"
              required
              // value={username}
              onChange={onusernamechange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control login-form-input-f"
              placeholder="Password"
              required
              // value={password}
              onChange={onpasswordchange}
            />
          </div>

          <div className="row mb-5">
            <div className="col-6 text-start">
              <div className=" form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Remember Me
                </label>
              </div>
            </div>
            <div className="col-6 text-end">
              <a href="/">Forgot Password?</a>
            </div>
          </div>

          <button type="submit" className="btn login-btn p-3">
            Login
          </button>
        </form>

        <p className="student-line pt-2">
          If you are a student,{" "}
          <a href="https://www.study.zinedu.com" className="student-link">
            click here
          </a>
        </p>
      </div> */}
        </>
    );
}

export default ForgotPassword;