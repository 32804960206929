import React, { useEffect, useState, useRef, useCallback } from "react";
import ChequeDetails from "./ChequeDetails";
import ChequeHeader from "./ChequeHeader";
import Filter from "../common/financeFilter";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import { useScrollingList } from "../../../utils/hooks/use-scrolling-list";
import Loader from '../../common/Loader';
import FinanceLoader from '../common/FinanceLoader';
import { useSelector, useDispatch } from "react-redux";
import { SCRL_HANDLE_SEARCH_INIT, HANDLE_SCRL_LIST, HANDLE_SCRL_DATA, SCRL_RESET_LIST } from '../../../actions/types';
import { getMyclassroomCentre } from '../../../utils/helperFunctions';
import moment from 'moment';
import BackTop from "../common/backTop";
import Empty from "../../../assets/img/empty_student.svg";
import DownloadIcon from "../../../assets/img/downloadIcon.svg";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { CustomFinanceTooltip } from "../common/handleTooltip";

const PendingCheque = () => {
    const dispatch = useDispatch();

    const { myclassroomCentre } = useSelector(state => state.common);

    const [filterLoading, setFilterLoading] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [centre, setCentre] = useState([]);
    const [dateError, setDateError] = useState("");

    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const centreRef = useRef(centre);
    const totalAmount = useRef(0);

    const getPendingCheque = useCallback(async ({ skip = 0, limit = 10, search = "", }) => {

        let payload = {
            skip,
            limit
        }

        if (search && search.trim().length > 0) {
            payload.search = search.trim();
        }

        if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
            payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
            payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
        }

        if (centreRef && centreRef.current.length > 0) {
            payload.centre_ids = centreRef.current;
        }

        const data = await Api.doFetch("POST", payload, ApiUrl.PENDING_CHEQUE);

        if (data?.status === true) {
            const payload = data.data;

            if (payload) {
                const { data: items, total, skip, limit, cheque_sum } = payload;

                totalAmount.current = cheque_sum

                return {
                    items,
                    total,
                    skip,
                    limit,
                };
            }
        }

        setFilterLoading(false);

        return null;
    }, []);

    const { skip, limit } = useSelector((state) => state.scrollingList);

    const getChequeDetails = async (getOne = false) => {

        // if (!getOne && moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
        //     setDateError("From date cannot be greater than To date");
        //     return;
        // }

        !getOne && setFilterLoading(true);
        const data = await getPendingCheque(getOne ? {
            skip: skip + limit,
            limit: 1,
            search: searchText
        } : {
            skip,
            limit,
            search: searchText
        });

        if (getOne) {
            dispatch({
                type: HANDLE_SCRL_LIST,
                payload: {
                    listItems: (data && data.items) || []
                }
            });
        } else {
            dispatch({
                type: SCRL_HANDLE_SEARCH_INIT,
                payload: {
                    list: (data && data.items) || [],
                    skip: 0,
                    total: (data && data.total) || 0,
                    scrollLoading: false,
                },
            });
        }

        setFilterLoading(false);
    }

    const updateCheque = (id) => {
        dispatch({
            type: HANDLE_SCRL_DATA,
            payload: pendingChequeList.filter(x => {
                if (x.id === id) {
                    return false;
                }
                return true;
            })
        })
        getChequeDetails(true)
    }

    const [scrollContainer, { loading, scrollLoading, list: pendingChequeList, handleScroll, searchText, handleSearch }] = useScrollingList({
        listKey: "finance-cheque",
        getData: getPendingCheque
    });

    const handleSearchText = (event) => {
        const value = event.target.value;
        handleSearch(value);
    }

    useEffect(() => {
        if (myclassroomCentre && myclassroomCentre.length === 0) {
            getMyclassroomCentre();
        }

        return () => {
            dispatch({ type: SCRL_RESET_LIST })
        }
    }, [])

    const filterApplied = () => {
        if (
            searchText ||
            startDateRef.current ||
            endDateRef.current ||
            (centreRef.current && centreRef.current.length > 0)
        ) {
            return true;
        }

        return false;
    };

    const [downloadLoading, setDownloadLoading] = useState(false);

    const downloadPaymentDetatails = async () => {
        if (!filterApplied() || downloadLoading) {
            return;
        }

        let payload = {}

        if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
            payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
            payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
        }

        if (searchText && searchText.trim().length > 0) {
            payload.search = searchText;
        }

        if (centreRef && centreRef.current.length > 0) {
            payload.centre_ids = centreRef.current;
        }

        setDownloadLoading(true);

        const data = await Api.doFetch("POST", payload, ApiUrl.PENDING_CHEQUE);

        setDownloadLoading(false);

        if (data?.status) {

            const workbook = new ExcelJS.Workbook();

            const sheet = workbook.addWorksheet('Pending Cheque details');

            sheet.columns = [
                { header: "Ack No", key: "Ack No" },
                { header: "Cheque ID", key: "Cheque ID" },
                { header: "Bank name", key: "Bank name" },
                { header: "Cheque date", key: "Cheque date" },
                { header: "Student ID", key: "Student ID" },
                { header: "Student name", key: "Student name" },
                { header: "Student number", key: "Student number" },
                { header: "Centre name", key: "Centre name" },
                { header: "Fee type", key: "Fee type" },
                { header: "Course", key: "Course" },
                { header: "Batch name", key: "Batch name" },
                { header: "Amount", key: "Amount" },
                { header: "Entry date", key: "Entry date" },
            ];

            let downloadData = data?.data?.data.map((row) => {
                let obj = {
                    "Ack No": row?.ack_no || "",
                    "Cheque ID": row?.cheque_id || "",
                    "Bank name": row?.bank_name || "",
                    "Cheque date": row?.cheque_date ? moment(row?.cheque_date).format("DD/MM/YYYY") : "",
                    "Student ID": row?.student_id || "",
                    "Student name": row?.student_name || "",
                    "Student number": row?.mobile_number || "",
                    "Centre name": row?.centre_name || "",
                    "Fee type": (row?.payment_type === "C" ? "Course" : "Hostel") || "",
                    "Course": row?.course || "",
                    "Batch name": row?.batch || "",
                    "Amount": row?.amount || "",
                    "Entry date": row?.cheque_entry_date ? moment(row?.cheque_entry_date).format("DD/MM/YYYY") : "",
                }

                sheet.addRow(obj);
            })

            workbook.xlsx.writeBuffer().then(function (buffer) {
                const blob = new Blob([buffer], { type: "application/xlsx" });
                saveAs(blob, "Pending Cheque details.xlsx");
            });

        } else {
            Swal.fire("Error!", data?.message || "Internal Server Error", "error");
        }
    }

    if (loading) {
        return (
            <FinanceLoader text="Loading pending cheques details..." />
        )
    }

    return (
        <div className="course_list_container">

            <div ref={scrollContainer} className="pending-cheques-scroll-container" onScroll={handleScroll}>

                <ChequeHeader
                    totalAmount={totalAmount?.current}
                    searchText={searchText}
                    handleSearch={handleSearchText}
                />

                <Filter
                    startDate={startDate}
                    endDate={endDate}
                    centre={centre}
                    setStartDate={setStartDate}
                    startDateRef={startDateRef}
                    setEndDate={setEndDate}
                    endDateRef={endDateRef}
                    setCentre={setCentre}
                    centreRef={centreRef}
                    dateError={dateError}
                    setDateError={setDateError}
                    getDetails={getChequeDetails}
                    filterLoading={filterLoading}
                />

                <div className="d-flex export_row">
                    <CustomFinanceTooltip title="Apply filters to export the data" placement="left" hideTooltip={filterApplied()}>
                        <span
                            className={filterApplied() ? "cursor-pointer" : "disabled-content"}
                            onClick={downloadPaymentDetatails}
                        >
                            {
                                downloadLoading ?
                                    <span className="spinner spinner-white mr-3" />
                                    :
                                    <img src={DownloadIcon} alt="Download" className="export_download" />
                            }
                            {" "}
                            Export
                        </span>
                    </CustomFinanceTooltip>
                </div>

                {
                    filterLoading ?
                        <div className="loader_scroll_container">
                            <Loader />
                        </div>
                        :
                        pendingChequeList?.length === 0 ?
                            <div className="student_search_empty_container mt-5">
                                <div className="student_search_empty_img_container mt-5">
                                    <img
                                        src={Empty}
                                        alt="not found"
                                        className="student_search_empty_img"
                                    />
                                </div>

                                <p className="student_search_empty_header">
                                    No Pending cheque found
                                </p>
                                {/* <span className="student_search_empty_label">
                            Try adjusting your search to find
                        </span>
                        <span className="student_search_empty_label">
                            the best results
                        </span> */}
                            </div>
                            :
                            pendingChequeList.map((data, index) => {
                                return (
                                    <ChequeDetails
                                        key={index}
                                        id={data.id}
                                        ackNo={data.ack_no}
                                        chequeID={data.cheque_id}
                                        entryDate={data.cheque_entry_date}
                                        chequeDate={data.cheque_date}
                                        bankName={data.bank_name}
                                        studentID={data.student_id}
                                        studentName={data.student_name}
                                        studentMobile={data.mobile_number}
                                        centreName={data.centre_name}
                                        amount={data.amount}
                                        chequePhoto={data.cheque_image}
                                        updateCheque={updateCheque}
                                    />
                                )
                            })
                }

                {
                    !loading && scrollLoading &&
                    <div className="loader_scroll_container">
                        <Loader />
                    </div>
                }

                <BackTop
                    target="pending-cheques-scroll-container"
                />
            </div>

        </div>
    );
};

export default PendingCheque;
