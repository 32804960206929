import React, { useState } from "react";
import { Asterisk, currencyFormatter, zinedus3 } from "../../../../utils/helperFunctions";
import { Select, DatePicker } from "antd";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Api from "../../../common/api";
import ApiUrl from "../../../common/apiUrl";
import ToastMessage from "../../../common/toastMessage";
import { useRef } from "react";

const { Option } = Select;

const CashDetails = (props) => {

    const { 
        entry_date,
        accountant_name,
        accountant_id,
        amount,
        centre_name,
        id,
        payment_partner,
        slip_date,
        deposit_slip,
    } = props.data
    const {updatePendingCashDeposit} = props

    const chequeStatus = ["Confirm", "Rejected"];

    const [loading, setLoading] = useState(false);

    const [paymentReceiveDate, setPaymentReceiveDate] = useState("");

    const [paymentDeclineReason, setPaymentDeclineReason] = useState("");

    const [selectedReason, setSelectedReason] = useState("");

    const [errorText, setErrorText] = useState("");

    const errorTimeoutRef = useRef(null);

    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.arrow}`]: {
                color: theme.palette.common.black,
            },
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.black,
                fontSize: "13px",
            },
        })
    );

    const handleSubmitClick = async () => {
        if (loading) {
            return;
        }

        let payload = {
            status: selectedReason==="Confirm"?"confirmed":"rejected"
        }

        if (selectedReason === "Confirm") {
            payload.amount_receiving_date = moment(paymentReceiveDate).format("YYYY-MM-DD");
        } else if (selectedReason === "Rejected") {
            payload.reason_for_rejection = paymentDeclineReason;
        }

        setLoading(true);

        const data = await Api.doFetch("POST", payload, `${ApiUrl.UPDATE_PENDING_CASH_DEPOSIT}${id}`);

        setLoading(false);

        if(data?.status) {
            setSelectedReason("")
            setPaymentReceiveDate("")
            setPaymentDeclineReason("")
            updatePendingCashDeposit(id);
            toast(<ToastMessage text={"Payment updated successfully!"} />);
        } else {
            Swal.fire("Error!", data?.message || "Internal Server Error" , "error");
        }
    };

    const handleViewSlip = () => {
        window.open(`${zinedus3}${deposit_slip}`, '_blank');
    };

    return (
        <>
            <div className="pending-cheques__details_container">
                <div className="details_container_row1">
                    <div className="course_divider3 my-2">
                        <p className="cheque-details__label mb-0">Entry date</p>
                        <p className="cheque-details__label-1 mb-0">{moment(entry_date).format("MMM DD,YYYY")||"-"}</p>
                    </div>

                    <div className="course_divider2 my-2">
                        <p className="cheque-details__label mb-0">Slip date</p>
                        <p className="cheque-details__label-1 mb-0">{moment(slip_date).format("MMM DD,YYYY")||"-"}</p>
                    </div>

                    <div className="course_divider1 my-2">
                        <p className="cheque-details__label mb-0">Payment partner</p>
                        <p className="cheque-details__label-1 mb-0">
                            {centre_name&&payment_partner.length > 15 ? (
                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={payment_partner}>
                                    <span style={{ cursor: "pointer" }}>{payment_partner.substring(0, 15) + "..."}</span>
                                </BootstrapTooltip>
                            ) : (
                                <>
                                {payment_partner||"-"}
                                </>
                            )}
                        </p>
                    </div>

                    <div className="course_divider1 my-2">
                        <p className="cheque-details__label mb-0">Centre name</p>
                        <p className="cheque-details__label-1 mb-0">
                            {centre_name&&centre_name.length > 15 ? (
                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={centre_name}>
                                    <span style={{ cursor: "pointer" }}>{centre_name.substring(0, 15) + "..."}</span>
                                </BootstrapTooltip>
                            ) : (
                                <>
                                {centre_name||"-"}
                                </>
                            )}
                        </p>
                    </div>

                    <div className="course_divider1 my-2">
                        <p className="cheque-details__label mb-0">Accountant name</p>

                        <p className="cheque-details__label-1 mb-0">
                            {accountant_name&&accountant_name.length > 20 ? (
                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={accountant_name}>
                                    <span style={{ cursor: "pointer" }}>{accountant_name.substring(0, 20) + "..."}</span>
                                </BootstrapTooltip>
                            ) : (
                                <>
                                {accountant_name||"-"}
                                </>
                            )}
                        </p>
                        <p className="cheque-details__label mb-0">Employee ID : {accountant_id || "-"}</p>
                    </div>

                    <div className="course_divider1 my-2">
                        <p className="cheque-details__label mb-0">Amount</p>
                        { amount && <p className="cheque-details__label-1 mb-0">{`₹ ${currencyFormatter(amount,false)}`||"-"} </p>}
                        {deposit_slip && <button className='link_button' style={{textDecoration:'underline'}} onClick={handleViewSlip} >View slip</button>}
                    </div>
                </div>

                <div className="details_container_row2">
                    <p className="update-cheque__heading mb-0">Update deposit</p>

                    <div className="cheque-form-container">
                        <div className="form-container__col-first">
                            <div className="cheque-status-select-container margin-right-det-col my-2 antd_dropdown">
                                <p className="cheque_filter_drop_header mb-1">
                                    Status <Asterisk />
                                </p>

                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select status"
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    showArrow
                                    allowClear
                                    optionFilterProp="children"
                                    value={selectedReason || undefined}
                                    onChange={(value) => {
                                        setSelectedReason(value);
                                        setPaymentDeclineReason("");
                                        setPaymentReceiveDate(null);
                                    }}
                                >
                                    {chequeStatus.map((item, index) => {
                                        return (
                                            <Option key={index} value={item}>
                                                {item}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {/* {chapterError.subject_assoc && <ErrorText text={chapterError.subject_assoc} />} */}
                            </div>

                            <div className="cheque-status-select-container margin-left-det-col my-2">
                                {selectedReason === "Rejected" && (
                                    <>
                                        <p className="cheque_filter_drop_header mb-1">
                                            Reason for rejection <Asterisk />
                                        </p>
                                        <input
                                            className={`change_pw_input`}
                                            placeholder="Enter reason"
                                            value={paymentDeclineReason}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 300) {
                                                    setPaymentDeclineReason(e.target.value);
                                                    setErrorText("");
                                                } else {
                                                    setErrorText("Max limit of 300 characters reached");
                                                    if (errorTimeoutRef.current !== null && errorTimeoutRef.current <= 300) {
                                                        clearTimeout(errorTimeoutRef.current);
                                                    }
                                                    errorTimeoutRef.current = setTimeout(() => {
                                                        setErrorText("");
                                                    }, 1200)
                                                }
                                            }}
                                        />
                                        {errorText && <p className="reject_reason_error">{errorText}</p>}
                                    </>
                                )}

                                {selectedReason === "Confirm" && (
                                    <>
                                        <p className="cheque_filter_drop_header mb-1">
                                            Amount receiving date <Asterisk />
                                        </p>
                                        <DatePicker
                                            allowClear={false}
                                            className="attendance_date_picker mb-0"
                                            getPopupContainer={(triggerNode) => {
                                                return triggerNode.parentNode;
                                            }}
                                            placeholder="DD/MM/YYYY"
                                            // style={{ width: "100%" }}
                                            format={"DD/MM/YYYY"}
                                            onChange={(date) => setPaymentReceiveDate(date)}
                                            value={paymentReceiveDate}
                                        />
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="cheque-button-container" style={{ marginTop: "2.8rem" }}>
                            <button
                                className="btn submit-btn-pending-cheque"
                                disabled={
                                    (selectedReason === "Confirm" && paymentReceiveDate) || (selectedReason === "Rejected" && paymentDeclineReason.trim())
                                        ? false
                                        : true
                                }
                                onClick={handleSubmitClick}
                            >
                                Submit
                                {loading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CashDetails;
