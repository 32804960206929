import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchStudent from "../../assets/img/search_student.svg";
import AddStudent from "../../assets/img/add_student.svg";
import { useSelector, useDispatch } from "react-redux";
import { RESET_STUDENT_SEARCH } from "../../actions/types";
import { roleConstant } from "../../utils/helperFunctions";
import Api from "../common/api";
import { segmentEvents } from "../../utils/constants";

const Student = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { roleList } = useSelector((state) => state.auth);

	useEffect(() => {
		if (roleList && roleList.length > 0 && roleList.findIndex((x) => x.role_name === roleConstant.STUDENT_MANAGER) >= 0) {
			if (roleList.findIndex((x) => x.role_name === roleConstant.COUNSELOR) === -1) {
				navigate("/student/search");
			}
		}
	}, [roleList]);

	return (
		<div className="containerMain">
			<div className="payment_reversal_container">
				<div
					className="payment_reversal_container_pending"
					onClick={() => {
						Api.trackEvent(segmentEvents.STUDENT.CLICKED_SEARCH_EXISTING);
						dispatch({ type: RESET_STUDENT_SEARCH });
						navigate("/student/search");
					}}
				>
					<img src={SearchStudent} alt="pending" className="mb-3" />

					<span className="payment_reversal_container_text">Search an</span>
					<span className="payment_reversal_container_text">Existing Student</span>
				</div>

				<div
					className="payment_reversal_container_history"
					onClick={() => {
						Api.trackEvent(segmentEvents.STUDENT.CLICKED_ADD_NEW);
						navigate("/student/add");
					}}
				>
					<img src={AddStudent} alt="history" className="mb-3" />

					<span className="payment_reversal_container_text">Add a New</span>
					<span className="payment_reversal_container_text">Student</span>
				</div>
				{roleList && roleList.length > 0 && roleList.findIndex((x) => x.role_name === roleConstant.CENTER_HEAD) >= 0 && (
					<div
						className="payment_reversal_container_history"
						onClick={() => {
							Api.trackEvent(segmentEvents.STUDENT.CLICKED_ADD_NEW);
							navigate("/student/omr");
						}}
					>
						<span className="payment_reversal_container_text">OMR Sheet</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default Student;
