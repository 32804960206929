import React, { useState, useEffect } from "react";
import XlsxFileInput from "./XlsxFileInput";
import { Card } from "antd";
import "./OMR.css";
import { db } from "../../firebase/firebase";
import { collection, getDocs, addDoc, Timestamp, setDoc, doc } from "firebase/firestore";
import Api from "../common/api";
import Loader from "../common/Loader";
import swal from "sweetalert2";
import apiUrl from "../common/apiUrl";

const OMR = () => {
	let data = localStorage.getItem("userdetail");
	data = JSON.parse(data);
	const token = data.token;
	const [examID, setexamID] = useState("");
	const [selectFile, setselectFile] = useState(false);
	const [isHaveExamData, setIsHaveExamData] = useState(false);
	const [xl_Data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [messageToDisplay, setMessageToDisplay] = useState("");
	const [counter, setCounter] = useState({ count: 0 });
	const [que_order, setQue_order] = useState([]);

	async function getSaveExamQuestions({ item, examData, actualSerialAns, count }) {
		const results = await Promise.all(
			item.questionsList.map(async (que, index) => {
				var serialObj = actualSerialAns.find((obj) => obj.S_NO == que.questionNumber);
				var queObj = examData.data.section_questions.find((obj) => obj.question_bank_assoc.id === serialObj.question_id);
				setMessageToDisplay("Saving DATA of S.NO: " + `${count} and STUDENT ID: ${item.MCRID}`);
				let formData = new FormData();
				formData.append("student_id", item.MCRID);
				formData.append("ques_id", queObj.id);
				formData.append("total_seconds", 60);
				formData.append("review_status", 0);
				if (queObj.question_type_id == 1) {
					const validValues = [1, 2, 3, 4];
					if (validValues.includes(que.answer)) {
						formData.append("ans_given", que.answer);
					} else {
						const alternatives = validValues.filter((value) => value !== parseInt(queObj.question_bank_assoc.question_bank_assoc));
						const randomValue = alternatives[Math.floor(Math.random() * alternatives.length)];
						formData.append("ans_given", randomValue);
					}
				}
				if (queObj.question_type_id == 3) {
					formData.append("ans_given", que.answer);
				}
				if (queObj.question_type_id == 2) {
				}
				const response = await Api.doUpload("POST", formData, apiUrl.OMR_SUBMIT_RESPONSE + `${examID}/`);
				if (response?.status) {
					console.log("SaveD Data OF: ", que.questionNumber);
				} else {
					setIsLoading(false);
				}
			})
		);
		setTimeout(function () {
			submitForAllUsers({ item: item, count: count, actualSerialAns: actualSerialAns });
		}, 10);
	}

	async function submitForAllUsers({ item, count, actualSerialAns }) {
		setMessageToDisplay("Submitting DATA of " + `S.NO: ${count} StudentID${item.MCRID} `);
		let formData = new FormData();
		formData.append("student_id", item.MCRID);
		const response = await Api.doUpload("POST", formData, apiUrl.OMR_SUBMIT_EXAM_CALCULATE + `${examID}/`);
		if (response?.status) {
			setIsLoading(false);
			console.log("Submit EXAM FOR Data");
			setCounter((prevState) => ({
				...prevState, // Spread the previous state to keep other properties
				count: prevState.count + 1, // Increment the count
			}));
			increment({ count: count + 1, actualSerialAns: actualSerialAns });
		} else {
			swal.fire("Error!!", `Excel Upload Failed at ${count} STUDENT ID: ${item.MCRID}`, "success");
		}
	}
	const increment = ({ count, actualSerialAns }) => {
		if (xl_Data.length == count) {
			console.log("ALL APi call Done");
			swal.fire("Success!!", "All Student ids have been updated", "success");
		} else {
			console.log("Keep Going ON");
			CAllingApI({ count: count, actualSerialAns: actualSerialAns });
		}
		setIsLoading(true);
	};
	async function CAllingApI({ count, actualSerialAns }) {
		console.log("GOING for second Item:" + count);
		var newOBj = xl_Data[count];
		fetchDownloadExamDATA({ item: newOBj, count: count, actualSerialAns: actualSerialAns });
	}

	async function fetchDownloadExamDATA({ item, count, actualSerialAns }) {
		setMessageToDisplay("Loading Exam DATA of " + `S.NO: ${count} StudentID${item.MCRID} `);
		const myHeaders = new Headers();
		myHeaders.append("authorization", `Token ${token}`);
		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		const examResponse = await fetch(apiUrl.OMR_EXAM_DATA + `${examID}?student_id=${item.MCRID}`, requestOptions);
		if (examResponse?.status) {
			const examData = await examResponse.json();
			if (!examData.status) {
				swal.fire("Error!", "could Not proceed on S.NO " + count + " studentID: " + item.MCRID, "error");
			} else {
				if (!actualSerialAns.length > 0) {
					console.log("No Data");
					const requestOptions = {
						method: "GET",
						headers: myHeaders,
						redirect: "follow",
					};
					const response = await fetch(apiUrl.OMR_Exam_Order + `${examID}`, requestOptions);
					if (response?.status) {
						const da = await response.json();
						var a = da.data.question_list;
						setQue_order(a);
						getSaveExamQuestions({ item: item, examData: examData, actualSerialAns: a, count: count });
						setIsHaveExamData(true);
					}else{
						swal.fire("Error!", "Unable to fetch Exam Order", "error");
					}
				} else {
					console.log("HAS DATA");
					getSaveExamQuestions({ item: item, examData: examData, actualSerialAns: actualSerialAns, count: count });
					setIsHaveExamData(true);
				}
			}
		} else {
			swal.fire("Error!", "Internal Server Error", "error");
			setIsLoading(false);
		}
	}

	const handleFileLoad = (xlsxData) => {
		let finalArray = [];
		xlsxData.map((data, index) => {
			let questions = [];
			let restData = {};
			Object.keys(data).forEach((key) => {
				if (key.startsWith("Branch")) {
					restData[key] = data[key];
				} else if (key.startsWith("MCRID")) {
					restData[key] = data[key];
				} else {
					questions.push({
						questionNumber: parseInt(key),
						answer: data[key],
					});
					// restData[key] = data[key];      //it will put rest all data
				}
			});
			restData["questionsList"] = questions;
			finalArray.push(restData);
		});
		setData(finalArray);
	};

	return (
		<div
			style={{
				background: "white",
				padding: 30,
				justifyContent: "center",
				alignContent: "center",
				alignSelf: "center",
				display: "flex",
				width: "100%",
				height: "100%",
			}}
		>
			<div style={{ width: "100%", height: "100%" }}>
				{!isHaveExamData && <h6>{"Provide Exam ID"}</h6>}
				{!isHaveExamData && (
					<div style={{ display: "flex", paddingTop: "5px", paddingBottom: "20px" }}>
						<input
							className="exam_button"
							value={examID}
							onChange={(e) => {
								if (e.target) {
									setexamID(e.target.value.replace(/[^0-9.-]/g, ""));
								}
							}}
							placeholder="Exam ID"
						/>
						<button
							className="btn btn-md student_detail_no"
							onClick={() => {
								if (examID.length > 3) {
									setselectFile(true);
								} else {
									swal.fire("Error!", "Please Provide examId and Student ID", "info");
								}
							}}
						>
							Submit Exam ID
						</button>
					</div>
				)}
				{isLoading && (
					<div className="loader_container" style={{ display: "block", textAlign: "center" }}>
						<Loader />
						<h3 style={{ marginTop: 20, fontWeight: "bold" }}>{messageToDisplay}</h3>
					</div>
				)}
				{selectFile && <XlsxFileInput onFileLoad={handleFileLoad} />}

				<div style={{ marginTop: 40, backgroundColor: "white", display: "block", width: "100%", height: "100%" }}>
					{/* {data} */}
					{xl_Data.length > 0 && (
						<div>
							<h3> {"Total Records found in OMR: " + xl_Data.length}</h3>
						</div>
					)}
					{xl_Data.length > 0 && (
						<div style={{ display: "flex", paddingTop: "5px", paddingBottom: "20px", justifyContent: "flex-end" }}>
							<input
								className="exam_button"
								value={counter.count}
								onChange={(e) => {
									if (e.target) {
										setCounter((prevState) => ({
											...prevState, // Spread the previous state to keep other properties
											count: parseInt(e.target.value.replace(/[^0-9.-]/g, ""), 10) || 0, // Increment the count
										}));
									}
								}}
								placeholder="Run Excel FROM S.NO"
							/>
							<button
								className="btn btn-md student_detail_no"
								style={{ color: "white", background: "green" }}
								onClick={() => {
									setIsLoading(true);
									CAllingApI({ count: counter.count, actualSerialAns: [] });
								}}
							>
								Submit OMR SHEET
							</button>

							{/* <button
								className="btn btn-md student_detail_no"
								style={{ color: "white", background: "#0D47A1", marginLeft: 20 }}
								onClick={async () => {
									setIsLoading(true);
									// calculateMarks();
									xl_Data.map((item, index) => {
										submitForAllUsers({ item: item });
									});
								}}
							>
								Calculate Marks
							</button> */}
						</div>
					)}
					{xl_Data.map((row, index) => {
						return (
							<Card style={{ marginBottom: 0, borderRadius: 10, display: "block" }} key={index}>
								<span style={{ fontSize: 16 }}>
									<span style={{ color: "grey", fontWeight: "bold" }}>◉ {index} : </span> {row.MCRID}
								</span>
								<br></br>

								<div style={{ overflowX: "auto", maxWidth: "100%", margin: "10px auto", display: "flex" }}>
									{row.questionsList.map((item, index) => {
										return (
											<div style={{ borderWidth: 12, padding: 8, textAlign: "center", borderColor: "black" }}>
												Que {item.questionNumber} Ans {item.answer}
											</div>
										);
									})}
								</div>
								{/* <span>{JSON.stringify(row.questionsList)}</span> */}
							</Card>
						);
					})}
				</div>
			</div>
		</div>
	);
};
export default OMR;
