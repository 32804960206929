import React from "react";
// import SuccessImg from "../../../assets/img/success.svg";
import WarningImg from "../../../assets/img/warning.svg";
import moment from "moment";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import { generateProfileInitials } from "../../../utils/helperFunctions";
import IncludesTab from "../../../assets/img/Tab.svg";
import LockcontentImg from "../../../assets/img/lock_content.svg";
import { MoreVert } from "@mui/icons-material";
import GreenTick from "../../../assets/img/lead/greenTick.svg";
import { Tooltip } from "antd";

const StudentCard = ({ studentData, installmentMissed, redirect, installmentPlanNotCreated, style = {}, onEditLead = () => {}, isLeadList }) => {
	const isMobile = useMediaQuery("(max-width:977px)");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [courseEllipsisNeeded, setCourseEllipsisNeeded] = React.useState(false);
	const [centerEllipsisNeeded, setCenterEllipsisNeeded] = React.useState(false);

	React.useEffect(() => {
		const e = document.getElementById(`course-${studentData?.id}`);
		const e2 = document.getElementById(`center-${studentData?.id}`);
		if (e) setCourseEllipsisNeeded(e.offsetWidth < e.scrollWidth);
		if (e2) setCenterEllipsisNeeded(e2.offsetWidth < e2.scrollWidth);
	}, [studentData]);

	const onMenuClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (anchorEl) {
			setAnchorEl(null);
			return;
		}
		setAnchorEl(e.currentTarget);
	};
	return (
		<div
			className={`student_data_container ${
				installmentMissed || installmentPlanNotCreated ? "student_data_container_installment_hover" : "student_data_container_hover"
			}${isMobile ? " is-mobile" : ""}`}
			style={{
				"--student_card_margin_top": style.marginTop ? style.marginTop : "2rem",
				"--student_card_margin_bottom": style.marginBottom ? style.marginBottom : "2rem",
			}}
			onClick={redirect}
		>
			{studentData.is_tablet_issued && (
				<div className="student_tablet_wrapper">
					<img
						src={IncludesTab}
						alt="includes Tab"
						className={`${
							!studentData.profile_locked && !studentData.is_installment_created ? "student_tablet_img_no_enroll" : "student_tablet_img"
						} ${isMobile ? "is-mobile" : ""}`}
					/>
				</div>
			)}

			<div className={`student_enrolled_wrapper${isMobile ? " is-mobile" : ""}`}>
				{studentData.is_installment_created ? (
					<div className="student_enrolled">Enrolled</div>
				) : studentData.is_free || isLeadList ? (
					<div className="student_free_user">Free User</div>
				) : null}
				{studentData.profile_locked && (
					<div className="d-flex align-items-center">
						<img src={LockcontentImg} style={{ width: 16, marginLeft: 8 }} alt="locked" />
						<div className="student_locked_tag">Content locked</div>
					</div>
				)}
			</div>

			<div className="search_student_details">
				<div className="search_student_details_flex profile-pic justify-content-center">
					<div className="search_student_detail_profile">{studentData && generateProfileInitials(studentData.name)}</div>
				</div>
				<div className="search_student_details_flex_name">
					<div>
						<p className="search_student_detail_name">{studentData && studentData.name}</p>
						<p className="search_student_detail_id">ID-{studentData && studentData.id}</p>
					</div>
				</div>
				<div className="search_student_details_flex phone-number">
					<div>
						<p className="search_student_detail_header">Phone No</p>
						<p className="search_student_detail_value">
							{studentData && studentData.mobile_number && `*****${studentData.mobile_number.substring("5")}`}
							{studentData.is_mobile && (
								<>
									&nbsp;
									<img src={GreenTick} alt="" />
								</>
							)}
							{/* <img
                                src={SuccessImg}
                                alt="verified"
                                className="ml-1"
                            /> */}
						</p>
					</div>
				</div>
				<div className="search_student_details_flex registered-on">
					<div>
						<p className="search_student_detail_header">Created on</p>
						<p className="search_student_detail_value">
							{studentData ? moment(isLeadList ? studentData.created_on : studentData.date_joined, "YYYY-MM-DD").format("MMM DD, YYYY") : "-"}
						</p>
					</div>
				</div>
				<div className="search_student_details_flex last-paid">
					{studentData.is_free || isLeadList ? (
						<div>
							<p className="search_student_detail_header">Course</p>
							{courseEllipsisNeeded ? (
								<Tooltip title={studentData?.course} placement="bottom">
									<p className="search_student_detail_value search_student_course_details">
										{isLeadList || studentData.is_free ? studentData?.course ?? "-" : studentData.section_assoc.class_name}
									</p>
								</Tooltip>
							) : (
								<p id={`course-${studentData?.id}`} className="search_student_detail_value search_student_course_details">
									{isLeadList || studentData.is_free ? studentData?.course ?? "-" : studentData.section_assoc.class_name}
								</p>
							)}
							{centerEllipsisNeeded ? (
								<Tooltip title={studentData?.centre} placement="bottom">
									<p className="search_student_detail_value search_student_course_details">
										{isLeadList || studentData.is_free ? studentData?.centre ?? "-" : studentData.section_assoc.class_name}
									</p>
								</Tooltip>
							) : (
								<p id={`center-${studentData?.id}`} className="search_student_detail_header search_student_course_details">
									Center: {isLeadList || studentData.is_free ? studentData?.centre ?? "-" : studentData.school_assoc.name}
								</p>
							)}
						</div>
					) : (
						<div>
							<p className="search_student_detail_header">Last paid on</p>
							<p className="search_student_detail_value">
								{studentData && studentData.last_paid_on
									? moment(studentData.last_paid_on, "YYYY-MM-DD").format("MMM DD, YYYY")
									: studentData && studentData.last_payment_on
									? moment(studentData.last_payment_on, "YYYY-MM-DD").format("MMM DD, YYYY")
									: "-"}
							</p>
						</div>
					)}
				</div>
				<div className="search_student_details_flex view-profile pl-1">
					{studentData.is_free || isLeadList ? (
						<div className="d-flex justify-content-between" style={{ width: "100%" }}>
							<span
								className={`search_student_view_profile${isMobile ? " mt-2" : ""}`}
								onClick={(e) => {
									if (isMobile) {
										e.preventDefault();
										e.stopPropagation();
										redirect();
									}
								}}
							>
								Enroll Course
							</span>
							<div className="d-flex align-items-center mr-3 cursor-pointer" onClick={onMenuClick}>
								<MoreVert fontSize="large" />
								<Menu
									anchorEl={anchorEl}
									keepMounted
									open={!!anchorEl}
									onClose={onMenuClick}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									PaperProps={{ className: "menu_container" }}
									classes={{ list: "p-0" }}
								>
									<MenuItem className="menu_item" autoFocus={false} onClick={onEditLead}>
										Edit
									</MenuItem>
								</Menu>
							</div>
						</div>
					) : (
						<span
							className={`search_student_view_profile${isMobile ? " mt-2" : ""}`}
							onClick={(e) => {
								if (isMobile) {
									e.preventDefault();
									e.stopPropagation();
									redirect();
								}
							}}
						>
							View Profile
						</span>
					)}
				</div>
			</div>
			{installmentMissed ? (
				<div className="student_installment_missed">
					<img src={WarningImg} alt="warning" />
					<span className="student_installment_missed_text">Missed installment due date</span>
				</div>
			) : installmentPlanNotCreated ? (
				<div className="student_installment_missed">
					<img src={WarningImg} alt="warning" />
					<span className="student_installment_missed_text">Installment Plan Not Created</span>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default StudentCard;
