import React from "react";
import { FormControlLabel, Avatar } from "@mui/material";
import PresentHand from "../assets/attendance Module/noun-raise-hand-4160048 1.svg";
import FingerPrint from "../assets/attendance Module/Fingerprint.svg";
import Online from "../assets/attendance Module/online.svg";
import { generateProfileInitials } from "../../../utils/helperFunctions";

function AttendanceCard({
  markedPresent,
  totalStudents,
  alreadyPresent,
  attendanceData,
  changeMarkedPresent,
  finalList,
}) {
  var tempList1 = [];

  return (
    <>
      <div className="top-bar-card">
        <FormControlLabel
          control={
            <input
              type="checkbox"
              className="form-check-input mark-all-chck-mob"
              checked={
                markedPresent.length > 0 &&
                markedPresent.length === totalStudents - alreadyPresent
              }
              style={{
                width: '15px',
                height: '15px',
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 28,
                },
              }}
              onChange={(e) => {
                attendanceData.map((student, i) => {
                  /* eslint-disable-line */
                  if (
                    student.status !== "Present" &&
                    e.target.checked === true
                  ) {
                    if (
                      markedPresent.indexOf(student.student_assoc.id) === -1
                    ) {
                      tempList1.push(student.student_assoc.id);
                    }
                  }
                  return null
                });

                changeMarkedPresent([...markedPresent, ...tempList1]);

                if (e.target.checked === false) {
                  changeMarkedPresent([]);
                  tempList1 = [];
                }
              }}
            />
          }
        />
        Mark All Present
      </div>

      {finalList.map((student, index) => (
        <div className="attendance-card" key={index}>
            <div className="attendance-card-header">
                <div className="attendance-card-img-con" >
                    {student.student_assoc.profile_pic ==
                        null ? (
                        <Avatar
                            sx={{
                                bgcolor:
                                    "rgba(222, 229, 250, 1)",
                                height: "80px",
                                width: "80px",
                                marginRight: "20px",
                                fontsize: "2.9rem",
                                color: "#012c63",
                            }}
                        >
                            <p className="nameInitialAvatar">
                                {generateProfileInitials(student.student_assoc.name)}
                            </p>
                        </Avatar>
                    ) : (
                        <Avatar
                            sx={{
                                height: "80px",
                                width: "80px",
                                marginRight: "20px",
                            }}
                        >
                            <img alt={"Profile Pic"}
                                src={
                                    student.student_assoc
                                        .profile_pic
                                }
                            />
                        </Avatar>
                    )}
                </div>
                
                <div className="attendance-card-stud-details-1">
                    <p className="student-name-tbl">
                        {student.student_assoc.name}
                    </p>
                    <p className="student-id-tbl">
                        Student ID:{" "}
                        {student.student_assoc.id}
                    </p>
                </div>
            </div>
            <div className="attendance-card-stud-details-2">
                <p className="student-name-tbl">
                    Mobile Number: <span className="mobile-number-card">---- ----{" "}{student.student_assoc.mobile_number.substr(-4)}</span>
                </p>
            </div>
            
            <div
                className="form-check present-div-cont att-card-input-con"
                style={{
                    display: "flex",
                }}
            >
                <input
                    type="checkbox"
                    className="form-check-input att-check"
                    checked={
                        markedPresent.indexOf(
                            student.student_assoc.id
                        ) !== -1 ||
                            (student.status === "Present" &&
                                true)
                            ? true
                            : false
                    }
                    disabled={
                        student.status === "Present" && true
                    }
                    onChange={(e) => {
                        if (
                            markedPresent.indexOf(
                                student.student_assoc.id
                            ) === -1
                        ) {
                            // console.log("Not Present");
                            changeMarkedPresent([
                                ...markedPresent,
                                student.student_assoc.id,
                            ]);
                            // console.log("Marked Students", markedPresent);
                        } else {
                            // console.log("Present");

                            let temp = markedPresent;
                            var myIndex =
                                markedPresent.indexOf(
                                    student.student_assoc.id
                                );

                            temp.splice(myIndex, 1);
                            changeMarkedPresent([...temp]);
                            // console.log("Item Removed:", markedPresent);
                        }
                    }}
                />
                {markedPresent.indexOf(
                    student.student_assoc.id
                ) !== -1 && (
                        <div className="present-div-selected">
                            <img alt={"Present"}
                                src={PresentHand}
                                className="present-hand-img"
                            />
                            Present
                        </div>
                    )}
                {student.status === "Present" &&
student.attendance_assoc.length > 0 &&
                    student.attendance_assoc[0]
                        .mode_of_attending === "mycrm" ? (
                    <div className="present-div-selected">
                        <img
                            alt={"PresentHand"}

                            src={PresentHand}
                            className="present-hand-img"
                        />
                        Present
                    </div>
                ) : student.attendance_assoc.length > 0 &&
                    student.attendance_assoc[0]
                    .mode_of_attending === "Offline" ? (
                    <div className="offline-div-selected">
                        <img
                            alt={"FingerPrint"}
                            src={FingerPrint}
                            className="present-hand-img"
                        />
                        Biometric
                    </div>
                ) : student.attendance_assoc.length > 0 &&
                    student.attendance_assoc[0]
                    .mode_of_attending === "Online" ? (
                    <div className="offline-div-selected">
                        <img
                            alt={"Online"}

                            src={Online}
                            className="present-hand-img"
                        />
                        Online
                    </div>
                ) : (
                    ""
                )}
            </div>
            <p style={{
                margin: '0px 12px'
            }}><small>Select to mark present</small></p>

        </div>
    ))}

      
    </>
  );
}

export default AttendanceCard;
