import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import defaultUser from '../../assets/img/defaultUser.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import Api from "../common/api";
import ApiUrl from "../common/apiUrl";
import myclassroom_logo from "../../assets/img/mycrm-logo.svg";
import ProfileDrop from "../../assets/img/profile_drop.svg";
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2';
import TopBarMobile from './TopNavBarMobile';
import { RESET } from "../../actions/types";
import ChangePassword from './changePassword';
import { ToastContainer, toast } from "react-toastify";
import TickWhite from "../../assets/img/tick_white.svg";
import { Capitalize, permissionConstants } from "../../utils/helperFunctions";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { segmentEvents } from '../../utils/constants';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        arrow
        classes={{ popper: `${ className } tooltip_header` }}
    />
))(({ theme }) => ({
    [`& .${ tooltipClasses.arrow }`]: {
        color: theme.palette.common.black,
    },
    [`& .${ tooltipClasses.tooltip }`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "13px"
    },
}));

const ToastMessage = () => (
    <div
        className="reversal_toast"
        style={{
            "--toast-status": "#35CB00"
        }}
    >
        <img
            src={TickWhite}
            alt={"success"}
            className="mr-2"
            style={{ width: "1.2rem" }}
        />
        Password reset successfully.
    </div>
)

const TopNavBar = ({
    logo = true
}) => {

	const { pages, centerList, roleList } = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let options = [
        "Change Password",
        "Logout"
    ];

	let index = pages.findIndex(x => x.page === permissionConstants.CHANGE_PASSWORD);

    let hasPasswordPermission = index === -1 ? false : true;

	if(!hasPasswordPermission){
		options = options.filter(x => x !== "Change Password");
	}

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const logout = () => {
        Api.logout();
        dispatch({ type: RESET });
        navigate("/");
    }

    const handleClose = e => {
        if (e.nativeEvent.target.outerText === "Change Password") {
            setChangePassword(true);
        } else if (e.nativeEvent.target.outerText === "Logout") {
            logout();
        }
        setAnchorEl(null);
    };

    const isMobile = useMediaQuery('(max-width:600px)');

    const userDisplayName = Api.getUserDisplayName();

    const [passwordLoading, setPasswordLoading] = useState(false);
    const [changePassword, setChangePassword] = useState(false);

    const changeNewPassword = async formdata => {
        setPasswordLoading(true);
        const data = await Api.doUpload("POST", formdata, ApiUrl.CHANGE_USER_PASSWORD);
        setPasswordLoading(false);

        console.log(data);
        if (data.Error) {
            Swal.fire("Error!", data.Error, "error");
        } else if (data.Success) {
            toast(<ToastMessage />);
            setChangePassword(false);
			Api.trackEvent(segmentEvents.GENERAL.CHANGED_PASSWORD);
        } else {
            Swal.fire("Error!", "Internal Server Error", "error");
        }
    }

    return (
        <div className={`${ isMobile ? "top_bar_mobile" : "topnavbar" } pending_reversal`}>
            <ToastContainer
                position={"top-center"}
                autoClose={2000}
                hideProgressBar={true}
                closeOnClick={true}
                pauseOnHover={false}
                closeButton={false}
                style={{
                    "--toastify-toast-min-height": "10px",
                    "--toastify-toast-max-height": "50px"
                }}
								className='reset-pwd-toast'
            />
            {
                changePassword ?
                    <ChangePassword
                        open={changePassword}
                        close={() => {
                            setChangePassword(false);
                        }}
                        loading={passwordLoading}
                        changeNewPassword={changeNewPassword}
                    />
                    :
                    null
            }
            {
                !isMobile && logo &&
                <div className='nav_heading'>
                    <Link to="/dashboard">
                        <img src={myclassroom_logo} alt='LOGO' />
                    </Link>
                </div>
            }

			{
				isMobile ?
				<TopBarMobile
					changePassword = {() => setChangePassword(true)}
				/>
				:
				<>
					<div className="centerlist">
						<div className='badgesWrapOuter'>
							<div className='badgesWrap'>
								{
									<>
										{
											centerList.slice(0, 3).map((item, i) => {
												return (<div className="center-badge" key={item.school_name}>{item.school_name}</div>)
											})
										}
										{
											centerList.length > 3 &&
											<BootstrapTooltip
												title={centerList.length > 3 && (centerList.slice(3, centerList.length).map((item) => { return ` ${item.school_name}` })).toString() }
											>
												<div
													className="center-badge"
													style={{ cursor: "pointer" }}
													// title={centerList.length > 3 && centerList.slice(3, centerList.length).map((item) => { return item.school_name })}
												>{`+${centerList.length - 3}`}</div>
											</BootstrapTooltip>
										}
									</>
								}
							</div>
						</div>
					</div>
					<div className='IconsWrp'>
						<div className="bellicon">
						</div>
						<IconButton
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							onClick={handleClick}
							disableRipple
						>
							<div
								className="topBar-logo cursor-pointer"
							>
								<div className='ImgText'>
									<div className="d-flex">
										<div className='ImgInner'>
											<img src={defaultUser} alt='default-user' />
										</div>
										<div className='textWrp profile_wrapper'>
											<h4 title={userDisplayName} style={{ cursor: 'pointer', fontSize: '14px' }}>Hi {userDisplayName && userDisplayName.substring(0, 12)}</h4>
											{/* <p>Faculty</p> */}
											<div>
												{
													roleList.length > 0 &&
													<div style={{ display: 'flex', flexDirection: 'row' }} >
														<p style={{ marginRight: 5 }} >
															{
																roleList[0].role_name && 
																roleList[0].role_name.split(" ").map(x => Capitalize(x)).join(" ")
															}
															{/* {roleList[0].role_name.charAt(0).toUpperCase() + roleList[0].role_name.slice(1)} */}
														</p>
														{
															roleList.length > 1 &&
															<BootstrapTooltip
																title={
																	roleList.length > 1 && 
																	(roleList.slice(1, roleList.length).map((item) => { return ` ${item.role_name.split(" ").map(x => Capitalize(x)).join(" ")}` })).toString() 
																}
															>
																<div style={{ cursor: "pointer" }}
																	// title={roleList.slice(1, roleList.length).map((item) => { return item.role_name.charAt(0).toUpperCase() + item.role_name.slice(1) })}
																>{`+${roleList.length - 1}`}
																</div>
															</BootstrapTooltip>
														}
													</div>
												}
											</div>
										</div>
									</div>
									<img
										src={ProfileDrop}
										alt="drop"
									/>
								</div>
							</div>
						</IconButton>

						<Menu
							anchorEl={anchorEl}
							keepMounted
							open={open}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							style={{
								marginLeft: "8rem",
								marginTop: "0.3rem",
							}}
						>
							{options.map((option) => (
								<MenuItem
									key={option}
									value={option}
									onClick={handleClose}
									style={{
										padding: "1rem 2rem"
									}}
									className="mui_dropdown_options"
								>
									<span
										className={`${option === "Change Password" ? "student_detail_option_reset_pw" : "student_detail_option_delete"}`}
										style={
											!hasPasswordPermission ?
											{ minWidth: "125px" }
											:
											{}
										}
									>
										{option}
									</span>
								</MenuItem>
							))}
						</Menu>

                        </div>
                    </>
            }

        </div>
    )
}

export default TopNavBar