import { HANDLE_STUDENT_SEARCH, RESET_STUDENT_SEARCH } from '../actions/types';

const initialState = {
    searchText: "",
    selectedCentre: "",
    selectedCourse: "",
    selectedBatch: "",
    selectedState: "",
    selectedCity: "",
    searchFilterApplied: false,
    advancedFilterApplied: false,
    studentList: [],
    studentData: [],

    courseList: [],
    batchList: [],
    cityList: [],

    skip: 0,
    limit: 10,
    continueScroll: false
};
//eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_STUDENT_SEARCH:
            return {
                ...state,
                ...action.payload
            };
        case RESET_STUDENT_SEARCH:
            if (action.payload) {
                return {
                    ...initialState,
                    ...action.payload,
                }
            }
            return initialState;
        default:
            return state;
    }
}
