import React from 'react'

const ReviewDetails = ({ title = '', content = '', smallText = '' }) => {
    return <div className="mpp_qp_container">
        <span className="mpp_qp_title">{title}</span>
        <span className="mpp_qp_content">{content}</span>
        {Boolean(smallText) && <span className="mpp_qp_small_text">{smallText}</span>}
    </div>
}

export default ReviewDetails;