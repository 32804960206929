import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import BasicDetails from "./basicDetails";
import ChapterDetails from "./chapterDetails";
import ToastMessage from "../../common/toastMessage";
import { toast } from "react-toastify";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import swal from "sweetalert2";
import NotFound from "../../common/notFound";
import Loader from "../../common/Loader";
import Confirmation from "./confirmation";
import { debounce } from "../../../utils/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { SCRL_UPDATE_LIST } from "../../../actions/types";

const AddChapter = ({ defaultView = false }) => {
    const chapterDataInitState={
            chapter_name: "",
            chapter_serial_number: 0,
            courses: [],
            subject_assoc: "",
            tags: [],
        }
    const { chapterID } = useParams();
    const chapterState = useSelector((state) => state.scrollingList);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    const [chapterData, setChapterData] = useState(chapterDataInitState);

    const [courseList, setCourseList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [tagList, setTagList] = useState([]);

    const [dataDump, setDataDump] = useState({});

    const [chapterError, setChapterError] = useState({});

    const navigate = useNavigate();

    const [view, setView] = useState(defaultView);

    const [createModal, setCreateModal] = useState(false);

    const [updateLoading, setUpdateLoading] = useState(false);

    const checkSubmit = () => {
        let flag = true;

        let temp = {};

        if (!(chapterData.chapter_name && chapterData.chapter_name.trim())) {
            temp = { ...temp, chapter_name: "Please enter chapter name" };
            
            flag = false;
        }
        if (!(chapterData.chapter_serial_number && chapterData.chapter_serial_number !== 0)) {
            temp = { ...temp, chapter_serial_number: "Please enter chapter serial no." };

            flag = false;
        }
        // if (chapterData.courses.length === 0) {
        //     temp = { ...temp, courses: "Please select course" };

        //     flag = false;
        // }
        if (!chapterData.subject_assoc) {
            temp = { ...temp, subject_assoc: "Please select subject" };

            flag = false;
        }
        if (chapterData.tags.length === 0) {
            temp = { ...temp, tags: "Please enter tag" };

            flag = false;
        }
        if (chapterData.chapter_serial_number && chapterData.chapter_serial_number <= 0){
            temp = { ...temp, chapter_serial_number : "Please enter appropriate chapter number"}

            flag= false
        }
        setChapterError(temp);
        return flag;
    };

    // const getCourseList = async () => {
    //     const data = await Api.doFetch("GET", {}, `${ApiUrl.CHAPTER}/course-list`);

    //     if (data && data.status) {
    //         setCourseList(data.data);
    //     } else {
    //         if (data && data.message) {
    //             swal.fire("Error!", data.message, "error");
    //         } else {
    //             swal.fire("Error!", "Internal Server Error", "error");
    //         }
    //     }
    // };

    const setChapterDump = (data) => {
        setChapterData({
            chapter_name: data.chapter_name,
            chapter_serial_number: data.chapter_serial_number,
            courses: data.courses.map((x) => x.id),
            subject_assoc: data.subject_assoc && data.subject_assoc.id ? data.subject_assoc.id : "",
            tags: data.tags.map(x => x.tag_name),
        });
        setTagList(data.tags);
    }

    const getChapterDetails = async () => {
        setLoading(true);

        const data = await Api.doFetch("GET", {}, `${ApiUrl.CHAPTER}/${chapterID}`);

        setLoading(false);

        if (data && data.status) {
            setChapterDump(data.data);
            setDataDump(data.data); 
        } else {
            if (data && data.error_code && data.error_code === 1001) {
                setNotFound(true);
            } else if (data && data.message) {
                swal.fire("Error!", data.message, "error");
            } else {
                swal.fire("Error!", "Internal Server Error", "error");
            }
        }

        getSubjectList();
    };

    useEffect(() => {
        if (subjectList && subjectList.length > 0) {
            let index = subjectList.findIndex(x => x.id === chapterData.subject_assoc);
            if(index === -1) {
                setChapterData({
                    ...chapterData,
                    subject_assoc: ""
                })
            }
        }
    }, [subjectList])

    const getSubjectList = async () => {
        // const data = await Api.doFetch("POST", { course_id: chapterData.courses }, `${ApiUrl.CHAPTER}/subject-list`);
        const data = await Api.doFetch("GET", {}, `${ApiUrl.CHAPTER}/subject-list`);

        if (data && data.status) {
            setSubjectList(data.data);
        } else {
            if (data && data.message) {
                swal.fire("Error!", data.message, "error");
            } else {
                swal.fire("Error!", "Internal Server Error", "error");
            }
        }
    };

    const closeCreateModal = () => {
        setCreateModal(false);
    };

    const [updateModal, setUpdateModal] = useState(false);

    const closeUpdateModal = () => {
        setUpdateModal(false);
    };

    const updateChapter = async () => {
        setUpdateLoading(true);

        const data = await Api.doFetch(
            chapterID ? "PATCH" : "POST",
            {
                chapter_name: chapterData.chapter_name,
                subject_id: chapterData.subject_assoc,
                chapter_serial_number: chapterData.chapter_serial_number,
                tags: tagList.map(x => {
                    if(x.id) {
                        return {
                            id: x.id,
                            tag_name: x.tag_name
                        }
                    }

                    return {
                        tag_name: x.tag_name
                    }
                }),
            },
            chapterID ? `${ApiUrl.CHAPTER}/update/${chapterID}` : `${ApiUrl.CHAPTER}/add`
        );

        setUpdateLoading(false);

        if (data && data.status) {
            toast(<ToastMessage text={data.message} />);
            setChapterDump(chapterDataInitState)

            if (defaultView) {
                const chapterRefreshedData = await getChapterDetails();
    
                if (chapterRefreshedData) {
                    const chapterList = chapterState.listKey ? chapterState[chapterState.listKey] || [] : [];

                    const result = Array.from(chapterList);
    
                    const selectedChapterIndex = result.findIndex(sub => sub.id === chapterRefreshedData.id);
    
                    if (selectedChapterIndex > -1) {
                        chapterRefreshedData.tags = Array.from(chapterRefreshedData.tags || [], (tag) => tag.tag_name);
    
                        result[selectedChapterIndex] = chapterRefreshedData;
    
                        dispatch({
                            type: SCRL_UPDATE_LIST,
                            payload: result,
                        });
                    }
                }
            } else {
                setView(false);
            }

        } else {
            if (data && data.error_code && data.error_code === 1001) {
                setNotFound(true);
            } else if (data && data.message) {
                swal.fire("Error!", data.message, "error");
            } else {
                swal.fire("Error!", "Internal Server Error", "error");
            }
        }
    };

    const checkUserHasUpdated = () => {
        let hasChanged = [];

        let oldTags = [];

        if (dataDump.tags) {
            for (let i = 0; i < dataDump.tags.length; i++) {
                oldTags.push(dataDump.tags[i].id);
            }
        }

        if (dataDump.chapter_name && dataDump.chapter_name !== chapterData.chapter_name) {
            hasChanged.push({
                header: "Chapter name",
                value: chapterData.chapter_name,
            });
        }

        if (dataDump.chapter_serial_number && dataDump.chapter_serial_number !== chapterData.chapter_serial_number) {
            hasChanged.push({
                header: "Chapter serial number",
                value: chapterData.chapter_serial_number,
            });
        }

        if (chapterData.subject_assoc && dataDump.subject_assoc && dataDump.subject_assoc.id && dataDump.subject_assoc.id !== chapterData.subject_assoc) {
            hasChanged.push({
                header: "Subject",
                value: subjectList && subjectList.length > 0 && subjectList[subjectList.findIndex(x => x.id === chapterData.subject_assoc)].subject_name,
            });
        }

        if (tagList.filter(x => !x.id).length > 0 || (dataDump && dataDump.tags && tagList.length < dataDump.tags.length)) {
            hasChanged.push({
                header: "Tags",
                value: tagList.map(x => x.tag_name)
            });
        }

        return hasChanged;
    };

    useEffect(() => {
        if (chapterID) {
            getChapterDetails();
        } else {
            getSubjectList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chapterID]);

    const [combinationError, setCombinationError] = useState("");
    const [serialError, setSerialError] = useState("");
    const checkDuplicate = async (chapter_name, subject_id, type) => {

        let payload = { subject_id };

        if (type === "name") {
            payload.chapter_name = chapter_name
        } else {
            payload.chapter_serial_number = chapter_name
        }

        const data = await Api.doFetch(
            "POST",
            payload,
            `${ApiUrl.CHAPTER}/duplicate-check`
        );

        if (data && data.status) {
            let temp = data.data;

            if (chapterID) {
                temp = temp.filter((x) => +x.id !== +chapterID);
            }
            if (type === "name") {
                if (temp.length > 0) {
                    setCombinationError("The chapter-subject combination already exists");
                } else {
                    setCombinationError("")
                }
            } else {
                if (temp.length > 0) {
                    setSerialError("The chapter serial number-subject combination already exists");
                } else {
                    setSerialError("")
                }
            }
        }
    };

    const testCheckDuplicate = useRef(debounce(checkDuplicate, 1000)).current;

    useEffect(() => {
        if(chapterData && chapterData.chapter_name && chapterData.subject_assoc && !view) {
            testCheckDuplicate(chapterData.chapter_name, chapterData.subject_assoc, "name");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chapterData.chapter_name, chapterData.subject_assoc]);

    useEffect(() => {

        if(chapterData && +chapterData.chapter_serial_number > 0 && chapterData.subject_assoc && !view) {
            testCheckDuplicate(chapterData.chapter_serial_number, chapterData.subject_assoc, "serial_number");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chapterData.chapter_serial_number, chapterData.subject_assoc]);

    if (notFound) {
        return <NotFound />;
    }

    return (
        <>
            <div className="course_add_container antd_dropdown">
                <Confirmation
                    createModal = {createModal}
                    closeCreateModal = {closeCreateModal}
                    updateModal = {updateModal}
                    closeUpdateModal = {closeUpdateModal}
                    chapterData = {chapterData}
                    updateChapter = {updateChapter}
                    checkUserHasUpdated = {checkUserHasUpdated}
                />

                <div className="reversal_detail_header justify-content-between">
                    <div className="d-flex align-items-center">
                        <img
                            src={LeftArrow}
                            alt="go back"
                            className="cursor-pointer"
                            onClick={() => {
                                if(chapterID){
                                    if (view) { navigate("/chapter/list"); } else { 
                                        setView(true);
                                        setChapterDump(dataDump);
                                    }
                                }else{
                                    navigate("/chapter");
                                }
                            }}
                        />

                        <span className="reversal_detail_header_text ml-2">{defaultView ? (view ? "View chapter" : "Edit chapter") : "Add chapter"}</span>
                    </div>

                    {
                        view &&
                        <button className="btn btn-md course_add_next" onClick={() => setView(false)}>
                            Edit
                        </button>
                    }
                </div>

                {
                    loading ?
                    <div className="loader_container">
                        <Loader />
                    </div>
                    :
                    <>
                        <BasicDetails
                            view={view}
                            chapterData={chapterData}
                            setChapterData={setChapterData}
                            chapterError={chapterError}
                            setChapterError={setChapterError}
                            combinationError={combinationError}
                            setCombinationError={setCombinationError}
                            serialError={serialError}
                            setSerialError={setSerialError}
                        />

                        <ChapterDetails
                            view = {view}
                            chapterData = {chapterData}
                            setChapterData = {setChapterData}
                            chapterError = {chapterError}
                            setChapterError = {setChapterError}
                            chapterID = {chapterID}
                            courseList = {courseList}
                            subjectList = {subjectList}
                            tagList = {tagList} 
                            setTagList = {setTagList}
                            dataDump = {dataDump}
                            setCombinationError = {setCombinationError}
                            setSerialError = {setSerialError}
                        />
                        {
                            !view &&
                            <div className="mt-3 d-flex justify-content-between">
                                <button
                                    className="btn btn-md course_add_back"
                                    onClick={() => {
                                        if (defaultView) {
                                            setView(true);
                                            setChapterDump(dataDump);
                                        } else {
                                            navigate("/chapter");
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-md course_add_next"
                                    onClick={() => {
                                        if(serialError || combinationError){
                                            return;
                                        }
                                        if (Object.values(chapterError).filter((x) => x).length > 0) {
                                            return;
                                        }
                                        if (updateLoading) {
                                            return;
                                        }
                                        if (defaultView) {
                                            if (checkSubmit()) {
                                                setUpdateModal(true);
                                            }
                                        } else {
                                            if (checkSubmit()) {
                                                setCreateModal(true);
                                            }
                                        }
                                    }}
                                    disabled={defaultView && checkUserHasUpdated().length === 0 ? true : false}
                                >
                                    {defaultView ? "Save" : "Create"}
                                    {updateLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                                </button>
                            </div>
                        }
                    </>
                }
            </div>
        </>
    );
};

export default AddChapter;
