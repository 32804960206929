import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import ToastMessage from "../../common/toastMessage";
import { toast } from "react-toastify";
import NotFound from "../../common/notFound";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import Swal from "sweetalert2";
import moment from "moment";
import Loader from "../../common/Loader";

import BasicDetails from "./basicDetails";
import AcademicDetails from "./academicDetails";
import TimelineDetails from "./timelineDetails";
import PricingDetails from "./pricingDetails";
import Confirmation from "./batchConfirmation";

import { SCRL_UPDATE_LIST } from "../../../actions/types";
import { useDispatch, useSelector } from "react-redux";

const AddBatch = ({ defaultView = false }) => {
    const batchDataInitState = {
        course_id: null,
        is_school_integrated: false,
        associated_centre_id: null,
        uses_mycrm: true,
        start_academic_year: null,
        start_date: null,
        end_date: null,
        fee_amount: null,
        minimum_fee_amount: null,
        desc: null,
        default_classroom_id: null,
        default_classroom_name: null,
        fee_amount_currency: "INR",
        minimum_fee_amount_currency: "INR",
    };

    const dispatch = useDispatch();
    const batchState = useSelector((state) => state.scrollingList);

    const [loading, setLoading] = useState(false);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    const [batchError, setBatchError] = useState({});

    const navigate = useNavigate();

    const {
        batchID,
        // batchCentreID
    } = useParams();

    const [view, setView] = useState(defaultView);

    const [selectedCourseName, setSelectedCourseName] = useState("");
    const [selectedCentreName, setSelectedCentreName] = useState("");

    const [batchDumpData, setBatchDumpData] = useState({});

    const [batchData, setBatchData] = useState(batchDataInitState);
    const [courseListMetaData, setCourseListMetaData] = useState([]);
    const [centreListMetaData, setCentreListMetaData] = useState([]);
    const [classroomListMetaData, setClassroomListMetaData] = useState([]);
    const [academicYearListMetaData, setAcademicYearListMetaData] = useState([]);
    const [selectedCourseInfo, setSelectedCourseInfo] = useState({
        course_type: "",
        subjects: [],
        resources: [],
    });

    const [createModal, setCreateModal] = useState(false);

    const closeCreateModal = () => {
        setCreateModal(false);
    };

    const [updateModal, setUpdateModal] = useState(false);

    const closeUpdateModal = () => {
        setUpdateModal(false);
    };

    const setBatchDump = (data) => {
        setBatchData({
            ...batchData,
            uses_mycrm: data.uses_mycrm,
            start_academic_year: data.start_academic_year,
            start_date: data.start_date,
            end_date: data.end_date,
            fee_amount: data.fee_amount,
            minimum_fee_amount: data.minimum_fee_amount,
            desc: data.desc,
            default_classroom_id: data.default_classroom_id,
            fee_amount_currency: data.fee_amount_currency,
            minimum_fee_amount_currency: data.minimum_fee_amount_currency,
            associated_centre_id: data.associated_centre_id,
            course_id: data.course_id,
            is_school_integrated: data.is_school_integrated,
            centres: data.centres,
        });
        setBatchError({});
    };

    const getBatchDetails = async () => {
        setLoading(true);

        const data = await Api.doFetch("GET", {}, `${ApiUrl.BATCH}/${batchID}`);

        setLoading(false);

        if (data && data.status) {
            setBatchDumpData(data.data[0]);
            setBatchDump(data.data[0]);
            return data.data[0];
        } else {
            if (data && data.error_code && data.error_code === 1001) {
                setNotFound(true);
            } else if (data && data.message) {
                Swal.fire("Error!", data.message, "error");
            } else {
                Swal.fire("Error!", "Internal Server Error", "error");
            }
        }
    };

    const checkUserHasUpdated = () => {
        let hasChanged = [];

        if (batchDumpData.course_id !== batchData.course_id) {
            hasChanged.push({
                header: "Course name",
                value: selectedCourseName,
            });
        }

        if (batchDumpData.is_school_integrated !== batchData.is_school_integrated) {
            hasChanged.push({
                header: "Is it school integration program?",
                value: batchData.is_school_integrated ? "Yes" : "No",
            });
        }

        if (batchDumpData.associated_centre_id !== batchData.associated_centre_id) {
            hasChanged.push({
                header: "Associated centre name",
                value: selectedCentreName,
            });
        }

        if (batchDumpData.default_classroom_id !== batchData.default_classroom_id) {
            hasChanged.push({
                header: "Default classroom name",
                value: batchData.default_classroom_id,
            });
        }

        if (batchDumpData.uses_mycrm !== batchData.uses_mycrm) {
            hasChanged.push({
                header: "Will the batch use our platform?",
                value: batchData.uses_mycrm ? "Yes" : "No",
            });
        }

        if (batchDumpData.start_academic_year !== batchData.start_academic_year) {
            hasChanged.push({
                header: "Academic Year",
                value: batchData.start_academic_year,
            });
        }

        if (batchDumpData.fee_amount !== batchData.fee_amount) {
            hasChanged.push({
                header: "Fee amount (MRP)",
                value: batchData.fee_amount,
            });
        }

        if (batchDumpData.minimum_fee_amount !== batchData.minimum_fee_amount) {
            hasChanged.push({
                header: "Minimum fee amount",
                value: batchData.minimum_fee_amount,
            });
        }

        if (batchDumpData.desc !== batchData.desc) {
            hasChanged.push({
                header: "Description",
                value: batchData.desc,
            });
        }

        if (
            batchDumpData.start_date &&
            batchData.start_date &&
            moment(batchDumpData.start_date).format("DD-MM-YYYY").toString() !== moment(batchData.start_date).format("DD-MM-YYYY").toString()
        ) {
            hasChanged.push({
                header: "Start date",
                value: moment(batchData.start_date).format("DD-MM-YYYY"),
            });
        } else if (!batchDumpData.start_date && batchData.start_date) {
            hasChanged.push({
                header: "Start date",
                value: moment(batchData.start_date).format("DD-MM-YYYY"),
            });
        }

        if (
            batchDumpData.end_date &&
            batchData.end_date &&
            moment(batchDumpData.end_date).format("DD-MM-YYYY") !== moment(batchData.end_date).format("DD-MM-YYYY")
        ) {
            hasChanged.push({
                header: "End date",
                value: moment(batchData.end_date).format("DD-MM-YYYY"),
            });
        } else if (!batchDumpData.end_date && batchData.end_date) {
            hasChanged.push({
                header: "End date",
                value: moment(batchData.end_date).format("DD-MM-YYYY"),
            });
        }

        return hasChanged;
    };

    const checkSubmit = () => {
        let flag = true;

        let temp = {};

        if (!batchData.course_id) {
            temp = { ...temp, course_id: "Please select a course" };

            flag = false;
        }
        if (!batchData.associated_centre_id) {
            temp = { ...temp, associated_centre_id: "Please select associated centre" };

            flag = false;
        }
        if (!batchData.start_academic_year) {
            temp = { ...temp, start_academic_year: "Please select start academic year" };

            flag = false;
        }
        if (!batchData.start_date) {
            temp = { ...temp, start_date: "Please enter start date" };

            flag = false;
        }
        if (!batchData.end_date) {
            temp = { ...temp, end_date: "Please enter end date" };

            flag = false;
        }
        if (!(batchData.fee_amount && batchData.fee_amount > 0 && batchData.fee_amount !== "")) {
            temp = { ...temp, fee_amount: "Please enter fee amount (MRP)" };

            flag = false;
        }
        if (!(batchData.minimum_fee_amount && batchData.minimum_fee_amount > 0 && batchData.minimum_fee_amount !== "")) {
            temp = { ...temp, minimum_fee_amount: "Please enter minimum fee amount" };

            flag = false;
        }
        if (batchData.start_date > batchData.end_date) {
            temp = { ...temp, end_date: "End date can not be before start date" };

            flag = false;
        }
        if (parseInt(batchData.minimum_fee_amount) > parseInt(batchData.fee_amount)) {
            temp = { ...temp, minimum_fee_amount: "Minimum fee amount can not be more than MRP" };

            flag = false;
        }
        setBatchError(temp);
        return flag;
    };

    const createBatchClickHandler = async () => {
        setSubmitLoading(true);
        const data = await Api.doFetch(
            batchID ? "PATCH" : "POST",
            {
                ...batchData,
                start_date: moment(batchData.start_date).format("YYYY-MM-DD"),
                end_date: moment(batchData.end_date).format("YYYY-MM-DD"),
                desc: batchData.desc === "" ? null : batchData.desc,
                minimum_fee_amount: parseInt(batchData.minimum_fee_amount),
                fee_amount: parseInt(batchData.fee_amount),
            },
            batchID ? `${ApiUrl.BATCH}/update/${batchID}/` : `${ApiUrl.BATCH}/add`
        );

        setSubmitLoading(false);
        if (data && data.status) {
            window.scrollTo(0, 0);
            setCreateModal(false);
            setUpdateModal(false);
            toast(<ToastMessage text={data.message} />);
            if (batchID) {
                const batchRefreshedData = await getBatchDetails();
                if (batchRefreshedData) {
                    setView(!view);
                    const batchList = batchState.listKey ? batchState[batchState.listKey] || [] : [];
                    const result = Array.from(batchList);

                    const selectedBatchIndex = result.findIndex((batch) => {
                        return batch.id === parseInt(batchID);
                    });

                    if (selectedBatchIndex > -1) {
                        result[selectedBatchIndex] = {
                            batch_name: batchRefreshedData.section_name,
                            course_name: selectedCourseName,
                            associated_centre: batchRefreshedData.associated_centre,
                            start_date: batchRefreshedData.start_date,
                            id: batchRefreshedData.id,
                            batch_centre_id: batchRefreshedData.batch_centre_id,
                        };

                        dispatch({ type: SCRL_UPDATE_LIST, payload: result });
                    }
                }
            } else {
                setBatchData({
                    ...batchData,
                    ...batchDataInitState,
                    minimum_fee_amount: "",
                    fee_amount: "",
                    desc: "",
                });
            }
        } else {
            if (data && data.message) {
                Swal.fire("Error!", data.message, "error");
            } else {
                Swal.fire("Error!", "Internal Server Error", "error");
            }
        }
    };

    const [systemGeneratedName, setSystemGeneratedName] = useState("");

    const generateBatchName = async () => {
        setSubmitLoading(true);
        const data = await Api.doFetch(
            "POST",
            batchData,
            `${ApiUrl.BATCH}/generate`
        );

        setSubmitLoading(false);
        if (data && data.status) {
            setSystemGeneratedName(data.data);
            setCreateModal(true);
        } else {
            if (data && data.message) {
                Swal.fire("Error!", data.message, "error");
            } else {
                Swal.fire("Error!", "Internal Server Error", "error");
            }
        }
    };

    const getMetaData = async () => {
        setLoading(true);
        const data = await Api.doFetch("GET", {}, `${ApiUrl.BATCH}/meta`);

        if (data && data.status) {
            if (Array.isArray(data.data.course)) {
                setCourseListMetaData(
                    data.data.course.sort((a, b) => {
                        var nameA = a.class_name.toLowerCase(),
                            nameB = b.class_name.toLowerCase();
                        if (nameA < nameB)
                            //sort string ascending
                            return -1;
                        if (nameA > nameB) return 1;
                        return 0; //default return value (no sorting)
                    })
                );
            }
            if (Array.isArray(data.data.centre)) {
                setCentreListMetaData(
                    data.data.centre.sort((a, b) => {
                        var nameA = a.name.toLowerCase(),
                            nameB = b.name.toLowerCase();
                        if (nameA < nameB)
                            //sort string ascending
                            return -1;
                        if (nameA > nameB) return 1;
                        return 0; //default return value (no sorting)
                    })
                );
            }
            setClassroomListMetaData(data.data.classroom);
            setAcademicYearListMetaData(data.data.academic_years);
            setLoading(false);
        } else {
            if (data && data.message) {
                Swal.fire("Error!", data.message, "error");
            } else {
                Swal.fire("Error!", "Internal Server Error", "error");
            }
        }
    };

    const getSelectedCourseInfo = async () => {
        const data = await Api.doFetch("GET", {}, `${ApiUrl.BATCH}/courseinfo/${batchData.course_id}`);

        if (data && data.status) {
            setSelectedCourseInfo({
                ...selectedCourseInfo,
                course_type: data.data[0].course_type,
                resources: [{ course_includes_tablet: data.data[0].course_includes_tablet }],
                subjects: data.data[0].subjects,
            });
            setLoading(false);
        } else {
            if (data && data.message) {
                Swal.fire("Error!", data.message, "error");
            } else {
                Swal.fire("Error!", "Internal Server Error", "error");
            }
        }
    };

    useEffect(() => {
        getMetaData();
    }, []);

    useEffect(() => {
        if (batchData && batchData.course_id) {
            getSelectedCourseInfo(batchData.course_id);
        } else
            setSelectedCourseInfo({
                course_type: "",
                subjects: [],
                resources: [],
            });
    }, [batchData.course_id]);

    useEffect(() => {
        if (batchID) {
            getBatchDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchID]);

    useEffect(() => {
        if (batchData.course_id) {
            courseNameHandler(batchData.course_id);
        }
    }, [batchData.course_id, courseListMetaData]);

    useEffect(() => {
        if (batchData.associated_centre_id) {
            centreNameHandler(batchData.associated_centre_id);
        }
    }, [batchData.associated_centre_id]);

    const courseNameHandler = (value) => {
        let courseName = "";
        courseListMetaData.filter((element) => {
            if (element.id === value) {
                courseName = element.class_name;
                setSelectedCourseName(courseName);
            }
        });
    };

    const centreNameHandler = (value) => {
        let centreName = "";
        centreListMetaData.filter((element) => {
            if (element.id === value) {
                centreName = element.name;
                setSelectedCentreName(centreName);
            }
        });
    };

    if (notFound) {
        return <NotFound />;
    }

    return (
        <div className="course_add_container antd_dropdown">
            {/* <Confirmation
                closeUpdateModal={closeUpdateModal}
                createBatchClickHandler={createBatchClickHandler}
                closeCreateModal={closeCreateModal}
                batchData={batchData}
                createModal={createModal}
                updateModal={updateModal}
                selectedCourseName={selectedCourseName}
                selectedCentreName={selectedCentreName}
                checkUserHasUpdated={checkUserHasUpdated}
                submitLoading={submitLoading}
            /> */}
            <Confirmation
                open = {createModal}
                close = {closeCreateModal}
                batchName = {systemGeneratedName}
                course = {selectedCourseName || ""}
                centre = {selectedCentreName || ""}
                startDate = {batchData?.start_date}
                createBatchClickHandler = {createBatchClickHandler}
                submitLoading = {submitLoading}
            />

            <div className="reversal_detail_header justify-content-between">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            if (batchID) {
                                navigate("/batch/list");
                            } else {
                                navigate("/batch");
                            }
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">{defaultView ? (view ? "View batch" : "Edit batch") : "Add batch"}</span>
                </div>

                {/* {view && (
                    <button className="btn btn-md course_add_next" onClick={() => setView(false)}>
                        Edit
                    </button>
                )} */}
            </div>

            {loading ? (
                <div className="loader_container">
                    <Loader />
                </div>
            ) : (
                <>
                    {
                        <>
                            <BasicDetails
                                view={view}
                                batchData={batchData}
                                setBatchData={setBatchData}
                                courseListMetaData={courseListMetaData}
                                selectedCourseInfo={selectedCourseInfo}
                                batchError={batchError}
                                setBatchError={setBatchError}
                                courseNameHandler={courseNameHandler}
                                batchDumpData={batchDumpData}
                                selectedCourseName={selectedCourseName}
                            />

                            {batchID ? (
                                batchData.centres &&
                                batchData.centres.map((centre, centreIndex) => {
                                    return (
                                        <div className="batch_center_wrapper" key={`center-${centreIndex}`}>
                                            {
                                                batchData.centres.length > 1 &&
                                                <p style={{ fontSize: "18px" }}>Centre: {centreIndex + 1}</p>
                                            }
                                            <div style = {{ marginTop: "-1rem" }} />
                                            <AcademicDetails
                                                view={view}
                                                centre={centre}
                                                batchData={batchData}
                                                setBatchData={setBatchData}
                                                centreListMetaData={centreListMetaData}
                                                classroomListMetaData={classroomListMetaData}
                                                setBatchError={setBatchError}
                                                batchError={batchError}
                                                batchDumpData={batchDumpData}
                                                centreNameHandler={centreNameHandler}
                                                selectedCentreName={centre.associated_centre}
                                                defaultCenterName={centre.default_classroom_name}
                                                forCenter
                                            />

                                            <TimelineDetails
                                                view={view}
                                                centre={centre}
                                                batchData={batchData}
                                                setBatchData={setBatchData}
                                                academicYearListMetaData={academicYearListMetaData}
                                                setBatchError={setBatchError}
                                                batchDumpData={batchDumpData}
                                                batchError={batchError}
                                            />

                                            <PricingDetails
                                                view={view}
                                                centre={centre}
                                                batchData={batchData}
                                                setBatchData={setBatchData}
                                                setBatchError={setBatchError}
                                                batchDumpData={batchDumpData}
                                                batchError={batchError}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <>
                                    <AcademicDetails
                                        view={view}
                                        batchData={batchData}
                                        setBatchData={setBatchData}
                                        centreListMetaData={centreListMetaData}
                                        classroomListMetaData={classroomListMetaData}
                                        setBatchError={setBatchError}
                                        batchError={batchError}
                                        batchDumpData={batchDumpData}
                                        centreNameHandler={centreNameHandler}
                                        selectedCentreName={selectedCentreName}
                                        defaultCenterName={batchData && batchData.default_classroom_name}
                                    />

                                    <TimelineDetails
                                        view={view}
                                        batchData={batchData}
                                        setBatchData={setBatchData}
                                        academicYearListMetaData={academicYearListMetaData}
                                        setBatchError={setBatchError}
                                        batchDumpData={batchDumpData}
                                        batchError={batchError}
                                    />

                                    <PricingDetails
                                        view={view}
                                        batchData={batchData}
                                        setBatchData={setBatchData}
                                        setBatchError={setBatchError}
                                        batchDumpData={batchDumpData}
                                        batchError={batchError}
                                    />
                                </>
                            )}
                        </>
                    }

                    {!view && (
                        <div className="mt-3 d-flex justify-content-between">
                            <button
                                className="btn btn-md course_add_back"
                                onClick={() => {
                                    if (defaultView) {
                                        setBatchDump(batchDumpData);
                                        setView(true);
                                    } else {
                                        navigate("/batch");
                                    }
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-md course_add_next"
                                onClick={() => {
                                    if (submitLoading) {
                                        return;
                                    }
                                    if (defaultView) {
                                        if (checkSubmit()) {
                                            setUpdateModal(true);
                                        }
                                    } else {
                                        if (checkSubmit()) {
                                            // setCreateModal(true);
                                            generateBatchName();
                                        }
                                    }
                                }}
                                disabled={defaultView && checkUserHasUpdated().length === 0 ? true : false}
                            >
                                {defaultView ? "Save" : "Create"}
                                {submitLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default AddBatch;
