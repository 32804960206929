import React, { useState } from "react";
import { DatePicker } from "antd";
import { Select } from 'antd';
import moment from "moment";
import ErrorText from "../../common/errorText";
import { bankStatusMap } from "../../../utils/helperFunctions";
import { useSelector } from "react-redux";
import { Capitalize } from "../../../utils/helperFunctions";

const { Option } = Select;

const Filter = ({
    startDate,
    endDate,
    centre,
    bankStatus,
    setStartDate,
    startDateRef,
    setEndDate,
    endDateRef,
    setCentre,
    centreRef,
    setBankStatus,
    bankStatusRef,
    dateError,
    setDateError,
    getDetails,
    filterLoading
}) => {

    const { centerList } = useSelector(state => state.auth);

    const filterArrowToggle = () => {

        setEndDate(endDateRef.current);
        setStartDate(startDateRef.current);
        setCentre(centreRef.current);
        setBankStatus(bankStatusRef.current);

        setStartDateError("");
        setEndDateError("");
        setDateError("");
    };

    const handleClearFilter = () => {
        setEndDate("");
        setStartDate("");
        setCentre([]);
        setBankStatus([]);

        startDateRef.current = "";
        endDateRef.current = "";
        centreRef.current = [];
        bankStatusRef.current = [];

        setStartDateError("");
        setEndDateError("");
        setDateError("");

        getDetails();
    }

    const filterApplied = () => {

        if (
            startDateRef.current ||
            endDateRef.current ||
            (centreRef.current && centreRef.current.length > 0) ||
            (bankStatusRef.current && bankStatusRef.current.length > 0)
        ) {
            return true;
        }
        return false;
    };

    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");

    const canApplyFilter = (skipStateUpdate = false) => {

        if (startDate && !endDate) {
            if (!skipStateUpdate) setEndDateError("Please select To date");
            return false;
        }

        if (endDate && !startDate) {
            if (!skipStateUpdate) setStartDateError("Please select From date");
            return false;
        }

        if (startDate && endDate && moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
            if (!skipStateUpdate) setDateError("From date cannot be greater than To date");
            return false;
        }

        if (
            startDate ||
            endDate ||
            (centre && centre.length > 0) ||
            (bankStatus && bankStatus.length > 0)
        ) {
            return true;
        }

        return false;
    };

    const applyFilter = () => {

        if (
            startDate ||
            endDate ||
            (centre && centre.length > 0) ||
            (bankStatus && bankStatus.length > 0)
        ) {
            return true;
        }

        return false;
    };

    const getCentreList = () => {
        return centerList
            .filter((x) => x && x.school_name && x.school_name.trim())
            .map((x) => {
                return {
                    id: x.school_id,
                    centre: x.school_name,
                };
            })
            .sort(function (a, b) {
                var nameA = a.centre.toLowerCase(),
                    nameB = b.centre.toLowerCase();
                if (nameA < nameB)
                    //sort string ascending
                    return -1;
                if (nameA > nameB) return 1;
                return 0; //default return value (no sorting)
            });
    };

    return (
        <div className="pending-cheques__filter_container">
            <div className="accountant_filter_header">
                <span
                    onClick={() => {
                        if (filterApplied()) {
                            filterArrowToggle();
                        }
                    }}
                    className={`${!filterApplied() ? "disabled-content" : ""} filter-arrow-wrapper`}
                >
                </span>
            </div>

                <div className="filter_expanded_div">
                    <div className="cheque-form-container">
                        <div className="form-container__col-first filter">

                            <div className="cheque-status-select-container filter my-2">
                                
                                <p className="accounts_filter_drop_header mb-1">
                                {bankStatusRef.current&&bankStatusRef.current.length>0&&<span className="marker" ></span>}
                                    Bank Status</p>

                                <div className="antd_dropdown">
                                    <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="All"
                                    value = {bankStatus}
                                    onChange={value => setBankStatus(value)}
                                    showArrow
                                    allowClear
                                    optionFilterProp="children"
                                    maxTagCount='responsive'
                                    autoClearSearchValue={false}
                                >
                                    {
                                        bankStatusMap && Object.values(bankStatusMap).map((status, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    value={status}
                                                >{Capitalize(status)}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                </div>
                            </div>

                            <div className="cheque-status-select-container filter my-2">
                                <p className="accounts_filter_drop_header mb-1">
                                {centreRef.current&&centreRef.current.length>0&&<span className="marker" ></span>}
                                    Centre</p>

                                <div className="antd_dropdown">
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    autoClearSearchValue={false}
                                    placeholder="All"
                                    value={centre}
                                    onChange={value => {
                                        let index = value.indexOf("Select All");

                                        if (index >= 0) {
                                            if (centre.length === getCentreList().length) {
                                                setCentre([]);
                                            } else {
                                                setCentre(getCentreList().map(x => x.id));
                                            }
                                        } else {
                                            setCentre(value);
                                        }

                                    }}
                                    showArrow
                                    allowClear
                                    optionFilterProp="children"
                                    maxTagCount='responsive'
                                >
                                    {
                                        getCentreList() && getCentreList().map((center, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    value={center.id}
                                                >{Capitalize(center.centre)}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                </div>
                            </div>

                            <div className="cheque-status-select-container filter my-2 antd_dropdown">
                                <p className="accounts_filter_drop_header mb-1">
                                {startDateRef.current&&<span className="marker" ></span>}
                                    From
                                </p>
                                <DatePicker
                                    allowClear={false}
                                    className="attendance_date_picker mb-0"
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    // style={{ width: "100%" }}
                                    format={"DD/MM/YYYY"}
                                    value={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        setDateError("");
                                        setStartDateError("");
                                    }}
                                    placeholder="dd/mm/yyyy"
                                />
                                {
                                    dateError &&
                                    <ErrorText text={dateError} />
                                }
                                {
                                    startDateError &&
                                    <ErrorText text={startDateError} />
                                }
                            </div>
                            <div className="cheque-status-select-container filter  my-2">
                                <p className="accounts_filter_drop_header mb-1">
                                {endDateRef.current&&<span className="marker" ></span>}
                                    To
                                </p>
                                <DatePicker
                                    allowClear={false}
                                    className="attendance_date_picker mb-0"
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    // style={{ width: "100%" }}
                                    format={"DD/MM/YYYY"}
                                    value={endDate}
                                    onChange={(date) => {
                                        setDateError("");
                                        setEndDate(date);
                                        setEndDateError("");
                                    }}
                                    placeholder="dd/mm/yyyy"
                                />
                                {
                                    endDateError &&
                                    <ErrorText text={endDateError} />
                                }
                            </div>

                        </div>

                        <div className="my-2 padding_top d-flex align-items-center ml-5">
                            <button
                                className="btn finance_manager-filter-button mr-2"
                                onClick={() => {
                                    if (!canApplyFilter()) {
                                        return;
                                    }
                                    if (!filterLoading) {
                                        startDateRef.current = startDate;
                                        endDateRef.current = endDate;
                                        centreRef.current = centre;
                                        bankStatusRef.current = bankStatus;

                                        getDetails();
                                        // setIsOpen(false);
                                    }
                                }}
                                disabled={(filterLoading || !applyFilter()) ? true : false}
                            >
                                Apply
                            </button>

                            <span className="clear-filter-cheques ml-2" onClick={handleClearFilter} style={{textDecoration: "none"}}>Clear</span>
                        </div>
                    </div>

                </div>

        </div>
    );
};

export default Filter;