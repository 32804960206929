import {
    SCRL_HANDLE_INIT,
    SCRL_HANDLE_DETAILS,
    SCRL_HANDLE_LOADING,
    SCRL_HANDLE_SCROLL_LOADING,
    SCRL_HANDLE_SCROLL_POSITION,
    SCRL_HANDLE_SCROLL_APPEND,
    SCRL_HANDLE_SEARCH_INIT,
    SCRL_HANDLE_SEARCH_CHANGE,
    SCRL_UPDATE_LIST,
    SCRL_RESET_LIST,
    HANDLE_SCRL_LIST,
    HANDLE_SCRL_DATA,
    SCRL_RESET_SEARCH
} from "../actions/types";

const scrollingListInititalState = {
    loading: false,
    scrollLoading: false,
    continueScroll: false,
    skip: 0,
    limit: 10,
    total: 0,
    scrollPositionY: 0,
    searchText: "",
    listKey: "",
    totalAmount: 0
};

function scrollingListReducer(state = scrollingListInititalState, action) {
    switch (action.type) {
        case SCRL_HANDLE_INIT:
            if (state.listKey === action.payload.listKey)
                return {
                    ...state,
                };
            return {
                ...state,
                listKey: action.payload.listKey,
                [action.payload.listKey]: [],
                skip: 0,
                limit: 10,
                total: 0,
                scrollPositionY: 0,
            };
        case SCRL_HANDLE_DETAILS:
            return {
                ...state,
                ...action.payload,
            };
        case SCRL_HANDLE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SCRL_HANDLE_SCROLL_LOADING:
            return {
                ...state,
                scrollLoading: action.payload,
            };
        case SCRL_HANDLE_SCROLL_POSITION:
            return {
                ...state,
                scrollPositionY: action.payload,
            };
        case SCRL_HANDLE_SCROLL_APPEND:
            const newList = [...state[state.listKey], ...(action.payload.listItems || [])];

            return {
                ...state,
                [state.listKey]: newList,
                skip: action.payload.skip,
                scrollLoading: action.payload.scrollLoading,
            };
        case SCRL_HANDLE_SEARCH_INIT:
            return {
                ...state,
                skip: 0,
                scrollLoading: false,
                loading: false,
                [state.listKey]: action.payload.list,
                continueScroll: true,
                total: action.payload.total,
            };
        case SCRL_HANDLE_SEARCH_CHANGE:
            return {
                ...state,
                searchText: action.payload,
                continueScroll: false,
                skip: 0,
            };
        case SCRL_UPDATE_LIST:
            return {
                ...state,
                [state.listKey]: action.payload,
            };
        case SCRL_RESET_LIST:
            return {
                ...scrollingListInititalState,
                [state.listKey]: undefined,
            };
        case HANDLE_SCRL_LIST:
            return {
                ...state,
                [state.listKey]: [...state[state.listKey], ...(action.payload.listItems || [])]
            };
        case HANDLE_SCRL_DATA:
            return {
                ...state,
                [state.listKey]: action.payload,
                total: state.total - (state[state.listKey].length - action.payload.length)
            };
        case SCRL_RESET_SEARCH:
            return {
                ...state,
                searchText: ''
            }
        default:
            return state;
    }
}

export default scrollingListReducer;
