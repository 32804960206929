/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import NotFound from "../../common/notFound";
import { State, City } from "country-state-city";
import EditParentsTab from "./editParentsTab";
import EditStudentTab from "./editStudentTab";
import { useMediaQuery } from "@mui/material";
import EditResourcesTab from "./editResourcesTab";
import swal from "sweetalert2";
import ToastMessage from "../../common/toastMessage";
import StudentModal from "./CommonModal";
import ParentModal from "./CommonModal";
import { segmentEvents } from "../../../utils/constants";

const initialState = {
	studentName: "",
	studentMobile: "",
	studentEmail: "",
	state: "",
	city: "",
	centre: "",
	course: "",
	batch: "",
	leadID: "",
	parentName: "",
	parentMobile: "",
	parentEmail: "",
	relation: "",
	tabData: [],
	parentDetailsFromBackend: false,
};

const tabDataInitialState = {
	brand_name: [],
	created_by_assoc: "",
	created_on: "",
	disabled_by_assoc: "",
	disabled_on: "",
	id: "",
	is_disabled: "",
	issue_date: null,
	serial_number: "",
	student_assoc: "",
	updated_on: "",
};

const EditStudent = (props) => {
	const isMobile = useMediaQuery("(max-width: 850px)");

	const navigate = useNavigate();
	const location = useLocation();

	const { student_id } = useParams();

	const [studentDetails, setStudentDetails] = useState(initialState);
	const [studentDataDump, setStudentDataDump] = useState(initialState);
	const [hasChangesStep1, setHasChangesStep1] = useState(false);
	const [hasChangesStep2, setHasChangesStep2] = useState(false);
	const [studentError, setStudentError] = useState({});
	const [TabModalFromHome, setTabModalFromHome] = useState(false);

	const [cityList, setCityList] = useState([]);

	const [loading, setLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);

	const [currentTab, set_currentTab] = useState("Student");
	const [tempTabList, setTempTabList] = useState([]);
	const [showTabModal, setShowTabModal] = useState(false);
	const [includesTab, setIncludesTab] = useState(false);
	const [editLoading, setEditLoading] = useState(false);

	const getStudentDetails = async () => {
		const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_PARENT_DETAILS}?student_id=${student_id}`);
		const data2 = await Api.doFetch("GET", {}, `${ApiUrl.TABLET_MANAGEMENT}${student_id}`);

		setLoading(false);
		if (data && data.payload && data2 && data2.payload) {
			const studentData = data.payload && data.payload.student_parent_detail;
			const resourcesData = data2.payload;
			//setting Tab dependent states (important)
			resourcesData.length === 0 && setTempTabList(Array(tabDataInitialState));
			resourcesData.length > 0 && setIncludesTab(true);

			if (studentData) {
				let state = "";

				let getCity = true;

				if (studentData.state) {
					let index = State.getStatesOfCountry("IN").findIndex((x) => x.name === studentData.state);

					if (index >= 0) {
						state = studentData.state;
						getCity = false;

						setCityList(City.getCitiesOfState("IN", State.getStatesOfCountry("IN")[index].isoCode));
					}
				}

				if (getCity) {
					const allCities = City.getCitiesOfCountry("IN");
					const cityData = allCities.find((city) => city.name === studentData.city);

					if (cityData) {
						const stateData = State.getStateByCodeAndCountry(cityData.stateCode, cityData.countryCode);

						if (stateData) {
							const stateCities = City.getCitiesOfState(cityData.countryCode, cityData.stateCode);

							state = stateData.name;
							setCityList(stateCities);
						}
					}
				}

				setStudentDetails({
					...studentDetails,
					studentName: studentData.name || "",
					studentMobile: studentData.mobile_number || "",
					studentEmail: studentData.email || "",
					state,
					city: studentData.city || "",
					leadID: studentData.lead_square_id || "",
					// Parent Data
					parentEmail: studentData.parent_email || "",
					parentMobile: studentData.parent_mobile_number || "",
					parentName: studentData.parent_name || "",
					relation: studentData.parent_relationship || "",
					parentDetailsFromBackend: !!(
						studentData.parent_email ||
						studentData.parent_mobile_number ||
						studentData.parent_name ||
						studentData.parent_relationship
					),
					tabData: resourcesData,
				});
				setStudentDataDump({
					...studentDetails,
					studentName: studentData.name || "",
					studentMobile: studentData.mobile_number || "",
					studentEmail: studentData.email || "",
					state,
					city: studentData.city || "",
					leadID: studentData.lead_square_id || "",
					// Parent Data
					parentEmail: studentData.parent_email || "",
					parentMobile: studentData.parent_mobile_number || "",
					parentName: studentData.parent_name || "",
					relation: studentData.parent_relationship || "",
					parentDetailsFromBackend: !!(
						studentData.parent_email ||
						studentData.parent_mobile_number ||
						studentData.parent_name ||
						studentData.parent_relationship
					),
					tabData: resourcesData,
				});
			}
		} else if (data.message && data.message === "Student not found") {
			setNotFound(true);
		}
	};

	const postTabData = async (payload) => {
		let postData = { tablet: payload };
		setEditLoading(true);

		const data = await Api.doFetch("POST", { ...postData }, `${ApiUrl.POST_TABLET_DATA}${student_id}`);

		if (data && data.status) {
			setTempTabList([]);
			getStudentDetails();
			setEditLoading(false);

			Api.trackEvent(segmentEvents.STUDENT.ADDED_TABLET);

			toast(<ToastMessage text={data.message} />);
		} else {
			swal.fire("Error!", "Internal Server Error", "error");
		}
	};

	useEffect(() => {
		setLoading(true);
		getStudentDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hasChangedStudentData = () => {
		const { studentName, studentMobile, studentEmail, state, city } = studentDetails;

		if (
			studentName === studentDataDump.studentName &&
			studentMobile === studentDataDump.studentMobile &&
			studentEmail === studentDataDump.studentEmail &&
			state === studentDataDump.state &&
			city === studentDataDump.city
		) {
			return false;
		}
		return true;
	};

	const validateStudentForm = () => {
		const { studentName, studentMobile, studentEmail, state, city } = studentDetails;

		let tempError = {};

		if (!studentName.trim()) {
			tempError.studentNameError = "Please enter student name";
		}
		if (studentMobile.trim().length !== 10) {
			tempError.studentMobileError = "Please enter a valid mobile";
		}
		if (studentEmail.trim()) {
			var validations = {
				email: [
					/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/, // eslint-disable-line
				],
			};
			let validation = new RegExp(validations["email"][0]);
			if (!validation.test(studentEmail)) {
				tempError.studentEmailError = "Please enter a valid student email";
			}
		}

		if (!state.trim()) {
			tempError.stateError = "Please select state";
		}
		if (!city.trim()) {
			tempError.cityError = "Please select city";
		}

		if (Object.keys(tempError).length === 0) {
			return true;
		} else {
			setStudentError({
				...studentError,
				...tempError,
			});
			return false;
		}
	};

	const validateParentForm = () => {
		const { parentMobile, parentEmail, parentName, relation } = studentDetails;

		let tempError = {};

		if (!parentName.trim()) {
			tempError.parentNameError = "Please enter parent's name";
		}

		if (!relation.trim()) {
			tempError.relationError = "Please select parent's relation";
		}

		if (parentMobile.trim() && parentMobile.trim().length < 10) {
			tempError.parentMobileError = "Please enter a valid mobile";
		} else if (!parentMobile.trim()) {
			tempError.parentMobileError = "Please enter parent's mobile";
		}

		if (parentEmail.trim()) {
			var validations = {
				email: [
					/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/, // eslint-disable-line
				],
			};
			let validation = new RegExp(validations["email"][0]);

			if (!validation.test(parentEmail)) {
				tempError.parentEmailError = "Please enter a valid parent email";
			}
		}

		if (Object.keys(tempError).length === 0) {
			setStudentError((s) => ({ ...s, parentNameError: "", parentMobileError: "", parentEmailError: "", relationError: "" }));
			return true;
		} else {
			setStudentError({
				...studentError,
				...tempError,
			});
		}

		return false;
	};

	const hasChangedParentData = () => {
		const { parentEmail, parentMobile, parentName, relation } = studentDetails;

		if (
			!(
				parentEmail === studentDataDump.parentEmail &&
				parentMobile === studentDataDump.parentMobile &&
				parentName === studentDataDump.parentName &&
				relation === studentDataDump.relation
			)
		) {
			return true;
		}
		return false;
	};

	const editParent = async () => {
		if (validateParentForm()) {
			let formdata = new FormData();

			formdata.append("parent_name", studentDetails.parentName || "");
			formdata.append("parent_mobile_number", studentDetails.parentMobile || "");
			formdata.append("parent_email", studentDetails.parentEmail || "");
			formdata.append("parent_relationship", studentDetails.relation || "");
			formdata.append("ID", student_id);

			setEditLoading(true);
			const data = await Api.doUpload("PUT", formdata, ApiUrl.EDIT_STUDENT);
			setEditLoading(false);

			if (data) {
				Api.trackEvent(segmentEvents.STUDENT.EDITED_PARENT);
				toast(<ToastMessage text={"Student profile updated successfully"} />);
				getStudentDetails();
			}
		} else {
			setHasChangesStep2(false);
		}
	};

	const editStudent = async () => {
		if (validateStudentForm()) {
			let formdata = new FormData();

			formdata.append("email", studentDetails.studentEmail);
			formdata.append("name", studentDetails.studentName);
			formdata.append("state", studentDetails.state);
			formdata.append("city_assoc", studentDetails.city);
			formdata.append("ID", student_id);

			setEditLoading(true);

			const data = await Api.doUpload("PUT", formdata, ApiUrl.EDIT_STUDENT);

			setEditLoading(false);

			if (data) {
				Api.trackEvent(segmentEvents.STUDENT.EDITED_STUDENT);
				setHasChangesStep1(false);
				toast(<ToastMessage text={"Student profile updated successfully"} />);
				getStudentDetails();
			}
		} else {
			setHasChangesStep1(false);
		}
	};

	const goBack = () => {
		if (location && location.state && location.state.fromFeeSummary) {
			navigate(-1);
		} else {
			navigate(`/student/detail/${student_id}`);
		}
	};

	const clickTab = (tabName) => {
		if (currentTab === "Resources") {
			(studentDetails.tabData.length > 0 && tempTabList.length > 0) || (includesTab && tempTabList.length > 0)
				? setShowTabModal(true)
				: set_currentTab(tabName);
		}

		if (currentTab === "Student") {
			hasChangedStudentData() ? setHasChangesStep1(true) : set_currentTab(tabName);
		}

		if (currentTab === "Parents") {
			hasChangedParentData() ? setHasChangesStep2(true) : set_currentTab(tabName);
		}
	};

	if (notFound) {
		return <NotFound />;
	}

	if (loading) {
		return (
			<div className="loader_container">
				<Loader />
			</div>
		);
	}

	return (
		<div className="edit_student_container">
			<div className="reversal_detail_header_student" style={{ justifyContent: "space-between" }}>
				<div className="reversal_detail_header-text">
					<img
						src={LeftArrow}
						alt="go back"
						className="cursor-pointer"
						onClick={() => {
							if (currentTab === "Resources") {
								if ((studentDetails.tabData.length > 0 && tempTabList.length > 0) || (includesTab && tempTabList.length > 0)) {
									setShowTabModal(true);
									setTabModalFromHome(true);
								} else {
									goBack();
								}
							} else {
								goBack();
							}
						}}
					/>

					<span className="reversal_detail_header_text ml-2">Edit Student</span>
				</div>

				{studentDetails.leadID && (
					<div className="reversal_detail_header-lead">
						<span>Lead ID:</span>
						<div className="reversal_detail_header-lead-text">{studentDetails.leadID}</div>
					</div>
				)}
			</div>

			<ul className="nav nav-tabs" style={{ marginTop: 20 }}>
				<li className={currentTab === "Student" ? "active" : ""} onClick={() => clickTab("Student")}>
					<a className={`fee_summary_tab ${currentTab === "Student" ? "fee_summary_tab_selected" : ""}`}>Student</a>
				</li>

				<li className={currentTab === "Parents" ? "active" : ""} onClick={() => clickTab("Parents")}>
					<a className={`fee_summary_tab ml-3  ${currentTab === "Parents" ? "fee_summary_tab_selected" : ""}`}>Parents</a>
				</li>

				<li className={currentTab === "Resources" ? "active" : ""} onClick={() => clickTab("Resources")}>
					<a className={`fee_summary_tab ml-3  ${currentTab === "Resources" ? "fee_summary_tab_selected" : ""}`}>Resources</a>
				</li>
			</ul>

			<div className="tab-content">
				{currentTab === "Student" && (
					<div
						className={currentTab === "Student" ? "tab-pane fade in active" : "tab-pane fade"}
						style={{
							backgroundColor: "#FFFFFF",
							border: "none",
						}}
					>
						<div className={`courseDetailWrap${isMobile ? " is-mobile" : ""}`}>
							<div className="tab_card_div">
								<EditStudentTab
									data={studentDetails}
									handleData={setStudentDetails}
									error={studentError}
									handleError={setStudentError}
									cityList={cityList}
									setCityList={setCityList}
									disableMobile={true}
								/>
								<div className="d-flex justify-content-between" style={{ width: "100%", padding: 20 }}>
									<button className="btn btn-md add_tab_back" onClick={goBack}>
										Cancel
									</button>
									<button
										disabled={!hasChangedStudentData()}
										className="btn btn-md add_tab_next ml-2"
										onClick={() => {
											if (!editLoading) {
												validateStudentForm() && hasChangedStudentData() && editStudent();
											}
										}}
									>
										Save
										{editLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
									</button>
								</div>
							</div>
						</div>
					</div>
				)}

				{currentTab === "Parents" && (
					<div
						className={currentTab === "Parents" ? "tab-pane fade in active" : "tab-pane fade"}
						style={{
							backgroundColor: "#FFFFFF",
							border: "none",
						}}
					>
						<div className={`courseDetailWrap${isMobile ? " is-mobile" : ""}`}>
							<div className="tab_card_div">
								<EditParentsTab
									data={studentDetails}
									handleData={setStudentDetails}
									error={studentError}
									handleError={setStudentError}
									editView={true}
								/>
								<div className="d-flex justify-content-between" style={{ width: "100%", padding: 20 }}>
									<button className="btn btn-md add_tab_back" onClick={goBack}>
										Cancel
									</button>
									<button
										disabled={!hasChangedParentData()}
										className="btn btn-md add_tab_next ml-2"
										onClick={() => {
											if (!editLoading) {
												validateParentForm() && hasChangedParentData() && editParent();
											}
										}}
									>
										Save
										{editLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
									</button>
								</div>
							</div>
						</div>
					</div>
				)}

				{currentTab === "Resources" && (
					<div
						className={currentTab === "Resources" ? "tab-pane fade in active" : "tab-pane fade"}
						style={{
							backgroundColor: "#FFFFFF",
							border: "none",
						}}
					>
						<EditResourcesTab
							editLoading={editLoading}
							tabData={studentDetails.tabData}
							postTabData={postTabData}
							tempTabList={tempTabList}
							setTempTabList={setTempTabList}
							showTabModal={showTabModal}
							setShowTabModal={setShowTabModal}
							includesTab={includesTab}
							setIncludesTab={setIncludesTab}
							tabDataInitialState={tabDataInitialState}
							goBack={goBack}
							TabModalFromHome={TabModalFromHome}
							setTabModalFromHome={setTabModalFromHome}
						/>
					</div>
				)}
			</div>

			<StudentModal
				isOpen={hasChangesStep1}
				setIsOpen={setHasChangesStep1}
				onCancel={() => {
					setStudentDetails({ ...studentDataDump });
					setHasChangesStep1(false);
				}}
				onClose={() => {
					setHasChangesStep1(false);
				}}
				onSave={editStudent}
			/>

			<ParentModal
				isOpen={hasChangesStep2}
				setIsOpen={setHasChangesStep2}
				onCancel={() => {
					setStudentDetails({ ...studentDataDump });
					setHasChangesStep2(false);
				}}
				onClose={() => {
					setHasChangesStep2(false);
				}}
				onSave={editParent}
			/>
		</div>
	);
};

export default EditStudent;
