import { useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import TopNavBar from "../navbar/TopNavBar";

// https://ap.zinedu.com/users/add-page-action/

//action_name: All , page_name: role
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const RoleList = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    roleList: [],
    error: "",
    allRolesList: []
  });
  let data = localStorage.getItem("userdetail");
  data = JSON.parse(data);
  const card = (count, title) => {
    return (
      <>
        <CardContent>
          <Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
            {count}
          </Typography>
          <Typography variant="h5" component="div"></Typography>
          <Typography sx={{ fontSize: 22 }} color="text.secondary">
            {title}
          </Typography>
        </CardContent>
      </>
    );
  };

  const tableCellStyles = {
    fontSize: "1rem",
  };

  const initFunction = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}users/list-role-with-user/`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${data.token}`,
          },
        }
      );
      const D = await response.json();
      if (response.status === 401) {
        Swal.fire("Unauthorised?", "User logged into another device", "error");
        localStorage.clear();
        navigate("/error");
      }
      if (response.status === 200) {
        setUserDetails({
            ...userDetails,
            roleList: D?.payload?.roles,
            allRolesList: D?.payload?.roles
        })

      }
    } catch (err) {
      setUserDetails({
        ...userDetails,
        error: err.message,
      });
    }
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = userDetails?.allRolesList.filter((row) => {
      return (
        row.role.toLowerCase().includes(searchedVal.toLowerCase()) 
      );
    });
    setUserDetails({
      ...userDetails,
      roleList: filteredRows,
    });
  };

  useEffect(() => {
    initFunction();
  }, []);

  const keyValueExtract = (arr, keyName) => {
    let res = "";
    arr.map((item) => {
      res += item[keyName] + ", ";
    });
    return res;
  };

  return (
    <div style={{ display: "flex" }}>

      <div style={{ width: "100%", padding: "0" }}>
       

       

        <div style={{ margin: "1rem" }}>
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => requestSearch(e.target.value)}
          />
        </div>

        <div style={{ margin: "1rem" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: 12 }}>Role Name</TableCell>
                  <TableCell sx={{ fontSize: 12 }} align="right">
                    Users
                  </TableCell>
                  <TableCell sx={{ fontSize: 12 }} align="right">
                   Action
                  </TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                {userDetails?.roleList?.map((row) => (
                  <TableRow
                    key={row.role_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: 12 }} align="right">
                      {row.role}
                    </TableCell>
                    <TableCell sx={{ fontSize: 12 }} align="right">
                    <div >
                    {keyValueExtract(row.users, "first_name").substring(0,200)} ...
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontSize: 12 }} align="right">
                    <Button variant="contained" onClick={(e) => navigate('/role/add', { state: {
                        roleId: row.role_id,
                        roleName: row.role
                    } })} >Edit</Button>
                    </TableCell>
                  
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RoleList;
