import React from "react";
import { Asterisk, CourseTypeMap } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import { Select } from "antd";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import { ThemeProvider } from "@mui/styles";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

// const muiTheme = createMuiTheme({
//     toggle: {
//       thumbOnColor: '#BC0000',
//     }
//   }); 

const { Option } = Select;

const BatchBasicDetails = ({ 
    view = false, 
    setBatchData, 
    batchData, 
    courseListMetaData,
    selectedCourseInfo,
    batchError,
    setBatchError,
    courseNameHandler,
    selectedCourseName
}) => {

    const selectedCourseChangeHandler = (value) => {
        setBatchError({
            ...batchError,
            course_id: ""
        })
        setBatchData({ ...batchData, course_id: value });
        courseNameHandler(value);
    };
    return (
        <>
            <div className="course_add_header_container">Basic details</div>

            <div className="course_add_data_container">
                <div className="row">
                    <div className={view ? "col-md-10" : "col-md-11"}>
                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">Course name {!view ? <Asterisk /> : ""}</p>

                            {view ? (
                                <p className="course_view_text_data">{selectedCourseName}</p>
                            ) : (
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select course name"
                                    value={batchData.course_id}
                                    onChange={selectedCourseChangeHandler}
                                    optionFilterProp="children"
                                    showArrow
                                    allowClear
                                    showSearch
                                >
                                    {courseListMetaData.map((options, index) => {
                                        return (
                                            <Option key={index} value={options.id}>
                                                {options.class_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            )}
                            {batchError.course_id && <ErrorText text={batchError.course_id} />}
                        </div>
                    </div>
                </div>
                {selectedCourseInfo.course_type === "" && selectedCourseInfo.subjects.length === 0 && selectedCourseInfo.resources.length === 0 ? (
                    <></>
                ) : (
                    <div className="row batch_read_only_wrapper">
                        <div className={view ? "col-md-10" : "col-md-11"}>
                            <div className="col-md-4 course_data_wrapper">
                                <p className="view_data_static_header">Course type</p>

                                <p className="view_data_static_value">
                                    {selectedCourseInfo.course_type === null || selectedCourseInfo.course_type === ""
                                        ? "-"
                                        : CourseTypeMap[selectedCourseInfo.course_type] }
                                </p>
                            </div>

                            <div className="col-md-4 course_data_wrapper">
                                <p className="view_data_static_header">List of subjects</p>

                                <div className="course_view_tag_container">
                                    {selectedCourseInfo.subjects.length === 0 && <div className="view_data_static_value">-</div>}
                                    {selectedCourseInfo.subjects.slice(0, 3).map((data, index) => {
                                        return (
                                            <div className="course_view_tag_wrapper" key={index}>
                                                <span className="course_view_tag_text">{data.subject_name}</span>
                                            </div>
                                        );
                                    })}
                                    {selectedCourseInfo.subjects.length > 3 && (
                                        <BootstrapTooltip
                                            disableFocusListener
                                            enterTouchDelay={0}
                                            title={
                                                selectedCourseInfo.subjects.length > 3 &&
                                                selectedCourseInfo.subjects
                                                    .slice(3, selectedCourseInfo.subjects.length)
                                                    .map((item) => {
                                                        return ` ${item.subject_name}`;
                                                    })
                                                    .toString()
                                            }
                                        >
                                            <div className="course_view_tag_wrapper cursor-pointer">
                                                <span className="course_view_tag_text">{`+${selectedCourseInfo.subjects.length - 3}`}</span>
                                            </div>
                                        </BootstrapTooltip>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-4 course_data_wrapper">
                                <p className="view_data_static_header">Resources</p>

                                <div className="course_view_tag_container">
                                    {selectedCourseInfo.resources.length > 0 ? (
                                        selectedCourseInfo.resources.map((item, index) => {
                                            return (
                                                <React.Fragment key={`course-resource-${index}`}>
                                                    {item.course_includes_tablet ? (
                                                        <div className="course_view_tag_wrapper" key={index}>
                                                            <span className="course_view_tag_text">Tab</span>
                                                        </div>
                                                    ) : (
                                                        <div className="view_data_static_value">-</div>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })
                                    ) : (
                                        <div className="view_data_static_value">-</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {
                    !view &&
                    <div className="row add_course_division">
                        <div className={view ? "col-md-10" : "col-md-11"}>
                            <div className="col-md-4 course_data_wrapper">
                                {view ? (
                                    <>
                                        <p className="student_filter_drop_header">School integrated</p>
                                        <p className="course_view_text_data">{batchData.is_school_integrated ? "Yes" : "No"}</p>
                                    </>
                                ) : (
                                    <div className="add_course_resource_container">
                                        <span className="add_course_resource_text">Is it school integrated program?</span>

                                        <FormControlLabel
                                            control={
                                                // <Switch
                                                //     checked={batchData.is_school_integrated}
                                                //     trackColor={{ false: "#FFD7D7", true: "#EFFFE2" }}
                                                //     thumbColor={{ false: "#BC0000", true: "#53BC00" }}
                                                //     className="toggle-switch"
                                                // />
                                                <Switch checked={batchData.is_school_integrated} color={batchData.is_school_integrated ? "success" : "error"} style={{color : batchData.is_school_integrated ? "" : "#BC0000"}} />
                                            }
                                            onClick={() => {
                                                setBatchData({
                                                    ...batchData,
                                                    is_school_integrated: !batchData.is_school_integrated,
                                                });
                                            }}
                                            className="student_course_toggle_text"
                                            style={{
                                                marginTop: "0.5rem",
                                            }}
                                        />

                                        <span className="add_course_resource_text">{batchData.is_school_integrated ? "Yes" : "No"}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }

            </div>
        </>
    );
};

export default BatchBasicDetails;
