import React, { useState } from 'react'
import Accordian from '../../../../utils/accordian'
import ArrowDown from "../../../../assets/img/arrow_down.svg";
import ArrowUp from "../../../../assets/img/arrow_up.svg";
import Empty from "../../../../assets/img/empty_student.svg";


const MppAccordianComponent = ({ data, selectedBatches, handleState }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [batchList, setBatchList] = useState(data.batches)

    const handleSearch = (q) => {

        let query = q.toLowerCase().trim();
        let filtered = data.batches.filter((batch) => { return batch.batch_name.toLowerCase().trim().includes(query) })
        if (query.length > 0) {
            setBatchList(filtered)
        }
        else {
            setBatchList(data.batches)
        }
    }

    const isAllbatchesSelected = () => {
        let checked = false;
        if (selectedBatches.length === 0) return false
        checked = data && data?.batches.every((batch) => { return selectedBatches.some((selectedBatches) => batch.batch_id === selectedBatches.batch_id) })
        if (checked === false) {
            return;
        }
        return checked;
    }
    const isChecked = (data) => {
        let checked = false
        if (selectedBatches.length > 0) {
            checked = selectedBatches.some((batch) => batch.batch_id === data.batch_id)
        }
        return checked
    }
    const handleChange = (e, batch) => {
        let temp = selectedBatches;
        if (e.target.checked) {
            let obj = {}
            obj.course_id = data.course_id
            obj.course_name = data.course_name
            obj.batch_id = batch.batch_id
            obj.batch_name = batch.batch_name
            temp.push(obj)
            handleState({ selectedBatches: temp })
        }
        else {
            temp = selectedBatches.filter((selected) => selected.batch_id !== batch.batch_id)
            handleState({ selectedBatches: temp })
        }
    }

    return (
        <>
            <div className="mpp_assign_card width99 d-flex align-items-center justify-content-between"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className="d-flex">
                    <input
                        className="form-check-input assign-question-checkbox"
                        type="checkbox"
                        checked={isAllbatchesSelected()}
                        style={{ cursor: "pointer", marginRight: 15 }}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            let temp = selectedBatches;
                            if (e.target.checked) {
                                data.batches?.map((batch) => {
                                    let obj = {}
                                    obj.course_id = data.course_id
                                    obj.course_name = data.course_name
                                    obj.batch_id = batch.batch_id
                                    obj.batch_name = batch.batch_name
                                    let foundIndex = temp.findIndex(x => x.batch_id == batch.batch_id)
                                    if (foundIndex == -1)
                                        temp.push(obj)
                                })
                            }
                            else {
                                data.batches?.map((batch) => {
                                    temp = temp.filter((selected) => selected.batch_id !== batch.batch_id)
                                })
                            }
                            // console.log('checked', temp)
                            handleState({ selectedBatches: temp })
                        }}
                    />
                    <span className="group_modal_text">{data.course_name}</span>
                </div>
                <div className='d-flex'>
                    {isExpanded &&
                        <input
                            onClick={(e) => { e.stopPropagation(); }}
                            onChange={(e) => {
                                setSearchQuery(e.target.value)
                                handleSearch(e.target.value)
                            }}

                            className="mpp_search_input my-1"
                            style={{ cursor: "pointer", marginRight: 20 }}
                            placeholder="Search by batch"

                        />}
                    <img className="mr-1 ml-2"
                        src={isExpanded ? ArrowUp : ArrowDown}
                        alt="arrow"
                    />
                </div>

            </div>
            <div style={{ width: "93%", marginLeft: '6%' }}>
                <Accordian
                    noStyles={true}
                    expand={isExpanded}
                >
                    <div className='mpp_batch_scroll_container'>
                        {batchList?.map((data, index) => {
                            return <div key={index} className='mpp_bottom_border'> <input
                                className="form-check-input assign-question-checkbox"
                                type="checkbox"
                                checked={isChecked(data)}
                                style={{ cursor: "pointer", marginRight: 15 }}
                                onChange={(e) => handleChange(e, data)}
                            />
                                <div className="group_modal_text">{data.batch_name}</div>
                            </div>
                        })}
                        {searchQuery.length > 0 && batchList.length === 0 && <div className="student_search_empty_container mt-4">
                            <div className="student_search_empty_img_container" style={{ height: 50, alignItems: "center" }}>
                                <img src={Empty} alt="not found" className="student_search_empty_img" />
                            </div>
                            {
                                <>
                                    <span className="student_search_empty_header mb-1">
                                        No results found
                                    </span>
                                    <span className="student_search_empty_label">
                                        Try adjusting your search to find
                                    </span>
                                    <span className="student_search_empty_label">
                                        the best results
                                    </span>
                                </>
                            }
                        </div>}
                    </div>


                </Accordian>
            </div>
        </>


    )
}

export default MppAccordianComponent