import React from "react";
import Navbar from "../navbar/Navbar";
import TopNavBar from "./../navbar/TopNavBar";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const Layout = () => {
    const [navExpanded, setNavExpanded] = React.useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <TopNavBar />

            <div style={{ display: "flex" }}>
                <Navbar setNavExpanded={setNavExpanded} />

                <div style={{ width: isMobile ? "100%" : navExpanded ? "calc(100% - 240px)" : "calc(100% - 85px)" }}>

                    <Outlet />

                </div>
            </div>
        </>
    );
}

export default Layout;
