import React from 'react'
import AddSubjectImg from "../../assets/img/add_subject.svg";
import ListImage from "../../assets/img/list-att-icon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { SCRL_RESET_LIST } from '../../actions/types';

const Subject = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    return (

        <div className="containerMain">

            <div className="payment_reversal_container">

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/subject/add");
                    }}
                >

                    <img
                        src={AddSubjectImg}
                        alt="add subject"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Add subject
                    </span>

                </div>

                <div
                    className="payment_reversal_container_history"
                    onClick={() => {
                        dispatch({ type: SCRL_RESET_LIST })
                        navigate("/subject/list");
                    }}
                >

                    <img
                        src={ListImage}
                        alt="subject list"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        List of subjects
                    </span>

                </div>

            </div>

        </div>

    )
}

export default Subject;