import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReceiptImg from "../../assets/img/receipt.svg";
import ReversalImg from "../../assets/img/reversal.svg";

const OptionMenu = ({
    openReceipt,
    setReversal,
    reversalPending,

    canViewReceipt,
    canDoReversal
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    let options = [
        "View Receipt",
        "Initiate Reversal"
    ];

    if(reversalPending){
        options = options.filter(x => x !== "Initiate Reversal")
    }

    if(!canViewReceipt){
        options = options.filter(x => x !== "View Receipt")
    }
    if(!canDoReversal){
        options = options.filter(x => x !== "Initiate Reversal")
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, value) => {
        if(e.nativeEvent.target.outerText === "View Receipt" ){
            openReceipt();
        }else if(e.nativeEvent.target.outerText === "Initiate Reversal" ){
            setReversal();
        }
        setAnchorEl(null);
    };

    return (
        <div className="options-container-div" >
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon
                    style={{
                        fontSize: "2rem"
                    }}
                />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{}}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                style = {{
                    marginLeft: "1rem",
                    marginTop: "-0.8rem"
                }}
                className="payment_history_options"
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option} selected={option === 'Pyxis'} onClick={handleClose}>
                        <img 
                            src={option === "View Receipt" ? ReceiptImg : ReversalImg} 
                            alt="img"
                            style = {{ width: "1.7rem" }}
                        />
                        <span className="payment_history_options_text">
                            {option}
                        </span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default OptionMenu;