import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import TickWhite from "../../../assets/img/tick_white.svg";
import { segmentEvents } from "../../../utils/constants";

const ToastMessage = () => (
	<div
		className="reversal_toast"
		style={{
			"--toast-status": "#35CB00"
		}}
	>
		<img
			src={TickWhite}
			alt={"success"}
			className="mr-2"
			style={{ width: "1.2rem" }}
		/>
		File Uploaded successfully!
	</div>
)

export default function UploadAdmissionForm() {

    const navigate = useNavigate();
    const location = useLocation();
    const { student_id } = useParams();

    const [admission_form, set_admission_form] = useState();
    const [admission_form_name, set_admission_form_name] = useState();
    const [isSubmitButtonDisabled, set_isSubmitButtonDisabled] = useState(false);

	const isMobile = useMediaQuery("(max-width:600px)");

    const upploadAdmissionForm = async () => {
        if (admission_form === undefined || admission_form === "") {
            Swal.fire("Please Upload admission form");
            return false;
        }
        if (!["application/pdf"].includes(admission_form.type.toLowerCase())) {
            Swal.fire("Please upload .pdf files only");
            return false;
        } else if (admission_form.size > 10485760) {
            Swal.fire("Please upload .pdf files upto 10Mb");
            return false;
        } else {
            set_isSubmitButtonDisabled(true);
            let formdata = new FormData();
            formdata.append(
                "student_section_course_id",
                location.state.student_course_id
            );
            formdata.append("admission_form", admission_form);
            
            const data = await Api.doUpload("POST", formdata, `${ApiUrl.ADMISSION_FORM}${student_id}/`);

            set_isSubmitButtonDisabled(false);
            
            if (data && data.status) {
                Api.trackEvent(segmentEvents.STUDENT.UPLOADED_SIGNED_FORM);
                Swal.fire("Success", data.message, "success");
                navigate(`/student/detail/${student_id}`);
            } else if (data && ((data.payload && data.payload.Error) || data.message)) {
                if (data.payload && data.payload.Error) {
                    Swal.fire("Error", data.payload.Error, "error");
                } else if (data.message) {
                    Swal.fire("Error", data.message, "error");
                }
            }
        }
    };

    const getAdmissionForm = (event) => {
        set_admission_form(event.target.files[0]);
        set_admission_form_name(event.target.files[0].name);
        toast(<ToastMessage /> );
    };

    return (
        <div>
            <div className="container-fluid">

                <div className="upload_form_header mt-3">

                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate(`/student/detail/${student_id}`);
                        }}
                        style={{ width: "1.8rem" }}
                    />

                    <span className="upload_form_header_text">
                        Upload Signed Form
                    </span>

                </div>

                <div className={`upload_form_container${isMobile ? " is-mobile" : ""}`}>

                    <div className="upload_signed_form_detail_container">
                        <p className="upload_signed_form_detail_header">Upload Signed Admission Form (PDF Only)</p>
                        <p>
                            Please ensure that the admission form has been read and signed
                            by the student and parent / guardian before uploading{" "}
                        </p>
                    </div>

                    <center>
                        <div className="filesBox">
                            <div className="inputFile"></div>
                            <input
                                type="file"
                                className="fileup"
                                onChange={(event) => getAdmissionForm(event)}
                            />

                            {admission_form != null ? <>{admission_form_name}</> : <><span className="fileUploadHelperText">
                                Drag and drop or browse to choose a file
                            </span>
                                <br />
                                <span>
                                    Max pdf size <b>10 MB</b>
                                </span></>}

                        </div>
                    </center>

                    <div 
                        className="btnWrp"
                        style = {{
                            marginBottom: "0.7rem",
                            marginTop: "-1.2rem"
                        }}
                    >
                        <button
                            onClick={() => {
                                if(!isSubmitButtonDisabled){
                                    upploadAdmissionForm()
                                }
                            }}
                            className="customSideNavBtn"
                        >
                            Update
                            {isSubmitButtonDisabled && <span className="ml-2 spinner spinner-white mr-3"></span>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
