import React from "react";
import ListImage from "../../assets/img/list-att-icon.svg";
import mppIcon from "../../assets/img/mppIcon.svg"
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { SCRL_RESET_LIST } from "../../actions/types";


const Mpp = () => {

    let navigate = useNavigate();

    const dispatch = useDispatch();

    return (
        <div className="containerMain">

            <div className="payment_reversal_container">

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/mpp/add") 
                    }}
                >

                    <img
                        src={mppIcon}
                        alt="add course"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Create MPP
                    </span>

                </div>

                <div
                    className="payment_reversal_container_history"
                    onClick={() => {
                        dispatch({ type: SCRL_RESET_LIST });
                        navigate("/mpp/list");
                    }}
                >

                    <img
                        src={ListImage}
                        alt="subject list"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        List of MPPs
                    </span>

                </div>

            </div>

        </div>
    );
}
export default Mpp;
