import { HANDLE_CENTRE_DETAILS, RESET_CENTRE_DETAILS, SET_CENTRE_SCROLL } from '../actions/types';

const initialState = {
    centreList: [],
    scrollPositionY: 0,
    searchText: "",
    skip: 0,
    limit: 20,
    continueScroll: false
};
//eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_CENTRE_DETAILS:
            return {
                ...state,
                ...action.payload
            };
        case RESET_CENTRE_DETAILS:
            return initialState;

        case SET_CENTRE_SCROLL:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}