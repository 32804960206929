import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../common/Loader";
import FinanceLoader from "../../common/FinanceLoader";
import Empty from "../../../../assets/img/empty_student.svg";
import BackTop from "../../common/backTop";
import CashNotConfirmedHeader from "./cashNotConfirmedHeader";
import CashNotConfirmedDetails from "./cashNotConfirmedDetails";
import ApiUrl from "../../../common/apiUrl";
import Api from "../../../common/api";
import Swal from "sweetalert2";
import { currencyFormatter } from "../../../../utils/helperFunctions";
import Filter from "../../common/pendingCashFilter";
// import { getMyclassroomCentre } from "../../../../utils/helperFunctions";
import { useSelector } from "react-redux";

const CashNotConfirmed = () => {

    // const { myclassroomCentre } = useSelector((state) => state.common);

    const [filterLoading, setFilterLoading] = useState(false);

    const [loading, setLoading] = useState(false);

    const [centre, setCentre] = useState([]);

    const centreRef = useRef(centre);

    const [pendingList, setPendingList] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    const getPendingDeposit = async() => {

        let payload = {};

        if (centreRef && centreRef.current.length > 0) {
            payload.centre_ids = centreRef.current;
        }

        const data = await Api.doFetch("POST", payload, ApiUrl.PENDING_CASH_CONFIRM);

        setLoading(false);
        setFilterLoading(false);

        if (data?.status) {
            let totalCash = 0;

            const computedPayload = data?.data?.results.map(paymentGroup => {

                totalCash += +paymentGroup.total_amount_paid;

                return { ...paymentGroup }
            })
            
            setTotalAmount(totalCash);
            setPendingList(computedPayload)
        } else {
            Swal.fire("Error", data?.message || "Internal server error", "error");
        }
    }

    useEffect(() => {

        // if (myclassroomCentre && myclassroomCentre.length === 0) {
        //     getMyclassroomCentre();
        // }

        setLoading(true);
        getPendingDeposit();
    }, [])

    if (loading) {
        return (
            <>
                <FinanceLoader text="Loading cash not confirmed details..." />
            </>
        );
    }

    return (
        <div className="course_list_container">
            <div className="pending-cheques-scroll-container">
                <CashNotConfirmedHeader />

                <Filter 
                    centre = {centre}
                    setCentre = {setCentre}
                    centreRef = {centreRef}
                    getDetails = {() => {
                        setFilterLoading(true);
                        getPendingDeposit();
                    }}
                    filterLoading = {filterLoading}
                />

                <div className="cash-details-header">
                    <span className="heading-header-light">Total cash not confirmed :</span> <span>₹{currencyFormatter(totalAmount, false)}</span>
                </div>

                <div className="cash-not-confirmed-container">
                    {
                        filterLoading ?
                        <div className="loader_scroll_container">
                            <Loader />
                        </div>
                        : 
                        pendingList.length === 0 ?
                        <div className="student_search_empty_container mt-5">
                            <div className="student_search_empty_img_container mt-5">
                                <img src={Empty} alt="not found" className="student_search_empty_img" />
                            </div>

                            <p className="student_search_empty_header">No Pending cash deposits found</p>
                            <span className="student_search_empty_label">
                                There are no cash pending to be confirmed 
                            </span>
                            <span className="student_search_empty_label">
                                at the moment.
                            </span>
                        </div>
                        :
                        pendingList.map((data, index) => {
                            return <CashNotConfirmedDetails data={data} key={index} />;
                        })
                    }
                </div>

                <BackTop target="pending-cheques-scroll-container" />
            </div>
        </div>
    );
};

export default CashNotConfirmed;
