import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import LeftArrow from "../../../assets/img/backArrowBlack.svg";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import Loader from "../../common/Loader";
import { SCRL_RESET_LIST } from '../../../actions/types';
import { useDispatch } from 'react-redux';


const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const ScheduleHistory = () => {
    const { mppID } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [scheduleData, setScheduleData] = useState(null)
    const [courseList, setCourseList] = useState([]);
    const [loading, setLoading] = useState(false)

    const getScheduleHistory = async () => {
        setLoading(true)
        let url = `${ApiUrl.MPP}/schedule/history?mpp_ids=${mppID}`;
        const data = await Api.doFetch("GET", {}, url);
        setLoading(false);
        if (data.status) {
            const payload = data.data
            if (payload) {
                setScheduleData(payload)
                let courseList = []
                payload.batches_info.map(x => {
                    if (courseList.find(c => c.course_id == x.course_id) == null) {
                        let tempList = payload.batches_info.filter(y => x.course_id == y.course_id)
                        courseList.push({
                            course_name: x.course_name,
                            course_id: x.course_id,
                            batches: tempList
                        })
                    }
                })
                // console.log('courseList', courseList)
                setCourseList(courseList)
                //     handleState({ subjectList: payload.subjects })
                //     handleState({ targetExamList: payload.target_exams })
                // }
            }
        }
    }
    useEffect(() => {
        getScheduleHistory()
    }, [])

    if (loading) {
        return <div className="loader_container">
            <Loader />
        </div>
    }
    return (
        <div className="course_list_container">
            <div className="course_list_header">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate(`/mpp/${mppID}`);
                        }}
                    />
                    <span className="reversal_detail_header_text m-2">{scheduleData?.mpp_details.mpp_name}</span>
                </div>

                <button
                    className="btn  mr-2 mpp_assign_button"
                    onClick={() => {
                        dispatch({ type: SCRL_RESET_LIST })
                        navigate(`/mpp/assign`, { state: { mppIds: [mppID], fromScheduleHistory: true } })
                    }}
                >
                    Add more batches
                </button>

            </div>
            <div className="course_list_card">
                <div className="mpp_divider2">
                    <p className="course_list_card_header">Program</p>

                    <p className="course_list_card_value">{scheduleData?.mpp_details?.target_exam || '-'}</p>
                </div>

                <div className="mpp_divider2">
                    <p className="course_list_card_header">Subject</p>

                    {scheduleData?.mpp_details?.subject_name ?
                        scheduleData?.mpp_details?.subject_name?.length < 35 ? (
                            <p className="course_list_card_value">{scheduleData?.mpp_details?.subject_name}</p>
                        ) : (
                            <BootstrapTooltip
                                disableFocusListener
                                enterTouchDelay={0}
                                title={scheduleData?.mpp_details?.subject_name}
                            >
                                <p style={{ cursor: "pointer" }} className="course_list_card_value">
                                    {scheduleData?.mpp_details?.subject_name?.substring(0, 35) + "..."}
                                </p>
                            </BootstrapTooltip>
                        )
                        :
                        <p>-</p>}
                </div>

                <div className="mpp_divider3">
                    <p className="course_list_card_header">Name</p>

                    <p className="course_list_card_value">{scheduleData?.mpp_details.mpp_name}</p>
                    <p className="payment_details_list_card_header subvalue">ID: {scheduleData?.mpp_details.mpp_id}</p>
                </div>

                <div className="mpp_divider4">
                    <div className="course_list_card_header">Tags</div>

                    <div className="course_list_card_value d-flex chapter_selected_tags_container">

                        {scheduleData?.mpp_details.tag_name.length > 1 ? <> {scheduleData?.mpp_details.tag_name[0].length > 13 ? <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={scheduleData?.mpp_details.tag_name[0]}>
                            <p style={{ cursor: "pointer" }} className="selected_tags_name">
                                {scheduleData?.mpp_details.tag_name[0].substr(0, 13) + "..."}
                            </p>
                        </BootstrapTooltip> :
                            <p className="selected_tags_name">
                                {scheduleData?.mpp_details.tag_name[0]}
                            </p>}
                            {scheduleData?.mpp_details.tag_name[1].length > 13 ? <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={scheduleData?.mpp_details.tag_name[1]}>
                                <p style={{ cursor: "pointer" }} className="selected_tags_name">
                                    {scheduleData?.mpp_details.tag_name[1].substr(0, 13) + "..."}
                                </p>
                            </BootstrapTooltip> : <p className="selected_tags_name">
                                {scheduleData?.mpp_details.tag_name[1]}
                            </p>}</> :
                            scheduleData?.mpp_details.tag_name.length === 0 ? '-' : <p className="selected_tags_name">
                                {scheduleData?.mpp_details.tag_name[0] ? scheduleData?.mpp_details.tag_name[0] : '-'}
                            </p>
                        }
                        {scheduleData?.mpp_details.tag_name?.length > 2 && (
                            <BootstrapTooltip
                                disableFocusListener
                                enterTouchDelay={0}
                                title={
                                    scheduleData?.mpp_details.tag_name?.length > 1 &&
                                    scheduleData?.mpp_details.tag_name.slice(2, scheduleData?.mpp_details.tag_name?.length)
                                        .map((tag) => {
                                            return tag;
                                        })
                                        .join(", ")
                                }
                            >
                                <div className="selected_tags_name" style={{ cursor: "pointer" }}>{`+${scheduleData?.mpp_details.tag_name?.length - 2}`}</div>
                            </BootstrapTooltip>
                        )}
                    </div>
                </div>
            </div>
            <div className="course_list_card d-column align-items-start" >
                <span className="search_student_detail_header">DPP Details</span>
                <div className='d-flex-wrap' style={{ width: '100%' }} >
                    <div className="mpp_details_card d-column" >
                        <span className="mpp_grey_header"> No of Questions</span>
                        <span className='mpp_details_card_text'>{scheduleData?.mpp_details.question_counts}</span>
                    </div>
                    <div className="mpp_details_card d-column" >
                        <span className="mpp_grey_header smallText">No. of students attempted MPP</span>
                        <span className='mpp_details_card_text'>{scheduleData?.mpp_details.attempt_counts}</span>
                    </div>
                </div>

            </div>
            <div className="course_list_card d-column align-items-start" >
                <span className="search_student_detail_header d-column">Course and batch info</span>
                {courseList.map(x => {
                    return <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className='mpp_details_card_text'>{x.course_name}</span>
                        {x.batches.map((y, yIndex) => {
                            return <span className={`mpp_details_card_text ml-2 mt-1`}>{y.batch_name}</span>
                        })}
                    </div>

                })}
                {/* {scheduleData?.batches_info.map((batch, index) => {
                    return <span className={`mpp_details_card_text ${index > 0 && 'ml-2'}`}>{batch.batch_name}</span>
                })} */}
            </div>
        </div>
    )
}

export default ScheduleHistory