import React, { useMemo, useRef } from 'react'
import LeftButton from "../../../assets/img/leftButton.png"
import RightButton from "../../../assets/img/rightButton.png"
import { currencyFormatter } from '../../../utils/helperFunctions'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const HeaderBar = ({
    summary
}) => {

    const innerRef = useRef()
    const outerRef = useRef()

    const scrollRight = () => {
        innerRef.current.scrollBy(outerRef.current.scrollWidth - 69 - 40 - 69, 0);
    }

    const scrollLeft = () => {
        innerRef.current.scrollBy(- outerRef.current.scrollWidth + 69 + 40 + 69, 0);
    }

    const calCash = (summary) => {
        let total = 0;

        summary.map(x => {
            total += +x.cash_inhand
        })

        return total;
    }

    const cashInHand = useMemo(() => calCash(summary), [summary])

    return (
        <div className='current_cash_headerbar_container' >
            Current cash in hand:
            <div className='current_cash_header_row' >
                <div className='current_cash_col_1' >
                    All
                    <span className='current_cash_col_1_text'>
                        ₹ {currencyFormatter(cashInHand, false)}
                    </span>
                </div>

                {
                    summary && summary.length > 0 &&
                    <div className='current_cash_col_2' ref={outerRef} >
                        <button className='current_cash_arrow_buttons'
                            onClick={scrollLeft}
                            style={{ marginRight: "20px" }} >
                            <img src={LeftButton} height="12.73px" width="7.78px" />
                        </button>

                        <div ref={innerRef} className='current_cash_center_list_row' >

                            {
                                summary.sort(function (a, b) {
                                    var nameA = a.centre_name.toLowerCase(),
                                        nameB = b.centre_name.toLowerCase();
                                    if (nameA < nameB)
                                        //sort string ascending
                                        return -1;
                                    if (nameA > nameB) return 1;
                                    return 0; //default return value (no sorting)
                                }).map((item, index) => {
                                    return (
                                        <div key={index} className="current_cash_center_container">
                                            {
                                                item.centre_name.length > 25 ? (
                                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={item.centre_name}>
                                                    <span style={{ cursor: "pointer" }}>{item.centre_name.substring(0, 25) + "..."}</span>
                                                </BootstrapTooltip>
                                                ) 
                                            : 
                                                (
                                                    item.centre_name
                                                )
                                            }
                                            {/* {item.centre_name} */}
                                            <span className="current_cash_col_1_text">₹ {currencyFormatter(item.cash_inhand, false)}</span>
                                        </div>
                                    );
                                })
                            }

                        </div>

                        <button
                            onClick={scrollRight}
                            className='current_cash_arrow_buttons' style={{ marginLeft: "20px" }} >
                            <img src={RightButton} height="12.73px" width="7.78px" />
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default HeaderBar;