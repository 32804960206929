import React, { useEffect, useState, useRef, useCallback } from 'react'
import HeaderBar from './headerBar';
import CashInfo from './cashInfo';
import { useSelector } from 'react-redux';
import Tab from '../common/tab';
import Filter from './filter';
import { SCRL_HANDLE_MULTIPLE, SCRL_RESET_LIST_MULTIPLE } from '../../../actions/types';
import { useScrollingList } from '../../../utils/hooks/use-scrolling-list-multi';
import moment from 'moment';
import Api from '../../common/api';
import ApiUrl from '../../common/apiUrl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackTop from "../../finance/common/backTop";
import Loader from '../../common/Loader';
import Empty from "../../../assets/img/empty_student.svg";

const initialCenterIndexState = {
    tab1: 0,
    tab2: 1
}

const AccountantDashboard = () => {

    const [selectedCenterId, setSelectedCenterId] = useState(null);
    const [centerIds, setCenterIds] = useState(initialCenterIndexState);

    const centerIDRef = useRef(selectedCenterId);

    const { centerList } = useSelector(state => state.auth);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [filterLoading, setFilterLoading] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);

    const [summary, setSummary] = useState([]);

    const getDashboardSummary = async () => {

        let payload = {};        
        const data = await Api.doFetch("GET", payload, ApiUrl.ACCOUNTANT_DASHBOARD_SUMMARY);

        if (data?.status) {
            setSummary(data.data);
        }
    }

    const getDashboardData = useCallback(async ({ skip = 0, limit = 10, search = "", }) => {

        let payload = {
            skip,
            limit
        }

        if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
            payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
            payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
        }

        if (centerIDRef && centerIDRef.current) {
            payload.centre_id = centerIDRef.current;
        }

        const data = await Api.doFetch("POST", payload, ApiUrl.ACCOUNTANT_DASHBOARD);

        if (data?.status === true) {
            const payload = data.data;
            // return {total:0,items:payload,skip:0,limit:0}

            if (payload) {
                const { results: items, total, skip, limit, } = payload;

                // totalAmount.current = no_cost_emi_sum

                return {
                    items,
                    total,
                    skip,
                    limit,
                };
            }
        }

        setFilterLoading(false);

        return null;
    }, []);

    const getDashboardDataDetails = async (getOne = false) => {

        if (moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
            // setDateError("From date cannot be greater than To date");
            return;
        }

        setFilterLoading(true);
        const data = await getDashboardData({
            skip: 0,
            limit
        });

        dispatch({
            type: SCRL_HANDLE_MULTIPLE,
            payload: {
                key: selectedCenterId,
                list: (data && data.items) || [],
                skip: 0,
                total: (data && data.total) || 0,
                scrollLoading: false,
            },
        });

        setFilterLoading(false);
    }

    const state = useSelector((state) => state.scrollingMultiple);

    const loading = state[selectedCenterId]?.loading || false;
    const list = state[selectedCenterId]?.data || [];
    const skip = state[selectedCenterId]?.skip || 0;
    const limit = state[selectedCenterId]?.limit || 0;
    const scrollLoading = state[selectedCenterId]?.scrollLoading || false;

    const [scrollContainer, { handleScroll }] = useCallback(useScrollingList({
        key: selectedCenterId,
        getData: getDashboardData,
        scrollState: state[selectedCenterId] || {}
    }), [selectedCenterId, state]);

    useEffect(() => {

        getDashboardSummary();

        return () => {
            dispatch({ type: SCRL_RESET_LIST_MULTIPLE })
        }
    }, [])

    return (
        <div className='course_list_container' >

            <HeaderBar 
                summary = {summary}
            />

            <Filter
                startDate = {startDate}
                setStartDate = {setStartDate}
                endDate = {endDate}
                setEndDate = {setEndDate}
                startDateRef = {startDateRef}
                endDateRef = {endDateRef}
                getDetails = {getDashboardDataDetails}
                filterLoading = {filterLoading}
            />

            {
                centerList && centerList.length > 0 &&
                <div ref={scrollContainer} className="accountant_dashboard_scroll_container" onScroll={handleScroll}>
                    
                    <Tab
                        selectedCenterId = {selectedCenterId}
                        setSelectedCenterId = {setSelectedCenterId}
                        centerIds = {centerIds}
                        setCenterIds  = {setCenterIds}
                        centerList = {centerList}
                        centerIDRef = {centerIDRef}
                    />

                    <div className="tab-content">

                        <div
                            className={
                                selectedCenterId === centerIds.tab1 || selectedCenterId === centerIds.tab2 ?
                                "tab-pane fade in active active_tab"
                                : 
                                "tab-pane fade"
                            }
                            style={{
                                backgroundColor: "#FFFFFF",
                                border: "none",
                                padding: "20px"
                            }}
                        >
                            {
                                loading || filterLoading ?
                                <div className="loader_scroll_container">
                                    <Loader />
                                </div>
                                :
                                list?.length === 0 ?
                                <div className="student_search_empty_container mt-5 mb-5">
                                    <div className="student_search_empty_img_container mt-5">
                                        <img
                                            src={Empty}
                                            alt="not found"
                                            className="student_search_empty_img"
                                        />
                                    </div>

                                    <p className="student_search_empty_header">
                                        No information available
                                    </p>

                                    <span className="student_search_empty_label">
                                        There are no cash collection details
                                    </span>
                                    <span className="student_search_empty_label">
                                        available for the date interval.
                                    </span>
                                </div>
                                :
                                list.map((data, i) => {
                                    return (
                                        <CashInfo data={data} key={i} />
                                    )
                                })
                            }

                        </div>

                    </div>

                    {
                        !loading && scrollLoading &&
                        <div className="loader_scroll_container">
                            <Loader />
                        </div>
                    }

                    <BackTop 
                        target = "accountant_dashboard_scroll_container"
                    />

                </div>
            }

        </div>
    )
}

export default AccountantDashboard;