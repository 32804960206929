import React from "react";

const CashPositionHeader = () => {
    return (
        <div className="course_list_header">
            <div className="d-flex align-items-center py-2">
                <span className="reversal_detail_header_text">Cash position</span>
            </div>
        </div>
    );
};

export default CashPositionHeader;
