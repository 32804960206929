import React from "react";
import Flag from "../../../assets/img/flag.svg";
import Loader from "../../common/Loader";
import { currencyFormatter, getPaymentMode } from "../../../utils/helperFunctions";
import Options from "../options";
import Swal from "sweetalert2";
import Reversal from "./reversal";
import Empty from "../../../assets/img/empty_history.svg";

const PaymentHistoryTable = ({
    currentTab,
    loading,
    setLoading,
    historyList,
    refresh,
    downloadInvoice,
    setReversalID,
    reversalID,
    reversalDeclined,
    setReversalDeclined,
    canViewReceipt,
    canDoReversal,
    checkCashConfirmed,
    isCourseActive
}) => {

    function convertDate(inputFormat) {
        function pad(s) {
            return s < 10 ? "0" + s : s;
        }
        var d = new Date(inputFormat);
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
    }

    const handleToggleReversalReason = (paymentHistoryId) => () => {
        if (reversalDeclined !== paymentHistoryId) {
            setReversalDeclined(paymentHistoryId)
        } else {
            setReversalDeclined("");
        }
    }

    return (
        <>
            {
                loading ?
                <div className="loader_container">
                    <Loader />
                </div>
                :
                <div>
                    <div className="installmentList">
                        <div className="table-responsive">
                        <table rules="rows" className="table">
                            <thead>
                                <tr>
                                    <th className="payment_history_table_header">Paid On</th>
                                    <th className="payment_history_table_header">Ack. No</th>
                                    <th className="payment_history_table_header">Amount</th>
                                    <th className="payment_history_table_header">Mode</th>
                                    <th className="payment_history_table_header">Status</th>
                                    {/* <th>Receipt</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    historyList.length > 0 &&
                                    historyList.map((paymentHistory, i) => {
                                        return (
                                            <React.Fragment key={`row${i}`}>
                                                <tr className={`${paymentHistory.id === reversalID ? "reversal_container" : (paymentHistory.is_reversal_refund_init || paymentHistory.reversal_declined) ? "reversal_container" : ""}`}>
                                                    <td className="payment_history_table_date_value">{convertDate(paymentHistory.activation_time)}</td>
                                                    <td className="payment_history_table_value">{paymentHistory.id}</td>
                                                    <td className="payment_history_table_value">{currencyFormatter(paymentHistory.amount_paid)}</td>
                                                    <td className="payment_history_table_value">{getPaymentMode(paymentHistory.payment_type)}</td>
                                                    <td className="payment_history_table_value">
                                                        {paymentHistory.status === "Paid" && <span className="bg-success">{paymentHistory.status}</span>}
                                                        {paymentHistory.status === "Processing" && <span className="bg-info">{paymentHistory.status}</span>}
                                                        {(paymentHistory.status === "Failed" || paymentHistory.status === "Failure") && <span className="bg-danger">{paymentHistory.status}</span>}
                                                        {paymentHistory.status === "Pending" && <span className="bg-info">{paymentHistory.status}</span>}
                                                        {paymentHistory.status === "Reversed" && <span className="bg-info">{paymentHistory.status}</span>}
                                                        {paymentHistory.is_reversal_refund_init && <img src={Flag} alt="flag" className="reversal_raised_flag" />}
                                                    </td>
                                                    {/* <td>{(paymentHistory.status === "Paid" || paymentHistory.payment_type === 'cheque' || paymentHistory.payment_type === 'Cheque') && <button className="ViewBtn" onClick={() => downloadInvoice(paymentHistory.id)}>{(downloaodClicked && downloadPaymentId === paymentHistory.id) ? 'Loading...' : 'View'}</button>}</td> */}
                                                    <td>
                                                        {
                                                            (canViewReceipt(paymentHistory.status, paymentHistory.payment_type) || paymentHistory.status === "Paid") &&
                                                            <Options
                                                                openReceipt={() => downloadInvoice(paymentHistory.id)}
                                                                setReversal={() => {
                                                                    if (canDoReversal(paymentHistory.payment_type, paymentHistory.cash_confirmed)) {
                                                                        setReversalID(paymentHistory.id)
                                                                    } else if(checkCashConfirmed(paymentHistory.cash_confirmed)) {
                                                                        Swal.fire("", `Cash entry already confirmed by ${paymentHistory.accountant_name} (Accountant), can’t be reversed`, "warning");
                                                                    } else {
                                                                        Swal.fire("", "Reversal can only be initiated where mode of payment is Cash/Cheque/offline swipe POS/Bank Transfer (UPI)", "warning");
                                                                    }
                                                                }}
                                                                reversalPending={paymentHistory.is_reversal_refund_init}

                                                                canViewReceipt={canViewReceipt(paymentHistory.status, paymentHistory.payment_type)}
                                                                canDoReversal={paymentHistory.status === "Paid" && currentTab !== "Hostel" && isCourseActive ? true : false}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                                {
                                                    paymentHistory.id === reversalID ?
                                                        <tr>
                                                            <td colSpan={"6"} className="reversal_td_container">
                                                                <Reversal
                                                                    reversalID={reversalID}
                                                                    refresh={(reload) => {
                                                                        setReversalID("");
                                                                        if (reload) {
                                                                            setLoading(true);
                                                                            refresh();
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        :
                                                        paymentHistory.is_reversal_refund_init ?
                                                            <tr>
                                                                <td colSpan={"6"} className="reversal_td_container">
                                                                    <div className="reversal_reason_container_flag">
                                                                        <img src={Flag} alt="flag" className="reversal_raised_flag mr-1" />
                                                                        <span className="reversal_raised_text">
                                                                            Reversal initiated for the payment, waiting on Centre Head for approval.
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            :
                                                        paymentHistory.reversal_declined ?
                                                            <tr>
                                                                <td colSpan={"6"} className="reversal_td_container">
                                                                    <div className="reversal_reason_container_flag">
                                                                        <div className="reversal_raised_text reversal_declined">
                                                                            <div className={`reversal_declined_content${reversalDeclined === paymentHistory.id ? " open" : ""}`}>
                                                                                <div className="reversal_declined_tag">
                                                                                    Reason to decline
                                                                                </div>

                                                                                <div className="reversal_declined_remark">
                                                                                    {paymentHistory.reversal_declined_remarks}
                                                                                </div>

                                                                                <div className="reversal_declined_centre_head">
                                                                                    Declined by {paymentHistory.reversal_declined_by} (Centre Head)
                                                                                </div>
                                                                            </div>

                                                                            <span className="reversal_declined_toggle" onClick={handleToggleReversalReason(paymentHistory.id)}>
                                                                                {reversalDeclined === paymentHistory.id ? 
                                                                                    "Hide reason"
                                                                                :
                                                                                    <>
                                                                                        <span style={{ color: "#AF0000" }}>Reversal request declined, </span>
                                                                                        <span>click to know reason</span>
                                                                                    </>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            :
                                                        null
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        </div>


                        {
                            historyList.length === 0 &&
                            <div className="student_search_empty_container">
                                <div className="payment_history_empty_container">
                                    <img
                                        src={Empty}
                                        alt="not found"
                                        className="student_search_empty_img"
                                    />
                                </div>

                                <p className="student_search_empty_header">
                                    No payment made yet.
                                </p>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default PaymentHistoryTable;