import React from "react";
import { Select } from "antd";
import CloseImg from "../../../assets/img/close.svg";
import { Capitalize } from "../../../utils/helperFunctions";

const { Option } = Select;

const SelectComponent = ({
    value,
    setValue,
    data,
    keyValue = false,
    placeholder,
    capitalize = false,
    selectAll = false,
    tagRender = true,
    showList = true
}) => {
    return (
        <>
            <Select
                mode="multiple"
                getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                }}
                style={{ width: "100%" }}
                placeholder={placeholder}
                showArrow
                showSearch
                optionFilterProp="children"
                value={value}
                autoClearSearchValue={false}
                onChange={selectedData => {
                    setValue(selectedData);
                }}
                // allowClear
                tagRender={tagRender === true ? selectedTag => {
                    if (value?.length > 0 && selectedTag.value === value[0]) {
                        // console.log(document.getElementsByClassName("ant-select-selection-search"), "testing");
                        return <span className="antd-placeholder-tag" >{placeholder}</span>;
                        // return <span className="ant-select-selection-placeholder" >{placeholder}</span>;
                    }
                    return null;
                } : undefined}
            >
                {
                    selectAll &&
                    <Option value={"Select All"}>
                        Select All
                    </Option>
                }
                {
                    data && data.map((options, index) => {
                        return (
                            <Option key={index} value={keyValue ? options.id : options}>
                                {
                                    keyValue ?
                                        capitalize ? Capitalize(options[keyValue]) : options[keyValue]
                                        :
                                        capitalize ? Capitalize(options) : options
                                }
                            </Option>
                        );
                    })}
            </Select>

           {showList && <div className="select_component_wrapper">
                {
                    value?.length > 0 && value.map(x => {
                        return (
                            <div className="select_component_text_wrapper">
                                <img
                                    src={CloseImg}
                                    className="cursor-pointer"
                                    alt="close"
                                    onClick={() => {
                                        setValue(value.filter(z => z !== x))
                                    }}
                                />
                                <span className="select_component_text">
                                    {
                                        keyValue ?
                                            data?.length > 0 && data.findIndex(z => z.id === x) >= 0 ?
                                                capitalize ? Capitalize(data[data.findIndex(z => z.id === x)][keyValue]) : data[data.findIndex(z => z.id === x)][keyValue]
                                                :
                                                capitalize ? Capitalize(x) : x
                                            :
                                            capitalize ? Capitalize(x) : x
                                    }
                                </span>
                            </div>
                        )
                    })
                }
            </div>}
        </>
    );
};

export default SelectComponent;