import React from "react";
import LoaderImage from "./../../../assets/img/financeLoader.gif";

const LoaderFinance = ({text}) => {
    return (
        <div className="finance-loader-container">
            <img src={LoaderImage} alt="Finance Loader Image" className="finance-loader-gif" />
            
            <p className="finace-loader-tagline mb-0">{text}</p>
        </div>
    );
};

export default LoaderFinance;
