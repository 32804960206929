
import React from "react";
// import Config from "../../config.json";
import { useState, useEffect } from 'react';
import { httpGet, httpPost } from "../../utils/https";
import {
    useParams,
    useNavigate,
    useLocation,
} from "react-router-dom";
import Loader from '../common/Loader'
// import Supportsidenav from "../supportsidenav";
// import style from "../support.css"
import './installmentCreate.css';
import loaderImg from "./css/loader.gif";
import Swal from 'sweetalert2';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    FormControl,
    TextField,
    ThemeProvider,
    createTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import BankDeposit from "./paymentMode/bankDeposit";
import NoCostEMI from "./paymentMode/nocostEMI";
import Cheque from "./paymentMode/cheque";
import OnlineSwipe from "./paymentMode/onlineSwipe";
import Hdfc from "./paymentMode/hdfc";


// const formatClassTime = date => date ? moment(date).format("hh:MM A") : "";

const datePickerTheme = createTheme({
    typography: {
        htmlFontSize: 10,
    }
});


const PaymentDetails = (props) => {
    let data = localStorage.getItem("userdetail");
    data = JSON.parse(data);

    const navigate = useNavigate()
    const location = useLocation()

    const { student_id } = useParams();
    const [receivedBy, set_receivedBy] = useState(data.username);
    const [isLoading, setIsLoading] = useState(false);
    const [feeDetails, set_feeDetails] = useState(null);
    const [studentDetails, set_studentDetails] = useState(null);
    const paymentMethod = location.state.paymentMethod
    const paymentAmount = location.state.paymentAmount
    const [swipe_recipt, set_swipe_recipt] = useState();
    const [chequeid, set_chequeid] = useState();
    const [bankname, set_bankname] = useState();
    const [chequedate, set_chequedate] = useState(moment().format(`YYYY-MM-DD`));
    const [cheque_image, set_cheque_image] = useState();
    const [appr_code, set_appr_code] = useState();
    // const [payment_id, set_payment_id] = useState();
    const [payment_link, set_payment_link] = useState();
    const [chequeBtnDisabled, set_chequeBtnDisabled] = useState(false);
    const [swipeBtnDisabled, set_swipeBtnDisabled] = useState(false);
    const currentDate = new Date();
    const date2 = currentDate.setDate(currentDate.getDate() - 90);
    const min_cheque_date = new Date(date2).toISOString().split("T")[0];
    // const max_cheque_date = new Date().toISOString().split("T")[0];
    const [tempDate, setTempDate] = useState(
        moment().format(`YYYY-MM-DD`)
    );
    const [sharePaymentLinkLoading, setSharePaymentLinkLoading] = useState(false);
    var openedWindow;


    const getStudentDetails = async () => {
        const studentDetailsRes = await httpGet(`${process.env.REACT_APP_BASE_URL}users/get-student-details/?student_id=${student_id}`);
        if (studentDetailsRes.status === 200) {
            set_studentDetails(studentDetailsRes.data);
        } else {
            console.log(studentDetailsRes.data.payload.Error)
        }
    }

    const getUserDetail = async () => {
        const userDetailsRes = await httpGet(`${process.env.REACT_APP_BASE_URL}users/get-user-id/?username=${data.username}`);
        if (userDetailsRes.status === 200) {
            set_receivedBy(userDetailsRes.data.user_info.first_name);
        } else {
            //console.log("error")
        }
    }

    const getFeeDetails = async () => {
        // setIsLoading(true);
        if (studentDetails?.student_data?.id) {
            const feeDetailsRes = await httpGet(`${process.env.REACT_APP_BASE_URL}support/get-sectionsubject/?school_id=${location.state.school_id}&section_id=${location.state.section_id}`);
            console.log("feeDetailsRes", feeDetailsRes);
            if (feeDetailsRes.status === 200) {
                set_feeDetails(feeDetailsRes.data.payload[0]);
            } else {
                console.log(feeDetailsRes.data.payload.Error)
            }
        }
    }

    function currencyFormatter(input) {
        if (input) {
            return (Number(input)).toLocaleString('en-IN', {
                style: 'currency',
                currency: 'INR',
                minimumFractionDigits: 0
            })
        } else {
            return 0;
        }
    }

    const getChequefile = (event) => {
        // const data = event.target.files[0];
        set_cheque_image(event.target.files[0])
        // console.log(data)

    };

    const getSwipefile = (event) => {
        // const data = event.target.files[0];
        // console.log(data)
        set_swipe_recipt(event.target.files[0])
    };



    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const displayRazorpay = async () => {
        //let razorPayKey = "rzp_test_xguDglODlGEiy0"; //test
        let razorPayKey = process.env.REACT_APP_RZP_ID; //live
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        if (feeDetails) {
            let formdata = new FormData();
            formdata.append("payment_type", paymentMethod);
            formdata.append("amount", paymentAmount);
            formdata.append("section_subject_id", feeDetails.id);
            const paymentApiRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/receive-payment/${student_id}/`, formdata);
            console.log("paymentApiRes", paymentApiRes);
            if (paymentApiRes.status === 200) {
                const { amount, order_id } = paymentApiRes.data.payload;
                let amount_obj = amount * 100;
                const options = {
                    key: razorPayKey, // Enter the Key ID generated from the Dashboard
                    amount: amount_obj.toString(),
                    currency: "INR",
                    description: "Test Transaction",
                    handler: async function (response) {
                        let formdata = new FormData();
                        formdata.append("order_id", order_id);
                        formdata.append("razor_payment_id", response.razorpay_payment_id);
                        formdata.append("type", "Razorpay_type");
                        const paymentHandleRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/receive-payment-support-handle/`, formdata);
                        console.log("paymentHandleRes", paymentHandleRes);
                        if (paymentHandleRes.status === 200) {
                            // props.history.push({
                            //   pathname: `/support/studentmodule/payment-success`,
                            // state:{
                            //   payment_id:paymentApiRes.data.payload.id,
                            //   section_id:studentDetails.student_data.section_assoc.id,
                            //   school_id:studentDetails.student_data.school_assoc.id,
                            //   student_id:student_id
                            // }
                            // });
                            navigate(`/payment/paymentSuccess`, {
                                state: {
                                    payment_id: paymentApiRes.data.payload.id,
                                    section_id: location.state.section_id,
                                    school_id: location.state.school_id,
                                    student_id: student_id
                                }
                            })
                        }
                        else {
                            // props.history.push({
                            //   pathname: `/school/studentpayment/payment-failed`,
                            //   state:{student_id:student_id}
                            // });
                            navigate(`/payment/paymentFailed`, {
                                state: {
                                    student_id: student_id,
                                    section_id: location.state.section_id,
                                    school_id: location.state.school_id,
                                    school_name: location.state.school_name,
                                    section_name: location.state.section_name,
                                },
                                replace: true
                            })
                        }
                        // alert(result.data.Success);
                        // this.props.history.push("/student/PaymentSuccess");
                    },
                    theme: {
                        color: "#61dafb",
                    },
                }
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            } else {
                alert("Something went wrong. Please try after sometime")
            }

            // console.log(result.data.param_dict)
            if (!paymentApiRes) {
                alert("Server error. Are you online?");
                return;
            }
        }
    };

    /***************Cash Payment Start *****************/
    const makeCashPayment = async () => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            text: `Receiving ${currencyFormatter(Number(paymentAmount))} in cash by ${receivedBy}`,
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setIsLoading(true)

                let formdata = new FormData();
                //transfer, swipe, postdatedcheque, cheque, razorpay, paytm, cash, hdfc, cash, link    
                formdata.append("payment_type", paymentMethod);
                formdata.append("amount", paymentAmount);
                formdata.append("section_subject_id", feeDetails.id);
                const paymentApiRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/receive-payment/${student_id}/`, formdata);

                if (paymentApiRes.status === 200) {
                    // setIsLoading(false)        
                    navigate(`/payment/paymentSuccess`, {
                        state: {
                            payment_id: paymentApiRes.data.payload.id,
                            section_id: location.state.section_id,
                            school_id: location.state.school_id,
                            student_id: student_id
                        },
                        replace: true
                    })
                }
                else {
                    navigate(`/payment/paymentFailed`, {
                        state: {
                            student_id: student_id,
                            section_id: location.state.section_id,
                            school_id: location.state.school_id,
                            school_name: location.state.school_name,
                            section_name: location.state.section_name,
                        },
                        replace: true
                    })
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire('Payment is not completed', '', 'info')
                // setIsLoading(false)
            }
        })
    }
    /***************Cash Payment End *****************/

    /***************Swipe Payment Start *****************/
    const makeSwipePayment = async () => {
        if (appr_code === undefined || appr_code === "") {
            Swal.fire("Please Enter APPR CODE");
            return false;
        }
        else if (swipe_recipt === undefined || swipe_recipt === "") {
            Swal.fire("Please Upload Swipe Recipt");
            return false;
        }
        else if (!['image/png', 'image/jpeg', 'image/jpg'].includes(swipe_recipt.type.toLowerCase())) {
            Swal.fire("Please upload .Jpg, .Png files upto 10Mb");
            return false;
        }
        else if (swipe_recipt.size > 10485760) {
            Swal.fire("Please upload .Jpg, .Png files upto 10Mb");
            return false;
        }

        else {
            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                text: `Receiving ${currencyFormatter(Number(paymentAmount))} in swipe by ${receivedBy}`,
                showCancelButton: true,
                confirmButtonText: 'Confirm',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    set_swipeBtnDisabled(true);
                    //if ((swipe_recipt !== null && appr_code !== undefined)) {    
                    let formdata = new FormData();
                    formdata.append("payment_type", paymentMethod);
                    formdata.append("amount", paymentAmount);
                    formdata.append("section_subject_id", feeDetails.id);
                    formdata.append("appr_code", appr_code);
                    formdata.append("swipe_slip", swipe_recipt);
                    const paymentApiRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/receive-payment/${student_id}/`, formdata);

                    if (paymentApiRes.status === 200) {
                        navigate(`/payment/paymentSuccess`, {
                            state: {
                                payment_id: paymentApiRes.data.payload.id,
                                section_id: location.state.section_id,
                                school_id: location.state.school_id,
                                student_id: student_id
                            }
                        })
                    } else {
                        navigate(`/payment/paymentFailed`, {
                            state: {
                                student_id: student_id,
                                section_id: location.state.section_id,
                                school_id: location.state.school_id,
                                school_name: location.state.school_name,
                                section_name: location.state.section_name,
                            },
                            replace: true
                        })
                    }
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    Swal.fire('Payment is not completed', '', 'info')
                }
            });
        }

    }
    /***************Swipe Payment End *****************/

    /***************Cheque Payment Start *****************/
    const makeChequePayment = async () => {

        if (bankname === undefined || bankname === "") {
            Swal.fire("Please Enter Bank Name");
            return false;
        }
        else if (chequeid === undefined || chequeid === "") {
            Swal.fire("Please Enter Cheque Number");
            return false;
        }
        else if (chequedate === undefined || chequedate === "") {
            Swal.fire("Please Enter Cheque Date");
            return false;
        }
        else if (cheque_image === undefined || cheque_image === "") {
            Swal.fire("Please Upload Cheque Image");
            return false;
        }
        else if (!['image/png', 'image/jpeg', 'image/jpg'].includes(cheque_image.type.toLowerCase())) {
            Swal.fire("Please upload .Jpg, .Png files upto 10Mb");
            return false;
        }
        else if (cheque_image.size > 10485760) {
            Swal.fire("Please upload .Jpg, .Png files upto 10Mb");
            return false;
        }
        else {
            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                text: `Receiving ${currencyFormatter(Number(paymentAmount))} in cheque by ${receivedBy}`,
                showCancelButton: true,
                confirmButtonText: 'Confirm',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    set_chequeBtnDisabled(true);
                    let formdata = new FormData();
                    formdata.append("payment_type", paymentMethod);
                    formdata.append("amount", paymentAmount);
                    formdata.append("section_subject_id", feeDetails.id);
                    formdata.append("cheque_id", chequeid);
                    formdata.append("cheque_date", chequedate);
                    formdata.append("cheque_file", cheque_image);
                    formdata.append("payment_date", chequedate);

                    const paymentApiRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/receive-payment/${student_id}/`, formdata);

                    if (paymentApiRes.status === 200) {
                        navigate(`/payment/paymentSuccess`, {
                            state: {
                                payment_id: paymentApiRes.data.payload.id,
                                section_id: location.state.section_id,
                                school_id: location.state.school_id,
                                student_id: student_id
                            }
                        })
                    } else {
                        navigate(`/payment/paymentFailed`, {
                            state: {
                                student_id: student_id,
                                section_id: location.state.section_id,
                                school_id: location.state.school_id,
                                school_name: location.state.school_name,
                                section_name: location.state.section_name,
                            },
                            replace: true
                        })
                    }
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    Swal.fire('Payment is not completed', '', 'info')
                }
            });


        }

    }
    /***************Cheque Payment End *****************/


    /***************Share Payment Link Start *****************/
    const sharePaymentLink = async () => {
        if (sharePaymentLinkLoading) return;

        let formdata = new FormData();
        formdata.append("payment_type", paymentMethod);
        formdata.append("amount", paymentAmount);
        formdata.append("section_subject_id", feeDetails.id);
        formdata.append("cheque_id", chequeid);
        setSharePaymentLinkLoading(true);
        const paymentApiRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/receive-payment/${student_id}/`, formdata);

        if (paymentApiRes.status === 200) {
            setSharePaymentLinkLoading(false);

            var host = window.location.protocol + "//" + window.location.host;
            set_payment_link(`${host}${paymentApiRes.data.payload.payment_link}`);
        } else {
            setSharePaymentLinkLoading(false);

            // props.history.push({
            //   pathname: `/support/studentmodule/payment-failed`,
            //   state:{student_id:student_id}
            // });
            navigate(`/payment/paymentFailed`, {
                state: {
                    student_id: student_id,
                    section_id: location.state.section_id,
                    school_id: location.state.school_id,
                    school_name: location.state.school_name,
                    section_name: location.state.section_name,
                },
                replace: true
            })
        }
    }
    /***************Share Payment Link End *****************/

    /***************ICICI Payment Start *****************/
    const makeICICIPayment = async () => {
        if (feeDetails) {
            let formdata = new FormData();
            formdata.append("payment_type", "CommerceConnect");
            formdata.append("amount", paymentAmount);
            formdata.append("section_subject_id", feeDetails.id);
            const paymentApiRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/receive-payment/${student_id}/`, formdata);

            if (paymentApiRes.status === 200) {
                // set_payment_id(paymentApiRes.data.payload.payment_id);
                openedWindow = window.open(paymentApiRes.data.payload.redirect_url, "_blank");

                var start = (new Date()).getTime();
                var timer_id = setInterval(async function () {
                    var current = (new Date()).getTime();
                    if ((current - start) / 1000 > 2 * 60) {
                        clearInterval(timer_id); // when it's exceed 1 minute, stop the timer.
                        openedWindow.close();
                        // props.history.push({
                        //   pathname: `/support/studentmodule/payment-failed`,
                        //   state:{student_id:student_id}
                        // });
                        navigate(`/payment/paymentFailed`, {
                            state: {
                                student_id: student_id,
                                section_id: location.state.section_id,
                                school_id: location.state.school_id,
                                school_name: location.state.school_name,
                                section_name: location.state.section_name,
                            },
                            replace: true
                        })
                    }
                    const paymentApiStatusRes = await httpGet(`${process.env.REACT_APP_BASE_URL}support/get-payment-detail/${paymentApiRes.data.payload.payment_id}/`);
                    console.log('payment izizi console', paymentApiStatusRes)
                    if (paymentApiStatusRes.status === 200) {
                        if (paymentApiStatusRes.data.payload.status === "Paid") {
                            openedWindow.close();
                            // props.history.push({
                            //   pathname: `/support/studentmodule/payment-success`,
                            //   state:{
                            //     payment_id:paymentApiRes.data.payload.payment_id,
                            //     section_id:studentDetails.student_data.section_assoc.id,
                            //     school_id:studentDetails.student_data.school_assoc.id,
                            //     student_id:student_id
                            //   }
                            // });
                            navigate(`/payment/paymentSuccess`, {
                                state: {
                                    payment_id: paymentApiRes.data.payload.id,
                                    section_id: location.state.section_id,
                                    school_id: location.state.school_id,
                                    student_id: student_id
                                }
                            })
                            clearInterval(timer_id)
                        }
                        else if (paymentApiStatusRes.data.payload.status === "Failed") {
                            openedWindow.close();
                            // props.history.push({
                            //   pathname: `/support/studentmodule/payment-success`,
                            //   state:{
                            //     payment_id:paymentApiRes.data.payload.payment_id,
                            //     section_id:studentDetails.student_data.section_assoc.id,
                            //     school_id:studentDetails.student_data.school_assoc.id,
                            //     student_id:student_id
                            //   }
                            // });
                            navigate(`/payment/paymentFailed`, {
                                state: {
                                    student_id: student_id,
                                    section_id: location.state.section_id,
                                    school_id: location.state.school_id,
                                    school_name: location.state.school_name,
                                    section_name: location.state.section_name,
                                },
                                replace: true
                            })
                            clearInterval(timer_id)
                        }
                    }
                }, 10000);
            }
        }
    }
    /***************ICICI Payment End *****************/


    /***************ICICI Swipe Payment Start *****************/
    const makeSwipeICICIPayment = async () => {
        if (feeDetails) {
            let formdata = new FormData();
            formdata.append("payment_type", 'ezetap');
            formdata.append("amount", paymentAmount);
            formdata.append("section_subject_id", feeDetails.id);
            const paymentApiRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/receive-payment/${student_id}/`, formdata);

            if (paymentApiRes.status === 200) {
                var start = (new Date()).getTime();
                var timer_id = setInterval(async function () {
                    var current = (new Date()).getTime();
                    if ((current - start) / 1000 > 3 * 60) {
                        clearInterval(timer_id); // when it's exceed 1 minute, stop the timer.

                        // props.history.push({
                        //   pathname: `/support/studentmodule/payment-failed`,
                        //   state:{student_id:student_id}
                        // });
                        navigate(`/payment/paymentFailed`, {
                            state: {
                                student_id: student_id,
                                section_id: location.state.section_id,
                                school_id: location.state.school_id,
                                school_name: location.state.school_name,
                                section_name: location.state.section_name,
                            },
                            replace: true
                        })
                    }

                    const paymentApiStatusRes = await httpGet(`${process.env.REACT_APP_BASE_URL}support/receive-payment-swipe/${paymentApiRes.data.payload.swipe_id}/`);

                    if (paymentApiStatusRes.data.payload?.Success) {
                        clearInterval(timer_id);
                        // props.history.push({
                        //   pathname: `/support/studentmodule/payment-success`
                        // });
                        navigate(`/payment/paymentSuccess`, {
                            state: {
                                payment_id: paymentApiStatusRes.data.payload.order_id,
                                section_id: location.state.section_id,
                                school_id: location.state.school_id,
                                student_id: student_id
                            }
                        })
                    }
                }, 10000);

                // props.history.push({
                //   pathname: `/support/studentmodule/payment-success`
                // });
            }
        }
    }
    /***************ICICI Swipe End *****************/

    useEffect(() => {
        window.scrollTo(0, 0);
        getStudentDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (paymentMethod === 'razorpay') {
            displayRazorpay();
        }
        if (paymentMethod === 'icici') {
            makeICICIPayment();
        }
        if (paymentMethod === 'ezetap') {
            makeSwipeICICIPayment();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feeDetails]);


    useEffect(() => {
        getFeeDetails();
        getUserDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentDetails]);


    return (
        <div>
            {/* <TopNavBar logo={true} /> */}
            <div className="container-fluid">
                <div className="row">
                    {/* <div className="col-md-2">
                    <Supportsidenav />
                </div> */}
                    <div className="col-md-12">

                        <div className="support_main">
                            <div className="installCreateWrap">

                                {
                                    (paymentMethod === 'ezetap' || paymentMethod === 'icici') &&
                                    <>
                                        <div className="container">
                                            <div className="row">
                                                <div className="headingWrap">
                                                    <div className="col-sm-12">
                                                        <h2>Installment Details</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="tabWrap paymentSuccess" style={{ textAlign: 'center' }}>
                                                <div className="LoadPage">
                                                    <img alt={"Loader"} src={loaderImg} />
                                                    <div className="successWrp">
                                                        <h2>Do not close this window...</h2>
                                                        {paymentMethod === 'ezetap' && <p>Check POS Machine</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                }
                                {
                                    (
                                        paymentMethod === 'hdfc/ccavenue' 
                                        ||
                                        paymentMethod === 'online/swipe'
                                        ||
                                        paymentMethod === 'cash'
                                        ||
                                        paymentMethod === 'link'
                                        ||
                                        paymentMethod === 'swipe'
                                        ||
                                        paymentMethod === 'cheque'
                                        || 
                                        paymentMethod=== 'bankdeposit'
                                        ||
                                        paymentMethod === 'nocostemi'
                                    ) &&
                                    <>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="headingWrap">
                                                    <div className="col-sm-12">
                                                        <h2>Installment Details</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bgFull">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-4 col-sm-12">
                                                        <p><label><b>Student Name </b></label> : {studentDetails?.student_data?.name}</p>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                        <p><label><b>Mobile Number </b></label> : {studentDetails?.student_data?.mobile_number}</p>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                        <p><label><b>myClassroom Centre</b></label> : {location.state.school_name}</p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="">
                                                            <p><label><b>Section</b></label> : {location.state.section_name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="installmentPaymentt">
                                            <div className="container">
                                                {/* <h1>Create Installment Plan</h1> */}
                                                <div className="row">
                                                    <div className="content_width bankDetail">
                                                        {paymentMethod === 'cash' &&
                                                            <>
                                                                <div className="form-group">
                                                                    <label className="contain cash">Cash Amount:</label>
                                                                    <h3>{currencyFormatter(paymentAmount)}</h3>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="contain cash">Received by </label>
                                                                    <h3>{receivedBy}</h3>
                                                                </div>
                                                                {isLoading ? <Loader /> : <div className="form-group">
                                                                    <button className="confirmBtn" onClick={() => makeCashPayment()}>Confirm</button>
                                                                </div>}
                                                            </>
                                                        }
                                                        {paymentMethod === 'link' &&
                                                            <>
                                                                {/* <div className="form-group">
                                  <label className="contain cash">Enter mobile number to share link <i className="fa fa-asterisk custom-asterisk" aria-hidden="true"></i></label>
                                  <input type="text" className="form-control" placeholder="Mobile Number" />
                                </div> */}
                                                                <div className="form-group">
                                                                    <label className="contain cash" style={{ fontSize: '18px' }}>Payment Link</label>
                                                                    <p style={{ fontSize: '15px', padding: "10px 0px" }}>{payment_link}
                                                                        <br /><br />
                                                                        {payment_link &&
                                                                            <CopyToClipboard text={payment_link}>
                                                                                <button className="btn btn-success" style={{ fontSize: '14px', padding: "5px", marginLeft: "5px" }}>Copy Link</button>
                                                                            </CopyToClipboard>}
                                                                    </p>

                                                                </div>
                                                                {
                                                                    !payment_link &&
                                                                    <div className="form-group">
                                                                        <button 
                                                                            className="confirmBtn" 
                                                                            onClick={sharePaymentLink}
                                                                        >
                                                                            Generate Link
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </>
                                                        }

                                                        {
                                                            paymentMethod === 'online/swipe' &&
                                                            <OnlineSwipe 
                                                                paymentAmount = {paymentAmount} 
                                                                studentName = {studentDetails && studentDetails.student_data && studentDetails.student_data.name}
                                                                sectionSchoolID = {feeDetails && feeDetails.id}
                                                                student_id = {student_id}
                                                                location = {location}
                                                                type = "C"
                                                                studentDetails = {studentDetails}
                                                            />
                                                        }

                                                        {
                                                            paymentMethod === 'hdfc/ccavenue' &&
                                                            <Hdfc 
                                                                paymentAmount = {paymentAmount} 
                                                                studentName = {studentDetails && studentDetails.student_data && studentDetails.student_data.name}
                                                                sectionSchoolID = {feeDetails && feeDetails.id}
                                                                student_id = {student_id}
                                                                location = {location}
                                                                type = "C"
                                                                studentDetails = {studentDetails}
                                                            />
                                                        }

                                                        {
                                                            paymentMethod === 'cheque' &&
                                                            <Cheque 
                                                                paymentAmount = {paymentAmount} 
                                                                studentName = {studentDetails && studentDetails.student_data && studentDetails.student_data.name}
                                                                sectionSchoolID = {feeDetails && feeDetails.id}
                                                                student_id = {student_id}
                                                                location = {location}
                                                                type = "C"
                                                                studentDetails = {studentDetails}
                                                            />
                                                            // <>
                                                            //     <div className="form-group">
                                                            //         <label className="contain cash">Bank Name <i className="fa fa-asterisk custom-asterisk" aria-hidden="true"></i></label>
                                                            //         <input type="text" className="form-control input" placeholder="BANK NAME" onChange={(event) => { set_bankname(event.target.value); }} />
                                                            //     </div>
                                                            //     <div className="form-group">
                                                            //         <label className="contain cash">Enter Cheque Number
                                                            //             <i className="fa fa-asterisk custom-asterisk" aria-hidden="true"></i></label>
                                                            //         <input type="text" className="form-control select" placeholder="123 123"
                                                            //             maxLength={6}
                                                            //             onKeyPress={(event) => {
                                                            //                 if (!/[0-9]/.test(event.key)) {
                                                            //                     event.preventDefault();
                                                            //                 }
                                                            //             }}
                                                            //             onChange={(event) => { set_chequeid(event.target.value); }} />
                                                            //     </div>
                                                            //     <div className="form-group">
                                                            //         <label className="contain cash">Cheque Date <i className="fa fa-asterisk custom-asterisk" aria-hidden="true"></i></label>
                                                            //         {/* <input type="date" className="form-control"
                                                            //             min={min_cheque_date
                                                            //                 // new Date().toISOString().split("T")[0]
                                                            //             }
                                                            //             max={max_cheque_date}
                                                            //             onKeyDown={(e) => e.preventDefault()} onChange={(event) => { set_chequedate(event.target.value); }} /> */}

                                                            //         <FormControl
                                                            //             fullWidth
                                                            //             style={{ marginBottom: "20px" }}
                                                            //             className="attendance_date1"
                                                            //         >
                                                            //             <ThemeProvider theme={datePickerTheme}>
                                                            //                 <LocalizationProvider
                                                            //                     dateAdapter={AdapterMoment}
                                                            //                     fullWidth
                                                            //                     className="attendance_date2 select"
                                                            //                 >
                                                            //                     <DatePicker
                                                            //                         disableFuture
                                                            //                         minDate={moment(min_cheque_date)}
                                                            //                         PopperProps={{
                                                            //                             sx: {
                                                            //                                 zIndex: 100000000000000,
                                                            //                                 fontFamily: "Montserrat",
                                                            //                                 fontStyle: "normal",
                                                            //                                 fontWeight: 400,
                                                            //                             }
                                                            //                         }}
                                                            //                         inputProps={{
                                                            //                             "aria-label": "Without label",
                                                            //                             style: {
                                                            //                                 fontSize: 16,
                                                            //                                 border: "none"
                                                            //                             },
                                                            //                             readOnly: true
                                                            //                         }}
                                                            //                         style={{
                                                            //                             color: !tempDate
                                                            //                                 ? "#858585"
                                                            //                                 : "rgba(0, 0, 0, 0.87)",
                                                            //                         }}
                                                            //                         fullWidth
                                                            //                         value={tempDate}
                                                            //                         onChange={(newValue) => {
                                                            //                             setTempDate(moment(newValue).format(
                                                            //                                 `YYYY-MM-DD hh:mm:ss`
                                                            //                             ));
                                                            //                         }}
                                                            //                         onClose={() => {
                                                            //                             set_chequedate(tempDate);
                                                            //                         }}
                                                            //                         // onAccept={() => {}}
                                                            //                         renderInput={(props) => (
                                                            //                             <TextField
                                                            //                                 size="small"
                                                            //                                 {...props}
                                                            //                                 onBlur={() => {
                                                            //                                     props.onBlur && props.onBlur();
                                                            //                                     set_chequedate(tempDate);
                                                            //                                 }}
                                                            //                                 onKeyDown={(e) => {
                                                            //                                     if (e.key === 'Enter') {
                                                            //                                         set_chequedate(tempDate);
                                                            //                                     }
                                                            //                                 }}
                                                            //                             />
                                                            //                         )}
                                                            //                     // className="attendance_date"
                                                            //                     />
                                                            //                 </LocalizationProvider>
                                                            //             </ThemeProvider>
                                                            //         </FormControl>
                                                            //     </div>
                                                            //     <div className="form-group">
                                                            //         <label className="contain cash">Cheque Amount: </label>
                                                            //         <h3>{currencyFormatter(paymentAmount)}</h3>
                                                            //     </div>
                                                            //     <div className="form-group">
                                                            //         <label className="contain cash">Received by </label>
                                                            //         <h3>{receivedBy}</h3>
                                                            //     </div>
                                                            //     <div className="form-group upCheque">
                                                            //         <label className="contain cash">Upload Screenshot of Cheque <i className="fa fa-asterisk custom-asterisk" aria-hidden="true"></i></label>
                                                            //         <div className="d-flex btnWrap">
                                                            //             {/* <button className="camertaBtn"><img src={cameraIcon} /></button> */}
                                                            //             <div className="upbtn"><input className="fileup input" type="file" onChange={(event) => getChequefile(event)} /> </div>
                                                            //         </div>
                                                            //         <span className="span">Please upload .Jpg, .Png files upto 10Mb</span>
                                                            //     </div>
                                                            //     {cheque_image &&
                                                            //         <div className="form-group cheque-m">
                                                            //             <h5><i className="fa fa-check-double" style={{ color: "#2CAA00" }}></i> Cheque {chequeid}</h5>
                                                            //         </div>
                                                            //     }
                                                            //     <div className="form-group">
                                                            //         <button className={chequeBtnDisabled ? 'confirmBtn btn disabled' : 'confirmBtn'} disabled={chequeBtnDisabled} onClick={() => makeChequePayment()}>Confirm</button>
                                                            //     </div>
                                                            // </>
                                                        }
                                                        { 
                                                            paymentMethod === 'bankdeposit' && 
                                                            <BankDeposit 
                                                                paymentAmount = {paymentAmount} 
                                                                studentName = {studentDetails && studentDetails.student_data && studentDetails.student_data.name}
                                                                sectionSchoolID = {feeDetails && feeDetails.id}
                                                                student_id = {student_id}
                                                                location = {location}
                                                                type = "C"
                                                                studentDetails = {studentDetails}
                                                            />
                                                        }
                                                        {
                                                            paymentMethod === 'nocostemi' &&
                                                            <NoCostEMI 
                                                                paymentAmount = {paymentAmount} 
                                                                studentName = {studentDetails && studentDetails.student_data && studentDetails.student_data.name}
                                                                sectionSchoolID = {feeDetails && feeDetails.id}
                                                                student_id = {student_id}
                                                                location = {location}
                                                                type = "C"
                                                                studentDetails = {studentDetails}
                                                            />
                                                        }

                                                        {paymentMethod === 'swipe' &&
                                                            <>
                                                                <div className="form-group">
                                                                    <label className="contain cash">APPR CODE <i className="fa fa-asterisk custom-asterisk" aria-hidden="true"></i></label>
                                                                    <input type="text" className="form-control" placeholder="APPR CODE NUMBER" onChange={(event) => { set_appr_code(event.target.value); }} />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="contain cash">Cash Amount:</label>
                                                                    <h3>{currencyFormatter(paymentAmount)}</h3>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="contain cash">Received by </label>
                                                                    <h3>{receivedBy}</h3>
                                                                </div>
                                                                <div className="form-group upCheque">
                                                                    <label className="contain cash">Upload Swipe Recipt <i className="fa fa-asterisk custom-asterisk" aria-hidden="true"></i></label>
                                                                    <div className="d-flex btnWrap">
                                                                        <div className="upbtn"><input className="fileup" type="file" onChange={(event) => getSwipefile(event)} />  </div>
                                                                    </div>
                                                                    <span className="span">Please upload .Jpg, .Png files upto 10Mb</span>
                                                                </div>
                                                                <div className="form-group">
                                                                    <button className={swipeBtnDisabled ? 'confirmBtn btn disabled' : 'confirmBtn'} disabled={swipeBtnDisabled} onClick={() => makeSwipePayment()}>Confirm</button>
                                                                </div>
                                                            </>
                                                        }


                                                    </div>
                                                    <div className="col-sm-8"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PaymentDetails