import React from "react";
import { currencyFormatter, zinedus3 } from "../../../../utils/helperFunctions";
import Error from "../../../common/errorText";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import moment from "moment";

const CashListDetails = (props) => {

    const {
        entry_date,
        accountant_name,
        accountant_id,
        amount,
        centre_name,
        payment_partner,
        slip_date,
        deposit_slip,
        status,
        receiving_date,
        rejected_reason
    } = props.data

    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.arrow}`]: {
                color: theme.palette.common.black,
            },
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.black,
                fontSize: "13px",
            },
        })
    );

    const handleViewSlip = () => {
        // setViewSlipModal((state) => !state);
        window.open(`${zinedus3}${deposit_slip}`, '_blank');
    };

    return (
        <div className="pending-cheques__details_container">
            <div className="details_container_row1">
                <div className="course_divider3 my-2">
                    <p className="cheque-details__label mb-0">Entry date</p>
                    <p className="cheque-details__label-1 mb-0">{moment(entry_date).format("MMM DD,YYYY") || "-"}</p>
                </div>

                <div className="course_divider2 my-2">
                    <p className="cheque-details__label mb-0">Slip date</p>
                    <p className="cheque-details__label-1 mb-0">{moment(slip_date).format("MMM DD,YYYY") || "-"}</p>
                </div>

                <div className="course_divider3 my-2">
                    <p className="cheque-details__label mb-0">Payment partner</p>
                    <p className="cheque-details__label-1 mb-0">
                        {payment_partner && payment_partner.length > 12 ? (
                            <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={payment_partner}>
                                <span style={{ cursor: "pointer" }}>{payment_partner.substring(0, 12) + "..."}</span>
                            </BootstrapTooltip>
                        ) : (
                            <>
                                {payment_partner || "-"}
                            </>
                        )}
                    </p>
                </div>

                <div className="course_divider3 my-2">
                    <p className="cheque-details__label mb-0">Centre name</p>
                    <p className="cheque-details__label-1 mb-0">
                        {centre_name && centre_name.length > 15 ? (
                            <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={centre_name}>
                                <span style={{ cursor: "pointer" }}>{centre_name.substring(0, 15) + "..."}</span>
                            </BootstrapTooltip>
                        ) : (
                            <>
                                {centre_name || "-"}
                            </>
                        )}
                    </p>
                </div>

                <div className="course_divider1 my-2">
                    <p className="cheque-details__label mb-0">Accountant name</p>
                    <p className="cheque-details__label-1 mb-0">
                        {accountant_name && accountant_name.length > 20 ? (
                            <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={accountant_name}>
                                <span style={{ cursor: "pointer" }}>{accountant_name.substring(0, 20) + "..."}</span>
                            </BootstrapTooltip>
                        ) : (
                            <>
                                {accountant_name || "-"}
                            </>
                        )}
                    </p>
                    <p className="cheque-details__label mb-0">Employee ID : {accountant_id || "-"}</p>
                </div>

                <div className="course_divider3 my-2">
                    <p className="cheque-details__label mb-0">Amount</p>
                    { amount && <p className="cheque-details__label-1 mb-0">{`₹ ${currencyFormatter(amount,false)}`||"-"} </p>}
                    {deposit_slip && <p className="cheque-details__label view-cheque-link m-0" onClick={handleViewSlip}>
                        View slip
                    </p>}
                </div>

                <div className="course_divider3 my-2">
                    <p className="cheque-details__label mb-0">Status</p>
                    {/* <p className="cash-details-status-approved mb-0">Confirmed</p> */}
                    {/* <p className="cash-details-status-pending mb-0">Pending</p> */}
                    {status && <div className="d-flex align-items-center">
                        <p className={status === "rejected" ? `cash-details-status-rejected2 mb-0` : `cash-details-status-approved mb-0`}>{status}</p>
                        {
                            status === "rejected" && 
                            <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={rejected_reason}>
                                <p className="mb-0 cursor-pointer" style={{ marginLeft: "0.3rem" }}>
                                    {/* KEEP TEXT BLANK HERE */}
                                    <Error text="" />
                                </p>
                            </BootstrapTooltip>
                        }
                    </div>}
                </div>

                <div className="course_divider3 my-2">
                    <p className="cheque-details__label mb-0">Receiving date</p>
                    {
                        receiving_date ?
                        <p className="cheque-details__label-1 mb-0">
                            {moment(receiving_date).format("MMM DD, YYYY")}
                        </p>
                        :
                        <p className="cheque-details__label-1-disabled mb-0">Not applicable</p>
                    }
                </div>
            </div>
        </div>
    );
};

export default CashListDetails;
