import React, { useState, useCallback, useEffect, useRef } from 'react'
import LeftArrow from "../../../assets/img/backArrowBlack.svg";
import SearchIcon from "../../../assets/img/search.svg";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import SelectWithTooltip from "../../common/component/SelectWithTooltip"
import MppCard from '../mppScreens/components/MppCard'
import Empty from "../../../assets/img/empty_student.svg";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import { useScrollingList } from "../../../utils/hooks/use-scrolling-list";
import { SCRL_HANDLE_SEARCH_INIT, SCRL_RESET_LIST } from '../../../actions/types';
import { useSelector, useDispatch } from "react-redux";

import Loader from "../../common/Loader";
import { handleFileResponse } from '../../../utils';

const filterState = {
    selectedProgram: [],
    selectedSubject: null,
    selectedChapter: [],
}

const initalState = {
    ...filterState,
    subjectList: [],
    chapterList: [],
    targetExamList: [],
    selectedMpps: []
}

const { Option } = Select;

const MppList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [noData, setNoData] = useState(false)
    const [state, setState] = useState({ ...initalState })
    const [filterLoading, setFilterLoading] = useState(false);
    const SelectedSubjectRef = useRef(state.selectedSubject)
    const SelectedProgramRef = useRef(state.selectedProgram)
    const SelectedChapterRef = useRef(state.selectedChapter)
    const [isFilterApplied, setIsFilterApplied] = useState(false)

    const { skip, limit } = useSelector((state) => state.scrollingList);

    const downloadPDF = async (mppID, name) => {
        // const response = await Api.doDownload("GET", {}, `${ApiUrl.MPP}/download/pdf/${mppID}`, true)
        await handleFileResponse(`${ApiUrl.MPP}/download/pdf/${mppID}`, name+'.docx')
    }

    const handleSearchText = (e) => {
        const value = e.target.value;
        handleSearch(value);
    };

    const handleOnView = () => {
        dispatch({ type: SCRL_RESET_LIST })
        if (scrollContainer.current) {
            const scrollTopPos = scrollContainer.current.scrollTop;
            saveScrollPosition(scrollTopPos);
        }
    };

    const handleState = (payload) => {
        setState((state) => { return { ...state, ...payload } })
    }

    const ClearFilter = () => {
        handleState({ ...filterState })
        SelectedSubjectRef.current = null
        SelectedProgramRef.current = []
        SelectedChapterRef.current = []
        handleState({ chapterList: [] })
    }

    const getProgramSubjectList = async () => {
        let url = `${ApiUrl.MPP}/list`;
        const data = await Api.doFetch("GET", {}, url);
        if (data.status) {
            const payload = data.data
            if (payload) {
                handleState({ subjectList: payload.subjects.sort((a, b) => a.subject_name.localeCompare(b.subject_name)) })
                handleState({ targetExamList: payload.target_exams.sort((a, b) => a.target_exam.localeCompare(b.target_exam)) })
            }
        }
    }

    const getChapter = async (subjectID) => {
        let url = `${ApiUrl.MPP}/list/chapters/${subjectID}`;
        const data = await Api.doFetch("GET", {}, url);

        if (data.status) {
            const payload = data.data
            if (payload) {
                handleState({ chapterList: payload.sort((a, b) => a.chapter_name.localeCompare(b.chapter_name)) })
            }
        }
    }

    const getMpps = useCallback(async ({ skip = 0, search = "", limit = 10 }) => {
        let url = `${ApiUrl.MPP}/list`;
        let postData = {
            "skip": skip,
            "limit": limit,
        }
        if (SelectedProgramRef.current && SelectedProgramRef.current.length > 0) {
            postData.target_exam_ids = SelectedProgramRef.current
        }

        if (SelectedSubjectRef.current && SelectedSubjectRef.current !== null) {
            postData.subject_ids = [SelectedSubjectRef.current]
        }
        if (SelectedChapterRef.current && SelectedChapterRef.current.length > 0) {
            postData.chapter_ids = SelectedChapterRef.current
        }

        if (search && search.trim().length > 0) {
            postData.search = search;
        }

        const data = await Api.doFetch("POST", postData, url);

        if (data?.status === true) {
            const payload = data.data;
            if (payload) {
                const { mpps: items, total, skip, limit } = payload;
                return {
                    items,
                    total,
                    skip,
                    limit,
                };
            }
            return data.data.mpps;
        }
        setNoData(true);
        return null;
    }, []);

    const getFilteredMpps = async () => {
        setFilterLoading(true);
        let payload = {
            skip: 0,
            limit: limit,
            search: searchText
        }
        const data = await getMpps({ ...payload });

        dispatch({
            type: SCRL_HANDLE_SEARCH_INIT,
            payload: {
                list: (data && data.items) || [],
                skip: 0,
                total: (data && data.total) || 0,
                scrollLoading: false,
            },
        });
        setFilterLoading(false);
    }

    const [scrollContainer, { loading, scrollLoading, list: mppList, handleScroll, searchText, handleSearch, saveScrollPosition, scrollPositionY }] = useScrollingList({
        listKey: "mpps",
        getData: getMpps,
    });

    useEffect(() => {
        getProgramSubjectList()
        handleState({ selectedMpps: [] })
    }, [])

    useEffect(() => {
        if (state.selectedSubject) {
            getChapter(state.selectedSubject)
        }
    }, [state.selectedSubject])

    useEffect(() => {
        if (scrollContainer.current && scrollPositionY !== 0) {
            scrollContainer.current.scrollTo(0, scrollPositionY);
        }
    }, [scrollContainer, scrollPositionY]);

    return (
        <>
            <div className="mpp_list_container">
                <div className="course_list_header">
                    <div className="d-flex align-items-center">
                        <img
                            src={LeftArrow}
                            alt="go back"
                            className="cursor-pointer"
                            onClick={() => {
                                navigate("/dashboard");
                            }}
                        />

                        <span className="reversal_detail_header_text ml-2">List of MPPs</span>
                    </div>

                    <div className="d-flex align-items-center course_search_wrapper">
                        <img src={SearchIcon} alt="search" className="course_search_image" />
                        <input
                            className="course_search_input"
                            defaultValue={searchText}
                            onChange={handleSearchText}
                            placeholder="Search"
                        />
                    </div>
                </div>
                {/* filter */}

                <div className=" d-flex course_list_header justify-content-flex-start">
                    <div className="mpp_divider3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Program</p>

                        <div className="antd_dropdown" >
                            <SelectWithTooltip
                                value={state.selectedProgram}
                                setValue={value => {
                                    let index = value.indexOf("Select All");
                                    if (index >= 0) {
                                        handleState({ selectedProgram: state.targetExamList.map((x) => x.id) })
                                    } else {
                                        handleState({ selectedProgram: value })
                                    }
                                }}
                                keyValue={'target_exam'}
                                data={state.targetExamList}
                                placeholder={"Select program"}
                                selectAll={state.targetExamList.length > 0 && true}


                            />
                        </div>
                    </div>

                    <div className="mpp_divider3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Subject</p>

                        <div className="antd_dropdown" >
                            <Select
                                style={{ width: '100%' }}
                                placeholder={"Select subject"}
                                onChange={value => {
                                    handleState({ selectedSubject: value })
                                }}
                                value={state.selectedSubject}
                                optionFilterProp="children"
                                showArrow
                                allowClear
                                showSearch
                            >
                                {
                                    state.subjectList && state.subjectList.map((options, index) => {
                                        return (
                                            <Option
                                                key={index}
                                                value={options.id}
                                            >{options.subject_name}</Option>
                                        )
                                    })
                                }
                            </Select>

                        </div>
                    </div>
                    <div className="mpp_divider3 p-0 filter-heading-col-assign-ques my-1">
                        <p className="cheque_filter_drop_header mb-1">Chapter</p>

                        <div className="antd_dropdown" >
                            <SelectWithTooltip
                                value={state.selectedChapter}
                                setValue={value => {
                                    let index = value.indexOf("Select All");
                                    if (index >= 0) {
                                        handleState({ selectedChapter: state.chapterList.map((x) => x.id) })
                                    } else {
                                        handleState({ selectedChapter: value })
                                    }
                                }}
                                data={state.chapterList}
                                placeholder={"Select chapter"}
                                keyValue={"chapter_name"}
                                selectAll={state.chapterList.length > 0 && true}
                            />
                        </div>
                    </div>

                    <div className="my-2 padding_top">
                        <button
                            className="btn cheques_apply-filter-button mr-2"
                            onClick={() => {
                                if (state.selectedProgram.length !== 0 || state.selectedChapter.length !== 0 || state.selectedSubject !== null) {
                                    SelectedSubjectRef.current = state.selectedSubject
                                    SelectedProgramRef.current = state.selectedProgram
                                    SelectedChapterRef.current = state.selectedChapter
                                    getFilteredMpps()
                                    setIsFilterApplied(true)
                                }

                            }}
                        >
                            Apply
                        </button>

                        <span onClick={() => {
                            if (state.selectedProgram.length !== 0 || state.selectedChapter.length !== 0 || state.selectedSubject !== null) {
                                ClearFilter()
                                getFilteredMpps()
                                setIsFilterApplied(false)
                            }

                        }}
                            className="clear-filter-cheques ml-2" >Clear</span>
                    </div>
                </div>

                {loading || filterLoading ? (
                    <div className="loader_container">
                        <Loader />
                    </div>
                ) : mppList.length === 0 ? (
                    <div className="student_search_empty_container mt-5">
                        <div className="student_search_empty_img_container">
                            <img src={Empty} alt="not found" className="student_search_empty_img" />
                        </div>

                        {
                            noData ?
                                <p className="student_search_empty_header">
                                    No mpps found
                                </p>
                                :
                                <>
                                    <p className="student_search_empty_header">
                                        No results found
                                    </p>
                                    <span className="student_search_empty_label">
                                        Try adjusting your search to find
                                    </span>
                                    <span className="student_search_empty_label">
                                        the best results
                                    </span>
                                </>
                        }
                    </div>
                ) : <>
                    {isFilterApplied && <div className="d-flex align-items-center  mt-5" >
                        <input
                            className="form-check-input assign-question-checkbox"
                            type="checkbox"
                            checked={state.selectedMpps.length === mppList.length}
                            style={{ cursor: "pointer", marginRight: 20 }}

                            onChange={(e) => {
                                if (e.target.checked) {
                                    let tempSelected = []
                                    // eslint-disable-next-line array-callback-return
                                    mppList.length > 0 && mppList.map((mpp) => {
                                        tempSelected.push(mpp.mpp_id)
                                    })
                                    handleState({ selectedMpps: tempSelected })

                                } else handleState({ selectedMpps: [] })

                            }}
                        />
                        {/* <Checkbox 
                                    className="form-check-input assign-question-checkbox"
                                    checked={state.selectedMpps.length === mppList.length}
                                  indeterminate={state.selectedMpps.length > 0 && state.selectedMpps.length !== mppList.length}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            let tempSelected = []
                                            // eslint-disable-next-line array-callback-return
                                            mppList.length > 0 && mppList.map((mpp) => {
                                                tempSelected.push(mpp.mpp_id)
                                            })
                                            handleState({ selectedMpps: tempSelected })

                                        } else handleState({ selectedMpps: [] })

                                    }}
                              /> */}
                        <span className="student_search_filter_heading"> Select All</span>
                    </div>}

                    <div className={`mpp_scroll_container ${!isFilterApplied && 'big'}`}
                        ref={scrollContainer}
                        onScroll={handleScroll}
                    >
                        {mppList && mppList.map((mpp) => {
                            return <MppCard key={mpp.mpp_id} navigate={navigate} data={mpp} handleOnView={handleOnView} selectedMpps={state.selectedMpps} setState={setState} handleState={handleState} downloadPDF={downloadPDF} />
                        })}
                        {
                            !loading && scrollLoading &&
                            <div className="loader_scroll_container">
                                <Loader />
                            </div>
                        }
                    </div>
                </>
                }
            </div>
            {(!loading && !filterLoading && mppList.length !== 0) && <div className="mpp_footer" >
                <div className="d-flex align-items-center">
                    <span style={{ paddingLeft: 25 }}>No of selected dpps</span>
                    <div
                        className="mpp_count ml-4"
                    >
                        <span>{state.selectedMpps.length}</span>
                    </div>
                </div>

                <div className="bottom-submit-con ">
                    <button
                        disabled={state.selectedMpps.length === 0}
                        onClick={() => {
                            dispatch({ type: SCRL_RESET_LIST })
                            navigate(`/mpp/assign`, { state: { mppIds: state.selectedMpps, fromList: true } })
                        }}
                        className="btn mpp_assign_button"
                    >
                        Assign Batches
                    </button>
                </div>
            </div>}
        </>
    )
}

export default MppList