import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/Login.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";


import Logo from "../../assets/img/mycrm-logo.svg";
import Swal from 'sweetalert2'

import { getAuthDetails } from "../../utils/helperFunctions";
import Api from "../common/api";
import { segmentEvents } from "../../utils/constants";

function Login() {
    let navigate = useNavigate();
    // const location = useLocation()

    // const redirectPath = location.state?.path || '/student'

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    //   const [isLoading, setIsLoading] = useState(false);
    

    // const checkAuth = () =>{
    //   let data = localStorage.getItem("userdetail");
    //   data = JSON.parse(data);

    // }

    const onSubmithandler = (e) => {
        e.preventDefault();
        // setIsLoading(true);
        // let data = localStorage.getItem("userdetail");
        // data = JSON.parse(data);
        // console.log(data);
        var formdata = { username: "", password: "" };
        formdata.username = username;
        formdata.password = password;
        var input = JSON.stringify(formdata);
        var requestOptions = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: input,
        };

        //login API (get-user-type) uses the get method to get data from backend according to there user type such as user_type: student, support etc

        fetch(`${ process.env.REACT_APP_BASE_URL }login/`, requestOptions)
            .then((response) => response.json())
            .then((json) => {
                if (typeof json.token != "undefined") {
                    fetch(
                        `${ process.env.REACT_APP_BASE_URL }users/get-user-id-base-on-username/?username=${ username }`,
                        {
                            method: "GET",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: `Token ${ json.token }`,
                            },
                        }
                    )
                        .then((res) => res.json())
                        .then((D) => {
                            // console.log(D.payload.user_id,'data')
                            let store = {};
                            store["user_display_name"] = D?.payload?.name;
                            store["email"] = D?.payload?.email || "";
                            store["username"] = username;
                            store["token"] = json.token;
                            store["authenticated"] = true;
                            store["user_id"] = D.payload.user_id;
                            localStorage.setItem("userdetail", JSON.stringify(store));
                            getAuthDetails();
                            Api.identifyUser();
                            Api.trackEvent(segmentEvents.GENERAL.LOGIN);
                            navigate("/dashboard");
                        });
                    // this.ch();
                    // });
                } else {
                    Swal.fire(
                        "Invalid credentials",
                        'Please enter correct mobile number and password',
                        'error'
                    )
                    // this.setState({ authenticated: false });
                    localStorage.clear();
                    // this.ch();
                }
            });
    };

    // const getOtp = () => {
    //     var requestOptions = {
    //         redirect: "follow",
    //         // headers: {
    //         //     'Authorization': 'Token ' + this.state.token
    //         // },
    //     };
    //     if (isNaN(mobileNumber) === true) {
    //         // console.log('hello')
    //         toast.error("Input Value Must be a Number", {
    //             position: "top-center",
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //         });
    //     } else {
    //         let formdata = new FormData();
    //         formdata.append("username", mobileNumber);
    //         formdata.append("is_crm_loging", "1");
    //         axios
    //             .post(
    //                 process.env.REACT_APP_BASE_URL + `forgot-password-request/`,
    //                 formdata,
    //                 requestOptions
    //             )
    //             .then((data) => {
    //                 // console.log(data)
    //                 if (data.data.Success) {
    //                     toast.success("Otp sent Successfully", {
    //                         position: "top-center",
    //                         autoClose: 1000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                     });
    //                     setTimeout(() => {

    //                     }, 1500);
    //                 } else {
    //                     toast.error("User Does Not Exist", {
    //                         position: "top-center",
    //                         autoClose: 2000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                     });
    //                 }
    //             })
    //             .catch((err) => {
    //                 toast.error("Server Error", {
    //                     position: "top-center",
    //                     autoClose: 2000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                 });
    //             });
    //     }
    // };

    const onusernamechange = (e) => {
        let variable = e.target.value;
        setUsername(variable);
    };
    const onpasswordchange = (e) => {
        let variable = e.target.value;
        setPassword(variable);
    };

    useEffect(() => {
        if (Api.isAuthenticated()) {
            navigate("/dashboard");
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <div className="row main-cont">
                <div className="col-md-8 img-side"></div>
                <ToastContainer />

                <div className="col-md-4 form-side">
                    <img src={Logo} className="logo-img" alt="" />

                    <div className="login-div">
                        <h3 className="login-text blue-primary mt-4 mb-1">Log In</h3>
                        <p className="blue-primary">
                            Enter your Phone number and Password to login.
                        </p>

                        <form className="formm mt-5" onSubmit={onSubmithandler}>
                            <div className="mb-3">
                                <label className="form-label">Mobile Number</label>
                                <input
                                    className="form-control login-form-input-f"
                                    placeholder="Mobile Number"
                                    required
                                    // value={username}
                                    onChange={onusernamechange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input
                                    type="password"
                                    className="form-control login-form-input-f"
                                    placeholder="Password"
                                    required
                                    // value={password}
                                    onChange={onpasswordchange}
                                />
                            </div>

                            <div className="row-check mb-5">
                                <div className=" text-left">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="exampleCheck1"
                                        >
                                            Remember Me
                                        </label>
                                    </div>
                                </div>
                                <div className="text-right" onClick={() => navigate("/forgotPassword")} >
                                    <a href="/#" className="forgot-pass">Forgot Password ?</a>
                                </div>
                            </div>

                            <button className="btn login-btn p-3" type="submit">Login</button>
                        </form>

                        <p className="student-line pt-2">
                            If you are a student,{" "}
                            <a href="https://student.myclassroom.digital" className="student-link">
                                click here
                            </a>
                        </p>
                    </div>

                </div>
            </div>

        </>
    );
}

export default Login;
