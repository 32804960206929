import React, { useState } from "react";
import { DatePicker } from "antd";
import ErrorText from "../../common/errorText";
import moment from "moment";

const Filter = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startDateRef,
    endDateRef,
    getDetails,
    filterLoading
}) => {

    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [dateError, setDateError] = useState("");

    const canApplyFilter = (skipStateUpdate = false) => {

        if (startDate && !endDate) {
            if (!skipStateUpdate) setEndDateError("Please select To date");
            return false;
        }

        if (endDate && !startDate) {
            if (!skipStateUpdate) setStartDateError("Please select From date");
            return false;
        }

        if (startDate && endDate && moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
            if (!skipStateUpdate) setDateError("From date cannot be greater than To date");
            return false;
        }

        if (
            startDate ||
            endDate
        ) {
            return true;
        }

        return false;
    };

    const applyFilter = () => {

        if (
            startDate ||
            endDate
        ) {
            return true;
        }

        return false;
    };

    const handleClearFilter = () => {
        setEndDate("");
        setStartDate("");

        setStartDateError("");
        setEndDateError("");
        setDateError("");

        let flag = false;
        if (startDateRef.current && endDateRef.current) {
            flag = true;
        }

        startDateRef.current = "";
        endDateRef.current = "";

        flag && getDetails();
    }

    return (
        <div className="accountant_dashboard_filter_container"  >

            <div className="antd_dropdown confirm_input_container mx-2">
                <p className="student_filter_drop_header">
                    {startDateRef.current&&<span className="marker" ></span>}
                    From
                    {/* <Asterisk /> */}
                </p>
                <DatePicker
                    allowClear={false}
                    className="attendance_date_picker mb-0"
                    getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                    }}
                    // style={{ width: "100%" }}
                    format={"DD/MM/YYYY"}
                    value={startDate}
                    onChange={(date) => {
                        setStartDate(date);
                        setDateError("");
                        setStartDateError("");
                    }}
                    placeholder="dd/mm/yyyy"
                />
                {
                    dateError &&
                    <ErrorText text={dateError} />
                }
                {
                    startDateError &&
                    <ErrorText text={startDateError} />
                }
            </div>
            <div className="antd_dropdown confirm_input_container mx-2">
                <p className="student_filter_drop_header">
                    {endDateRef.current&&<span className="marker" ></span>}
                    To
                    {/* <Asterisk /> */}
                </p>
                <DatePicker
                    allowClear={false}
                    className="attendance_date_picker mb-0"
                    getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                    }}
                    // style={{ width: "100%" }}
                    format={"DD/MM/YYYY"}
                    value={endDate}
                    onChange={(date) => {
                        setDateError("");
                        setEndDate(date);
                        setEndDateError("");
                    }}
                    placeholder="dd/mm/yyyy"
                />
                {
                    endDateError &&
                    <ErrorText text={endDateError} />
                }
            </div>

            <div className="my-2 padding_top">
                <button
                    className="btn btn-md course_add_next mx-2"
                    onClick={() => {
                        if (!canApplyFilter()) {
                            return;
                        }
                        if (!filterLoading) {
                            startDateRef.current = startDate;
                            endDateRef.current = endDate;

                            getDetails();
                        }
                    }}
                    disabled={(filterLoading || !applyFilter()) ? true : false}
                >
                    Apply
                </button>

                <span className="clear-filter-cheques ml-2" onClick={handleClearFilter} style={{textDecoration: "none"}}>Clear</span>
            </div>

        </div>
    );
};

export default Filter;