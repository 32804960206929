import React from 'react'
import { useNavigate } from "react-router-dom";

import Pending from "../../assets/img/pending_reversal_request.svg";
import History from "../../assets/img/reversal_payment_history.svg";

function PaymentReversal() {

    const navigate = useNavigate();

    // let data = localStorage.getItem("userdetail");
    // data = JSON.parse(data);

    // const [oldPassword, setOldPassword] = useState('')
    // const [newPassword, setNewPassword] = useState('')
    // const [confirmPassword, setConfirmPassword] = useState('')


    // const changePassword = async () => {
    //     if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
    //         Swal.fire(
    //             'Error',
    //             'Empty Field',
    //             'warning'
    //         )
    //     } else if (newPassword !== confirmPassword) {
    //         Swal.fire(
    //             'Error',
    //             ` New password doesn't match`,
    //             'warning'
    //         )
    //     } else {
    //         try {
    //             var formdata = new FormData();
    //             formdata.append("old_password", oldPassword);
    //             formdata.append("new_password", newPassword);
    //             formdata.append("confirm_password", confirmPassword);
    //             const response = await fetch(`${ process.env.REACT_APP_BASE_URL }users/change-password/`, {
    //                 method: 'POST',
    //                 headers: {
    //                     'Accept': "application/json",
    //                     // "Content-Type": "application/json",
    //                     'Authorization': `Token ${ data.token }`
    //                 },
    //                 body: formdata
    //             })
    //             const D = await response.json()
    //             // setClassList(D)
    //             if (D?.Error) {
    //                 Swal.fire(
    //                     `${ D.Error }`,
    //                 )
    //             } else if (D?.Success) {
    //                 Swal.fire(
    //                     `${ D.Success }`,
    //                 )
    //             }
    //             console.log(D, 'Change Password')
    //         } catch (err) {
    //             console.log(err)
    //             Swal.fire(
    //                 `${ err.message }`,
    //             )
    //         }

    //     }

    // }


    return (

        <div className="containerMain">

            <div className="payment_reversal_container">

                <div
                    className="payment_reversal_container_pending"
                    onClick={() => {
                        navigate("/payment-reversal/pending")
                    }}
                >

                    <img
                        src={Pending}
                        alt="pending"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Pending
                    </span>
                    <span className="payment_reversal_container_text">
                        Reversal Request
                    </span>

                </div>

                <div
                    className="payment_reversal_container_history"
                    onClick={() => {
                        navigate("/payment-reversal/history")
                    }}
                >

                    <img
                        src={History}
                        alt="history"
                        className="mb-3"
                    />

                    <span className="payment_reversal_container_text">
                        Reversal
                    </span>
                    <span className="payment_reversal_container_text">
                        Payment History
                    </span>

                </div>

            </div>


        </div>
    )
}

export default PaymentReversal;