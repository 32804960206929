import React from "react";
import { currencyFormatter } from "../../../utils/helperFunctions";

const DeleteConfirmation = ({
    numberToCardinal,
    current_row_data,
    confirmDeleteRow,
    convertDate
}) => {
    return (
        <div className="modal-content">
            <div className="modal-body">
                <div className="content">

                    <div className="tableWrap">
                        <div className="d-flex deleteText">
                            <p style={{ fontWeight: "bold" }}>
                                Are you sure you want to delete {numberToCardinal(current_row_data?.installment_no)} installment?
                            </p>
                            <div className="InnerPopup">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <p>  Due Date</p>
                                    </div>
                                    <div className="col-xs-6">
                                        <span>: {convertDate(current_row_data?.start_date)}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <p>Installment Amount</p></div>
                                    <div className="col-xs-6"> <span>: {currencyFormatter(Number(current_row_data?.amount_per_installment))}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex btnsWrap">
                            <button
                                type="button"
                                className="btn modal-button cancelBtn"
                                data-dismiss="modal"
                                style={{
                                    backgroundColor: "#ffffff",
                                    border: "1px solid #012C63",
                                }}
                            >
                                Cancel
                            </button>

                            <button
                                type="button"
                                className="btn btn-primary modal-button confirmBtn2"
                                data-dismiss="modal"
                                onClick={() => confirmDeleteRow()}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirmation;