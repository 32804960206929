import React from "react";
import SearchStudent from "../../assets/img/search_student.svg";
import AddStudent from "../../assets/img/add_student.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RESET_STUDENT_SEARCH } from "../../actions/types";

const LeadManagement = () => {
	let navigate = useNavigate();

	const dispatch = useDispatch();
	return (
		<div className="containerMain">
			<div className="payment_reversal_container">
				<div
					className="payment_reversal_container_pending"
					onClick={() => {
						dispatch({ type: RESET_STUDENT_SEARCH });
						navigate("/lead/search");
					}}
				>
					<img src={SearchStudent} alt="add course" className="mb-3" />

					<span className="payment_reversal_container_text">Search an</span>
					<span className="payment_reversal_container_text">Existing Lead</span>
				</div>

				<div
					className="payment_reversal_container_history"
					onClick={() => {
						navigate("/lead/add");
					}}
				>
					<img src={AddStudent} alt="subject list" className="mb-3" />
					<span className="payment_reversal_container_text">Add</span>

					<span className="payment_reversal_container_text">New Lead</span>
				</div>
			</div>
		</div>
	);
};

export default LeadManagement;
