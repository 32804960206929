import React from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { currencyFormatter } from "../../../../utils/helperFunctions";

const Confirmation = ({
    open,
    close,
    selectedCash,
    confirmCash
}) => {
    return (
        <Modal
            open={open}
            onClose={close}
            className="payment_reversal_cofirmation_modal"
        >
            <Box className="view_cheque_modal" >
                <span className="modal_text_18" >
                    ₹ {currencyFormatter(selectedCash, false)} cash confirmed. Once confirmed can’t be changed in future.
                </span>
                <p className='modal_text_18_g' >
                    Still you want to proceed?
                </p>


                <div
                    className="mt-3"
                    style={{ float: "right" }}
                >
                    <button
                        className="btn btn-md student_detail_no"
                        onClick={close}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-md student_detail_password_confirm ml-2"
                        onClick={() => {
                            close();
                            confirmCash();
                        }}
                    >
                        Confirm
                    </button>
                </div>

            </Box>
        </Modal>
    );
};

export default Confirmation;