import React from "react";
import StudentImg from "../../../../assets/img/student.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { currencyFormatter } from "../../../../utils/helperFunctions";
import moment from "moment";
import flagImg from "../../../../assets/img/flagImg.svg"

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const CashNotConfirmedDetails = ({
    data
}) => {

    const {
        counsellor_name,
        counsellor_id,
        centre_name,
        centre_id,
        student_fees,
        total_amount_paid,
        paymentIds
    } = data

    return (
        <>
            <div className="pending-cheques__details_container-two">
                <div className="details_container_row1">
                    <div className="confirm_cash_counselor_column1">
                        <p className="cheque-details__label mb-0">Counsellor</p>
                        <p className="cheque-details__label-1 mb-0">
                            {
                                counsellor_name.length > 20 ?
                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={counsellor_name}>
                                    <span style={{ cursor: "pointer" }}>{counsellor_name.substring(0, 20) + "..."}</span>
                                </BootstrapTooltip>
                                :
                                counsellor_name
                            }
                        </p>
                    </div>

                    <div className="confirm_cash_counselor_column2">
                        <p className="cheque-details__label mb-0">Transaction count</p>
                        <p className="cheque-details__label-1 mb-0">{student_fees.length}</p>
                    </div>

                    <div className="confirm_cash_counselor_column2">
                        <p className="cheque-details__label mb-0">Cash collection</p>
                        <p className="cheque-details__label-1 mb-0">₹ {currencyFormatter(total_amount_paid, false)}</p>
                    </div>

                    <div className="confirm_cash_counselor_column3">
                        <p className="cheque-details__label mb-0">Centre</p>
                        <p className="cheque-details__label-1 mb-0">
                            {
                                centre_name.length > 20 ?
                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={centre_name}>
                                    <span style={{ cursor: "pointer" }}>{centre_name.substring(0, 20) + "..."}</span>
                                </BootstrapTooltip>
                                :
                                centre_name
                            }
                        </p>
                    </div>
                </div>

                {
                    student_fees.map(student => {
                        return (
                            <>
                            <div 
                                className="confirm_cash_counselor_row" 
                                key={student.student_id}
                            >
                                <div className="confirm_cash_counselor_column1">
                                    <div className="confirm_cash_fm_view">
                                        <p className="cheque-details__label mb-0 d-flex align-items-center" style={{ fontWeight: "400" }}>
                                            <img src={StudentImg} alt="" className="mr-1" />
                                            Student name
                                        </p>
                                        <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "400" }}>
                                            {
                                                student.student_name.length > 20 ?
                                                <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={student.student_name}>
                                                    <span style={{ cursor: "pointer" }}>{student.student_name.substring(0, 20) + "..."}</span>
                                                </BootstrapTooltip>
                                                :
                                                student.student_name
                                            }
                                        </p>
                                        <p className="cheque-details__label mb-0" style={{ fontWeight: "400", fontSize: "10px" }}>
                                            ID : {student.student_id}
                                        </p>
                                    </div>
                                </div>

                                <div className="confirm_cash_counselor_column2">
                                    <p className="cheque-details__label mb-0" style={{ fontWeight: "400" }}>
                                        Date of payment
                                    </p>
                                    <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "400" }}>
                                        {moment(student.paid_on_date).format("MMM DD, YYYY")}
                                    </p>
                                </div>

                                <div className="confirm_cash_counselor_column2">
                                    <p className="cheque-details__label mb-0" style={{ fontWeight: "400" }}>
                                        Cash collection
                                        {
                                            student.reversed && 
                                            <img 
                                                src={flagImg} 
                                                alt="reversal flag"
                                                className="reversal-flag-cash-recon"
                                            />
                                        }
                                    </p>
                                    <p className="cheque-details__label-1 mb-0" style={{ fontWeight: "400" }}>
                                        ₹ {currencyFormatter(student.amount_paid, false)}
                                    </p>
                                </div>
                                <div className="confirm_cash_counselor_column3"></div>
                            </div>
                            {
                                student.reversed &&
                                <div className="reversal-flag-div-fm" >
                                    <img src={flagImg} alt="reversal flag" style={{ width: "13px", height: "13px" }} />
                                    <span className="reversal-flag-text">Reversal raised by counsellor for the cash entry</span>
                                </div>
                            }
                            </>
                        );
                    })
                }
            </div>
        </>
    );
};

export default CashNotConfirmedDetails;
