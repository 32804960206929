import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import AddCircle from "../../../assets/img/add_circle.svg";
import SearchImg from "../../../assets/img/search.svg";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import Loader from "../../common/Loader";
import Empty from "../../../assets/img/empty_student.svg";
import { useScrollingList } from "../../../utils/hooks/use-scrolling-list";
import moment from "moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${className} tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "13px",
        },
    })
);

const BatchList = () => {
    const [noData, setNoData] = useState(false);

    const navigate = useNavigate();

    const handleSearchText = (e) => {
        const value = e.target.value;

        handleSearch(value);
    };

    const handleOnEdit = () => {
        if (scrollContainer.current) {
            const scrollTopPos = scrollContainer.current.scrollTop;
            saveScrollPosition(scrollTopPos);
        }
    };

    const getBatches = useCallback(async ({ skip = 0, search = "", limit = 10 }) => {
        let url = `${ApiUrl.BATCH}?skip=${skip}&limit=${limit}`;

        if (search && search.trim().length > 0) {
            url += `&search=${search}`;
        }

        const data = await Api.doFetch("GET", {}, url);

        if (data?.status === true) {
            const payload = data.data;
            console.log(payload)
            if (payload) {
                const { data: items, total, skip, limit } = payload;

                return {
                    items,
                    total,
                    skip,
                    limit,
                };
            }
        }

        setNoData(true);

        return null;
    }, []);

    const [scrollContainer, { loading, scrollLoading, list: batchList, handleScroll, searchText, handleSearch, saveScrollPosition, scrollPositionY }] =
        useScrollingList({
            listKey: "batches",
            getData: getBatches,
        });

    useEffect(() => {
        if (scrollContainer.current && scrollPositionY !== 0) {
            scrollContainer.current.scrollTo(0, scrollPositionY);
        }
    }, [scrollContainer, scrollPositionY]);

    return (
        <div className="course_list_container">
            <div className="course_list_header">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/batch");
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">List of batches</span>
                </div>

                <div className="d-flex align-items-center course_search_wrapper">
                    <img src={SearchImg} alt="search" className="course_search_image" />
                    <input className="course_search_input" defaultValue={searchText} onChange={handleSearchText} placeholder="Search a batch" />
                </div>
            </div>

            <div id="batch_scroll_container_id" className={"subject_scroll_container"} ref={scrollContainer} onScroll={handleScroll}>
                {loading ? (
                    <div className="loader_container">
                        <Loader />
                    </div>
                ) : batchList?.length === 0 ? (
                    <div className="student_search_empty_container mt-5">
                        <div className="student_search_empty_img_container">
                            <img src={Empty} alt="not found" className="student_search_empty_img" />
                        </div>

                        {noData ? (
                            <p className="student_search_empty_header">No batches found</p>
                        ) : (
                            <>
                                <p className="student_search_empty_header">No results found</p>
                                <span className="student_search_empty_label">Try adjusting your search to find</span>
                                <span className="student_search_empty_label">the best results</span>
                            </>
                        )}
                    </div>
                ) : (
                    batchList.map((batch, index) => {
                        return (
                            <div className="course_list_card" key={index}>
                                <div className="course_divider1">
                                    <p className="course_list_card_header">Batch name</p>
                                    {batch.batch_name.length > 35 ? (
                                        <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={batch.batch_name}>
                                            <p className="course_list_card_value">{batch.batch_name.slice(0, 35) + " ..."}</p>
                                        </BootstrapTooltip>
                                    ) : (
                                        <p className="course_list_card_value">{batch.batch_name}</p>
                                    )}
                                </div>

                                <div className="course_divider2">
                                    <p className="course_list_card_header">Course name</p>
                                    {batch.course_name.length > 35 ? (
                                        <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={batch.course_name}>
                                            <p className="course_list_card_value">{batch.course_name.slice(0, 35) + " ..."}</p>
                                        </BootstrapTooltip>
                                    ) : (
                                        <p className="course_list_card_value">{batch.course_name}</p>
                                    )}
                                </div>

                                <div className="course_divider2">
                                    <p className="course_list_card_header">Associated centres</p>
                                    <p className="course_list_card_value" >
                                    {batch.associated_centres.length>0&&
                                        (batch.associated_centres[0].length > 35 ? (
                                            <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={batch.associated_centres[0]}>
                                                <span >{batch.associated_centres[0].slice(0, 35) + " ..."}</span>
                                            </BootstrapTooltip>
                                        ) : (
                                            <span style={{paddingRight:10}} >{batch.associated_centres[0]}</span>
                                            ))}
                                        {batch.associated_centres.length>1&&(
                                            <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={batch.associated_centres.slice(1).map((item,index)=>`${item}${batch.associated_centres.length-2===index?"":", "}`)}>
                                            <span className="selected_tags_name" style={{cursor:'pointer','padding': '4px 8px'}} >+{batch.associated_centres.length-1}</span>
                                        </BootstrapTooltip>
                                        )}
                                        </p>
                                </div>

                                <div className="course_divider3">
                                    <p className="course_list_card_header">Start date</p>
                                    {batch.start_dates.length>0&&
                                        <p className="course_list_card_value">

                                            {moment(batch.start_dates[0]).format("DD-MM-YYYY")}
                                            {batch.start_dates.length>1&&(
                                            <BootstrapTooltip disableFocusListener enterTouchDelay={0} title={batch.start_dates.slice(1).map((item,index)=>`${moment(item).format("DD-MM-YYYY")}${batch.start_dates.length-2===index?"":", "}`)}>
                                            <span className="selected_tags_name" style={{cursor:'pointer','padding': '4px 8px'}} >+{batch.start_dates.length-1}</span>
                                        </BootstrapTooltip>)}
                                            
                                        </p>}

                                </div>

                                <div className="course_divider4">
                                    <div
                                        onClick={() => {
                                            navigate(`/batch/${batch.id}`);
                                            handleOnEdit();
                                        }}
                                        className="course_action_wrapper"
                                    >
                                        <span>View</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}

                {!loading && scrollLoading && (
                    <div className="loader_scroll_container">
                        <Loader />
                    </div>
                )}

                {!loading && !scrollLoading && (
                    <div
                        className="add_course_container"
                        onClick={() => {
                            navigate("/batch/add");
                        }}
                    >
                        <div className="add_course_text_container">
                            <img src={AddCircle} alt="add" className="add_student_img" />

                            <span className="add_student_container_text">Add Batch</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BatchList;
