import React, { useState } from "react";
import PaymentHistoryCard from "./paymentHistoryCard";

import PaymentHistoryTable from "./paymentHistoryTable";

const PaymentHistory = ({
    
    hostel_installment_plans,

    paymentHistoryList,
    hostelPaymentHistoryList,
    
    getPaymentHistory,
    getHostelPaymentHistory,

    courseHistoryLoading,
    hostelHistoryLoading,
    setHostelHistoryLoading,
    setCourseHistoryLoading,

    reversalID,
    setReversalID,
    reversalDeclined,
    setReversalDeclined,
    canViewReceipt,
    canDoReversal,
    checkCashConfirmed,

    isCourseActive
}) => {

    const [currentTab, set_currentTab] = useState("Course");

    const clickTab = (tabName) => {
        set_currentTab(tabName);
    };


    const downloadInvoice = async (paymentId) => {
        window.open(`/payment/paymentReceiptSummary/${paymentId}`, "_blank");
        // let formdata = new FormData();
        // formdata.append("student_id", student_id);
        // formdata.append("payment_id", paymentId);
        // formdata.append("section_id", studentDetails.student_data.section_assoc.id);
        // formdata.append("school_id", studentDetails.student_data.school_assoc.id);
        // set_downloaodClicked(true);
        // set_downloadPaymentId(paymentId);
        // const paymentHistoryRes = await httpPost(`${process.env.REACT_APP_BASE_URL}support/generate-payment-receipt/`, formdata);
        // if (paymentHistoryRes.status === 200) {
        //   window.open(paymentHistoryRes.data.payload.invoice_url, "_blank");
        //   set_downloaodClicked(false);
        //   set_downloadPaymentId();
        // } else {
        //   alert("Something went wrong, please try later");
        //   set_downloaodClicked(false);
        //   set_downloadPaymentId();
        // }
    };

    const downloadHostelInvoice = async (paymentId) => {
        window.open(`/payment/hostel-receipt/${paymentId}`, "_blank");
    };

    return (
        <>
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close payment_history_close" data-dismiss="modal">
                        &times;
                    </button>
                    <h4 className="modal-title">Payment History</h4>
                </div>
                <div className="modal-body">
                    
                    <div className="d-flex">
                        <div 
                            className={`${currentTab === "Course" ? "payment_history_selector_active" : "payment_history_selector"}`}
                            onClick={() => clickTab("Course")}
                        >
                            Course
                        </div>
                        {
                            hostel_installment_plans.length > 0 &&
                            <div 
                                className={`${currentTab === "Hostel" ? "payment_history_selector_active" : "payment_history_selector"} ml-3`}
                                onClick={() => clickTab("Hostel")}
                            >
                                Hostel    
                            </div>
                        }
                    </div>

                    <div className="tabWrap">

                        <div className="tab-content">
                            <div
                                id="CourseFee"
                                className={
                                    currentTab === "Course"
                                        ? "tab-pane fade in active"
                                        : "tab-pane fade"
                                }
                                style = {{ border: "none" }}
                            >
                                <div className="history-mob-cards">
                                    <PaymentHistoryCard 
                                        loading = {courseHistoryLoading}
                                        setLoading = {setCourseHistoryLoading}
                                        historyList = {paymentHistoryList}
                                        refresh = {getPaymentHistory}
                                        downloadInvoice = {downloadInvoice}

                                        setReversalID = {setReversalID}
                                        reversalID = {reversalID}
                                        reversalDeclined={reversalDeclined}
                                        setReversalDeclined={setReversalDeclined}
                                        canViewReceipt = {canViewReceipt}
                                        canDoReversal = {canDoReversal}
                                        checkCashConfirmed = {checkCashConfirmed}
                                        isCourseActive = {isCourseActive}
                                    />
                                </div>

                                <div className="history-web-table">
                                    <PaymentHistoryTable 
                                        loading = {courseHistoryLoading}
                                        setLoading = {setCourseHistoryLoading}
                                        historyList = {paymentHistoryList}
                                        refresh = {getPaymentHistory}
                                        downloadInvoice = {downloadInvoice}

                                        setReversalID = {setReversalID}
                                        reversalID = {reversalID}
                                        reversalDeclined={reversalDeclined}
                                        setReversalDeclined={setReversalDeclined}
                                        canViewReceipt = {canViewReceipt}
                                        canDoReversal = {canDoReversal}
                                        checkCashConfirmed = {checkCashConfirmed}
                                        isCourseActive = {isCourseActive}
                                    />
                                </div>
                                
                            </div>
                            <div
                                id="HostelFee"
                                className={
                                    currentTab === "Hostel"
                                        ? "tab-pane fade in active"
                                        : "tab-pane fade"
                                }
                                style = {{ border: "none" }}
                            >
                                <div className="history-web-table">
                                    <PaymentHistoryTable 
                                        currentTab = {currentTab}
                                        loading = {hostelHistoryLoading}
                                        setLoading = {setHostelHistoryLoading}
                                        historyList = {hostelPaymentHistoryList}
                                        refresh = {getHostelPaymentHistory}
                                        downloadInvoice = {downloadHostelInvoice}

                                        setReversalID = {setReversalID}
                                        reversalID = {reversalID}
                                        reversalDeclined={reversalDeclined}
                                        setReversalDeclined={setReversalDeclined}
                                        canViewReceipt = {canViewReceipt}
                                        canDoReversal = {canDoReversal}
                                        checkCashConfirmed = {checkCashConfirmed}
                                        isCourseActive = {isCourseActive}
                                    />
                                </div>

                                <div className="history-mob-cards">
                                    <PaymentHistoryCard 
                                        currentTab = {currentTab}
                                        loading = {hostelHistoryLoading}
                                        setLoading = {setHostelHistoryLoading}
                                        historyList = {hostelPaymentHistoryList}
                                        refresh = {getHostelPaymentHistory}
                                        downloadInvoice = {downloadHostelInvoice}

                                        setReversalID = {setReversalID}
                                        reversalID = {reversalID}
                                        reversalDeclined={reversalDeclined}
                                        setReversalDeclined={setReversalDeclined}
                                        canViewReceipt = {canViewReceipt}
                                        canDoReversal = {canDoReversal}
                                        checkCashConfirmed = {checkCashConfirmed}
                                        isCourseActive = {isCourseActive}
                                    />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentHistory;