import { useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import TopNavBar from "../navbar/TopNavBar";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useLocation } from "react-router-dom";
const UserAdd = () => {
    const navigate = useNavigate();
    const [userState, setUserState] = useState({
        allUserRoles: [],
        allSchools: [],
        toBeUpdated:false,
        userFormData:{
            first_name:'',
            mobile_number:'',
            email_id:'',
            schools:[],
            roles:[],
            user_id:"",
            selected_schools_string:""
        }
    })
    const location = useLocation();
    useEffect(() => {
        if(location.state){
            setUserState({
                ...userState,
                userFormData:location.state
            })
        }
    },[])

    let data = localStorage.getItem("userdetail");
    data = JSON.parse(data);
    const inputStyles = {
        width:'100%',
        height: '5rem',
    }

    const initFunction = async() => {
        try{
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}users/list-role-page-action/`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Token ${data.token}`,
                  },
                }
              );

              const D = await response.json();
        if (response.status === 401) {
            Swal.fire("Unauthorised?", "User logged into another device", "error");
            localStorage.clear();
            navigate("/error");
        }
        if (response.status === 200) {
            const res = await fetch(
                `${process.env.REACT_APP_BASE_URL}users/get-list-of-schools/`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Token ${data.token}`,
                  },
                }
              );
            const schools = await res.json();
                let t = userState;

            if(location.state){
                t = {
                    ...t,
                    userFormData:location.state
                }
            }

            t = {
                ...t,
                allUserRoles: D?.payload?.roles,
                allSchools: schools
            }
            setUserState(t);
        }
        }catch(error){

        }
    }

    useEffect(() => {
        initFunction();
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formData = userState?.userFormData;
        let schools = [];
        formData.schools.map((item) => {
            if(item!==''){
                schools.push({school_id:item})
            }
                
            
           
        })
        let roles = [];
         formData.roles.map((item) => {
            if(item!==''){
                roles.push({role_id:item})
            }
            
        })
        formData = {
            ...formData,
            schools:schools,
            roles:roles,
            gender:'male'
        }

        
       
        try{
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}users/add-edit-support-user/`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Token ${data.token}`,
                  },
                  body:JSON.stringify({user_school_role:[formData]})
                }
              );
            console.log(response)
        }catch(error){

        }

    }

    return (<>
        <div style={{ display: "flex" }}>
           
            <div style={{ width: "100%", padding: "0" }}>
               
                <form onSubmit={handleSubmit}>
                    <div style={{margin: "10rem"}}>
                        <h2>{userState?.toBeUpdated ? 'Update ': 'Add '} User</h2>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                            <Paper>
                                    <input type="text"  value={userState?.userFormData?.first_name} placeholder="Full Name" style={inputStyles} onChange={(e) => setUserState({
                                        ...userState,
                                        userFormData:{
                                            ...userState.userFormData,
                                            first_name: e.target.value
                                        }
                                    })} />
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper>
                                    <input type="number" value={userState?.userFormData?.mobile_number} placeholder="Mobile Number" style={inputStyles} onChange={(e) => setUserState({
                                        ...userState,
                                        userFormData:{
                                            ...userState.userFormData,
                                            mobile_number: e.target.value
                                        }
                                    })} />
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper>
                                    <input type="email" value={userState?.userFormData?.email_id} placeholder="Email Id" style={inputStyles} 
                                    onChange={(e) => setUserState({
                                        ...userState,
                                        userFormData:{
                                            ...userState.userFormData,
                                            email_id: e.target.value
                                        }
                                    })} />
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                {/* <Paper> */}

                                { userState?.allSchools &&
                                    <>
                                    Select Centers
                                    
                                        
                                        <Select
                                            multiple
                                            value={userState?.userFormData?.schools}                    
                                            onChange={(e) => {
                                               
                                                
                                                setUserState({
                                                    ...userState,
                                                    userFormData:{
                                                        ...userState.userFormData,
                                                        schools: e.target.value
                                                    }
                                                })
                                            }}
                                            input={<OutlinedInput label="Center" />}
                                            
                                            >
                                        {userState?.allSchools.map((item,index) => {
                                            return <MenuItem
                                            key={index}
                                            value={''+item?.id}
                                            
                                          > {item?.name} </MenuItem>
                                        })}
                                        </Select>



                                    

                                    </>
                                    }
                                {/* </Paper> */}
                            </Grid>
                            <Grid item xs={4}>
                                <Paper>
                                
                                </Paper>
                            </Grid>
                        </Grid>

                        { userState?.allUserRoles &&
                        <div style={{marginTop: '3rem'}}>
                            <h3>User Roles</h3>
                            <Grid container spacing={2}>
                             {userState?.allUserRoles.map((item,index) => {
                                return (
                                    <Grid item xs={4}>
                                        
                                        <input type="checkbox" checked={userState?.userFormData?.roles.includes(''+item?.role_id)} name="role" value={''+item?.role_id}   onChange={(e) => {
                                            let selectedRoles = userState?.userFormData?.roles;
                                            if(selectedRoles.includes(''+e.target.value)){
                                                let ind = selectedRoles.indexOf(''+e.target.value);
                                                selectedRoles.splice(ind,1);
                                            }else{
                                                selectedRoles.push(''+e.target.value);
                                            }

                                            setUserState({
                                                ...userState,
                                                userFormData:{
                                                    ...userState.userFormData,
                                                    roles:selectedRoles
                                                }
                                            })

                                        }}  /> {item?.role}
                            
                                       
                                    </Grid>
                                )
                             })}   

                            </Grid>
                            

                        </div>

                        }
                        <br />
                    <Button variant="contained" type="Submit" size="large"  > Submit</Button>

                    </div>
                    
                    
                    
                    
                </form>
            </div>
        </div>

    </>)
}

export default UserAdd;