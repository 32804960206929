import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const AddRole = () => {
    const navigate = useNavigate();
    const [roleState, setRoleState] = useState({
            allPageActions: [],
            selectedPermissions: [],
            roleName: "",
            roleId:"",
            pageModalVisible: false,
            pageName:""
    })
    const location = useLocation();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
  

    let data = localStorage.getItem("userdetail");
    data = JSON.parse(data);
    const inputStyles = {
        width:'100%',
        height: '5rem',
    }

    const initFunction = async() => {
        try{
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}users/list-page-action/`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Token ${data.token}`,
                  },
                }
              );

              const D = await response.json();
        if (response.status === 401) {
            Swal.fire("Unauthorised?", "User logged into another device", "error");
            localStorage.clear();
            navigate("/error");
        }
        if (response.status === 200) {
            let payload = roleState;
            if(location.state){
                let allPageActions = D?.payload.pages;
                try{
                    const response = await fetch(
                        `${process.env.REACT_APP_BASE_URL}users/list-page-action-for-role/?role_id=${location.state.roleId}`,
                        {
                          method: "GET",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Token ${data.token}`,
                          },
                        }
                      );
        
                      let selectedD = await response.json();
                if (response.status === 401) {
                    Swal.fire("Unauthorised?", "User logged into another device", "error");
                    localStorage.clear();
                    navigate("/error");
                }
                if (response.status === 200) {
                    let selectedPermissions = [];
                    selectedD?.payload?.pages.filter((item) => {
                        item.actions.map((page_action) => {
                            if(page_action.is_enabled){
                                selectedPermissions.push(""+page_action.page_action_id)
                            }
                            
                        } )

                    });
                    


                        payload = {
                            ...payload,
                            selectedPermissions: selectedPermissions,
                            roleId: location.state.roleId,
                            roleName: location.state.roleName,
                            allPageActions: allPageActions
                        }

                        setRoleState(payload)
                    
                }
                }catch(error){
                    
                }

            
            }else{
                payload = {
                    ...payload,
                     allPageActions: D.payload.pages
                }
                setRoleState(payload);
            }
            
            
            
        }
        }catch(error){

        }
    }

    const addPageHandler = async (e) => {
        e.preventDefault();
        // const formData = new FormData();
        // formData.append("page_name", roleState.pageName);
        // formData.append("action_name", "all");
        const formData = {
            page_name: roleState.pageName,
            action_name: "all"
        }
        try{
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}users/add-page-action/`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Token ${data.token}`,
                  },
                  body: JSON.stringify(formData)
                }
              );

              if(response.status === 200){
                initFunction();
                Swal.fire("Success", "Page Created Successfully", "success");
              }
        }catch(error){

        }

        

    }


    useEffect(() => {
        initFunction();
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formData = {role_page_action:[{
            role: roleState.roleName,
            role_id: roleState.roleId,
            pages: roleState.selectedPermissions.map((item) => {
                return({page_action_id: item})
            })
        }]};
       
        try{
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}users/add-edit-role/`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Token ${data.token}`,
                  },
                  body:JSON.stringify(formData)
                }
              );
        }catch(error){

        }

    }

    return (<>
        <div style={{ display: "flex" }}>
           
            <div style={{ width: "100%", padding: "0" }}>
               
              <form onSubmit={handleSubmit}>
                <div style={{margin: "10rem"}}>
                    <div>
                        <input type="text"  placeholder="Role Name" style={inputStyles} value={roleState?.roleName} onChange={(e) => setRoleState({
                            ...roleState,
                            roleName: e.target.value
                        })} />
                    </div>
                    <h2>Permissions</h2> <br />
                    {roleState?.allPageActions?.map((item) => {
                       return( 
                        <div>
                            <div>
                                <h3>
                                    {item?.page}
                                </h3>
                            </div>
                            <div>
                                {item?.actions?.map((action, index) => {
                                    return(
                                        <>
                                           
                                            <input type="checkbox" id="role" key={index} value={action.page_action_id} name="permissions" checked={roleState?.selectedPermissions?.includes(''+action.page_action_id)}   onChange={(e) => {
                                                let t = roleState?.selectedPermissions; 
                                            
                                                (t.indexOf(e.target.value)>-1) ? t.splice(t.indexOf(e.target.value),1) : t.push(e.target.value);
                                                setRoleState({
                                                    ...roleState,
                                                    selectedPermissions: t
                                                })  
                                                    
                                                
                                            }} />
                                            
                                            <label for="role"> {" " + action?.action}</label> &nbsp;
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        )
                    })}

                    <div>

                  

                    <Button variant="contained" type="button" onClick={(e) => {setRoleState({
                        ...roleState,
                        pageModalVisible: true
                    })}} >Add Page</Button>

                    <Button variant="contained" type="submit">Submit</Button>

                   
                </div>
                </div>
                
              </form>
            </div>
        </div>

          <Modal
                        open={roleState?.pageModalVisible}
                        onClose={(e) => setRoleState({
                            ...roleState,
                            pageModalVisible: false
                        })}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                            Add Page
                        </Typography>
                        <form onSubmit={addPageHandler}>
                        <input type="text" placeholder="Page Name" style={inputStyles} value={roleState.pageName} onChange={(e) => setRoleState({
                            ...roleState,
                            pageName: e.target.value
                        })} /> <br />
                        <Button variant="contained" type="submit">Add</Button>
                        </form>
                      
                        </Box>
                    </Modal>

    </>)
}

export default AddRole;