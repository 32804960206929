
import React from "react";

const InstructionText = ({ index = 0, text = '' }) => {
    return <div className="mpp_text_container">
        <span className="mpp_instruction_text">{index}. </span>
        <span className="mpp_instruction_text">{text}</span>
    </div>
}

export default InstructionText;