import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import "../student.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import moment from "moment";
import StudentCard from "./studentCard";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import SignedForm from "./signedForm";
import AddCircle from "../../../assets/img/add_circle.svg";
import Loader from "../../common/Loader";
import "react-toastify/dist/ReactToastify.css";
import AddCourse from "./addCourse";
import NotFound from "../../common/notFound";
import { useMediaQuery } from "@mui/material";
import WarningImg from "../../../assets/img/warning.svg";
import { segmentEvents } from "../../../utils/constants";
import { Input, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { roleConstant } from "../../../utils/helperFunctions";

const StudentDetail = () => {
	const navigate = useNavigate();
	const { roleList } = useSelector((state) => state.auth);

	const { student_id } = useParams();

	const [studentCourses, setStudentCourses] = useState([]);
	const [showCourseForm, setShowCourseForm] = useState(false);
	const [studentDetails, set_studentDetails] = useState(null);
	const [admission_form_list, set_admission_form_list] = useState([]);
	const [loading, setLoading] = useState(false);

	const [notFound, setNotFound] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [password, setPassword] = useState("");
	const [settingItemForChange, setSettingItemForChange] = useState(0);

	const handlePassword = (e) => {
		const value = e.target.value;
		setPassword(value);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = async () => {
		if (password == "test@123") {
			setIsModalOpen(false);
			setPassword("");
			update_or_delete_fee(settingItemForChange);
		} else {
			setPassword("");
			Swal.fire(`Password is incorrect`);
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setPassword("");
	};

	const isMobile = useMediaQuery("(max-width:767px)");
	const isSmall = useMediaQuery("(max-width:420px)");

	const getStudentDetails = async () => {
		setLoading(true);

		const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_DETAILS}?student_id=${student_id}`);

		setLoading(false);
		if (data && !data.message) {
			if (data.student_data?.is_free) {
				setNotFound(true);
				return;
			}
			set_studentDetails(data);
		} else if (data.message && data.message === "Student not found") {
			setNotFound(true);
		}
	};

	const update_or_delete_fee = async (item) => {
		if (item?.is_installment_created) {
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			var raw = JSON.stringify({
				student_id: student_id,
				school_id: item?.school_id,
				section_id: item?.section_id,
			});
			var requestOptions = {
				method: "DELETE",
				headers: myHeaders,
				body: raw,
				redirect: "follow",
			};
			fetch(process.env.REACT_APP_BASE_URL + "test/v3/delete-student-installment-plans/", requestOptions)
				.then((response) => response.text())
				.then((result) => {
					console.log(result);
					window.location.reload();
				})
				.catch((error) => console.log("error", error));
		} else {
			let fee = prompt("Enter total course fee!");
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			var raw = JSON.stringify({
				student_id: student_id,
				school_id: item?.school_id,
				section_id: item?.section_id,
				total_fee: fee,
			});
			var requestOptions = {
				method: "PUT",
				headers: myHeaders,
				body: raw,
				redirect: "follow",
			};
			fetch(process.env.REACT_APP_BASE_URL + "test/v3/update-student-fee-portal/", requestOptions)
				.then((response) => response.text())
				.then((result) => {
					console.log(result);
					window.location.reload();
				})
				.catch((error) => console.log("error", error));
		}
	};

	const getCoursesListForStudent = async () => {
		try {
			const data = await Api.doFetch("GET", {}, `${ApiUrl.STUDENT_COURSES}?student_id=${student_id}`);

			setLoading(false);

			if (data.status) {
				setStudentCourses(data.payload && data.payload.courses && data.payload.courses.length > 0 ? data.payload.courses : []);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updateCourse = async (student_course_id, status) => {
		try {
			if (status === true) {
				// setSwitchColor("success");
			} else {
				// setSwitchColor("error");
			}
			var formdata = new FormData();
			formdata.append("student_course_id", student_course_id);
			formdata.append("status", !status);

			const data = await Api.doUpload("PUT", formdata, ApiUrl.TOGGLE_COURSE);

			if (data && data.status) {
				Api.trackEvent(status ? segmentEvents.STUDENT.COURSE_DEACTIVATED : segmentEvents.STUDENT.COURSE_ACTIVATED);
				getCoursesListForStudent();
			} else if (data && ((data.payload && data.payload.Error) || data.message)) {
				if (data.payload && data.payload.Error) {
					Swal.fire("Error", data.payload.Error, "error");
				} else if (data.message) {
					Swal.fire("Error", data.message, "error");
				}
			}
		} catch (err) {
			console.log(err);
			if (err?.response?.data?.payload?.Error) {
				Swal.fire(`${err.response.data.payload.Error}`);
			} else {
				Swal.fire(`${err.message}`);
			}
		}
	};

	const viewAllPdf = (item) => {
		set_admission_form_list(item.admission_forms_data);
	};

	useEffect(() => {
		getStudentDetails();
		getCoursesListForStudent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (notFound) {
		return <NotFound />;
	}

	return (
		<div className="pending_reversal">
			{showCourseForm ? (
				<AddCourse
					open={showCourseForm}
					close={() => {
						setShowCourseForm(false);
					}}
					student_id={student_id}
					refresh={getCoursesListForStudent}
				/>
			) : null}

			{/* Popup  Start */}
			<div className={`modal fade paymentPopup student_signed_form${isMobile ? " is-mobile" : ""}`} id="myModal" role="dialog">
				<SignedForm admission_form_list={admission_form_list} />
			</div>
			{/* Popup  End */}

			<div className="containerMain courseDetail">
				{loading ? (
					<div className="loader_container">
						<Loader />
					</div>
				) : (
					<>
						<StudentCard
							style={{
								marginTop: "0rem",
							}}
							studentData={studentDetails}
							getStudentDetails={getStudentDetails}
							goback={() => {
								navigate("/student/search");
							}}
						/>

						<p className="enrolled_courses_header">Enrolled Courses</p>

						<div className={"course_container" + (isMobile ? " is-mobile" : "")}>
							{studentCourses &&
								studentCourses.length > 0 &&
								studentCourses.map((item, index) => {
									let switchClass = "clickActivate";
									if (item.status) {
										switchClass = "clickDeactivate";
									}
									return (
										<div
											key={index}
											className={`student_course_container${!item.status ? " course_inactive" : ""}${isMobile ? " is-mobile" : ""}`}
										>
											{isMobile && (
												<div className="course-status">
													<div className={`course-status-text${item.status ? " active" : ""}`}>
														{item.status ? "Course active" : "Course inactive"}
													</div>
													<div className="course-status-toggle">
														<FormControlLabel
															control={
																<Switch
																	size={isSmall ? "small" : "medium"}
																	checked={item.status}
																	color={item.status ? "success" : "error"}
																	className={switchClass}
																/>
															}
															label={
																<div className="student_course_toggle-label">
																	{item.status ? `Click to deactivate` : `Click to activate`}
																</div>
															}
															onClick={() => updateCourse(item.student_course_id, item.status)}
															className="student_course_toggle_text"
														/>
													</div>
												</div>
											)}
											<div className={`row${isMobile ? "" : " mb-4"}`}>
												<div className="col-md-8 d-flex enrolled-course-details">
													<div className="student_course_detail_centre_flex">
														<span className="student_course_detail_header">Centre:</span>
														<p className="student_course_detail_value">{item.school_name}</p>
													</div>
													<div className="student_course_detail_others_flex">
														<span className="student_course_detail_header">Course:</span>
														<p className="student_course_detail_value">{item.course_name}</p>
														{item.hostel_applicable && <div className="hostel_tag_wrapper">+Hostel</div>}
													</div>
													<div className="student_course_detail_others_flex">
														<span className="student_course_detail_header">Batch:</span>
														<p className="student_course_detail_value">{item.section_name}</p>
													</div>
												</div>
												{!isMobile && (
													<div className="col-md-4">
														<div className="enrolled-course-toggle">
															<FormControlLabel
																control={
																	<Switch
																		checked={item.status}
																		color={item.status ? "success" : "error"}
																		className={switchClass}
																	/>
																}
																label={
																	<div className="student_course_toggle-label">
																		{item.status ? `Click to deactivate` : `Click to activate`}
																	</div>
																}
																onClick={() => updateCourse(item.student_course_id, item.status)}
																className="student_course_toggle_text"
															/>
															<p className={`${item.status ? "student_course_active_text" : "student_course_inactive_text"}`}>
																{item.status ? "Course active" : "Course inactive"}
															</p>
														</div>
													</div>
												)}
											</div>

											<div className="row">
												<div className="col-sm-6">
													<button
														className="btn btn-md upload_signed_form_button"
														onClick={() => {
															navigate(`/student/upload-admission-form/${student_id}`, {
																state: {
																	student_course_id: item.student_course_id,
																	section_id: item.section_id,
																	school_id: item.school_id,
																	school_name: item.school_name,
																	section_name: item.section_name,
																},
															});
														}}
													>
														Upload Signed form
													</button>
													{item?.admission_forms_data?.length > 0 && (
														<div className="seeAllbtn mt-3">
															<p>
																{item.admission_forms_data[0]?.name}{" "}
																{moment(item.admission_forms_data[0]?.created_on).format("DD MMM,YYYY")} ||{" "}
																{moment(item.admission_forms_data[0]?.created_on).format("hh:mm A")}{" "}
																<a
																	rel="noopener noreferrer"
																	href={item?.admission_forms_data[0]?.registration_form_url}
																	target="_blank"
																	onClick={() => {
																		Api.trackEvent(segmentEvents.STUDENT.CLICK_VIEW_SIGNED_FORM);
																	}}
																>
																	View
																</a>
															</p>
															{item?.admission_forms_data.length > 1 && (
																// eslint-disable-next-line jsx-a11y/anchor-is-valid
																<a
																	href="#"
																	data-toggle="modal"
																	data-target="#myModal"
																	onClick={(e) => {
																		Api.trackEvent(segmentEvents.STUDENT.CLICK_SEE_ALL_SIGNED_FORM);
																		e.preventDefault();
																		viewAllPdf(item);
																	}}
																>
																	See all uploads
																</a>
															)}
														</div>
													)}
												</div>
												<div className="col-sm-6">
													<div className="student-detail-fee-summary">
														<button
															className="btn btn-md student_detail_fee_summary_button"
															onClick={() => {
																if (item.is_installment_created) {
																	navigate(`/payment/fee-summary/${item.school_id}/${item.section_id}/${student_id}`);
																} else if (item.status) {
																	navigate(
																		`/payment/create-installment/${item.school_id}/${item.section_id}/${student_id}`
																	);
																	// if (item.has_installments) {
																	// 	navigate(
																	// 		`/payment/edit-installment/${item.school_id}/${item.section_id}/${student_id}`,
																	// 		{
																	// 			state: {
																	// 				new_flow: true,
																	// 			},
																	// 		}
																	// 	);
																	// } else
																	// 	navigate(
																	// 		`/payment/create-installment/${item.school_id}/${item.section_id}/${student_id}`
																	// 	);
																} else {
																	Swal.fire(`Course is not active`);
																}
															}}
														>
															{item.is_installment_created ? "Fee Summary" : "Create Installments"}
														</button>

														{item.installment_missed && (
															<div className="student_installment_missed">
																<img src={WarningImg} alt="warning" />
																<span className="student_installment_missed_text">Missed installment due date</span>
															</div>
														)}
													</div>
												</div>

												<div className="col-sm-6">
													{roleList &&
														roleList.length > 0 &&
														roleList.findIndex((x) => x.role_name === roleConstant.CENTER_HEAD) >= 0 &&
														item.is_installment_created && (
															<div className="student-detail-fee-summary">
																<button
																	className="btn btn-md student_detail_fee_summary_button"
																	onClick={() => {
																		if (item.status) {
																			setSettingItemForChange(item);
																			showModal();
																		} else {
																			Swal.fire(`Course is not active`);
																		}
																	}}
																>
																	{item.is_installment_created ? "Delete Installments" : "Update Total Fee"}
																</button>
																{roleList &&
																	roleList.length > 0 &&
																	roleList.findIndex((x) => x.role_name === roleConstant.CENTER_HEAD) >= 0 &&
																	item.is_installment_created && (
																		<button
																			className="btn btn-md student_detail_fee_summary_button"
																			style={{marginLeft:20}}
																			onClick={() => {
																				if (item.status) {
																					let discount = prompt("Enter additional discount!");
																					var myHeaders = new Headers();
																					myHeaders.append("Content-Type", "application/json");

																					var raw = JSON.stringify({
																						school_id: item?.school_id,
																						section_id: item?.section_id,
																						student_id: student_id,
																						discount: parseFloat(discount),
																					});

																					var requestOptions = {
																						method: "POST",
																						headers: myHeaders,
																						body: raw,
																						redirect: "follow",
																					};

																					fetch(
																						process.env.REACT_APP_BASE_URL + "support/add-additional-discount/",
																						requestOptions
																					)
																						.then((response) => response.text())
																						.then((result) => {
																							console.log(result)
																							Swal.fire(JSON.parse(result).message)})
																						.catch((error) => Swal.fire(`Something went wrong!`));
																				} else {
																					Swal.fire(`Course is not active`);
																				}
																			}}
																		>
																			Add Additional Discount
																		</button>
																	)}
																<Modal
																	title="Credential Required"
																	open={isModalOpen}
																	centered
																	onOk={() => {
																		handleOk();
																	}}
																	onCancel={handleCancel}
																>
																	<Input placeholder="password" value={password} onChange={handlePassword} />
																</Modal>
															</div>
														)}
												</div>
											</div>
										</div>
									);
								})}

							<div className="add_student_container" onClick={() => setShowCourseForm(true)}>
								<div className="add_student_text_container">
									<img src={AddCircle} alt="add" className="add_student_img" />

									<span className="add_student_container_text">Add Course</span>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default StudentDetail;
