import React from "react";
import { Select } from "antd";
import { Asterisk } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";

const { Option } = Select;

const ChapterDetails = ({
    view = false,
    chapterData,
    setChapterData,
    chapterError = {},
    setChapterError,
    courseList,    
    subjectList,
    tagList,
    setTagList,
    dataDump,
    setCombinationError,
    setSerialError
}) => {
    const handleChapterError = payload => {
        setChapterError({
            ...chapterError,
            ...payload,
        });
    };

    const handleChapter = payload => {
        setChapterData({
            ...chapterData,
            ...payload
        });
    };

    return (
        <>
            <div className="course_add_header_container">Chapter details</div>

            <div className="course_add_data_container">
                <div className="row">
                    <div className="col-md-10">
                        {/* <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">Course {!view ? <Asterisk /> : ""}</p>

                            {
                                view ?
                                <div className="chapter_selected_tags_container">
                                    {
                                        dataDump && dataDump.courses && dataDump.courses.map((courses, index2) => {
                                            return (
                                                <p className="course_view_text_data course_name_data" key={index2}>
                                                    {courses.class_name}
                                                </p>
                                            );
                                        })
                                    }
                                </div>
                                :
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Select course name"
                                    onChange={courseData => {
                                        handleChapterError({ courses: "" });
                                        handleChapter({ courses: courseData });
                                    }}
                                    value={chapterData.courses}
                                    showArrow
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {
                                        courseList.map((options, index) => {
                                            return (
                                                <Option key={index} value={options.id}>
                                                    {options.class_name}
                                                </Option>
                                            );
                                        })
                                    }
                                </Select>
                            }
                            {chapterError.courses && <ErrorText text={chapterError.courses} />}
                        </div> */}

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">Subject {!view ? <Asterisk /> : ""}</p>

                            {
                                view ?
                                <p className="course_view_text_data">
                                    {
                                        dataDump && dataDump.subject_assoc && dataDump.subject_assoc.subject_name ?
                                        dataDump.subject_assoc.subject_name
                                        :
                                        "-"
                                    }
                                </p>
                                : 
                                <>
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select subject name"
                                        onChange={value => {
                                            handleChapterError({ subject_assoc: "" });
                                            handleChapter({ subject_assoc: value || "" });
                                            setCombinationError("")
                                            setSerialError("")
                                        }}
                                        value={chapterData.subject_assoc || undefined}
                                        showArrow
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {subjectList.map((subjects, index) => {
                                            return (
                                                <Option key={index} value={subjects.id}>
                                                    {subjects.subject_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {chapterError.subject_assoc && <ErrorText text={chapterError.subject_assoc} />}
                                </>
                            }
                        </div>

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">Tags {!view ? <Asterisk /> : ""}</p>

                            {
                                view ?
                                <div className="chapter_selected_tags_container">
                                    {
                                        tagList.length > 0 ?
                                        tagList.map((tags, index1) => {
                                            return (
                                                <p className="course_view_text_data selected_tags_name" key={index1}>
                                                    {tags.tag_name}
                                                </p>
                                            );
                                        })
                                        :
                                        <p className="course_view_text_data">No tags added</p>
                                    }
                                </div>
                                :
                                <Select
                                    mode="tags"
                                    autoClearSearchValue={false}
                                    style={{ width: "100%" }}
                                    placeholder="Enter tag"
                                    onChange={value => {
                                        handleChapterError({ tags: "" });                                        
                                        handleChapter({
                                            tags: value
                                        })
                                        setTagList(value.map(x => {
                                            let index = tagList.findIndex(z => z.tag_name === x);

                                            if(index >= 0){
                                                return {
                                                    ...tagList[index],
                                                    tag_name: x
                                                }
                                            }

                                            return { tag_name: x }
                                        }))
                                    }}
                                    value={chapterData.tags}
                                    showArrow
                                    allowClear
                                    showSearch
                                >
                                    {
                                        tagList.map((options, index) => {
                                            return (
                                                <Option key={index} value={options.tag_name}>
                                                    {options.tag_name}
                                                </Option>
                                            );
                                        })
                                    }
                                </Select>
                            }
                            {chapterError.tags && <ErrorText text={chapterError.tags} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChapterDetails;
