import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles/Login.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import OtpInput from "react-otp-input";
import Logo from "../../assets/img/mycrm-logo.svg";

import Api from "../common/api";

function EnterOtp() {
  let navigate = useNavigate();

  const location = useLocation();

  const [otp, setOtp] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const onSubmithandler = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("password1", password1);
    formdata.append("password2", password2);
    for (var value of formdata.values()) {
      console.log(value);
    }
    axios
      .post(
        process.env.REACT_APP_BASE_URL +
          `forgot-password/${otp}/${location.state.mobileNumber}/`,
        formdata
      )
      .then((data) => {
        // console.log(data)
        if (data.data.Success) {
          toast.success("Password Changed Successfully", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setTimeout(() => {
            navigate("/login");
          }, 1500);
        } else {
          toast.error(data.data.Error, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Api.isAuthenticated()) {
      navigate("/dashboard");
    }
    if (!location || !location.state || !location.state.mobileNumber) {
      navigate("/");
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row main-cont">
        <div className="col-md-8 img-side"></div>
        <ToastContainer />

        <div className="col-md-4 form-side">
          <img src={Logo} className="logo-img" alt="" />

          <div className="forgot-password-second-div">
            <h3 className="login-text blue-primary mt-4 mb-1">
              Forgot Password ?
            </h3>
            <p className="blue-primary">
              Enter OTP received on number {location && location.state && location.state.mobileNumber} to
              reset password
            </p>

            <form className="mt-5">
              <div className="my-5">
                <label className="form-label">Enter OTP</label>
                {/* <input type='tel' className="form-control login-form-input-f" required /> */}
                <OtpInput
                  value={otp}
                  onChange={(e) => setOtp(e)}
                  numInputs={4}
                  separator={<span></span>}
                  isInputNum
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 1rem",
                    fontSize: "2rem",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                  }}
                />
              </div>
            </form>
          </div>

          <div className="reset-password-div">
            <h3 className="login-text blue-primary mt-4 mb-1">
              Reset Password
            </h3>
            <p className="blue-primary">Enter your new password and save</p>

            <form className="formm mt-5">
              <div className="mb-3">
                <label className="form-label">Set New Password</label>
                <input
                  className="form-control login-form-input-f"
                  placeholder="Set New Password"
                  type="password"
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Re-type New Password</label>
                <input
                  className="form-control login-form-input-f"
                  placeholder="Re-type New Password"
                  type="password"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  required
                />
              </div>

              {/* <div className=" form-check mb-5">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck2"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck2">
                    Remember Me
                  </label>
                </div> */}

              <button className="btn login-btn p-3" onClick={onSubmithandler}>
                Save & Login
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="row main-cont">
          <div className="col-md-8 img-side sticky-top" id="img">
          </div>
          <div className="col-md-4 form-side my-5 pt-5 px-5" id="form">
            <div className="col">
              <img src="" alt="mycrm logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="login-div">
        <h4 className="login-text blue-primary mt-4 mb-1">Log In</h4>
        <p className="blue-primary">
          Enter your Phone number and Password to login.
        </p>

        <form onSubmit={onSubmithandler} className="mt-5">
          <div className="mb-3">
            <label className="form-label">Mobile Number</label>
            <input
              className="form-control login-form-input-f"
              placeholder="Mobile Number"
              required
              // value={username}
              onChange={onusernamechange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control login-form-input-f"
              placeholder="Password"
              required
              // value={password}
              onChange={onpasswordchange}
            />
          </div>

          <div className="row mb-5">
            <div className="col-6 text-start">
              <div className=" form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Remember Me
                </label>
              </div>
            </div>
            <div className="col-6 text-end">
              <a href="/">Forgot Password?</a>
            </div>
          </div>

          <button type="submit" className="btn login-btn p-3">
            Login
          </button>
        </form>

        <p className="student-line pt-2">
          If you are a student,{" "}
          <a href="https://www.study.zinedu.com" className="student-link">
            click here
          </a>
        </p>
      </div> */}
    </>
  );
}

export default EnterOtp;
