import {
    SCRL_HANDLE_INIT_MULTIPLE,
    SCRL_HANDLE_DETAILS_MULTIPLE,
    SCRL_HANDLE_LOADING_MULTIPLE,
    SCRL_HANDLE_SCROLL_LOADING_MULTIPLE,
    SCRL_HANDLE_SCROLL_POSITION_MULTIPLE,
    SCRL_HANDLE_SCROLL_APPEND_MULTIPLE,
    SCRL_UPDATE_LIST_MULTIPLE,
    SCRL_RESET_LIST_MULTIPLE,
    HANDLE_SCRL_LIST_MULTIPLE,
    HANDLE_SCRL_DATA_MULTIPLE,
    SCRL_HANDLE_MULTIPLE
} from "../actions/types";

const scrollingListInititalState = {
    data: {}
};

function scrollingListReducer(state = scrollingListInititalState, action) {
    switch (action.type) {
        case SCRL_HANDLE_INIT_MULTIPLE:
            if (state[action.payload.key])
                return {
                    ...state,
                    [action.payload.key]: {
                        ...state[action.payload.key]
                    }
                };
            return {
                ...state,
                [action.payload.key]: {
                    data: [],
                    loading: false,
                    scrollLoading: false,
                    continueScroll: false,
                    skip: 0,
                    limit: 10,
                    total: 0,
                    scrollPositionY: 0,
                    searchText: "",                    
                    totalAmount: 0
                }
            };
        case SCRL_HANDLE_DETAILS_MULTIPLE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    ...action.payload
                }
            };
        case SCRL_HANDLE_LOADING_MULTIPLE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    loading: action.payload.loading
                }
            };
        case SCRL_HANDLE_SCROLL_LOADING_MULTIPLE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    scrollLoading: action.payload.scrollLoading
                }
            };
        case SCRL_HANDLE_SCROLL_POSITION_MULTIPLE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    scrollPositionY: action.payload.scrollPositionY
                }
            };
        case SCRL_HANDLE_SCROLL_APPEND_MULTIPLE:
            const newList = [...state[action.payload.key].data, ...(action.payload.listItems || [])];

            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    data: newList,
                    skip: action.payload.skip,
                    scrollLoading: action.payload.scrollLoading,
                }
            };
        case SCRL_UPDATE_LIST_MULTIPLE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    data: action.payload
                }
            };
        case SCRL_RESET_LIST_MULTIPLE:
            return {
                ...scrollingListInititalState
            };
        case HANDLE_SCRL_LIST_MULTIPLE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    data: [...state[action.payload.key].data, ...(action.payload.listItems || [])]
                }
            };
        case HANDLE_SCRL_DATA_MULTIPLE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    data: action.payload,
                    total: state.total - (state[state.listaction.payload.key].length - action.payload.length)
                }
            };
        case SCRL_HANDLE_MULTIPLE:
            return {
                [action.payload.key]: {
                    ...state[action.payload.key],
                    skip: 0,
                    scrollLoading: false,
                    loading: false,
                    data: action.payload.list,
                    continueScroll: true,
                    total: action.payload.total,
                },
                ...scrollingListInititalState
            };
        default:
            return state;
    }
}

export default scrollingListReducer;
