import React from "react";
import { Avatar } from "@mui/material";
import Swal from "sweetalert2";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import OnlineGreen from "../assets/attendance Module/online-green.svg";
import HandEmoji from "../assets/attendance Module/hand-emoji.svg";
import PresentHand from "../assets/attendance Module/noun-raise-hand-4160048 1.svg";
import FingerPrintGreen from "../assets/attendance Module/Fingerprint-Green.svg";
import { useNavigate } from "react-router-dom";

function MarkAttendanceModal({
    isOpen,
    close,
    attendanceData,
    alreadyPresent,
    markedPresent,
    presentOnline,
    presentOffline,
    postAttendance,
    emptyMarkedPresent,
    alreadyMarkedByCounscellor
}) {
    let navigate = useNavigate();
    return (
        <Dialog
            open={isOpen}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "800px", // Set your width here
                    },
                },
            }}
        >
            <DialogTitle>
                <div className="mod-head-conf">Please confirm</div>
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                        flexWrap: 'wrap',
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#F9F9F9",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            paddingLeft: 30,
                            paddingRight: 30,
                            borderRadius: 20,
                            margin: '5px 0px',
                        }}
                    >
                        <Avatar
                            sx={{
                                bgcolor: "#EFEFEF",
                                height: "30px",
                                width: "30px",
                                alignItems: "center",
                                marginRight: 3,
                                color: "#000000",
                                border: "0.5px solid #A3A3A3",
                            }}
                        >
                            B
                        </Avatar>
                        <div style={{ alignItems: "center" }}>
                            <p style={{ marginBottom: "3px" }}>
                                Batch strength
                            </p>
                            <p
                                style={{
                                    fontWeight: 600,
                                    marginBottom: "3px",
                                }}
                            >
                                {attendanceData.length}
                            </p>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#F5FFF6",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            paddingLeft: 30,
                            paddingRight: 30,
                            borderRadius: 20,
                            margin: '5px 0px',
                        }}
                    >
                        <img alt={"Hand Emoji"}
                            src={HandEmoji}
                            style={{
                                height: "33px",
                                width: "40px",
                                alignItems: "center",
                                marginRight: "12px",
                            }}
                        />

                        <div style={{ alignItems: "center" }}>
                            <p style={{ marginBottom: "3px" }}>
                                Student present
                            </p>
                            <p
                                style={{
                                    fontWeight: 600,
                                    marginBottom: "3px",
                                }}
                            >
                                {alreadyPresent + markedPresent.length}
                            </p>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#FFFAFA",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            paddingLeft: 30,
                            paddingRight: 30,
                            borderRadius: 20,
                            margin: '5px 0px',
                        }}
                    >
                        <Avatar
                            sx={{
                                bgcolor: "#FFDFDF",
                                height: "30px",
                                width: "30px",
                                alignItems: "center",
                                marginRight: 3,
                                color: "#000000",
                                border: "0.5px solid #832424",
                            }}
                        >
                            A
                        </Avatar>
                        <div style={{ alignItems: "center" }}>
                            <p style={{ marginBottom: "3px" }}>
                                Student absent
                            </p>
                            <p
                                style={{
                                    fontWeight: 600,
                                    marginBottom: "3px",
                                }}
                            >
                                {attendanceData.length -
                                    markedPresent.length -
                                    alreadyPresent}
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        backgroundColor: "#F5FFF6",
                        padding: 10,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            marginBottom: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Avatar
                            sx={{
                                bgcolor: "#E8FFE0",
                                alignItems: "center",
                                marginRight: 3,
                                border: "0.5px solid #24832E",
                            }}
                        >
                            <img alt={"Present Hand"} src={PresentHand} />
                        </Avatar>
                        <div>
                            <p style={{ marginBottom: "3px" }}>
                                Marked present by counsellor
                            </p>
                            <p
                                style={{
                                    fontWeight: 600,
                                    marginBottom: "3px",
                                }}
                            >
                                {alreadyMarkedByCounscellor + markedPresent.length}
                            </p>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            marginBottom: "10px",
                        }}
                    >
                        <Avatar
                            sx={{
                                bgcolor: "#E8FFE0",
                                alignItems: "center",
                                marginRight: 3,
                                border: "0.5px solid #24832E",
                            }}
                        >
                            <img alt={"OnlineGreen"} src={OnlineGreen} />
                        </Avatar>
                        <div>
                            <p style={{ marginBottom: "3px" }}>
                                Present online
                            </p>
                            <p
                                style={{
                                    fontWeight: 600,
                                    marginBottom: "3px",
                                }}
                            >
                                {presentOnline}
                            </p>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            marginBottom: "10px",
                        }}
                    >
                        <Avatar
                            sx={{
                                bgcolor: "#E8FFE0",
                                alignItems: "center",
                                marginRight: 3,
                                border: "0.5px solid #24832E",
                            }}
                        >
                            <img alt={"Finger Print Green"} src={FingerPrintGreen} />
                        </Avatar>
                        <div>
                            <p style={{ marginBottom: "3px" }}>
                                Present via biometric
                            </p>
                            <p
                                style={{
                                    fontWeight: 600,
                                    marginBottom: "3px",
                                }}
                            >
                                {presentOffline}
                            </p>
                        </div>
                    </div>
                </div>

                <p
                    style={{
                        marginTop: "15px",
                        marginBottom: "15px",
                    }}
                >
                    Do you want to proceed?
                </p>

                <div style={{ display: "flex" }}>
                    <button
                        style={{
                            backgroundColor: "#e0eeff",
                            color: "#012c63",
                            border: "1px solid #bad3f2",
                            padding: "10px 40px",
                            fontSize: "19px",
                            marginRight: "10px",
                            borderRadius: "10px",
                        }}
                        onClick={() => {
                            close();
                        }}
                        className="btn cancel-btn"
                    >
                        No
                    </button>
                    <button
                        className="btn submit-att-btn-2"
                        onClick={() => {
                            if (markedPresent.length === 0) {
                                close();
                                Swal.fire(
                                    `Please mark atleast one student present`
                                );
                                return;
                            }
                            postAttendance();
                            emptyMarkedPresent();
                            navigate("/student/attendance",);
                        }}
                    >
                        Yes
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default MarkAttendanceModal;
