import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../utils/helperFunctions";

import {
    SCRL_HANDLE_DETAILS,
    SCRL_HANDLE_LOADING,
    SCRL_HANDLE_SCROLL_LOADING,
    SCRL_HANDLE_SCROLL_APPEND,
    SCRL_HANDLE_SEARCH_INIT,
    SCRL_HANDLE_SEARCH_CHANGE,
    SCRL_HANDLE_INIT,
    SCRL_HANDLE_SCROLL_POSITION,
} from "../../actions/types";

export function useScrollingList({ searchWait = 500, getData, listKey: providedListKey }) {
    const scrollContainer = useRef(null);
    const initialLoaded = useRef(false);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.scrollingList);
    // const [state, dispatch] = useReducer(scrollingListReducer, scrollingListInititalState);

    const { loading, scrollLoading, skip, limit, total, searchText, continueScroll, listKey, scrollPositionY } = state;
    
    const list = useMemo(() => {
        if (listKey && state[listKey]) {
            return state[listKey];
        }

        return [];
    }, [state, listKey]);

    const handleSearch = useMemo(() => {
        return debounce(async (searchText) => {
            if (!listKey) return;

            if (scrollContainer.current) {
                // Scrolls container to top
                scrollContainer.current.scrollTo(0, 0);
            }

            dispatch({
                type: SCRL_HANDLE_SEARCH_CHANGE,
                payload: searchText,
            });

            // Search API is triggered
            const data = await getData({
                skip: 0,
                limit,
                search: searchText,
            });

            dispatch({
                type: SCRL_HANDLE_SEARCH_INIT,
                payload: {
                    list: (data && data.items) || [],
                    skip: 0,
                    total: (data && data.total) || 0,
                    scrollLoading: false,
                },
            });
        }, searchWait);
    }, [dispatch, listKey, searchWait, limit, getData]);

    const handleScroll = useCallback(
        async (event) => {
            const target = event.target;

            const threshold = 150;
            const overThreshold = target.scrollHeight - target.scrollTop <= target.clientHeight + threshold;
            if (overThreshold && !scrollLoading && list.length < total && continueScroll) {
                // Increment skip, limit for next iteration

                dispatch({
                    type: SCRL_HANDLE_SCROLL_LOADING,
                    payload: true,
                });

                const newSkip = skip + limit;

                const data = await getData({
                    skip: newSkip,
                    limit,
                    search: searchText,
                });

                if (data && data.items) {
                    dispatch({
                        type: SCRL_HANDLE_SCROLL_APPEND,
                        payload: {
                            listItems: data.items || [],
                            skip: newSkip,
                            scrollLoading: false,
                            total: data.total || 0,
                        },
                    });
                } else {
                    dispatch({
                        type: SCRL_HANDLE_SCROLL_LOADING,
                        payload: false,
                    });
                }
            }
        },
        [dispatch, scrollLoading, skip, limit, total, list, searchText, continueScroll, getData]
    );

    const saveScrollPosition = useCallback((scrollPosY) => {
        dispatch({
            type: SCRL_HANDLE_SCROLL_POSITION,
            payload: scrollPosY || 0,
        })
    }, [dispatch]);

    useEffect(() => {
        initialLoaded.current = false;
    }, []);

    useEffect(() => {
        if (!providedListKey) {
            throw new Error("Please provide a list key for scrolling-list hook!");
        }

        dispatch({
            type: SCRL_HANDLE_INIT,
            payload: {
                listKey: providedListKey,
            },
        });
    }, [dispatch, providedListKey]);

    useEffect(() => {
        if (initialLoaded.current && total === 0) return;

        if (!listKey || list.length > 0 || searchText.trim().length > 0) return;
        
        // Will trigger the initial call
        const getInitialListData = async () => {
            dispatch({
                type: SCRL_HANDLE_LOADING,
                payload: true,
            });

            const data = await getData({ skip: 0, limit: 10 });

            initialLoaded.current = true;

            if (data) {
                dispatch({
                    type: SCRL_HANDLE_DETAILS,
                    payload: {
                        [listKey]: data.items || [],
                        skip: 0,
                        limit: 10,
                        total: data.total || 0,
                        loading: false,
                        continueScroll: true,
                    },
                });
            }
        };

        getInitialListData();
    }, [dispatch, listKey, list, getData, searchText, total]);

    return [
        scrollContainer,
        {
            handleSearch,
            searchText,
            handleScroll,
            list,
            loading,
            scrollLoading,
            scrollPositionY,
            saveScrollPosition,
        },
    ];
}
