import React, { useRef } from "react";
import { Asterisk } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { debounce } from "../../../utils/helperFunctions";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";

const SubjectBasicDetails = ({
    view = false,
    subjectData,
    setSubjectData,
    subjectError = {},
    setSubjectError,
    subjectID
}) => {

    const {
        subject_name,
        is_main_subject
    } = subjectData;

    const handleSubjectData = payload => {
        setSubjectData({
            ...subjectData,
            ...payload
        })
    }

    const handleSubjectError = payload => {
        setSubjectError({
            ...subjectError,
            ...payload
        })
    }

    const checkDuplicate = async (value) => {
        const data = await Api.doFetch("POST", {
            subject_name: value.trim()
        }, `${ApiUrl.SUBJECT}/duplicate-check`);

        if (data && data.status) {
            let temp = data.data;

            if (subjectID) {
                temp = temp.filter(x => +x.id !== +subjectID);
            }

            if (temp.length > 0) {
                handleSubjectError({ subject_name: "Subject name already exists" });
            } else {
                handleSubjectError({ subject_name: "" });
            }
        }
    }

    const delayedQuery = useRef(debounce(checkDuplicate, 500)).current;

    const handleSearchText = (event) => {
        delayedQuery(event.target.value);
    }

    return (
        <>
            <div className="course_add_header_container">
                Basic details
            </div>

            <div className="course_add_data_container">

                <div className="row" >

                    <div className="col-md-11">

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">
                                Subject name {!view ? <Asterisk /> : ""}
                            </p>

                            {
                                view ?
                                    <p className="course_view_text_data">
                                        {subject_name}
                                    </p>
                                    :
                                    <input
                                        className={`change_pw_input ${subjectError.subject_name ? "change_pw_input_error" : ""}`}
                                        placeholder="Enter subject name"
                                        value={subject_name}
                                        onChange={e => {
                                            handleSearchText(e);
                                            handleSubjectData({ subject_name: e.target.value });
                                            handleSubjectError({ subject_name: "" });
                                        }}
                                    />
                            }
                            {
                                subjectError.subject_name &&
                                <ErrorText
                                    text={subjectError.subject_name}
                                />
                            }
                        </div>

                        <div className="col-md-4 course_data_wrapper">
                            {
                                view ?
                                    <>
                                        <p className="student_filter_drop_header">
                                            Main subject
                                        </p>
                                        <p className="course_view_text_data">
                                            {is_main_subject ? "Yes" : "No"}
                                        </p>
                                    </>
                                    :

                                    <div className="add_course_resource_container main_subject_container">
                                        <span className="add_course_resource_text">
                                            Is it a main subject?
                                        </span>

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={is_main_subject}
                                                    color={is_main_subject ? "success" : "error"}
                                                    style={{color : is_main_subject ? "" : "#BC0000"}}
                                                />
                                            }
                                            onClick={() => {
                                                handleSubjectData({ is_main_subject: !is_main_subject })
                                            }}
                                            className="student_course_toggle_text"
                                            style={{
                                                marginTop: "0.5rem"
                                            }}
                                        />

                                        <span className="add_course_resource_text">
                                            {is_main_subject ? "Yes" : "No"}
                                        </span>
                                    </div>
                            }

                        </div>

                    </div>

                </div>

            </div>
        </>
    );
};

export default SubjectBasicDetails;