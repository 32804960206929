import React from 'react'
import ReviewDetails from "./ReviewDetails";
import DPPSections from "./DPPSections";
import MathJax from "mathjax3-react";
import DifficultyLevel from "./DifficultyLevel";
import Tag from "./Tag";

const ViewQuestion = ({ question, sectionList, currentSectionId, setCurrentSectionId, sectionRefs,qB=false,total=null }) => {
    return (
        <MathJax.Provider options={{
            tex2jax: {
                inlineMath: [["$", "$"], ["\\(", "\\)"]]
            },
            "HTML-CSS": {
                linebreaks: { automatic: true, width: "container" }
            }
        }}>
            <div className="mpp_review_container2">
                <div className="addMppContainer" style={{ paddingLeft: 20, paddingRight: 20, flex: 2, width: '66%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {qB && <div className="mpp_qp_header">Review all {total ? total : ''} questions</div>}
                        {/* <strong className="mpp_review_header">{question?.chapter_name}</strong> */}
                        {/* <span className="mpp_review_tag mt-3">DPP</span> */}

                        {/* <DPPSections sections={sectionList} currentSectionId={currentSectionId} changeSection={setCurrentSectionId} ref={sectionRefs} /> */}
                        <strong className="mt-3">{question.question_number}</strong>

                        <div className="mt-2" style={{ overflowX: 'auto' }}>
                            <MathJax.Html html={question.question} />
                        </div>

                        {/* <span className="mpp_review_tag mt-3">DPP</span> */}
                        {question?.question_type_id !== '3' && <>
                            <div className="mt-2 boxed" style={{ overflowX: 'auto' }}>
                                A.{" "} <MathJax.Html html={question.option1} />
                            </div>

                            <div className="mt-2 boxed" style={{ overflowX: 'auto' }}>
                                B.{" "} <MathJax.Html html={question.option2} />
                            </div>

                            <div className="mt-2 boxed" style={{ overflowX: 'auto' }}>
                                C.{" "} <MathJax.Html html={question.option3} />
                            </div>

                            <div className="mt-2 boxed" style={{ overflowX: 'auto' }}>
                                D.{" "} <MathJax.Html html={question.option4} />
                            </div>
                        </>}

                        {question?.solution_text &&
                            <>
                                <strong className="mt-3">Solution</strong>
                                <div className="mt-2 boxed" style={{ overflowX: 'auto' }}>
                                    <MathJax.Html html={question?.solution_text} />
                                </div>
                            </>}
                    </div>
                </div>

                <div className="addMppContainer" style={{ flex: 1, paddingLeft: 20, width: '34%' }}>
                    <span className="mpp_qp_header">Question Properties</span>

                    <ReviewDetails title="Question Number" content={question.question_number} />
                    <ReviewDetails title="Subject Name" content={question.subject_name} smallText={`ID-${question.subject_id}`} />
                    <ReviewDetails title="Chapter Name" content={question.chapter_name} smallText={`ID-${question.chapter_id}`} />

                    <div className="mpp_qp_container">
                        <span className="mpp_qp_title">Tags ID</span>
                        {question?.tag_ids?.length > 0 ? <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10 }}>
                            {question?.tag_ids?.map((item) => {
                                return <Tag text={item} />
                            })}
                        </div>
                            :
                        <span className="mpp_qp_content">NA</span>}
                    </div>

                    <div className="mpp_qp_container">
                        <span className="mpp_qp_title">Difficulty Level</span>
                        <DifficultyLevel selectedLevel={question.difficulty_level_id} />
                    </div>

                    <ReviewDetails title="Target exam" content={question?.target_exams?.length > 0 ? question?.target_exams?.join(', ') : 'NA'} />

                    <div className="mpp_qp_container">
                        <span className="mpp_qp_title">Feature type</span>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10 }}>
                            {question?.feature_type ? <Tag text={question.feature_type} /> : <span className="mpp_qp_content">NA</span>}
                        </div>
                    </div>

                    <ReviewDetails title="Ideal response time in seconds" content={question.response_time || 'NA'} />

                </div>
            </div>
        </MathJax.Provider>

    )
}

export default ViewQuestion