import Swal from "sweetalert2";
import Api from "../components/common/api";
import { message } from 'antd';

export const errorHandler = (response) => {
    Swal.fire("Error!", (response && response.message) ? response.message : "Internal Server Error", "error");
}

export const handleFileResponse = async (url, name) => {
    message.config({
        top: 100,
        duration: 2,
        maxCount: 1,
    })
    message.loading('Downloading file...', 0);
    const response = await Api.doDownload("GET", {}, url)
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(res => {
            // The response was a JSON object
            // Process your data as a JavaScript object
            if (res?.status === false) {
                errorHandler(res)
            }
        });
    } else {
        return response.blob().then(blob => {
            // The response wasn't a JSON object
            try {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${ name }`;
                alink.click();
                message.destroy()
            } catch (e) {
                console.log('blob error', e)
            }
        });
    }
}
export const handleFileResponsePost = async (url, name, payload = {}) => {

    message.config({
        top: 100,
        duration: 2,
        maxCount: 1,

    })
    message.loading('Downloading file...', 0);
    const response = await Api.doDownload("POST", payload, url)
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(res => {
            // The response was a JSON object
            // Process your data as a JavaScript object
            if (res?.status === false) {
                errorHandler(res)
            }
        });
    } else {
        return response.blob().then(blob => {
            // The response wasn't a JSON object
            try {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${ name }`;
                alink.click();
                message.destroy()
            } catch (e) {
                console.log('blob error', e)
            }
        });
    }
}

export const getSubstringedText = (text = '', value = 35) => {
    if (text) {
        if (text.length > value) {
            return text.substring(0, value) + "..."
        } else {
            return text
        }
    }
    return ''
}