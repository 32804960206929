import React from "react";
import TopNavBar from "./../navbar/TopNavBar";
import { Outlet } from "react-router-dom";

const Layout = () => {

    return (
        <>
            <TopNavBar />

            <div style={{ width: "100%", padding: "0" }}>

                <Outlet />

            </div>
        </>
    );
}

export default Layout;
