import React from "react";
import LeftArrow from "../../../../assets/img/left_arrow.svg"
import { useNavigate } from "react-router-dom";

const CashHeader = () => {
    const navigate = useNavigate();
    return (
        <div className="course_list_header">
                <div className="d-flex align-items-center py-2">
                    <img
                        src={LeftArrow}
                        alt="goback"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate(-1);
                        }}
                    />

                    <span className="reversal_detail_header_text ml-2">Pending cash deposit</span>
                </div>

                
            </div>
    );
};

export default CashHeader;
