import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SearchStudent from "../../../assets/img/search.svg";
import LeftArrow from "../../../assets/img/left_arrow.svg";
import AddCircle from "../../../assets/img/add_circle.svg";
import StudentCard from "./studentCard";
import Drawer from "@mui/material/Drawer";
import Filter from "./filter";
import { useSelector, useDispatch } from "react-redux";
import { HANDLE_STUDENT_SEARCH, RESET_STUDENT_SEARCH } from "../../../actions/types";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Loader from "../../common/Loader";
import MoreFilter from "./moreFilter";
import Empty from "../../../assets/img/empty_student.svg";
import { useMediaQuery } from "@mui/material";
import { roleConstant } from "../../../utils/helperFunctions";
import { segmentEvents } from "../../../utils/constants";

const openInNewTab = (href) => {
	Object.assign(document.createElement("a"), {
		target: "_blank",
		rel: "noopener noreferrer",
		href: href,
	}).click();
};

const StudentSearch = ({ isLeadSearch }) => {
	const { roleList } = useSelector((state) => state.auth);

	const [isStudentManager, setIsStudentManager] = useState(false);

	useEffect(() => {
		if (roleList && roleList.length > 0 && roleList.findIndex((x) => x.role_name === roleConstant.STUDENT_MANAGER) >= 0) {
			if (roleList.findIndex((x) => x.role_name === roleConstant.COUNSELOR) === -1) {
				setIsStudentManager(true);
			}
		}
	}, [roleList]);

	const hasSearchedRef = useRef(false);

	const dispatch = useDispatch();

	const {
		searchText,
		searchFilterApplied,
		advancedFilterApplied,
		studentList,
		studentData,

		selectedCentre,
		selectedCourse,
		selectedBatch,
		selectedState,
		selectedCity,
		skip,
		limit,
		continueScroll,
	} = useSelector((state) => state.studentSearch);
	const isMobile = useMediaQuery("(max-width:600px)");

	const navigate = useNavigate();

	const [searchLoading, setSearchLoading] = useState(false);
	const [advancedLoading, setAdvancedLoading] = useState(false);
	const [scrollLoading, setScrollLoading] = useState(false);
	const [openFilter, setOpenFilter] = useState(false);
	const [tempSearchText, setTempSearchText] = useState(searchText);

	const onChangeStudentSearch = (e) => {
		setTempSearchText(e.target.value);
		// dispatch({
		//     type: HANDLE_STUDENT_SEARCH,
		//     payload: {
		//         searchText: e.target.value
		//     }
		// })
	};

	const advancedFilterHasValues = () => {
		return selectedCentre || selectedCourse || selectedBatch || selectedState || selectedCity ? true : false;
	};

	const [cityList, setCityList] = useState([]);

	const getCityList = async () => {
		const data = await Api.doFetch("GET", {}, ApiUrl.CITY_LIST);

		if (data && data.status && data.payload && data.payload.city_list && data.payload.city_list.length > 0) {
			setCityList(data.payload.city_list);
		} else {
			setCityList([]);
		}
	};

	const getStudent = async (canSearch = true) => {
		if (+skip === 0) {
			let url = `${ApiUrl.GET_STUDENT_LIST_V1}?search_filter=${searchText}&school_id=${selectedCentre}&class=${selectedCourse}&section=${selectedBatch}&city=${selectedCity}&state=${selectedState}&skip=${skip}&limit=${limit}`;
			if (searchText && canSearch) {
				// url = `${ApiUrl.GET_STUDENT_LIST}?search_filter=${searchText}`;
				if (!advancedFilterHasValues()) {
					setSearchLoading(true);
				} else {
					setAdvancedLoading(true);
				}
			} else {
				url = `${ApiUrl.GET_STUDENT_LIST_V1}?search_filter=&school_id=${selectedCentre}&class=${selectedCourse}&section=${selectedBatch}&city=${selectedCity}&state=${selectedState}&skip=${skip}&limit=${limit}`;
				setAdvancedLoading(true);
			}

			const data = await Api.doFetch("GET", {}, url);

			setSearchLoading(false);
			setAdvancedLoading(false);

			let element = document.getElementById("student_search_scroll_container");
			element.scrollTop = 0;

			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					searchFilterApplied: searchText || !canSearch ? true : false,
					advancedFilterApplied: advancedFilterHasValues(),
					studentData: data && data.length > 0 ? data : [],
					studentList: data && data.length > 0 ? data : [],
					continueScroll: data && +data.length === +limit,
				},
			});
		} else {
			let url = `${ApiUrl.GET_STUDENT_LIST_V1}?search_filter=${
				canSearch ? searchText : ""
			}&school_id=${selectedCentre}&class=${selectedCourse}&section=${selectedBatch}&city=${selectedCity}&state=${selectedState}&skip=${skip}&limit=${limit}`;

			setScrollLoading(true);
			const data = await Api.doFetch("GET", {}, url);
			setScrollLoading(false);
			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					searchFilterApplied: searchText ? true : false,
					advancedFilterApplied: advancedFilterHasValues(),
					studentData: data && data.length > 0 ? [...studentData, ...data] : studentData,
					studentList: data && data.length > 0 ? [...studentList, ...data] : studentList,
					continueScroll: data && +data.length === +limit,
				},
			});
		}
	};

	const handleScroll = async (event) => {
		const e = event.target;
		let threshold = e.scrollHeight - e.scrollTop <= e.clientHeight + 150;

		if (threshold && continueScroll) {
			dispatch({
				type: HANDLE_STUDENT_SEARCH,
				payload: {
					skip: skip + limit,
					continueScroll: false,
				},
			});
		}
	};

	const resetSearchRef = () => {
		hasSearchedRef.current = false;
	};

	useEffect(() => {
		if (advancedFilterHasValues() || searchText.trim() || (searchText === "" && hasSearchedRef.current)) {
			getStudent();
		}
		hasSearchedRef.current = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCentre, selectedCourse, selectedBatch, selectedState, selectedCity, searchText]);

	useEffect(() => {
		if (skip > 0) {
			getStudent();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skip, limit]);

	useEffect(() => {
		getCityList();
		return () => dispatch({ type: RESET_STUDENT_SEARCH });
	}, []);

	return (
		<div className="containerMain">
			<Drawer
				anchor={"right"}
				open={openFilter}
				onClose={() => {
					setOpenFilter(false);
				}}
				className="student_search_filter_drawer"
			>
				<Filter close={() => setOpenFilter(false)} tempSearch={tempSearchText} allCity={cityList} />
			</Drawer>

			<div
				className={`student_search_container mb-5 ${searchFilterApplied || advancedFilterApplied ? "student_search_container_filter" : ""}${
					isMobile ? " is-mobile" : ""
				}`}
			>
				<div className="d-flex student_search_container-input align-items-center justify-content-between">
					<img src={SearchStudent} alt="search" className="student_search_image" />
					<input
						className="student_search_input"
						value={tempSearchText}
						onChange={(e) => {
							onChangeStudentSearch(e);
							if (!e.target.value) {
								if (advancedFilterHasValues()) {
									dispatch({
										type: HANDLE_STUDENT_SEARCH,
										payload: {
											searchText: "",
											skip: 0,
											limit: 10,
											continueScroll: false,
										},
									});
									// getStudent(false);
								} else {
									resetSearchRef();
									dispatch({ type: RESET_STUDENT_SEARCH });
								}
							}
						}}
						onKeyDown={(e) => {
							if (e.keyCode === 13 && tempSearchText.trim() && !searchLoading) {
								// getStudent();
								Api.trackEvent(segmentEvents.STUDENT.CLICKED_SEARCH_STUDENT);
								dispatch({
									type: HANDLE_STUDENT_SEARCH,
									payload: {
										skip: 0,
										continueScroll: false,
										searchText: tempSearchText,
									},
								});
							}
						}}
						placeholder={isLeadSearch ?"Search by student name/ mobile number" : "Search by Student ID / email / name"}
						disabled={searchLoading ? true : false}
					/>

					<button
						className={`btn btn-md student_search_button ${!tempSearchText.trim() ? "student_search_button_disabled" : ""}`}
						onClick={() => {
							if (!searchLoading && tempSearchText.trim()) {
								// getStudent();
								Api.trackEvent(segmentEvents.STUDENT.CLICKED_SEARCH_STUDENT);
								dispatch({
									type: HANDLE_STUDENT_SEARCH,
									payload: {
										skip: 0,
										continueScroll: false,
										searchText: tempSearchText,
									},
								});
							}
						}}
					>
						Search
						{searchLoading && <span className="ml-2 spinner spinner-white mr-3"></span>}
					</button>
				</div>

				{!isLeadSearch ? (
					<div className="student_search_more_filter_container">
						<span
							className="student_search_more_filter"
							onClick={() => {
								setOpenFilter(true);
								Api.trackEvent(segmentEvents.STUDENT.CLICKED_STUDENT_SEARCH_MORE_FILTER);
							}}
						>
							More Filters
						</span>
					</div>
				) : (
					<div
						className="student_search_more_filter_container d-flex justify-content-flex-end"
						onClick={() => {
							dispatch({ type: RESET_STUDENT_SEARCH });
							navigate("/lead/list");
						}}
					>
						<span className="student_search_more_filter">View all leads</span>
					</div>
				)}
			</div>

			{advancedFilterHasValues() && <MoreFilter resetSearchRef={resetSearchRef} />}

			<div
				className={`student_search_list_container ${searchFilterApplied || advancedFilterApplied ? "student_search_list_container_filter" : ""}${
					isMobile ? " is-mobile" : ""
				}`}
			>
				<div className="d-flex align-items-center mb-5">
					{!isStudentManager && (
						<img src={LeftArrow} alt="back" className="student_list_back" onClick={() => navigate(isLeadSearch ? "/lead" : "/student")} />
					)}

					<span className="student_search_list_header">List of matching {isLeadSearch ? "leads" : "students"}</span>
				</div>
			</div>

			{!scrollLoading && !searchFilterApplied && !advancedFilterApplied && advancedFilterHasValues() && (
				<div className="mt-5">
					<Loader />
				</div>
			)}

			<div
				id="student_search_scroll_container"
				className={`${advancedFilterHasValues() ? "student_search_scroll_advanced" : "student_search_scroll"} student_search_list_container${
					isMobile ? " is-mobile" : ""
				} ${searchFilterApplied || advancedFilterApplied ? "student_search_list_container_filter" : ""}`}
				onScroll={handleScroll}
			>
				{(searchFilterApplied || advancedFilterApplied) && advancedLoading && !scrollLoading ? (
					<div className="loader_container">
						<Loader />
					</div>
				) : searchFilterApplied && searchLoading ? null : studentList.length === 0 ? (
					<div className="student_search_empty_container">
						<div className="student_search_empty_img_container">
							<img src={Empty} alt="not found" className="student_search_empty_img" />
						</div>

						<p className="student_search_empty_header">No results found</p>
						<span className="student_search_empty_label">Try adjusting your search history to find</span>
						<span className="student_search_empty_label">the best results</span>
					</div>
				) : (
					studentList.map((studentData, index) => {
						return (
							<StudentCard
								studentData={studentData}
								key={index}
								installmentMissed={
									studentData && (studentData.last_installment_due_on || studentData.hostel_last_installment_due_on) ? true : false
								}
								installmentPlanNotCreated={studentData && studentData.is_free ? false : studentData.installment_created ? false : true}
								redirect={() => {
									if (studentData.is_free) openInNewTab(`/student/add?lead-id=${studentData.id}`);
									else openInNewTab(`/student/detail/${studentData.id}`);
								}}
								onEditLead={() => {
									openInNewTab(`/lead/${studentData.id}`);
								}}
								style={index === 0 ? { marginTop: "0rem" } : {}}
							/>
						);
					})
				)}

				{(searchFilterApplied || advancedFilterApplied) && scrollLoading ? (
					<div className="loader_container">
						<Loader />
					</div>
				) : (searchFilterApplied || advancedFilterApplied) && !(searchLoading || scrollLoading || advancedLoading) ? (
					<div
						className="add_student_container"
						onClick={() => {
							navigate(isLeadSearch ? "/lead/add" : "/student/add");
						}}
					>
						<div className="add_student_text_container">
							<img src={AddCircle} alt="add" className="add_student_img" />

							<span className="add_student_container_text">Add New {isLeadSearch ? "Lead" : "Student"}</span>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default StudentSearch;
