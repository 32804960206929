import React from "react";
import { Asterisk } from "../../../utils/helperFunctions";
import { currencyFormatter } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import { Select } from 'antd';

const { Option } = Select;

const formatCurrency = (amount) => {
    if (amount) return <>&#8377; {currencyFormatter(amount, false)}</>;

    return null;
};

const BatchPricingDetails = ({
    view,
    batchData,
    setBatchData,
    batchError,
    setBatchError,
    centre
}) => {
    return (
        <>
            {!centre && <div className="course_add_header_container">Pricing details</div>}

            <div className={centre ? "academic_details_data_container" : "course_add_data_container"}>
            {centre && <div className="academic_details_header_container">Pricing details</div>}
                <div className="row">
                    <div className={view ? "col-md-10" : "col-md-11"}>
                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">MRP {!view ? <Asterisk /> : ""}</p>

                            {view ? (
                                <p className="course_view_text_data">
                                    {(formatCurrency(centre ? centre.fee_amount : batchData.fee_amount)) || "-"}
                                </p>
                            ) : (
                                <div className="d-flex">
                                    <Select
                                        style={{ width: "90px", backgroundColor: "#FFFFFF" }}
                                        placeholder="Select type"
                                        optionLabelProp="label"
                                        showArrow
                                        allowClear
                                        showSearch
                                        value="INR ₹"
                                        disabled
                                        className="add_course_inr"
                                    >
                                        <Option value={"INR ₹"}>INR ₹</Option>
                                    </Select>

                                    <input
                                        // className={`change_pw_input student_add_mobile_input ${studentMobileError ? "change_pw_input_error" : ""}`}
                                        className={`change_pw_input ml-2`}
                                        placeholder="Enter amount"
                                        value={(centre ? centre.fee_amount : batchData.fee_amount) || ""}
                                        onChange={(e) => {
                                            let temp = {};
                                            if (
                                                batchError.minimum_fee_amount &&
                                                batchError.minimum_fee_amount === "Minimum fee amount can not be more than MRP"
                                            ) {
                                                temp = { minimum_fee_amount: "" };
                                            }
                                            setBatchError({
                                                ...batchError,
                                                fee_amount: "",
                                                ...temp,
                                            });
                                            setBatchData({
                                                ...batchData,
                                                fee_amount: `${e.target.value.replace(/[^0-9]/g, "")}`,
                                            });
                                        }}
                                    />
                                </div>
                            )}

                            {batchError.fee_amount && <ErrorText text={batchError.fee_amount} />}
                        </div>

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">Minimum fee {!view ? <Asterisk /> : ""}</p>

                            {view ? (
                                <p className="course_view_text_data">
                                    {formatCurrency(centre ? centre.minimum_fee_amount : batchData.minimum_fee_amount) || "-"}
                                </p>
                            ) : (
                                <div className="d-flex">
                                    <Select
                                        style={{ width: "90px", backgroundColor: "#FFFFFF" }}
                                        placeholder="Select type"
                                        optionLabelProp="label"
                                        showArrow
                                        allowClear
                                        showSearch
                                        value="INR ₹"
                                        disabled
                                        className="add_course_inr"
                                    >
                                        <Option value={"INR ₹"}>INR ₹</Option>
                                    </Select>

                                    <input
                                        // className={`change_pw_input student_add_mobile_input ${studentMobileError ? "change_pw_input_error" : ""}`}
                                        className={`change_pw_input ml-2`}
                                        placeholder="Enter amount"
                                        value={(centre ? centre.minimum_fee_amount : batchData.minimum_fee_amount) || ""}
                                        onChange={(e) => {
                                            setBatchError({
                                                ...batchError,
                                                minimum_fee_amount: "",
                                            });
                                            setBatchData({
                                                ...batchData,
                                                minimum_fee_amount: `${e.target.value.replace(/[^0-9]/g, "")}`,
                                            });
                                        }}
                                    />
                                </div>
                            )}

                            {batchError.minimum_fee_amount && <ErrorText text={batchError.minimum_fee_amount} />}
                        </div>

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">Description</p>

                            {view ? (
                                <p className="course_view_text_data">{(centre ? centre.desc : batchData.desc) || "-"}</p>
                            ) : (
                                <input
                                    // className={`change_pw_input ${studentEmailError ? "change_pw_input_error" : ""}`}
                                    className={`change_pw_input`}
                                    placeholder="Enter description"
                                    value={(centre ? centre.desc : batchData.desc) || ""}
                                    onChange={(e) => {
                                        setBatchData({
                                            ...batchData,
                                            desc: e.target.value,
                                        });
                                    }}
                                />
                            )}

                            {/* {
                                studentMobileError &&
                                <ErrorText
                                    text={studentMobileError}
                                />
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BatchPricingDetails;