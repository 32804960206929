import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { errorHandler } from "../../../utils";
import api from "../../common/api";
import apiUrl from "../../common/apiUrl";
import Loader from "../../common/Loader";
import Confirmation from "./components/confirmation";
import ViewQuestion from "./components/ViewQuestion";

const styles = {
    dialog: {
        boxShadow: 24,
        maxWidth: 600,
        borderRadius: 4,
        background: "#fff",
    },
    paper: {
        background: "transparent",
    },
    toast: {
        display: "flex",
        alignItems: "center",
    },
};

const ToastMessage = ({ variant = "success", message = "" }) => {
    const color =
        {
            success: "#35CB00",
            error: "#FF3300",
        }[variant] || "#35CB00";

    return (
        <div className="reversal_toast" style={{ ...styles.toast, "--toast-status": color }}>
            {{
                success: <CheckCircleIcon sx={{ fill: "#fff", mr: 1 }} />,
                error: <CancelIcon sx={{ fill: "#fff", mr: 1 }} />,
            }[variant] || null}
            {message}
        </div>
    );
};

// const question = "<p><img src=\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAAgCAYAAADOmyyBAAADY0lEQVRoge2aO5KjMBCGf23tUWACyicQJ/A4ceR0MxGaxNmGm00iwiHbdCInI05gTuByYHGX3gA/GIyEeXhtM3xVDrCF6OZXS909w4iIMPL0/Li3ASP9MAo5EEYhB8LPpjcwxm5hx4iBa1OYxkI2fcDI/2HcWgdCKyGHE40JAsbAGIMfZU9tx7eOyCzaY04EIoVJ+Ibkie1gTRsCjLEBReSZJPCxX22wdJ7Tju4RmQRgwb3W8nUkgY/aHctbYHZnEQG0t4MaAIC+3KIEgUvSRESkSXIcxnCS2jSLInGY53zvrcltE8pkkjD/1vAZnfzvYEdjIU9oSbxCCC15bqTJIiUIAHGzpzdCkah6wUqYbW1Ba/872tFaSCWqV7iWgqQUhlWpSQpBgt9DSLp4WaeXfvj0oWcb//uwo+UZmWAdC8ynhp9nK0ieInwrnZ3JG3bzFbyrHhHAevRmEXzmI8rOqXt+VltSedcDj9enrNBZbkD5YgYR4d3kz7U2HWnov9WOLIJ/9O/48SOUj/x2QiZrxNyDaxzgYLbgQOGlHW4zi9+ELILvhkiRInTXeequBBC/grHzdVpO5Z0ZFjzG+ua5WV/+JwjcEBOVC6wEAKFAmyXK+VArIbP9tnaMs/wNgRh/jlGRRVh7K/ShI5wlNlqCg0Pq93xO16u43mJfka1uq77smV78z/bYgsM7RMx0LoDt/iIaAYOQ/TTGp1hJfoqK7HMHz5pXZ4j8wvbxGiN+tW8nt6eLTU39r8CZYcFTfHzmT8n2W2DychGNQIWQRxHLYhYbAc7L5Do7TqsywttuXlPkOlhuzucEKQGhCtcV20lbJi/XztTNpmb+G57/VwKhC8YY3HACZTjIKyOS6jo30zlE+oHPyuW4xU6fBmIugDT8gLfqZVPtiMYutSRpvdCn/wmCX8DfUyL0bt6aTeksSsV/eehl+VEsiAt1VLHe1JJ4Ic22NgSsxXGhqAYnqW3XglRhzk41o9Wmnv2vmtNSnljrSJuQuVGFl/TwaJLc1nF5RBTJssFaEq9QslbIo4CVwfulRffI1LToHhQlLjtEWvLmEUlU0V8tY1ghj4SpC/X4FI8IWFubtX/GOmavNcNG7kxtQ2AU8Dm4qrMzivn4fOt/9RgSo5ADYRRyIIxCDoRRyIHwD1Eph8uzN5dmAAAAAElFTkSuQmCC\" /><br /></p>"
const limit = 1;

function ReviewMpp({ showEdit = false }) {
    const navigate = useNavigate();
    const { mppID } = useParams();
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentSectionId, setCurrentSectionId] = useState('');
    const sectionRefs = useRef([]);
    const open = Boolean(anchorEl);
    const [saveLoading, setSaveLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(0);

    const getMppDetails = async ({ SKIP = skip, LIMIT = limit }) => {
        setLoading(true)
        const response = await api.doFetch("GET", {}, apiUrl.MPP_PREVIEW + `?mpp_id=${mppID}&skip=${SKIP}&limit=${LIMIT}`)
        if (response?.status) {
            const questionList = response?.data?.questions
            setTotal(response?.data?.total)
            if (questionList.length > 0) {
                setCurrentQuestion(questionList[0])
            } else {
                setCurrentQuestion({})
            }
        } else {
            errorHandler(response)
        }
        window.scrollTo(0, 0);
        setLoading(false)
    }

    useEffect(() => {
        getMppDetails({ SKIP: skip, LIMIT: limit })
    }, [skip])

    const toggleModal = () => {
        setOpenModal(!openModal)
    }
    const canSave = () => {
        return true
    }

    // if (loading) {
    //     return (
    //         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
    //             <Loader />
    //         </div>
    //     );
    // }

    const goBack = () => {
        navigate(mppID ? "/mpp/list" : "/mpp/add");
    };

    const handlePrevious = () => {
        if (skip == 0) {
            navigate(`/mpp/add`)
        } else {
            setSkip(skip => skip - 1)
        }
    }

    const handleNext = () => {
        if (skip + 1 == total) {
            navigate(`/mpp/add/${mppID}`)
        } else {
            setSkip(skip => skip + 1)
        }
    }

    const handleConfirmSubmit = () => {
        toast(<ToastMessage variant="success" message="Saved changes successfully." />);
    }



    return (
        <>
            <Confirmation
                createModal={openModal}
                closeCreateModal={toggleModal}
                hubName={""}
                showEdit={showEdit}
                // handleConfirmUpdate={handleConfirmUpdate}
                handleConfirmSubmit={handleConfirmSubmit}
            />
            {/* <div className="containerWhite"> */}
            {loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                <Loader />
            </div>
                : <div className="mpp_review_container1">
                    <ViewQuestion question={currentQuestion} currentSectionId={currentSectionId} setCurrentSectionId={setCurrentSectionId} sectionRefs={sectionRefs} />
                    <div className="button_row_add_mpp mt-3">
                        <button
                            className="btn btn-md mpp_previous_button"
                            type="button"
                            onClick={handlePrevious}
                        >
                            Previous
                        </button>
                        <div />

                        <button
                            disabled={!canSave() || saveLoading}
                            onClick={handleNext}
                            className={"btn btn-md course_add_next"}
                            type="button"
                        >
                            {skip + 1 == total ? "Create MPP" : "Next"}
                            {saveLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                        </button>
                    </div>
                </div>}
        </>
    );
}

export default ReviewMpp;
