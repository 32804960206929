import React, { useState, useEffect } from 'react'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Asterisk } from "../../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import Confirmation from "./ConfirmQBModal"
import ToastMessage from '../../common/toastMessage';
import { toast } from "react-toastify";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import { useParams } from "react-router-dom";
import LeftArrow from "../../../assets/img/backArrowBlack.svg";
import Loader from '../../common/Loader';
import { errorHandler } from "../../../utils";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: `${ className } tooltip_header` }} />)(
    ({ theme }) => ({
        [`& .${ tooltipClasses.arrow }`]: {
            color: theme.palette.common.black
        },
        [`& .${ tooltipClasses.tooltip }`]: {
            backgroundColor: theme.palette.common.black,
            fontSize: "14px"
        },
    })
);

const exerciseSequenceList = [{ no: 1, name: 'Exercise 1' }, { no: 2, name: 'Exercise 2' }, { no: 3, name: 'Exercise 3' }, { no: 4, name: 'Exercise 4' }, { no: 5, name: 'Exercise 5' }, { no: 6, name: 'Exercise 6' }, { no: 7, name: 'Exercise 7' }, { no: 8, name: 'Exercise 8' }, { no: 9, name: 'Exercise 9' }, { no: 10, name: 'Exercise 10' }, { no: 11, name: 'Exercise 11' }, { no: 12, name: 'Exercise 12' }, { no: 13, name: 'Exercise 13' }, { no: 14, name: 'Exercise 14' }, { no: 15, name: 'Exercise 15' }]

const TextContainer = ({ header = '', content = '' }) => {

    return <div className="mpp_gray_container">
        <span className="mpp_gray_container_text1">{header}</span>
        {
            header === "Chapter" ?
            <span 
                className="mpp_gray_container_text2" 
                style={{ fontSize: 17, display: "flex", columnGap: "7px" }}
            >
                {content[0]}
                {
                    content.length > 1 &&
                    <div className="d-flex align-items-center cursor-pointer">
                        <div className="test_chapter_seperator" />
                        <BootstrapTooltip title={content.slice(1).map(e => e).join(", ")} placement="bottom">
                            <span className="test_chapters_more">+{content.length-1}</span>
                        </BootstrapTooltip>
                    </div>
                }
            </span>
            :
            <span 
                className="mpp_gray_container_text2" 
                style={{ fontSize: 17 }}
            >
                {content}
            </span>
        }
    </div>
}

const initialState = {
    selectedCourse: null,
    selectedExam: null,
    selectedSequence: null,
    selectedFaculty: null,
}

const CreateQB = () => {
    const navigate = useNavigate();
    const { qbID } = useParams();
    const [showModal, setShowModal] = useState(false)
    const [filterState, setFilterState] = useState(initialState)
    const [targetExamName, setTargetExamName] = useState('')
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [confirmMessage, setConfirmMessage] = useState('')
    const handleFilterState = (payload) => {
        setFilterState((state) => { return { ...state, ...payload } })
    }
    const getQBData = async () => {
        setLoading(true)
        let url = `${ ApiUrl.QB }/confirm/${ qbID }`;
        const data = await Api.doFetch("GET", {}, url);
        if (data.status) {
            const payload = data.data
            if (payload) {
                setData({
                    ...payload,
                    courses: payload?.courses.sort((a, b) => a.course_name.localeCompare(b.course_name)),
                    target_exams: payload?.target_exams.sort((a, b) => a.target_exam.localeCompare(b.target_exam)),
                    faculties: payload?.faculties.sort((a, b) => a.faculty_name.localeCompare(b.faculty_name))
                })
            }
        }
        else {
            errorHandler(data)
        }
        setLoading(false)
    }
    const ConfirmQBData = async () => {
        setLoading(true)
        let url = `${ ApiUrl.QB }/confirm/${ qbID }`;
        let payload = {}
        if (filterState.selectedCourse !== null) {
            payload.course_id = filterState.selectedCourse
        }
        if (filterState.selectedExam !== null) {
            payload.target_exam_id = filterState.selectedExam
        }
        if (filterState.selectedSequence !== null) {
            payload.sequence_number = filterState.selectedSequence
        }

        if (filterState.selectedFaculty !== null) {
            payload.faculty_id = filterState.selectedFaculty
        }

        const data = await Api.doFetch("POST", payload, url);
        if (data.status) {
            setConfirmMessage(data?.message)
            setShowModal(true)
        }
        else {
            errorHandler(data)
        }
        setLoading(false)
    }

    const canSave = () => {
        if (filterState.selectedCourse && filterState.selectedExam && filterState.selectedFaculty && filterState.selectedSequence) {
            return true
        }
        else {
            return false
        }

    }

    useEffect(() => {
        getQBData()
    }, [])

    return (
        <div className="mpp_list_container">

            <div className="qb_add_header">
                <div className="d-flex align-items-center">

                    <img src={LeftArrow} alt="back" className="cursor-pointer" onClick={() => navigate("/qb")} />
                    <span className="reversal_detail_header_text ml-2">Create QB</span>
                </div>
            </div>
            {loading ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                    <Loader />
                </div>
                :
                <>
                    {
                        confirmMessage !== '' && 
                        <Confirmation 
                            openModal={showModal} 
                            closeModal={() => setShowModal(false)} 
                            message={confirmMessage}
                            handleConfirmSubmit={() => {
                                setShowModal(false)
                                navigator.clipboard.writeText(confirmMessage.substring(confirmMessage.lastIndexOf("ID-") + 3, confirmMessage.length - 1))
                                toast(<ToastMessage text="Exercise ID copied successfully." />)
                                navigate("/qb/list")

                            }}
                        />
                    }
                    <div className="addQBContainer">
                        <div className="mpp_file_upload_container1">
                            <div className="mpp_file_upload_container2">
                                <TextContainer header="Subject" content={data?.subject} />
                                <TextContainer header="Chapter" content={data?.chapter} />
                                <TextContainer header="File uploaded" content={data?.filename} />
                                <TextContainer header="Number of questions" content={data?.num_questions} />
                            </div>
                        </div>
                    </div>

                    <div className="addQBContainer">
                        <span className="mpp_text_header">
                            Select QB details
                        </span>

                        <div className="mt-1 row display-name-overall-container">
                            <div className={"col-md-12"}>
                                <div className="col-md-4 course_data_wrapper" style={{ paddingLeft: 0 }}>
                                    <p className="student_filter_drop_header">Course {<Asterisk />}</p>
                                    <div className="antd_dropdown">
                                        <Select style={{ width: "100%" }}
                                            placeholder="All"
                                            value={filterState.selectedCourse || undefined}
                                            onChange={event => {
                                                handleFilterState({ selectedCourse: event })
                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            showArrow
                                            allowClear>
                                            {data?.courses.map((course, index) => {
                                                return (
                                                    <Option key={index} value={course.course_id}>
                                                        {course.course_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </div>

                                <div className="col-md-2 course_data_wrapper">
                                    <p className="student_filter_drop_header">Target Exam {<Asterisk />}</p>
                                    <div className="antd_dropdown">
                                        <Select style={{ width: "100%" }}
                                            placeholder="All"
                                            value={filterState.selectedExam || undefined}
                                            onChange={event => {
                                                handleFilterState({ selectedExam: event })
                                                setTargetExamName(data.target_exams.find(exam => exam.target_exam_id === event).target_exam)
                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            showArrow
                                            allowClear>
                                            {data?.target_exams.map((exam, index) => {
                                                return (
                                                    <Option key={index} value={exam.target_exam_id}>
                                                        {exam.target_exam}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </div>

                                <div className="col-md-2 course_data_wrapper">
                                    <p className="student_filter_drop_header">Exercise sequence {<Asterisk />}</p>
                                    <div className="antd_dropdown">
                                        <Select style={{ width: "100%" }}
                                            placeholder="All"
                                            value={filterState.selectedSequence || undefined}
                                            onChange={value => {
                                                handleFilterState({ selectedSequence: value })
                                            }}

                                            showSearch
                                            optionFilterProp="children"
                                            showArrow
                                            allowClear>
                                            {exerciseSequenceList.map((sequence, index) => {
                                                return (
                                                    <Option key={index} value={sequence.no}>
                                                        {sequence.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div>

                                </div>
                                <div className="col-md-4 course_data_wrapper">
                                    <p className="student_filter_drop_header">Faculty name {<Asterisk />}</p>
                                    <div className="antd_dropdown">
                                        <Select style={{ width: "100%" }}
                                            placeholder="Select faculty name"
                                            value={filterState.selectedFaculty || undefined}
                                            onChange={event => {
                                                console.log(event)
                                                handleFilterState({ selectedFaculty: event })

                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            showArrow
                                            allowClear>
                                            {data?.faculties.map((faculty, index) => {
                                                return (
                                                    <Option key={index} value={faculty.faculty_id}>
                                                        {faculty.faculty_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="d-flex mt-4 justify-content-between align-items-center">
                        <div className='d-column'>
                            {
                                filterState.selectedExam && filterState.selectedSequence && 
                                <>
                                    <span className="mpp_text_header"> File name visible in list of question bank</span>
                                    <span className='mt-1 mpp_tag mpp_qp_header'>
                                        {targetExamName}-{data?.chapter.length > 1 ? "Test" : data?.chapter}-Ex{filterState.selectedSequence}
                                    </span>
                                </>
                            }
                        </div>

                        <button
                            style={{ height: 60 }}
                            onClick={() => {
                                ConfirmQBData()
                            }}
                            className={"btn btn-md course_add_next"}
                            type="button"
                            disabled={!canSave()}
                        >
                            Create exercise
                            {/* {saveLoading && <span className="ml-2 spinner spinner-white mr-2"></span>} */}
                        </button>

                    </div>

                </>}


        </div>


    )
}

export default CreateQB