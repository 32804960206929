import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LeftArrow from "../../assets/img/backArrowBlack.svg";

import api from "../common/api";
import apiUrl from "../common/apiUrl";
import { Asterisk, debounce } from "../../utils/helperFunctions";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { SCRL_UPDATE_LIST } from "../../actions/types";
import ErrorText, { InfoText } from "../common/errorText";
import Confirmation from "./confirmation";

const styles = {
    dialog: {
        boxShadow: 24,
        maxWidth: 600,
        borderRadius: 4,
        background: "#fff",
    },
    paper: {
        background: "transparent",
    },
    toast: {
        display: "flex",
        alignItems: "center",
    },
};

const ToastMessage = ({ variant = "success", message = "" }) => {
    const color =
        {
            success: "#35CB00",
            error: "#FF3300",
        }[variant] || "#35CB00";

    return (
        <div className="reversal_toast" style={{ ...styles.toast, "--toast-status": color }}>
            {{
                success: <CheckCircleIcon sx={{ fill: "#fff", mr: 1 }} />,
                error: <CancelIcon sx={{ fill: "#fff", mr: 1 }} />,
            }[variant] || null}
            {message}
        </div>
    );
};

function AddHub({ showEdit = false }) {
    const subscribed = useRef(true);
    const edited = useRef(false);
    const navigate = useNavigate();
    const { hubID } = useParams();
    const dispatch = useDispatch();

    const [hubName, setHubName] = useState("");
    const [hubLogo, setHubLogo] = useState(null);
    const [hubNameDuplicate, setHubNameDuplicate] = useState(false);
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [dataDump, setDataDump] = useState(null);
    const hubState = useSelector((state) => state.scrollingList);

    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(showEdit);
    const [openModal, setOpenModal] = useState(false);
    const [logoError, setLogoError] = useState("");
    const [uploadedLogoChanged, setUploadedLogoChanged] = useState(false);

    const canSubmit = useMemo(() => {
        if (hubName.length > 0 && (hubLogo !== null || uploadedLogo !== null) && !hubNameDuplicate) {
            return true;
        }

        return false;
    }, [hubName, hubLogo, hubNameDuplicate, uploadedLogo]);

    const goBack = () => {
        navigate(hubID ? "/hub/list" : "/hub");
    };

    const handleCancel = () => {
        goBack();
        // if (showEdit && dataDump) {
        //     setHubName(dataDump.hub_name);
        //     setUploadedLogo(dataDump.hub_logo);
        //     setUploadedLogoChanged(false);
        // } else {
        //     setHubName("");
        //     setHubLogo(null);
        //     setUploadedLogo(null);
        // }
    }

    const handleFileDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length === 1 && rejectedFiles.length === 0) {
            // Accepted file
            setLogoError("");
            setHubLogo(acceptedFiles[0]);
            setUploadedLogo(URL.createObjectURL(acceptedFiles[0]));
            setUploadedLogoChanged(true);
        } else {
            if (rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors && rejectedFiles[0].errors.length > 0 && rejectedFiles[0].errors[0].code === "file-too-large") {
                setLogoError(<span>The image size is larger than <strong>5 MB</strong>. Please reduce the image size.</span>);
            } else {
                setLogoError(<span>Please upload a <strong>.png</strong> or <strong>.jpg</strong> image</span>);
            }
            setHubLogo(null);
            setUploadedLogoChanged(false);
        }
    }, []);

    const canSave = () => {
        if (!showEdit) {
            if (hubName.trim().length > 0 && hubLogo !== null && !hubNameDuplicate) {
                return true;
            }
        } else {
            if (dataDump) {
                if ((hubName.trim().length > 0 && dataDump.hub_name !== hubName.trim() && !hubNameDuplicate) || uploadedLogoChanged) {
                    return true;
                }
            }
        }

        return false;
    };

    const { getInputProps, getRootProps } = useDropzone({
        multiple: false,
        maxSize: 5.01 * 1024 * 1024, // 5 MB
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
        onDrop: handleFileDrop,
    });

    const handleSearchDuplicate = useMemo(() => {
        return debounce(async (searchText = "", hubID) => {
            if (!searchText && searchText.length === 0) return;

            const checkDuplicateBody = {
                hub_name: searchText.trim(),
                hub_id: hubID || null,
            };

            if (hubID) checkDuplicateBody.hub_id = hubID;

            const checkDuplicateResponse = await api.doFetch("POST", checkDuplicateBody, `${apiUrl.HUB}/check-duplicate`);

            if (checkDuplicateResponse?.status) {
                setHubNameDuplicate(checkDuplicateResponse.data?.length > 0);
            }
        }, 650);
    }, []);

    useEffect(() => {
        subscribed.current = true;
        edited.current = false;

        return () => {
            subscribed.current = false;
        };
    }, []);

    const getHubDetails = React.useCallback(async () => {
        const hubResponse = await api.doFetch("GET", {}, `${apiUrl.HUB}/${hubID}`);

        if (hubResponse?.status === true) {
            const hubData = hubResponse.data;

            if (hubData) {
                setDataDump(hubData);
                return hubData;
            }
        }

        return null;
    }, [hubID]);


    useEffect(() => {
        if (showEdit) {
            setLoading(true);

            getHubDetails().then((hubData) => {
                if (hubData) {
                    setUploadedLogo(hubData.hub_logo);
                    setHubName(hubData.hub_name);
                    setLoading(false);
                }
            });
        }
    }, [showEdit, getHubDetails]);

    useEffect(() => {
        if (edited.current) handleSearchDuplicate(hubName, hubID);
    }, [handleSearchDuplicate, hubName, hubID]);

    const toggleModal = () => {
        setOpenModal(!openModal);
    };

    const handleConfirmSubmit = async () => {
        try {
            if (saveLoading || !canSubmit) return;
            setSaveLoading(true);

            // Upload to S3 -> link
            const uploadFormData = new FormData();
            uploadFormData.append("file", hubLogo);

            const uploadResponse = await api.doUpload("POST", uploadFormData, `${apiUrl.HUB}/upload-s3`);

            if (uploadResponse?.status) {
                const imageUrl = uploadResponse.data?.url;

                if (imageUrl) {
                    // Save hub name and link
                    const createHubBody = {
                        hub_name: hubName,
                        hub_logo: imageUrl,
                    };

                    const createHubResponse = await api.doFetch("POST", createHubBody, `${apiUrl.HUB}`);

                    if (createHubResponse?.status === true && subscribed.current) {
                        toast(<ToastMessage variant="success" message="Hub created successfully." />);

                        setHubLogo(null);
                        setHubName('');
                        setOpenModal(false);

                        return;
                    }

                    toast(<ToastMessage variant="error" message="Couldn't create hub" />);
                    setOpenModal(false);
                }
            } else {
                toast(<ToastMessage variant="error" message="Couldn't upload image" />);
                setOpenModal(false);
            }
        } catch (error) {
            toast(<ToastMessage variant="error" message="There was a problem creating hub" />);
            setOpenModal(false);
        } finally {
            if (subscribed.current) setSaveLoading(false);
        }
    };

    const handleConfirmUpdate = async () => {
        try {
            if (saveLoading || !canSubmit) return;
            setSaveLoading(true);

            let hubLogoLink = dataDump.hub_logo;

            if (uploadedLogoChanged) {
                // Upload to S3 -> link
                const uploadFormData = new FormData();
                uploadFormData.append("file", hubLogo);

                const uploadResponse = await api.doUpload("POST", uploadFormData, `${apiUrl.HUB}/upload-s3`);

                if (uploadResponse?.status === true && uploadResponse.data?.url) {
                    hubLogoLink = uploadResponse.data.url;
                } else {
                    toast(<ToastMessage variant="error" message="Couldn't upload image" />);
                    setOpenModal(false);
                    return;
                }
            }

            const updateHubBody = {
                hub_name: hubName,
                hub_logo: hubLogoLink,
            };

            const updateHubResponse = await api.doFetch("PATCH", updateHubBody, `${apiUrl.HUB}/${hubID}`);

            if (updateHubResponse?.status === true && subscribed.current) {
                toast(<ToastMessage variant="success" message="Hub updated successfully." />);

                const hubData = await getHubDetails();

                if (hubData) {
                    // Update component state
                    setUploadedLogo(hubData.hub_logo);
                    setHubName(hubData.hub_name);
                    setUploadedLogoChanged(false);

                    // Update redux
                    const hubList = hubState.listKey ? hubState[hubState.listKey] || [] : [];

                    const result = Array.from(hubList);

                    const selectedHubIndex = result.findIndex((h) => h.id === hubData.id);

                    if (selectedHubIndex > -1) {
                        result[selectedHubIndex].hub_name = hubData.hub_name;
                        result[selectedHubIndex].hub_logo = hubData.hub_logo;

                        dispatch({ type: SCRL_UPDATE_LIST, payload: result });
                    }
                }

                setOpenModal(false);
                return;
            } else {
                toast(<ToastMessage variant="error" message="Couldn't update hub" />);
                setOpenModal(false);
            }
        } catch (error) {
            toast(<ToastMessage variant="error" message="There was a problem updating hub" />);
            setOpenModal(false);
        } finally {
            if (subscribed.current) setSaveLoading(false);
        }
    };

    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                <Loader />
            </div>
        );
    }

    return (
        <>
            <Confirmation 
                createModal = {openModal}
                closeCreateModal = {toggleModal}
                hubName = {hubName}
                showEdit = {showEdit}
                handleConfirmUpdate = {handleConfirmUpdate}
                handleConfirmSubmit = {handleConfirmSubmit}
            />

            <div className="containerWhite">
                <div className="addHubContainer" style={{ display: "flex" }}>
                    <img src={LeftArrow} alt="back" className="cursor-pointer" onClick={goBack} />

                    <span className="reversal_detail_header_text">{showEdit ? "Edit hub" : "Add hub"}</span>
                </div>
                <div className="addHubContainer">
                    <p className="student_filter_drop_header">
                        Hub brand <Asterisk />
                    </p>

                    <input
                        className={`student_search_input add_hub${hubName && hubNameDuplicate ? " error" : ""}`}
                        value={hubName}
                        onChange={(event) => {
                            setHubName(event.target.value);
                        }}
                        onFocus={() => {
                            edited.current = true;
                        }}
                        placeholder="Add hub brand name"
                    />

                    {
                        hubName && hubNameDuplicate &&
                        <div>
                            <ErrorText
                                text={"Hub brand already exists"}
                            />
                        </div>
                    }

                    <p className="student_filter_drop_header mt-5">
                        {showEdit ? "Update hub logo" : "Upload hub logo"} <Asterisk />
                    </p>

                    {
                        uploadedLogo ?
                            <div className="update_hub_logo">
                                <img className="hub_list_img" src={uploadedLogo} alt="hub_list_img" />
                                <div className="hub_list_text cursor-pointer" {...getRootProps()}>Edit</div>
                                <input className="fileup" {...getInputProps()} />
                            </div>
                            :
                            <div className="filesBox add_hub" {...getRootProps()}>
                                <div className="inputFile"></div>
                                <input className="fileup" {...getInputProps()} />

                                {hubLogo ? (
                                    hubLogo.name
                                ) : (
                                    <>
                                        <div className="fileUploadHelperText hub">Drag {"&"} drop or click to choose a file</div>
                                        <div style={{ marginTop: 14 }}>
                                            Please upload a <strong>.png</strong> or <strong>.jpg</strong> image of max size <strong>5 MB</strong>
                                        </div>
                                    </>
                                )}
                            </div>
                    }

                    {
                        logoError &&
                        <div>
                            <ErrorText
                                text={logoError}
                            />
                        </div>
                    }
                </div>
            </div>

            <div className="button_row_add_hub">
                <button className="btn btn-md course_add_back" type="button" onClick={handleCancel}>
                    Cancel
                </button>

                <button
                    disabled={!canSave() || saveLoading}
                    onClick={toggleModal}
                    className={"btn btn-md course_add_next"}
                    type="button"
                >
                    {showEdit ? "Save" : "Create"}
                    {saveLoading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                </button>
            </div>

        </>
    );
}

export default AddHub;
