import React from "react";
import {
  useLocation,
  NavLink
} from "react-router-dom";
import './installmentCreate.css';
import paymentSuccessImg from "../../assets/img/payment-success.gif";
import Api from "../common/api";

const PaymentSuccessHostel = (props) => {

  const location = useLocation()

  return (
    <div>
      {/* <TopNavBar logo={true} /> */}
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-md-2">
          <Supportsidenav />
        </div> */}
          <div className="col-md-12">
            <div className="support_main">
              <div className="installCreateWrap">
                <div className="container">
                  <div className="row">
                    <div className="headingWrap">
                      <div className="col-sm-12">
                        <h2>Installment Details</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  {/* <h1>Create Installment Plan</h1> */}
                  <div className="tabWrap paymentSuccess" style={{ textAlign: 'center'}}>
                    <div>
                      <img src={paymentSuccessImg} alt="success" />
                      <div className="successWrp">
                        <p>Payment Successfully Submited</p>
                        {
                          Api.isAuthenticated() &&
                          <>
                            <NavLink 
                              to={`./../hostel-receipt/${location.state.payment_id}`}
                              target="_blank"
                            >
                              View Payment Receipt
                            </NavLink>
                            <br/>
                            <NavLink 
                              to="/" 
                              style={{color:"#847C7C", cursor: "pointer", textDecoration: "none"}}
                            >
                              Go to dashboard
                            </NavLink>
                          </>
                        }
                      </div>
                    </div>

                    {/* <div className="LoadPage">
                      <img src={loaderImg} />
                      <p>Waiting For Payment ...</p>
                      <p>Check POS Machine</p>
                    </div> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PaymentSuccessHostel