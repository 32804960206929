import React from 'react'
import { currencyFormatter } from '../../../../utils/helperFunctions'

const StatusBar = ({
	totalCash,
	selectedCash
}) => {
	return (
		<div className='confirm_cash_status_bar_container' >
			<div className='confirm_cash_status_bar_column' >
				<span className='confirm_cash_status_bar_header' >Total cash not confirmed:</span>
				₹ {currencyFormatter(totalCash, false)}
			</div>
			<div className='confirm_cash_status_bar_column' >
				<span className='confirm_cash_status_bar_header' >Selected cash amount:</span>
				₹ {currencyFormatter(selectedCash, false)}
			</div>
		</div>
	)
}

export default StatusBar