import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../../assets/img/left_arrow.svg";
import { Select, DatePicker } from "antd";
import { Asterisk, disableFutureData } from '../../../../utils/helperFunctions';
import ErrorText from "../../../common/errorText";
import { useDropzone } from "react-dropzone";
// import { bankList } from "../../../payment/paymentMode/bankList"
import moment from 'moment';
import { useSelector } from "react-redux";
import Api from '../../../common/api';
import ApiUrl from '../../../common/apiUrl';
import pdfImg from "../../../../assets/img/pdf.svg";
import Confirmation from './confirmation';
import Swal from 'sweetalert2';
import ToastMessage from '../../../common/toastMessage';
import { toast } from "react-toastify";
import Tab from '../../common/tab';

const initialCenterIndexState = {
    tab1: 0,
    tab2: 1
}

const bankList = [
    "HDFC Bank Ltd",
    "ICICI Bank Ltd",
]

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     bgcolor: 'background.paper',
//     boxShadow: 24,
//     maxWidth: 500,
//     width: "90%",
//     padding: "3rem",
//     borderRadius: 4,

// };

const { Option } = Select;

const AddCashDeposit = () => {

    const navigate = useNavigate();

    const [selectedCenterId, setSelectedCenterId] = useState(null);
    const [centerIds, setCenterIds] = useState(initialCenterIndexState);

    const [bankName, setBankName] = useState(null)
    const [depositAmount, setDepositAmount] = useState("");
    const [depositDate, setDepositDate] = useState()

    const [formError, setFormError] = useState({});

    const { centerList } = useSelector(state => state.auth);

    const [slipImage, setSlpiImage] = useState(null);

    const [confirmationModal, setConfirmationModal] = useState(false);

    const closeConfirmationModal = () => {
        setConfirmationModal(false);
    }

    const checkIfPDF = () => {
        if (slipImage.type === "application/pdf") {
            return true;
        }
        return false;
    }

    const handleFileDrop = (acceptedFiles, rejectedFiles) => {
        if (loading) {
            return;
        }
        if (acceptedFiles.length === 1 && rejectedFiles.length === 0) {
            setFormError({
                ...formError,
                slipImage: ""
            })
            setSlpiImage(acceptedFiles[0]);
        } else {
            if (rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors && rejectedFiles[0].errors.length > 0 && rejectedFiles[0].errors[0].code === "file-too-large") {
                setFormError({
                    ...formError,
                    slipImage: <span>The image size is larger than <strong>5 MB</strong>. Please reduce the image size.</span>
                })
            } else {
                setFormError({
                    ...formError,
                    slipImage: <span>Please upload a <strong>.png</strong> or <strong>.jpg</strong> image or <strong>.pdf</strong> file</span>
                })
                
            }
            setSlpiImage(null);
        }
    };

    const { getInputProps, getRootProps } = useDropzone({
        multiple: false,
        maxSize: 5.01 * 1024 * 1024, // 5 MB
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
            "application/pdf": [".pdf"]
        },
        onDrop: handleFileDrop,
    });

    const handleDepositAmountChange = (e) => {
        const value = e.target.value;

        if (!value) {
            setDepositAmount("");
        } else if (/^[0-9]+$/.test(value)) {
            setDepositAmount(value);
        }

        setFormError({ ...formError, depositAmount: "" });
    }

    const [loading, setLoading] = useState(false);
    
    const addCashDeposit = async () => {

        if (loading) { 
            return;
        }

        const uploadFormData = new FormData();
        uploadFormData.append("centre_id", selectedCenterId);
        uploadFormData.append("deposit_date", moment(depositDate).format("YYYY-MM-DD"));
        uploadFormData.append("amount", +depositAmount);
        uploadFormData.append("bank_name", bankName);
        uploadFormData.append("deposit_slip", slipImage);

        setLoading(true);

        const data = await Api.doUpload("POST", uploadFormData, `${ApiUrl.ADD_CASH_DEPOSIT}`);

        setLoading(false);

        if (data?.status === true) {
            toast(<ToastMessage text="Cash deposit updated successfully." />)
            navigate("/cash-deposit");
        } else {
            Swal.fire("Error", data?.message || "Internal server error", "error");
        }

    }

    const checkCanSubmit = () => {

        let flag = true, tempError = {};

        if (!bankName) {
            tempError.bankName = "Please select bank";
            flag = false
        }
        if (!depositAmount) {
            tempError.depositAmount = "Please enter deposit amount";
            flag = false
        } else if (+depositAmount <= 0) {
            tempError.depositAmount = "Please enter a valid deposit amount";
            flag = false
        } if (!depositDate) {
            tempError.depositDate = "Please select deposit date";
            flag = false
        }
        if (!slipImage) {
            tempError.slipImage = "Please upload slip image";
            flag = false
        }

        setFormError({
            ...formError,
            ...tempError
        })

        return flag;
    }

    return (
        <div className='course_list_container'>

            <Confirmation 
                open = {confirmationModal}
                close = {closeConfirmationModal}
                depositAmount = {depositAmount}
                confirm = {() => {
                    closeConfirmationModal();
                    addCashDeposit();
                }}
            />

            <div className="course_list_header">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/cash-deposit");
                        }}
                    />
                    <span className="reversal_detail_header_text ml-2">Add cash deposit</span>
                </div>
            </div>

            {
                centerList && centerList.length >= 1 && 
                <>
                    
                    <Tab
                        selectedCenterId = {selectedCenterId}
                        setSelectedCenterId = {setSelectedCenterId}
                        centerIds = {centerIds}
                        setCenterIds  = {setCenterIds}
                        centerList = {centerList}
                    />

                    <div className="tab-content">

                        {/* {selectedCenterId === centerIds.tab1 &&  */}
                        <div
                            className={
                                // selectedCenterId === centerIds.tab1
                                //     ? 
                                "tab-pane fade in active active_tab"
                                // : "tab-pane fade"
                            }
                            style={{
                                backgroundColor: "#FFFFFF",
                                border: "none",
                                padding: "20px"
                            }}
                        >
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }} >

                                <div className="antd_dropdown confirm_input_container mx-2">
                                    <p className="student_filter_drop_header">
                                        Deposit date (as per slip) <Asterisk />
                                    </p>
                                    <DatePicker
                                        allowClear={false}
                                        className="attendance_date_picker mb-0"
                                        getPopupContainer={(triggerNode) => {
                                            return triggerNode.parentNode;
                                        }}
                                        placeholder="DD/MM/YYYY"
                                        // style={{ width: "100%" }}
                                        format={"DD/MM/YYYY"}
                                        onChange={(date) => {
                                            setFormError({ ...formError, depositDate: "" });
                                            setDepositDate(date);
                                        }}
                                        value={depositDate}
                                        disabledDate={disableFutureData}
                                    />
                                    {
                                        formError.depositDate &&
                                        <div>
                                            <ErrorText
                                                text={formError.depositDate}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className=" antd_dropdown confirm_input_container1 mx-2">
                                    <p className="student_filter_drop_header">
                                        Deposit amount (as per slip) <Asterisk />
                                    </p>
                                    <input
                                        className={`change_pw_input hidden_arrows_input_full_width cash_deposit_rupee_input`}
                                        placeholder="20,000"
                                        onChange={handleDepositAmountChange}
                                        value={depositAmount}
                                    />
                                    <span className="cash_deposit_rupee">
                                    ₹
                                    </span>
                                    <span className="helper_text">
                                        Amount should <strong>exactly</strong> match with slip amount
                                    </span>
                                    {
                                        formError.depositAmount &&
                                        <div>
                                            <ErrorText
                                                text={formError.depositAmount}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className=" antd_dropdown confirm_input_container mx-2">
                                    <p className="student_filter_drop_header">Bank Name <Asterisk />
                                    </p>
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Select bank name"
                                        onChange={value => {
                                            setFormError({ ...formError, bankName: "" })
                                            setBankName(value || "")
                                        }}
                                        value={bankName || null}
                                        showArrow
                                        allowClear
                                        showSearch
                                    >
                                        {
                                            bankList.map((options, index) => {
                                                return (
                                                    <Option
                                                        key={index}
                                                        value={options}
                                                    >{options}</Option>
                                                )
                                            })
                                        }

                                    </Select>
                                    {
                                        formError.bankName &&
                                        <div>
                                            <ErrorText
                                                text={formError.bankName}
                                            />
                                        </div>
                                    }
                                </div>

                            </div>

                            <p className="student_filter_drop_header mt-5">
                                Upload deposit slip image<Asterisk />
                            </p>

                            {
                                slipImage ?
                                <div className="mb-3">
                                    <div className="update_deposit_slip">
                                        <img 
                                            className="hub_list_img" 
                                            src={
                                                checkIfPDF() ?
                                                pdfImg
                                                :
                                                URL.createObjectURL(slipImage)
                                            } 
                                            alt="hub_list_img" 
                                        />
                                        <input className="fileup" {...getInputProps()} />
                                    </div>
                                    <span className="student_filter_drop_header">
                                        {slipImage.name}
                                        <span className="cursor-pointer ml-3 cash_deposit_upload_deposit_edit" {...getRootProps()}>Edit</span>
                                    </span>
                                </div>
                                :
                                <div className="filesBox add_hub" style={{ "height": "178px", "padding": "10px" }} {...getRootProps()}>
                                    <div className="inputFile"></div>
                                    <input className="fileup" {...getInputProps()} />

                                    {
                                        slipImage ?
                                        slipImage.name
                                        :
                                        <>
                                            <div className="helper_text2">Drag and drop or browse to choose a file</div>
                                            <div style={{ marginTop: 10 }}>
                                                Max file size <strong>5 MB</strong>
                                            </div>
                                        </>
                                    }
                                </div>
                            }

                            {
                                formError.slipImage &&
                                <div>
                                    <ErrorText
                                        text={formError.slipImage}
                                    />
                                </div>
                            }
                            <div className="d-flex justify-content-between" style={{ width: '100%', paddingTop: "10px" }} >
                                <button
                                    className="btn btn-md add_tab_back"
                                    onClick={() => navigate("/cash-deposit")}
                                >
                                    Cancel
                                </button>
                                <button
                                    // disabled={!hasChangedStudentData()}
                                    className="btn btn-md add_tab_next ml-2"
                                    onClick={() => {
                                        if (loading) {
                                            return;
                                        }
                                        if (checkCanSubmit()) {
                                            setConfirmationModal(true);
                                        }
                                    }}
                                >
                                    Confirm
                                    {loading && <span className="ml-2 spinner spinner-white mr-2"></span>}
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default AddCashDeposit