import React from 'react';

const Loader = () => {
  return (
    <div className="col-md-12">
      <div className="text-center" role="alert">
        <img alt="loader" src="https://c.tenor.com/5o2p0tH5LFQAAAAi/hug.gif" width="60px" height="60px"></img>
      </div>
    </div>
  );
}

export default Loader
