import swal from "sweetalert2";

async function handleErrors(response) {

    if (response.status === 401) {
        swal.fire("Unauthorised?", "User logged into another device", "error");
        Api().resetTracking();
        localStorage.clear();

        setTimeout(() => {
            window.location.href = "/"
        }, 1000)

        return null;
    }

    return response;
}

function Api() {

    const logout = () => {
        // localStorage.clear();
        resetTracking();
        localStorage.removeItem("userdetail");
        localStorage.removeItem("mycrm-nav");
    }

    const getUserDisplayName = () => {
        try {
            let data = localStorage.getItem("userdetail");
            data = JSON.parse(data);

            if (!data) {
                return null;
            }

            return data.user_display_name;
        } catch (e) {
            return null;
        }
    }

    const getUserName = () => {
        try {
            let data = localStorage.getItem("userdetail");
            data = JSON.parse(data);

            if (!data) {
                return null;
            }

            return data.username;
        } catch (e) {
            return null;
        }
    }

    const getUserData = () => {
        try {
            let data = localStorage.getItem("userdetail");
            data = JSON.parse(data);

            if (!data) {
                return {};
            }

            return data;
        } catch (e) {
            return {};
        }
    }

    const getUserID = () => {
        try {
            let data = localStorage.getItem("userdetail");
            data = JSON.parse(data);

            if (!data) {
                return null;
            }

            return data.user_id;
        } catch (e) {
            return null;
        }
    }

    const isAuthenticated = () => {
        try {
            let data = localStorage.getItem("userdetail");
            data = JSON.parse(data);

            if (!data) {
                return null;
            }

            return data.token;
        } catch (e) {
            return null;
        }
    };

    const doFetch = async (type, data, url) => {

        let reqData = { ...data };
        if (data === null || data === undefined) {
            reqData = {};
        }

        let token = isAuthenticated();

        return fetch(url, {
            method: type,
            headers:
                type !== "GET"
                    ? {
                        "Authorization": `Token ${token}`,
                        "content-type": "Application/json"
                    }
                    : {
                        "Authorization": `Token ${token}`
                    },
            body: type !== "GET" ? JSON.stringify({ ...reqData }) : null,
        })
            .then(handleErrors)
            .then((res) => {
                if (res) {
                    return res.json()
                }

                return null;
            })
            .catch((err) => console.log(err));
    };

    const doDownload = async (type, data, url) => {

        let reqData = { ...data };
        if (data === null || data === undefined) {
            reqData = {};
        }

        let token = isAuthenticated();

        return fetch(url, {
            method: type,
            headers:
                type !== "GET"
                    ? {
                        "Authorization": `Token ${token}`,
                        "content-type": "Application/json"
                    }
                    : {
                        "Authorization": `Token ${token}`
                    },
            body: type !== "GET" ? JSON.stringify({ ...reqData }) : null,
        })
            .then(handleErrors)
            .then((res) => {
                return res
            })
            .catch((err) => console.log(err));
    };

    const doUpload = async (type, data, url) => {

        let token = isAuthenticated();

        return fetch(url, {
            method: type,
            headers:
                type !== "GET"
                    ? {
                        "Authorization": `Token ${token}`,
                    }
                    : {
                        "Authorization": `Token ${token}`
                    },
            body: data,
        })
            .then(handleErrors)
            .then((res) => {
                if (res) {
                    return res.json()
                }

                return null;
            })
            .catch((err) => console.log(err));
    };

    const getNavState = () => {
        try {
            let data = localStorage.getItem("mycrm-nav");

            if (!data) {
                return true
            };

            return data === "close" ? false : true;
        } catch (e) {
            return true;
        }
    }

    const setNavState = (status) => {
        localStorage.setItem("mycrm-nav", status ? "open" : "close")
    }

    // Identify user for sending events to segment
    const identifyUser = (stage, urlParams, partner, partnerCode, locationData) => {
        if (process.env.REACT_APP_ENV !== 'PROD') {
            return;
        }

        const user = getUserData();

        if (!user || !user.user_id) {
            return;
        }

        let traits = {
            mobileNumber: user.username,
            name: user.user_display_name
        };

        if (user.email) {
            traits.email = user.email;
        }

        try {
            window.analytics.identify(user.user_id, traits);
        } catch (err) {
        }
    };

    // Update user with given traits
    const updateUser = traits => {
        // const user = getDecodedToken();
        const user = {};
        try {
            // user && user._id && window.analytics.identify(user._id, traits);
        } catch (err) {
        }
    };

    // Track pages that user visits and send to segment
    const trackPage = (pageName) => {
        try {
            if (process.env.REACT_APP_ENV !== 'PROD') {
                return;
            }
            if (pageName) {
                window.analytics.page(pageName);
            } else {
                window.analytics.page();
            }
        } catch (err) {
        }
    };

    // Send event to Segment
    const trackEvent = (event, properties) => {
        try {
            if (process.env.REACT_APP_ENV !== 'PROD') {
                return;
            }
            window.analytics.track(event, properties);
        } catch (err) {
        }
    };

    // Clear userId and other info stored for segment tracking
    const resetTracking = () => {
        try {
            if (process.env.REACT_APP_ENV !== 'PROD') {
                return;
            }
            window.analytics.reset();
        } catch (err) {
        }
    }

    return {
        isAuthenticated,
        doFetch,
        doUpload,
        doDownload,
        getUserID,
        getUserName,
        getUserDisplayName,
        logout,
        getNavState,
        setNavState,
        identifyUser,
        trackPage,
        trackEvent,
        resetTracking
    };
}

export default new Api();