import React, { useEffect, useState } from "react";
import "./Fees.css";
import axios from "axios";
import dateFormat from "dateformat";
import Logo from "../../assets/img/MCR.png";

import { ToWords } from "to-words";
import ReactLoading from "react-loading";

import { httpGet } from "../../utils/https";

import { useParams } from "react-router-dom";

const PaymentRecipt_feesummmary = (props) => {
	let data = localStorage.getItem("userdetail");
	data = JSON.parse(data);
	// console.log(props, "props");
	const { payment_id } = useParams();
	const [paymentdata, setPayment] = useState("");
	const [name, setName] = useState("");
	//   const [amtword, setAmtWord] = useState("");
	const [studentId, setStudentId] = useState("");
	//   const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [receipt, setReceipt] = useState(null);

	const [mobile, setMobile] = useState("");
	const [stud_class, setStud_Class] = useState("");
	const [parentName, setParentName] = useState("");
	const [amt, setAmt] = useState("");
	const [logoUrl, setLogoUrl] = useState("");
	const token = data.token;
	const [schoolname, setSchoolname] = useState("");
	//   const pdfExportComponent = useRef(null);

	const [totalfees, setTotalFees] = useState("");
	const [feepaid, setFeePaid] = useState("");
	const [remfee, setRemFee] = useState("");
	const [unrelamt, setUnrealAmt] = useState("");
	//   const [paidfor, setPaidFor] = useState("");

	const societyMatch = ["40086", "40084", "40083", "40069", "40068"];
	const hostelMatch = ["40090", "40089", "40088", "40087", "40085", "40082", "40079", "40017", "40012", "40005", "40003"];

	const [QR, setQR] = useState("");

	const getPaymentMode = (paymentType) => {
		let paymentMode = "";
		switch (paymentType) {
			case "online/swipe":
				paymentMode = "Online/Swipe";
				break;
			case "hdfc/ccavenue":
				paymentMode = "hdfc/ccavenue";
				break;
			case "cash":
			case "Cash":
				paymentMode = "Cash";
				break;
			case "cheque":
			case "Cheque":
			case "Post Dated Cheque":
				paymentMode = "Cheque";
				break;
			case "CommerceConnect":
			case "commerceconnect":
			case "razorpay":
			case "Razorpay":
			case "Bank Transfer":
			case "paytm":
			case "Paytm":
				paymentMode = "Online";
				break;
			case "ezetap":
			case "swipe":
			case "Swipe":
				paymentMode = "POS";
				break;
			case "link":
				paymentMode = "Link";
				break;
			default:
				paymentMode = paymentType;
		}
		return paymentMode;
	};

	const getPaymentReceipt = async () => {
		const data = await httpGet(`${process.env.REACT_APP_BASE_URL}support/get-payment-detail/${payment_id}`);

		if (data.status === 200) {
			console.log(data.data.payload, "data summary");
			//   setPaymentDetails(data.data.payload)
			setSchoolname(data.data.payload.school_assoc.name);
			setName(data.data.payload.student_assoc.name);
			setStudentId(data.data.payload.student_assoc.id);
			setLogoUrl(data.data.payload.school_assoc.logo);
			//   setEmail(data.data.payload.student_assoc.email);
			setMobile(data.data.payload.student_assoc.mobile_number);
			setPayment(data.data.payload);
			setAmt(data.data.payload.amount_paid);
			setStud_Class(data.data.payload.course_name);

			setTotalFees(data.data.payload.student_assoc.total_fees);
			setFeePaid(data.data.payload.student_assoc.fee_paid);
			setRemFee(data.data.payload.student_assoc.fee_pending);
			setUnrealAmt(data.data.payload.unrealised_amount);
			//   setPaidFor(data.data.payload.course_name);
			setLoading(false);

			setQR(data?.data?.payload?.QR);

			downloadReciept(
				data.data.payload.student_assoc.id,
				data.data.payload.id,
				data.data.payload.student_assoc.section_assoc.id,
				data.data.payload.school_assoc.id
			);
			axios
				.get(process.env.REACT_APP_BASE_URL + "users/get-parent-details/?ID=" + data.data.payload.student_assoc.id)
				.then((data) => {
					setParentName(data && data.data.length > 0 && data.data[0].name ? data.data[0].name : "");
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const downloadReciept = async (student_id, payment_id, section_id, school_id) => {
		try {
			// console.log('studentid:',studentId,'payment_id',paymentDetails.id,'section_id',paymentDetails.student_assoc.section_assoc.id,'school_id',paymentDetails.school_assoc.id)
			let formdata = new FormData();
			formdata.append("student_id", student_id);
			formdata.append("payment_id", payment_id);
			formdata.append("section_id", section_id);
			formdata.append("school_id", school_id);

			const response = await fetch(`${process.env.REACT_APP_BASE_URL}support/generate-payment-receipt/`, {
				method: "POST",
				headers: {
					// 'Accept': "application/json",
					// 'Content-Type': "application/json",
					Authorization: "Token " + token,
				},
				body: formdata,
			});
			const D = await response.json();
			console.log(D.payload.invoice_url, "open");
			setReceipt(D.payload.invoice_url);
		} catch (err) {
			console.log(err);
		}
	};

	var toWords = new ToWords({
		localeCode: "en-IN",
		converterOptions: {
			currency: false,
			ignoreDecimal: false,
			ignoreZeroCurrency: false,
			doNotAddOnly: false,
		},
	});
	useEffect(() => {
		setLoading(true);
		getPaymentReceipt();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className="container">
				<div className="text-right" style={{ marginTop: "20px" }}>
					<button
						className={receipt != null ? `btn btn-primary` : `btn non_click`}
						onClick={() => {
							if (receipt != null) {
								window.open(receipt, "_blank");
							}
						}}
					>
						Download Invoice
					</button>
				</div>

				<br />
				<br />
				{loading ? (
					<div>
						<div>
							<br />
							<br />
							<center>
								<ReactLoading type="spinningBubbles" color="blue" height="3px" />
								<br />
								<br />
								<br />
								<br />
								<p>Please Wait While Your Receipt Is loading</p>
							</center>
						</div>
					</div>
				) : (
					<div>
						<div style={{ marginTop: "0px", border: "0.5px solid #909090" }}>
							<div className="blue-box row">
								<div className="col-md-4">
									<img
										src="https://zinedumain.s3.us-east-2.amazonaws.com/Rectangle_logo_container.svg"
										alt=""
										className="rect-img-cont"
									/>

									{logoUrl === null ? (
										<img alt={"Partner Logo"} src={Logo} className="partner-logo-img" />
									) : (
										<img alt={"Partner Logo"} src={logoUrl} className="partner-logo-img" />
									)}
								</div>
								<div className="col-md-4">
									<center>
										<span className="ack-text"> Fee Acknowledgement </span>
									</center>
								</div>
								<div className="col-md-4"></div>
							</div>

							<div className="ack-below-box">
								{/* <div className="col-md-3 blank"></div> */}
								<div className="company-deets">
									<p>
										<strong>
											<span className="company-name">
												{Boolean(
													societyMatch.find((a) => {
														return a == paymentdata?.school_assoc?.id;
													})
												)
													? "Nano Educational Society"
													: Boolean(
															hostelMatch.find((a) => {
																return a == paymentdata?.school_assoc?.id;
															})
													  )
													? "Nano Eduventures Private Limited"
													: paymentdata?.centre_details?.entity}
											</span>
										</strong>
										<br />
										{Boolean(
											hostelMatch.find((a) => {
												return a == paymentdata?.school_assoc?.id;
											})
										) && <span className="small-line">{"GSTIN : 36AABCP8809L2ZY"}</span>}
									</p>
								</div>
								<div className="myclass-center">
									<strong>Centre name</strong> <br />
									<span className="center-heading-value">{schoolname}</span>
									<br />
								</div>
								<div className="myclass-center">
									<strong>Contact Us</strong> <br />
									<span>{"+91-" + paymentdata?.student_assoc?.school_assoc?.mobile_number}</span>
									<br />
								</div>
							</div>

							<div className="maincontent">
								<div className="acknowledgement-num">
									<div>
										<span style={{ fontWeight: "600" }}>Acknowledgement Number: </span>
										<span style={{ fontWeight: "500" }}>{paymentdata.id}</span>
									</div>
									<div>
										<span style={{ fontWeight: "600" }}>Acknowledgement Date: </span>
										<span style={{ fontWeight: "500" }}>{dateFormat(paymentdata.created_on, "dd mmmm yyyy")}</span>
									</div>
									{Boolean(paymentdata?.counseller_name) && (
										<div>
											<span style={{ fontWeight: "600" }}>Counselor Name: </span>
											<span style={{ fontWeight: "500" }}>{paymentdata.counseller_name}</span>
										</div>
									)}
								</div>

								<center>
									<div className="stud-details-box mx-auto">
										<table>
											<tbody>
												<tr>
													<td className="stud-deet-td">
														{" "}
														<strong>Student Name</strong>
													</td>
													<td className="stud-deet-td">:</td>
													<td className="stud-deet-td">{name}</td>
													<td className="stud-deet-td">
														<strong>Student Mobile No</strong>
													</td>
													<td className="stud-deet-td">:</td>
													<td className="stud-deet-td">{mobile}</td>
												</tr>

												<tr>
													<td className="stud-deet-td">
														<strong>Student ID</strong>
													</td>
													<td className="stud-deet-td">:</td>
													<td className="stud-deet-td">{studentId}</td>
													<td className="stud-deet-td">
														<strong>Parent Name</strong>
													</td>
													<td className="stud-deet-td">:</td>

													<td className="stud-deet-td">{parentName}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</center>

								<div className="pay-deets-1">
									<center>
										<table className="pay-deets-1-tab">
											<tbody>
												<tr>
													<td>
														<strong>Paid For</strong>
													</td>
													<td>{stud_class}</td>
												</tr>
												{/* <tr>
                                            <td>
                                                <strong>Mode of Payment</strong>
                                            </td>
                                            <td>
                                                <strong>{getPaymentMode(paymentdata.payment_type)}</strong>
                                            </td>
                                        </tr> */}
												<tr>
													<td>
														<strong>Cash/Bank/Ref No</strong>
													</td>
													<td>
														{paymentdata.payment_id === "Not Applicable !" ? (
															<p>{paymentdata?.payment_type == "cash" ? "Cash" : "Not Applicable"}</p>
														) : (
															<p>{paymentdata.payment_id}</p>
														)}
													</td>
												</tr>
												{/* <tr>
                                            <td>
                                                <strong>Date of Receipt</strong>
                                            </td>
                                            <td>{dateFormat(paymentdata.created_on, "dd mmmm yyyy")}</td>
                                        </tr> */}
												<tr>
													<td>
														<strong>Total Amount</strong>
													</td>
													<td>₹ {paymentdata.amount_paid}</td>
												</tr>
												<tr>
													<td>
														<strong>Total Amount in Words</strong>
													</td>
													<td>{amt ? <div> Rupees {toWords.convert(amt)} Only</div> : ""}</td>
												</tr>
											</tbody>
										</table>
									</center>
								</div>

								{/* {
                            paymentdata?.centre_details?.CIN &&
                            <p className="to-view">
                                To view detailed installment breakup, check your student
                                dashboard on{" "}
                                <a href="https://myclassroom.digital">
                                    {" "}
                                    https://myclassroom.digital
                                </a>
                            </p>
                        } */}
								<p className="comp-gen text-center">
									<strong>This is a computer generated receipt and does not require manual signature.</strong>
								</p>
							</div>

							<div className="bottom-sheet">
								<div className="bottom-sheet-name">Customer Copy</div>

								{/* <img src="https://zinedumain.s3.us-east-2.amazonaws.com/below-img.svg" alt="" className="below-img"></img> */}
							</div>
						</div>

						<div className="dashed-line">
							--------------------------------------------------------------------------------------------------------------------------------------------------------------
						</div>

						<div style={{ marginTop: "0px", border: "0.5px solid #909090" }}>
							<div className="blue-box row">
								<div className="col-md-4">
									<img
										src="https://zinedumain.s3.us-east-2.amazonaws.com/Rectangle_logo_container.svg"
										alt=""
										className="rect-img-cont"
									/>

									{logoUrl === null ? (
										<img alt={"Partner Logo"} src={Logo} className="partner-logo-img" />
									) : (
										<img alt={"Partner Logo"} src={logoUrl} className="partner-logo-img" />
									)}
								</div>
								<div className="col-md-4">
									<center>
										<span className="ack-text"> Fee Acknowledgement </span>
									</center>
								</div>
								<div className="col-md-4"></div>
							</div>

							<div className="ack-below-box">
								{/* <div className="col-md-3 blank"></div> */}
								<div className="company-deets">
									<p>
										<strong>
											<span className="company-name">
												{Boolean(
													societyMatch.find((a) => {
														return a == paymentdata?.school_assoc?.id;
													})
												)
													? "Nano Educational Society"
													: Boolean(
															hostelMatch.find((a) => {
																return a == paymentdata?.school_assoc?.id;
															})
													  )
													? "Nano Eduventures Private Limited"
													: paymentdata?.centre_details?.entity}
											</span>
										</strong>
										<br />
										{Boolean(
											hostelMatch.find((a) => {
												return a == paymentdata?.school_assoc?.id;
											})
										) && <span className="small-line">{"GSTIN : 36AABCP8809L2ZY"}</span>}
										{/* <span className="small-line">
                                    {paymentdata?.centre_details?.sub_entity}
                                </span> */}
									</p>
								</div>
								<div className="myclass-center">
									<p className="center-heading">
										<strong>Centre name</strong> <br />
										<span className="center-heading-value">{schoolname}</span>
									</p>
								</div>
								<div className="myclass-center">
									<strong>Contact Us</strong> <br />
									<span>{"+91-" + paymentdata?.student_assoc?.school_assoc?.mobile_number}</span>
									<br />
								</div>
							</div>

							<div className="maincontent">
								<div className="acknowledgement-num">
									<div>
										<span style={{ fontWeight: "600" }}>Acknowledgement Number: </span>
										<span style={{ fontWeight: "500" }}>{paymentdata.id}</span>
									</div>
									<div>
										<span style={{ fontWeight: "600" }}>Acknowledgement Date: </span>{" "}
										<span style={{ fontWeight: "500" }}>{dateFormat(paymentdata.created_on, "dd mmmm yyyy")}</span>
									</div>
									{Boolean(paymentdata?.counseller_name) && (
										<div>
											<span style={{ fontWeight: "600" }}>Counselor Name: </span>
											<span style={{ fontWeight: "500" }}>{paymentdata.counseller_name}</span>
										</div>
									)}
								</div>

								<center>
									<div className="stud-details-box mx-auto">
										<table>
											<tbody>
												<tr>
													<td className="stud-deet-td">
														{" "}
														<strong>Student Name</strong>
													</td>
													<td className="stud-deet-td">:</td>
													<td className="stud-deet-td">{name}</td>
													<td className="stud-deet-td">
														<strong>Student Mobile No</strong>
													</td>
													<td className="stud-deet-td">:</td>
													<td className="stud-deet-td">{mobile}</td>
												</tr>

												<tr>
													<td className="stud-deet-td">
														<strong>Student ID</strong>
													</td>
													<td className="stud-deet-td">:</td>
													<td className="stud-deet-td">{studentId}</td>
													<td className="stud-deet-td">
														<strong>Parent Name</strong>
													</td>
													<td className="stud-deet-td">:</td>

													<td className="stud-deet-td">{parentName}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</center>

								<div className="pay-deets-1">
									<center>
										<table className="pay-deets-1-tab">
											<tbody>
												<tr>
													<td>
														<strong>Paid For</strong>
													</td>
													<td>{stud_class}</td>
												</tr>
												{/* <tr>
                                            <td>
                                                <strong>Mode of Payment</strong>
                                            </td>
                                            <td>
                                                <strong>{getPaymentMode(paymentdata.payment_type)}</strong>
                                            </td>
                                        </tr> */}
												<tr>
													<td>
														<strong>Cash/Bank/Ref No</strong>
													</td>
													<td>
														{paymentdata.payment_id === "Not Applicable !" ? (
															<p>{paymentdata?.payment_type == "cash" ? "Cash" : "Not Applicable"}</p>
														) : (
															<p>{paymentdata.payment_id}</p>
														)}
													</td>
												</tr>
												{/* <tr>
                                            <td>
                                                <strong>Date of Receipt</strong>
                                            </td>
                                            <td>{dateFormat(paymentdata.created_on, "dd mmmm yyyy")}</td>
                                        </tr> */}
												<tr>
													<td>
														<strong>Total Amount</strong>
													</td>
													<td>₹ {paymentdata.amount_paid}</td>
												</tr>
												<tr>
													<td>
														<strong>Total Amount in Words</strong>
													</td>
													<td>{amt ? <div> Rupees {toWords.convert(amt)} Only</div> : ""}</td>
												</tr>
											</tbody>
										</table>
									</center>
								</div>

								{/* {
                            paymentdata?.centre_details?.CIN &&
                            <p className="to-view">
                                To view detailed installment breakup, check your student
                                dashboard on{" "}
                                <a href="https://myclassroom.digital">
                                    {" "}
                                    https://myclassroom.digital
                                </a>
                            </p>
                        } */}
								<p className="comp-gen text-center">
									<strong>This is a computer generated receipt and does not require manual signature.</strong>
								</p>
							</div>

							<div className="bottom-sheet">
								<div className="bottom-sheet-name">Merchant Copy</div>

								{/* <img src="https://zinedumain.s3.us-east-2.amazonaws.com/below-img.svg" alt="" className="below-img"></img> */}
							</div>
						</div>
					</div>
				)}

				<br />
				<br />

				<br />
				<br />
			</div>
		</div>
	);
};

export default PaymentRecipt_feesummmary;

/*
LINE 302
*/
