import React from "react";
import { Asterisk } from "../../../utils/helperFunctions";
import ErrorText from "../../common/errorText";
import { Select, DatePicker } from "antd";
import moment from "moment";

const { Option } = Select;

const formatDate = (d) => {
    if (d) return moment(d).format("DD-MM-YYYY");

    return null;
};

const timelineDetails = ({ view, setBatchData, batchData, academicYearListMetaData, batchError, setBatchError, centre }) => {
    const selectedAcademicYearChangeHandler = (value) => {
        setBatchError({
            ...batchError,
            start_academic_year: "",
        });
        setBatchData({ ...batchData, start_academic_year: value });
    };

    return (
        <>
            {!centre && <div className="course_add_header_container">Timeline details</div>}

            <div className={centre ? "academic_details_data_container" : "course_add_data_container"}>
                {centre && <div className="academic_details_header_container">Timeline details</div>}
                <div className="row">
                    <div className={view ? "col-md-10" : "col-md-11"}>
                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">
                                Start academic year {!view && <Asterisk />}
                            </p>

                            {view ? (
                                <p className="course_view_text_data">{(centre ? centre.start_academic_year : batchData.start_academic_year) || "-"}</p>
                            ) : (
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select academic year"
                                    value={(centre ? centre.start_academic_year : batchData.start_academic_year) || undefined}
                                    onChange={selectedAcademicYearChangeHandler}
                                    showArrow
                                    allowClear
                                    showSearch
                                >
                                    {academicYearListMetaData.map((options, index) => {
                                        return (
                                            <Option key={index} value={options}>
                                                {options}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            )}
                            {batchError.start_academic_year && <ErrorText text={batchError.start_academic_year} />}
                        </div>

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">
                                Start date {!view && <Asterisk />}
                            </p>

                            {view ? (
                                <p className="course_view_text_data">{formatDate(centre ? centre.start_date : batchData.start_date) || "-"}</p>
                                ) : (
                                <DatePicker
                                    className="batch_date_picker"
                                    format={"DD-MM-YYYY"}
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    onChange={(date) => {
                                        setBatchError({
                                            ...batchError,
                                            start_date: "",
                                        });
                                        setBatchData({
                                            ...batchData,
                                            start_date: date,
                                        });
                                    }}
                                    value={(centre ? centre.start_date : batchData.start_date) || undefined}
                                />
                            )}
                            {batchError.start_date && <ErrorText text={batchError.start_date} />}
                        </div>

                        <div className="col-md-4 course_data_wrapper">
                            <p className="student_filter_drop_header">
                                End date {!view && <Asterisk />}
                            </p>

                            {view ? (
                                <p className="course_view_text_data">{formatDate(centre ? centre.end_date : batchData.end_date) || "-"}</p>
                                ) : (
                                <DatePicker
                                    className="batch_date_picker"
                                    format={"DD-MM-YYYY"}
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    onChange={(date) => {
                                        setBatchError({
                                            ...batchError,
                                            end_date: "",
                                        });
                                        setBatchData({
                                            ...batchData,
                                            end_date: date,
                                        });
                                    }}
                                    value={(centre ? centre.end_date : batchData.end_date) || undefined}
                                />
                            )}
                            {batchError.end_date && <ErrorText text={batchError.end_date} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default timelineDetails;
