import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../../assets/img/left_arrow.svg";
import Filter from '../../common/accountsFilter';
import CashDepositCard from './cashDepositCard';
import moment from 'moment';
import Api from '../../../common/api';
import ApiUrl from '../../../common/apiUrl';
import { useSelector, useDispatch } from "react-redux";
import { useScrollingList } from '../../../../utils/hooks/use-scrolling-list';
import { SCRL_HANDLE_SEARCH_INIT, HANDLE_SCRL_LIST, SCRL_RESET_LIST } from '../../../../actions/types';
import FinanceLoader from "../../../finance/common/FinanceLoader";
import Loader from '../../../common/Loader';
import Empty from "../../../../assets/img/empty_student.svg";
import BackTop from "../../../finance/common/backTop";

const CashDepositList = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [filterLoading, setFilterLoading] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [centre, setCentre] = useState([]);
    const [dateError, setDateError] = useState("");
    const [bankStatus, setBankStatus] = useState([])

    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const centreRef = useRef(centre);
    const bankStatusRef = useRef(bankStatus);

    const getCashDepositList = useCallback(async ({ skip = 0, limit = 10, search = "", }) => {

        let payload = {
            skip,
            limit
        }

        if (search && search.trim().length > 0) {
            payload.search = search.trim();
        }

        if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
            payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
            payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
        }

        if (centreRef && centreRef.current.length > 0) {
            payload.centre_ids = centreRef.current;
        }

        if (bankStatusRef && bankStatusRef.current.length > 0) {
            payload.bank_status = bankStatusRef.current;
        }

        const data = await Api.doFetch("POST", payload, ApiUrl.CASH_DEPOSIT_LIST);

        if (data?.status === true) {
            const payload = data.data;
            // console.log(payload, 'payload')
            // return {total:0,items:payload,skip:0,limit:0}

            if (payload) {
                const { cash_deposits: items, total, skip, limit, } = payload;                

                // totalAmount.current = no_cost_emi_sum

                return {
                    items,
                    total,
                    skip,
                    limit,
                };
            }
        }

        setFilterLoading(false);

        return null;
    }, []);

    const getCashDepositListDetails = async (getOne = false) => {

        if (!getOne && moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
            // setDateError("From date cannot be greater than To date");
            return;
        }

        !getOne && setFilterLoading(true);
        const data = await getCashDepositList(getOne ? {
            skip: skip + limit,
            limit: 1
        } : {
            skip,
            limit
        });

        if (getOne) {
            dispatch({
                type: HANDLE_SCRL_LIST,
                payload: {
                    listItems: (data && data.items) || []
                }
            });
        } else {
            dispatch({
                type: SCRL_HANDLE_SEARCH_INIT,
                payload: {
                    list: (data && data.items) || [],
                    skip: 0,
                    total: (data && data.total) || 0,
                    scrollLoading: false,
                },
            });
        }

        setFilterLoading(false);
    }

    const { skip, limit } = useSelector((state) => state.scrollingList);

    const [scrollContainer, { loading, scrollLoading, list: cashDepositList, handleScroll }] = useScrollingList({
        listKey: "cash_deposits",
        getData: getCashDepositList
    });

    useEffect(() => {
        return () => {
            dispatch({ type: SCRL_RESET_LIST })
        }
    }, [])

    if(loading) {
        return (
            <FinanceLoader text="Loading deposit list..."/>
        )
    }

    return (
        <div className="course_list_container">
            <div className="course_list_header2">
                <div className="d-flex align-items-center">
                    <img
                        src={LeftArrow}
                        alt="go back"
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/cash-deposit");
                        }}
                    />
                    <span className="reversal_detail_header_text ml-2">List of deposits</span>
                </div>
                <button className="btn btn-md course_add_next" onClick={() => {
                    navigate("/cash-deposit/add");
                }}>
                    Add deposit
                </button>
            </div>

            <Filter
                startDate={startDate}
                endDate={endDate}
                centre={centre}
                bankStatus={bankStatus}
                setStartDate={setStartDate}
                startDateRef={startDateRef}
                setEndDate={setEndDate}
                endDateRef={endDateRef}
                setCentre={setCentre}
                centreRef={centreRef}
                setBankStatus={setBankStatus}
                bankStatusRef={bankStatusRef}
                dateError={dateError}
                setDateError={setDateError}
                getDetails={getCashDepositListDetails}
                filterLoading={filterLoading}
            />

            <div ref={scrollContainer} className='cash_deposit_list_scroll_container' onScroll={handleScroll} >

                {
                    filterLoading ?
                    <div className="loader_scroll_container">
                        <Loader />
                    </div>
                    :
                    cashDepositList?.length === 0 ?
                    <div className="student_search_empty_container mt-5">
                        <div className="student_search_empty_img_container mt-5">
                            <img
                                src={Empty}
                                alt="not found"
                                className="student_search_empty_img"
                            />
                        </div>

                        <p className="student_search_empty_header">
                            No deposits available
                        </p>
                        <span className="student_search_empty_label">
                            There are no cash deposits made
                        </span>
                        <span className="student_search_empty_label">
                            at the moment
                        </span>
                    </div>
                    :
                    cashDepositList.map((data, index) => {
                        return (
                            <CashDepositCard 
                                data={data} 
                                key={index}
                            />
                        )
                    })
                }

                {
                    !loading && scrollLoading &&
                    <div className="loader_scroll_container">
                        <Loader />
                    </div>
                }

                <BackTop 
                    target = "cash_deposit_list_scroll_container"
                />

            </div>

        </div>
    )
}

export default CashDepositList;