import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LeftArrow from "../../../assets/img/backArrowBlack.svg";
import api from "../../common/api";
import apiUrl from "../../common/apiUrl";
import { Asterisk, debounce } from "../../../utils/helperFunctions";
import Loader from "../../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { SCRL_UPDATE_LIST } from "../../../actions/types";
import ErrorText, { InfoText } from "../../common/errorText";
import { Accordion, AccordionDetails, IconButton } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InstructionText from "./components/InstructionText";
import Accordian from "../../../utils/accordian";
import ArrowUp from "../../../assets/img/arrow_up.svg";
import ArrowDown from "../../../assets/img/arrow_down.svg";

const styles = {
    dialog: {
        boxShadow: 24,
        maxWidth: 600,
        borderRadius: 4,
        background: "#fff",
    },
    paper: {
        background: "transparent",
    },
    toast: {
        display: "flex",
        alignItems: "center",
    },
};

const ToastMessage = ({ variant = "success", message = "" }) => {
    const color =
        {
            success: "#35CB00",
            error: "#FF3300",
        }[variant] || "#35CB00";

    return (
        <div className="reversal_toast" style={{ ...styles.toast, "--toast-status": color }}>
            {{
                success: <CheckCircleIcon sx={{ fill: "#fff", mr: 1 }} />,
                error: <CancelIcon sx={{ fill: "#fff", mr: 1 }} />,
            }[variant] || null}
            {message}
        </div>
    );
};



function MppInstructions({ showEdit = false }) {
    const subscribed = useRef(true);
    const edited = useRef(false);
    const navigate = useNavigate();
    const { mppID } = useParams();
    const dispatch = useDispatch();

    const [hubName, setHubName] = useState("");
    const [file, setFile] = useState(null);
    const [hubNameDuplicate, setHubNameDuplicate] = useState(false);
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [dataDump, setDataDump] = useState(null);
    const hubState = useSelector((state) => state.scrollingList);

    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(showEdit);
    const [openModal, setOpenModal] = useState(false);
    const [fileError, setFileError] = useState("");
    const [uploadedLogoChanged, setUploadedLogoChanged] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [toggle, setToggle] = useState(true);
    const open = Boolean(anchorEl);

    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                <Loader />
            </div>
        );
    }

    const goBack = () => {
        navigate(mppID ? "/mpp/list" : "/mpp/add");
    };

    return (
        <>
            {/* <div className="containerWhite"> */}
            <div className="mpp_list_container">

                <div className="course_list_header">
                    <div className="d-flex align-items-center">
                        <img src={LeftArrow} alt="back" className="cursor-pointer" onClick={goBack} />
                        <span className="reversal_detail_header_text ml-2">View MPP Instructions</span>
                    </div>
                </div>

                <div className="addMppContainer" style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span className="mpp_instruction_header1">1. MPP Instructions</span>
                        <div className="">
                            <button
                                type="button"
                                className="payment_reversal_collapse_button"
                                onClick={() => setToggle(!toggle)}
                            >
                                <img
                                    src={toggle ? ArrowUp : ArrowDown}
                                    alt="arrow"
                                    style={{ width: "1.2rem" }}
                                />
                            </button>
                        </div>
                    </div>

                    <Accordian expand={toggle}>
                        <div className="mt-5">
                            <span className="mpp_instruction_header2">General Instructions</span>
                        </div>

                        <div className="mpp_instruction_container mt-3">
                            <InstructionText index={1} text='Total duration of the paper is 3 hours (180 minutes).' />
                            <InstructionText index={2} text={<span>The on-screen computer countdown timer on the top right corner of computer screen will display the remaining time (in minutes) available to you for completing the paper. When the on-screen countdown timer reaches zero, the paper will end by itself – <strong> No input from your side will be accepted after the timer reaches zero</strong> and whatever answers have been saved by you will automatically be submitted for evaluation.</span>} />
                            <InstructionText index={3} text={<span>The <strong>“Submit”</strong> button present at the bottom right corner of the screen will remain deactivated during the entire 180 minutes duration of the paper. Note that the <strong>“Submit”</strong> button will be activated only after the timer has reached zero and the saved responses will be automatically submitted. It is <strong>NOT</strong> required to click on <strong>“Submit”</strong> after the timer has reached zero.</span>} />
                            <InstructionText index={4} text={<span>The Question Palette displayed on the right side of screen will show the status of each question as per one of the following symbols <i>(the question numbers appear inside the symbols)</i></span>} />

                            <InstructionText index={5} text='Total duration of the paper is 3 hours (180 minutes).' />
                            <InstructionText index={6} text={<span>The on-screen computer countdown timer on the top right corner of computer screen will display the remaining time (in minutes) available to you for completing the paper. When the on-screen countdown timer reaches zero, the paper will end by itself – <strong> No input from your side will be accepted after the timer reaches zero</strong> and whatever answers have been saved by you will automatically be submitted for evaluation.</span>} />
                            <InstructionText index={7} text={<span>The <strong>“Submit”</strong> button present at the bottom right corner of the screen will remain deactivated during the entire 180 minutes duration of the paper. Note that the <strong>“Submit”</strong> button will be activated only after the timer has reached zero and the saved responses will be automatically submitted. It is <strong>NOT</strong> required to click on <strong>“Submit”</strong> after the timer has reached zero.</span>} />
                            <InstructionText index={8} text={<span>The Question Palette displayed on the right side of screen will show the status of each question as per one of the following symbols <i>(the question numbers appear inside the symbols)</i></span>} />
                        </div>
                    </Accordian>
                </div>
            </div>
        </>
    );
}

export default MppInstructions;

