import React from "react";
import AddChapter from "../addChapter";

const EditChapter = (props) => {

    return (
        <AddChapter 
            {...props}
            defaultView = {true}
        />
    );
};

export default EditChapter;