import React from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { currencyFormatter } from "../../../../utils/helperFunctions";

const Confirmation = ({
    open,
    close,
    depositAmount,
    confirm
}) => {
    return (
        <Modal
            open={open}
            onClose={close}
            className="payment_reversal_cofirmation_modal"
        >
            <Box className="view_cheque_modal" >
                <span className='modal_text_14'>
                    Deposited amount
                </span>
                <p className="modal_text_16">
                    ₹ {currencyFormatter(+(depositAmount), false)}
                </p>
                <p className="modal_text_18" style={{ marginTop: '22px', marginBottom: '16px' }}>
                    Deposited amount should exactly match with the amount mentioned on slip.
                </p>

                <div
                    className="mt-3"
                    style={{ float: "right" }}
                >
                    <button
                        className="btn btn-md student_detail_no"
                        onClick={close}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-md student_detail_password_confirm ml-2"
                        onClick={confirm}
                    >
                        Confirm
                    </button>
                </div>

            </Box>
        </Modal>
    );
};

export default Confirmation;