import axios from 'axios';

export const getCommonHeaders = () => {
  let userdetailData = localStorage.getItem("userdetail"); 
  userdetailData = JSON.parse(userdetailData); 
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: userdetailData.token != null ? "Token "+ userdetailData.token : ''
  };

  return headers;
};

export const httpGet = async (url) => {
  return axios
    .get(url, {
      redirect: "follow",  
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const httpDelete = async (url) => {
  return axios
    .delete(url, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.res;
    });
};

export const httpPost = async (url, body) => {
  return axios
    .post(url, body, {  
      redirect: "follow",  
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const httpPut = async (url, body) => {
  return axios
    .put(url, body, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
