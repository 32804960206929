import React from 'react'
import { useNavigate } from "react-router-dom";

const NotFound = () => {

	let navigate = useNavigate()

	return (
		<div className="not_found_container_component">
			<h1 className="not_found_container_heading">
				404
			</h1>

			<span className="not_found_container_text">
				OOPS! Something went wrong here
			</span>

			<div>
				<button
					className="btn btn-md not_found_page_button"
					onClick={() => navigate("/dashboard")}
				>
					Go to Home Page
				</button>
			</div>

		</div>
	)
}

export default NotFound;