import React, { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";
import Api from "../../../common/api";
import ApiUrl from "../../../common/apiUrl";
import Loader from "../../../common/Loader";
import FinanceLoader from "../../common/FinanceLoader";
import Empty from "../../../../assets/img/empty_student.svg";
import BackTop from "../../common/backTop";
import CashHeader from "./cashHeader";
import CashDetails from "./cashDetails";
import { useSelector, useDispatch } from "react-redux";
import Filter from "../../common/cashFilter";
import { useScrollingList } from "../../../../utils/hooks/use-scrolling-list";
import { SCRL_HANDLE_SEARCH_INIT, HANDLE_SCRL_LIST, HANDLE_SCRL_DATA, SCRL_RESET_LIST } from "../../../../actions/types";
// import { getMyclassroomCentre } from "../../../../utils/helperFunctions";

const PendingCashDeposits = () => {

    const dispatch = useDispatch();

    // const { myclassroomCentre } = useSelector((state) => state.common);

    const [filterLoading, setFilterLoading] = useState(false);

    // const [loading, setLoading] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [centre, setCentre] = useState([]);
    const [dateError, setDateError] = useState("");

    const [notFound, setNotFound] = useState(false);

    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const centreRef = useRef(centre);

    const getPendingCashDeposits = useCallback(async ({ skip = 0, limit = 10, search = "", }) => {

        let payload = {
            skip,
            limit,
            bank_status: ["pending"],
        }

        if (search && search.trim().length > 0) {
            payload.search = search.trim();
        }

        if (startDateRef && endDateRef && startDateRef.current && endDateRef.current) {
            payload.from_date = moment(startDateRef.current).format("YYYY-MM-DD");
            payload.to_date = moment(endDateRef.current).format("YYYY-MM-DD");
        }

        if (centreRef && centreRef.current.length > 0) {
            payload.centre_ids = centreRef.current;
        }

        const data = await Api.doFetch("POST", payload, ApiUrl.FINANCE_MANAGER_VIEW);

        if (data?.status === true) {
            const payload = data.data;
            console.log(data)

            if (payload) {
                const { cash_deposits: items, total, skip, limit, } = payload;

                // totalAmount.current = bank_deposit_sum

                return {
                    items,
                    total,
                    skip,
                    limit,
                };
            }
        }

        setFilterLoading(false);

        return null;
    }, []);

    const updatePendingCashDeposit = (id) => {
        dispatch({
            type: HANDLE_SCRL_DATA,
            payload: pendingCashDepositList.filter(x => {
                if (x.id === id) {
                    return false;
                }
                return true;
            })
        })
        getCashDepositDetails(true)
    }

    const getCashDepositDetails = async (getOne = false) => {

        if (!getOne && moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
            // setDateError("From date cannot be greater than To date");
            return;
        }

        !getOne && setFilterLoading(true);
        const data = await getPendingCashDeposits(getOne ? {
            skip: skip + limit,
            limit: 1,
            search: searchText
        } : {
            skip,
            limit,
            search: searchText
        });

        if (getOne) {
            dispatch({
                type: HANDLE_SCRL_LIST,
                payload: {
                    listItems: (data && data.items) || []
                }
            });
        } else {
            dispatch({
                type: SCRL_HANDLE_SEARCH_INIT,
                payload: {
                    list: (data && data.items) || [],
                    skip: 0,
                    total: (data && data.total) || 0,
                    scrollLoading: false,
                },
            });
        }

        setFilterLoading(false);
    }

    const { skip, limit } = useSelector((state) => state.scrollingList);


    const [scrollContainer, { loading, scrollLoading, list: pendingCashDepositList, handleScroll, searchText, handleSearch }] = useScrollingList({
        listKey: "pending_cash_deposits",
        getData: getPendingCashDeposits,
    });


    useEffect(() => {
        // if (myclassroomCentre && myclassroomCentre.length === 0) {
        //     getMyclassroomCentre();
        // }

        return () => {
            dispatch({ type: SCRL_RESET_LIST })
        }
    }, [])


    if (loading) {
        return (
            <>
                <FinanceLoader text="Loading pending cash deposits details..." />
            </>
        );
    }

    return (
        <div className="course_list_container">
            <div ref={scrollContainer} className="pending-cheques-scroll-container" onScroll={handleScroll}>

                <CashHeader />

                <Filter
                    startDate={startDate}
                    endDate={endDate}
                    centre={centre}
                    setStartDate={setStartDate}
                    startDateRef={startDateRef}
                    setEndDate={setEndDate}
                    endDateRef={endDateRef}
                    setCentre={setCentre}
                    centreRef={centreRef}
                    dateError={dateError}
                    setDateError={setDateError}
                    getDetails={getCashDepositDetails}
                    filterLoading={filterLoading}
                />

                {
                    filterLoading ?
                    <div className="loader_scroll_container">
                        <Loader />
                    </div>
                    : 
                    pendingCashDepositList.length === 0 ?
                    <div className="student_search_empty_container mt-5">
                        <div className="student_search_empty_img_container mt-5">
                            <img src={Empty} alt="not found" className="student_search_empty_img" />
                        </div>

                        <p className="student_search_empty_header">No Pending cash deposits found</p>
                        <span className="student_search_empty_label">
                            There are no cash deposit request pending 
                        </span>
                        <span className="student_search_empty_label">
                            at the moment.
                        </span>
                    </div>
                    :
                    pendingCashDepositList.map((data, index) => {
                        return (
                            <CashDetails updatePendingCashDeposit={updatePendingCashDeposit} data={data} key={index} />
                        );
                    })
                }

                {!loading && scrollLoading && (
                    <div className="loader_scroll_container">
                        <Loader />
                    </div>
                )}

                <BackTop target="pending-cheques-scroll-container" />
            </div>
        </div>
    );
};

export default PendingCashDeposits;