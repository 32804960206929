import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Asterisk } from "../../../utils/helperFunctions";
import { useSelector } from "react-redux";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import ErrorText from "../../common/errorText";
import { toast } from "react-toastify";
import TickWhite from "../../../assets/img/tick_white.svg";
import Swal from "sweetalert2";
import { Select } from "antd";
import { segmentEvents } from "../../../utils/constants";

const { Option } = Select;

const ToastMessage = () => (
    <div
        className="reversal_toast"
        style={{
            "--toast-status": "#35CB00"
        }}
    >
        <img
            src={TickWhite}
            alt={"success"}
            className="mr-2"
            style={{ width: "1.2rem" }}
        />
        Course added successfully.
    </div>
)

const AddCourse = ({
    open,
    close,
    student_id,
    refresh
}) => {

    const [centreError, setCentreError] = useState("");
    const [courseError, setCourseError] = useState("");
    const [batchError, setBatchError] = useState("");

    const { centerList } = useSelector(state => state.auth);

    const [selectedCentre, setSelectedCentre] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');

    const [courseList, setCourseList] = useState([]);
    const [batchList, setBatchList] = useState([]);

    const handleChangeCentre = value => {
        setSelectedCentre(value || "");
        setCentreError("");
    }

    const handleChangeCourse = value => {
        setSelectedCourse(value || "");
        setCourseError("");
    }

    const handleChangeBatch = value => {
        setSelectedBatch(value || "");
        setBatchError("");
    }

    const getCourses = async () => {
        const data = await Api.doFetch("GET", {}, `${ApiUrl.CLASSES}?school=${selectedCentre}`);
        setCourseList(data);
    }

    const getBatches = async () => {
        const data = await Api.doFetch("GET", {}, `${ApiUrl.SECTION}?school=${selectedCentre}&class=${selectedCourse}`)
        setBatchList(data);
    }

    useEffect(() => {
        setSelectedCourse("");
        setSelectedBatch("");

        setCourseList([]);
        setBatchList([]);
        if (selectedCentre) {
            getCourses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCentre])


    useEffect(() => {
        setSelectedBatch("");

        setBatchList([]);
        if (selectedCourse) {
            getBatches();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCourse])

    const [loading, setLoading] = useState(false);

    const addCourse = async () => {

        let flag = 1;

        if (!selectedCentre) {
            setCentreError("Please select centre");
            flag = 0;
        }
        if (!selectedCourse) {
            setCourseError("Please select course");
            flag = 0;
        }
        if (!selectedBatch) {
            setBatchError("Please select batch");
            flag = 0;
        }

        if (flag) {
            var formdata = new FormData();

            formdata.append("student_id", student_id);
            formdata.append("school_id", selectedCentre)
            formdata.append("section_id", selectedBatch);

            setLoading(true);

            const data = await Api.doUpload("POST", formdata, ApiUrl.ADD_COURSE);

            setLoading(false);

            if (data && data.status) {

                Api.trackEvent(segmentEvents.STUDENT.ADDED_COURSE);
                toast(<ToastMessage />);
                refresh();
                close();

            } else if (data && ((data.payload && data.payload.Error) || data.message)) {
                if (data.payload && data.payload.Error) {
                    Swal.fire("Error", data.payload.Error, "error");
                } else if (data.message) {
                    Swal.fire("Error", data.message, "error");
                }
            }
        }
    }

    return (
        <div className="add_course_container">
            <Dialog
                open={open}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "380px", // Set your width here
                        },
                    },
                }}
                className="add_course_modal_index"
            >
                <DialogTitle style={{ paddingBottom: "10px" }}>
                    <div
                        style={{
                            display: "flex",
                            padding: "6px",
                            justifyContent: "space-between",
                        }}
                    >
                        <div className="mark-att-modal-head">Add Course</div>
                        <button
                            onClick={close}
                            className="close-modal-btn"
                        >
                            x
                        </button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ padding: 5 }} className="antd_dropdown">
                        <div style={{ marginBottom: "20px" }}>
                            <p className="add_course_drop_header">
                                Select Center <Asterisk />
                            </p>

                            <Select
                                className = "full-width"
                                value = {selectedCentre || undefined}
                                placeholder = "Select Centre"
                                onChange = {handleChangeCentre}
                                showArrow
                                allowClear
                                showSearch
                                optionFilterProp="children"
                            >
                                {
                                    centerList && centerList.map((centreData, index) => {
                                        return (
                                            <Option
                                                value={centreData.school_id}
                                                key={index}
                                            >
                                                {centreData.school_name}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                            {
                                centreError &&
                                <ErrorText
                                    text={centreError}
                                />
                            }
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                            <p className="add_course_drop_header">
                                Course Name <Asterisk />
                            </p>

                            <Select
                                className = "full-width"
                                value = {selectedCourse || undefined}
                                placeholder = "Select Course Name"
                                onChange = {handleChangeCourse}
                                showArrow
                                allowClear
                                showSearch
                                optionFilterProp="children"
                            >
                                {
                                    courseList && courseList.map((courseData, index) => {
                                        return (
                                            <Option
                                                value={courseData.id}
                                                key={index}
                                            >
                                                {courseData.class_name}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                            {
                                courseError &&
                                <ErrorText
                                    text={courseError}
                                />
                            }
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                            <p className="add_course_drop_header">
                                Batch Name <Asterisk />
                            </p>

                            <Select
                                className = "full-width"
                                value = {selectedBatch || undefined}
                                placeholder = "Select Batch Name"
                                onChange = {handleChangeBatch}
                                showArrow
                                allowClear
                                showSearch
                                optionFilterProp="children"
                            >
                                {
                                    batchList && batchList.map((batchData, index) => {
                                        return (
                                            <Option
                                                value={batchData.id}
                                                key={index}
                                            >
                                                {batchData.section_name}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                            {
                                batchError &&
                                <ErrorText
                                    text={batchError}
                                />
                            }
                        </div>

                        <button
                            className="btn btn-md student_detail_add_course_button"
                            onClick={() => {
                                if (!loading) {
                                    addCourse();
                                }
                            }}
                        >
                            Assign Course
                            {loading && <span className="ml-2 spinner spinner-white mr-3"></span>}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddCourse;