import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, props }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    backgroundColor: "inherit"
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    display: "none",
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme, props}) => ({
    padding: props?.noStyles && props.noStyles ? theme.spacing(2) :theme.spacing(0),
    borderTop: props?.noStyles && props.noStyles ?'1px solid rgba(0, 0, 0, .125)': null,
}));

const AccordionComponent = (props) => {
    return(
        <Accordion expanded={props.expand ? true : false}>
            <AccordionSummary>
                <Typography>Collapsible Group Item #1</Typography>
            </AccordionSummary>
            <AccordionDetails classes={props.accordionDetailsClasses}>
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionComponent;