import { DatePicker, Select } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DownloadIcon from "../../../../assets/img/downloadIcon.svg";
import moment from "moment";
import ErrorText from "../../../common/errorText";
import { Capitalize, currencyFormatter } from "../../../../utils/helperFunctions";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { CustomFinanceTooltip } from "../../common/handleTooltip";
const { Option } = Select;

const CashPositionFilter = ({ startDate,
    endDate,
    centre,
    setStartDate,
    startDateRef,
    setEndDate,
    endDateRef,
    setCentre,
    centreRef,
    dateError,
    setDateError,
    getDetails,
    filterLoading,
    setFilterLoading,
    cashDepositList = [],
    allData
}) => {

    // const { myclassroomCentre } = useSelector(state => state.common);
    const { centerList } = useSelector(state => state.auth);

    const [isOpen, setIsOpen] = useState(true);
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [downloadLoading, setDownloadLoading] = useState(false);

    const filterArrowToggle = () => {
        setIsOpen(!isOpen);

        setEndDate(endDateRef.current);
        setStartDate(startDateRef.current);
        setCentre(centreRef.current);

        setStartDateError("");
        setEndDateError("");
        setDateError("");
    };

    const handleClearFilter = () => {
        setEndDate("");
        setStartDate("");
        setCentre([]);

        setIsOpen(true);

        startDateRef.current = "";
        endDateRef.current = "";
        centreRef.current = [];

        setStartDateError("");
        setEndDateError("");
        setDateError("");
        setFilterLoading(true);
        getDetails();
    }

    const filterApplied = () => {

        if (
            startDateRef.current ||
            endDateRef.current ||
            (centreRef.current && centreRef.current.length > 0)
        ) {
            return true;
        }
        return false;
    };

    const canApplyFilter = (skipStateUpdate = false) => {

        if (startDate && !endDate) {
            if (!skipStateUpdate) setEndDateError("Please select To date");
            return false;
        }

        if (endDate && !startDate) {
            if (!skipStateUpdate) setStartDateError("Please select From date");
            return false;
        }

        if (startDate && endDate && moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")) {
            if (!skipStateUpdate) setDateError("From date cannot be greater than To date");
            return false;
        }

        if (
            startDate ||
            endDate ||
            (centre && centre.length > 0)
        ) {
            return true;
        }

        return false;
    };

    const applyFilter = () => {
        if (
            startDate ||
            endDate ||
            (centre && centre.length > 0)
        ) {
            return true;
        }

        return false;
    };

    const getCentreList = () => {
        // return myclassroomCentre.filter(x => x && x.centre && x.centre.trim())
        return centerList
            .filter((x) => x && x.school_name && x.school_name.trim())
            .map((x) => {
                return {
                    id: x.school_id,
                    centre: x.school_name,
                };
            })
            .sort(function (a, b) {
                var nameA = a.centre.toLowerCase(),
                    nameB = b.centre.toLowerCase();
                if (nameA < nameB)
                    //sort string ascending
                    return -1;
                if (nameA > nameB) return 1;
                return 0; //default return value (no sorting)
            });
    };

    const downloadCashPositionDetails = () => {
        if (cashDepositList.length == 0) {
            return;
        }
        const workbook = new ExcelJS.Workbook();

        const sheet = workbook.addWorksheet('Payment details');

        sheet.columns = [
            { header: "Centre", key: "Centre" },
            { header: "Cash opening", key: "Cash_opening" },
            { header: "Confirmed cash", key: "Confirmed_cash" },
            { header: "Cash deposit", key: "Cash_deposit" },
            { header: "Cash closing", key: "Cash_closing" },
        ];

        let obj = {
            Centre: "All",
            Cash_opening: currencyFormatter(allData.cashOpening, false) || "",
            Confirmed_cash: currencyFormatter(allData.confirmedCash, false) || "",
            Cash_deposit: currencyFormatter(allData.cashDeposit, false) || "",
            Cash_closing: currencyFormatter(allData.cashClosing, false) || ""
        }
        sheet.addRow(obj);

        cashDepositList.map((row) => {
            const { cash_closing, cash_confirmed, cash_opening, deposit_confirmed, centre_name, centre_id } = row
            let obj = {
                Centre: centre_name || "",
                Cash_opening: currencyFormatter(cash_opening, false) || "",
                Confirmed_cash: currencyFormatter(cash_confirmed, false) || "",
                Cash_deposit: currencyFormatter(deposit_confirmed, false) || "",
                Cash_closing: currencyFormatter(cash_closing, false) || ""
            }
            sheet.addRow(obj);
        })

        setDownloadLoading(false);
        workbook.xlsx.writeBuffer().then(function (buffer) {
            const blob = new Blob([buffer], { type: "application/xlsx" });
            saveAs(blob, "Cash Deposit Details.xlsx");
        });
    }

    return (
        <div className="pending-cheques__filter_container">
            <div className="cheque-form-container">
                <div className="form-container__col-first_second filter">
                    <div className="cheque-status-select-container filter my-2">
                        <p className="cheque_filter_drop_header mb-1">
                            {centreRef.current&&centreRef.current.length>0&&<span className="marker" ></span>}
                            Centre
                        </p>

                        <div className="antd_dropdown">
                            <Select
                                mode="multiple"
                                autoClearSearchValue={false}
                                style={{ width: '100%' }}
                                placeholder="All"
                                value={centre}
                                onChange={value => {
                                    let index = value.indexOf("Select All");

                                    if (index >= 0) {
                                        if (centre.length === getCentreList().length) {
                                            setCentre([]);
                                        } else {
                                            setCentre(getCentreList().map(x => x.id));
                                        }
                                    } else {
                                        setCentre(value);
                                    }

                                }}
                                showArrow
                                allowClear
                                optionFilterProp="children"
                                maxTagCount='responsive'
                            >
                                {
                                    getCentreList() && getCentreList().map((center, index) => {
                                        return (
                                            <Option
                                                key={index}
                                                value={center.id}
                                            >{Capitalize(center.centre)}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="cheque-status-select-container filter my-2 antd_dropdown">
                        <p className="cheque_filter_drop_header mb-1">
                            {startDateRef.current&&<span className="marker" ></span>}
                            From
                        </p>
                        <DatePicker
                            allowClear={false}
                            className="attendance_date_picker mb-0"
                            getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                            }}
                            // style={{ width: "100%" }}
                            format={"DD/MM/YYYY"}
                            value={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                                setDateError("");
                                setStartDateError("");
                            }}
                            placeholder="dd/mm/yyyy"
                        />
                        {
                            dateError &&
                            <ErrorText text={dateError} />
                        }
                        {
                            startDateError &&
                            <ErrorText text={startDateError} />
                        }
                    </div>
                    <div className="cheque-status-select-container filter  my-2">
                        <p className="cheque_filter_drop_header mb-1">
                            {endDateRef.current&&<span className="marker" ></span>}
                            To
                        </p>
                        <DatePicker
                            allowClear={false}
                            className="attendance_date_picker mb-0"
                            getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                            }}
                            // style={{ width: "100%" }}
                            format={"DD/MM/YYYY"}
                            value={endDate}
                            onChange={(date) => {
                                setDateError("");
                                setEndDate(date);
                                setEndDateError("");
                            }}
                            placeholder="dd/mm/yyyy"
                        />
                        {
                            endDateError &&
                            <ErrorText text={endDateError} />
                        }
                    </div>
                </div>

                <div className="my-2 padding_top">
                    <button className="btn btn-lg search-btn mr-2 px-3"
                        onClick={() => {
                            if (!canApplyFilter()) {
                                return;
                            }
                            if (!filterLoading) {
                                startDateRef.current = startDate;
                                endDateRef.current = endDate;
                                centreRef.current = centre;
                                setFilterLoading(true);
                                getDetails();
                                setIsOpen(false);
                            }
                        }}
                        disabled={(filterLoading || !applyFilter()) ? true : false}>Apply</button>

                    <span className="clear-filter-cheques ml-2" onClick={handleClearFilter} style={{textDecoration: "none"}}>Clear</span>
                </div>

                <div className="my-2 padding_top">
                    <button className="btn export-btn-filter" onClick={downloadCashPositionDetails}>
                        {
                            downloadLoading ?
                                <span className="spinner spinner-white mr-3" />
                                :
                                <img src={DownloadIcon} alt="Download" className="export_download" />
                        }
                        {" "}
                        Export
                    </button>
                </div>
                {/* <div className="d-flex export_row">
                    <CustomFinanceTooltip title="export the data" placement="left" hideTooltip={filterApplied()}>
                        <span
                            className={filterApplied() ? "cursor-pointer" : "disabled-content"}
                            onClick={downloadCashPositionDetails}
                        >
                            {
                                downloadLoading ?
                                    <span className="spinner spinner-white mr-3" />
                                    :
                                    <img src={DownloadIcon} alt="Download" className="export_download" />
                            }
                            {" "}
                            Export
                        </span>
                    </CustomFinanceTooltip>
                </div> */}
            </div>
        </div>
    );
};

export default CashPositionFilter;
