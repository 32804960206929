import { HANDLE_BATCH_DETAILS, RESET_BATCH_DETAILS, SET_BATCH_SCROLL } from '../actions/types';

const initialState = {
    batchList: [],
    scrollPositionY: 0,
    searchText: "",
    skip: 0,
    limit: 20,
    continueScroll: false
};
//eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case HANDLE_BATCH_DETAILS:
            return {
                ...state,
                ...action.payload
            };
        case RESET_BATCH_DETAILS:
            return initialState;

        case SET_BATCH_SCROLL:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}